import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { SET_PRECONTRACT, SET_PRECONTRACTS_LIST, SET_PRECONTRACT_ITEM, SET_PRECONTRACT_ITEMS } from "./actionTypes";

export const getPrecontracts = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/pre-contract`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PRECONTRACTS_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the precontract by id
export const getPrecontractById = (precontractId) => async (dispatch) => {
  try {
    return API.get(`/contracts/pre-contract/${precontractId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_PRECONTRACT, payload: res?.data?.data });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// form data for add/edit contract
const handlePreContractFormDataAppend = (payload) => {
  const formData = new FormData();

  for (var key in payload) {
    if (key === "pre_contract_photos") {
      payload?.pre_contract_photos?.forEach((item) => {
        formData.append("pre_contract_photos", item);
      });
    } else if (key === "appraisal_abstract_en" && payload?.contract_date) {
      formData.append(key, payload[key]);
    } else if (key === "specialists") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "expenses") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "customer") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "internal_observations") {
      formData.append(key, payload[key]);
    } else if (key === "notes") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_abstract_pt") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_location_en") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_location_pt") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_report_conditions_en") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_report_conditions_pt") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_title_en") {
      formData.append(key, payload[key]);
    } else if (key === "appraisal_title_pt") {
      formData.append(key, payload[key]);
    } else if (key === "contract_date") {
      formData.append(key, payload[key]);
    } else if (key === "vat") {
      formData.append(key, payload[key]);
    } else if (key === "premium") {
      formData.append(key, JSON.stringify(payload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  }

  return formData;
};

// to add pre-contract
export const addPrecontract = (payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/pre-contract`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update pre-contract
export const updatePrecontract = (precontractId, payload) => async (dispatch) => {
  try {
    return API.put(`/contracts/pre-contract/${precontractId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_PRECONTRACT, payload: res?.data?.data });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add contract form data
export const addPrecontractForm = (payload) => async (dispatch) => {
  const formData = handlePreContractFormDataAppend(payload);
  try {
    return API.post(`/contracts/pre-contract`, formData).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to edit pre contract- formdata
export const updatePrecontractForm = (precontractId, payload) => async (dispatch) => {
  const formData = handlePreContractFormDataAppend(payload);
  try {
    return API.put(`/contracts/pre-contract/${precontractId}`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_PRECONTRACT, payload: res?.data?.data });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete pre-contract
export const deletePrecontract = (precontractId) => async (dispatch) => {
  try {
    return API.delete(`/contracts/pre-contract/${precontractId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// pre-contracts items

// to get the list of pre-contract items
export const getPrecontractItems = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/pre-contract-item`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PRECONTRACT_ITEMS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            suggested_pre_contract_no: res?.data?.data?.suggested_pre_contract_no,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the precontract-item by id
export const getPrecontractItemById = (precontractItemId) => async (dispatch) => {
  try {
    return API.get(`/contracts/pre-contract-item/${precontractItemId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_PRECONTRACT_ITEM, payload: res?.data?.data });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// to add pre-contract item
export const addPrecontractItem = (payload) => async (dispatch) => {
  const formData = new FormData();

  for (var key in payload) {
    if (key === "appraisal_photos") {
      payload?.appraisal_photos?.forEach((item) => {
        formData.append("appraisal_photos", item);
      });
    } else if (key === "expenses") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "categories") {
      formData.append(key, JSON.stringify(payload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  }

  try {
    return API.post(`/contracts/pre-contract-item`, formData).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to edit pre-contract item
export const updatePrecontractItem = (precontractItemId, payload) => async (dispatch) => {
  const formData = new FormData();

  for (var key in payload) {
    if (key === "appraisal_photos") {
      payload?.appraisal_photos?.forEach((item) => {
        formData.append("appraisal_photos", item);
      });
    } else if (key === "expenses") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "categories") {
      formData.append(key, JSON.stringify(payload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  }
  try {
    return API.put(`/contracts/pre-contract-item/${precontractItemId}`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete pre-contract item
export const deletePrecontractItem = (payload) => async (dispatch) => {
  try {
    return API.delete(`/contracts/pre-contract-item`, {
      data: {
        is_selected_all: payload?.isSelectedAll,
        ids: payload?.ids,
        filter: payload?.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to send the pre-contract items to the contract
export const sendItemsToContract = (precontractId, payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/pre-contract-copy/${precontractId}`, {
      filter: payload?.filter,
      existing_contract_id: payload?.existing_contract_id ? payload?.existing_contract_id : null,
      is_selected_all: payload?.is_selected_all,
      ids: payload?.ids,
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export pre-contract items
export const exportPrecontract = (precontractId, payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/pre-contract-export/${precontractId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
