import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { openFileURL } from "../../helpers/utils";
import { SET_PADDLES_DATA } from "./actionType";

// to get list of paddles
export const getPaddles = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/paddles`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PADDLES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            suggested_paddle_no: res?.data?.data?.suggested_paddle_no,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add paddle
export const addPaddle = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/paddles`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update paddle
export const updatePaddle = (paddleId, payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/paddles/${paddleId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete paddle
export const deletePaddle = (payload) => async (dispatch) => {
  try {
    return API.delete(`/auctions/paddles`, { data: { ...payload } }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export individual invoice
export const viewInvoiceDocuments = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/paddle-export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        if (res?.data?.data?.document_urls?.length > 0) {
          res?.data?.data?.document_urls?.forEach((url) => {
            openFileURL(url, "download");
          });
        }
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
