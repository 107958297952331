import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import {
  ADD_PRODUCTANDSERVICE,
  DELETE_PRODUCTANDSERVICE,
  SET_PRODUCTSANDSERVICES_DATA,
  UPDATE_PRODUCTANDSERVICE,
} from "./actionType";

export const getProductsAndServices = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/products`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PRODUCTSANDSERVICES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add currency
export const addProductOrService = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/products`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_PRODUCTANDSERVICE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update currency
export const updateProductOrService = (productOrServiceId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/products/${productOrServiceId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_PRODUCTANDSERVICE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete currency
export const deleteProductOrService = (productOrServiceId) => async (dispatch) => {
  try {
    return API.delete(`/billings/products/${productOrServiceId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: DELETE_PRODUCTANDSERVICE,
          payload: productOrServiceId,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
