import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_PERIOD, SET_PERIODS_DATA, UPDATE_PERIOD } from "./actionTypes";

export const getPeriods = (params) => async (dispatch) => {
  try {
    return API.get(`/lots/periods`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PERIODS_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add artist
export const addPeriod = (payload) => async (dispatch) => {
  try {
    return API.post(`/lots/periods`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_PERIOD,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update artist
export const updatePeriod = (periodId, payload) => async (dispatch) => {
  try {
    return API.put(`/lots/periods/${periodId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_PERIOD,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete artist
export const deletePeriod =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/lots/periods/${id}`, { data: { ...payload } }).then((res) => {
        return res;
      });
    } catch (error) {
      console.log(error);
    }
  };
