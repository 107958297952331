import i18next from "i18next";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import TabsComponent from "../../../common/TabsComponent";
import InfoForm from "../../auctions/InfoForm";
import EditionCatalogue from "./EditionCatalogue";
import EditionInfo from "./EditionInfo";

const AuctionsEditionContent = (props) => {
  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.appraisalValidation?.resetForm();
      props?.artExpertiseValidation?.resetForm();
      props?.artConsultancyValidation?.resetForm();
    };
  }, []);

  // handle change in active language tab index
  const handleChangeActiveTabIndex = (index) => {
    if (props?.infoValidation?.dirty || props?.catalogueValidation?.dirty || props?.isInfoFormDirty) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        props?.setActivePageIndex(index);
        props?.setIsInfoFormDirty(false);
      }
    } else {
      props?.setActivePageIndex(index);
    }
  };
  return (
    <div className="edition-tab-content">
      <TabsComponent
        tabStyleClasses="sub-tabs-style"
        controlledTabChange={true}
        activeTab={props?.activePageIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabData={[
          {
            label: i18next.t("common.general"),
            Component: InfoForm,
            props: {
              pageType: "general",
              ...props,
              isEditable: true,
            },
            disabled: false,
          },
          {
            label: i18next.t("common.catalogue"),
            Component: EditionCatalogue,
            props: { pageType: "catalogue", ...props },
            disabled: false,
          },
          {
            label: i18next.t("common.info"),
            Component: EditionInfo,
            props: { pageType: "info", ...props },
            disabled: false,
          },
        ]}
      />
    </div>
  );
};

export default withTranslation()(AuctionsEditionContent);
