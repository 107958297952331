import { FieldArray } from "formik";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Row } from "reactstrap";
import ActionButton from "../../common/ActionButton";
import CustomAddButton from "../../common/CustomAddButton";
import MessageBlock from "../../common/MessageBlock";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  getColumnDetail,
  getDateString,
  getLabelValueArray,
  getValuesFromArray,
} from "../../../helpers/utils";

import DeleteModal from "../../../components/common/DeleteModal";
import { SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import moment from "moment";
import { ADMIN, COMUNICATIONS } from "../../../helpers/constants";

const DEFAULT_SUBSCRIPTION_ITEM = {
  catalogues: "",
  start_date: "",
  end_date: "",
};
const DEFAULT_SHIPPING_ITEM = { catalogues: "", end_date: "" };

const subscriptionColumnsSpacing = [
  { md: 1, lg: 1 },
  { md: 4, lg: 4 },
  { md: 3, lg: 3 },
  { md: 3, lg: 3 },
];

const shippingColumnsSpacing = [
  { md: 1, lg: 1 },
  { md: 5, lg: 5 },
  { md: 5, lg: 5 },
];

const CatalogueSubscriptionTable = (props) => {
  const shippingOptionList = useSelector((state) => state?.Customers?.shippingOptions?.results || []);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const columnSpacing = props?.catalogueShipping ? shippingColumnsSpacing : subscriptionColumnsSpacing;

  // to bind the array helpers of the expense items field array(formik).
  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const pathname = window.location.pathname;

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (pathname.includes("edit")) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [pathname]);

  const handleSelectClick = () => {
    alert("Your user level is not allowed to edit this.");
  };

  // delete modal open handler
  const handleDeleteModalOpen = (index) => {
    setSelectedRowIndex(index);
    setIsDeleteModalOpen(true);
  };
  // delete modal close handler
  const handleDeleteModalClose = () => {
    setSelectedRowIndex(null);
    setIsDeleteModalOpen(false);
  };
  // handle delete
  const handleDelete = () => {
    boundArrayHelpers.remove(selectedRowIndex);
    handleDeleteModalClose();
  };

  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key) => {
    if (checkSelectAllSelected(selectedItem)) {
      props?.validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value)
      );
    } else {
      if (selectedItem.length > 0) {
        props?.validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        props?.validation.setFieldValue(key, []);
      }
    }
  };

  const handleGetCatalogueSubscriptionValue = (index) => {
    return props.validation.values?.[props?.arrayKey]?.[index]?.catalogues
      ?.map((id) => {
        const existingItem = shippingOptionList?.find((item) => item?.id === id);
        if (existingItem) {
          return {
            label: getColumnDetail(existingItem?.name_en, existingItem?.name_pt),
            value: existingItem?.id,
          };
        }
      })
      ?.filter((item) => Boolean(item?.value));
  };

  return (
    <>
      <div className="card-subtable-section table-light">
        <Row className="p-3 subtable-header subtable-row">
          <Col md={columnSpacing[1]?.md} lg={columnSpacing[0]?.lg}>
            {props.t("common.no_")}
          </Col>
          <Col md={columnSpacing[2]?.md} lg={columnSpacing[2]?.lg}>
            {props.t("customers.catalogues")}
          </Col>
          {!props?.catalogueShipping ? (
            <Col md={columnSpacing[2]?.md} lg={columnSpacing[2]?.lg}>
              {props.t("common.startDate")}
            </Col>
          ) : null}
          <Col md={columnSpacing[3]?.md} lg={columnSpacing[3]?.lg}>
            {props.t("common.endDate")}
          </Col>
        </Row>
        <hr />
        <div className="p-3 subtable-container" style={{ maxHeight: "320px", overflowY: "auto" }}>
          <FieldArray
            name={props.arrayKey}
            render={(arrayHelpers) => {
              bindArrayHelpers(arrayHelpers);
              return props.validation.values?.[props.arrayKey]?.length !== 0 ? (
                props.validation.values?.[props.arrayKey]?.map((_, index) => {
                  return (
                    <Row key={`${index}-${props?.arrayKey}`} className="subtable-row mb-3">
                      <Col md={columnSpacing[0]?.md} lg={columnSpacing[0]?.lg}>
                        <Input
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                          disabled
                          placeholder={index + 1}
                          className="text-center"
                        />
                      </Col>
                      <Col
                        md={columnSpacing[1]?.md}
                        lg={columnSpacing[1]?.lg}
                        id={`${index}-catalogues-${props.arrayKey}`}
                      >
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          name={`[${props.arrayKey}].[${index}].catalogues`}
                          className={`custom-select temp-select custom-multi-select ${
                            props.validation.touched?.[props.arrayKey]?.[index]?.catalogues &&
                            props.validation.errors?.[props.arrayKey]?.[index]?.catalogues
                              ? "select-error"
                              : ""
                          }`}
                          options={[
                            SELECT_ALL_OPTION,
                            ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                          ]}
                          value={
                            props.validation.values?.[props.arrayKey]?.[index]?.catalogues?.length > 0
                              ? handleGetCatalogueSubscriptionValue(index)
                              : []
                          }
                          placeholder={`${props.t("common.select")} ${props.t("customers.catalogues")}`}
                          onChange={(catalogue) => {
                            if (props?.catalogueShipping) {
                              if (checkPermissionByRoles([ADMIN, COMUNICATIONS])) {
                                handleMultiSelectDropdown(
                                  catalogue,
                                  getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                                  `[${props.arrayKey}].[${index}].catalogues`
                                );
                              }
                            } else {
                              handleMultiSelectDropdown(
                                catalogue,
                                getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                                `[${props.arrayKey}].[${index}].catalogues`
                              );
                            }
                          }}
                          onBlur={(e) => {
                            props?.validation.setFieldTouched(`[${props.arrayKey}].[${index}].catalogues`, true);
                            props?.validation.handleBlur(e);
                          }}
                          isMulti={true}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          menuPosition="fixed"
                          closeMenuOnScroll={(e) => {
                            if (e.target === document) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          isDisabled={props?.catalogueShipping && !checkPermissionByRoles([ADMIN, COMUNICATIONS])}
                        />

                        {props.validation.touched?.[props.arrayKey]?.[index]?.catalogues &&
                        props.validation.errors?.[props.arrayKey]?.[index]?.catalogues ? (
                          <span className="custom-invalid-feedback">
                            {props.validation.errors?.[props.arrayKey]?.[index]?.catalogues}
                          </span>
                        ) : null}
                      </Col>
                      {!props?.catalogueShipping ? (
                        <Col md={columnSpacing[2]?.md} lg={columnSpacing[2]?.lg}>
                          <Input
                            name={`[${props.arrayKey}].[${index}].start_date`}
                            className="form-control"
                            type="date"
                            onChange={props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                            value={props.validation.values?.[props.arrayKey]?.[index]?.start_date}
                            invalid={
                              props.validation.touched?.[props.arrayKey]?.[index]?.start_date &&
                              props.validation.errors?.[props.arrayKey]?.[index]?.start_date
                                ? true
                                : false
                            }
                          />
                          {props.validation.touched?.[props.arrayKey]?.[index]?.start_date &&
                          props.validation.errors?.[props.arrayKey]?.[index]?.start_date ? (
                            <FormFeedback type="invalid">
                              {props.validation.errors?.[props.arrayKey]?.[index]?.start_date}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      ) : null}
                      <Col md={columnSpacing[3]?.md} lg={columnSpacing[3]?.lg}>
                        <Input
                          name={`[${props.arrayKey}].[${index}].end_date`}
                          className="form-control"
                          type="date"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          min={
                            props.validation.values?.[props.arrayKey]?.[index]?.start_date
                              ? getDateString(
                                  moment(new Date(props.validation.values?.[props.arrayKey]?.[index]?.start_date)).add(
                                    1,
                                    "day"
                                  )
                                )
                              : ""
                          }
                          value={props.validation.values?.[props.arrayKey]?.[index]?.end_date}
                          invalid={
                            props.validation.touched?.[props.arrayKey]?.[index]?.end_date &&
                            props.validation.errors?.[props.arrayKey]?.[index]?.end_date
                              ? true
                              : false
                          }
                          disabled={props?.catalogueShipping && !checkPermissionByRoles([ADMIN, COMUNICATIONS])}
                        />
                        {props.validation.touched?.[props.arrayKey]?.[index]?.end_date &&
                        props.validation.errors?.[props.arrayKey]?.[index]?.end_date ? (
                          <FormFeedback type="invalid">
                            {props.validation.errors?.[props.arrayKey]?.[index]?.end_date}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={1} lg={1}>
                        <ActionButton
                          onClick={() => {
                            if (isEdit && !checkPermissionByRoles([ADMIN])) {
                              handleSelectClick();
                            } else {
                              handleDeleteModalOpen(index);
                            }
                          }}
                          tooltip={props.t("common.delete")}
                          iconClasses="ri-delete-bin-fill fs-18"
                          iconColor="red"
                          buttonId={`delete-action-${index}-${props?.arrayKey}`}
                          disabled={props?.catalogueShipping && !checkPermissionByRoles([ADMIN, COMUNICATIONS])}
                        />
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <MessageBlock
                  fontSize="14px"
                  height="50px"
                  message={
                    props?.catalogueShipping
                      ? props.t("information.noShippings")
                      : props.t("information.noSubscription")
                  }
                />
              );
            }}
          />
        </div>
        <hr />
        <div className="p-2 subtable-footer">
          <div className="d-flex justify-content-end">
            <CustomAddButton
              type="button"
              btnTitle={props.t("common.addTitle", {
                module: props?.catalogueShipping
                  ? props.t("common.cataloguesShipping")
                  : props.t("common.subscription"),
              })}
              onClick={() => {
                if (isEdit && !checkPermissionByRoles([ADMIN])) {
                  handleSelectClick();
                } else {
                  if (props?.catalogueShipping) {
                    if (checkPermissionByRoles([ADMIN, COMUNICATIONS])) {
                      boundArrayHelpers.push({
                        ...DEFAULT_SHIPPING_ITEM,
                      });
                    }
                  } else {
                    boundArrayHelpers.push({
                      ...DEFAULT_SUBSCRIPTION_ITEM,
                    });
                  }
                }
              }}
              disabled={isEdit && props?.catalogueShipping && !checkPermissionByRoles([ADMIN, COMUNICATIONS])}
              color={checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "success" : "grey"}
              {...(isEdit &&
                props?.catalogueShipping &&
                !checkPermissionByRoles([ADMIN, COMUNICATIONS]) && {
                  style: {
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    background: "none",
                  },
                })}
            />
          </div>
        </div>
      </div>
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDelete}
        title={`${props.t("common.delete")} ${
          props?.catalogueShipping ? props.t("common.shipping") : props.t("common.subscription")
        }`}
        body={props.t("confirmation.deleteMessage", {
          module: props?.catalogueShipping ? props.t("common.shipping") : props.t("common.subscription"),
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(CatalogueSubscriptionTable);
