import { ADD_AUCTION_TAGS, DELETE_AUCTION_TAGS, EDIT_AUCTION_TAGS, SET_AUCTION_TAGS_LIST } from "./actionType";
const INIT_STATE = {
  data: [],
  total: 0,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUCTION_TAGS_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload?.total,
      };
    case ADD_AUCTION_TAGS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        total: state.total + 1,
      };
    case EDIT_AUCTION_TAGS:
      const existingIndex = state.data.findIndex((auctionTag) => auctionTag.id === action.payload.data.id);
      const updatedAuctionTagData = state.data;
      if (existingIndex !== -1) {
        updatedAuctionTagData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        data: [...updatedAuctionTagData],
      };
    case DELETE_AUCTION_TAGS:
      return {
        ...state,
        data: state.data.filter((auctionTagData) => auctionTagData.id !== action.payload.deletedId),
        total: state.total - 1,
      };
    default:
      return state;
  }
};

export default User;
