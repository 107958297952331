import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";

import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../components/common/DeleteModal";
import Loader from "../../../components/common/Loader";
import AddNoteModal from "../../../components/pages/home/AddNoteModal";
import { createNote, deleteNote, getNoteLists, updateNote } from "../../../store/notes/action";

const INITIAL_PAYLOAD = {
  search: "",
  // page: 1,
  // limit: 10,
  filter: {
    id__icontains: "",
    description__icontains: "",
  },
};

const Notes = (props) => {
  const dispatch = useDispatch();
  const { data: NoteData } = useSelector((state) => state?.Notes);

  const [isGetNotesLoader, setIsGetNotesLoader] = useState(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [noteDeleteLoader, setNoteDeleteLoader] = useState(false);
  const [noteSaveLoader, setNoteSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    id: 1,
    description: "DSSDF",
  });
  const [notePayload, setNotePayload] = useState(INITIAL_PAYLOAD);

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.notes")}`;

  const handleGetNoteList = useCallback(
    (notePayload) => {
      setIsGetNotesLoader(true);
      dispatch(getNoteLists(notePayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setIsGetNotesLoader(false);
        })
        .catch((error) => {
          setIsGetNotesLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetNoteList(notePayload);
  }, [handleGetNoteList, notePayload]);

  const handleSaveTask = (values, validation) => {
    setNoteSaveLoader(true);

    if (selectedRow && selectedRow?.id) {
      // dispatch for editing page
      dispatch(updateNote({ id: selectedRow.id, values: values }))
        .then((res) => {
          if (res) {
            //
            handleGetNoteList(notePayload);
            validation.resetForm();
            setIsAddNoteModalOpen(false);
          } else {
            //
          }
          setNoteSaveLoader(false);
        })
        .catch((error) => {
          setIsAddNoteModalOpen(false);
          setNoteSaveLoader(false);
        });
    } else {
      dispatch(createNote(values))
        .then((res) => {
          if (res) {
            //
            handleGetNoteList(notePayload);
            validation.resetForm();
            setIsAddNoteModalOpen(false);
          } else {
            //
          }
          setNoteSaveLoader(false);
        })
        .catch((error) => {
          setIsAddNoteModalOpen(false);
          setNoteSaveLoader(false);
        });
    }
  };

  const handleConfirmBtn = () => {
    setNoteDeleteLoader(true);

    // to delete the task
    dispatch(deleteNote({ ids: selectedRow?.id, isSelectAll: false }))
      .then((res) => {
        if (res) {
          //
          handleGetNoteList(notePayload);
          setIsDeleteModalOpen(false);
        } else {
          //
        }
        setNoteDeleteLoader(false);
      })
      .catch((error) => {
        setNoteDeleteLoader(false);
        setIsDeleteModalOpen(false);
      });
  };

  const handleCloseBtn = () => {
    setIsDeleteModalOpen(false);
  };

  const handleNoteModalOpen = (row) => {
    // setSelectedRow({ id: 1, description: "DSSDF" });
    setSelectedRow(row);
    setIsAddNoteModalOpen(true);
  };

  const handleNoteModalClose = () => {
    setIsAddNoteModalOpen(false);
  };

  const noteDeleteHandler = (row) => {
    setSelectedRow(row);
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      {/* <div className="layout-main-container"> */}
      <Card className="container-body card-section">
        <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between p-2">
          <span className="card-title">{`${props.t("sidebarMenu.notes")}`}</span>
          <div className="d-flex flex-row gap-2 p-0">
            <CustomAddButton
              color={"success"}
              onClick={() => handleNoteModalOpen(null)}
              btnTitle={`${props.t("common.add")} ${props.t("home.notes.note")}`}
            />
          </div>
        </CardHeader>
        <CardBody>
          <Row className="notes-list">
            {isGetNotesLoader ? <Loader /> : null}
            {NoteData.map((note, index) => {
              return (
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Card className="note-container">
                    <div className="note-container-body" onClick={() => handleNoteModalOpen(note)}>
                      <Input
                        className="dec-input"
                        type="textarea"
                        value={note?.description}
                        style={{
                          height: "150px",
                          backgroundColor: "#fff7d1",
                        }}
                        readOnly
                      />
                      {/* {note.description} */}
                    </div>

                    <div
                      className="note-container-footer d-flex justify-content-end"
                      style={{ backgroundColor: "#fff2ab" }}
                    >
                      {/* <button
                      type="button"
                      className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                      onClick={() => handleNoteModalOpen(note)}
                    >
                      <CustomTooltipWrapper
                        target={`tooltip-${note?.id}-edit-${index}`}
                        tooltipBody={props.t("common.edit")}
                      />
                      <i
                        className="ri-pencil-fill fs-18"
                        id={`tooltip-${note?.id}-edit-${index}`}
                      />
                    </button> */}
                      <button
                        type="button"
                        key={`key-${note?.id}-delete-${index}`}
                        className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                        onClick={() => noteDeleteHandler(note)}
                      >
                        <CustomTooltipWrapper
                          target={`tooltip-${note?.id}-delete-${index}`}
                          tooltipBody={props.t("common.delete")}
                        />
                        <i
                          className="ri-delete-bin-fill fs-18"
                          color="red"
                          id={`tooltip-${note?.id}-delete-${index}`}
                        />
                      </button>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
      {/* </div> */}

      {/* to add /edit note */}
      <AddNoteModal
        isModalOpen={isAddNoteModalOpen}
        note={selectedRow}
        onCancel={handleNoteModalClose}
        onSave={handleSaveTask}
        loader={noteSaveLoader}
      />

      {/* delete modal for note*/}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("home.notes.note")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("home.notes.note"),
        })}
        actionBtnTitle={`${props.t("common.delete")}`}
        loader={noteDeleteLoader}
        isModalOpen={isDeleteModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />
    </>
  );
};

export default withTranslation()(Notes);
