import { SET_TITLES_DATA } from "../actionTypes";

const INITIAL_STATE = {
  data: [],
  total: 0,
};

const Titles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TITLES_DATA:
      return {
        ...state,
        data: action?.payload?.results,
        total: action?.payload?.count,
      };
    default:
      return { ...state };
  }
};

export default Titles;
