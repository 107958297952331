import React, { useEffect, useState } from "react";
import { BlockPicker } from "react-color";

const CustomColorPicker = ({ initialColor, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const handleOpenColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color) => {
    onChange(color.hex);
    setColor(color.hex);
  };
  return (
    <div className="custom-color-picker">
      <div className="swatch-container" onClick={handleOpenColorPicker}>
        <div
          className="swatch-cell"
          style={{
            background: `${color}`,
          }}
        />
      </div>

      {displayColorPicker ? (
        <div className="color-picker-popover">
          <div className="color-picker-cover" onClick={handleCloseColorPicker} />
          <BlockPicker
            color={color}
            onChange={handleColorChange}
            triangle="hide"
            colors={[
              "#8ed1fc",
              "#7BDCB5",
              "#FCB900",
              "#FF6900",
              "#0693E3",
              "#ABB8C3",
              "#000000",
              "#EB144C",
              "#00D084",
              "#9900EF",
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomColorPicker;
