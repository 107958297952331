import i18next from "i18next";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "reactstrap";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";
import {
  checkSelectAllSelected,
  getColumnDetail,
  getDateInDDMMMYYYYFormat,
  getDateInYYYYMMDDFormat,
  getDateString,
  getLabelValueArray,
  getValuesFromArray,
} from "../../../../helpers/utils";
import { getAuctionList } from "../../../../store/auctions/actions";
import CustomButton from "../../../common/CustomButton";
import CustomColumnPhoto from "../../../common/CustomColumnPhoto";
import CustomLinkWrapper from "../../../common/CustomLinkWrapper";
import CustomSearchInput from "../../../common/CustomSearchInput";
import DateRangeInput from "../../../common/DateRangeInput";
import NoRecords from "../../../common/NoRecords";
import Loader from "../../../common/Loader";
import CustomMultiSelect from "../../../common/CustomMultiSelect";
import { SELECT_ALL_OPTION } from "../../../../helpers/constants/selectOptions";
import { getLocations } from "../../../../store/locations/actions";

const WebAuctionsContent = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: auctionData, total: totalAuctions } = useSelector((state) => state?.Auctions);
  const LOCATION_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Locations?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "location_name_en" : "location_name_pt"
  );

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedAuctionLocation, setSelectedAuctionLocation] = useState([]);
  const [auctionLocationLoader, setAuctionLocationLoader] = useState(false);
  const [auctionLocationPayload, setAuctionLocationPayload] = useState({
    page: 1,
    sort: getColumnDetail("location_name_en", "location_name_pt"),
  });
  const [auctionLoader, setAuctionLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "auction_no",
    filter: {
      auction_start_date__gt: getDateInYYYYMMDDFormat(new Date()),
    },
  });

  // useeffect to filter old and upcoming
  useEffect(() => {
    if (type === "next") {
      setAuctionPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__lte;
        return {
          ...prevState,
          filter: {
            ...prevState?.filter,
            auction_start_date__gt: getDateInYYYYMMDDFormat(new Date()),
          },
        };
      });
    } else {
      setAuctionPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__gt;
        return {
          ...prevState,
          filter: {
            ...prevState?.filter,
            auction_start_date__lte: getDateInYYYYMMDDFormat(new Date()),
          },
        };
      });
    }
  }, [type]);

  useEffect(() => {
    if (auctionPayload?.filter?.auction_start_date__gt || auctionPayload?.filter?.auction_start_date__lte) {
      setAuctionLoader(true);
      dispatch(getAuctionList(auctionPayload)).then(() => {
        setAuctionLoader(false);
      });
    }
  }, [auctionPayload, dispatch]);

  const navigateOnEditionPage = (auctionId, type) => {
    history.push(`/website-management/website-auctions/${auctionId}/edition/${type}`);
  };

  // handle page change
  const handlePageChange = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setAuctionPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setAuctionPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setAuctionPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // get auction theme, auction type and location
  useEffect(() => {
    setAuctionLocationLoader(true);
    dispatch(getLocations(auctionLocationPayload)).then(() => {
      setAuctionLocationLoader(false);
    });
  }, [dispatch, auctionLocationPayload]);

  // handle date filter
  useEffect(() => {
    if (selectedDateRange) {
      setAuctionPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          auction_start_date__range: [
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)),
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)),
          ],
        },
      }));
    } else {
      setAuctionPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__range;
        return { ...prevState };
      });
    }
  }, [selectedDateRange]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${i18next.t("common.auctionNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_no"
            className="column-search-input"
            payload={auctionPayload}
            setPayload={setAuctionPayload}
          />
        </div>
      ),
      selector: (row) => (row?.auction_no ? row?.auction_no : "-"),
      width: "138px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${i18next.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.auction_photo} title={<CustomColumnPhoto sourceUrl={row?.auction_photo} />} />
      ),
      width: "82px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{i18next.t("common.dates")}</div>
          <DateRangeInput
            classes={"column-date-range"}
            inputValue={
              selectedDateRange
                ? `${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.startDate))}  ${i18next.t(
                    "common.to"
                  )}  ${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.endDate))}`
                : ""
            }
            rangeValue={selectedDateRange}
            onChange={(values) => {
              setSelectedDateRange(values);
            }}
            onClear={() => {
              setSelectedDateRange(null);
            }}
          />
        </div>
      ),
      selector: (row) => getDateInDDMMMYYYYFormat(new Date(getDateString(row?.auction_start_date))),
      width: "306px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{i18next.t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_en__icontains", "title_pt__icontains")}
            className="column-search-input"
            payload={auctionPayload}
            setPayload={setAuctionPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.title_en, row?.title_pt),
      width: "306px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{i18next.t("settings.auctions.locations.location")}</div>
          <CustomMultiSelect
            isLoading={auctionLocationLoader}
            options={auctionLocationLoader ? [] : [SELECT_ALL_OPTION, ...LOCATION_OPTIONS]}
            selectedOptions={selectedAuctionLocation}
            handleOnSelection={(values) => {
              setSelectedAuctionLocation(values);
              handleDropdownFilter(values, "auction_location__in", setSelectedAuctionLocation);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getColumnDetail(row?.auction_location?.location_name_en, row?.auction_location?.location_name_pt) || "-",
      // selector: (row) => row?.auction_location?.location_name,
      width: "208px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{i18next.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions gap-2">
          <CustomButton
            onClick={() => {
              window.open(`${process.env.REACT_APP_NEXT_WEBSITE}/pt/auctions/${row.id}/lots`, "_blank");
            }}
            btnTitle={i18next.t("common.view")}
          />
          <CustomButton
            btnTitle={`${i18next.t("common.edition")} ${i18next.t("common.pt")}`}
            onClick={() => {
              navigateOnEditionPage(row?.id, "pt");
            }}
          />
          <CustomButton
            btnTitle={`${i18next.t("common.edition")} ${i18next.t("common.en")}`}
            onClick={() => {
              navigateOnEditionPage(row?.id, "en");
            }}
          />
        </div>
      ),
      width: "348px",
      center: true,
    },
  ];

  return (
    <Card className="subcard-table-container table-light">
      {auctionLoader ? <Loader /> : null}
      <DataTable
        fixedHeader
        className="data-table"
        persistTableHead
        columns={columns}
        data={[...auctionData]}
        pagination
        paginationServer
        paginationTotalRows={totalAuctions}
        paginationDefaultPage={auctionPayload?.page}
        paginationPerPage={DEFAULT_ROW_PER_PAGE}
        noDataComponent={<NoRecords />}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: i18next.t("common.rowsPerPage"),
          rangeSeparatorText: i18next.t("common.rangeSeparator"),
        }}
      />
    </Card>
  );
};

export default WebAuctionsContent;
