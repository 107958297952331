import {
  RESET_PRECONTRACT_ITEM,
  SET_PRECONTRACT,
  SET_PRECONTRACTS_LIST,
  SET_PRECONTRACT_ITEM,
  SET_PRECONTRACT_ITEMS,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
  precontract: null,
  precontractItems: [],
  totalItems: 0,
  precontractItem: null,
};

const PreContracts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PRECONTRACTS_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case SET_PRECONTRACT:
      return {
        ...state,
        precontract: action.payload,
      };
    case SET_PRECONTRACT_ITEMS:
      return {
        ...state,
        precontractItems: action.payload.data,
        totalItems: action.payload.total,
        suggested_pre_contract_no: action.payload.suggested_pre_contract_no,
      };
    case SET_PRECONTRACT_ITEM:
      return {
        ...state,
        precontractItem: action.payload,
      };
    case RESET_PRECONTRACT_ITEM:
      return {
        ...state,
        precontractItem: null,
      };
    default:
      return { ...state };
  }
};

export default PreContracts;
