import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ ...props }) => {
  return (
    <div className="custom-react-datepicker-wrapper">
      <DatePicker className="custom-react-datepicker form-control" showIcon {...props}></DatePicker>
    </div>
  );
};

export default CustomDatePicker;
