import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import ActionButton from "../../../../components/common/ActionButton";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomMultiSelect from "../../../../components/common/CustomMultiSelect";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddTitleModal from "../../../../components/pages/settings/AddTitleModal";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";
import { SELECT_ALL_OPTION, TITLE_TYPE_OPTIONS } from "../../../../helpers/constants/selectOptions";
import { checkSelectAllSelected, getLabelFromArray, getValuesFromArray } from "../../../../helpers/utils";
import { addTitle, deleteTitle, getTitles, updateTitle } from "../../../../store/titles/actions";

const Titles = (props) => {
  const dispatch = useDispatch();
  const { data: titlesData, total: totalTitles } = useSelector((state) => state?.Titles);

  const [titlesPayload, setTitlesPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
  });

  const [selectedTitle, setSelectedTitle] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);

  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const handleOpenTitleModal = (title) => {
    setSelectedTitle(title);
    setIsTitleModalOpen(true);
  };
  const handleCloseTitleModal = () => {
    setSelectedTitle(null);
    setIsTitleModalOpen(false);
  };

  const handleSaveTitle = (values) => {
    // check for add or edit
    if (selectedTitle && selectedTitle?.id) {
      // edit title
      setSaveLoader(true);
      dispatch(updateTitle(selectedTitle?.id, values))
        .then((res) => {
          if (res) {
            handleCloseTitleModal();
            handleGetTitles(titlesPayload);
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add title
      setSaveLoader(true);
      dispatch(addTitle(values))
        .then((res) => {
          if (res) {
            handleCloseTitleModal();
            handleGetTitles(titlesPayload);
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (title) => {
    setSelectedTitle(title);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedTitle(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteTitle = () => {
    if (selectedTitle && selectedTitle?.id) {
      setDeleteLoader(true);
      dispatch(deleteTitle(selectedTitle?.id))
        .then((res) => {
          if (res) handleGetTitles(titlesPayload);

          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setTitlesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setTitlesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle dropdown type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setTitlesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload?.filter?.[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setTitlesPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setTitlesPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload?.filter?.[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  //   handle get titles
  const handleGetTitles = useCallback(
    (titlesPayload) => {
      setTableLoader(true);
      dispatch(getTitles(titlesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetTitles(titlesPayload);
  }, [titlesPayload, handleGetTitles]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.title")} ${props.t("common.en")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="title_en__icontains"
            className="column-search-input"
            payload={titlesPayload}
            setPayload={setTitlesPayload}
          />
        </div>
      ),
      selector: (row) => (row?.title_en ? row?.title_en : "-"),
      width: "500px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.title")} ${props.t("common.pt")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="title_pt__icontains"
            className="column-search-input"
            payload={titlesPayload}
            setPayload={setTitlesPayload}
          />
        </div>
      ),
      selector: (row) => (row?.title_pt ? row?.title_pt : "-"),
      width: "500px",
      wrap: true,
    },

    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.type")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...TITLE_TYPE_OPTIONS]}
            selectedOptions={selectedTypeOptions}
            handleOnSelection={(values) => {
              setSelectedTypeOptions(values);
              handleDropdownFilter(values, "title_type__in", setSelectedTypeOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.title_type ? getLabelFromArray(TITLE_TYPE_OPTIONS, row?.title_type) : "-"),
      width: "286px",
      wrap: true,
    },

    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <ActionButton
            iconClasses={"ri-pencil-fill fs-18"}
            buttonId={`tooltip-${row?.id}-edit`}
            tooltip={props.t("common.edit")}
            onClick={() => {
              handleOpenTitleModal(row);
            }}
          />
          <ActionButton
            iconClasses={"ri-delete-bin-fill fs-18"}
            buttonId={`tooltip-${row?.id}-delete`}
            tooltip={props.t("common.delete")}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          />
        </div>
      ),
      width: "142px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.titles")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title d-flex flex-row align-items-center">
            <div>{props.t("sidebarMenu.titles")}</div>
            <div className="contain-header-right ms-auto">
              <CustomAddButton
                color="success"
                btnTitle={props.t("common.addTitle", {
                  module: props.t("common.title"),
                })}
                onClick={handleOpenTitleModal}
              />
            </div>
          </CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...titlesData]}
            paginationDefaultPage={titlesPayload?.page}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            pagination
            paginationServer
            paginationTotalRows={totalTitles}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* add/edit title modal */}
      <AddTitleModal
        title={selectedTitle}
        isModalOpen={isTitleModalOpen}
        loader={saveLoader}
        onCancel={handleCloseTitleModal}
        onSave={handleSaveTitle}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteTitle}
        title={`${props.t("common.delete")} ${props.t("common.title")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.title"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Titles);
