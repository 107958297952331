import {
  ADD_PRODUCTANDSERVICE,
  DELETE_PRODUCTANDSERVICE,
  SET_PRODUCTSANDSERVICES_DATA,
  UPDATE_PRODUCTANDSERVICE,
} from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
};

const ProductsAndServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PRODUCTSANDSERVICES_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_PRODUCTANDSERVICE:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_PRODUCTANDSERVICE:
      const existingProductOrServiceIndex = state.data.findIndex((item) => item.id === action.payload.id);
      const updatedProductsAndServicesData = state.data;
      if (existingProductOrServiceIndex !== -1) {
        updatedProductsAndServicesData[existingProductOrServiceIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedProductsAndServicesData,
      };
    case DELETE_PRODUCTANDSERVICE:
      return {
        ...state,
        data: [...state.data.filter((item) => item.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default ProductsAndServices;
