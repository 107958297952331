import { SET_INVOICE, SET_INVOICES, SET_MODAL_DOCUMENTS } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: null,
  invoice: null,
  modal: {
    data: [],
    total: null,
  },
};

const Invoicing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        all_ids: action.payload?.all_ids,
        closing_balance: action.payload?.closing_balance,
      };
    case SET_MODAL_DOCUMENTS:
      return {
        ...state,
        modal: {
          ...state?.modal,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Invoicing;
