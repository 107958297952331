import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddCategoryModal from "../../../../components/pages/settings/AddCategoryModal";
import { addCategory, deleteCategory, getCategories, updateCategory } from "../../../../store/categories/actions";

const Categories = (props) => {
  const dispatch = useDispatch();
  const { data: categoriesData, total: totalCategories } = useSelector((state) => state?.Categories);

  const [categoriesPayload, setCategoriesPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    sort: "",
    filter: {
      id__icontains: "",
      category_name_en__icontains: "",
      category_name_pt__icontains: "",
    },
  });

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // add or edit action handlers
  const handleCategoryModalOpen = (categoryData) => {
    setSelectedCategory(categoryData);
    setIsCategoryModalOpen(true);
  };
  const handleCategoryModalClose = () => {
    setSelectedCategory(null);
    setIsCategoryModalOpen(false);
  };
  const handleSaveCategory = (values, formikValidation) => {
    // check for add or edit
    if (selectedCategory && selectedCategory?.id) {
      // edit category
      setSaveLoader(true);
      dispatch(updateCategory(selectedCategory?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleCategoryModalClose();
            formikValidation.resetForm();
            handleGetCategories(categoriesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add category
      setSaveLoader(true);
      dispatch(addCategory(values))
        .then((res) => {
          if (res) {
            // success handler
            handleCategoryModalClose();
            formikValidation.resetForm();
            handleGetCategories(categoriesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (categoryData) => {
    setSelectedCategory(categoryData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedCategory(null);
    setIsDeleteModalOpen(false);
    setIsDeleteActionAllowed(false);
  };
  const handleDeleteCategory = () => {
    if (selectedCategory && selectedCategory?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteCategory({
          id: selectedCategory?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetCategories(categoriesPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setCategoriesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCategoriesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setCategoriesPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get categories
  const handleGetCategories = useCallback(
    (categoriesPayload) => {
      setTableLoader(true);
      dispatch(getCategories(categoriesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCategories(categoriesPayload);
  }, [handleGetCategories, categoriesPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={categoriesPayload}
            setPayload={setCategoriesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      // width: "212px",
      minWidth: "130px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.descriptionEn")}
            sortable
            sortableKey="category_name_en"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="category_name_en__icontains"
            className="column-search-input"
            payload={categoriesPayload}
            setPayload={setCategoriesPayload}
          />
        </div>
      ),
      selector: (row) => row?.category_name_en,
      // width: "500px",
      minWidth: "230px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.descriptionPt")}
            sortable
            sortableKey="category_name_pt"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="category_name_pt__icontains"
            className="column-search-input"
            payload={categoriesPayload}
            setPayload={setCategoriesPayload}
          />
        </div>
      ),
      selector: (row) => row?.category_name_pt,
      // width: "500px",
      minWidth: "230px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleCategoryModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      // width: "212px",
      minWidth: "120px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.categories")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={categoriesPayload}
            setPayload={setCategoriesPayload}
          />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleCategoryModalOpen}
              btnTitle={props.t("common.addTitle", {
                module: props.t("settings.lots.categories.category"),
              })}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.categories")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...categoriesData]}
            pagination
            paginationServer
            paginationTotalRows={totalCategories}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddCategoryModal
        category={selectedCategory}
        isModalOpen={isCategoryModalOpen}
        onCancel={handleCategoryModalClose}
        onSave={handleSaveCategory}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteCategory}
        title={`${props.t("common.delete")} ${props.t("settings.lots.categories.category")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.lots.categories.category"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Categories);
