// customer action types
export const SET_AUCTIOND_DATA = "SET_AUCTIOND_DATA";
export const SET_AUCTION = "SET_AUCTION";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_SHIPPING_OPTIONS = "SET_SHIPPING_OPTIONS";
export const SET_PERSON_DETAILS = "SET_PERSON_DETAILS";

// paddle action types
export const SET_PADDLES_DATA = "SET_PADDLES_DATA";

// bids action types
export const SET_LOTS_VIEW_DATA = "SET_LOTS_VIEW_DATA";

// bids action types
export const SET_BIDS_DATA = "SET_BIDS_DATA";

// lot action types
export const SET_LOTS = "SET_LOTS";

// lot contracts
export const SET_CONTRACTS_IN_LOTS_LIST = "SET_CONTRACTS_IN_LOTS_LIST";

// documents
export const SET_BUYER_DOCUMENTS = "SET_BUYER_DOCUMENTS";
export const SET_PADDLE_LOTS = "SET_PADDLE_LOTS";
