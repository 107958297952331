import React, { useEffect } from "react";
// import { useEffect } from "react";
import { useState } from "react";
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// pass tabData in form of
// [ {
//   label: props.t("common.info"),
//   Component: InfoForm,
//   props: { isEditable: Boolean(id) },
//   disabled: false,
// },]

const TabsComponent = ({
  tabStyleClasses,
  tabData,
  initialActiveTabIndex = 0,
  HeaderCompoenent,
  setActiveTab,
  activeTab,
  controlledTabChange,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (initialActiveTabIndex) setActiveTabIndex(initialActiveTabIndex);
  }, [initialActiveTabIndex]);

  return (
    <CardBody>
      <Nav
        tabs
        className={`nav   mb-3 ${tabStyleClasses ? tabStyleClasses : "nav-tabs nav-border-top nav-border-top-primary"}`}
      >
        <>
          {tabData?.map((tab, index) => (
            <NavItem key={index} className="custom-nav-item">
              <NavLink
                style={{ cursor: "pointer" }}
                className={`${
                  controlledTabChange ? (activeTab === index ? "active" : "") : activeTabIndex === index ? "active" : ""
                } fs-16`}
                onClick={() => {
                  setActiveTabIndex(index);
                  if (setActiveTab) {
                    setActiveTab(index);
                  }
                }}
                disabled={tab?.disabled}
              >
                {tab?.label}
              </NavLink>
            </NavItem>
          ))}
          {HeaderCompoenent}
        </>
      </Nav>

      <TabContent activeTab={controlledTabChange ? activeTab.toString() : activeTabIndex.toString()}>
        {tabData?.map(({ Component, props = {} }, index) => (
          <TabPane tabId={index.toString()} id="nav-border-top-home" key={index}>
            {controlledTabChange ? (
              activeTab === index ? (
                <Component {...{ ...props, setActiveTabIndex }} />
              ) : null
            ) : activeTabIndex === index ? (
              <Component {...{ ...props, setActiveTabIndex }} />
            ) : null}
          </TabPane>
        ))}
      </TabContent>
    </CardBody>
  );
};

export default TabsComponent;
