import React, { useEffect, useState } from "react";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Formik validation

import { withTranslation } from "react-i18next";
import CustomButton from "../../../common/CustomButton";
import CustomDropDown from "../../../common/CustomDropDown";
import { toast } from "react-toastify";

const UploadMediaModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const [documents, setDocuments] = useState([]);

  //   to reset modal form.
  useEffect(() => {
    if (!isModalOpen) {
      setDocuments([]);
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      onCancel();
    }
  };

  const handleUploadMultiMedia = () => {
    if (documents?.length === 0) {
      toast.info(t("information.addAtleaseOneItem", { module: `${t("common.item")}` }));
      return;
    }
    onSave(documents);
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.addTitle", {
          module: t("common.multimedia"),
        })}
      </ModalHeader>

      <ModalBody>
        <div className="mb-3">
          <Label htmlFor="appraisal_photos" className="form-label">
            {`${t("common.multimedia")}`}
          </Label>
          <CustomDropDown
            multimedia
            previewContainerHeight={"212px"}
            grid={12}
            setFiles={setDocuments}
            files={documents}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <CustomButton color={"dark"} onClick={handleModalClose} btnTitle={t("common.cancel")} disabled={loader} />
        <CustomButton btnTitle={t("common.save")} isLoading={loader} onClick={handleUploadMultiMedia} />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(UploadMediaModal);
