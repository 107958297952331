import API from "./../../helpers/api/api_interceptor";
import { SET_CONTRACT_ITEM_LIST } from "./actionTypes";

export const getContractItemList = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/item`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CONTRACT_ITEM_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            all_ids: res?.data?.data?.all_ids,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
