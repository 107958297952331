import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addBid,
  addBids,
  distributeBids,
  exportDistributedBids,
  getBids,
  sendEmailNotifications,
  updateBid,
} from "../../../store/auctions/bidsActions";
import { getLotLists } from "../../../store/auctions/lotActions";
import ConfirmationModal from "../../common/ConfirmationModal";
import CustomAddButton from "../../common/CustomAddButton";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import AddBidModal from "./AddBidModal";
import BidsView from "./BidsView";
import LotsView from "./LotsView";
import AddBulkBidsModal from "./AddBulkBidsModal";
import CustomButton from "../../common/CustomButton";
import SellersNotificationsModal from "./SellersNotificationsModal";

const Bids = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const bidsViewRef = useRef(null);
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const [selectedBid, setSelectedBid] = useState(null);
  const [isBidModalOpen, setIsBidModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [bidsPayload, setBidsPayload] = useState();
  const [tableLoader, setTableLoader] = useState(false);
  const [lotTableLoader, setLotTableLoader] = useState(false);
  const [copyBid, setCopyBid] = useState(false);
  const [lotNoToView, setLotNoToView] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [distributeBidsLoader, setDistributesLoader] = useState(false);
  const [sendNotificationLoader, setSendNotificationLoader] = useState(false);

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: 10,
    sort: "lot_no",
    filter: { auction: id },
  });

  const [bulkBidsSaveLoader, setBulkBidsSaveLoader] = useState(false);
  const [isBulkBidsModalOpen, setIsBulkBidsModalOpen] = useState(false);

  // handle sellers notification modal open
  const handleSendNotificationModalOpen = () => {
    setIsNotificationModalOpen(true);
  };
  // handle sellers notification modal close
  const handleSendNotificationModalClose = () => {
    setIsNotificationModalOpen(false);
  };

  // handle bulk bids modal open
  const handleBulkBidsModalOpen = () => {
    setIsBulkBidsModalOpen(true);
  };
  // handle bulk bids modal close
  const handleBulkBidsModalClose = () => {
    setIsBulkBidsModalOpen(false);
  };
  // handle bulk bids save
  const handleBulkBidsModalSave = (values) => {
    const payload = {
      auction: id,
      customer: values?.customer?.id,
      bidding_origin: values?.bidding_origin,
      employee_preference: values?.employee_preference,
      languages_speaks: values?.languages_speaks,
      bids: values?.bidsData?.map((bid) => ({
        auction_lot: bid?.auction_lot?.id,
        bidding_type: bid?.bidding_type,
        value: bid?.value,
        contact_1: bid?.contact_1,
        contact_2: bid?.contact_2,
      })),
    };
    setBulkBidsSaveLoader(true);
    dispatch(addBids(payload))
      .then((res) => {
        if (res) {
          handleGetBids(bidsPayload);
          handleGetLots(lotsPayload);
          handleBulkBidsModalClose();
        }
        setBulkBidsSaveLoader(false);
      })
      .catch((error) => {
        setBulkBidsSaveLoader(false);
        console.log(error);
      });
  };

  // handle get bids
  const handleGetBids = useCallback(
    (bidsPayload) => {
      setTableLoader(true);
      dispatch(getBids(bidsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // handle confirmation modal open
  const handleConfirmationModalOpen = () => {
    setIsConfirmationModalOpen(true);
  };
  // handle confirmation modal close
  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };
  const handleConfirm = () => {
    handleSaveBid(selectedBid, true);
  };

  // to handle bid modal open for adding/editing
  const handleBidModalOpen = (bidData, copyBid) => {
    setCopyBid(copyBid);
    setSelectedBid(bidData);
    setIsBidModalOpen(true);
  };
  // to handle bid modal close for adding/editing
  const handleBidModalClose = () => {
    setIsBidModalOpen(false);
    setSelectedBid(null);
    setCopyBid(false);
  };

  // get lot list
  const handleGetLots = () => {
    setLotTableLoader(true);
    dispatch(getLotLists(lotsPayload))
      .then((res) => {
        setLotTableLoader(false);
      })
      .catch((error) => {
        setLotTableLoader(false);
        console.log(error);
      });
  };

  // to handle save bid
  const handleSaveBid = (values, isConfirm) => {
    const payload = {
      ...values,
      auction: id,
      customer: values?.customer?.id ? values?.customer?.id : values?.customer,
      auction_lot: values?.auction_lot?.id ? values?.auction_lot?.id : values?.auction_lot,
    };
    if (payload?.bidding_type === "telephone") {
      payload.value = null;
    } else {
      payload.languages_speaks = [];
      payload.contact_1 = "";
      payload.contact_2 = "";
    }
    // check for add or edit
    if (selectedBid && selectedBid?.id && !copyBid) {
      // edit bid
      setSaveLoader(true);
      dispatch(updateBid(selectedBid?.id, payload))
        .then((res) => {
          if (res) {
            handleBidModalClose();
            handleGetBids(bidsPayload);
            handleGetLots(lotsPayload);
          } else {
            // failure
          }
          setSaveLoader(false);
        })
        .catch((errror) => {
          setSaveLoader(false);
        });
    } else {
      // add bid
      setSaveLoader(true);
      dispatch(addBid({ ...payload, is_agree: isConfirm || false }))
        .then((res) => {
          if (res?.status === 200) {
            handleConfirmationModalClose();
            handleBidModalClose();
            handleGetBids(bidsPayload);
            handleGetLots(lotsPayload);
            toast.success(res?.data?.detail);
          } else if (res?.status === 201) {
            delete payload?.id;
            delete payload?.status;
            setSelectedBid(payload);
            setConfirmationText(res?.data?.detail);
            handleConfirmationModalOpen();
          } else {
            // failure
          }
          setSaveLoader(false);
        })
        .catch((errror) => {
          setSaveLoader(false);
        });
    }
  };

  // handle distribute bids
  const handleDistributeBids = () => {
    if (id) {
      setDistributesLoader(true);
      dispatch(distributeBids(id)).then(() => {
        setDistributesLoader(false);
      });
    }
  };

  // handle export distributed bids
  const handleExportDistributeBids = () => {
    if (id) {
      setDistributesLoader(true);
      dispatch(exportDistributedBids(id)).then(() => {
        setDistributesLoader(false);
      });
    }
  };
  // handle send notifications
  const handleSendNotifications = (userType) => {
    if (id) {
      setSendNotificationLoader(true);
      dispatch(sendEmailNotifications(id, { user_type: userType })).then(() => {
        setSendNotificationLoader(false);
      });
    }
  };
  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-2 gap-2">
        {auctionData?.auction_type?.auction_type !== "Online" ? (
          <>
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.bids"),
              })}
              onClick={handleBulkBidsModalOpen}
            />
            <CustomTooltipWrapper target={"distribute-btn"} tooltipBody={props.t("information.distributeButton")} />
            <CustomDropdownButton
              id="distribute-btn"
              btnTitle={props.t("common.distribute")}
              isLoading={distributeBidsLoader}
              options={[
                {
                  label: `${props.t("common.bids")}`,
                  onClick: () => {
                    handleDistributeBids();
                  },
                },
                {
                  label: `${props.t("common.export")} ${props.t("common.distribution")}`,
                  onClick: () => {
                    handleExportDistributeBids();
                  },
                },
              ]}
            />
          </>
        ) : null}
        <CustomDropdownButton
          btnTitle={props.t("common.sendNotification")}
          isLoading={sendNotificationLoader}
          id={"send-notifications"}
          options={[
            {
              label: `${props.t("common.bidders")}`,
              onClick: () => {
                handleSendNotifications("bidders");
              },
            },
            {
              label: `${props.t("common.sellers")}`,
              onClick: handleSendNotificationModalOpen,
            },
          ]}
        />
        <CustomTooltipWrapper
          target={"send-notifications"}
          tooltipBody={props.t("information.sendNotificationButton")}
        />
      </div>
      <BidsView
        bidsViewRef={bidsViewRef}
        lotNo={lotNoToView}
        setLotNoToView={setLotNoToView}
        key="bids-view"
        setBidsViewPayload={setBidsPayload}
        isLoading={tableLoader}
        editBid={handleBidModalOpen}
      />
      <LotsView bidsViewRef={bidsViewRef} key="lots-view" setLotNoToView={setLotNoToView} isLoading={lotTableLoader} />
      {/* bulk bids modal */}
      <AddBulkBidsModal
        loader={bulkBidsSaveLoader}
        isModalOpen={isBulkBidsModalOpen}
        onCancel={handleBulkBidsModalClose}
        onSave={handleBulkBidsModalSave}
      />
      {/* bid modal */}
      <AddBidModal
        copyBid={copyBid}
        loader={saveLoader}
        bid={selectedBid}
        isModalOpen={isBidModalOpen}
        onCancel={handleBidModalClose}
        onSave={handleSaveBid}
      />
      {/* confirmation modal */}
      <ConfirmationModal
        loader={saveLoader}
        isModalOpen={isConfirmationModalOpen}
        onCloseBtnHandler={handleConfirmationModalClose}
        body={confirmationText}
        onConfirmBtnHandler={handleConfirm}
      />
      {/* sellers notifications modal */}
      <SellersNotificationsModal isModalOpen={isNotificationModalOpen} onCancel={handleSendNotificationModalClose} />
    </>
  );
};

export default withTranslation()(Bids);
