import API from "../../helpers/api/api_interceptor";

export const getAuctionReports = (auctionId, params) => async (dispatch) => {
  try {
    return API.get(`/reports/auctions/${auctionId}`, { params: params }).then((res) => {
      if (res && res.status && res.status === 200) {
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSalesReports = (params) => async (dispatch) => {
  try {
    return API.get(`/reports/sales`, { params: params }).then((res) => {
      if (res && res.status && res.status === 200) {
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
