import { SET_REPORT_DETAILS } from "./actionType";

const INIT_STATE = {
  data: [],
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_REPORT_DETAILS:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default User;
