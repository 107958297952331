import { ADD_PERIOD, DELETE_PERIOD, SET_PERIODS_DATA, UPDATE_PERIOD } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Periods = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PERIODS_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_PERIOD:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_PERIOD:
      const existingPeriodIndex = state.data.findIndex((artist) => artist.id === action.payload.id);
      const updatedPeriodsData = state.data;
      if (existingPeriodIndex !== -1) {
        updatedPeriodsData[existingPeriodIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedPeriodsData,
      };
    case DELETE_PERIOD:
      return {
        ...state,
        data: [...state.data.filter((period) => period.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default Periods;
