import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { FAQ_LANGUAGE_TYPES, TYPE_OF_FAQS } from "../../../../helpers/constants/selectOptions";
import { getTypeFromValue, scrollToFirstFormikError } from "../../../../helpers/utils";
import { useSelector } from "react-redux";
import NumberInput from "../../../common/NumberInput";

const AddFAQModal = ({ FAQ, isModalOpen, onCancel, onSave, loader, t }) => {
  const { suggestions } = useSelector((state) => state?.FAQs);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      question: FAQ?.question || "",
      answer: FAQ?.answer || "",
      sequence_order: (FAQ?.id ? FAQ?.sequence_order : "") || "",
      faq_type: FAQ?.faq_type || "",
      language_type: FAQ?.language_type || "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.question"),
        })}`
      ),
      answer: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.answer"),
        })}`
      ),
      faq_type: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.select"),
          fieldType: t("common.FAQType"),
        })}`
      ),
      language_type: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.select"),
          fieldType: t("common.languageType"),
        })}`
      ),
      sequence_order: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.sequenceOrder"),
        })}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });

  // to prefill suggestions
  useEffect(() => {
    if (!FAQ?.id && validation?.values?.faq_type && validation?.values?.language_type) {
      validation?.setFieldValue(
        "sequence_order",
        suggestions?.[`next_${validation?.values?.faq_type}_${validation?.values?.language_type}`] || ""
      );
    }
  }, [validation?.values?.faq_type, validation?.values?.language_type, suggestions, FAQ]);

  // reset form
  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {FAQ?.id
          ? t("common.editTitle", { module: t("common.FAQ") })
          : t("common.addTitle", { module: t("common.FAQ") })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label className="form-label">{t("common.question")}</Label>
            <textarea
              name="question"
              className={`form-control ${
                validation.touched.question && validation.errors.question ? "input-field-error" : ""
              }`}
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.question"),
              })}`}
              rows={4}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.question}
              invalid={validation.touched.question && validation.errors.question ? true : false}
            />
            {validation.touched.question && validation.errors.question ? (
              <p className="custom-invalid-feedback">{validation.errors.question}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">{t("common.answer")}</Label>
            <textarea
              name="answer"
              className={`form-control ${
                validation.touched.answer && validation.errors.answer ? "input-field-error" : ""
              }`}
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.answer"),
              })}`}
              rows={4}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.answer}
              invalid={validation.touched.answer && validation.errors.answer ? true : false}
            />
            {validation.touched.answer && validation.errors.answer ? (
              <p className="custom-invalid-feedback">{validation.errors.answer}</p>
            ) : null}
          </div>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("common.FAQType")}</Label>
                <Select
                  name="faq_type"
                  options={TYPE_OF_FAQS}
                  className={`custom-select ${
                    validation.touched.faq_type && validation.errors.faq_type ? "select-error" : ""
                  }`}
                  placeholder={`${t("placeholder.selectField", {
                    fieldType: t("common.FAQType"),
                  })}`}
                  value={getTypeFromValue(TYPE_OF_FAQS, validation.values.faq_type) || ""}
                  onChange={(faq_type) => {
                    validation.setFieldValue("faq_type", faq_type?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("faq_type", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.faq_type && validation.errors.faq_type ? (
                  <p className="custom-invalid-feedback">{validation.errors.faq_type}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("common.languageType")}</Label>
                <Select
                  name="language_type"
                  options={FAQ_LANGUAGE_TYPES}
                  className={`custom-select ${
                    validation.touched.language_type && validation.errors.language_type ? "select-error" : ""
                  }`}
                  placeholder={`${t("placeholder.selectField", {
                    fieldType: t("common.languageType"),
                  })}`}
                  value={getTypeFromValue(FAQ_LANGUAGE_TYPES, validation.values.language_type) || ""}
                  onChange={(language_type) => {
                    validation.setFieldValue("language_type", language_type?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("language_type", true);
                    validation.handleBlur(e);
                  }}
                />

                {validation.touched.language_type && validation.errors.language_type ? (
                  <p className="custom-invalid-feedback">{validation.errors.language_type}</p>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <Label className="form-label">{t("common.sequenceOrder")}</Label>
            <NumberInput
              name="sequence_order"
              className="form-control"
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.sequenceOrder"),
              })}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.sequence_order}
              invalid={validation.touched.sequence_order && validation.errors.sequence_order ? true : false}
            />
            {validation.touched.sequence_order && validation.errors.sequence_order ? (
              <FormFeedback type="invalid">{validation.errors.sequence_order}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddFAQModal);
