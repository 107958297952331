/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { Badge, Button, Card, CardHeader, Input } from "reactstrap";

import { withTranslation } from "react-i18next";
import CustomAddButton from "../../components/common/CustomAddButton";
import CustomSearchInput from "../../components/common/CustomSearchInput";
import DeleteModal from "../../components/common/DeleteModal";
import Loader from "../../components/common/Loader";
import NoRecords from "../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS } from "../../helpers/constants";
import { updateBulkUser } from "../../store/customers/actions";

import { useHistory } from "react-router-dom";
import CustomColumnHeader from "../../components/common/CustomColumnHeader";
import CustomMultiSelect from "../../components/common/CustomMultiSelect";
import CustomTooltipWrapper from "../../components/common/CustomTooltipWrapper";
import UpdateBulkUserModal from "../../components/pages/customers/UpdateBulkUserModal";
import { AUCTION_STATUS_OPTIONS, SELECT_ALL_OPTION } from "../../helpers/constants/selectOptions";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  generateContrastColor,
  getAuctionStatus,
  getDateInDDMMMYYYYFormat,
  getDateInYYYYMMDDFormat,
  getDateString,
  getLabelValueArray,
  getValuesFromArray,
} from "../../helpers/utils";

import i18next from "i18next";
import CustomColumnPhoto from "../../components/common/CustomColumnPhoto";
import CustomLinkWrapper from "../../components/common/CustomLinkWrapper";
import CustomTagChip from "../../components/common/CustomTagChip";
import DateRangeInput from "../../components/common/DateRangeInput";
import { DEFAULT_ROW_PER_PAGE } from "../../helpers/constants";
import { getAuctionTheme } from "../../store/auction-theme/actions";
import { getAuctionTypes } from "../../store/auction-types/actions";
import { deleteAuction, getAuctionList } from "../../store/auctions/actions";
import { getLocations } from "../../store/locations/actions";

const INITIAL_GET_AUCTIONS_PAYLOAD = {
  search: "",
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  sort: "",
  filter: {},
};

const Auctions = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getColumnDetail = (enCol, ptCol) => {
    return i18next.resolvedLanguage !== "en" ? ptCol : enCol;
  };

  // handle row selection
  const handleRowSelection = (rowId) => {
    const isSelected = Boolean(selectedRows.find((item) => item === rowId));

    if (isSelected) {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setIsAllSelected(false);
    } else {
      setSelectedRows((prevState) => [...prevState, rowId]);
    }
  };

  const { data: auctionData, total: totalAuctions } = useSelector((state) => state?.Auctions);

  const THEME_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.AuctionTheme?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "description_en" : "description_pt"
  );
  const LOCATION_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Locations?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "location_name_en" : "location_name_pt"
  );

  const AUCTION_TYPES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.AuctionTypes?.data),
    "id",
    "auction_type"
  );

  const [auctionsPayload, setAuctionsPayload] = useState({
    ...INITIAL_GET_AUCTIONS_PAYLOAD,
    sort: "-auction_no",
  });

  const auctionThemePayload = {
    ...INITIAL_GET_AUCTIONS_PAYLOAD,
    sort: i18next.resolvedLanguage === "en" ? "description_en" : "description_pt",
  };

  const auctionTypesPayload = {
    ...INITIAL_GET_AUCTIONS_PAYLOAD,
    sort: "auction_type",
  };

  const auctionLocationPayload = {
    ...INITIAL_GET_AUCTIONS_PAYLOAD,
    sort: getColumnDetail("location_name_en", "location_name_pt"),
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectedAuctionType, setSelectedAuctionType] = useState([]);
  const [selectedAuctionTheme, setSelectedAuctionTheme] = useState([]);
  const [selectedAuctionLocation, setSelectedAuctionLocation] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isBulkUpdateUserModalOpen, setIsBulkUpdateUserModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDateStatus, setSelectedDateStatus] = useState([]);
  const [clearSearch, setClearSearch] = useState();

  const handleClearFilters = () => {
    setAuctionsPayload(INITIAL_GET_AUCTIONS_PAYLOAD);
    setSelectedAuctionType([]);
    setSelectedAuctionTheme([]);
    setSelectedAuctionLocation([]);
    setSelectedDateRange(null);
    setSelectedDateStatus([]);
    setIsAllSelected(false);
    setSelectedRows([]);
    setClearSearch(true);
  };

  // get auction theme, auction type and location
  useEffect(() => {
    dispatch(getAuctionTheme(auctionThemePayload));
    dispatch(getAuctionTypes(auctionTypesPayload));
    dispatch(getLocations(auctionLocationPayload));
  }, [dispatch]);

  //   navigate on auction from
  const navigateOnAuctionForm = (id) => {
    if (id) {
      history.push(`/auctions/edit/${id}`);
    } else {
      history.push("/auctions/new");
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteAuction = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteAuction({
          id: selectedItem?.id,
        })
      )
        .then((res) => {
          if (res) {
            setDeleteLoader(false);
            handleDeleteModalClose();
            handleGetAuctions(auctionsPayload);
          } else {
            setDeleteLoader(false);
          }
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setAuctionsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setAuctionsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setAuctionsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return {
          ...tempPayload,
          page: 1,
          filter: {
            ...tempPayload.filter,
            [`${filterKey}__isnull`]: false,
          },
        };
      });
    } else {
      if (values.length > 0) {
        setAuctionsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[`${filterKey}__isnull`];
          return {
            ...tempPayload,
            filter: {
              ...tempPayload.filter,
              [`${filterKey}__in`]: getValuesFromArray(values),
            },
            page: 1,
          };
        });
      } else {
        setAuctionsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          delete tempPayload.filter[`${filterKey}__in`];
          delete tempPayload.filter[`${filterKey}__isnull`];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setAuctionsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get products and services
  const handleGetAuctions = useCallback(
    (auctionsPayload) => {
      setTableLoader(true);
      dispatch(getAuctionList(auctionsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetAuctions(auctionsPayload);
  }, [auctionsPayload, handleGetAuctions]);

  const handleBulkUserModalClose = () => {
    setIsBulkUpdateUserModalOpen(false);
    setIsUpdatingUser(false);
  };

  const handleUpdateBulkUser = (values, formikValues) => {
    setIsUpdatingUser(true);
    dispatch(
      updateBulkUser({
        search: auctionsPayload?.search,
        filter: auctionsPayload?.filter,
        isAllSelected: isAllSelected,
        ids: selectedRows,
        is_active: values?.is_active,
      })
    )
      .then((res) => {
        if (res) {
          handleGetAuctions(auctionsPayload);
          handleBulkUserModalClose();
        } else {
          setIsUpdatingUser(false);
        }
      })
      .catch((error) => {
        setIsUpdatingUser(false);
        handleBulkUserModalClose();
        console.log(error);
      });
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleDateStatusFilter = (selectedItem) => {
    if (selectedItem) {
      setAuctionsPayload((prevState) => {
        return {
          ...prevState,
          filter: { ...prevState.filter, date_status: selectedItem?.value },
        };
      });
    } else {
      setAuctionsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.date_status;

        return {
          ...tempPayload,
        };
      });
    }
  };

  // handle date filter
  useEffect(() => {
    if (selectedDateRange) {
      setAuctionsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          auction_start_date__range: [
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)),
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)),
          ],
        },
      }));
    } else {
      setAuctionsPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__range;
        return { ...prevState };
      });
    }
  }, [selectedDateRange]);

  const columns = [
    // {
    //   name: (
    //     <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
    //       <Input
    //         type="checkbox"
    //         checked={isAllSelected}
    //         onChange={(event) => {
    //           setIsAllSelected(event.target.checked);
    //           setSelectedRows(event.target.checked ? auctionData.map((row) => row.id) : []);
    //         }}
    //       />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <Input
    //       type="checkbox"
    //       checked={Boolean(selectedRows.find((item) => item === row.id))}
    //       onChange={() => handleRowSelection(row.id)}
    //     />
    //   ),
    //   width: "50px",
    //   center: true,
    // },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.auctionNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_no__icontains"
            className="column-search-input"
            payload={auctionsPayload}
            setPayload={setAuctionsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.auction_no ? row?.auction_no : "-"),
      width: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.auction_photo} title={<CustomColumnPhoto sourceUrl={row?.auction_photo} />} />
      ),
      width: "120px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.dates")}</div>
          <DateRangeInput
            classes={"column-date-range"}
            inputValue={
              selectedDateRange
                ? `${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.startDate))}  ${props.t(
                    "common.to"
                  )}  ${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.endDate))}`
                : ""
            }
            rangeValue={selectedDateRange}
            onChange={(values) => {
              setSelectedDateRange(values);
            }}
            onClear={() => {
              setSelectedDateRange(null);
            }}
          />
        </div>
      ),
      selector: (row) =>
        `${getDateInDDMMMYYYYFormat(new Date(getDateString(row?.auction_start_date)))} -  ${getDateInDDMMMYYYYFormat(
          new Date(getDateString(row?.auction_end_date))
        )}`,
      width: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_en__icontains", "title_pt__icontains")}
            className="column-search-input"
            payload={auctionsPayload}
            setPayload={setAuctionsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.title_en, row?.title_pt),
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.auctions.locations.location")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...LOCATION_OPTIONS]}
            selectedOptions={selectedAuctionLocation}
            handleOnSelection={(values) => {
              setSelectedAuctionLocation(values);
              handleDropdownFilter(values, "auction_location", setSelectedAuctionLocation);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getColumnDetail(row?.auction_location?.location_name_en, row?.auction_location?.location_name_pt) || "-",
      // selector: (row) => row?.auction_location?.location_name,
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.type")}`}
            sortable
            sortableKey="auction_type__auction_type"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...AUCTION_TYPES_OPTIONS]}
            selectedOptions={selectedAuctionType}
            handleOnSelection={(values) => {
              setSelectedAuctionType(values);
              handleDropdownFilter(values, "auction_type", setSelectedAuctionType);
            }}
          />
        </div>
      ),
      selector: (row) => row?.auction_type?.auction_type,
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.theme")}`}
            sortable
            sortableKey={getColumnDetail("auction_theme__description_en", "auction_theme__description_pt")}
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...THEME_OPTIONS]}
            selectedOptions={selectedAuctionTheme}
            handleOnSelection={(values) => {
              setSelectedAuctionTheme(values);
              handleDropdownFilter(values, "auction_theme", setSelectedAuctionTheme);
            }}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.auction_theme?.description_en, row?.auction_theme?.description_pt),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.totalLots")}</div>
          <CustomSearchInput
            inputType={"number"}
            columnWise={true}
            columnSearchKey="lots_count"
            className="column-search-input"
            payload={auctionsPayload}
            setPayload={setAuctionsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => row?.lots_count,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.status")}`}</div>
          <Select
            placeholder={props.t("common.select")}
            className="custom-select"
            isMulti={false}
            components={{ Option }}
            isClearable
            options={[...AUCTION_STATUS_OPTIONS]}
            value={selectedDateStatus}
            onChange={(selectedItem) => {
              if (selectedItem === null) {
                setSelectedDateStatus("");
              }
              setSelectedDateStatus(selectedItem);
              handleDateStatusFilter(selectedItem);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.date_status !== null && row?.date_status !== "" ? (
          <div className="d-flex flex-column gap-1 m-2 tag-status">
            {
              <CustomTagChip
                title={getAuctionStatus(AUCTION_STATUS_OPTIONS, row?.date_status)?.label}
                bgColor={getAuctionStatus(AUCTION_STATUS_OPTIONS, row?.date_status)?.color}
                textColor={generateContrastColor(
                  getAuctionStatus(AUCTION_STATUS_OPTIONS, row?.date_status)?.color || "#000"
                )}
              />
            }
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.buyer'sPremium")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="buyer_premium__icontains"
            className="column-search-input"
            payload={auctionsPayload}
            setPayload={setAuctionsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => row?.buyer_premium,
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {/* <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              navigateOnEditCustomerForm(row?.id);
            }}
          >
            <CustomTooltipWrapper
              target={`tooltip-${row?.id}-edit`}
              tooltipBody={`${props.t("common.edit")}`}
            />
            <i
              className="ri-pencil-fill fs-18"
              id={`tooltip-${row?.id}-edit`}
            />
          </button> */}
          <button
            type="button"
            className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
              checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
            }`}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
            style={{
              outline: "none",
              border: "none",
              opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
              cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i
              className={`ri-delete-bin-fill fs-18 ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
              }`}
              id={`tooltip-${row?.id}-delete`}
              style={{
                color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                border: "none",
              }}
            />
          </button>
        </div>
      ),
      //   width: "8%",
      width: "100px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.auctions")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={auctionsPayload}
            setPayload={setAuctionsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            disabled={tableLoader}
          />

          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalAuctions > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalAuctions}
                </Badge>
              </Button>
            ) : null}
            <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
              {props.t("common.clearFilters")}
            </Button>
            <CustomAddButton
              color={"success"}
              onClick={() => navigateOnAuctionForm()}
              btnTitle={`${props.t("common.add")} ${props.t("common.auction")}`}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.auctions")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...auctionData]}
            pagination
            paginationServer
            paginationTotalRows={totalAuctions}
            paginationDefaultPage={auctionsPayload?.page}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            pointerOnHover={true}
            onRowClicked={(row) => {
              navigateOnAuctionForm(row?.id);
            }}
          />
        </Card>
      </div>

      {/* bulk update user */}
      <UpdateBulkUserModal
        isModalOpen={isBulkUpdateUserModalOpen}
        onCancel={handleBulkUserModalClose}
        onSave={handleUpdateBulkUser}
        loader={isUpdatingUser}
      />

      {/* delete modal */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("common.auction")}`}
        body={props.t("confirmation.auctionDeleteMessage")}
        actionBtnTitle={`${props.t("common.delete")}`}
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onConfirmBtnHandler={handleDeleteAuction}
        onCloseBtnHandler={handleDeleteModalClose}
      />
    </>
  );
};

export default withTranslation()(Auctions);
