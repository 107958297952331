import { ADD_PAYMENTTYPE, DELETE_PAYMENTTYPE, SET_PAYMENTTYPES_DATA, UPDATE_PAYMENTTYPE } from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
};

const PaymentTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAYMENTTYPES_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_PAYMENTTYPE:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_PAYMENTTYPE:
      const updatePaymentTypesData = state.data;
      const existingPaymentTypeIndex = state.data.findIndex((paymentType) => paymentType?.id === action.payload.id);
      if (existingPaymentTypeIndex !== -1) {
        updatePaymentTypesData[existingPaymentTypeIndex] = action.payload;
      }
      return {
        ...state,
        data: updatePaymentTypesData,
      };
    case DELETE_PAYMENTTYPE:
      return {
        ...state,
        data: [...state.data.filter((paymentType) => paymentType?.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default PaymentTypes;
