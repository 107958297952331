import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";

import DeleteModal from "../../../../components/common/DeleteModal";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";

import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";

import CustomTagChip from "../../../../components/common/CustomColorTagChip";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import AddAuctionTagsModal from "../../../../components/pages/settings/AddAuctionTagsModal";
import { generateContrastColor, getColumnDetail } from "../../../../helpers/utils";
import {
  addAuctionTags,
  deleteAuctionTags,
  editAuctionTags,
  getAuctionTags,
} from "../../../../store/auction-tags/actions";

const AuctionTags = ({ t }) => {
  const dispatch = useDispatch();
  const { data: auctionTagsData, total: totalAuctionTags } = useSelector((state) => state.AuctionTags);

  const [auctionTagsListLoader, setAuctionTagsListLoader] = useState(false);
  const [isDeleteAuctionTagsModalOpen, setIsDeleteAuctionTagsModalOpen] = useState(false);
  const [isAddActionTagsModalOpen, setIsAddAuctionTagsModalOpen] = useState(false);
  const [auctionTagDeleteLoader, setAuctionTagDeleteLoader] = useState(false);
  const [auctionTagSaveLoader, setAuctionTagSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [auctionTagsPayload, setAuctionTagsPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: {
      id__icontains: "",
      [`${getColumnDetail("tag_label", "tag_label_pt")}__icontains`]: "",
      tag_name__icontains: "",
    },
  });

  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // setting title of the page
  document.title = `Veritas | ${t("sidebarMenu.auctionTags")}`;

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setAuctionTagsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handling auction tag list
  const handleGetAuctionList = useCallback(
    (auctionTagsPayload) => {
      setAuctionTagsListLoader(true);
      dispatch(getAuctionTags(auctionTagsPayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setAuctionTagsListLoader(false);
        })
        .catch((error) => {
          setAuctionTagsListLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetAuctionList(auctionTagsPayload);
  }, [auctionTagsPayload, handleGetAuctionList]);

  const handleConfirmBtn = () => {
    setAuctionTagDeleteLoader(true);
    dispatch(deleteAuctionTags(selectedRow))
      .then((res) => {
        if (res) {
          //
          handleGetAuctionList(auctionTagsPayload);
          setIsDeleteAuctionTagsModalOpen(false);
        } else {
          //
        }
        setAuctionTagDeleteLoader(false);
      })
      .catch((error) => {
        setAuctionTagDeleteLoader(false);
        setIsDeleteAuctionTagsModalOpen(false);
      });
  };

  const handleSaveAuctoinTag = (values, validation) => {
    setAuctionTagSaveLoader(true);
    if (selectedRow && selectedRow.id) {
      // dispatch for editing page
      dispatch(editAuctionTags({ id: selectedRow.id, values }))
        .then((res) => {
          if (res) {
            handleGetAuctionList(auctionTagsPayload);
            validation.resetForm();
            setIsAddAuctionTagsModalOpen(false);
            //
          } else {
            //
          }
          setAuctionTagSaveLoader(false);
        })
        .catch((error) => {
          setIsAddAuctionTagsModalOpen(false);
          setAuctionTagSaveLoader(false);
        });
    } else {
      // dispatch for adding new language
      dispatch(addAuctionTags(values))
        .then((res) => {
          if (res) {
            handleGetAuctionList(auctionTagsPayload);
            validation.resetForm();
            setIsAddAuctionTagsModalOpen(false);
            //
          } else {
            //
          }
          setAuctionTagSaveLoader(false);
        })
        .catch((error) => {
          setIsAddAuctionTagsModalOpen(false);
          setAuctionTagSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setAuctionTagsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setAuctionTagsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const auctionTagDeleteHandler = (row) => {
    setIsDeleteAuctionTagsModalOpen(true);
    setSelectedRow(row.id);
  };

  const auctionTagEditHandler = (row) => {
    setIsAddAuctionTagsModalOpen(true);
    setSelectedRow(row);
  };

  const handleCloseBtn = () => {
    setIsDeleteAuctionTagsModalOpen(false);
  };

  const handleAddAuctionTagModalOpen = () => {
    setSelectedRow(null);
    setIsAddAuctionTagsModalOpen(true);
  };

  const handleAuctionTagModalClose = () => {
    setIsAddAuctionTagsModalOpen(false);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={auctionTagsPayload}
            setPayload={setAuctionTagsPayload}
          />
        </div>
      ),
      selector: (row) => row.id,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.tag")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={`${getColumnDetail("tag_label", "tag_label_pt")}__icontains`}
            className="column-search-input"
            payload={auctionTagsPayload}
            setPayload={setAuctionTagsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <CustomTagChip
          title={getColumnDetail(row?.tag_label, row?.tag_label_pt)}
          bgColor={row?.tag_color}
          textColor={generateContrastColor(row?.tag_color)}
        />
      ),
      minWidth: "200px",
      wrap: true,
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          {/* <div className="table-column-title mb-2">
            {t("common.description")}
          </div> */}
          <CustomColumnHeader
            columnTitle={t("common.description")}
            sortable
            sortableKey="tag_name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="tag_name__icontains"
            className="column-search-input"
            payload={auctionTagsPayload}
            setPayload={setAuctionTagsPayload}
          />
        </div>
      ),
      selector: (row) => row.tag_name,

      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            <div className="table-actions">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => auctionTagEditHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={t("common.edit")} />
                <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
              </button>
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => auctionTagDeleteHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={t("common.delete")} />
                <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
              </button>
            </div>
          </div>
        </div>
      ),
      minWidth: "150px",
      wrap: true,
      center: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={auctionTagsPayload}
            setPayload={setAuctionTagsPayload}
          />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleAddAuctionTagModalOpen}
              btnTitle={`${t("common.addTitle", {
                module: `${t("settings.auctions.auctionTags.auctionTag")}`,
              })}`}
            />
          </div>
        </div>
        <Card className="container-body">
          {auctionTagsListLoader ? <Loader /> : null}
          <CardHeader className="table-title">{t("sidebarMenu.auctionTags")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...auctionTagsData]}
            pagination
            paginationServer
            paginationTotalRows={totalAuctionTags}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* to add /edit Auction Tags */}
      <AddAuctionTagsModal
        isModalOpen={isAddActionTagsModalOpen}
        auctionTag={selectedRow}
        // auctionTag={{
        //   tag: "adasd",
        //   tag_color: "#697689",
        //   tag_name: "tag name",
        // }}
        onCancel={handleAuctionTagModalClose}
        onSave={handleSaveAuctoinTag}
        loader={auctionTagSaveLoader}
      />

      {/* delete modal for Auction Tags */}
      <DeleteModal
        title={`${t("common.delete")} ${t("settings.auctions.auctionTags.auctionTag")}`}
        body={t("confirmation.deleteMessage", {
          module: t("settings.auctions.auctionTags.auctionTag"),
        })}
        actionBtnTitle={`${t("common.delete")}`}
        loader={auctionTagDeleteLoader}
        isModalOpen={isDeleteAuctionTagsModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />
    </>
  );
};

export default withTranslation()(AuctionTags);
