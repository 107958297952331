import { ADD_CURRENCY, DELETE_CURRENCY, SET_CURRENCIES_DATA, UPDATE_CURRENCY } from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Currencies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENCIES_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_CURRENCY:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_CURRENCY:
      const existingCurrencyIndex = state.data.findIndex((currency) => currency.id === action.payload.id);
      const updatedCurrenciesData = state.data;
      if (existingCurrencyIndex !== -1) {
        updatedCurrenciesData[existingCurrencyIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedCurrenciesData,
      };
    case DELETE_CURRENCY:
      return {
        ...state,
        data: [...state.data.filter((item) => item.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default Currencies;
