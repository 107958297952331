import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT, SALES_NOTE } from "../../../helpers/constants";
import {
  calculateInsuranceForLots,
  calculateTotalForDocumentLine,
  getColumnDetail,
  getContractOptionLabel,
  getExpenseDescription,
  getLotDescriptionForInvoice,
  onKeyPressForm,
  roundTo,
  scrollToFirstFormikError,
  uniqBy,
} from "../../../helpers/utils";
import { getContractItems, getContracts } from "../../../store/contracts/actions";
import { getVATs } from "../../../store/vat/actions";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";
import NumberInput from "../../common/NumberInput";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";

const SalesNoteDocumentLineModal = ({
                                      doNotApplyVat,
                                      selectedItems,
                                      selectedExpenses,
                                      selectedCustomer,
                                      addDocumentLine,
                                      isModalOpen,
                                      handleModalClose,
                                      validation: propsValidation,
                                      t,
                                    }) => {
  const dispatch = useDispatch();
  const CONTRACT_OPTIONS = useSelector((state) => state?.Contracts?.data);
  const VAT_OPTIONS = useSelector((state) => state?.VAT.data);
  const DEFAULT_VAT_OPTION = useSelector((state) => state?.common?.vats?.default);

  const { contractItems, totalItems } = useSelector((state) => state?.Contracts);
  const [tableContractItems, setTableContractItems] = useState(structuredClone(uniqBy(contractItems, "id")));

  const [saveLoader, setSaveLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [contractSearchLoader, setContractSearchLoader] = useState(false);
  const [contractsPayload, setContractsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "customer__name",
    is_paid_expense: false,
    filter: selectedCustomer ? { customer: selectedCustomer } : {},
  });
  const [vatSearchLoader, setVatSearchLoader] = useState(false);
  const [vatPayload, setVatPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "code",
  });
  const [itemsPayload, setItemsPayload] = useState({
    page: 1,
    sort: "position_contract",
    is_paid_expense: false,
    filter: {
      is_paid: true,
    },
  });
  const [masterPremium, setMasterPremium] = useState(+propsValidation?.values?.customer?.suggested_premium || 0);
  const [premiumsCache, setPremiumsCache] = useState({});

  // handle contract search
  const handleContractSearch = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_list__icontains: value },
    }));
  };
  // debounce for contract search
  const contractSearchDebounce = useMemo(() => {
    return debounce(handleContractSearch, DEBOUNCE_TIME);
  }, []);

  // handle vat search
  const handleVatSearch = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_code__icontains: value },
    }));
  };
  // debounce for vat search
  const vatSearchDebounce = useMemo(() => {
    return debounce(handleVatSearch, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setTableContractItems([]);
      setPremiumsCache({});
    }

    setTableContractItems(() => {
      return structuredClone(uniqBy(contractItems, "id"))?.map?.((item) => ({
        ...item,
        premium: +(premiumsCache[item.id] ?? item?.premium ?? masterPremium) || 0,
      }));
    });
  }, [contractItems, isModalOpen]);

  useEffect(() => {
    setPremiumsCache((oldCache) => {
      for (const item of tableContractItems) {
        oldCache[item.id] = item?.premium;
      }
      return oldCache;
    });
  }, [tableContractItems]);

  useEffect(() => {
    setMasterPremium(propsValidation?.values?.customer?.suggested_premium);
  }, [propsValidation?.values?.customer?.suggested_premium]);

  // cancel all debounces
  useEffect(() => {
    return () => {
      contractSearchDebounce.cancel();
      vatSearchDebounce.cancel();
    };
  }, [contractSearchDebounce, vatSearchDebounce]);

  // to the list of  contracts
  useEffect(() => {
    if (isModalOpen) {
      setContractSearchLoader(true);
      dispatch(getContracts(contractsPayload)).then(() => {
        setContractSearchLoader(false);
      });
    }
  }, [dispatch, contractsPayload, isModalOpen]);

  // to get the list of the vats
  useEffect(() => {
    if (isModalOpen) {
      setVatSearchLoader(true);
      dispatch(getVATs(vatPayload)).then(() => {
        setVatSearchLoader(false);
      });
    }
  }, [dispatch, vatPayload, isModalOpen]);

  // to get the list of contract items
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then(() => {
        setTableLoader(false);
      });
    }
  }, [itemsPayload, dispatch]);

  // to exclude list of expenses from contract and contract items
  useEffect(() => {
    if (selectedExpenses?.length > 0 && isModalOpen) {
      setContractsPayload((prevState) => ({
        ...prevState,
        expense_exclude: selectedExpenses,
      }));
      setItemsPayload((prevState) => ({
        ...prevState,
        expense_exclude: selectedExpenses,
      }));
    }
  }, [selectedExpenses, isModalOpen]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      type_of_product: "lot",
      contract: "",
      vat: DEFAULT_VAT_OPTION,
    },
    validationSchema: Yup.object({
      contract: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.contract")}`),
    }),
    onSubmit: (values) => {
      handleAddDocumentLine(values);
    },
  });

  // handle add/edit document
  const handleAddDocumentLine = (values) => {
    if ((isAllSelected && totalItems === 0) || (!isAllSelected && selectedRows?.length === 0)) {
      toast.info(t("information.atleastOneItem", { module: t("common.contractItem") }));
      return;
    }

    let lots = [];
    let items = [];

    const itemsObj = {};
    for (const item of tableContractItems) {
      itemsObj[item.id] = structuredClone(item);
    }

    if (isAllSelected && totalItems) {
      lots = tableContractItems;
    }
    if (selectedRows.length > 0 && !isAllSelected) {
      lots = selectedRows?.map((item) => itemsObj[item.id])?.filter(Boolean);
    }

    items = lots?.map((lot) => {
      const item = {};
      const insurance = propsValidation?.values?.no_insurance
        ? 0
        : calculateInsuranceForLots(lot?.auction_lots?.sale_value, 1, values?.vat?.rate, doNotApplyVat);
      const finalAmount = calculateTotalForDocumentLine(
        {
          qty: 1,
          value: lot?.auction_lots?.sale_value,
          insurance: insurance,
          premium: +(lot?.premium ?? values?.contract?.premium) || 0,
          vat: values?.vat?.rate,
        },
        values?.type_of_product,
        doNotApplyVat,
        SALES_NOTE,
      );

      item.type_of_product = values?.type_of_product;
      item.contract = values?.contract?.id;
      item.contract_item = lot?.id;
      item.quantity = 1;
      item.vat = values?.vat?.id;
      item.amount = finalAmount;
      item.premium = +(lot?.premium ?? values?.contract?.premium) || 0;

      const itemData = {};
      itemData.type_of_product = values?.type_of_product;
      itemData.positionInContract = lot?.contract_item_no;
      itemData.description = getLotDescriptionForInvoice({
        contract_item: { ...lot },
      });
      itemData.quantity = 1;
      itemData.amount = lot?.auction_lots?.sale_value || 0;
      itemData.premium = +(lot?.premium ?? values?.contract?.premium) || 0;
      itemData.vat = values?.vat?.rate || 0;
      itemData.insurance = insurance || 0;
      itemData.final_amount = finalAmount;
      item.itemData = itemData;

      return { ...item };
    });

    let expenseItems = [
      ...values?.contract?.expenses?.map((expense) => ({
        ...expense,
        no: values?.contract?.contract_no,
      })),
    ];

    setSaveLoader(true);
    dispatch(
      getContractItems({
        page: 1,
        is_paid_expense: false,
        filter: { contract: itemsPayload?.filter?.contract },
        expense_exclude: selectedExpenses,
      }),
    ).then((res) => {
      setSaveLoader(false);
      res?.results?.forEach((item) =>
        item?.expenses?.forEach((expense) =>
          expenseItems.push({
            ...expense,
            no: item?.contract_item_no,
          }),
        ),
      );
      if (expenseItems?.length > 0) {
        expenseItems?.forEach((item) => {
          let finalAmount = item?.value;
          if (!doNotApplyVat && values?.vat?.rate) finalAmount += (item?.value * values?.vat?.rate) / 100;
          finalAmount = roundTo(finalAmount);
          items.push({
            type_of_product: "expense",
            quantity: 1,
            expense: item?.id,
            vat: values?.vat?.id,
            amount: finalAmount,
            itemData: {
              type_of_product: "expense",
              description: getExpenseDescription(item),
              quantity: 1,
              vat: values?.vat?.rate || 0,
              amount: item?.value || 0,
              final_amount: finalAmount,
            },
          });
        });
      }
      addDocumentLine(items);
    });
  };

  // to filter the contracts by selected customer id
  useEffect(() => {
    if (selectedCustomer && isModalOpen) {
      setContractsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, customer: selectedCustomer },
      }));
    }
  }, [selectedCustomer, isModalOpen]);

  // to get the list of the contract items by contract id
  useEffect(() => {
    if (validation?.values?.contract?.id) {
      setItemsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          contract: validation?.values?.contract?.id,
          is_sales_note: false,
        },
        exclude: {
          ...prevState?.exclude,
          id__in: selectedItems,
        },
      }));
    }
  }, [validation?.values?.contract?.id, selectedItems]);

  // handle reset form with all other maunual states
  const handleReset = () => {
    validation.resetForm();
    setIsAllSelected(false);
    setSelectedRows([]);
  };

  // use effect to reset the modal form
  useEffect(() => {
    if (!isModalOpen) {
      handleReset();
    }
  }, [isModalOpen]);

  const handleMasterPremiumChange = (premium) => {
    const premiumValue = +premium || 0;
    if (premiumValue < 0 || premiumValue > 100) {
      const target = premiumValue < 0 ? 0 : 100;
      const errorText = t(`validation.number${!target ? "Min" : "Max"}`, { totalNumber: target });
      return toast.error(errorText);
    }

    setMasterPremium(premiumValue);
    setTableContractItems((items) => [...items.map((item) => ({ ...item, premium: premiumValue }))]);
  };

  const handlePremiumChange = (line, premium) => {
    const premiumValue = +premium || 0;
    if (premiumValue < 0 || premiumValue > 100) {
      const target = premiumValue < 0 ? 0 : 100;
      const errorText = t(`validation.number${!target ? "Min" : "Max"}`, { totalNumber: target });
      return toast.error(errorText);
    }

    setTableContractItems((prevState) => {
      for (const item of prevState) {
        if (item.id !== line.id) continue;
        item.premium = premiumValue;
      }

      return [...prevState];
    });
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="position_contract__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.position_contract ? row?.position_contract : "-"),
      width: "16%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_en__icontains", "title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.title_en, row?.title_pt) || "-",
      width: "26%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("description_en__icontains", "description_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.description_en, row?.description_pt) || "-",
      width: "26%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.premiumToApply")}</div>
          <div className="d-flex justify-content-start align-items-center gap-1">
            <NumberInput
              name="premium"
              placeholder={`${t("common.enter")} ${t("common.premium")}`}
              onChange={(e) => handleMasterPremiumChange(e?.target?.value)}
              onBlur={validation.handleBlur}
              value={masterPremium}
              invalid={+masterPremium < 0 || +masterPremium > 100}
              tooltipId="master-premium-edit"
              errorText={t(`validation.number${!+masterPremium < 0 ? 0 : 100 ? "Min" : "Max"}`, {
                totalNumber: +masterPremium < 0 ? 0 : 100,
              })}
              bsSize="md"
              style={{ width: "80px" }}
              required
            />
            <i className="ri-information-line fs-18 text-black-50" id="master-premium-edit" />
            <CustomTooltipWrapper target="master-premium-edit" tooltipBody={t("information.masterPremium")} />
          </div>
        </div>
      ),
      selector: (row, index) => {
        const matchedIndex = tableContractItems.findIndex((item) => item.id === row.id);
        const premiumValue = +tableContractItems[matchedIndex]?.premium || 0;
        const target = +row?.itemData?.premium < 0 ? 0 : 100;
        const errorText = t(`validation.number${!target ? "Min" : "Max"}`, { totalNumber: target });
        return (
          <NumberInput
            name="premium"
            placeholder={`${t("common.enter")} ${t("common.premium")}`}
            onChange={(e) => handlePremiumChange(row, e?.target?.value)}
            onBlur={validation.handleBlur}
            value={premiumValue}
            invalid={+premiumValue < 0 || +premiumValue > 100}
            tooltipId={`premium-${index}`}
            errorText={errorText}
            bsSize="sm"
            style={{ width: "80px" }}
            required
          />
        );
      },
      width: "22%",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={() => {
        if (!saveLoader) handleModalClose();
      }}
      fade={false}
      size="lg"
      className="modal-header-width-100"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{t("common.addTitle", { module: t("common.documentLine") })}</span>

          {((isAllSelected && totalItems > 0) || selectedRows?.length) && validation?.values?.contract?.id ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows?.length || totalItems}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>
      <Form
        onKeyPress={onKeyPressForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <Row>
            <Col sm={6} md={6} lg={6}>
              <div className="mb-3">
                <Label htmlFor="contract" className="form-label">
                  {`${t("common.contract")}*`}
                </Label>
                <Select
                  isLoading={contractSearchLoader}
                  options={contractSearchLoader ? [] : CONTRACT_OPTIONS}
                  getOptionLabel={getContractOptionLabel}
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${
                    validation.touched.contract && validation.errors.contract ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.contract")}`}
                  value={validation.values.contract || null}
                  onInputChange={contractSearchDebounce}
                  onChange={(contract) => {
                    validation.setFieldValue("contract", contract?.id ? contract : null);
                    validation.setFieldValue("vat", contract?.vat);
                    setIsAllSelected(false);
                    setSelectedRows([]);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("contract", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.contract && validation.errors.contract ? (
                  <p className="custom-invalid-feedback">{validation.errors.contract}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="vat" className="form-label">
                  {`${t("common.vat_another")}`}
                </Label>
                <Select
                  isClearable
                  isLoading={vatSearchLoader}
                  options={vatSearchLoader ? [] : VAT_OPTIONS}
                  getOptionLabel={(option) => option?.code + " - " + option?.rate + "%"}
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${validation.touched.vat && validation.errors.vat ? "select-error" : ""}`}
                  placeholder={`${t("common.select")} ${t("common.vat_another")}`}
                  value={validation.values.vat || ""}
                  onInputChange={vatSearchDebounce}
                  onChange={(vat) => {
                    validation.setFieldValue("vat", vat);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("vat", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.vat && validation.errors.vat ? (
                  <p className="custom-invalid-feedback">{validation.errors.vat}</p>
                ) : null}
              </div>
            </Col>
          </Row>

          {/* lots table */}
          <Card className="subcard-table-container table-light">
            {tableLoader ? <Loader /> : null}
            <DataTable
              className="mini-data-table"
              fixedHeader
              persistTableHead
              columns={columns}
              data={validation?.values?.contract?.id ? tableContractItems : []}
              noDataComponent={
                <NoRecords message={!validation?.values?.contract?.id && t("information.returnGuideContracts")} />
              }
              pagination
              paginationComponentOptions={{
                rowsPerPageText: t("common.rowsPerPage"),
                rangeSeparatorText: t("common.rangeSeparator"),
              }}
            />
          </Card>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            color="dark"
            btnTitle={t("common.cancel")}
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(SalesNoteDocumentLineModal);
