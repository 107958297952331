import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  checkPermissionByRoles,
  getLableAndValue,
  getLableAndValueForPermission,
  getSelectedLanguages,
  getValuesFromArray,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import ConfirmationModal from "../../common/ConfirmationModal";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        &nbsp;&nbsp;
        <label className="ms-2">{props.label}</label>
      </components.Option>
    </div>
  );
};

const AddUserModal = ({ user, isModalOpen, onCancel, onSave, loader, t }) => {
  const [isPasswordConfirmationOpen, setIsPasswordConfirmationOpen] = useState(false);
  const [mainLanguages, setMainLanguages] = useState([]);
  const [spokenLanguages, setSpokenLanguages] = useState([]);
  const [permissionOption, setPermissionOption] = useState("");
  const [selectedMainLanguage, setSelectedMainLanguage] = useState([]);
  const [selectedSpokenLanguage, setSelectedSpokenLanguage] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState("");
  const { mainLanguageList, spokenLanguageList, permissionList } = useSelector((state) => state.GeneralUserSettings);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordConfirmationModalOpen = () => {
    setIsPasswordConfirmationOpen(true);
  };
  const handlePasswordConfirmationModalClose = () => {
    setIsPasswordConfirmationOpen(false);
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      is_active: user ? user?.is_active : true,
      specialist: user ? user?.specialist : true,
      name: user?.name || "",
      email: user?.email || "",
      password: "",
      languages: user?.languages.map((lang) => lang?.id) || [],
      languages_speaks: user?.languages_speaks.map((lang) => lang?.id) || [],
      permission: user?.permission?.id || "",
    },
    validationSchema: Yup.object({
      is_active: Yup.bool().oneOf([true, false], "This is required"),
      specialist: Yup.bool().oneOf([true, false], "This is required"),
      name: Yup.string().required(t("settings.general.users.nameValidationMag")),
      email: Yup.string()
        .email(t("settings.general.users.emailValidationMag1"))
        .required(t("settings.general.users.emailValidationMag")),
      password: Yup.string().when("test", {
        is: () => user,
        then: Yup.string().when("test2", {
          is: () => checkPermissionByRoles(["AD"]),
          then: Yup.string().min(8, t("settings.general.users.passwordValidationMsg2")),
          otherwise: Yup.string(),
        }),
        otherwise: Yup.string()
          .min(8, t("settings.general.users.passwordValidationMsg2"))
          .required(t("settings.general.users.passwordValidationMsg1")),
      }),
      languages: Yup.array()
        .min(1, t("settings.general.users.mainLangSelectionValidationMsg1"))
        .required(t("settings.general.users.mainLangSelectionValidationMsg1")),
      languages_speaks: Yup.array()
        .min(1, t("settings.general.users.spokenLangSelectionValidationMsg1"))
        .required(t("settings.general.users.spokenLangSelectionValidationMsg1")),
      permission: Yup.string().required(t("settings.general.users.permissionValidationMsg")),
    }),
    onSubmit: (values) => {
      if (values?.password && checkPermissionByRoles(["AD"]) && user) {
        handlePasswordConfirmationModalOpen();
      } else {
        onSave({ ...values, password: values?.password || undefined }, validation);
      }
    },
  });

  const handlePasswordChangeConfirmation = () => {
    onSave(
      {
        ...validation?.values,
        password: validation?.values?.password || undefined,
      },
      validation
    );
    handlePasswordConfirmationModalClose();
  };

  useEffect(() => {
    validation.resetForm();
    setSelectedMainLanguage([]);
    setSelectedSpokenLanguage([]);
    setSelectedPermission("");
    setIsPasswordVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // For creating label-value options.
  useEffect(() => {
    setMainLanguages(getLableAndValue(mainLanguageList));
    setSpokenLanguages(getLableAndValue(spokenLanguageList));
    setPermissionOption(getLableAndValueForPermission(permissionList));
  }, [mainLanguageList, spokenLanguageList, permissionList]);

  useEffect(() => {
    setSelectedMainLanguage(getLableAndValue(getSelectedLanguages(mainLanguageList, user, "main")));
    setSelectedSpokenLanguage(getLableAndValue(getSelectedLanguages(spokenLanguageList, user, "spoken")));
    setSelectedPermission({
      label: user?.permission?.code,
      value: user?.permission?.id,
    });
  }, [mainLanguageList, permissionList, spokenLanguageList, user]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      setSelectedMainLanguage([]);
      setSelectedSpokenLanguage([]);
      setSelectedPermission("");
      onCancel();
    }
  };

  const handleMainLanguageChange = (selectedMain) => {
    getValuesFromArray(selectedMain);
    validation.setFieldValue("languages", getValuesFromArray(selectedMain));
  };

  const handleSpokenLanguageChange = (selectedSpoken) => {
    validation.setFieldValue("languages_speaks", getValuesFromArray(selectedSpoken));
  };

  const handlePermissionChange = (selectedPermission) => {
    validation.setFieldValue("permission", selectedPermission.value);
  };

  return (
    <Modal size="lg" centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${user?.id ? t("common.edit") : t("common.add")} ${t(
        "settings.general.users.user"
      )}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
        autoComplete="off"
      >
        <ModalBody>
          {/* form */}
          <Row>
            <Row>
              <Col>
                {/* For is_active field */}
                <div className="mb-3">
                  <Label htmlFor="active user" className="form-label">
                    {`${t("common.activeUser")}`}
                  </Label>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      role="switch"
                      id="is_active"
                      name="is_active"
                      defaultChecked={user ? user?.is_active : true}
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.is_active}
                      invalid={validation.touched.is_active && validation.errors.is_active && true}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                {/* For Specialist Switch */}
                <div className="mb-3">
                  <Label htmlFor="active user" className="form-label">
                    {`${t("settings.general.users.specialist")}`}
                  </Label>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      role="switch"
                      id="specialist"
                      name="specialist"
                      defaultChecked={user ? user?.specialist : true}
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.specialist}
                      invalid={validation.touched.specialist && validation.errors.specialist && true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* For Name field */}
                <div className="mb-3">
                  <Label htmlFor="name" className="form-label">
                    {t("common.name")}
                  </Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder={`${t("common.enter")} ${t("common.name")}`}
                    type="text"
                    value={validation.values.name || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={validation.touched.name && validation.errors.name ? true : false}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                {/* For Main Language Dropdown */}
                <div className="mb-3">
                  <Label htmlFor="idiom" className="form-label">
                    {`${t("settings.general.users.system")} ${t("settings.general.language.language")}`}
                  </Label>
                  <Select
                    name="languages"
                    placeholder={`${t("common.select")} ${t("settings.general.users.system")} ${t(
                      "settings.general.language.language"
                    )}`}
                    value={selectedMainLanguage || []}
                    isMulti={true}
                    onChange={(sortBy) => {
                      setSelectedMainLanguage(sortBy);
                      handleMainLanguageChange(sortBy);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("languages", true);
                      validation.handleBlur(e);
                    }}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    options={mainLanguages}
                    closeMenuOnSelect={false}
                    classNamePrefix="js-example-basic-multiple mb-0"
                  />
                  {validation.touched.languages && validation.errors.languages ? (
                    <p className="custom-validation-style">{validation.errors.languages}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* For email field */}
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    {t("common.email")}
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder={`${t("common.enter")} ${t("common.email")}`}
                    type="text"
                    disabled={user ? true : false}
                    value={validation.values.email || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={validation.touched.email && validation.errors.email ? true : false}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                {/* For Spoken Language Drop down */}
                <div className="mb-3">
                  <Label htmlFor="spoken" className="form-label">
                    {`${t("settings.general.language.spoken")} ${t("settings.general.language.language")}`}
                  </Label>
                  <Select
                    name="languages_speaks"
                    placeholder={`${t("common.select")} ${t("settings.general.language.spoken")} ${t(
                      "settings.general.language.language"
                    )}`}
                    value={selectedSpokenLanguage || []}
                    isMulti={true}
                    onChange={(sortBy) => {
                      setSelectedSpokenLanguage(sortBy);
                      handleSpokenLanguageChange(sortBy);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("languages_speaks", true);
                      validation.handleBlur(e);
                    }}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    options={spokenLanguages}
                    closeMenuOnSelect={false}
                    classNamePrefix="js-example-basic-multiple mb-0"
                  />
                  {validation.touched.languages_speaks && validation.errors.languages_speaks ? (
                    <p className="custom-validation-style">{validation.errors.languages_speaks}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* For Password Field */}
                <div className="mb-3">
                  <Label htmlFor="password" className="form-label">
                    {t("common.password")}
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="password"
                      className="form-control"
                      placeholder={
                        user && !checkPermissionByRoles(["AD"])
                          ? "* * * * * *"
                          : `${t("common.enter")} ${t("common.password")}`
                      }
                      type={isPasswordVisible ? "text" : "password"}
                      disabled={user && !checkPermissionByRoles(["AD"]) ? true : false}
                      value={validation.values.password || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={validation.touched.password && validation.errors.password ? true : false}
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                    <button
                      className="btn shadow-none btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => {
                        setIsPasswordVisible((prevState) => !prevState);
                      }}
                    >
                      <i
                        className={`${validation.touched.password && validation.errors.password ? "me-3" : ""} ${
                          !user && isPasswordVisible ? "ri-eye-fill" : "ri-eye-off-fill"
                        } align-middle`}
                      ></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                {/* For Permission DropDown */}
                <div className="mb-3">
                  <Label htmlFor="permission" className="form-label">
                    {`${t("sidebarMenu.permissions")}`}
                  </Label>
                  <Select
                    name="permission"
                    placeholder={`${t("common.select")} ${t("settings.general.permission.permission")}`}
                    value={selectedPermission || ""}
                    onChange={(sortBy) => {
                      setSelectedPermission(sortBy);
                      handlePermissionChange(sortBy);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("permission", true);
                      validation.handleBlur(e);
                    }}
                    // components={{ Option }}s
                    options={permissionOption}
                    classNamePrefix="js-example-basic-multiple mb-0"
                  />
                  {validation.touched.permission && validation.errors.permission ? (
                    <p className="custom-validation-style">{validation.errors.permission}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
      <ConfirmationModal
        title={t("common.confirmation")}
        isModalOpen={isPasswordConfirmationOpen}
        body={t("confirmation.changePassword")}
        onConfirmBtnHandler={handlePasswordChangeConfirmation}
        onCloseBtnHandler={handlePasswordConfirmationModalClose}
      />
    </Modal>
  );
};

export default withTranslation()(AddUserModal);
