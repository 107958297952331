import React from "react";
import { useHistory } from "react-router-dom";

const CustomBreadcrumb = ({ data }) => {
  const history = useHistory();
  return (
    <div className="custom-breadcrumb">
      {data?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.link ? (
              <span
                key={`link-${index}`}
                className="text-primary cursor-pointer"
                onClick={() => history.push(item.link)}
              >
                {item?.label}
              </span>
            ) : (
              <span key={`label-${index}`}>{item?.label}</span>
            )}
            {index !== data.length - 1 ? (
              <i key={`seperator-${index}`} className=" ri-arrow-right-s-line fs-20" />
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CustomBreadcrumb;
