import { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import Loader from "../../components/common/Loader";

import ActionButton from "../../components/common/ActionButton";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import CustomButton from "../../components/common/CustomButton";
import TabsComponent from "../../components/common/TabsComponent";
import Bids from "../../components/pages/auctions/Bids";
import ChooseProformaInvoicesModal from "../../components/pages/auctions/ChooseProformaInvoicesModal";
import Contracts from "../../components/pages/auctions/Contracts";
import InfoForm from "../../components/pages/auctions/InfoForm";
import Invoice from "../../components/pages/auctions/Invoice";
import Lots from "../../components/pages/auctions/Lots";
import Paddles from "../../components/pages/auctions/Paddles";
import SendProformaInvoicesModal from "../../components/pages/auctions/SendProformaInvoicesModal";
import { PROFORMA_INVOICE_DOCUMENT } from "../../helpers/constants";
import { currencyFormatter } from "../../helpers/utils";
import { bulkIssueProforma, getAuctionById, notifyUsers, sendProformaInvoices } from "../../store/auctions/actions";
import IssueProformaInvoiceModal from "../../components/pages/auctions/IssueProformaInvoiceModal";
import ChooseIssueProformaInvoiceModal from "../../components/pages/auctions/ChooseIssueProformaInvoiceModal";
import NotifyUsersModal from "../../components/pages/auctions/NotifyUsersModal";
import ChoosePaddlesModal from "../../components/pages/auctions/ChoosePaddlesModal";

const AuctionForm = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const auction = useSelector((state) => state?.Auctions?.auction);
  const [infoLoader, setInfoLoader] = useState(false);
  const [selectedPaddle, setSelectedPaddle] = useState(null);
  const totalLots = useSelector((state) => state?.Auctions?.auction?.lots_count);
  const [initialActiveTabIndex, setIntialActiveIndex] = useState(null);
  const [sendProformaInvoiceLoader, setSendProformaInvoiceLoader] = useState(false);
  const [notifyUsersLoader, setNotifyUsersLoader] = useState(false);
  const [isSendProformaModalOpen, setIsSendProformaModalOpen] = useState(false);
  const [isNotifyUsersModalOpen, setIsNotifyUsersModalOpen] = useState(false);
  const [isChooseProformaModalOpen, setisChooseProformaModalClose] = useState(false);
  const [isChoosePaddlesModalOpen, setIsChoosePaddlesModalClose] = useState(false);

  const [issueProformaInvoiceLoader, setIssueProformaInvoiceLoader] = useState(false);
  const [isIssueProformaModalOpen, setIsIssueProformaModalOpen] = useState(false);
  const [isChooseIssueProformaModalOpen, setIsChooseIssueProformaModalClose] = useState(false);

  const handleIssueProformaModalOpen = () => {
    setIsIssueProformaModalOpen(true);
  };

  const handleIssueProformaModalClose = () => {
    setIsIssueProformaModalOpen(false);
  };
  const handleChooseIssueProformaModalOpen = () => {
    setIsChooseIssueProformaModalClose(true);
  };
  const handleChooseIssueProformaModalClose = () => {
    setIsChooseIssueProformaModalClose(false);
  };

  const handleSendProformaModalOpen = () => {
    setIsSendProformaModalOpen(true);
  };
  const handleNotifyUsersModalOpen = () => {
    setIsNotifyUsersModalOpen(true);
  };
  const handleSendProformaModalClose = () => {
    setIsSendProformaModalOpen(false);
  };
  const handleNotifyUsersModalClose = () => {
    setIsNotifyUsersModalOpen(false);
  };
  const handleChooseProformaModalOpen = () => {
    setisChooseProformaModalClose(true);
  };
  const handleChoosePaddlesModalOpen = () => {
    setIsChoosePaddlesModalClose(true);
  };
  const handleChooseProformaModalClose = () => {
    setisChooseProformaModalClose(false);
  };
  const handleChoosePaddlesModalClose = () => {
    setIsChoosePaddlesModalClose(false);
  };

  // to issue proforma invoices
  const handleIssueProformaInvoices = (data) => {
    let payload = {
      auction_id: id,
    };
    if (data) {
      payload = {
        ...payload,
        paddle_ids: data?.paddle_ids,
      };
    }
    setIssueProformaInvoiceLoader(true);
    dispatch(bulkIssueProforma(payload)).then((res) => {
      setIssueProformaInvoiceLoader(false);
      handleChooseProformaModalClose();
      handleSendProformaModalClose();
    });
  };

  // to choose proforma inovices to issue
  const handleChooseIssueProformaInvoices = () => {
    handleChooseIssueProformaModalOpen();
  };

  // to send proforma invoices
  const handleSendProformaInvoices = (data) => {
    let payload = {
      is_english: false,
      is_mail: true,
      auction_id: id,
    };
    if (data) {
      payload = {
        ...payload,
        filter: data?.filter,
        document_ids: data?.document_ids,
      };
    } else {
      payload = {
        ...payload,
        filter: {
          document_type__description: PROFORMA_INVOICE_DOCUMENT,
          document_lines__auction: id,
        },
        document_ids: [],
      };
    }
    setSendProformaInvoiceLoader(true);
    dispatch(sendProformaInvoices(payload)).then((res) => {
      setSendProformaInvoiceLoader(false);
      handleChooseProformaModalClose();
      handleSendProformaModalClose();
    });
  };

  const handleNotifyUsers = (data) => {
    let payload = {
      is_english: false,
      is_mail: true,
    };
    if (data) {
      payload = {
        ...payload,
        paddle_ids: data?.paddle_ids,
      };
    } else {
      payload = {
        ...payload,
      };
    }
    setNotifyUsersLoader(true);
    dispatch(notifyUsers(id, payload)).then((res) => {
      setNotifyUsersLoader(false);
      handleChoosePaddlesModalClose();
      handleNotifyUsersModalClose();
    });
  };

  // to choose proforma inovices to send
  const handleChooseProformaInvoices = () => {
    handleChooseProformaModalOpen();
  };

  const handleChoosePaddles = () => {
    handleChoosePaddlesModalOpen();
  };

  // navigate to the auction's situation page.
  const navigateToAuctionSituation = () => {
    history.push(`/auctions/${auction?.id}/auction-situation`);
  };

  // to redirect to lot if in edit mode
  useEffect(() => {
    if (id) {
      setIntialActiveIndex(1);
    }
  }, [id]);

  // handle get auction by id
  const handleGetAuctionDetails = useCallback(
    (auctionId) => {
      setInfoLoader(true);
      dispatch(getAuctionById(auctionId)).then((res) => {
        if (!res) {
          history.push(`/auctions`);
        }
        setInfoLoader(false);
      });
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (id) {
      handleGetAuctionDetails(id);
    }
  }, [dispatch, id, history, handleGetAuctionDetails]);

  document.title = `Veritas | ${props.t("sidebarMenu.auctions")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomBreadcrumb
            data={[
              {
                label: props.t("sidebarMenu.auctions"),
                link: "/auctions",
              },
              {
                label: `${props.t(`${id ? "common.editTitle" : "common.addTitle"}`, {
                  module: props.t("common.auction"),
                })} ${id ? `(${auction?.auction_no})` : ""}`,
              },
            ]}
          />
          {id ? (
            <CustomButton btnTitle={props.t("common.auctionSituation")} onClick={navigateToAuctionSituation} />
          ) : null}
        </div>
        <Card className="container-body card-section auction-tab-wrapper">
          {infoLoader ? <Loader /> : null}
          <TabsComponent
            initialActiveTabIndex={initialActiveTabIndex}
            tabData={[
              {
                label: props.t("common.info"),
                Component: InfoForm,
                props: { isEditable: Boolean(id) },
                disabled: false,
              },
              {
                label: props.t("common.lots"),
                Component: Lots,
                props: { id: id, handleGetAuctionDetails },
                disabled: id ? false : true,
              },
              {
                label: props.t("common.paddles"),
                Component: Paddles,
                props: { setPaddle: setSelectedPaddle },
                disabled: id && totalLots > 0 ? false : true,
              },
              {
                label: props.t("common.bids"),
                Component: Bids,
                disabled: id && totalLots > 0 ? false : true,
              },
              {
                label: props.t("common.contracts"),
                Component: Contracts,
                disabled: id ? false : true,
              },
              {
                label: props.t("common.invoice"),
                Component: Invoice,
                props: { paddle: selectedPaddle, setPaddle: setSelectedPaddle },
                disabled: id && totalLots > 0 ? false : true,
              },
            ]}
            HeaderCompoenent={
              <div className="d-flex flex-row gap-2 ms-auto align-items-center">
                {id ? (
                  <div className="fs-14 text-primary fw-bold d-flex flex-row align-item-center gap-2">
                    <div>{`${props.t("common.totalAuctionSales")} :`}</div>
                    <div>{currencyFormatter(auction?.total_sale_value || 0)}</div>
                  </div>
                ) : null}
                <ActionButton
                  disabled={!(id && totalLots > 0)}
                  iconClasses="ri-file-text-fill fs-24"
                  buttonId={`issue-proforma-invoice`}
                  tooltip={props.t("common.issueProformaInvoices")}
                  onClick={() => {
                    handleIssueProformaModalOpen();
                  }}
                />

                <ActionButton
                  disabled={!(id && totalLots > 0)}
                  iconClasses="ri-file-paper-fill fs-24"
                  buttonId={`send-proforma-invoice`}
                  tooltip={props.t("common.sendProformaInvoices")}
                  onClick={() => {
                    handleSendProformaModalOpen();
                  }}
                />

                {auction?.auction_type?.auction_type === "Live" && (
                  <ActionButton
                    disabled={!(id && totalLots > 0)}
                    iconClasses="ri-inbox-unarchive-fill fs-24"
                    buttonId={`notify-users`}
                    tooltip={props.t("common.notifyUsers")}
                    onClick={() => {
                      handleNotifyUsersModalOpen();
                    }}
                  />
                )}
              </div>
            }
          />
        </Card>
      </div>
      {/* send proforma invoices */}
      <SendProformaInvoicesModal
        loader={sendProformaInvoiceLoader}
        isModalOpen={isSendProformaModalOpen}
        onCloseBtnHandler={handleSendProformaModalClose}
        onSendBtnHandler={handleSendProformaInvoices}
        onChooseBtnHandler={handleChooseProformaInvoices}
      />
      {/* choose proforma invoices and send */}
      <ChooseProformaInvoicesModal
        isModalOpen={isChooseProformaModalOpen}
        loader={sendProformaInvoiceLoader}
        onCancel={handleChooseProformaModalClose}
        onSend={handleSendProformaInvoices}
      />
      <NotifyUsersModal
        loader={notifyUsersLoader}
        isModalOpen={isNotifyUsersModalOpen}
        onCloseBtnHandler={handleNotifyUsersModalClose}
        onSendBtnHandler={handleNotifyUsers}
        onChooseBtnHandler={handleChoosePaddles}
      />

      {/* choose paddles and send */}
      <ChoosePaddlesModal
        isModalOpen={isChoosePaddlesModalOpen}
        loader={notifyUsersLoader}
        onCancel={handleChoosePaddlesModalClose}
        onSend={handleNotifyUsers}
      />

      {/* send proforma invoices */}
      <IssueProformaInvoiceModal
        loader={issueProformaInvoiceLoader}
        isModalOpen={isIssueProformaModalOpen}
        onCloseBtnHandler={handleIssueProformaModalClose}
        onIssueBtnHandler={handleIssueProformaInvoices}
        onChooseBtnHandler={handleChooseIssueProformaInvoices}
      />
      {/* choose proforma invoices and send */}
      <ChooseIssueProformaInvoiceModal
        isModalOpen={isChooseIssueProformaModalOpen}
        loader={issueProformaInvoiceLoader}
        onCancel={handleChooseIssueProformaModalClose}
        onSend={handleIssueProformaInvoices}
      />
    </>
  );
};

export default withTranslation()(AuctionForm);
