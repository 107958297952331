import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Input } from "reactstrap";
import {
  addPaddle,
  deletePaddle,
  getPaddles,
  updatePaddle,
  viewInvoiceDocuments,
} from "../../../store/auctions/paddleActions";
import CustomAddButton from "../../common/CustomAddButton";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import DeleteModal from "../../common/DeleteModal";
import NoRecords from "../../common/NoRecords";
import AddPaddleModal from "./AddPaddleModal";
import Loader from "../../../components/common/Loader";
import { useHistory, useParams } from "react-router-dom";
import ActionButton from "../../common/ActionButton";
import { toast } from "react-toastify";
import CustomTextLink from "../../common/CustomTextLink";
import i18next from "i18next";
import BidRequestsModal from "./BidRequestsModal";
import {
  checkPermissionByRoles,
  exportToexcel,
  formatAmount,
  getCustomerAddress,
  getIdFromArray,
  getLabelValueArray,
  getOnlyCustomerName,
} from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE, INVOICE_DOCUMENT, PROFORMA_INVOICE_DOCUMENT } from "../../../helpers/constants";
import CustomColumnHeader from "../../common/CustomColumnHeader";
import Select, { components } from "react-select";
import { ADDRESS_OPTION, SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import API from "../../../helpers/api/api_interceptor";
import { generateExportPaddlesFileName, generatePaddlesExcelData } from "../../../helpers/lotExportDataGenerator";

const Paddles = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const auction = useSelector((state) => state?.Auctions?.auction);
  const {
    data: paddlesData,
    total: totalPaddles,
    suggested_paddle_no,
  } = useSelector((state) => state?.Auctions?.paddles);
  const [paddlesPayload, setPaddlesPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "paddle_no",
    filter: { auction: id },
  });
  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data) || [],
    "id",
    "code"
  );

  const [tableLoader, setTableLoader] = useState(false);
  const [isPaddleModalOpen, setIsPaddleModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [selectedPaddle, setSelectedPaddle] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);

  const [viewDocumentLoader, setViewDocumentLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  const [isBidRequestsModalOpen, setIsBidRequestModalOpen] = useState(false);

  const [orderBy, setOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [exportBtnLoader, setExportBtnLoader] = useState(false);

  // handle bid requests modal open
  const handleBidRequestsModalOpen = () => {
    setIsBidRequestModalOpen(true);
  };
  // handle bid requests modal close
  const handleBidRequestsModalClose = () => {
    setIsBidRequestModalOpen(false);
  };

  // navigate to customer edit page
  const navigateToCustomerEditPage = (row) => {
    if (row?.customer?.id && !row?.customer?.parent_id) {
      history.push(`/customers/edit/${row?.customer?.id}`);
    } else if (row?.customer?.parent_id) {
      history.push(`/customers/${row?.customer?.parent_id}/edit-person/${row?.customer?.id}`);
    }
  };

  // to handle delete modal open
  const handleDeleteModalOpen = (paddleData) => {
    setSelectedPaddle(paddleData);
    setIsDeleteModalOpen(true);
  };
  // to handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedPaddle(null);
    setIsDeleteModalOpen(false);
    setIsDeleteActionAllowed(false);
  };
  // handle delete paddle
  const handleDeletePaddle = () => {
    if (selectedPaddle && selectedPaddle?.id) {
      setDeleteLoader(true);
      dispatch(
        deletePaddle({
          filter: {},
          is_selected_all: false,
          ids: [selectedPaddle?.id],
          is_admin: isDeleteActionAllowed,
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
            setDeleteLoader(false);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleDeleteModalClose();
            handleGetPaddles(paddlesPayload);
            setDeleteLoader(false);
          }
        })
        .catch((error) => {
          handleDeleteModalClose();
          setDeleteLoader(false);
        });
    }
  };

  // to handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  // to handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setIsBulkDeleteModalOpen(false);
  };
  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    setBulkDeleteLoader(true);
    // dispatch(
    //   deletePaddle({
    //     is_selected_all: isAllSelected,
    //     ids: selectedRows,
    //     filter: paddlesPayload?.filter || {},
    //   })
    // ).then((res) => {
    //   if (res) {
    //     handleBulkDeleteModalClose();
    //     handleGetPaddles(paddlesPayload);
    //     setIsAllSelected(false);
    //     setSelectedRows([]);
    //   } else {
    //     //
    //   }
    //   setBulkDeleteLoader(false);
    // });
    dispatch(
      deletePaddle({
        is_selected_all: isAllSelected,
        ids: selectedRows,
        filter: paddlesPayload?.filter || {},
        is_admin: isDeleteActionAllowed,
      })
    )
      .then((res) => {
        if (res.data.status === 200) {
          if (!res?.data?.data?.action_allowed) {
            handleBulkDeleteModalClose();
          }
          setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
          toast.warn(res?.data?.detail);
          setBulkDeleteLoader(false);
        } else if (res.data.status === 204) {
          toast.success(res?.data?.detail);
          handleBulkDeleteModalClose();
          handleGetPaddles(paddlesPayload);
          setBulkDeleteLoader(false);
        }
      })
      .catch((error) => {
        handleBulkDeleteModalClose();
        setBulkDeleteLoader(false);
      });
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  // to handle paddle modal open for adding/editing
  const handlePaddleModalOpen = (paddleData) => {
    setSelectedPaddle(paddleData);
    setIsPaddleModalOpen(true);
  };
  // to handle paddle modal close for adding/editing
  const handlePaddleModalClose = () => {
    setSelectedPaddle(null);
    setIsPaddleModalOpen(false);
  };
  // handle save paddle
  const handleSavePaddle = async (values, formikValidation) => {
    const payload = { ...values, auction: id };
    // check for add or edit
    if (selectedPaddle && selectedPaddle?.id) {
      // edit paddle
      setSaveLoader(true);
      dispatch(updatePaddle(selectedPaddle?.id, payload))
        .then((res) => {
          if (res) {
            handlePaddleModalClose();
            formikValidation.resetForm();
            handleGetPaddles(paddlesPayload);
          } else {
            // failure
          }
          setSaveLoader(false);
        })
        .catch((errror) => {
          setSaveLoader(false);
        });
    } else {
      // add paddle

      const warning = paddlesData.some(
        (paddle) => paddle.customer.id === payload.customer && paddle.auction.toString() === payload.auction
      );
      if (warning === true) {
        setSaveLoader(true);
        const confirmation = window.confirm(
          "Warning! This customer already has a paddle for this auction. Are you sure you want to proceed with this action?\n\nAtenção! Este cliente já tem uma raquete para este leilão. Tem a certeza de que pretende prosseguir com esta acção?"
        );
        if (confirmation) {
          dispatch(addPaddle(payload)).then((res) => {
            if (res) {
              handlePaddleModalClose();
              formikValidation.resetForm();
              handleGetPaddles(paddlesPayload);
            }
          });
        }
        setSaveLoader(false);
      }
      if (warning === false) {
        setSaveLoader(true);
        dispatch(addPaddle(payload)).then((res) => {
          if (res) {
            handlePaddleModalClose();
            formikValidation.resetForm();
            handleGetPaddles(paddlesPayload);
          }
          setSaveLoader(false);
        });
      }
    }
  };
  // handle page change
  const handlePageChange = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get paddles
  const handleGetPaddles = useCallback(
    (paddlesPayload) => {
      setTableLoader(true);
      dispatch(getPaddles(paddlesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaddles(paddlesPayload);
  }, [paddlesPayload, handleGetPaddles]);

  //  to handle view / preivew Invoice
  const handleViewPreviewDocument = (paddle, documentType) => {
    const payload = {
      is_english: i18next.resolvedLanguage === "en",
      auction_id: id,
      document_type: documentType,
      paddle_id: paddle?.id,
    };
    setViewDocumentLoader(true);
    const toastId = toast.loading(`${props.t("common.loading")}...`);
    dispatch(viewInvoiceDocuments(payload)).then((res) => {
      toast.dismiss(toastId);
      setViewDocumentLoader(false);
    });
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setPaddlesPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  const handleAddressFilter = (selectedItem, filterKey) => {
    if (selectedItem && selectedItem.value === "*") {
      setSelectedAddress(SELECT_ALL_OPTION);
      return setPaddlesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else if (selectedItem && selectedItem.value === "portugal") {
      let tempPayload = paddlesPayload;
      delete tempPayload.exclude;

      return setPaddlesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        return {
          ...tempPayload,
          filter: {
            ...prevState.filter,
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else if (selectedItem && selectedItem.value === "internationals") {
      return setPaddlesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return {
          ...tempPayload,
          exclude: {
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else {
      setPaddlesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        delete tempPayload.exclude;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const handleExportPaddlesExcel = () => {
    setExportBtnLoader(true);
    let filter = { ...paddlesPayload.filter };
    if (selectedRows.length > 0) {
      filter.id__in = selectedRows;
    }
    API.get(`/auctions/paddles`, {
      params: {
        filter,
        exclude: paddlesPayload?.exclude,
        sort: paddlesPayload?.sort,
      },
    })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          const { widthArr, data } = generatePaddlesExcelData(res?.data?.data?.results);
          exportToexcel(data, `Auction_${auction?.auction_no}_${generateExportPaddlesFileName()}`, widthArr);
          handleGetPaddles(paddlesPayload);
        } else setExportBtnLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setExportBtnLoader(false);
      })
      .finally(() => setExportBtnLoader(false));
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "50px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.paddleNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="paddle_no__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) => (row?.paddle_no ? <span style={{ color: row?.color_code }}>{row?.paddle_no}</span> : "-"),
      width: "135px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.id ? (
          <span
            className="cursor-pointer"
            style={{ color: row?.color_code }}
            onClick={() => navigateToCustomerEditPage(row)}
          >
            {getOnlyCustomerName(row?.customer)}
          </span>
        ) : (
          "-"
        ),
      width: "425px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.buyer'sAddress")}`}
            sortable
            sortableKey="customer__customers_address__country__name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            className="custom-select"
            isMulti={false}
            isClearable
            components={{ Option }}
            options={[SELECT_ALL_OPTION, ...ADDRESS_OPTION]}
            value={selectedAddress}
            onChange={(selectedLocation) => {
              if (selectedLocation === null) {
                setSelectedAddress("");
              }
              setSelectedAddress(selectedLocation);
              handleAddressFilter(selectedLocation, "customer__customers_address__country");
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.addresses
          ? getCustomerAddress(
              row?.customer?.addresses,
              row?.customer?.customer_type === "company" ? "company" : "client"
            )
          : "-",
      width: "355px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.totalPurchase")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total_purchase__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span style={{ color: row?.color_code }}>{formatAmount(row?.total_purchase || 0) + " €"}</span>
      ),
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions align-items-center gap-1">
          {row?.paddle_no < 5000 && row?.total_purchase > 0 ? (
            !row?.is_document_invoice ? (
              <>
                <CustomButton
                  classes={"p-1 h-100"}
                  color={"success"}
                  btnTitle={props.t("common.invoice")}
                  onClick={() => {
                    props?.setPaddle(row);
                    props?.setActiveTabIndex(5);
                  }}
                />
                {row?.is_document_proforma ? (
                  <ActionButton
                    iconClasses="ri-file-list-fill fs-18"
                    buttonId={`tooltip-${row?.id}-proforma`}
                    tooltip={props.t("common.viewProformaInvoice")}
                    onClick={() => {
                      handleViewPreviewDocument(row, PROFORMA_INVOICE_DOCUMENT);
                    }}
                  />
                ) : null}
                <ActionButton
                  iconClasses="ri-delete-bin-fill fs-18 disabled"
                  buttonId={`tooltip-${row?.id}-delete`}
                  tooltip={props.t("common.delete")}
                  disabled={true}
                />
              </>
            ) : (
              <>
                <ActionButton
                  iconClasses="ri-task-fill fs-18"
                  buttonId={`tooltip-${row?.id}-invoice`}
                  tooltip={props.t("common.viewInvoice")}
                  onClick={() => {
                    handleViewPreviewDocument(row, INVOICE_DOCUMENT);
                  }}
                />
              </>
            )
          ) : (
            <ActionButton
              iconClasses="ri-delete-bin-fill fs-18"
              buttonId={`tooltip-${row?.id}-delete`}
              tooltip={props.t("common.delete")}
              onClick={() => {
                handleDeleteModalOpen(row);
              }}
            />
          )}
          <ActionButton
            iconClasses="ri-pencil-fill fs-18"
            buttonId={`tooltip-${row?.id}-edit`}
            tooltip={props.t("common.edit")}
            onClick={() => {
              handlePaddleModalOpen(row);
            }}
          />
        </div>
      ),
      width: "210px",
      right: true,
    },
  ];

  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-2 gap-2">
        {(isAllSelected && totalPaddles > 0) || selectedRows.length ? (
          <Button
            color="info"
            onClick={() => {
              setSelectedRows([]);
              setIsAllSelected(false);
            }}
          >
            {props.t("common.deselect")}
            <Badge color="dark" className="ms-1">
              {selectedRows.length || totalPaddles}
            </Badge>
          </Button>
        ) : null}
        <CustomButton
          isLoading={exportBtnLoader}
          btnTitle={props.t("common.export")}
          onClick={() => {
            handleExportPaddlesExcel();
          }}
        />
        <CustomAddButton
          color={"success"}
          btnTitle={props.t("common.addTitle", {
            module: props.t("common.paddle"),
          })}
          onClick={() => {
            handlePaddleModalOpen();
          }}
        />
        {auction?.auction_type?.auction_type === "Online" && checkPermissionByRoles(["AD"]) ? (
          <CustomButton
            isLoading={bulkDeleteLoader}
            color={"success"}
            btnTitle={props.t("common.bidRequests")}
            onClick={handleBidRequestsModalOpen}
          />
        ) : null}
        <CustomButton
          isLoading={bulkDeleteLoader}
          color={"danger"}
          btnTitle={props.t("common.delete")}
          disabled={!(isAllSelected && totalPaddles > 0) && selectedRows.length === 0}
          onClick={() => {
            handleBulkDeleteModalOpen();
          }}
        />
      </div>
      <Card className="subcard-table-container table-light">
        {tableLoader ? <Loader /> : null}
        <DataTable
          className="data-table"
          fixedHeader
          persistTableHead
          columns={columns}
          data={[...paddlesData]}
          pagination
          paginationServer
          paginationTotalRows={totalPaddles}
          paginationDefaultPage={paddlesPayload?.page}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationPerPage={DEFAULT_ROW_PER_PAGE}
          paginationComponentOptions={{
            rowsPerPageText: props.t("common.rowsPerPage"),
            rangeSeparatorText: props.t("common.rangeSeparator"),
          }}
        />
      </Card>
      {/* add/edit paddle modal */}
      <AddPaddleModal
        suggestedPaddle={suggested_paddle_no}
        isModalOpen={isPaddleModalOpen}
        onCancel={handlePaddleModalClose}
        onSave={handleSavePaddle}
        loader={saveLoader}
        paddle={selectedPaddle}
      />
      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={`${props.t("common.delete")} ${selectedRows.length || totalPaddles} ${props.t("common.paddles")}`}
        body={props.t("confirmation.deleteMessage", {
          module: `${props.t("common.paddles")}`,
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={bulkDeleteLoader}
      />
      {/* bid requests modal */}
      <BidRequestsModal isModalOpen={isBidRequestsModalOpen} onCancel={handleBidRequestsModalClose} />
      {/* delete modal */}
      <DeleteModal
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePaddle}
        title={`${props.t("common.delete")} ${props.t("common.paddle")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.paddle"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
      />
    </>
  );
};

export default withTranslation()(Paddles);
