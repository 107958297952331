import { ADD_AUCTION_TYPE, DELETE_AUCTION_TYPE, EDIT_AUCTION_TYPE, SET_AUCTION_TYPE_LIST } from "./actionType";
const INIT_STATE = {
  data: [],
  total: 0,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUCTION_TYPE_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload?.total,
      };
    case ADD_AUCTION_TYPE:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        total: state.total + 1,
      };
    case EDIT_AUCTION_TYPE:
      const existingIndex = state.data.findIndex((auctionType) => auctionType.id === action.payload.data.id);
      const updatedAuctionTypeData = state.data;
      if (existingIndex !== -1) {
        updatedAuctionTypeData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        data: [...updatedAuctionTypeData],
      };
    case DELETE_AUCTION_TYPE:
      return {
        ...state,
        data: state.data.filter((auctionTypeData) => auctionTypeData.id !== action.payload.deletedId),
        totalAuctionType: state.totalAuctionType - 1,
      };
    default:
      return state;
  }
};

export default User;
