import { FormikProvider } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, Label, Row } from "reactstrap";
import { TYPE_SAVE, WHO_WE_ARE_CONTENT } from "../../../../helpers/constants";
import { checkImageFileValidation, checkVideoFileValidation } from "../../../../helpers/utils";
import { deleteWebsitePagesInformation, getWebsitePagesInformation } from "../../../../store/website-pages/actions";
import InformationFieldArray from "../../../common/InformationFieldArray";
import Loader from "../../../common/Loader";
import MultiMediaTableForm from "../../../common/MultiMediaTableForm";
import ActionButton from "../../../common/ActionButton";
import ImageCropperModal from "../../../common/ImageCropperModal";

const WhoWeAreContent = ({ languageType, website_page, pageType, ...props }) => {
  const dispatch = useDispatch();
  const [infoLoader, setInfoLoader] = useState(false);
  const [infoPayload, setInfoPayload] = useState({
    filter: {
      website_page: website_page,
      language_type: languageType,
      page_type: pageType,
      save_type: TYPE_SAVE,
    },
  });
  const [isImageCropperModalOpen, setIsImageCropperModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const [selectedArrayKey, setSelectedArrayKey] = useState();

  // to get the home page information
  const handleGetPageInformation = useCallback(
    (payload) => {
      setInfoLoader(true);
      dispatch(getWebsitePagesInformation(payload, WHO_WE_ARE_CONTENT)).then(() => {
        setInfoLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPageInformation(infoPayload);
  }, [infoPayload, handleGetPageInformation]);

  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.whoWeAreValidation?.resetForm();
    };
  }, []);

  const handleDeleteItem = (payload, setDeleteLoader, closeModal, boundArrayHelpers, itemIndex, arrayKey) => {
    setDeleteLoader(true);
    dispatch(deleteWebsitePagesInformation(payload)).then((res) => {
      if (res) {
        if (arrayKey === "carousel")
          props?.setWhoWeAreValues((prevState) => ({
            ...prevState,
            carousel: prevState?.carousel?.filter((item) => !payload?.ids?.includes(item?.id)),
          }));
        boundArrayHelpers?.remove(itemIndex);
      }
      setDeleteLoader(false);
      closeModal();
    });
  };

  // handle image modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsImageCropperModalOpen(true);
  };
  // handle image modal close
  const handleImageCropModalClose = () => {
    setIsImageCropperModalOpen(false);
  };

  // gets the cropped image file and sets in the form
  const handleImageCrop = (file) => {
    const selectedRowData = props?.whoWeAreValidation?.values?.[selectedArrayKey]?.[selectedImageIndex];

    props?.whoWeAreValidation?.setFieldValue(`${selectedArrayKey}.${selectedImageIndex}`, {
      ...selectedRowData,
      file,
    });
    handleImageCropModalClose();
  };

  return (
    <FormikProvider value={props?.whoWeAreValidation}>
      <Form
        className="position-relative"
        onSubmit={(e) => {
          e.preventDefault();
          props?.whoWeAreValidation.handleSubmit();
          return false;
        }}
      >
        {infoLoader ? <Loader /> : null}

        {/* carousel */}
        <Row>
          <div className="mb-3">
            <Label className="form-label fw-bold">{`${props.t("common.carousel")}`}</Label>
            <MultiMediaTableForm
              validation={props?.whoWeAreValidation}
              arrayKey="carousel"
              sectionName={props.t("common.item")}
              defaultRowValues={{ file: "", title: "", subtitle: "", link: "" }}
              handleDeleteItem={handleDeleteItem}
              columns={[
                {
                  label: props.t("common.no_"),
                  spacing: { sm: 1, md: 1, lg: 1 },
                  type: "counter",
                },
                {
                  key: "file",
                  label: `${props.t("common.image")}/${props.t("common.video")}`,
                  actionButtons: [
                    {
                      Component: (index, arrayKey) => (
                        <ActionButton
                          iconClasses="ri-crop-2-fill fs-18 cursor-pointer"
                          buttonId={`tooltip-${arrayKey}-${index}-cropping`}
                          tooltip={props.t("common.cropImage")}
                          onClick={() => {
                            setSelectedImageIndex(index);
                            setSelectedArrayKey(arrayKey);
                            handleLinkToAuctionModalOpen();
                          }}
                        />
                      ),
                      fileType: "image",
                    },
                  ],
                  spacing: { sm: 4, md: 4, lg: 4 },
                  type: "file",
                  accept: "image/*,video/*",
                  checkFileTypeValidation: (fileName) => {
                    return checkImageFileValidation(fileName) || checkVideoFileValidation(fileName);
                  },
                },
                {
                  key: "title",
                  label: props.t("common.title"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "subtitle",
                  label: props.t("common.subTitle"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "link",
                  label: props.t("common.link"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
              ]}
            />
          </div>
        </Row>

        {/* information */}
        <InformationFieldArray
          validation={props?.whoWeAreValidation}
          arrayKey={"meta_info"}
          blocks={[
            {
              label: props.t("common.informationNumber", { number: 1 }),
              key: "information_1",
            },
          ]}
        />
      </Form>
      <ImageCropperModal
        isModalOpen={isImageCropperModalOpen}
        onCancel={handleImageCropModalClose}
        title={props.t("common.cropImage")}
        src={props?.whoWeAreValidation?.values?.[selectedArrayKey]?.[selectedImageIndex]?.file}
        handleImageCrop={handleImageCrop}
      />
    </FormikProvider>
  );
};

export default withTranslation()(WhoWeAreContent);
