import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  DEBOUNCE_TIME,
  DROPDOWN_DEFAULT_LIMIT,
  INVOICE_DOCUMENT,
  RETURN_GUIDE_DOCUMENT,
} from "../../../helpers/constants";
import {
  getColumnDetail,
  getCreditNoteItemsData,
  getFormattedDocumentLines,
  getReferenceDocumentLabel,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import { SET_MODAL_DOCUMENTS } from "../../../store/invoicing/actionTypes";
import { getInvoices } from "../../../store/invoicing/actions";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";

const TransportDocumentLineModal = ({
                                      selectedLots,
                                      selectedCustomer,
                                      isModalOpen,
                                      handleModalClose,
                                      addDocumentLine,
                                      t,
                                    }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const INVOICES_OPTIONS = useSelector((state) => state?.Invoicing?.modal?.data);

  const saveLoader = false;
  const tableLoader = false;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [documentLines, setDocumentLines] = useState([]);
  const [itemsPayload, setItemsPayload] = useState({});
  const [invoiceSearchLoader, setInvoiceSearchLoader] = useState(false);
  const [invoicePayload, setInvoicePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: selectedCustomer
      ? {
        customer: selectedCustomer,
        document_type__description__in: [INVOICE_DOCUMENT, RETURN_GUIDE_DOCUMENT],
      }
      : {
        document_type__description__in: [INVOICE_DOCUMENT, RETURN_GUIDE_DOCUMENT],
      },
    exclude: {
      id: id,
      is_cancel: true,
    },
  });

  // to handle the invoice search
  const handleInvoiceSearch = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_document__icontains: value },
    }));
  };
  // debounce for invoice search
  const invoiceSearchDebounce = useMemo(() => {
    return debounce(handleInvoiceSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      invoiceSearchDebounce.cancel();
    };
  }, [invoiceSearchDebounce]);

  useEffect(() => {
    // To reset the modal documents when the modal is closed
    return () => {
      dispatch({
        type: SET_MODAL_DOCUMENTS,
        payload: {
          data: [],
          total: 0,
        },
      });
    };
  }, []);

  // to get the list of invoices
  useEffect(() => {
    if (isModalOpen) {
      setInvoiceSearchLoader(true);
      dispatch(getInvoices(invoicePayload)).then((res) => {
        dispatch({
          type: SET_MODAL_DOCUMENTS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        setInvoiceSearchLoader(false);
      });
    }
  }, [dispatch, invoicePayload, isModalOpen]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      invoice: "",
    },
    validationSchema: Yup.object({
      invoice: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.invoice")}`),
    }),
    onSubmit: (values) => {
      handleAddDocumentLine(values);
    },
  });

  // handle add/edit document
  const handleAddDocumentLine = (values) => {
    if ((isAllSelected && documentLines?.length === 0) || (!isAllSelected && selectedRows?.length === 0)) {
      toast.info(t("information.atleastOneItem", { module: t("common.documentLine") }));
      return;
    }

    let items = [];
    if (isAllSelected && documentLines?.length) {
      items = getFormattedDocumentLines([
        ...documentLines?.map((row) => ({
          ...row,
          invoice_item_id: row?.id,
          positionInContract: row?.auction_lot?.contract_item?.contract_item_no || row?.contract_item?.contract_item_no,
        })),
      ])?.map((row) => ({
        ...row,
        invoice_id: values?.invoice?.id,
      }));
    }
    if (selectedRows.length > 0 && !isAllSelected) {
      items = getFormattedDocumentLines([...selectedRows?.map((row) => ({
        ...row,
        invoice_item_id: row?.id,
        positionInContract: row?.auction_lot?.contract_item?.contract_item_no || row?.contract_item?.contract_item_no,
      }))])?.map(
        (row) => ({
          ...row,
          invoice_id: values?.invoice?.id,
        }),
      );
    }
    addDocumentLine(items);
  };

  // handle reset form with all other maunual states
  const handleReset = () => {
    validation.resetForm();
    setIsAllSelected(false);
    setSelectedRows([]);
    setItemsPayload({});
  };

  // useEffect to filter the invoices by the selected customer id
  useEffect(() => {
    if (selectedCustomer && isModalOpen) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, customer: selectedCustomer },
      }));
    }
  }, [selectedCustomer, isModalOpen]);

  const handleLocalSearch = (data, id, title, description) => {
    let filteredData = data;
    if (id) {
      filteredData = filteredData.filter((item) => String(item?.id)?.includes(id));
    }
    if (title) {
      filteredData = filteredData.filter((item) => item?.item_title?.toLowerCase()?.includes(title));
    }
    if (description) {
      filteredData = filteredData.filter((item) => item?.item_description?.toLowerCase()?.includes(description));
    }

    return filteredData;
  };

  // useEffect to set the document lines according to the selected invoice
  useEffect(() => {
    if (validation?.values?.invoice?.document_lines?.length > 0) {
      setDocumentLines(
        handleLocalSearch(
          getCreditNoteItemsData(validation?.values?.invoice?.document_lines),
          itemsPayload?.filter?.item_id,
          itemsPayload?.filter?.item_title,
          itemsPayload?.filter?.item_description,
        ).filter((lineItem) => {
          if (lineItem?.auction_lot?.id && selectedLots?.auction_lots?.length > 0) {
            if (selectedLots?.auction_lots?.includes(lineItem?.auction_lot?.id)) return false;
          }
          if (lineItem?.contract_item?.id && selectedLots?.contract_items?.length > 0) {
            if (selectedLots?.contract_items?.includes(lineItem?.contract_item?.id)) return false;
          }
          if (lineItem?.product?.id && selectedLots?.products?.length > 0) {
            if (selectedLots?.products?.includes(lineItem?.product?.id)) return false;
          }
          return true;
        }),
      );
    } else {
      setDocumentLines([]);
    }
  }, [validation?.values?.invoice, itemsPayload, selectedLots]);

  // use effect to reset the modal form
  useEffect(() => {
    if (!isModalOpen) {
      handleReset();
    }
  }, [isModalOpen]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="item_id"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.id ? row?.id : "-"),
      width: "20%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"item_title"}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.auction_lot?.id
          ? getColumnDetail(row?.auction_lot?.contract_item?.title_en, row?.auction_lot?.contract_item?.title_pt) || "-"
          : row?.contract_item?.id
            ? getColumnDetail(row?.contract_item?.title_en, row?.contract_item?.title_pt) || "-"
            : row?.product?.id
              ? row?.product?.product_name || "-"
              : "-",
      width: "32.5%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"item_description"}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.auction_lot?.id
          ? getColumnDetail(
          row?.auction_lot?.contract_item?.description_en,
          row?.auction_lot?.contract_item?.description_pt,
        ) || "-"
          : row?.contract_item?.id
            ? getColumnDetail(row?.contract_item?.description_en, row?.contract_item?.description_pt) || "-"
            : "-",
      width: "32.5%",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="lg"
      className="modal-header-width-100"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{t("common.addTitle", { module: t("common.documentLine") })}</span>

          {((isAllSelected && documentLines?.length > 0) || selectedRows?.length) && validation?.values?.invoice ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows?.length || documentLines?.length}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>
      <Form
        onKeyPress={onKeyPressForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <Row>
            <Col sm={6} md={6} lg={6}>
              <div className="mb-3">
                <Label htmlFor="invoice" className="form-label">
                  {`${t("common.document")}*`}
                </Label>
                <Select
                  isLoading={invoiceSearchLoader}
                  name="invoice"
                  options={INVOICES_OPTIONS}
                  getOptionLabel={getReferenceDocumentLabel}
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${
                    validation.touched.invoice && validation.errors.invoice ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.document")}`}
                  value={validation.values.invoice || ""}
                  onInputChange={invoiceSearchDebounce}
                  onChange={(invoice) => {
                    validation.setFieldValue("invoice", invoice);
                    setIsAllSelected(false);
                    setSelectedRows([]);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("invoice", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.invoice && validation.errors.invoice ? (
                  <p className="custom-invalid-feedback">{validation.errors.invoice}</p>
                ) : null}
              </div>
            </Col>
          </Row>

          {/*invoice items table */}
          <Card className="subcard-table-container table-light">
            {tableLoader ? <Loader /> : null}
            <DataTable
              className="mini-data-table"
              fixedHeader
              persistTableHead
              columns={columns}
              data={validation?.values?.invoice ? documentLines : []}
              noDataComponent={
                <NoRecords message={!validation?.values?.invoice && t("information.transportDocumentInvoices")} />
              }
              pagination
              paginationComponentOptions={{
                rowsPerPageText: t("common.rowsPerPage"),
                rangeSeparatorText: t("common.rangeSeparator"),
              }}
            />
          </Card>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            color="dark"
            btnTitle={t("common.cancel")}
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(TransportDocumentLineModal);
