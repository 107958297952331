import React, { useEffect } from "react";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

import { withTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractItems } from "../../../store/contracts/actions";
import CustomSearchInput from "../../common/CustomSearchInput";
import { getColumnDetail } from "../../../helpers/utils";
import NoRecords from "../../common/NoRecords";
import DataTable from "react-data-table-component";
import Loader from "../../common/Loader";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { AVAILABLE_FOR_AUCTION_LOT, AVAILABLE_LOT, RESERVED_LOT } from "../../../helpers/constants";

const INITIAL_GET_CONTRACT_ITEMS_PAYLOAD = {
  search: "",
  page: 1,
  limit: 10,
  filter: {
    lots_tag__tag_label__in: [AVAILABLE_LOT, AVAILABLE_FOR_AUCTION_LOT, RESERVED_LOT],
  },
  exclude: { id__in: [] },
};

const AddLotModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { contractItems, totalItems } = useSelector((state) => state?.Contracts);
  // loader
  const [tableLoader, setTableLoader] = useState(true);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [exclude, setExclude] = useState([]);
  const [itemsPayload, setItemsPayload] = useState({
    ...INITIAL_GET_CONTRACT_ITEMS_PAYLOAD,
  });

  // handle getting contract items
  const handleGetContractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isModalOpen) {
      setItemsPayload({
        ...INITIAL_GET_CONTRACT_ITEMS_PAYLOAD,
      });
    }
  }, [isModalOpen]);

  // reset modal states
  useEffect(() => {
    if (!isModalOpen) {
      setSelectedRows([]);
      setIsAllSelected(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      handleGetContractItems(itemsPayload);
    }
  }, [handleGetContractItems, isModalOpen, itemsPayload]);

  const handleSaveBtn = () => {
    onSave(isAllSelected, selectedRows, itemsPayload?.filter, exclude);
  };

  const handleModalClose = () => {
    if (!loader) {
      setSelectedRows([]);
      setIsAllSelected(false);
      onCancel();
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleRowSelection = (row, event) => {
    const { checked } = event.target;
    // if (checked) {
    //   if (selectedRows?.find((item) => item === row?.id)) {
    //     setSelectedRows((prevState) => [
    //       ...prevState?.filter((item) => item !== row?.id),
    //     ]);
    //     setExclude((prevExclude) => [...prevExclude, row?.id]);
    //   } else {
    //     setSelectedRows((prevState) => [...prevState, row?.id]);
    //   }
    // }
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row?.id]);
    } else {
      setSelectedRows((prevState) => prevState?.filter((item) => item !== row?.id));
      setExclude((prevExclude) => [...prevExclude, row?.id]);
    }
  };

  // get item description -> artist, title, description
  const getItemDescription = (row) => {
    let description = [];
    if (row?.author?.id) description.push(row?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.title_pt) description.push(row?.title_pt);
      if (row?.description_pt) description.push(row?.description_pt);
    } else {
      if (row?.title_en) description.push(row?.title_en);
      if (row?.description_en) description.push(row?.description_en);
    }
    return description?.length > 0 ? description.join(", ") : "-";
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              if (event.target.checked) {
                setSelectedRows(contractItems.map((row) => row.id));
              } else {
                setSelectedRows([]);
              }
              setExclude([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          checked={Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            handleRowSelection(row, event);
          }}
        />
      ),
      width: "60px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.auctionNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"auction_lots__auction__auction_no__icontains"}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.auction?.auction_no ? row?.auction?.auction_no : "-"),
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.contractNo")} / ${t("common.item")} ${t(
            "common.no_"
          )}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.contract_item_no ? row?.contract_item_no : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.description")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => getItemDescription(row),
      width: "270px",
      wrap: true,
    },
  ];
  return (
    <Modal centered isOpen={isModalOpen} size="lg" toggle={handleModalClose} fade={false}>
      <ModalHeader className="lot-header w-100">
        <div className="d-flex w-100 flex-row justify-content-between">
          <span>
            {`${t("common.addTitle", {
              module: t("common.lot"),
            })}`}
          </span>
          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalItems > 0) || selectedRows.length ? (
              <>
                <Button
                  color="info"
                  onClick={() => {
                    setSelectedRows([]);
                    setIsAllSelected(false);
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {`${t("common.deselect")}`}
                  <Badge color="dark" className="ms-1">
                    {selectedRows.length || totalItems}
                  </Badge>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody
        className="subcard-table-container table-light"
        style={{ border: "0px", borderRadius: "5px", boxShadow: "2px" }}
      >
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            fixedHeader
            pointerOnHover
            className="data-table"
            persistTableHead
            columns={columns}
            data={contractItems}
            pagination
            paginationServer
            paginationTotalRows={totalItems}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            onRowClicked={(row) => handleRowSelection(row)}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            conditionalRowStyles={[
              {
                when: (row) => row?.item_status === "auction_lot",
                style: { backgroundColor: "lightgray" },
              },
            ]}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="dark"
          onClick={() => {
            handleModalClose();
          }}
          disabled={loader}
        >
          {t("common.cancel")}
        </Button>
        <Button
          color="primary"
          disabled={loader || (isAllSelected === false && selectedRows.length < 1) || totalItems < 1}
          className="btn-load d-flex"
          onClick={handleSaveBtn}
        >
          {loader ? (
            <span className="d-flex align-items-center ">
              <Spinner size="xs" className="flex-shrink-0">
                {t("common.loading")}...
              </Spinner>
            </span>
          ) : null}
          <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{`${t("common.add")}`}</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(AddLotModal);
