import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { PRODUCT_SERVICE_TYPES, PRODUCT_UNIT_TYPES } from "../../../helpers/constants/selectOptions";
import { getLabelValueArray, getTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";
import NumberInput from "../../common/NumberInput";

const AddDocumentStatusModal = ({ item, isModalOpen, onCancel, onSave, loader, t }) => {
  const VAT_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.VAT.data),
    "id",
    "rate"
  );
  const CURRENCIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Currencies?.data),
    "id",
    "code"
  );
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      product_name: item?.product_name || "",
      product_type: item?.product_type || "",
      value: item?.value,
      units: item?.units || "",
      vat: item?.vat?.id || "",
      currency: item?.currency?.id || "",
    },
    validationSchema: Yup.object({
      product_name: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.description")}`),
      product_type: Yup.string().required(`${t("common.please")} ${t("common.select")} ${t("common.type")}`),
      value: Yup.number()
        .min(0, t("validation.numberMin", { totalNumber: 0 }))
        .required(`${t("common.please")} ${t("common.enter")} ${t("common.value")}`),
      units: Yup.string().required(`${t("common.please")} ${t("common.select")} ${t("common.unit")}`),
      vat: Yup.number().required(`${t("common.please")} ${t("common.select")} ${t("common.vat_another")}`),
      currency: Yup.number().required(`${t("common.please")} ${t("common.select")} ${t("common.currency")}`),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {item?.id
          ? t("settings.billing.products&services.editProductOrService")
          : t("settings.billing.products&services.addProductOrService")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="product_name" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="product_name"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.description")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.product_name || ""}
              invalid={validation.touched.product_name && validation.errors.product_name ? true : false}
            />
            {validation.touched.product_name && validation.errors.product_name ? (
              <FormFeedback type="invalid">{validation.errors.product_name}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="product_type" className="form-label">
              {t("common.type")}
            </Label>
            <Select
              name="product_type"
              options={PRODUCT_SERVICE_TYPES}
              getOptionLabel={(e) => t(e.label)}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.type")}`}
              value={getTypeFromValue(PRODUCT_SERVICE_TYPES, validation.values.product_type) || ""}
              onChange={(product_type) => {
                validation.setFieldValue("product_type", product_type?.value);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("product_type", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.product_type && validation.errors.product_type ? (
              <p className="custom-invalid-feedback">{validation.errors.product_type}</p>
            ) : null}
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="value" className="form-label">
                  {t("common.value")}
                </Label>
                <NumberInput
                  name="value"
                  className="form-control"
                  placeholder={`${t("common.enter")} ${t("common.value")}`}
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.value}
                  invalid={validation.touched.value && validation.errors.value ? true : false}
                />
                {validation.touched.value && validation.errors.value ? (
                  <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="units" className="form-label">
                  {t("common.unit")}
                </Label>
                <Select
                  name="units"
                  options={PRODUCT_UNIT_TYPES}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("common.unit")}`}
                  value={getTypeFromValue(PRODUCT_UNIT_TYPES, validation.values.units) || ""}
                  onChange={(units) => {
                    validation.setFieldValue("units", units?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("units", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.units && validation.errors.units ? (
                  <p className="custom-invalid-feedback">{validation.errors.units}</p>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="vat" className="form-label">
                  {t("common.vat_another")}
                </Label>
                <Select
                  name="vat"
                  options={VAT_OPTIONS}
                  getOptionLabel={(e) => e.label + "%"}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("common.vat_another")}`}
                  value={getTypeFromValue(VAT_OPTIONS, validation.values.vat) || ""}
                  onChange={(vat) => {
                    validation.setFieldValue("vat", vat?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("vat", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.vat && validation.errors.vat ? (
                  <p className="custom-invalid-feedback">{validation.errors.vat}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="currency" className="form-label">
                  {t("common.currency")}
                </Label>
                <Select
                  name="currency"
                  options={CURRENCIES_OPTIONS}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("common.currency")}`}
                  value={getTypeFromValue(CURRENCIES_OPTIONS, validation.values.currency) || ""}
                  onChange={(currency) => {
                    validation.setFieldValue("currency", currency?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("currency", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.currency && validation.errors.currency ? (
                  <p className="custom-invalid-feedback">{validation.errors.currency}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddDocumentStatusModal);
