import {
  SET_AUCTION_LOT_TAG_OPTIONS,
  SET_AVAILABLE_LOTS,
  SET_CUSTOMERS_DATA,
  SET_CUSTOMER_DETAILS,
  SET_PAID_TO_SELLER_LOTS,
  SET_PERSON_DETAILS,
  SET_RETURNED_LOTS,
  SET_SHIPPING_OPTIONS,
  SET_SOLD_LOTS,
  SET_TAG_OPTIONS,
} from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
  selectedCustomerData: {},
  shippingOptions: {},
  tagOptions: {},
  auctionLotTagOptions: {},
  situation: {
    availableLots: { data: [], total: 0 },
    soldLots: { data: [], total: 0 },
    returnedLots: { data: [], total: 0 },
    paidToSellerLots: { data: [], total: 0 },
  },
};

const ProductsAndServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOMERS_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        all_ids: action.payload?.all_ids,
      };
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        selectedCustomerData: action.payload,
      };
    case SET_PERSON_DETAILS:
      return {
        ...state,
        selectedPersonData: action.payload,
      };
    case SET_SHIPPING_OPTIONS:
      return {
        ...state,
        shippingOptions: action.payload,
      };
    case SET_TAG_OPTIONS:
      return {
        ...state,
        tagOptions: action.payload,
      };
    case SET_AUCTION_LOT_TAG_OPTIONS:
      return {
        ...state,
        auctionLotTagOptions: action.payload,
      };
    case SET_AVAILABLE_LOTS:
      return {
        ...state,
        situation: {
          ...state?.situation,
          availableLots: {
            ...state?.situation?.availableLots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_SOLD_LOTS:
      return {
        ...state,
        situation: {
          ...state?.situation,
          soldLots: {
            ...state?.situation?.soldLots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_RETURNED_LOTS:
      return {
        ...state,
        situation: {
          ...state?.situation,
          returnedLots: {
            ...state?.situation?.returnedLots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_PAID_TO_SELLER_LOTS:
      return {
        ...state,
        situation: {
          ...state?.situation,
          paidToSellerLots: {
            ...state?.situation?.paidToSellerLots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default ProductsAndServices;
