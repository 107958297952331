import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NumberInput from "../../common/NumberInput";

const AddPositionModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const nextPosition = useSelector((state) => state.common.teamPositions.nextPosition);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      title_pt: "",
      title_en: "",
      position_no: nextPosition || "",
    },
    validationSchema: Yup.object({
      title_pt: Yup.string().required(
        t("validation.formatGeneral", {
          enterOrSelect: t("common.enter"),
          field: t("common.title"),
        })
      ),
      title_en: Yup.string().required(
        t("validation.formatGeneral", {
          enterOrSelect: t("common.enter"),
          field: `${t("common.title")} ${t("common.en")}`,
        })
      ),
      position_no: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.sequenceOrder"),
        })}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.addTitle", {
          module: t("settings.lots.positions.position"),
        })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="title_pt" className="form-label">
              {t("common.title")}
            </Label>
            <Input
              name="title_pt"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.title"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_pt || ""}
              invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
            />
            {validation.touched.title_pt && validation.errors.title_pt ? (
              <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label htmlFor="title_en" className="form-label">
              {t("common.title")} {t("common.en")}
            </Label>
            <Input
              name="title_en"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: `${t("common.title")} ${t("common.en")}`,
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_en || ""}
              invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
            />
            {validation.touched.title_en && validation.errors.title_en ? (
              <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">{t("common.sequenceOrder")}</Label>
            <NumberInput
              name="position_no"
              className="form-control"
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.sequenceOrder"),
              })}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.position_no}
              invalid={validation.touched.position_no && validation.errors.position_no ? true : false}
            />
            {validation.touched.position_no && validation.errors.position_no ? (
              <FormFeedback type="invalid">{validation.errors.position_no}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            onClick={(e) => validation.handleSubmit(e)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddPositionModal);
