import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddLocationModal = ({ location, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      code: location?.code || "",
      location_name_en: location?.location_name_en || "",
      location_name_pt: location?.location_name_pt || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        // .matches(/^[A-Z]+$/, t("settings.general.language.codeValidationMsg1"))
        .max(5, t("common.validataionMessageForMax5Chars"))
        .required(
          t("common.requiredValidationMsg", {
            field: `${t("common.code")}`,
          })
        ),
      location_name_en: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.description")}`,
        })
      ),
      location_name_pt: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.description")}`,
        })
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {`${
          location?.id
            ? t("common.editTitle", {
                module: `${t("settings.auctions.locations.location")} `,
              })
            : t("common.addTitle", {
                module: `${t("settings.auctions.locations.location")} `,
              })
        } `}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="code" className="form-label">
              {t("common.code")}
            </Label>
            <Input
              name="code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("sidebarMenu.locations")} ${t("common.code")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={validation.touched.code && validation.errors.code ? true : false}
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")} {t("common.en")}
            </Label>
            <Input
              name="location_name_en"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("sidebarMenu.locations")} ${t("common.description")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.location_name_en || ""}
              invalid={validation.touched.location_name_en && validation.errors.location_name_en ? true : false}
            />
            {validation.touched.location_name_en && validation.errors.location_name_en ? (
              <FormFeedback type="invalid">{validation.errors.location_name_en}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")} {t("common.pt")}
            </Label>
            <Input
              name="location_name_pt"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("sidebarMenu.locations")} ${t("common.description")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.location_name_pt || ""}
              invalid={validation.touched.location_name_pt && validation.errors.location_name_pt ? true : false}
            />
            {validation.touched.location_name_pt && validation.errors.location_name_pt ? (
              <FormFeedback type="invalid">{validation.errors.location_name_pt}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddLocationModal);
