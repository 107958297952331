import React, { useEffect } from "react";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { withTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import i18next from "i18next";
import CustomButton from "../../../common/CustomButton";
import NoRecords from "../../../common/NoRecords";
import Loader from "../../../common/Loader";
import CustomSearchInput from "../../../common/CustomSearchInput";
import { getColumnDetail } from "../../../../helpers/utils";
import { getLotLists } from "../../../../store/auctions/lotActions";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";
import CustomLinkWrapper from "../../../common/CustomLinkWrapper";
import CustomColumnPhoto from "../../../common/CustomColumnPhoto";
import API from "../../../../helpers/api/api_interceptor";

const AddHighlightModal = ({ selectedLots, auctionId, isModalOpen, onCancel, onSave, t }) => {
  const dispatch = useDispatch();
  const { data: lotsData, total: totalLots } = useSelector((state) => state?.Auctions?.lots);
  const [addLoader, setAddLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "lot_no",
    filter: {},
    exclude: { id__in: selectedLots },
  });

  //   to set auction filter
  useEffect(() => {
    if (auctionId && isModalOpen) {
      setLotsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, auction: auctionId },
        exclude: { id__in: selectedLots },
      }));
    }
  }, [auctionId, isModalOpen, selectedLots]);

  // handle getting lots item
  const handleGetLotLists = useCallback(
    (lotsPayload) => {
      setTableLoader(true);
      dispatch(getLotLists(lotsPayload)).then((res) => {
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (lotsPayload?.filter?.auction && isModalOpen) {
      handleGetLotLists(lotsPayload);
    }
  }, [handleGetLotLists, lotsPayload, isModalOpen]);

  // reset modal states
  useEffect(() => {
    if (!isModalOpen) {
      setSelectedRows([]);
      setIsAllSelected(false);
      setLotsPayload({
        page: 1,
        limit: DEFAULT_ROW_PER_PAGE,
        sort: "lot_no",
        filter: {},
      });
    }
  }, [isModalOpen]);

  const handleSaveBtn = () => {
    if (isAllSelected) {
      setAddLoader(true);
      API.get(`/auctions/lots`, {
        params: {
          page_number: lotsPayload?.page,
          page_size: lotsPayload?.limit,
          filter: lotsPayload?.filter,
          sort: lotsPayload?.sort,
          exclude: lotsPayload?.exclude,
        },
      }).then((res) => {
        setAddLoader(false);
        if (res && res.status && res.status === 200) {
          onSave(res?.data?.data?.results);
        } else {
          onSave([]);
        }
      });
    } else {
      onSave(selectedRows);
    }
  };

  const handleModalClose = () => {
    if (!addLoader) {
      setSelectedRows([]);
      setIsAllSelected(false);
      onCancel();
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleRowSelection = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item?.id === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row]);
      }
    }
  };

  // get item description -> artist, title, description
  const getLotDescription = (row) => {
    let description = [];
    if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
      if (row?.contract_item?.description_pt) description.push(row?.contract_item?.description_pt);
    } else {
      if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
      if (row?.contract_item?.description_en) description.push(row?.contract_item?.description_en);
    }
    return description?.length > 0 ? description.join(", ") : "-";
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            handleRowSelection(row);
          }}
        />
      ),
      width: "70px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper
          link={row?.contract_item?.default_photo}
          title={<CustomColumnPhoto sourceUrl={row?.contract_item?.default_photo} />}
        />
      ),
      width: "110px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.lotNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"search_lot_no__icontains"}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.lot_no ? row?.lot_no + row?.lot_alpha : "-"),
      width: "130px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.description")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => getLotDescription(row),
      width: "250px",
      wrap: true,
    },
  ];
  return (
    <Modal centered isOpen={isModalOpen} size="lg" toggle={handleModalClose} fade={false}>
      <ModalHeader className="lot-header w-100">
        <div className="d-flex w-100 flex-row justify-content-between">
          <span>
            {`${t("common.addTitle", {
              module: t("common.highlight"),
            })}`}
          </span>
          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalLots > 0) || selectedRows.length ? (
              <>
                <Button
                  color="info"
                  onClick={() => {
                    setSelectedRows([]);
                    setIsAllSelected(false);
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {`${t("common.deselect")}`}
                  <Badge color="dark" className="ms-1">
                    {selectedRows.length || totalLots}
                  </Badge>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody
        className="subcard-table-container table-light"
        style={{ border: "0px", borderRadius: "5px", boxShadow: "2px" }}
      >
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            fixedHeader
            pointerOnHover
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...lotsData]}
            pagination
            paginationServer
            paginationPerPage={lotsPayload?.limit}
            paginationTotalRows={totalLots}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            onRowClicked={(row) => handleRowSelection(row)}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            conditionalRowStyles={[
              {
                when: (row) => row?.item_status === "auction_lot",
                style: { backgroundColor: "lightgray" },
              },
            ]}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          color={"dark"}
          btnTitle={t("common.cancel")}
          onClick={() => {
            handleModalClose();
          }}
          disabled={addLoader}
        />
        <CustomButton
          type="button"
          btnTitle={t("common.add")}
          onClick={handleSaveBtn}
          isLoading={addLoader}
          disabled={addLoader || (isAllSelected === false && selectedRows.length < 1) || totalLots < 1}
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(AddHighlightModal);
