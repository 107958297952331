import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import ActionButton from "../../../components/common/ActionButton";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomMultiSelect from "../../../components/common/CustomMultiSelect";
import CustomSearchInput from "../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../components/common/DeleteModal";
import Loader from "../../../components/common/Loader";
import NoRecords from "../../../components/common/NoRecords";
import AddFAQModal from "../../../components/pages/website-management/FAQs/AddFAQModal";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { FAQ_LANGUAGE_TYPES, SELECT_ALL_OPTION, TYPE_OF_FAQS } from "../../../helpers/constants/selectOptions";
import { checkSelectAllSelected, getLabelFromArray, getValuesFromArray } from "../../../helpers/utils";
import { addFAQ, deleteFAQ, getFAQsData, updateFAQ } from "../../../store/FAQs/actions";

const FAQs = (props) => {
  const dispatch = useDispatch();
  const { data: FAQData, total: totalFAQ } = useSelector((state) => state?.FAQs);

  const [FAQsPayload, setFAQsPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
  });

  const [selectedFAQ, setSelctedFAQ] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [selectedFAQTypeOptions, setSelectedFAQTypeOptions] = useState([]);
  const [selectedLanguageTypeOptions, setSelectedLanguageTypeOptions] = useState([]);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const handleOpenFAQModal = (FAQData) => {
    setSelctedFAQ(FAQData);
    setIsFAQModalOpen(true);
  };
  const handleCloseFAQModal = () => {
    setSelctedFAQ(null);
    setIsFAQModalOpen(false);
  };

  const handleSaveFAQ = (values) => {
    // check for add or edit
    if (selectedFAQ && selectedFAQ?.id) {
      // edit FAQ
      setSaveLoader(true);
      dispatch(updateFAQ(selectedFAQ?.id, values))
        .then((res) => {
          if (res) {
            handleCloseFAQModal();
            handleGetFAQs(FAQsPayload);
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add FAQ
      setSaveLoader(true);
      dispatch(addFAQ(values))
        .then((res) => {
          if (res) {
            handleCloseFAQModal();
            handleGetFAQs(FAQsPayload);
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (FAQData) => {
    setSelctedFAQ(FAQData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelctedFAQ(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteFAQ = () => {
    if (selectedFAQ && selectedFAQ?.id) {
      setDeleteLoader(true);
      dispatch(deleteFAQ(selectedFAQ?.id))
        .then((res) => {
          if (res) {
            handleGetFAQs(FAQsPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setFAQsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setFAQsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle dropdown type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setFAQsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload?.filter?.[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setFAQsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setFAQsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload?.filter?.[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle get FAQs
  const handleGetFAQs = useCallback(
    (FAQsPayload) => {
      setTableLoader(true);
      dispatch(getFAQsData(FAQsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetFAQs(FAQsPayload);
  }, [FAQsPayload, handleGetFAQs]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.sequenceOrder")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="sequence_order"
            className="column-search-input"
            payload={FAQsPayload}
            setPayload={setFAQsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.sequence_order ? row?.sequence_order : "-"),
      width: "142px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.question")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="question__icontains"
            className="column-search-input"
            payload={FAQsPayload}
            setPayload={setFAQsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.question ? row?.question : "-"),
      width: "320px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.answer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="answer__icontains"
            className="column-search-input"
            payload={FAQsPayload}
            setPayload={setFAQsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.answer ? row?.answer : "-"),
      width: "320px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.FAQType")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...TYPE_OF_FAQS]}
            selectedOptions={selectedFAQTypeOptions}
            handleOnSelection={(values) => {
              setSelectedFAQTypeOptions(values);
              handleDropdownFilter(values, "faq_type__in", setSelectedFAQTypeOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.faq_type ? getLabelFromArray(TYPE_OF_FAQS, row?.faq_type) : "-"),
      width: "214px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.languageType")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...FAQ_LANGUAGE_TYPES]}
            selectedOptions={selectedLanguageTypeOptions}
            handleOnSelection={(values) => {
              setSelectedLanguageTypeOptions(values);
              handleDropdownFilter(values, "language_type__in", setSelectedLanguageTypeOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.language_type ? getLabelFromArray(FAQ_LANGUAGE_TYPES, row?.language_type) : "-"),
      width: "214px",
      wrap: true,
    },

    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <ActionButton
            iconClasses={"ri-pencil-fill fs-18"}
            buttonId={`tooltip-${row?.id}-edit`}
            tooltip={props.t("common.edit")}
            onClick={() => {
              handleOpenFAQModal(row);
            }}
          />
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "214px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.FAQs")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title d-flex flex-row align-items-center">
            <div>{props.t("sidebarMenu.FAQs")}</div>
            <div className="contain-header-right ms-auto">
              <CustomAddButton
                color={"success"}
                btnTitle={props.t("common.addTitle", {
                  module: props.t("common.FAQ"),
                })}
                onClick={handleOpenFAQModal}
              />
            </div>
          </CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...FAQData]}
            paginationDefaultPage={FAQsPayload?.page}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            pagination
            paginationServer
            paginationTotalRows={totalFAQ}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* add/edit FAQ modal */}
      <AddFAQModal
        FAQ={selectedFAQ}
        isModalOpen={isFAQModalOpen}
        loader={saveLoader}
        onCancel={handleCloseFAQModal}
        onSave={handleSaveFAQ}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteFAQ}
        title={`${props.t("common.delete")} ${props.t("common.FAQ")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.FAQ"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(FAQs);
