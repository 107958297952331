import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTagChip from "../../../../components/common/CustomTagChip";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddLotsTags from "../../../../components/pages/settings/AddLotsTags";
import { generateContrastColor, getColumnDetail } from "../../../../helpers/utils";
import { addTag, deleteTag, getTags, updateTag } from "../../../../store/lots-tags/actions";

const Tags = (props) => {
  const dispatch = useDispatch();
  const { data: tagsData, total: totalTags } = useSelector((state) => state?.LotsTags);

  const [tagsPayload, setTagsPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    sort: "",
    filter: {
      id__icontains: "",
      [`${getColumnDetail("tag_label", "tag_label_pt")}__icontains`]: "",
      tag_name__icontains: "",
    },
  });

  const [selectedTag, setSelectedTag] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // add or edit action handlers
  const handleTagModalOpen = (tagData) => {
    setSelectedTag(tagData);
    setIsTagModalOpen(true);
  };
  const handleTagModalClose = () => {
    setSelectedTag(null);
    setIsTagModalOpen(false);
  };
  const handleSaveTag = (values, formikValidation) => {
    // check for add or edit
    if (selectedTag && selectedTag?.id) {
      // edit tag
      setSaveLoader(true);
      dispatch(updateTag(selectedTag?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleTagModalClose();
            formikValidation.resetForm();
            handleGetTags(tagsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add tag
      setSaveLoader(true);
      dispatch(addTag(values))
        .then((res) => {
          if (res) {
            // success handler
            handleTagModalClose();
            formikValidation.resetForm();
            handleGetTags(tagsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (tagData) => {
    setSelectedTag(tagData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedTag(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteTag = () => {
    if (selectedTag && selectedTag?.id) {
      setDeleteLoader(true);
      dispatch(deleteTag(selectedTag?.id))
        .then((res) => {
          if (res) {
            handleGetTags(tagsPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setTagsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setTagsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    setTagsPayload((prevState) => ({
      ...prevState,
      sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
    }));
  }, [orderBy, sortingOrder]);

  // handle get tags
  const handleGetTags = useCallback(
    (tagsPayload) => {
      setTableLoader(true);
      dispatch(getTags(tagsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetTags(tagsPayload);
  }, [handleGetTags, tagsPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={tagsPayload}
            setPayload={setTagsPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "130px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader columnTitle={props.t("common.tag")} />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={`${getColumnDetail("tag_label", "tag_label_pt")}__icontains`}
            className="column-search-input"
            payload={tagsPayload}
            setPayload={setTagsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <CustomTagChip
          title={getColumnDetail(row?.tag_label, row?.tag_label_pt)}
          bgColor={row?.tag_color}
          textColor={generateContrastColor(row?.tag_color)}
        />
      ),
      minWidth: "350px",
      center: true,
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader columnTitle={`${props.t("common.tag")} (${props.t("common.pt")})`} />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="tag_label_pt__icontains"
            className="column-search-input"
            payload={tagsPayload}
            setPayload={setTagsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <CustomTagChip
          title={row?.tag_label_pt}
          bgColor={row?.tag_color}
          textColor={generateContrastColor(row?.tag_color)}
        />
      ),
      minWidth: "350px",
      center: true,
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.description")}
            sortable
            sortableKey="tag_name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="tag_name__icontains"
            className="column-search-input"
            payload={tagsPayload}
            setPayload={setTagsPayload}
          />
        </div>
      ),
      selector: (row) => row?.tag_name,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleTagModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),

      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.tags")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput className="custom-search-input" payload={tagsPayload} setPayload={setTagsPayload} />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleTagModalOpen}
              btnTitle={props.t("common.addTitle", {
                module: props.t("sidebarMenu.tags"),
              })}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.tags")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...tagsData]}
            pagination
            paginationServer
            paginationTotalRows={totalTags}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddLotsTags
        tag={selectedTag}
        isModalOpen={isTagModalOpen}
        onCancel={handleTagModalClose}
        onSave={handleSaveTag}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteTag}
        title={`${props.t("common.delete")} ${props.t("settings.lots.tags.tag")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.lots.tags.tag"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Tags);
