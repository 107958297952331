import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { TAG_TYPES } from "../../../helpers/constants/selectOptions";
import { getTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";

const AddDocumentStatusModal = ({ documentStatus, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      fiscal_code: documentStatus?.fiscal_code || "",
      tag: documentStatus?.tag || "",
      description: documentStatus?.description || "",
    },
    validationSchema: Yup.object({
      fiscal_code: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.fiscalCode")}`),

      tag: Yup.string().required(`${t("common.please")} ${t("common.select")} ${t("common.tag")}`),
      description: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.description")}`),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {documentStatus?.id
          ? t("settings.billing.documentStatus.editDocumentStatus")
          : t("settings.billing.documentStatus.addDocumentStatus")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="fiscal_code" className="form-label">
              {t("common.fiscalCode")}
            </Label>
            <Input
              name="fiscal_code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.fiscalCode")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.fiscal_code || ""}
              invalid={validation.touched.fiscal_code && validation.errors.fiscal_code ? true : false}
            />
            {validation.touched.fiscal_code && validation.errors.fiscal_code ? (
              <FormFeedback type="invalid">{validation.errors.fiscal_code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="tag" className="form-label">
              {t("common.tag")}
            </Label>
            <Select
              name="tag"
              options={TAG_TYPES}
              getOptionLabel={(e) => (
                <div className="d-flex align-items-center">
                  <i class={`fs-18 ${e.iconClasses}`} />
                  <span className="ms-3">{t(e.text)}</span>
                </div>
              )}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.tag")}`}
              value={getTypeFromValue(TAG_TYPES, validation.values.tag) || ""}
              onChange={(tag) => {
                validation.setFieldValue("tag", tag?.value);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("tag", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.tag && validation.errors.tag ? (
              <p className="custom-invalid-feedback">{validation.errors.tag}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.description")}`}
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddDocumentStatusModal);
