import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
const ChooseNotificationTypeModal = ({ isModalOpen, onCancel, onSave, loader, handleNotificationTypeChange, t }) => {
  const [notificationType, setNotificationType] = useState("");

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={onCancel} fade={false} size="l">
        <ModalHeader>{t("common.chooseTheTypeOfNotification")}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row mb-3 gap-4">
            <div>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={() => handleNotificationTypeChange("pre")}
              />
              <Label className="form-label ms-2" for="flexRadioDefault1">
                {t("common.preAuctionNotifications")}
              </Label>
            </div>
            <div>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={() => handleNotificationTypeChange("post")}
              />
              <Label className="form-label ms-2" for="flexRadioDefault1">
                {t("common.postAuctionNotifications")}
              </Label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(ChooseNotificationTypeModal);
