import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";
import { formatAmount, getOnlyCustomerName, roundTo } from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import { getPaddles } from "../../../store/auctions/paddleActions";

const ChooseIssueProformaInvoicesModal = ({ isModalOpen, onSend, onCancel, loader, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const INITIAL_PAYLOAD = {
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "paddle_no",
  };

  const [documentsPayload, setDocumentsPayload] = useState({
    ...INITIAL_PAYLOAD,
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data: paddlesData, total: totalPaddles } = useSelector((state) => state?.Auctions?.paddles);

  // handle page change
  const handlePageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get paddles
  const handleGetPaddles = useCallback(
    (documentsPayload) => {
      setTableLoader(true);
      dispatch(getPaddles(documentsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaddles(documentsPayload);
  }, [documentsPayload, handleGetPaddles]);

  // to reset the form
  useEffect(() => {
    if (isModalOpen && id) {
      setDocumentsPayload({
        ...INITIAL_PAYLOAD,
        filter: { ...INITIAL_PAYLOAD?.filter, auction: id },
      });
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }, [isModalOpen, id]);

  const handleModalClose = () => {
    if (!loader) onCancel();
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              if (event.target.checked) {
                setSelectedRows(paddlesData?.map((item) => item?.id));
              } else {
                setSelectedRows([]);
              }
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "68px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.paddleNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="paddle_no__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.paddle_no ? <span style={{ color: row?.color_code }}>{row?.paddle_no}</span> : "-"),
      width: "210px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.id ? (
          <span className="cursor-pointer" style={{ color: row?.color_code }}>
            {getOnlyCustomerName(row?.customer)}
          </span>
        ) : (
          "-"
        ),
      width: "452px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.totalPurchase")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total_purchase__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span style={{ color: row?.color_code }}>{formatAmount(row?.total_purchase || 0) + " €"}</span>
      ),
      width: "452px",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="xl"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select")} ${t("common.and")} ${t("common.issueProformaInvoices")}`}</span>
          {(isAllSelected && totalPaddles > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalPaddles}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            fixedHeader
            persistTableHead
            columns={columns}
            data={[...paddlesData]}
            pagination
            paginationServer
            paginationTotalRows={totalPaddles}
            paginationDefaultPage={documentsPayload?.page}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={() => {
            onSend({
              paddle_ids: selectedRows,
            });
          }}
          isLoading={loader}
          btnTitle={t("common.issue")}
          disabled={(isAllSelected && totalPaddles === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ChooseIssueProformaInvoicesModal);
