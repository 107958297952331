import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddPeriodModal = ({ period, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      title_en: period?.title_en || "",
      title_pt: period?.title_pt || "",
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.descriptionEn")}`),
      title_pt: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.descriptionPt")}`),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {period?.id
          ? t("common.editTitle", {
              module: t("settings.lots.periods.period"),
            })
          : t("common.addTitle", {
              module: t("settings.lots.periods.period"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="title_en" className="form-label">
              {t("common.descriptionEn")}
            </Label>
            <Input
              name="title_en"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionEn")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_en || ""}
              invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
            />
            {validation.touched.title_en && validation.errors.title_en ? (
              <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="title_pt" className="form-label">
              {t("common.descriptionPt")}
            </Label>
            <Input
              name="title_pt"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionPt")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_pt || ""}
              invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
            />
            {validation.touched.title_pt && validation.errors.title_pt ? (
              <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddPeriodModal);
