import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Button, Card, CardHeader, Input, Label } from "reactstrap";
import CustomSearchInput from "../../components/common/CustomSearchInput";
import Loader from "../../components/common/Loader";
import NoRecords from "../../components/common/NoRecords";

import {
  exportToexcel,
  formatAmount,
  generateExcelDataForCheckingAccount,
  getDateInDDMMMYYYYFormat,
  getDateInDDMMYYYYFormat,
  getDateInYYYYMMDDFormat,
  getOnlyCustomerName,
  openFileURL,
} from "../../helpers/utils";

import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import CustomButton from "../../components/common/CustomButton";
import CustomDropdownButton from "../../components/common/CustomDropdownButton";
import CustomTextLink from "../../components/common/CustomTextLink";
import DateRangeInput from "../../components/common/DateRangeInput";
import API from "../../helpers/api/api_interceptor";
import {
  CREDIT_NOTE,
  CREDIT_NOTE_LOT,
  DEBIT_NOTE,
  DEBIT_NOTE_LOT,
  DEFAULT_ROW_PER_PAGE,
  INVOICE_DOCUMENT,
  RECEIPT_DOCUMENT,
  SALES_NOTE,
} from "../../helpers/constants";
import { exportCustomerCheckingAccount, getCustomerDetails } from "../../store/customers/actions";
import { exportInvoice, getInvoices } from "../../store/invoicing/actions";

const CustomerDocuments = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paramId } = useParams();
  const customerData = useSelector((state) => state?.Customers?.selectedCustomerData);
  const { data: invoicesData, total: totalInvoices, closing_balance } = useSelector((state) => state?.Invoicing);
  const closingBalance = closing_balance || 0;

  const [invoicePayload, setInvoicePayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "date",
    filter: paramId
      ? {
          is_cancel: false,
          customer: paramId,
          document_type__description__in: [
            INVOICE_DOCUMENT,
            RECEIPT_DOCUMENT,
            CREDIT_NOTE,
            CREDIT_NOTE_LOT,
            SALES_NOTE,
            DEBIT_NOTE,
            DEBIT_NOTE_LOT,
          ],
        }
      : {},
    customer_id: paramId,
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [exportPDFLoader, setExportPDFLoader] = useState(false);
  const [exportExcelLoader, setExportExcelLoader] = useState(false);
  const [viewPTDocumentLoader, setViewPTDocumentLoader] = useState(false);
  const [viewENDocumentLoader, setViewENDocumentLoader] = useState(false);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);

  // handle open mail app
  const handleOpenMailApp = (mail) => {
    if (mail) {
      window.open(`mailto:${mail}`);
    } else {
      window.open(`mailto://`);
    }
  };

  // to handle export Invoice
  const handleViewDocument = (invoice, isEnglish) => {
    const payload = { isEnglish };
    if (isEnglish) {
      setViewENDocumentLoader(true);
    } else {
      setViewPTDocumentLoader(true);
    }
    setSelectedInvoice(invoice?.id);
    dispatch(exportInvoice(invoice?.id, payload)).then((res) => {
      if (res) {
        openFileURL(res, "download");
        handleOpenMailApp(customerData?.email);
      }
      setSelectedInvoice(null);
      setViewENDocumentLoader(false);
      setViewPTDocumentLoader(false);
    });
  };

  // to handle the pdf exporting
  const handleExportPDF = (isEnglish) => {
    const payload = {
      is_english: isEnglish,
      ids: selectedRows,
      filter: invoicePayload?.filter,
      document_search: invoicePayload?.search,
    };
    setExportPDFLoader(true);
    dispatch(exportCustomerCheckingAccount(paramId, payload)).then((res) => {
      if (res) {
        openFileURL(res, "download");
      }
      setExportPDFLoader(false);
    });
  };

  // handle export to excel
  const handleExportExcel = () => {
    setExportExcelLoader(true);
    let filter = {};
    if (selectedRows.length > 0) {
      filter.id__in = selectedRows;
    }
    API.get(`/billings/documents`, {
      params: {
        search: invoicePayload?.search,
        sort: invoicePayload?.sort,
        filter: { ...filter, ...invoicePayload?.filter },
      },
    })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          exportToexcel(
            generateExcelDataForCheckingAccount(res?.data?.data?.results),
            `${props.t("common.checkingAccount")}-${getDateInDDMMYYYYFormat(new Date())}`,
            [20, 15, 20, 20, 20]
          );
        }
        setExportExcelLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setExportExcelLoader(false);
      });
  };

  // handle page change
  const handlePageChange = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };
  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get invoices
  const handleGetInvoices = useCallback((invoicePayload) => {
    setTableLoader(true);
    dispatch(getInvoices(invoicePayload))
      .then((res) => {
        setTableLoader(false);
      })
      .catch((error) => {
        setTableLoader(false);
        console.log(error);
      });
  }, []);

  // to get the list of the invoices
  useEffect(() => {
    handleGetInvoices(invoicePayload);
  }, [invoicePayload]);

  // to get the customer by id
  useEffect(() => {
    if (paramId) {
      dispatch(getCustomerDetails({ customerId: paramId })).then((res) => {
        if (!res) {
          history.push("/customers");
        }
      });
    }
  }, [dispatch, paramId, history]);

  // handle date filter
  useEffect(() => {
    if (selectedDateRange) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          date__range: [
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)),
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)),
          ],
        },
      }));
    } else {
      setInvoicePayload((prevState) => {
        if (!prevState?.filter?.date__range) return prevState;
        delete prevState?.filter?.date__range;
        return { ...prevState };
      });
    }
  }, [selectedDateRange]);

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              if (selectedRows?.find((item) => item === row?.id)) {
                setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
              } else {
                setSelectedRows((prevState) => [...prevState, row?.id]);
              }
            }}
          />
        </Label>
      ),
      width: "114px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.documentNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="document_no__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.document_no ? (
          <CustomTextLink text={row?.document_no} handleClick={() => history.push(`/invoicing/edit/${row?.id}`)} />
        ) : (
          "-"
        ),
      width: "172px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.date")}</div>
          <DateRangeInput
            classes={"column-date-range"}
            inputValue={
              selectedDateRange
                ? `${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.startDate))}  ${props.t(
                    "common.to"
                  )}  ${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.endDate))}`
                : ""
            }
            rangeValue={selectedDateRange}
            onChange={(values) => {
              setSelectedDateRange(values);
            }}
            onClear={() => {
              setSelectedDateRange(null);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.date ? getDateInDDMMMYYYYFormat(new Date(row?.date)) : "-"),
      width: "286px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.credit")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="credit__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
          />
        </div>
      ),
      selector: (row) => formatAmount(row?.total_info?.credit || 0) + " €",
      width: "172px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.debit")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="debit__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
          />
        </div>
      ),
      selector: (row) => formatAmount(row?.total_info?.debit || 0) + " €",
      width: "172px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.balance")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="balance__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
          />
        </div>
      ),
      selector: (row) => formatAmount(row?.total_info?.balance || 0) + " €",
      width: "172px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.viewDocument")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="d-flex flex-row gap-2 text-decoration-none-cell">
          <>
            {row?.phc_file_pt !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewPTDocumentLoader}
                type="button"
                btnTitle={props.t("common.pt")}
                onClick={() => {
                  if (row?.phc_file_pt) openFileURL(row?.phc_file_pt, "preview");
                }}
              />
            ) : null}
            {row?.phc_file_en !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewENDocumentLoader}
                type="button"
                btnTitle={props.t("common.en")}
                onClick={() => {
                  if (row?.phc_file_en) openFileURL(row?.phc_file_en, "preview");
                }}
              />
            ) : null}
          </>
        </div>
      ),
      width: "286px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.customers")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomBreadcrumb
            data={[
              {
                label: props.t("sidebarMenu.customers"),
                link: "/customers",
              },
              {
                label: `${customerData?.customer_no} (${getOnlyCustomerName(customerData)})`,
                link: `/customers/edit/${paramId}`,
              },
              {
                label: props.t("common.checkingAccount"),
              },
            ]}
          />
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">
            <div className="container-header ">
              <CustomSearchInput
                className="custom-search-input"
                payload={invoicePayload}
                setPayload={setInvoicePayload}
              />
              <div className="contain-header-right d-flex flex-row gap-2">
                {(isAllSelected && totalInvoices > 0) || selectedRows.length ? (
                  <>
                    <Button
                      color="info"
                      onClick={() => {
                        setSelectedRows([]);
                        setIsAllSelected(false);
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {`${props.t("common.deselect")}`}
                      <Badge color="dark" className="ms-1">
                        {selectedRows.length || totalInvoices}
                      </Badge>
                    </Button>
                  </>
                ) : null}
                <div style={{ marginRight: 16 }} className="d-flex justify-content-center align-items-center">
                  Balance: {closingBalance}€
                </div>
                <CustomButton
                  type="button"
                  btnTitle={props.t("common.share")}
                  onClick={() => handleOpenMailApp(customerData?.email)}
                />
                <CustomDropdownButton
                  disabled={invoicesData <= 0}
                  isLoading={exportPDFLoader || exportExcelLoader}
                  btnTitle={props.t("common.export")}
                  options={[
                    {
                      label: props.t("common.pdf", {
                        lang: props.t("common.pt"),
                      }),
                      onClick: () => {
                        handleExportPDF(false);
                      },
                    },
                    {
                      label: props.t("common.pdf", {
                        lang: props.t("common.en"),
                      }),
                      onClick: () => {
                        handleExportPDF(true);
                      },
                    },
                    {
                      label: props.t("common.excel"),
                      onClick: () => {
                        handleExportExcel();
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...invoicesData]}
            pagination
            paginationServer
            paginationDefaultPage={invoicePayload?.page}
            paginationPerPage={invoicePayload?.limit}
            paginationTotalRows={totalInvoices}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default withTranslation()(CustomerDocuments);
