import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_WAREHOUSELIST } from "../actionTypes";

// list all warehouses
export const getWarehouses = (params) => async (dispatch) => {
  try {
    return API.get(`/users/warehouses`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        filter: params?.filter,
        sort: params?.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_WAREHOUSELIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Add  warehouse
export const addWarehouse = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/warehouses`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Edit warehouse
export const editWarehouse = (warehouseId, payload) => async (dispatch) => {
  try {
    return API.put(`/users/warehouses/${warehouseId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// delete warehouse
export const deleteWarehouse = (warehouseId) => async (dispatch) => {
  try {
    return API.delete(`/users/warehouses/${warehouseId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
