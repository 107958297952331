import React, { useEffect, useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getDateForInput } from "../../helpers/utils";

const DateTimePicker = ({ date, onChange, minDate, invalid }) => {
  const keyRef = useRef(Date.now());
  useEffect(() => {
    keyRef.current = minDate;
    return () => {
      keyRef.current = null;
    };
  }, [minDate]);

  return (
    <DateRangePicker
      key={keyRef.current}
      initialSettings={{
        ...{
          startDate: date ? new Date(date) : new Date(),
          autoApply: true,
          singleDatePicker: true,
          timePicker: true,
          timePickerIncrement: 5,
          autoUpdateInput: false,
          minDate: minDate ? new Date(minDate) : null,
          showDropdowns: true,
          timePicker24Hour: true,
        },
      }}
      onApply={(_, date) => {
        onChange(new Date(date?.startDate).toISOString());
      }}
    >
      <input
        value={date ? getDateForInput(date) : ""}
        type="text"
        className={`form-control test ${invalid ? "input-field-error" : ""}`}
        placeholder={`dd-mm-yyyy  --:-- --`}
        readOnly
      />
    </DateRangePicker>
  );
};

export default DateTimePicker;
