import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { MOVEMENT_TYPES, PRINTED_TYPES } from "../../../helpers/constants/selectOptions";
import { getMovementTypeFromValue, getPrintedTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";

const AddDocumentTypeModal = ({ documentType, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      fiscal_code: documentType?.fiscal_code || "",
      prefix: documentType?.prefix || "",
      description: documentType?.description || "",
      movement: documentType?.movement || "",
      printed: documentType?.printed || "",
      description_en: documentType?.description_en || "",
    },
    validationSchema: Yup.object({
      fiscal_code: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("settings.billing.documentTypes.fiscalCode")}`
      ),
      prefix: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("settings.billing.documentTypes.prefix")}`
      ),
      //   description: Yup.string().required(
      //     `${t("common.please")} ${t("common.enter")} ${t(
      //       "settings.billing.paymentTypes.descriptionPt"
      //     )}`
      //   ),
      movement: Yup.string().required(
        `${t("common.please")} ${t("common.select")} ${t("settings.billing.documentTypes.movement")}`
      ),
      printed: Yup.string().required(
        `${t("common.please")} ${t("common.select")} ${t("settings.billing.documentTypes.printed")}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {documentType?.id
          ? t("settings.billing.documentTypes.editDocumentType")
          : t("settings.billing.documentTypes.addDocumentType")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="fiscal_code" className="form-label">
              {t("settings.billing.documentTypes.fiscalCode")}
            </Label>
            <Input
              name="fiscal_code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.documentTypes.fiscalCode")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.fiscal_code || ""}
              invalid={validation.touched.fiscal_code && validation.errors.fiscal_code ? true : false}
            />
            {validation.touched.fiscal_code && validation.errors.fiscal_code ? (
              <FormFeedback type="invalid">{validation.errors.fiscal_code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="prefix" className="form-label">
              {t("settings.billing.documentTypes.prefix")}
            </Label>
            <Input
              name="prefix"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.documentTypes.prefix")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.prefix || ""}
              invalid={validation.touched.prefix && validation.errors.prefix ? true : false}
            />
            {validation.touched.prefix && validation.errors.prefix ? (
              <FormFeedback type="invalid">{validation.errors.prefix}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.descriptionPt")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionPt")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description_en" className="form-label">
              {t("common.descriptionEn")}
            </Label>
            <Input
              name="description_en"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionEn")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description_en || ""}
              invalid={validation.touched.description_en && validation.errors.description_en ? true : false}
            />
            {validation.touched.description_en && validation.errors.description_en ? (
              <FormFeedback type="invalid">{validation.errors.description_en}</FormFeedback>
            ) : null}
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="movement" className="form-label">
                  {t("settings.billing.documentTypes.movement")}
                </Label>
                <Select
                  name="movement"
                  options={MOVEMENT_TYPES}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("settings.billing.documentTypes.movement")}`}
                  value={getMovementTypeFromValue(validation.values.movement) || ""}
                  onChange={(movement) => {
                    validation.setFieldValue("movement", movement?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("movement", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.movement && validation.errors.movement ? (
                  <p className="custom-invalid-feedback">{validation.errors.movement}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="printed" className="form-label">
                  {t("settings.billing.documentTypes.printed")}
                </Label>
                <Select
                  name="printed"
                  options={PRINTED_TYPES}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("settings.billing.documentTypes.printed")}`}
                  value={getPrintedTypeFromValue(validation.values.printed) || ""}
                  onChange={(printed) => {
                    validation.setFieldValue("printed", printed?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("printed", true);
                    validation.handleBlur(e);
                  }}
                />

                {validation.touched.printed && validation.errors.printed ? (
                  <p className="custom-invalid-feedback">{validation.errors.printed}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddDocumentTypeModal);
