import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import NoRecords from "../../components/common/NoRecords";

import CustomTagChip from "../../components/common/CustomColorTagChip";
import Loader from "../../components/common/Loader";
import { PRIORITY_OPTIONS, STATUS_OPTIONS } from "../../helpers/constants/selectOptions";
import { generateContrastColor, getTypeFromValue } from "../../helpers/utils";
import { getTaskLists, updateTask } from "../../store/tasks/action";
import Notes from "../home/notes";

const INITIAL_PAYLOAD = {
  search: "",
  page: 1,
  limit: 10,
  sort: "status",
  filter: {
    id__icontains: "",
    description__icontains: "",
  },
};

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { data: taskData, total: totalTasks } = useSelector((state) => state.Tasks);

  const [isGetTasksLoader, setIsGetTasksLoader] = useState(false);
  const [taskPayload, setTaskPayload] = useState(INITIAL_PAYLOAD);

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.dashboard")}`;

  const handleGetTaskList = useCallback(
    (taskPayload) => {
      setIsGetTasksLoader(true);
      dispatch(getTaskLists(taskPayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setIsGetTasksLoader(false);
        })
        .catch((error) => {
          setIsGetTasksLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetTaskList(taskPayload);
  }, [handleGetTaskList, taskPayload]);

  const handleSaveTask = (selectedRow) => {
    if (selectedRow && selectedRow?.status === "pending") {
      const { id, created_by, ...payload } = selectedRow;
      dispatch(
        updateTask({
          id: selectedRow.id,
          values: {
            ...payload,
            user: payload?.user?.id,
            status: "solved",
          },
        })
      )
        .then((res) => {
          if (res) {
            //
            handleGetTaskList(taskPayload);
          } else {
            //
          }
        })
        .catch((error) => {});
    }
    if (selectedRow && selectedRow?.status === "solved") {
      const { id, created_by, ...payload } = selectedRow;
      dispatch(
        updateTask({
          id: selectedRow.id,
          values: {
            ...payload,
            user: payload?.user?.id,
            status: "pending",
          },
        })
      )
        .then((res) => {
          if (res) {
            //
            handleGetTaskList(taskPayload);
          } else {
            //
          }
        })
        .catch((error) => {});
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setTaskPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setTaskPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-2">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
        </div>
      ),
      selector: (row) => row?.description,
      width: "50%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-2">
          <div className="table-column-title d-flex justify-content-center align-items-center mb-2">
            {props.t("common.priority")}
          </div>
        </div>
      ),
      selector: (row) => (
        <>
          <CustomTagChip
            title={getTypeFromValue(PRIORITY_OPTIONS, row?.priority)?.label}
            bgColor={row?.priority === "low" ? "#F7CB73" : row?.priority === "normal" ? "#57BDD9" : "#CC0202"}
            textColor={generateContrastColor(
              row?.priority === "low" ? "#F7CB73" : row?.priority === "normal" ? "#57BDD9" : "#CC0202"
            )}
          />
        </>
      ),
      width: "25%",
      center: true,
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-2">
          <div className="table-column-title d-flex justify-content-center align-items-center mb-2">
            {props.t("common.status")}
          </div>
        </div>
      ),
      selector: (row) => (
        <span onClick={() => handleSaveTask(row)} className="cursor-pointer">
          <CustomTagChip
            title={getTypeFromValue(STATUS_OPTIONS, row?.status)?.label}
            bgColor={row?.status === "pending" ? "#FF5F33" : "#37B71D"}
            textColor={generateContrastColor(row?.status === "pending" ? "#FF5F33" : "#37B71D")}
          />
        </span>
      ),
      width: "25%",
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <Row>
          <Col xl={7}>
            <Card className="container-body card-section">
              {isGetTasksLoader ? <Loader /> : null}
              <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between p-2">
                <span className="card-title">{`${props.t("sidebarMenu.tasks")}`}</span>
              </CardHeader>
              {/* <CardHeader className="table-title">
                {props.t("sidebarMenu.tasks")}
              </CardHeader> */}
              <CardBody>
                <DataTable
                  fixedHeader
                  className="data-table dashboard-tasks"
                  persistTableHead
                  // selectableRows
                  columns={columns}
                  data={[...taskData]}
                  pagination
                  paginationServer
                  paginationTotalRows={totalTasks}
                  noDataComponent={<NoRecords />}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={{
                    rowsPerPageText: props.t("common.rowsPerPage"),
                    rangeSeparatorText: props.t("common.rangeSeparator"),
                  }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
            <Notes />
            {/* <Card className="container-body">
              <CardHeader className="table-title">
                {props.t("home.notes.note")}
              </CardHeader>
              <CardBody
                className="note-container-body"
                // onClick={() => handleNoteModalOpen(note)}
              >
                <Input
                  className="dec-input"
                  type="textarea"
                  value={"heskdlfndskjfbs sdfbjdskfds"}
                  style={{ height: "100%", width: "100%" }}
                  readOnly
                />

              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withTranslation()(Dashboard);
