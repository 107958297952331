import React from "react";
import { withTranslation } from "react-i18next";
import ActionButton from "../../../common/ActionButton";
import imagePlaceHolder from "../../../../assets/images/veritas/image-fallback-placeholder.jpg";

const HighlightComponent = ({
  item,
  image_url,
  lot_no,
  title,
  description,
  handleDelete,
  handleEditHighlight,
  ...props
}) => {
  return (
    <div className="highlight-component">
      <img className="lot-image" src={image_url || imagePlaceHolder} alt="lot" />
      <div className="d-flex flex-column align-items-center fs-14 text-center w-100">
        {lot_no && <strong style={{ padding: "8px 8px 0px 8px" }}>{lot_no}</strong>}
        {title && (
          <strong
            style={{
              padding: "8px 8px 0px 8px",
              width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </strong>
        )}
        {description && (
          <strong
            style={{
              padding: "8px 8px 0px 8px",
              width: "75%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {description}
          </strong>
        )}
      </div>
      <ActionButton
        iconClasses="ri-pencil-fill fs-18"
        buttonClasses={"highlight-edit-icon highlight-action-button"}
        buttonId={`tooltip-${item?.id}-edit`}
        tooltip={props.t("common.edit")}
        onClick={() => {
          handleEditHighlight(item);
        }}
      />
      <ActionButton
        iconClasses="ri-delete-bin-fill fs-18"
        buttonClasses={"highlight-delete-icon highlight-action-button"}
        buttonId={`tooltip-${item?.id}-delete`}
        tooltip={props.t("common.delete")}
        onClick={() => {
          handleDelete(item);
        }}
      />
    </div>
  );
};

export default withTranslation()(HighlightComponent);
