import i18next from "i18next";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import TabsComponent from "../../../common/TabsComponent";
import AppraisalContent from "./AppraisalContent";
import ArtConsultancy from "./ArtConsultancy";
import ArtExpertiseContent from "./ArtExpertiseContent";

// custom-tabs-style added to avoid default nav styling

const ServicesContent = ({ ...props }) => {
  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.appraisalValidation?.resetForm();
      props?.artExpertiseValidation?.resetForm();
      props?.artConsultancyValidation?.resetForm();
    };
  }, []);

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {

    if (
      props?.appraisalValidation?.dirty ||
      props?.artExpertiseValidation?.dirty ||
      props?.artConsultancyValidation?.dirty
    ) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        props?.setActivePageIndex(index);
      }
    } else {
      props?.setActivePageIndex(index);
    }
  };

  return (
    <div className="services-main-content">
      <TabsComponent
        controlledTabChange={true}
        activeTab={props?.activePageIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabStyleClasses="sub-tabs-style"
        tabData={[
          {
            label: i18next.t("common.appraisals"),
            Component: AppraisalContent,
            props: { pageType: "appraisals", ...props },
            disabled: false,
          },
          {
            label: i18next.t("common.artExpertise"),
            Component: ArtExpertiseContent,
            props: {
              pageType: "art_expertise",
              ...props,
            },
            disabled: false,
          },
          {
            label: i18next.t("common.artConsultancy"),
            Component: ArtConsultancy,
            props: {
              pageType: "art_consultancy",
              ...props,
            },
            disabled: false,
          },
        ]}
      />
    </div>
  );
};

export default withTranslation()(ServicesContent);
