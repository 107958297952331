import i18next from "i18next";
import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_CONTRACTS_IN_LOTS_LIST, SET_LOTS } from "./actionType";

// to get lots lists
export const getLotLists = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/lots`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params.sort,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_LOTS, payload: res?.data?.data, all_ids: res?.data?.data?.all_ids });
        return res;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// to get list of contracts
export const getContractsInLots = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        search_list: params?.search_list,
        sort: params?.sort,
        filter: params?.filter,
        auction_id: params?.auction_id,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CONTRACTS_IN_LOTS_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add lots
export const addLots = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/lots`, {
      auction_id: payload?.auctionId,
      contract_item_ids: payload?.contractIds,
      is_selected_all: payload?.isSelectedAll,
      filter: payload?.filter,
      contract_item_search: payload?.contract_item_search,
      exclude: payload?.exclude,
    }).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add contracts
export const addContracts = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/contract-lots`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update lots
export const updateLot = (payload, type) => async (dispatch) => {
  let payloadData;
  if (type === "paddle_no") {
    payloadData = {
      auction_id: payload?.auctionId,
      contract_item: payload?.contractItem,
      lot_no: payload?.lot_no,
      lot_alpha: payload?.lot_alpha,
      paddle: payload?.paddle,
      sale_value: payload?.sale_value,
    };
  } else if (type === "lot_no") {
    payloadData = {
      auction_id: payload?.auctionId,
      lot_no: payload?.lot_no,
      lot_alpha: payload?.lot_alpha,
      delete_lot_no: payload?.delete_lot_no,
    };
  }
  try {
    return API.put(`/auctions/lots/${payload?.lotId}`, payloadData).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to bulk update lot
export const bulkUpdateLot = (payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/lots/tag`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to bulk update lot
export const UpdateLotPublishStatus = (auctionId, payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/lots/publish`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete lot
export const deleteLot = (payload) => async (dispatch) => {
  let lotPayload;
  if (payload?.ids) {
    lotPayload = {
      ids: payload?.ids,
      is_selected_all: payload?.isSelectedAll,
      filter: payload?.filter,
      api_type: payload?.apiType,
      exclude: payload?.exclude,
    };
  } else {
    lotPayload = {
      contract_ids: payload?.contract_ids,
      is_selected_all: payload?.isSelectedAll,
      filter: payload?.filter,
      api_type: payload?.apiType,
      auction_id: payload?.auctionId,
      exclude: payload?.exclude,
    };
  }
  try {
    return API.delete(`/auctions/lots`, {
      data: lotPayload,
    }).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export catalogues shipping
export const exportCataloguesShipping = (auctionId) => async (dispatch) => {
  try {
    return API.get(`/auctions/customers/export/${auctionId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        if (res?.data?.data?.length === 0) {
          toast.info(i18next.t("information.noCatalogueShippingFound"));
        } else {
          toast.success(res?.data?.detail);
        }
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to import translated lots
export const uploadTranslatedLotsExcel = (id, payload) => async (dispatch) => {
  const formData = new FormData();
  for (var key in payload) {
    if (key === "excel_file") {
      formData.append(key, payload[key]);
    }
  }
  try {
    return API.post(`/auctions/lots/${id}/import-translations`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to import  lots
export const uploadLotsExcel = (id, payload) => async (dispatch) => {
  const formData = new FormData();
  for (var key in payload) {
    if (key === "excel_file") {
      formData.append(key, payload[key]);
    }
  }
  try {
    return API.post(`/auctions/lots/${id}/import`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to import  lot numbers
export const uploadLotNumbersExcel = (id, payload) => async (dispatch) => {
  const formData = new FormData();
  for (var key in payload) {
    if (key === "excel_file") {
      formData.append(key, payload[key]);
    }
  }
  try {
    return API.post(`/auctions/lots/${id}/import-lot-numbers`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
