import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { SET_AUCTION, SET_AUCTIOND_DATA, SET_BUYER_DOCUMENTS } from "./actionType";

// get auction list
export const getAuctionList = (params, skipReduxState) => async (dispatch) => {
  try {
    return API.get(`/auctions/`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        if (!skipReduxState)
          dispatch({
            type: SET_AUCTIOND_DATA,
            payload: {
              data: res?.data?.data?.results,
              total: res?.data?.data?.count,
            },
          });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the auction by id
export const getAuctionById = (auctionId, params) => async (dispatch) => {
  try {
    return API.get(`/auctions/${auctionId}`, { params: params }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_AUCTION, payload: res?.data?.data });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// form data append handler for adding/editing auction
const handleFormDataAppend = (payload) => {
  const formData = new FormData();

  for (var key in payload) {
    if ((key === "exhibition_start_date" || key === "exhibition_end_date") && payload[key] === null) {
      continue;
    } else if (key === "sessions" || key === "auction_collaborators" || key === "catalogues_shipping") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "auction_photo" && payload?.auction_photo) {
      formData.append(key, payload[key]);
    } else {
      formData.append(key, payload[key]);
    }
  }

  return formData;
};

// to add auction
export const addAuction = (payload) => async (dispatch) => {
  const formData = handleFormDataAppend(payload);
  try {
    return API.post(`/auctions/`, formData).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update auction
export const updateAuction = (id, payload) => async (dispatch) => {
  const formData = handleFormDataAppend(payload);
  try {
    return API.put(`/auctions/${id}`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_AUCTION, payload: res?.data?.data });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete auction
export const deleteAuction =
  ({ id }) =>
  async (dispatch) => {
    try {
      return API.delete(`/auctions/${id}`).then((res) => {
        if (res && res?.data) {
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// to get list of buyer documents
export const getBuyerDocuments = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/documents`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        search_list: params?.search_list,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_BUYER_DOCUMENTS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// send proforma invoices
export const sendProformaInvoices = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/proforma`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// issue sales notes
export const bulkIssueSalesNotes = (payload) => async () => {
  try {
    return API.post(`/billings/documents/bulk-sales/export`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// send sales notes
export const bulkSendSalesNotes = (payload) => async () => {
  try {
    return API.post(`/billings/documents/bulk-sales/export`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const notifyUsers = (auctionId, payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/absentee-bids/email/${auctionId}`, payload).then((res) => {
      if (res && res?.data) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export auction's situation
export const exportAuctionSituation = (auctionId, payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/situation/export/${auctionId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get bid requests for auction
export const getBidRequests = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/request-bids`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        filter: params?.filter,
        sort: params?.sort,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        return res;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// to update bid request
export const updateBidRequestStatus = (id, payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/request-bids/${id}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to bulk issue proforma
export const bulkIssueProforma = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/bulk-proforma/export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const notifySellers = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/sales-note/email`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
