import { ADD_VAT, DELETE_VAT, SET_VATs_DATA, UPDATE_VAT } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const VAT = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_VATs_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_VAT:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_VAT:
      const existingVatIndex = state.data.findIndex((currency) => currency.id === action.payload.id);
      const updatedVATsData = state.data;
      if (existingVatIndex !== -1) {
        updatedVATsData[existingVatIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedVATsData,
      };
    case DELETE_VAT:
      return {
        ...state,
        data: [...state.data.filter((item) => item.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default VAT;
