import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import CustomButton from "../../../components/common/CustomButton";
import CustomLinkWrapper from "../../../components/common/CustomLinkWrapper";
import Loader from "../../../components/common/Loader";
import { TYPE_PUBLISH, TYPE_SAVE } from "../../../helpers/constants";
import { checkImageFileValidation, createFileUrl, extractNameFromUrl } from "../../../helpers/utils";
import { getMyVeritasImages, uploadMyVertiasImages } from "../../../store/my-veritas/actions";

const MyVeritas = (props) => {
  const dispatch = useDispatch();
  const { login_image_en, login_image_pt } = useSelector((state) => state?.MyVeritas);
  const [loader, setLoader] = useState();
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [payload, setPayload] = useState({
    filter: {
      file_type: "login_image",
      save_type: "save",
    },
  });
  const [submitType, setSubmitType] = useState();

  // to get the my-veritas images
  const handleGetMyVeritasData = useCallback(
    (payload) => {
      setLoader(true);
      dispatch(getMyVeritasImages(payload)).then(() => {
        setLoader(false);
      });
    },
    [dispatch]
  );

  // useEffect to get the images
  useEffect(() => {
    handleGetMyVeritasData(payload);
  }, [payload, handleGetMyVeritasData]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      login_pt: login_image_pt || "",
      login_en: login_image_en || "",
    },
    validationSchema: Yup.object({
      login_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.image")}`
      ),
      login_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.image")}`
      ),
    }),
    onSubmit: (values) => {
      handleSaveAndPublish(values, submitType);
    },
  });

  // to format the save and publish payload
  const formatPayload = (values, save_type) => {
    return [
      {
        file_type: "login_image",
        language_type: "pt",
        save_type: save_type,
        file: values?.login_pt,
      },
      {
        file_type: "login_image",
        language_type: "en",
        save_type: save_type,
        file: values?.login_en,
      },
    ];
  };

  // to handle save and save&publish of images
  const handleSaveAndPublish = (values, save_type) => {
    const payload = formatPayload(values, save_type);
    if (save_type === TYPE_SAVE) setSaveLoader(true);
    if (save_type === TYPE_PUBLISH) setPublishLoader(true);
    dispatch(uploadMyVertiasImages(payload)).then((res) => {
      setSaveLoader(false);
      setPublishLoader(false);
    });
  };

  // to reset form
  const handleReset = () => {
    validation.resetForm();
  };

  // clear uploaded document
  const clearUploadedDocument = (key) => {
    validation.setFieldValue(key, "");
  };

  return (
    <div className="page-content layout-main-container">
      <Prompt when={validation?.dirty} message={props.t("confirmation.resetTabData")} />
      <Card className="container-body card-section">
        <CardHeader className="card-section-header">
          <span className="card-title">{props.t("sidebarMenu.myVeritas")}</span>
        </CardHeader>
        <CardBody className="position-relative">
          {loader ? <Loader /> : null}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <CardBody>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="login_pt" className="form-label">
                      {`${props.t("common.loginImage", {
                        language: props.t("common.pt"),
                      })}`}
                    </Label>
                    {validation?.values?.login_pt ? (
                      <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                        <CustomLinkWrapper
                          link={
                            typeof validation.values.login_pt === "string"
                              ? validation.values.login_pt
                              : createFileUrl(validation.values.login_pt)
                          }
                          title={
                            typeof validation.values.login_pt === "string"
                              ? extractNameFromUrl(validation.values.login_pt, "/", 5)
                              : validation.values.login_pt.name
                          }
                        />
                        <i
                          className="ri-close-fill fs-18 cursor-pointer text-danger"
                          onClick={() => {
                            clearUploadedDocument("login_pt");
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <Input
                          name="login_pt"
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            if (checkImageFileValidation(e.target.files[0]?.name)) {
                              validation.setFieldValue("login_pt", e.target.files[0]);
                            } else {
                              toast.error(props.t("validation.uploadFileFormat"));
                            }
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.login_pt || ""}
                          invalid={validation.touched.login_pt && validation.errors.login_pt ? true : false}
                        />
                        {validation.touched.login_pt && validation.errors.login_pt ? (
                          <FormFeedback type="invalid">{validation.errors.login_pt}</FormFeedback>
                        ) : null}
                      </>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="login_en" className="form-label">
                      {`${props.t("common.loginImage", {
                        language: props.t("common.en"),
                      })}`}
                    </Label>
                    {validation?.values?.login_en ? (
                      <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                        <CustomLinkWrapper
                          link={
                            typeof validation.values.login_en === "string"
                              ? validation.values.login_en
                              : createFileUrl(validation.values.login_en)
                          }
                          title={
                            typeof validation.values.login_en === "string"
                              ? extractNameFromUrl(validation.values.login_en, "/", 5)
                              : validation.values.login_en.name
                          }
                        />
                        <i
                          className="ri-close-fill fs-18 cursor-pointer text-danger"
                          onClick={() => {
                            clearUploadedDocument("login_en");
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <Input
                          name="login_en"
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            validation.setFieldValue("login_en", e.target.files[0]);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.login_en || ""}
                          invalid={validation.touched.login_en && validation.errors.login_en ? true : false}
                        />
                        {validation.touched.login_en && validation.errors.login_en ? (
                          <FormFeedback type="invalid">{validation.errors.login_en}</FormFeedback>
                        ) : null}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="d-flex flex-row gap-2 justify-content-end">
                <CustomButton
                  disabled={saveLoader || publishLoader}
                  type="button"
                  btnTitle={props.t("common.preview")}
                />
                <CustomButton
                  disabled={publishLoader}
                  isLoading={saveLoader}
                  type="button"
                  // onClick={handleSave}
                  onClick={() => {
                    setSubmitType(TYPE_SAVE);
                    validation.handleSubmit();
                  }}
                  btnTitle={props.t("common.save")}
                />
                <CustomButton
                  disabled={saveLoader}
                  type="button"
                  isLoading={publishLoader}
                  btnTitle={props.t("common.save&publish")}
                  onClick={() => {
                    setSubmitType(TYPE_PUBLISH);
                    validation.handleSubmit();
                  }}
                />
                <CustomButton
                  disabled={saveLoader || publishLoader}
                  type="button"
                  btnTitle={props.t("common.reset")}
                  color="dark"
                  onClick={handleReset}
                />
              </div>
            </CardFooter>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(MyVeritas);
