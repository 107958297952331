import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddPaymentConditionModal from "../../../../components/pages/settings/AddPaymentConditionModal";
import {
  addPaymentCondition,
  deletePaymentCondition,
  getPaymentConditions,
  updatePaymentCondition,
} from "../../../../store/payment-conditions/actions";

const PaymentConditions = (props) => {
  const dispatch = useDispatch();
  const { data: paymentConditionsData, total: totalPaymentConditions } = useSelector(
    (state) => state?.PaymentConditions
  );

  const [paymentConditionsPayload, setPaymentConditionsPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: { id__icontains: "", name__icontains: "", deadline__icontains: "" },
  });

  const [selectedPaymentCondition, setSelectedPaymentCondition] = useState(null);
  const [isPaymentConditionModalOpen, setIsPaymentConditionModalOpen] = useState(false);
  const [tableLoader, setTableLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // add or edit action handlers
  const handlePaymentConditionModalOpen = (currencyData) => {
    setSelectedPaymentCondition(currencyData);
    setIsPaymentConditionModalOpen(true);
  };
  const handlePaymentConditionModalClose = () => {
    setSelectedPaymentCondition(null);
    setIsPaymentConditionModalOpen(false);
  };
  const handleSavePaymentCondition = (values, formikValidation) => {
    // check for add or edit
    if (selectedPaymentCondition && selectedPaymentCondition?.id) {
      // edit payment condition
      setSaveLoader(true);
      dispatch(updatePaymentCondition(selectedPaymentCondition?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handlePaymentConditionModalClose();
            formikValidation.resetForm();
            handleGetPaymentConditions(paymentConditionsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add payment condition
      setSaveLoader(true);
      dispatch(addPaymentCondition(values))
        .then((res) => {
          if (res) {
            // success handler
            handlePaymentConditionModalClose();
            formikValidation.resetForm();
            handleGetPaymentConditions(paymentConditionsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (paymentConditionData) => {
    setSelectedPaymentCondition(paymentConditionData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedPaymentCondition(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeletePaymentCondition = () => {
    if (selectedPaymentCondition && selectedPaymentCondition?.id) {
      setDeleteLoader(true);
      dispatch(deletePaymentCondition(selectedPaymentCondition?.id))
        .then((res) => {
          if (res) {
            handleGetPaymentConditions(paymentConditionsPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setPaymentConditionsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPaymentConditionsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setPaymentConditionsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get payment conditions
  const handleGetPaymentConditions = useCallback(
    (paymentConditionsPayload) => {
      setTableLoader(true);
      dispatch(getPaymentConditions(paymentConditionsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaymentConditions(paymentConditionsPayload);
  }, [handleGetPaymentConditions, paymentConditionsPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={paymentConditionsPayload}
            setPayload={setPaymentConditionsPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="name__icontains"
            className="column-search-input"
            payload={paymentConditionsPayload}
            setPayload={setPaymentConditionsPayload}
          />
        </div>
      ),
      selector: (row) => row?.name,
      minWidth: "220px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")} (En)</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="name_en__icontains"
            className="column-search-input"
            payload={paymentConditionsPayload}
            setPayload={setPaymentConditionsPayload}
          />
        </div>
      ),
      selector: (row) => row?.name_en,
      minWidth: "220px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.deadline")}
            sortable
            sortableKey="deadline"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="deadline__icontains"
            className="column-search-input"
            payload={paymentConditionsPayload}
            setPayload={setPaymentConditionsPayload}
          />
        </div>
      ),
      selector: (row) => row?.deadline,
      minWidth: "300px",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handlePaymentConditionModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.paymentConditions")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={paymentConditionsPayload}
            setPayload={setPaymentConditionsPayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handlePaymentConditionModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <div className="flex-grow-1 ms-2">
                {props.t("settings.billing.paymentConditions.addPaymentCondition")}
              </div>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.paymentConditions")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...paymentConditionsData]}
            pagination
            paginationServer
            paginationTotalRows={totalPaymentConditions}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddPaymentConditionModal
        paymentCondition={selectedPaymentCondition}
        isModalOpen={isPaymentConditionModalOpen}
        onCancel={handlePaymentConditionModalClose}
        onSave={handleSavePaymentCondition}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePaymentCondition}
        title={`${props.t("common.delete")} ${props.t("settings.billing.paymentConditions.paymentCondition")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.paymentConditions.paymentCondition_small"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(PaymentConditions);
