import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormikProvider, useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import ActionButton from "../../components/common/ActionButton";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import CustomAddButton from "../../components/common/CustomAddButton";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import CustomButton from "../../components/common/CustomButton";
import DateTimePicker from "../../components/common/DateTimePicker";
import Loader from "../../components/common/Loader";
import MessageBlock from "../../components/common/MessageBlock";
import CreditNoteDocumentLineModal from "../../components/pages/invoicing/CreditNoteDocumentLineModal";
import DocumentLines from "../../components/pages/invoicing/DocumentLines";
import ProformaInvoiceDocumentLineModal from "../../components/pages/invoicing/ProformaInvoiceDocumentLineModal";
import ReceiptDocumentLineModal from "../../components/pages/invoicing/ReceiptDocumentLineModal";
import ReturnGuideDocumentLineModal from "../../components/pages/invoicing/ReturnGuideDocumentLineModal";
import SalesNoteDocumentLineModal from "../../components/pages/invoicing/SalesNoteDocumentLineModal";
import TransportDocumentLineModal from "../../components/pages/invoicing/TransportDocumentLineModal";
import {
  CREDIT_NOTE,
  CREDIT_NOTE_LOT,
  DEBIT_NOTE,
  DEBIT_NOTE_LOT,
  DEBOUNCE_TIME,
  DROPDOWN_DEFAULT_LIMIT,
  FATURA_INVOICE,
  INVOICE_DOCUMENT,
  NORMAL_INVOICE,
  NORMAL_PROFORMA,
  PROFORMA_INVOICE_DOCUMENT,
  PROFORMA_NORMAL,
  RECEIPT_DOCUMENT,
  RETURN_GUIDE_DOCUMENT,
  SALES_NOTE,
  TRANSPORT_DOCUMENT,
} from "../../helpers/constants";
import { CREDIT_NOTE_OPTIONS, TAX_EXEMPTION_OPTIONS } from "../../helpers/constants/selectOptions";
import {
  calculateInsuranceForLots,
  calculateTotalAmountForDocument,
  calculateTotalForDocumentLine,
  extractKeyValues,
  formatAmount,
  getColumnDetail,
  getCustomerAddress,
  getCustomerAddressObject,
  getCustomerOptionLabel,
  getDateString,
  getFilterReferenceDocumentFilter,
  getFormattedDocumentLines,
  getListOfDebitNoteIds,
  getListOfItemsIds,
  getReferenceDocumentLabel,
  getTypeFromValue,
  roundTo,
  scrollToFirstFormikError,
} from "../../helpers/utils";
import { getCountriesOptions } from "../../store/common/actions";
import { getCurrencies } from "../../store/currencies/actions";
import { getCustomerList } from "../../store/customers/actions";
import { getDocumentTypes } from "../../store/document-types/actions";
import { addInvoice, getInvoiceById, getInvoices, updateInvoice } from "../../store/invoicing/actions";
import { getPaymentConditions } from "../../store/payment-conditions/actions";
import { getPaymentTypes } from "../../store/payment-types/actions";
import { getVATs } from "../../store/vat/actions";
import { getWarehouses } from "../../store/warehouse/actions";

const documentTypesPayload = {
  page: 1,
  sort: "description",
};

const InvoicingForm = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);
  const warehouses = useSelector((state) => state.Warehouse?.data);
  const invoiceData = useSelector((state) => state?.Invoicing?.invoice);
  const DEFAULT_CURRENCY_OPTION = useSelector((state) => state?.common?.currencies?.default);
  const DEFAULT_PAYMENT_CONDITION_OPTION = useSelector((state) => state?.common?.paymentConditions?.default);
  const DEFAULT_PAYMENT_METHOD_OPTION = useSelector((state) => state?.common?.paymentMethods?.default);
  const INVOICES_OPTIONS = useSelector((state) => state?.Invoicing?.data);

  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);

  const PAYMENT_CONDITIONS_OPTIONS = useSelector((state) => state?.PaymentConditions?.data);
  const PAYMENT_METHODS_OPTIONS = useSelector((state) => state?.PaymentTypes?.data);
  const TYPES_OF_DOCUMENTS = useSelector((state) => state?.DocumentTypes?.data);
  const CURRENCIES_OPTIONS = useSelector((state) => state?.Currencies?.data);
  const VAT_OPTIONS = useSelector((state) => state?.VAT.data);
  const DEFAULT_VAT_OPTION = useSelector((state) => state?.common?.vats?.default);

  const isAllowedToEdit = !Boolean(id);
  const [currentDate] = useState(new Date());
  const [disableFinancialFields, setDisableFinancialFields] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedInvoiceReference, setSelectedInvoiceReference] = useState();
  const [references, setReferences] = useState([]);
  const [documentLines, setDocumentLines] = useState([]);
  const [isAddDocumentLineModalOpen, setIsAddDocumentLineModalOpen] = useState(false);
  const [infoSaveLoader, setInfoSaveLoader] = useState(false);
  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    list: "basic",
    filter: {
      search_customer__icontains: "",
    },
  });
  const [personas, setPersonas] = useState([]);
  const [isAddServiceModal, setIsAddServiceModal] = useState(false);
  const [personaSearchLoader, setPersonaSearchLoader] = useState(false);
  const [personaPayload, setPersonaPayload] = useState({
    page: 1,
    sort: "name",
    filter: {
      search_customer__icontains: "",
    },
  });
  const [paymentConditionSearchLoader, setPaymentConditionSearchLoader] = useState(false);
  const [paymentConditionPayload, setPaymentConditionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
  });
  const [paymentMethodSearchLoader, setPaymentMethodSearchLoader] = useState(false);
  const [paymentMethodPayload, setPaymentMethodPayload] = useState({
    page: 1,
    sort: "description",
    limit: DROPDOWN_DEFAULT_LIMIT,
  });
  const [documentTypesSearchLoader, setDocumentTypesSearchLoader] = useState(false);

  const [currencySearchLoader, setCurrencySearchLoader] = useState(false);
  const [currencyPayload, setCurrencyPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "code",
  });
  const [invoiceSearchLoader, setInvoiceSearchLoader] = useState(false);
  const [invoicePayload, setInvoicePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: { is_cancel: false },
    exclude: {
      id: id,
    },
  });

  const [vatPayload, setVatPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "code",
  });

  const [warehouseSearchLoader, setWarehouseSearchLoader] = useState(false);
  const [warehousePayload, setWarehousePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "description",
  });

  const [apiSignals, setApiSignals] = useState([]);

  // handle confirmation modal open
  const handleConfirmationModalOpen = () => {
    setIsConfirmationModalOpen(true);
  };

  // handle confirmation modal close
  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };
  // handle confirmation of issue invoice
  const handleConfirmIssueInvoice = () => {
    const formattedAddresses = [
      {
        address_type: "loading",
        address: validation?.values?.loading_address,
        post_code: validation?.values?.loading_postcode,
        city: validation?.values?.loading_city,
        country_id: validation?.values?.loading_country?.id,
      },
      {
        address_type: "unloading",
        address: validation?.values?.unloading_address,
        post_code: validation?.values?.unloading_postcode,
        city: validation?.values?.unloading_city,
        country_id: validation?.values?.unloading_country?.id,
      },
    ];
    const payload = {
      ...validation?.values,
      warehouse: validation?.values?.warehouse?.id || null,
      addresses: formattedAddresses,
      customer: validation?.values?.customer?.id || null,
      billing_customer: validation?.values?.billing_customer?.id || null,
      document_type: validation?.values?.document_type?.id || null,
      payment_condition: validation?.values?.payment_condition?.id || null,
      payment_type: validation?.values?.payment_type?.id || null,
      currency: validation?.values?.currency?.id || null,
      debit_total: validation?.values?.debit_total || null,
      total: ![DEBIT_NOTE].includes(validation?.values?.document_type?.description)
        ? calculateTotalAmountForDocument(documentLines, validation?.values?.document_type?.description)
        : Number(validation?.values?.total),
      // document_lines: documentLines?.map((item) => {
      //     item.tax_exemption_code = validation?.values?.tax_exemption_code;

      //     if (validation?.values?.dont_apply_vat) {
      //       const vatId = VAT_OPTIONS.find((option) => option.rate === 0);
      //       item.vat = vatId?.id;
      //     }

      //     const { itemData, ...rest } = item;
      //     return rest;
      // }),
      document_lines: structuredClone(documentLines)
        ?.map((item) => {
          item.amount = item?.itemData?.amount * item?.itemData?.quantity;
          item.tax_exemption_code = validation?.values?.tax_exemption_code;

          if (validation?.values?.dont_apply_vat) {
            const vatId = VAT_OPTIONS.find((option) => option.rate === 0);
            item.vat = vatId?.id;
          }
          item.total_to_settle = +item.total_to_settle || 0;

          const { itemData, ...rest } = item;
          return rest;
        })
        ?.sort((a, b) => {
          if (a?.type_of_product === "lot") {
            if (a.lot_no === null && b.lot_no !== null) {
              return -1;
            } else if (a.lot_no !== null && b.lot_no === null) {
              return 1;
            } else if (a.lot_no !== null && b.lot_no !== null) {
              return a.lot_no - b.lot_no;
            }
          }
          return 0;
        }),
      information_text: [DEBIT_NOTE].includes(validation?.values?.document_type?.description)
        ? validation?.values?.information_text
        : undefined,
      vat: [DEBIT_NOTE].includes(validation?.values?.document_type?.description)
        ? validation?.values?.vat?.id
        : undefined,
      tax_exemption_code: validation?.values?.dont_apply_vat ? validation?.values?.tax_exemption_code || null : null,
      loading_date: validation?.values?.loading_date || null,
      unloading_date: validation?.values?.unloading_date || null,
    };
    if (references?.length > 0) payload.references = { documents: extractKeyValues(references, "id") };
    if (id) {
      setInfoSaveLoader(true);
      dispatch(updateInvoice(id, payload)).then((res) => {
        setInfoSaveLoader(false);
        handleConfirmationModalClose();
      });
    } else {
      setInfoSaveLoader(true);
      dispatch(addInvoice(payload)).then((res) => {
        if (res) {
          navigateOnInvoice(res?.id);
        }
        setInfoSaveLoader(false);
        handleConfirmationModalClose();
      });
    }
  };

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    if (value !== customersPayload.search_customer__icontains)
      setCustomersPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, search_customer__icontains: value },
      }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the payment conditions search
  const handlePyamentConditionsSearch = (value) => {
    setPaymentConditionPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for payment conditions search
  const paymentConditionsSearchDebounce = useMemo(() => {
    return debounce(handlePyamentConditionsSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the payment methods search
  const handlePyamentMethodsSearch = (value) => {
    setPaymentMethodPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, description__icontains: value },
    }));
  };
  // debounce for payment methods search
  const paymentMethodsSearchDebounce = useMemo(() => {
    return debounce(handlePyamentMethodsSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the currencies search
  const handleCurrencySearch = (value) => {
    setCurrencyPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, code__icontains: value },
    }));
  };
  // debounce for currencies search
  const currencySearchDebounce = useMemo(() => {
    return debounce(handleCurrencySearch, DEBOUNCE_TIME);
  }, []);

  // to handle the invoice search
  const handleInvoiceSearch = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_document__icontains: value },
    }));
  };
  // debounce for invoice search
  const invoiceSearchDebounce = useMemo(() => {
    return debounce(handleInvoiceSearch, DEBOUNCE_TIME);
  }, []);

  // handle vat search
  const handleVatSearch = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_code__icontains: value },
    }));
  };
  // debounce for vat search
  const vatSearchDebounce = useMemo(() => {
    return debounce(handleVatSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
      paymentConditionsSearchDebounce.cancel();
      paymentMethodsSearchDebounce.cancel();
      currencySearchDebounce.cancel();
      invoiceSearchDebounce.cancel();
      vatSearchDebounce.cancel();
    };
  }, [
    customerSearchDebounce,
    paymentConditionsSearchDebounce,
    paymentMethodsSearchDebounce,
    currencySearchDebounce,
    invoiceSearchDebounce,
    vatSearchDebounce,
  ]);

  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
  }, [dispatch]);

  // to get the list of the warehouses
  useEffect(() => {
    setWarehouseSearchLoader(true);
    dispatch(getWarehouses(warehousePayload)).then(() => {
      setWarehouseSearchLoader(false);
    });
  }, [dispatch, warehousePayload]);

  // to get the list of the vats
  useEffect(() => {
    dispatch(getVATs(vatPayload));
  }, [dispatch, vatPayload]);

  // to get the list of customers
  useEffect(() => {
    setApiSignals((controllers) => {
      for (const controller of controllers) {
        controller.abort();
      }
      return [];
    });

    const controller = new AbortController();

    const signal = controller.signal;

    setApiSignals(() => [controller]);
    setCustomerSearchLoader(true);
    dispatch(getCustomerList(customersPayload, false, signal)).finally(() => {
      setCustomerSearchLoader(false);
    });
  }, [customersPayload]);
  // to get the list of personas
  useEffect(() => {
    if (personaPayload?.filter?.parent_id) {
      setPersonaSearchLoader(true);
      dispatch(getCustomerList(personaPayload, true)).then((res) => {
        if (res) {
          setPersonas((prevState) => [...prevState, ...res?.results]);
        }
        setPersonaSearchLoader(false);
      });
    }
  }, [personaPayload]);
  // to get the list of payment conditions
  useEffect(() => {
    setPaymentConditionSearchLoader(true);
    dispatch(getPaymentConditions(paymentConditionPayload)).then(() => {
      setPaymentConditionSearchLoader(false);
    });
  }, [dispatch, paymentConditionPayload]);
  // to get the list of payment types
  useEffect(() => {
    setPaymentMethodSearchLoader(true);
    dispatch(getPaymentTypes(paymentMethodPayload)).then(() => {
      setPaymentMethodSearchLoader(false);
    });
  }, [dispatch, paymentMethodPayload]);
  // to get the list of document types
  useEffect(() => {
    setDocumentTypesSearchLoader(true);
    dispatch(getDocumentTypes(documentTypesPayload)).then(() => {
      setDocumentTypesSearchLoader(false);
    });
  }, [dispatch, documentTypesPayload]);
  // to get the list of currencies
  useEffect(() => {
    setCurrencySearchLoader(true);
    dispatch(getCurrencies(currencyPayload)).then(() => {
      setCurrencySearchLoader(false);
    });
  }, [dispatch, currencyPayload]);

  // to get the list of invoices
  const handleGetInvoices = useCallback(
    (invoicePayload) => {
      setInvoiceSearchLoader(true);
      dispatch(getInvoices(invoicePayload)).then(() => {
        setInvoiceSearchLoader(false);
      });
    },
    [dispatch]
  );
  useEffect(() => {
    handleGetInvoices(invoicePayload);
  }, [dispatch, invoicePayload, handleGetInvoices]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      date: id && invoiceData?.date ? invoiceData?.date : getDateString(new Date()),
      dont_apply_vat: id ? invoiceData?.dont_apply_vat : false,
      no_insurance: id ? invoiceData?.no_insurance : false,
      customer: id && invoiceData?.customer?.id ? invoiceData?.customer : state?.customer ? state?.customer : "",
      billing_customer:
        id && invoiceData?.billing_customer?.id
          ? invoiceData?.billing_customer
          : state?.customer
            ? state?.customer
            : "",
      payment_condition:
        id && invoiceData?.payment_condition?.id ? invoiceData?.payment_condition : DEFAULT_PAYMENT_CONDITION_OPTION,
      document_type:
        id && invoiceData?.document_type?.id
          ? invoiceData?.document_type
          : state?.document_type
            ? TYPES_OF_DOCUMENTS.find((type) => type?.description === state?.document_type)
            : TYPES_OF_DOCUMENTS.find((type) => type?.description === INVOICE_DOCUMENT),
      payment_type: id && invoiceData?.payment_type?.id ? invoiceData?.payment_type : "",
      currency: id && invoiceData?.currency?.id ? invoiceData?.currency : DEFAULT_CURRENCY_OPTION,
      internal_observations: id ? invoiceData?.internal_observations : "",
      debit_total: id ? invoiceData?.debit_total : "",
      total: id ? invoiceData?.total : "",
      information_text: id ? invoiceData?.information_text : "",
      vat: id ? invoiceData?.vat : DEFAULT_VAT_OPTION,
      tax_exemption_code: id ? invoiceData?.tax_exemption_code || "" : "",
      warehouse: id && invoiceData?.warehouse?.id ? invoiceData?.warehouse : "",
      loading_date: id ? invoiceData?.loading_date || "" : "",
      loading_address: id ? invoiceData?.addresses?.[0]?.address || "" : "",
      loading_city: id ? invoiceData?.addresses?.[0]?.city || "" : "",
      loading_postcode: id ? invoiceData?.addresses?.[0]?.post_code || "" : "",
      loading_country: id && invoiceData?.addresses?.[0]?.country?.id ? invoiceData?.addresses?.[0]?.country || "" : "",

      unloading_date: id ? invoiceData?.unloading_date || "" : "",
      unloading_address: id ? invoiceData?.addresses?.[1]?.address || "" : "",
      unloading_city: id ? invoiceData?.addresses?.[1]?.city || "" : "",
      unloading_postcode: id ? invoiceData?.addresses?.[1]?.post_code || "" : "",
      unloading_country:
        id && invoiceData?.addresses?.[1]?.country?.id ? invoiceData?.addresses?.[1]?.country || "" : "",

      car_registration_plate: id && invoiceData?.car_registration_plate ? invoiceData?.car_registration_plate : "",
      reg_type: id && invoiceData?.reg_type ? invoiceData?.reg_type : "",
    },
    validationSchema: Yup.object({
      tax_exemption_code: Yup.string().when("dont_apply_vat", {
        is: (dont_apply_vat) => {
          return dont_apply_vat;
        },
        then: Yup.string().required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.taxExemptionCode")}`
        ),
      }),
      reg_type: Yup.string().when("document_type", {
        is: (document_type) =>
          [CREDIT_NOTE, CREDIT_NOTE_LOT, DEBIT_NOTE, DEBIT_NOTE_LOT].includes(document_type?.description),
        then: Yup.string().required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.CreditNoteReason")}`
        ),
      }),
      date: Yup.date()
        .typeError(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.select"),
            field: props.t("common.date"),
          })
        )
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`),
      customer: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.customer")}`
      ),
      payment_condition: Yup.object().when("document_type", {
        is: (document_type) =>
          [
            RECEIPT_DOCUMENT,
            TRANSPORT_DOCUMENT,
            RETURN_GUIDE_DOCUMENT,
            CREDIT_NOTE,
            DEBIT_NOTE,
            CREDIT_NOTE_LOT,
            DEBIT_NOTE_LOT,
            PROFORMA_INVOICE_DOCUMENT,
          ].includes(document_type?.description),
        then: Yup.object().required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.paymentConditions")}`
        ),
      }),
      document_type: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.typeOfDocument")}`
      ),
      payment_type: Yup.object().when("document_type", {
        is: (document_type) =>
          ![
            TRANSPORT_DOCUMENT,
            RETURN_GUIDE_DOCUMENT,
            PROFORMA_INVOICE_DOCUMENT,
            INVOICE_DOCUMENT,
            NORMAL_PROFORMA,
            DEBIT_NOTE,
            CREDIT_NOTE,
            CREDIT_NOTE_LOT,
            DEBIT_NOTE_LOT,
          ].includes(document_type?.description),
        then: Yup.object().required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.paymentMethod")}`
        ),
      }),
      // debit_total: Yup.number().when("document_type", {
      //   is: (document_type) =>
      //     [DEBIT_NOTE,DEBIT_NOTE_LOT].includes(document_type?.description),
      //   then: Yup.number().required(
      //     `${props.t("common.please")} ${props.t("common.enter")} ${props.t(
      //       "common.totalAmount"
      //     )}`
      //   ).min(0, `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.totalAmount" )}`),
      // }),
      warehouse: Yup.object().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.object().required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.warehouse")}`
        ),
      }),
      loading_date: Yup.date().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            })
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`),
      }),
      unloading_date: Yup.date().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            })
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`),
      }),
      // car_registration_plate: Yup.string().when("document_type", {
      //   is: (document_type) =>
      //     [TRANSPORT_DOCUMENT].includes(document_type?.description),
      //   then: Yup.string().required(
      //     props.t("validation.formatGeneral", {
      //       enterOrSelect: props.t("common.enter"),
      //       field: props.t("common.vehicleRegistrationPlateNo"),
      //     })
      //   ),
      // }),
      loading_address: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string().required(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.enter"),
            field: props.t("common.loadingAddress"),
          })
        ),
      }),
      loading_city: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string()
          .required(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.enter"),
              field: props.t("common.loadingCity"),
            })
          )
          .nullable(),
      }),
      // loading_postcode: Yup.string().when("document_type", {
      //   is: (document_type) =>
      //     [TRANSPORT_DOCUMENT].includes(document_type?.description),
      //   then: Yup.string().required(
      //     props.t("validation.formatGeneral", {
      //       enterOrSelect: props.t("common.enter"),
      //       field: props.t("common.loadingPostcode"),
      //     })
      //   ).nullable(),
      // }),
      loading_postcode: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string()
          .required(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.enter"),
              field: props.t("common.loadingPostcode"),
            })
          )
          .test("no-spaces", `${props.t("common.enterNoSpaces")}`, (value) => !/\s/.test(value || "")),
      }),
      loading_country: Yup.object().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.object()
          .required(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.loadingCountry"),
            })
          )
          .nullable(),
      }),
      unloading_address: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string().required(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.enter"),
            field: props.t("common.unLoadingAddress"),
          })
        ),
      }),
      unloading_city: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string().required(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.enter"),
            field: props.t("common.unLoadingCity"),
          })
        ),
      }),
      // unloading_postcode: Yup.string().when("document_type", {
      //   is: (document_type) =>
      //     [TRANSPORT_DOCUMENT].includes(document_type?.description),
      //   then: Yup.string().required(
      //     props.t("validation.formatGeneral", {
      //       enterOrSelect: props.t("common.enter"),
      //       field: props.t("common.unLoadingPostcode"),
      //     })
      //   ),
      // }),
      unloading_postcode: Yup.string().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.string()
          .required(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.enter"),
              field: props.t("common.unLoadingPostcode"),
            })
          )
          .test("no-spaces", `${props.t("common.enterNoSpaces")}`, (value) => !/\s/.test(value || "")),
      }),
      unloading_country: Yup.object().when("document_type", {
        is: (document_type) => [TRANSPORT_DOCUMENT].includes(document_type?.description),
        then: Yup.object().required(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.select"),
            field: props.t("common.unLoadingCountry"),
          })
        ),
      }),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  // useEffect to filter invoices by customer id
  useEffect(() => {
    if (validation?.values?.customer) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          customer: validation?.values?.customer?.id,
        },
      }));
    }
  }, [validation?.values?.customer]);

  // useEffect to filter billing customers by customer id
  useEffect(() => {
    if (validation?.values?.customer && !id) {
      validation?.setFieldValue("billing_customer", validation?.values?.customer);
      setPersonas([validation?.values?.customer]);
      setPersonaPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          parent_id: validation?.values?.customer?.id,
        },
      }));
    }
  }, [validation?.values?.customer, id]);

  // useEffect to set disable fields to true on document type Receipt,Transport and Return
  useEffect(() => {
    if ([TRANSPORT_DOCUMENT, RETURN_GUIDE_DOCUMENT].includes(validation?.values?.document_type?.description)) {
      setDisableFinancialFields(true);
    } else {
      setDisableFinancialFields(false);
    }
  }, [validation?.values?.document_type?.description]);

  //reference documents filter
  useEffect(() => {
    setSelectedInvoiceReference();
    const isReceipt = validation?.values?.document_type?.description === RECEIPT_DOCUMENT;
    const isSalesNote = validation?.values?.document_type?.description === SALES_NOTE;
    if (!isSalesNote) validation.setFieldValue("no_insurance", false);
    const receiptPayload = isReceipt ? { is_settled: false } : {};
    setInvoicePayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        ...(isReceipt ? receiptPayload : { is_settled: undefined }),
        document_type__description__in: getFilterReferenceDocumentFilter(
          validation?.values?.document_type?.description
        ),
      },
    }));
  }, [validation?.values?.document_type]);

  // handle open add document line modal
  const handleAddDocumentLineModalOpen = () => {
    if (!validation?.values?.customer?.id) {
      toast.info(
        props.t("information.selectFirst", {
          module: props.t("common.customer"),
        })
      );
      return setClickedButton("");
    }
    if (validation?.values?.dont_apply_vat && !validation?.values?.tax_exemption_code) {
      toast.info(
        props.t("information.selectFirst", {
          module: props.t("common.taxExemptionCode"),
        })
      );
      return setClickedButton("");
    }
    setIsAddDocumentLineModalOpen(true);
  };
  // handle close add document line modal
  const handleAddDocumentLineModalClose = () => {
    setClickedButton(null);
    setIsAddDocumentLineModalOpen(false);
    handleGetInvoices(invoicePayload);
  };
  // handle add document line
  const handleAddDocumentLine = (value) => {
    setDocumentLines((prevState) => [...prevState, ...value]);
    handleAddDocumentLineModalClose();
  };
  // handle delete document line
  const handleDeleteDocumentLine = (index) => {
    setDocumentLines((prevState) => {
      return prevState?.filter((item, i) => index !== i);
    });
  };

  // handle add reference
  const handleAddReference = () => {
    setReferences((prevState) => {
      if (prevState.find((reference) => reference?.id === selectedInvoiceReference?.id)) {
        return prevState;
      }
      handleAddDocumentLinesOnAddRefernce();
      return [...prevState, selectedInvoiceReference];
    });

    setSelectedInvoiceReference();
  };

  // handle adding of doucment lines by adding refernce
  const handleAddDocumentLinesOnAddRefernce = () => {
    let items = [];
    let selectedTransportDocumentLots = getListOfItemsIds(documentLines, "transport");

    if (
      [CREDIT_NOTE, DEBIT_NOTE, RECEIPT_DOCUMENT].includes(validation?.values?.document_type?.description) &&
      [NORMAL_INVOICE, INVOICE_DOCUMENT, SALES_NOTE].includes(selectedInvoiceReference?.document_type?.description)
    ) {
      items = getFormattedDocumentLines([
        ...selectedInvoiceReference?.document_lines
          ?.map((row) => ({
            ...row,
            invoice_item_id: row?.id,
            vat: validation?.values?.invoice?.dont_apply_vat ? null : row?.vat,
            premium: row?.premium,
            discount: row?.discount,
            discount_unit: row?.discount_unit,
            VAT_OPTIONS,
          }))
          .filter((lineItem) => {
            if (getListOfItemsIds(documentLines, "invoice")?.length > 0) {
              if (getListOfItemsIds(documentLines, "invoice")?.includes(lineItem?.id)) return false;
            }
            return true;
          }),
      ])?.map((row) => ({
        ...row,
        itemData: {
          ...row.itemData,
          documentType: selectedInvoiceReference?.document_type?.description,
          documentNo: selectedInvoiceReference?.document_no,
        },
        invoice_id: selectedInvoiceReference?.id,
        total_to_settle:
          validation?.values?.document_type?.description === RECEIPT_DOCUMENT
            ? roundTo(parseFloat(row?.amount || 0) - parseFloat(row?.itemData?.paid_amount || 0))
            : undefined,
      }));
    }

    const isTransportGuide = validation?.values?.document_type?.description === TRANSPORT_DOCUMENT;
    const isReturnGuideAsReference = selectedInvoiceReference?.document_type?.description === RETURN_GUIDE_DOCUMENT;
    if (
      [CREDIT_NOTE_LOT, DEBIT_NOTE_LOT, TRANSPORT_DOCUMENT].includes(validation?.values?.document_type?.description) &&
      ([INVOICE_DOCUMENT].includes(selectedInvoiceReference?.document_type?.description) ||
        (isTransportGuide && isReturnGuideAsReference))
    ) {
      items = getFormattedDocumentLines([
        ...selectedInvoiceReference?.document_lines
          ?.map((row) => ({
            ...row,
            invoice_item_id: row?.id,
          }))
          .filter((lineItem) => {
            if (lineItem?.auction_lot?.id && selectedTransportDocumentLots?.auction_lots?.length > 0) {
              if (selectedTransportDocumentLots?.auction_lots?.includes(lineItem?.auction_lot?.id)) return false;
            }
            if (lineItem?.contract_item?.id && selectedTransportDocumentLots?.contract_items?.length > 0) {
              if (selectedTransportDocumentLots?.contract_items?.includes(lineItem?.contract_item?.id)) return false;
            }
            if (lineItem?.product?.id && selectedTransportDocumentLots?.products?.length > 0) {
              if (selectedTransportDocumentLots?.products?.includes(lineItem?.product?.id)) return false;
            }
            return true;
          }),
      ])?.map((row) => ({
        ...row,
        invoice_id: selectedInvoiceReference?.id,
      }));
    }
    if (
      [INVOICE_DOCUMENT, NORMAL_INVOICE].includes(validation?.values?.document_type?.description) &&
      [PROFORMA_INVOICE_DOCUMENT].includes(selectedInvoiceReference?.document_type?.description)
    ) {
      items = getFormattedDocumentLines([
        ...selectedInvoiceReference?.document_lines
          ?.map((row) => ({
            ...row,
            invoice_item_id: row?.id,
          }))
          .filter((lineItem) => {
            if (lineItem?.auction_lot?.contract_item?.is_invoice) return false;
            if (lineItem?.auction_lot?.id && selectedTransportDocumentLots?.auction_lots?.length > 0) {
              if (selectedTransportDocumentLots?.auction_lots?.includes(lineItem?.auction_lot?.id)) return false;
            }
            if (lineItem?.contract_item?.id && selectedTransportDocumentLots?.contract_items?.length > 0) {
              if (selectedTransportDocumentLots?.contract_items?.includes(lineItem?.contract_item?.id)) return false;
            }
            if (lineItem?.product?.id && selectedTransportDocumentLots?.products?.length > 0) {
              if (selectedTransportDocumentLots?.products?.includes(lineItem?.product?.id)) return false;
            }
            return true;
          }),
      ])?.map((row) => ({
        ...row,
        invoice_id: selectedInvoiceReference?.id,
      }));
    }

    if (
      [DEBIT_NOTE, DEBIT_NOTE_LOT, RECEIPT_DOCUMENT].includes(validation?.values?.document_type?.description) &&
      [DEBIT_NOTE].includes(selectedInvoiceReference?.document_type?.description) &&
      !documentLines?.find((line) => line?.invoice_id === selectedInvoiceReference?.id) &&
      selectedInvoiceReference?.total_to_settle > 0
    ) {
      let amount = selectedInvoiceReference?.debit_total;
      let finalAmount = roundTo(selectedInvoiceReference?.total);
      items = [
        {
          amount: finalAmount,
          type_of_product: "debit_note",
          quantity: 1,
          vat: selectedInvoiceReference?.vat?.id,
          invoice_id: selectedInvoiceReference?.id,
          total_to_settle: roundTo(
            parseFloat(finalAmount || 0) - parseFloat(selectedInvoiceReference?.paid_amount || 0)
          ),
          itemData: {
            type_of_product: "debit_note",
            description: selectedInvoiceReference?.information_text,
            quantity: 1,
            amount: amount,
            vat: selectedInvoiceReference?.vat?.rate,
            paid_amount: selectedInvoiceReference?.paid_amount,
            final_amount: finalAmount,
          },
        },
      ];
    }

    setDocumentLines((prevState) => [...prevState, ...items]);
  };

  // handle delete refernce
  const handleDeleteReference = (referenceId) => {
    setReferences((prevState) => [...prevState?.filter((reference) => reference?.id !== referenceId)]);
  };

  // handle reset info form
  const handleResetInfoForm = () => {
    if (
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)) ||
      JSON.stringify(invoiceData?.references) !== JSON.stringify(references) ||
      JSON.stringify(documentLines) !==
        JSON.stringify(
          getFormattedDocumentLines(
            invoiceData?.document_lines,
            validation?.values?.dont_apply_vat,
            validation?.values?.document_type?.description,
            validation?.values?.tax_exemption_code,
            VAT_OPTIONS,
            validation?.values?.no_insurance
          )
        )
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        handlePrefillStates(id, invoiceData);
      }
    }
  };

  // handle submit
  const handleSubmit = (values) => {
    if (
      [RECEIPT_DOCUMENT].includes(values?.document_type?.description) &&
      documentLines?.find((item) => item?.itemData?.hasError)
    ) {
      return toast.error(props.t("validation.documentLineHasError"));
    }
    if (documentLines.length === 0) {
      toast.error(
        props.t("information.addAtleaseOneItem", {
          module: props.t("common.docLine"),
        })
      );
      return;
    }
    if ([DEBIT_NOTE, DEBIT_NOTE_LOT].includes(values?.document_type?.description)) {
      let isValidDebitAmount = false;
      documentLines?.map((item) => {
        if (item?.debit_total && item?.debit_total > 0) {
          isValidDebitAmount = true;
        }
      });
      if (!isValidDebitAmount) {
        toast.error(
          props.t("information.addAtleaseOneItem", {
            module: props.t("common.totalAmount"),
          })
        );
        return;
      }
    }
    if (!id) {
      handleConfirmationModalOpen();
    } else {
      handleConfirmIssueInvoice();
    }
  };

  // navigate on invoice
  const navigateOnInvoice = (id) => {
    history.push(`/invoicing/edit/${id}`);
  };

  // use effect to exclude the invoice it self in edit mode
  useEffect(() => {
    if (id) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        exclude: { ...prevState?.exclude, id: id },
      }));
    }
  }, [id]);

  // handle pre-fill custom states
  const handlePrefillStates = useCallback(
    (id, invoiceData, state) => {
      if (id && invoiceData?.references?.length > 0) {
        setReferences(invoiceData?.references);
      } else {
        setReferences([]);
      }
      if (id && invoiceData?.document_lines?.length > 0) {
        setDocumentLines((prevState) => {
          const data = getFormattedDocumentLines(
            invoiceData?.document_lines,
            validation?.values?.dont_apply_vat,
            validation?.values?.document_type?.description,
            validation?.values?.tax_exemption_code,
            VAT_OPTIONS,
            validation?.values?.no_insurance
          );
          return data;
        });
      } else if (state?.documentLines?.length > 0) {
        setDocumentLines(state?.documentLines);
      } else {
        setDocumentLines([]);
      }
    },
    [validation?.values?.document_type?.description, validation?.values?.dont_apply_vat]
  );

  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    if (clickedButton === "AddService") {
      handleAddServiceModalOpen();
    } else if (clickedButton === "AddDocumentLine") {
      handleAddDocumentLineModalOpen();
    }
  }, [clickedButton]);

  const handleAddServiceModalOpen = () => {
    setIsAddServiceModal(true);
  };

  // to pre-filled custom states
  useEffect(() => {
    handlePrefillStates(id, invoiceData, state);
  }, [id, invoiceData, state, handlePrefillStates]);

  const handleGetInvoiceById = useCallback(
    (id) => {
      if (id) {
        setInfoSaveLoader(true);
        dispatch(getInvoiceById(id)).then((res) => {
          if (!res) {
            history.push(`/invoicing`);
          }
          setInfoSaveLoader(false);
        });
      }
    },
    [dispatch, history]
  );

  // to get the invoice data
  useEffect(() => {
    if (id) {
      handleGetInvoiceById(id);
    }
  }, [handleGetInvoiceById, id]);

  // to calculate total of items on toggle of do not apply vat
  useEffect(() => {
    setDocumentLines((documentLines) =>
      documentLines?.map((documentLine) => {
        let insurance = 0;
        if (
          validation?.values?.document_type?.description === SALES_NOTE &&
          documentLine?.type_of_product === "lot" &&
          !validation?.values?.no_insurance
        ) {
          insurance = calculateInsuranceForLots(
            documentLine?.itemData?.amount,
            1,
            documentLine?.itemData?.vat,
            validation?.values?.dont_apply_vat
          );
        }
        const total = calculateTotalForDocumentLine(
          {
            qty: documentLine?.itemData?.quantity,
            value: documentLine?.itemData?.amount,
            premium: documentLine?.itemData?.premium,
            insurance: insurance,
            discount: documentLine?.itemData?.discount,
            discount_unit: documentLine?.itemData?.discount_unit,
            vat: documentLine?.itemData?.vat,
          },
          documentLine?.itemData?.type_of_product,
          validation?.values?.dont_apply_vat,
          validation?.values?.document_type?.description
        );
        return {
          ...documentLine,
          amount: +total,
          itemData: {
            ...documentLine?.itemData,
            final_amount: +total,
            insurance: insurance,
          },
        };
      })
    );
  }, [
    validation?.values?.dont_apply_vat,
    validation?.values?.document_type?.description,
    validation?.values?.no_insurance,
  ]);

  // confirmation modal body
  const confirmationModalBody = (
    <div className="d-flex flex-column">
      <Row>
        <div className="mb-3 d-flex flex-row gap-3 fs-14">
          <Col sm={4} md={4} lg={4}>
            {props.t("common.customerName")}
          </Col>
          <Col className="fw-bold">
            {validation?.values?.billing_customer?.id
              ? validation?.values?.billing_customer?.customer_type === "company"
                ? validation?.values?.billing_customer?.company_name || "-"
                : validation?.values?.billing_customer?.name + " " + validation?.values?.billing_customer?.surname
              : "-"}
          </Col>
        </div>
      </Row>
      <Row>
        <div className="mb-3 d-flex flex-row gap-3 fs-14">
          <Col sm={4} md={4} lg={4}>
            {props.t("common.vat")}
          </Col>
          <Col className="fw-bold">
            {validation?.values?.billing_customer?.id
              ? validation?.values?.billing_customer?.customer_type === "company"
                ? validation?.values?.billing_customer?.company_vat || "-"
                : validation?.values?.billing_customer?.client_vat || "-"
              : "-"}
          </Col>
        </div>
      </Row>
      <Row>
        <div className="mb-3 d-flex flex-row gap-3 fs-14">
          <Col sm={4} md={4} lg={4}>
            {props.t("common.address")}
          </Col>
          <Col className="fw-bold">
            {validation?.values?.billing_customer?.id
              ? getCustomerAddress(
                  validation?.values?.billing_customer?.addresses,
                  validation?.values?.billing_customer?.customer_type === "company" ? "company" : "client"
                )
              : "-"}
          </Col>
        </div>
      </Row>

      <div>{props.t("confirmation.issueDocument")}</div>
    </div>
  );

  const handleAddServiceModalClose = () => {
    setClickedButton(null);
    setIsAddServiceModal(false);
  };

  // useeffect to prefill payment method on sale note
  useEffect(() => {
    if (validation?.values?.document_type?.description === SALES_NOTE) {
      validation?.setFieldValue("payment_type", DEFAULT_PAYMENT_METHOD_OPTION);
    }
  }, [validation?.values?.document_type?.description, DEFAULT_PAYMENT_METHOD_OPTION]);

  // useEffect to filter out document lines by document type
  useEffect(() => {
    setInvoicePayload((prevState) => {
      delete prevState?.is_paid;
      delete prevState?.is_credit_note;
      if (validation?.values?.document_type?.description === RECEIPT_DOCUMENT) {
        prevState.is_paid = false;
      } else if (
        [CREDIT_NOTE, CREDIT_NOTE_LOT, DEBIT_NOTE, DEBIT_NOTE_LOT].includes(
          validation?.values?.document_type?.description
        )
      ) {
        prevState.is_credit_note = false;
      }
      return {
        ...prevState,
      };
    });
  }, [validation?.values?.document_type]);

  // calculate total for the debit note
  useEffect(() => {
    let amount = validation?.values?.debit_total || 0;
    // if (validation?.values?.vat) {
    //   amount += (amount * (validation?.values?.vat?.rate || 0)) / 100;
    // }
    validation?.setFieldValue("total", amount);
  }, [validation?.values?.debit_total, validation?.values?.vat]);

  // handle set address info for the selected customer
  const handlePrefillUnloadingInfo = (customer) => {
    let address, postCode, city, country;
    const add = getCustomerAddressObject(
      customer?.addresses,
      customer.customer_type === "company" ? "company" : "client"
    );

    if (add?.address) address = add?.address;
    if (add?.post_code) postCode = add?.post_code;
    if (add?.city) city = add?.city;
    if (add?.country?.name) country = add?.country;

    validation?.setFieldValue("unloading_address", address || "");
    validation?.setFieldValue("unloading_postcode", postCode || "");
    validation?.setFieldValue("unloading_city", city || "");
    validation?.setFieldValue("unloading_country", country || "");
  };

  document.title = `Veritas | ${props.t("sidebarMenu.invoicing")}`;

  return (
    <>
      <Prompt
        when={!validation?.isSubmitting && validation?.dirty && !id}
        message={props.t("confirmation.formLeaveMsg")}
      />
      <FormikProvider value={validation}>
        <div className="page-content layout-main-container">
          <div className="container-header">
            <CustomBreadcrumb
              data={[
                {
                  label: props.t("sidebarMenu.invoicing"),
                  link: "/invoicing",
                },
                {
                  label: `${props.t(`${id ? "common.editTitle" : "common.addTitle"}`, {
                    module: `${props.t("common.document")}`,
                  })} ${
                    state?.type === "customized" ? `(${props.t("common.customized")} ${props.t("common.invoice")})` : ""
                  }`,
                },
              ]}
            />
          </div>
          <Card className="container-body card-section">
            {/* info form */}
            {infoSaveLoader ? <Loader /> : null}
            <Form
              onSubmit={(e) => {
                if (clickedButton === "AddService") {
                  handleAddServiceModalClose();
                }
                e.preventDefault();
                if (clickedButton === null) {
                  validation.handleSubmit();
                }
                return false;
              }}
            >
              <CardHeader className="card-section-header">
                <span className="card-title">{`${props.t("common.info")}`}</span>
                {!isAllowedToEdit && id ? null : (
                  <div className="d-flex flex-row justify-content-end gap-2">
                    <CustomButton
                      color="dark"
                      btnTitle={props.t("common.reset")}
                      disabled={infoSaveLoader}
                      onClick={handleResetInfoForm}
                    />
                    <CustomButton
                      color="primary"
                      disabled={infoSaveLoader}
                      btnTitle={props.t("common.save")}
                      type="submit"
                      isLoading={infoSaveLoader}
                      onClick={() => scrollToFirstFormikError(validation.errors)}
                    />
                  </div>
                )}
              </CardHeader>
              <CardBody className="card-section-body">
                {/* document no */}
                <Row>
                  <Col sm={6} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="document_no" className="form-label">
                        {props.t("common.documentNo")}
                      </Label>
                      <Input disabled className="form-control" placeholder={id ? invoiceData?.document_no : "D****"} />
                    </div>
                  </Col>
                </Row>
                {/* document form */}
                <Row>
                  {/* 1st column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="date" className="form-label">
                          {`${props.t("common.date")}`}*
                        </Label>
                        <Input
                          name="date"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.auctionStartDateTime")}`}
                          disabled={id}
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          max={getDateString(new Date())}
                          value={validation.values.date}
                          invalid={validation.touched.date && validation.errors.date ? true : false}
                        />

                        {validation.touched.date && validation.errors.date ? (
                          <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="customer" className="form-label">
                          {`${props.t("common.customer")}*`}
                        </Label>
                        <Select
                          isLoading={customerSearchLoader}
                          name="customer"
                          options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
                          getOptionLabel={(customer) => getCustomerOptionLabel(customer, " - ", true)}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select ${
                            validation.touched.customer && validation.errors.customer ? "select-error" : ""
                          } ${id ? "disabled-select" : ""}`}
                          isDisabled={id}
                          placeholder={`${props.t("common.select")} ${props.t("common.customer")}`}
                          value={validation.values.customer || ""}
                          onChange={(customer) => {
                            if (documentLines?.length > 0 || references?.length > 0) {
                              if (
                                window.confirm(
                                  props.t("confirmation.resetDocumentLines&DocumentReference", {
                                    changeIn: props.t("common.customer"),
                                  })
                                )
                              ) {
                                validation.setFieldValue("customer", customer?.id ? customer : "");
                                setDocumentLines([]);
                                setReferences([]);
                              }
                            } else {
                              validation.setFieldValue("customer", customer?.id ? customer : "");
                            }
                            handlePrefillUnloadingInfo(customer);
                          }}
                          onInputChange={customerSearchDebounce}
                          onBlur={(e) => {
                            validation.setFieldTouched("customer", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.customer && validation.errors.customer ? (
                          <p className="custom-invalid-feedback">{validation.errors.customer}</p>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="billing_customer" className="form-label">
                          {`${props.t("common.billingCustomer")}`}
                        </Label>
                        <Select
                          isLoading={personaSearchLoader}
                          name="billing_customer"
                          options={personaSearchLoader ? [] : personas}
                          getOptionLabel={getCustomerOptionLabel}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select ${
                            validation.touched.billing_customer && validation.errors.billing_customer
                              ? "select-error"
                              : ""
                          } ${id || !validation?.values?.customer ? "disabled-select" : ""}`}
                          isDisabled={id || !validation?.values?.customer}
                          placeholder={`${props.t("common.select")} ${props.t("common.billingCustomer")}`}
                          value={validation.values.billing_customer || ""}
                          onChange={(billing_customer) => {
                            validation.setFieldValue("billing_customer", billing_customer?.id ? billing_customer : "");
                          }}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="document_type" className="form-label">
                          {`${props.t("common.typeOfDocument")}*`}
                        </Label>
                        <Select
                          isDisabled={id || state?.type === "customized"}
                          isLoading={documentTypesSearchLoader}
                          name="document_type"
                          options={documentTypesSearchLoader ? [] : TYPES_OF_DOCUMENTS}
                          getOptionLabel={(option) => getColumnDetail(option?.description_en, option?.description)}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select fixed-selection-menu ${
                            validation.touched.document_type && validation.errors.document_type ? "select-error" : ""
                          } ${id || state?.type === "customized" ? "disabled-select" : ""}`}
                          placeholder={`${props.t("common.select")} ${props.t("common.typeOfDocument")}`}
                          value={validation.values.document_type || ""}
                          onChange={(document_type) => {
                            if (document_type?.description === TRANSPORT_DOCUMENT || validation?.values.customer) {
                              handlePrefillUnloadingInfo(validation?.values?.customer);
                            }
                            if (documentLines?.length > 0 || references?.length > 0) {
                              if (
                                window.confirm(
                                  props.t("confirmation.resetDocumentLines&DocumentReference", {
                                    changeIn: props.t("common.documentType"),
                                  })
                                )
                              ) {
                                validation.setFieldValue("document_type", document_type?.id ? document_type : null);
                                setDocumentLines([]);
                                setReferences([]);
                              }
                            } else {
                              validation.setFieldValue("document_type", document_type?.id ? document_type : null);
                            }
                          }}
                          // onInputChange={customerSearchDebounce}
                          onBlur={(e) => {
                            validation.setFieldTouched("document_type", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.document_type && validation.errors.document_type ? (
                          <p className="custom-invalid-feedback">{validation.errors.document_type}</p>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Label htmlFor="refernce_documents" className="form-label ">
                            {`${props.t("common.referenceDocuments")}`}
                          </Label>
                        </Row>
                        {!id ? (
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Select
                                  isLoading={invoiceSearchLoader}
                                  options={INVOICES_OPTIONS}
                                  getOptionLabel={getReferenceDocumentLabel}
                                  getOptionValue={(option) => option?.id}
                                  className={`custom-select ${
                                    id || !validation?.values?.customer?.id ? "disabled-select" : ""
                                  }`}
                                  isDisabled={id || !validation?.values?.customer?.id}
                                  placeholder={`${props.t("common.select")} ${props.t("common.referenceDocument")}`}
                                  onInputChange={invoiceSearchDebounce}
                                  value={selectedInvoiceReference || ""}
                                  onChange={(option) => {
                                    setSelectedInvoiceReference(option);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                              <ActionButton
                                disabled={!selectedInvoiceReference || !validation?.values?.customer?.id}
                                iconClasses="ri-add-circle-fill fs-24 "
                                onClick={handleAddReference}
                                tooltip={props.t("common.addTitle", {
                                  module: props.t("common.reference"),
                                })}
                                buttonId={"add-refernce-document"}
                              />
                            </Col>
                          </Row>
                        ) : null}
                        <Row>
                          <div className="refernce-document-preview-section">
                            <div className="documents-container">
                              {references?.length > 0 ? (
                                references?.map((reference, index) => (
                                  <>
                                    <div
                                      className={`d-flex flex-row align-items-center justify-content-between ${
                                        id ? "p-2" : ""
                                      }`}
                                      key={index}
                                    >
                                      <span
                                        className="text-primary cursor-pointer fw-bold"
                                        onClick={() => {
                                          navigateOnInvoice(reference?.id);
                                        }}
                                      >
                                        {`${reference?.document_no} - ${reference?.date}`}
                                      </span>
                                      {id ? null : (
                                        <ActionButton
                                          iconColor={"red"}
                                          iconClasses="ri-delete-bin-fill fs-16"
                                          buttonId={`tooltip-${index}-delete`}
                                          tooltip={props.t("common.delete")}
                                          onClick={() => {
                                            handleDeleteReference(reference?.id);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <hr />
                                  </>
                                ))
                              ) : (
                                <MessageBlock
                                  fontSize={"12px"}
                                  height={"100px"}
                                  message={
                                    id
                                      ? `${props.t("common.no")} ${props.t("common.referenceDocuments")}`
                                      : props.t("common.addTitle", {
                                          module: props.t("common.referenceDocuments"),
                                        })
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* 2nd column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="dont_apply_vat" className="form-label">
                            {`${props.t("common.doNotApplyVAT")}`}
                          </Label>
                          <div className="form-check mb-3">
                            <input
                              disabled={
                                (!isAllowedToEdit && id) ||
                                disableFinancialFields ||
                                [RECEIPT_DOCUMENT, DEBIT_NOTE, CREDIT_NOTE, CREDIT_NOTE_LOT, DEBIT_NOTE_LOT]?.includes(
                                  validation?.values?.document_type?.description
                                )
                              }
                              type="checkbox"
                              name="dont_apply_vat"
                              className="form-check-input"
                              onChange={(e) => {
                                validation.handleChange(e);
                                if (!e.target.checked) {
                                  validation.setFieldValue("tax_exemption_code", "");
                                }
                              }}
                              checked={validation.values.dont_apply_vat}
                            />
                          </div>
                        </div>
                      </Col>
                      {/* Do not charge premium */}
                      {validation?.values?.document_type?.description === SALES_NOTE ? (
                        <Col lg={8}>
                          <div className="mb-3">
                            <Label htmlFor="no_insurance" className="form-label">
                              {`${props.t("common.doNotChargeInsurance")}`}
                            </Label>
                            <div className="form-check mb-3">
                              <input
                                disabled={(!isAllowedToEdit && id) || disableFinancialFields}
                                type="checkbox"
                                name="no_insurance"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.no_insurance}
                              />
                            </div>
                          </div>
                        </Col>
                      ) : null}
                      <Col lg={8}>
                        {validation?.values?.dont_apply_vat ? (
                          <div className="mb-3">
                            <Label className="form-label">{`${props.t("common.taxExemptionCode")}`}</Label>
                            <Select
                              isDisabled={!isAllowedToEdit && id}
                              options={TAX_EXEMPTION_OPTIONS}
                              getOptionLabel={(option) => option?.label}
                              getOptionValue={(option) => option?.value}
                              className={`custom-select ${
                                validation.touched.tax_exemption_code && validation.errors.tax_exemption_code
                                  ? "select-error"
                                  : ""
                              } ${!isAllowedToEdit && id ? "disabled-select" : ""}`}
                              placeholder={`${props.t("common.select")} ${props.t("common.taxExemptionCode")}`}
                              value={
                                getTypeFromValue(TAX_EXEMPTION_OPTIONS, validation.values.tax_exemption_code) || ""
                              }
                              onChange={(tax_exemption_code) => {
                                validation.setFieldValue(
                                  "tax_exemption_code",
                                  tax_exemption_code?.value ? tax_exemption_code?.value : ""
                                );
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("tax_exemption_code", true);
                                validation.handleBlur(e);
                              }}
                            />
                            {validation.touched.tax_exemption_code && validation.errors.tax_exemption_code ? (
                              <p className="custom-invalid-feedback">{validation.errors.tax_exemption_code}</p>
                            ) : null}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="payment_condition" className="form-label">
                          {`${props.t("common.paymentConditions")}${
                            [PROFORMA_INVOICE_DOCUMENT, NORMAL_PROFORMA]?.includes(
                              validation?.values?.document_type?.description
                            )
                              ? ""
                              : "*"
                          }`}
                        </Label>
                        <Select
                          isDisabled={
                            (!isAllowedToEdit && id) ||
                            disableFinancialFields ||
                            [PROFORMA_INVOICE_DOCUMENT, RECEIPT_DOCUMENT, NORMAL_PROFORMA]?.includes(
                              validation?.values?.document_type?.description
                            )
                          }
                          isLoading={paymentConditionSearchLoader}
                          options={paymentConditionSearchLoader ? [] : PAYMENT_CONDITIONS_OPTIONS}
                          getOptionLabel={(option) => getColumnDetail(option?.name_en, option?.name)}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select ${
                            validation.touched.payment_condition && validation.errors.payment_condition
                              ? "select-error"
                              : ""
                          } ${
                            (!isAllowedToEdit && id) ||
                            disableFinancialFields ||
                            [PROFORMA_INVOICE_DOCUMENT, RECEIPT_DOCUMENT, NORMAL_PROFORMA]?.includes(
                              validation?.values?.document_type?.description
                            )
                              ? "disabled-select"
                              : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("common.paymentConditions")}`}
                          value={validation.values.payment_condition || ""}
                          onChange={(payment_condition) => {
                            validation.setFieldValue(
                              "payment_condition",
                              payment_condition?.id ? payment_condition : ""
                            );
                          }}
                          onInputChange={paymentConditionsSearchDebounce}
                          onBlur={(e) => {
                            validation.setFieldTouched("payment_condition", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.payment_condition && validation.errors.payment_condition ? (
                          <p className="custom-invalid-feedback">{validation.errors.payment_condition}</p>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="payment_type" className="form-label">
                          {`${props.t("common.paymentMethod")}${
                            [
                              PROFORMA_INVOICE_DOCUMENT,
                              INVOICE_DOCUMENT,
                              NORMAL_PROFORMA,
                              CREDIT_NOTE,
                              DEBIT_NOTE,
                              CREDIT_NOTE_LOT,
                              DEBIT_NOTE_LOT,
                            ]?.includes(validation?.values?.document_type?.description)
                              ? ""
                              : "*"
                          }`}
                        </Label>
                        <Select
                          isClearable
                          isDisabled={
                            (!isAllowedToEdit && id) ||
                            disableFinancialFields ||
                            [PROFORMA_INVOICE_DOCUMENT, NORMAL_PROFORMA]?.includes(
                              validation?.values?.document_type?.description
                            )
                          }
                          isLoading={paymentMethodSearchLoader}
                          name="payment_type"
                          options={paymentMethodSearchLoader ? [] : PAYMENT_METHODS_OPTIONS}
                          getOptionLabel={(option) => getColumnDetail(option?.description_en, option?.description)}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select ${
                            validation.touched.payment_type && validation.errors.payment_type ? "select-error" : ""
                          } ${
                            (!isAllowedToEdit && id) ||
                            disableFinancialFields ||
                            [PROFORMA_INVOICE_DOCUMENT, NORMAL_PROFORMA]?.includes(
                              validation?.values?.document_type?.description
                            )
                              ? "disabled-select"
                              : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("common.paymentMethod")}`}
                          value={validation.values.payment_type}
                          onChange={(payment_type) => {
                            validation.setFieldValue("payment_type", payment_type?.id ? payment_type : "");
                          }}
                          onInputChange={paymentMethodsSearchDebounce}
                          onBlur={(e) => {
                            validation.setFieldTouched("payment_type", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.payment_type && validation.errors.payment_type ? (
                          <p className="custom-invalid-feedback">{validation.errors.payment_type}</p>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="currency" className="form-label">
                          {`${props.t("common.currency")}*`}
                        </Label>
                        <Select
                          isDisabled={true}
                          isLoading={currencySearchLoader}
                          options={currencySearchLoader ? [] : CURRENCIES_OPTIONS}
                          getOptionLabel={(option) => option?.code}
                          getOptionValue={(option) => option?.id}
                          className={`custom-select ${
                            validation.touched.currency && validation.errors.currency ? "select-error" : ""
                          } ${true ? "disabled-select" : ""}`}
                          placeholder={`${props.t("common.select")} ${props.t("common.currency")}`}
                          value={validation.values.currency || ""}
                          onChange={(currency) => {
                            validation.setFieldValue("currency", currency?.id ? currency : "");
                          }}
                          onInputChange={currencySearchDebounce}
                          onBlur={(e) => {
                            validation.setFieldTouched("currency", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.currency && validation.errors.currency ? (
                          <p className="custom-invalid-feedback">{validation.errors.currency}</p>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="internal_observations" className="form-label">
                          {`${props.t("common.internalObservations")}`}
                        </Label>
                        <textarea
                          disabled={!isAllowedToEdit && id}
                          name="internal_observations"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.internalObservations")}`}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.internal_observations}
                          invalid={
                            validation.touched.internal_observations && validation.errors.internal_observations
                              ? true
                              : false
                          }
                          rows={8}
                        />
                        {validation.touched.internal_observations && validation.errors.internal_observations ? (
                          <span className="custom-invalid-feedback">{validation.errors.internal_observations}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  {/* 3rd column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* credit note reason */}
                    {[CREDIT_NOTE, CREDIT_NOTE_LOT]?.includes(validation?.values?.document_type?.description) && (
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="credit_note_reason" className="form-label">
                            {`${props.t("common.CreditNoteReason")}`}*
                          </Label>
                          <Select
                            isDisabled={id}
                            options={CREDIT_NOTE_OPTIONS}
                            getOptionLabel={(option) => option?.label}
                            getOptionValue={(option) => option?.value}
                            className={`custom-select ${
                              validation.touched.reg_type && validation.errors.reg_type ? "select-error" : ""
                            } ${id ? "disabled-select" : ""}`}
                            placeholder={`${props.t("common.select")} ${props.t("common.CreditNoteReason")}`}
                            value={getTypeFromValue(CREDIT_NOTE_OPTIONS, validation.values.reg_type) || ""}
                            onChange={(reg_type) => {
                              validation.setFieldValue("reg_type", reg_type?.value ? reg_type?.value : "");
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("reg_type", true);
                              validation.handleBlur(e);
                            }}
                          />
                          {validation.touched.reg_type && validation.errors.reg_type ? (
                            <p className="custom-invalid-feedback">{validation.errors.reg_type}</p>
                          ) : null}
                        </div>
                      </Row>
                    )}

                    {[DEBIT_NOTE, DEBIT_NOTE_LOT].includes(validation?.values?.document_type?.description) && (
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="information_text" className="form-label">
                            {`${props.t("common.debitNoteReason")}*`}
                          </Label>
                          <Select
                            isDisabled={id}
                            options={CREDIT_NOTE_OPTIONS}
                            getOptionLabel={(option) => option?.label}
                            getOptionValue={(option) => option?.value}
                            className={`custom-select ${
                              validation.touched.reg_type && validation.errors.reg_type ? "select-error" : ""
                            } ${id ? "disabled-select" : ""}`}
                            placeholder={`${props.t("common.select")} ${props.t("common.CreditNoteReason")}`}
                            value={getTypeFromValue(CREDIT_NOTE_OPTIONS, validation.values.reg_type) || ""}
                            onChange={(reg_type) => {
                              validation.setFieldValue("reg_type", reg_type?.value ? reg_type?.value : "");
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("reg_type", true);
                              validation.handleBlur(e);
                            }}
                          />
                          {validation.touched.reg_type && validation.errors.reg_type ? (
                            <p className="custom-invalid-feedback">{validation.errors.reg_type}</p>
                          ) : null}
                        </div>
                      </Row>
                    )}
                    {[SALES_NOTE].includes(validation?.values?.document_type?.description) ? (
                      <>
                        <ProformaInvoiceDocumentLineModal
                          selectedPremium={documentLines?.length > 0 ? documentLines?.[0]?.itemData?.premium : null}
                          doNotApplyVat={validation?.values?.dont_apply_vat}
                          exemptionCode={validation.values.tax_exemption_code}
                          documentType={validation?.values?.document_type?.description}
                          selectedLots={getListOfItemsIds(documentLines, "auction")}
                          isModalOpen={isAddServiceModal}
                          handleModalClose={handleAddServiceModalClose}
                          addDocumentLine={handleAddDocumentLine}
                          selectedCustomer={validation?.values?.customer?.id}
                        />
                      </>
                    ) : null}
                    {[TRANSPORT_DOCUMENT].includes(validation?.values?.document_type?.description) ? (
                      <>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="loading_date" className="form-label">
                                {`${props.t("common.loadingDate")}`}*
                              </Label>
                              <DateTimePicker
                                name="loading_date"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.loadingDate")}`}
                                disabled={id}
                                date={validation.values.loading_date}
                                minDate={currentDate.toISOString().slice(0, -8)}
                                onBlur={validation.handleBlur}
                                onChange={(date) => validation.setFieldValue("loading_date", date)}
                                invalid={
                                  validation.touched.loading_date && validation.errors.loading_date ? true : false
                                }
                              />

                              {validation.touched.loading_date && validation.errors.loading_date ? (
                                <FormFeedback type="invalid">{validation.errors.loading_date}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="unloading_date" className="form-label">
                                {`${props.t("common.unLoadingDate")}`}*
                              </Label>
                              <DateTimePicker
                                name="unloading_date"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.unLoadingDate")}`}
                                disabled={id}
                                date={validation.values.unloading_date}
                                minDate={validation.values.loading_date}
                                onChange={(date) => validation.setFieldValue("unloading_date", date)}
                                invalid={
                                  validation.touched.unloading_date && validation.errors.unloading_date ? true : false
                                }
                              />

                              {validation.touched.unloading_date && validation.errors.unloading_date ? (
                                <FormFeedback type="invalid">{validation.errors.unloading_date}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="warehouse" className="form-label">
                                {`${props.t("common.warehouse")}*`}
                              </Label>

                              <Select
                                isDisabled={!isAllowedToEdit && id}
                                isLoading={warehouseSearchLoader}
                                options={warehouseSearchLoader ? [] : warehouses}
                                getOptionLabel={(option) => option?.description}
                                getOptionValue={(option) => option?.id}
                                className={`custom-select ${
                                  validation.touched.warehouse && validation.errors.warehouse ? "select-error" : ""
                                } ${!isAllowedToEdit && id ? "disabled-select" : ""}`}
                                placeholder={`${props.t("common.select")} ${props.t("common.warehouse")}`}
                                value={validation.values.warehouse || ""}
                                onChange={(warehouse) => {
                                  validation?.setFieldValue("loading_address", warehouse?.address);
                                  validation?.setFieldValue("loading_city", warehouse?.city || "");
                                  validation?.setFieldValue("loading_postcode", warehouse?.post_code || "");
                                  validation?.setFieldValue(
                                    "loading_country",
                                    warehouse?.country && Object.entries(warehouse.country).length !== 0
                                      ? warehouse.country
                                      : ""
                                  );
                                  validation.setFieldValue("warehouse", warehouse?.id ? warehouse : "");
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("warehouse", true);
                                  validation.handleBlur(e);
                                }}
                              />
                              {validation.touched.warehouse && validation.errors.warehouse ? (
                                <p className="custom-invalid-feedback mb-0">{validation.errors.warehouse}</p>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="car_registration_plate" className="form-label">
                                {`${props.t("common.vehicleRegistrationPlateNo")}`}
                              </Label>
                              <Input
                                name="car_registration_plate"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t(
                                  "common.vehicleRegistrationPlateNo"
                                )}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.car_registration_plate}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="loading_address" className="form-label">
                                {`${props.t("common.loadingAddress")}`}*
                              </Label>
                              <Input
                                name="loading_address"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.loadingAddress")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.loading_address}
                                invalid={
                                  validation.touched.loading_address && validation.errors.loading_address ? true : false
                                }
                              />

                              {validation.touched.loading_address && validation.errors.loading_address ? (
                                <FormFeedback type="invalid">{validation.errors.loading_address}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="unloading_address" className="form-label">
                                {`${props.t("common.unLoadingAddress")}`}*
                              </Label>
                              <Input
                                name="unloading_address"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.unLoadingAddress")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unloading_address}
                                invalid={
                                  validation.touched.unloading_address && validation.errors.unloading_address
                                    ? true
                                    : false
                                }
                              />

                              {validation.touched.unloading_address && validation.errors.unloading_address ? (
                                <FormFeedback type="invalid">{validation.errors.unloading_address}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="loading_postcode" className="form-label">
                                {`${props.t("common.loadingPostcode")}`}*
                              </Label>
                              <Input
                                name="loading_postcode"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.loadingPostcode")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.loading_postcode}
                                invalid={
                                  validation.touched.loading_postcode && validation.errors.loading_postcode
                                    ? true
                                    : false
                                }
                              />

                              {validation.touched.loading_postcode && validation.errors.loading_postcode ? (
                                <FormFeedback type="invalid">{validation.errors.loading_postcode}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="unloading_postcode" className="form-label">
                                {`${props.t("common.unLoadingPostcode")}`}*
                              </Label>
                              <Input
                                name="unloading_postcode"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.unLoadingPostcode")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unloading_postcode}
                                invalid={
                                  validation.touched.unloading_postcode && validation.errors.unloading_postcode
                                    ? true
                                    : false
                                }
                              />

                              {validation.touched.unloading_postcode && validation.errors.unloading_postcode ? (
                                <FormFeedback type="invalid">{validation.errors.unloading_postcode}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="loading_city" className="form-label">
                                {`${props.t("common.loadingCity")}`}*
                              </Label>
                              <Input
                                name="loading_city"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.loadingCity")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.loading_city}
                                invalid={
                                  validation.touched.loading_city && validation.errors.loading_city ? true : false
                                }
                              />

                              {validation.touched.loading_city && validation.errors.loading_city ? (
                                <FormFeedback type="invalid">{validation.errors.loading_city}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="unloading_city" className="form-label">
                                {`${props.t("common.unLoadingCity")}`}*
                              </Label>
                              <Input
                                name="unloading_city"
                                className="form-control"
                                placeholder={`${props.t("common.enter")} ${props.t("common.unLoadingCity")}`}
                                disabled={id}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unloading_city}
                                invalid={
                                  validation.touched.unloading_city && validation.errors.unloading_city ? true : false
                                }
                              />

                              {validation.touched.unloading_city && validation.errors.unloading_city ? (
                                <FormFeedback type="invalid">{validation.errors.unloading_city}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("common.loadingCountry")}*</Label>
                              <Select
                                isDisabled={!isAllowedToEdit && id}
                                name="loading_country"
                                options={contriesOptions}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.id}
                                className={`custom-select ${
                                  validation.touched.loading_country && validation.errors.loading_country
                                    ? "select-error"
                                    : ""
                                } ${!isAllowedToEdit && id ? "disabled-select" : ""}`}
                                placeholder={`${props.t("placeholder.selectField", {
                                  fieldType: props.t("common.loadingCountry"),
                                })}`}
                                value={validation.values.loading_country || ""}
                                onChange={(loading_country) => {
                                  validation.setFieldValue(
                                    "loading_country",
                                    loading_country?.id ? loading_country : ""
                                  );
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("loading_country", true);
                                  validation.handleBlur(e);
                                }}
                              />
                              {validation.touched.loading_country && validation.errors.loading_country ? (
                                <p className="custom-invalid-feedback">{validation.errors.loading_country}</p>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("common.unLoadingCountry")}*</Label>
                              <Select
                                isDisabled={!isAllowedToEdit && id}
                                name="unloading_country"
                                options={contriesOptions}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.id}
                                className={`custom-select ${
                                  validation.touched.unloading_country && validation.errors.unloading_country
                                    ? "select-error"
                                    : ""
                                } ${!isAllowedToEdit && id ? "disabled-select" : ""}`}
                                placeholder={`${props.t("placeholder.selectField", {
                                  fieldType: props.t("common.unLoadingCountry"),
                                })}`}
                                value={validation.values.unloading_country || ""}
                                onChange={(unloading_country) => {
                                  validation.setFieldValue(
                                    "unloading_country",
                                    unloading_country?.id ? unloading_country : ""
                                  );
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("unloading_country", true);
                                  validation.handleBlur(e);
                                }}
                              />
                              {validation.touched.unloading_country && validation.errors.unloading_country ? (
                                <p className="custom-invalid-feedback">{validation.errors.unloading_country}</p>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                {/* document lines */}
                {/* {![DEBIT_NOTE].includes(
                  validation?.values?.document_type?.description
                ) && ( */}
                <Row>
                  <div className="mb-3">
                    <div className="d-flex flex-row justify-content-between mb-3">
                      <Label htmlFor="document_lines" className="form-label fw-bold fs-18">
                        {`${props.t("common.documentLines")}`}
                      </Label>
                      {(validation?.values?.document_type?.description === "Nota de Venda" ||
                        validation?.values?.document_type?.description === "Sales note") && (
                        <>
                          <CustomAddButton
                            type="button"
                            btnTitle={props.t("Add S&P")}
                            style={{ marginRight: "-900px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setClickedButton("AddService");
                            }}
                          />
                        </>
                      )}
                      {id ? null : (
                        <CustomAddButton
                          color={"success"}
                          type="button"
                          btnTitle={props.t("common.add")}
                          onClick={() => {
                            setClickedButton("AddDocumentLine");
                          }}
                        />
                      )}
                    </div>
                    <Card className="subcard-table-container table-light">
                      <DocumentLines
                        formValues={validation?.values}
                        doNotApplyVat={validation?.values?.dont_apply_vat}
                        onlyInfo={[TRANSPORT_DOCUMENT, RETURN_GUIDE_DOCUMENT]?.includes(
                          validation?.values?.document_type?.description
                        )}
                        isPartialPayment={[RECEIPT_DOCUMENT]?.includes(validation?.values?.document_type?.description)}
                        isDebitNote={[DEBIT_NOTE, DEBIT_NOTE_LOT]?.includes(
                          validation?.values?.document_type?.description
                        )}
                        viewMode={Boolean(id)}
                        data={documentLines}
                        deleteDocumentLine={handleDeleteDocumentLine}
                        setDocumentLines={setDocumentLines}
                        documentType={validation?.values?.document_type?.description}
                        validation={validation}
                        isRecipt={[RECEIPT_DOCUMENT]?.includes(validation?.values?.document_type?.description)}
                      />

                      {documentLines?.length > 0 &&
                        ![TRANSPORT_DOCUMENT, RETURN_GUIDE_DOCUMENT]?.includes(
                          validation?.values?.document_type?.description
                        ) && (
                          <CardFooter>
                            <div className="d-flex justify-content-end">
                              <div className="d-flex justify-content-between" style={{ width: "300px" }}>
                                <h4>{props.t("common.total")}</h4>
                                <h4>
                                  {`${
                                    id
                                      ? formatAmount(+invoiceData?.total)
                                      : formatAmount(
                                          calculateTotalAmountForDocument(
                                            documentLines,
                                            validation?.values?.document_type?.description
                                          )
                                        )
                                  } €`}
                                </h4>
                              </div>
                            </div>
                          </CardFooter>
                        )}
                    </Card>
                  </div>
                </Row>
                {/* )} */}
              </CardBody>
              <CardFooter>
                {!isAllowedToEdit && id ? null : (
                  <div className="d-flex flex-row justify-content-end gap-2">
                    <CustomButton
                      color="dark"
                      btnTitle={props.t("common.reset")}
                      disabled={infoSaveLoader}
                      onClick={handleResetInfoForm}
                    />
                    <CustomButton
                      color="primary"
                      type="submit"
                      disabled={infoSaveLoader}
                      btnTitle={props.t("common.save")}
                      isLoading={infoSaveLoader}
                      onClick={() => scrollToFirstFormikError(validation.errors)}
                    />
                  </div>
                )}
              </CardFooter>
            </Form>
          </Card>
        </div>
      </FormikProvider>

      {[
        PROFORMA_INVOICE_DOCUMENT,
        INVOICE_DOCUMENT,
        NORMAL_INVOICE,
        NORMAL_PROFORMA,
        PROFORMA_NORMAL,
        FATURA_INVOICE,
      ].includes(validation?.values?.document_type?.description) ? (
        <ProformaInvoiceDocumentLineModal
          selectedPremium={documentLines?.length > 0 ? documentLines?.[0]?.itemData?.premium : null}
          doNotApplyVat={validation?.values?.dont_apply_vat}
          exemptionCode={validation.values.tax_exemption_code}
          documentType={validation?.values?.document_type?.description}
          selectedLots={getListOfItemsIds(documentLines, "auction")}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
        />
      ) : null}
      {validation?.values?.document_type?.description === RETURN_GUIDE_DOCUMENT && (
        <ReturnGuideDocumentLineModal
          selectedItems={getListOfItemsIds(documentLines, "contract")}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
        />
      )}
      {validation?.values?.document_type?.description === TRANSPORT_DOCUMENT && (
        <TransportDocumentLineModal
          selectedLots={getListOfItemsIds(documentLines, "transport")}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
        />
      )}
      {validation?.values?.document_type?.description === SALES_NOTE && (
        <SalesNoteDocumentLineModal
          validation={validation}
          doNotApplyVat={validation?.values?.dont_apply_vat}
          selectedItems={getListOfItemsIds(documentLines, "contract")}
          selectedExpenses={getListOfItemsIds(documentLines, "expense")}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
        />
      )}

      {[CREDIT_NOTE, CREDIT_NOTE_LOT, DEBIT_NOTE_LOT, DEBIT_NOTE].includes(
        validation?.values?.document_type?.description
      ) && (
        <CreditNoteDocumentLineModal
          selectedLots={getListOfItemsIds(documentLines, "invoice")}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
          documentType={validation?.values?.document_type?.description}
        />
      )}
      {validation?.values?.document_type?.description === RECEIPT_DOCUMENT && (
        <ReceiptDocumentLineModal
          selectedItems={getListOfItemsIds(documentLines, "invoice")}
          selectedDebitNotes={getListOfDebitNoteIds(documentLines)}
          isModalOpen={isAddDocumentLineModalOpen}
          handleModalClose={handleAddDocumentLineModalClose}
          addDocumentLine={handleAddDocumentLine}
          selectedCustomer={validation?.values?.customer?.id}
        />
      )}
      <ConfirmationModal
        body={confirmationModalBody}
        loader={infoSaveLoader}
        actionBtnTitle={props.t("common.confirm")}
        cancelBtnTitle={props.t("common.cancel")}
        onConfirmBtnHandler={handleConfirmIssueInvoice}
        onCloseBtnHandler={handleConfirmationModalClose}
        isModalOpen={isConfirmationModalOpen}
      />
    </>
  );
};

export default withTranslation()(InvoicingForm);
