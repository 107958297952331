import React from "react";

const CustomTextLink = ({ text, handleClick, ...others }) => {
  return (
    <span className="text-primary cursor-pointer table-check-box" onClick={handleClick} {...others}>
      {text}
    </span>
  );
};

export default CustomTextLink;
