import { SET_AUCTION_EDITION, SET_PAGE_DATA, SET_PAGE_INFORMATION } from "./actionTypes";

const INIT_STATE = {
  auction: null,
  pages: { data: [], total: 0 },
  info: null,
};

const Pages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAGE_DATA:
      return {
        ...state,
        pages: {
          ...state?.pages,
          data: action.payload.results,
          total: action.payload.count,
        },
      };
    case SET_PAGE_INFORMATION:
      return {
        ...state,
        info: action.payload,
      };
    case SET_AUCTION_EDITION:
      return {
        ...state,
        auction: {
          ...state?.auction,
          [action?.payload?.type]: {
            ...action?.payload?.data,
          },
        },
      };

    default:
      return { ...state };
  }
};

export default Pages;
