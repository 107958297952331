import { ADD_DOCUMENTTYPE, DELETE_DOCUMENTTYPE, SET_DOCUMENTTYPES_DATA, UPDATE_DOCUMENTTYPE } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const DocumentTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DOCUMENTTYPES_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_DOCUMENTTYPE:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_DOCUMENTTYPE:
      const updateDocumentTypesData = state.data;
      const existingDocumentTypeIndex = state.data.findIndex((documentType) => documentType?.id === action.payload.id);
      if (existingDocumentTypeIndex !== -1) {
        updateDocumentTypesData[existingDocumentTypeIndex] = action.payload;
      }
      return {
        ...state,
        data: updateDocumentTypesData,
      };
    case DELETE_DOCUMENTTYPE:
      return {
        ...state,
        data: [...state.data.filter((documentType) => documentType?.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default DocumentTypes;
