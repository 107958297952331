import API from "./../../helpers/api/api_interceptor";
import { RESET_LOGGEDIN_USER, SET_LOGGEDIN_USER, SET_USER_DETAILS } from "./actionType";

export const loginUser = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/login`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        localStorage.setItem("token", res?.data?.data?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.data?.user_data));
        dispatch({
          type: SET_LOGGEDIN_USER,
          payload: {
            userData: res?.data?.data?.user_data,
            token: res?.data?.data?.token,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    return API.get(`/users/logout`).then((res) => {
      if (res && res.status && res.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        dispatch({
          type: RESET_LOGGEDIN_USER,
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the logged-in user details
export const getUserDetails = () => async (dispatch) => {
  try {
    return API.get(`/users/detail`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_USER_DETAILS, payload: res?.data?.data });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
