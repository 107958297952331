import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import {
  SET_AVAILABLE_CONTRACT_ITEMS,
  SET_CONTRACT,
  SET_CONTRACTS_LIST,
  SET_CONTRACT_ITEM,
  SET_CONTRACT_ITEMS,
  SET_SELLER_DOCUMENTS,
  SET_SOLD_CONTRACT_ITEMS,
} from "./actionTypes";

// to get list of contracts
export const getContracts = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        search_list: params?.search_list,
        sort: params?.sort,
        filter: params?.filter,
        is_paid_expense: params?.is_paid_expense,
        expense_exclude: JSON.stringify(params?.expense_exclude),
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CONTRACTS_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// form data for add/edit contract
const handleContractFormDataAppend = (payload) => {
  const formData = new FormData();

  for (var key in payload) {
    if (key === "contract_photos") {
      payload?.contract_photos?.forEach((item) => {
        formData.append("contract_photos", item);
      });
    } else if (key === "contract_date" && payload?.contract_date) {
      formData.append(key, payload[key]);
    } else if (key === "specialists") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "expenses") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "customer") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "internal_observations") {
      formData.append(key, payload[key]);
    } else if (key === "notes") {
      formData.append(key, payload[key]);
    } else if (key === "premium") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "vat") {
      formData.append(key, JSON.stringify(payload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  }

  return formData;
};

// to add contract form data
export const addContractForm = (payload) => async (dispatch) => {
  const formData = handleContractFormDataAppend(payload);
  try {
    return API.post(`/contracts/`, formData).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_CONTRACT, payload: res?.data?.data });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to edit contract form data
export const updateContractForm = (contractId, payload) => async (dispatch) => {
  const formData = handleContractFormDataAppend(payload);
  try {
    return API.put(`/contracts/${contractId}`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_CONTRACT, payload: res?.data?.data });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete contract
export const deleteContract = (contractId) => async (dispatch) => {
  try {
    return API.delete(`/contracts/${contractId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get contract by id
export const getContractById = (contractId, params) => async (dispatch) => {
  try {
    return API.get(`/contracts/${contractId}`, {
      params: { is_paid_expense: params?.is_paid_expense },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_CONTRACT, payload: res?.data?.data });
        return res?.data?.data;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// contracts items

// to get the list of contract items
export const getContractItems = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/item`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
        is_paid_expense: params?.is_paid_expense,
        expense_exclude: JSON.stringify(params?.expense_exclude),
        is_reserved_tag: params?.is_reserved_tag,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CONTRACT_ITEMS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            suggested_contract_no: res?.data?.data?.suggested_contract_no,
          },
        });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the contract-item by id
export const getContractItemById = (contractItemId) => async (dispatch) => {
  try {
    return API.get(`/contracts/item/${contractItemId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_CONTRACT_ITEM, payload: res?.data?.data });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// form data append handler for adding/editing contract item
const handleFormDataAppend = (payload) => {
  const formData = new FormData();

  for (var key in payload) {
    if (key === "appraisal_photos") {
      payload?.appraisal_photos?.forEach((item) => {
        formData.append("appraisal_photos", item);
      });
    } else if (key === "default_photo" && payload?.default_photo) {
      formData.append(key, payload[key]);
    } else if (key === "photos") {
      payload?.photos?.forEach((item) => {
        formData.append("photos", item);
      });
    } else if (key === "condition_report_photos") {
      payload?.condition_report_photos?.forEach((item) => {
        formData.append("condition_report_photos", item);
      });
    } else if (key === "lots_tag") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "expenses") {
      formData.append(key, JSON.stringify(payload[key]));
    } else if (key === "categories") {
      formData.append(key, JSON.stringify(payload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  }

  return formData;
};

// to add contract item
export const addContractItem = (payload) => async (dispatch) => {
  const formData = handleFormDataAppend(payload);
  try {
    return API.post(`/contracts/item`, formData).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to edit contract item
export const updateContractItem = (contractItemId, payload) => async (dispatch) => {
  const formData = handleFormDataAppend(payload);
  try {
    return API.put(`/contracts/item/${contractItemId}`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete contract item
export const deleteContractItem = (payload) => async (dispatch) => {
  try {
    return API.delete(`/contracts/item`, {
      data: {
        is_selected_all: payload?.isSelectedAll,
        ids: payload?.ids,
        filter: payload?.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to reintroduce contract item
export const reintroduceContractItem = (payload) => async () => {
  try {
    return API.post(`/contracts/item/re-introduce`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export contract items
export const exportContract = (contractId, payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/export/${contractId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export contract items
export const exportContractRenegotiation = (contractId, payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/renegotiation-export/${contractId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export contract items
export const exportItemList = (payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/item-export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the list of contract items that are available
export const getAvailableContractItems = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/item`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_AVAILABLE_CONTRACT_ITEMS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the list of contract items that are sold
export const getSoldContractItems = (params) => async (dispatch) => {
  try {
    return API.get(`/contracts/item`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_SOLD_CONTRACT_ITEMS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get list of seller documents
export const getSellerDocuments = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/documents`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        search_list: params?.search_list,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_SELLER_DOCUMENTS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
// to export contract items
export const uploadExcel =
  ({ id, payload }, type) =>
  async (dispatch) => {
    const formData = new FormData();
    for (var key in payload) {
      if (key === "excel_file") {
        formData.append(key, payload[key]);
      }
    }
    try {
      return API.post(
        type === "contract" ? `/contracts/${id}/item-import` : `/contracts/${id}/pre-contract/item-import`,
        formData
      ).then((res) => {
        if (res && res.status && res.status === 200) {
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// to clone contract item to another contract
export const cloneContractItem = (payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/item-clone`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export contract's situation
export const exportContractSituation = (contractId, payload) => async (dispatch) => {
  try {
    return API.post(`/contracts/situation/export/${contractId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
