import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { deleteMultimedia, getMultimedia, uploadMultimedia } from "../../../../store/files/actions";
import ActionButton from "../../../common/ActionButton";
import CustomAddButton from "../../../common/CustomAddButton";
import UploadMediaModal from "./UploadMediaModal";
import Loader from "../../../common/Loader";
import DeleteModal from "../../../common/DeleteModal";
import MessageBlock from "../../../common/MessageBlock";
import { getFileURLExtension, openFileURL } from "../../../../helpers/utils";
import { EXCEL_EXTENSIONS, IMAGE_EXTENSIONS, PDF_EXTENSIONS, VIDEO_EXTENSIONS } from "../../../../helpers/constants";
import PDFImage from "../../../../assets/images/veritas/pdf-placeholder.png";
import ExcelImage from "../../../../assets/images/veritas/excel-placeholder.png";
import DocumentImage from "../../../../assets/images/veritas/document-placeholder.png";

const Multimedia = (props) => {
  const dispatch = useDispatch();
  const { data: multimedia } = useSelector((state) => state?.Files?.multimedia);
  const [isUploadMediaModalOpen, setIsUploadMediaModalOpen] = useState(false);
  const [uploadLoader, setUploadeLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [multimediaPayload, setMultimediaPayload] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  // to handle delete modal open
  const handleDeleteModalOpen = (media) => {
    setIsDeleteModalOpen(true);
    setSelectedMedia(media);
  };
  // to handle delete modal close
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedMedia(null);
  };
  // to delete media
  const handleDeleteMedia = () => {
    setDeleteLoader(true);
    dispatch(deleteMultimedia({ is_selected_all: false, ids: [selectedMedia?.id] })).then((res) => {
      if (res) {
        handleDeleteModalClose();
        handleGetMultimedia();
      }
      setDeleteLoader(false);
    });
  };

  // to handle open upload media modal
  const handleUploadMediaModalOpen = () => {
    setIsUploadMediaModalOpen(true);
  };
  // to handle close upload media modal
  const handleUploadMediaModalClose = () => {
    setIsUploadMediaModalOpen(false);
  };
  // to upload media
  const handleUploadMedia = (values) => {
    setUploadeLoader(true);
    dispatch(uploadMultimedia({ files: values })).then((res) => {
      if (res) {
        handleUploadMediaModalClose();
        handleGetMultimedia();
      }
      setUploadeLoader(false);
    });
  };

  // to get the list of uploaded multimedias
  const handleGetMultimedia = useCallback(
    (multimediaPayload) => {
      setListLoader(true);
      dispatch(getMultimedia(multimediaPayload)).then((res) => {
        setListLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetMultimedia(multimediaPayload);
  }, [handleGetMultimedia, multimediaPayload]);

  return (
    <>
      <Card className="subcard-table-container table-light">
        {listLoader ? <Loader /> : null}
        <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
          <span className="fs-14">{props.t("common.multimediaLibrary")}</span>
          <CustomAddButton btnTitle={props.t("common.upload")} onClick={handleUploadMediaModalOpen} color={"success"} />
        </CardHeader>
        <CardBody className="multimedia-container">
          <Row>
            {multimedia?.length > 0 ? (
              multimedia?.map((media) => {
                return (
                  <Col className="media-container" md={3} lg={2}>
                    {IMAGE_EXTENSIONS.includes(getFileURLExtension(media?.file)) ? (
                      <img
                        className="image-container-card"
                        src={media?.file}
                        alt="media"
                        onClick={() => openFileURL(media?.file, "preview", "_blank")}
                      />
                    ) : VIDEO_EXTENSIONS.includes(getFileURLExtension(media?.file)) ? (
                      <video
                        className="image-container-card"
                        src={
                          "https://veritas-backend.s3.eu-west-2.amazonaws.com/media/website_management/multimedia/Grava%C3%A7%C3%A3o_do_ecr%C3%A3_2022-10-18_%C3%A0s_10.24.27.mov"
                        }
                        alt="media"
                        controls
                      />
                    ) : PDF_EXTENSIONS.includes(getFileURLExtension(media?.file)) ? (
                      <div className="d-flex justify-content-center align-items-center document-card">
                        <img src={PDFImage} alt="media" onClick={() => openFileURL(media?.file, "preview", "_blank")} />
                      </div>
                    ) : EXCEL_EXTENSIONS.includes(getFileURLExtension(media?.file)) ? (
                      <div className="d-flex justify-content-center align-items-center document-card">
                        <img
                          src={ExcelImage}
                          alt="media"
                          onClick={() => openFileURL(media?.file, "preview", "_blank")}
                        />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center document-card">
                        <img
                          src={DocumentImage}
                          alt="media"
                          onClick={() => openFileURL(media?.file, "preview", "_blank")}
                        />
                      </div>
                    )}
                    <ActionButton
                      iconClasses="ri-delete-bin-fill fs-18"
                      buttonClasses={"multimedia-delete-icon"}
                      buttonId={`tooltip-${media?.id}-delete`}
                      tooltip={props.t("common.delete")}
                      onClick={() => {
                        handleDeleteModalOpen(media);
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <MessageBlock message={`${props.t("common.no")} ${props.t("common.media")}`} />
            )}
          </Row>
        </CardBody>
      </Card>
      {/* upload media modal */}
      <UploadMediaModal
        loader={uploadLoader}
        isModalOpen={isUploadMediaModalOpen}
        onCancel={handleUploadMediaModalClose}
        onSave={handleUploadMedia}
      />
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteMedia}
        title={`${props.t("common.delete")} ${props.t("common.document")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.media"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Multimedia);
