import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const DeleteModal = ({
  isModalOpen,
  title,
  body,
  onCloseBtnHandler,
  loader,
  onConfirmBtnHandler,
  actionBtnTitle,
  t,
  disabledDeleteAction,
}) => {
  const handleModalClose = () => {
    if (!loader) onCloseBtnHandler();
  };

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
        <ModalHeader>{title || "Delete Modal"}</ModalHeader>
        <ModalBody>{body || "Are You Sure ?"}</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader || disabledDeleteAction}
            className="btn-load d-flex"
            onClick={onConfirmBtnHandler}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}

            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{actionBtnTitle || t("common.delete")}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(DeleteModal);
