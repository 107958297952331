import "cropperjs/dist/cropper.css";
import React, { useEffect, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { extractNameFromUrl, getFileURLExtension, urlToFile } from "../../helpers/utils";
import CustomButton from "./CustomButton";
import Loader from "./Loader";

const ImageCropperModal = ({ isModalOpen, title, onCancel, t, src, handleImageCrop }) => {
  const cropperRef = useRef(null);
  const [imageSrc, setImageSrc] = useState();
  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleModalClose = () => {
    setImageSrc();
    onCancel();
  };

  const handleOnCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    urlToFile(cropper.getCroppedCanvas().toDataURL(), imageSrc?.fileName, imageSrc?.type).then(function (file) {
      handleImageCrop(file);
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      setIsImageLoading(true);
      if (typeof src === "string") {
        setImageSrc({
          src,
          fileName: extractNameFromUrl(src, "/", 5),
          type: `image/${getFileURLExtension(src).slice(1)}`,
        });
      } else {
        setImageSrc({
          src: URL.createObjectURL(src),
          fileName: src.name,
          type: src?.type,
        });
      }
    }
  }, [isModalOpen, src]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody className="d-flex flex-column justify-content-center ">
        {isImageLoading && <Loader />}
        <Cropper
          src={imageSrc?.src}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1.8 / 1}
          guides={true}
          zoomable={false}
          minCropBoxHeight={15}
          minCropBoxWidth={15}
          viewMode={1}
          ref={cropperRef}
          responsive={true}
          onLoad={() => setIsImageLoading(false)}
          checkOrientation={false}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} type="button" onClick={handleModalClose} color="dark" />
        <CustomButton btnTitle={t("common.crop")} onClick={handleOnCrop} />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ImageCropperModal);
