import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import { getCustomerList } from "../../../store/customers/actions";
import { sendEmailNotifications } from "../../../store/auctions/bidsActions";
import { getOnlyCustomerName } from "../../../helpers/utils";

const INITIAL_PAYLOAD = {
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
};

const SellersNotificationsModal = ({ isModalOpen, onCancel, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [sendNotificationLoader, setSendNotificationLoader] = useState(false);

  const { data: customers, total: totalCustomers } = useSelector((state) => state?.Customers);

  const [customersPayload, setCustomersPayload] = useState({
    ...INITIAL_PAYLOAD,
    filter: {
      ...INITIAL_PAYLOAD?.filter,
      contracts__contract_items__auction_lots__auction: id,
    },
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // handle page change
  const handlePageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get customers
  const handleGetCustomers = useCallback(
    (customersPayload) => {
      setTableLoader(true);
      dispatch(getCustomerList(customersPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // to get the list of the customers
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      handleGetCustomers(customersPayload);
    }
  }, [isModalOpen, customersPayload, handleGetCustomers]);

  // to reset the form
  useEffect(() => {
    if (!isModalOpen && id) {
      setCustomersPayload({
        ...INITIAL_PAYLOAD,
        filter: {
          ...INITIAL_PAYLOAD?.filter,
          contracts__contract_items__auction_lots__auction: id,
        },
      });
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }, [isModalOpen, id]);

  const handleModalClose = () => {
    if (!sendNotificationLoader) onCancel();
  };

  const handleSelectRow = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row?.id]);
      }
    }
  };

  //   handle send notification to the customers, currently "sellers" only for sellers
  const handleSendNotifications = () => {
    if (id) {
      setSendNotificationLoader(true);
      dispatch(
        sendEmailNotifications(id, {
          customers: selectedRows,
          user_type: "sellers",
        })
      ).then(() => {
        setSendNotificationLoader(false);
      });
    }
  };

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              handleSelectRow(row);
            }}
          />
        </Label>
      ),
      width: "80px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("customers.customer")} ${t("common.no_")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="customer_no__icontains"
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.customer_no,
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_customer__icontains"
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => (row?.id ? getOnlyCustomerName(row) : "-"),
      width: "230px",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="md"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select&SendNotificationsToSellers")}`}</span>

          {(isAllSelected && totalCustomers > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalCustomers}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            persistTableHead
            fixedHeader
            columns={columns}
            data={[...customers]}
            pagination
            paginationServer
            paginationDefaultPage={customersPayload?.page}
            paginationPerPage={customersPayload?.limit}
            paginationTotalRows={totalCustomers}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            pointerOnHover
            onRowClicked={(row) => {
              handleSelectRow(row);
            }}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={sendNotificationLoader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={handleSendNotifications}
          isLoading={sendNotificationLoader}
          btnTitle={t("common.send")}
          disabled={(isAllSelected && totalCustomers === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(SellersNotificationsModal);
