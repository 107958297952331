import flagsportugal from "../assets/images/flags/pt.svg";
import flagus from "../assets/images/flags/us.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  pt: {
    label: "Português",
    flag: flagsportugal,
  },
};

export default languages;
