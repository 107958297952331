import {
  ADD_DOCUMENTSTATUS,
  DELETE_DOCUMENTSTATUS,
  SET_DOCUMENTSTATUS_DATA,
  UPDATE_DOCUMENTSTATUS,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const DocumentStatus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DOCUMENTSTATUS_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_DOCUMENTSTATUS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_DOCUMENTSTATUS:
      const updatedDocumentStatusData = state.data;
      const existingDocumentStatusIndex = state.data.findIndex(
        (documentStatus) => documentStatus.id === action.payload.id
      );
      if (existingDocumentStatusIndex !== -1) {
        updatedDocumentStatusData[existingDocumentStatusIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedDocumentStatusData,
      };
    case DELETE_DOCUMENTSTATUS:
      return {
        ...state,
        data: [...state.data?.filter((documentStatus) => documentStatus.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default DocumentStatus;
