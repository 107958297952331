import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_AUCTION_THEME, DELETE_AUCTION_THEME, EDIT_AUCTION_THEME, SET_AUCTION_THEME_LIST } from "./actionType";

export const getAuctionTheme = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/themes`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_AUCTION_THEME_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const addAuctionTheme = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/themes`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_AUCTION_THEME,
          payload: {
            data: res?.data?.data,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const editAuctionTheme =
  ({ id, values }) =>
  async (dispatch) => {
    try {
      return API.put(`/auctions/themes/${id}`, values).then((res) => {
        if (res && res.status && res.status === 200) {
          dispatch({
            type: EDIT_AUCTION_THEME,
            payload: {
              data: res?.data?.data,
            },
          });
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteAuctionTheme = (id) => async (dispatch) => {
  try {
    return API.delete(`/auctions/themes/${id}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: DELETE_AUCTION_THEME,
          payload: {
            deletedId: id,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
