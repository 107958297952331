import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { withTranslation } from "react-i18next";
import { getDateInMMDDYYYYFormat } from "../../helpers/utils";

const CustomDateRangePicker = ({ rangeValue, inputValue, onChange, t }) => {
  const [initialSettings, setInitialSettings] = useState({
    autoUpdateInput: false,
    autoApply: true,
    showDropdowns: true,
  });

  useEffect(() => {
    setInitialSettings((prevState) => ({
      ...prevState,
      startDate: rangeValue?.startDate
        ? getDateInMMDDYYYYFormat(new Date(rangeValue?.startDate), "/")
        : getDateInMMDDYYYYFormat(new Date()),
      endDate: rangeValue?.endDate
        ? getDateInMMDDYYYYFormat(new Date(rangeValue?.endDate), "/")
        : getDateInMMDDYYYYFormat(new Date()),
    }));
  }, [rangeValue]);

  return (
    <DateRangePicker
      initialSettings={{ ...initialSettings }}
      onApply={(_, range) => {
        onChange({ startDate: range?.startDate, endDate: range?.endDate });
      }}
    >
      <div>
        <input
          value={inputValue}
          type="text"
          className="form-control test"
          placeholder={`${t("common.select")} ${t("common.dates")}`}
          readOnly
        />
        <span className="ri-calendar-line fs-16 search-icon"></span>
      </div>
    </DateRangePicker>
  );
};

export default withTranslation()(CustomDateRangePicker);
