import React, { useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import { BIDDING_ORIGIN_OPTIONS, TYPE_OF_BID_OPTIONS } from "../../../helpers/constants/selectOptions";
import {
  getColumnDetail,
  getCustomerOptionLabel,
  getTypeFromValue,
  scrollToFirstFormikError,
  seperateDetails,
} from "../../../helpers/utils";
import { getAuctionCollaborators } from "../../../store/auction-collaborators/actions";
import { getLotLists } from "../../../store/auctions/lotActions";
import { getCustomerList } from "../../../store/customers/actions";
import { fetchLanguageListAction } from "../../../store/languages/action";
import NumberInput from "../../common/NumberInput";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import i18next from "i18next";

const AddBidModal = ({ copyBid, bid, isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);
  const LANGUAGE_OPTIONS = useSelector((state) => state?.Languages?.languageList);

  const USERS_OPTIONS = useSelector((state) => state?.Collaborators?.data);
  const LOTS_OPTIONS = useSelector((state) => state?.Auctions?.lots?.data);

  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: 50,
    sort: "name",
    list: "basic",
    filter: {
      search_customer__icontains: "",
      parent_id: null,
    },
  });
  const [userSearchLoader, setUserSearchLoader] = useState(false);
  const [usersPayload, setUsersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    filter: {
      name__icontains: "",
    },
  });
  const [lotSearchLoader, setLotSearchLoader] = useState(false);
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "lot_no",
    filter: {
      auction: id,
    },
    exclude: {
      lot_no: null,
    },
  });
  const [languageSearchLoader, setLanguageSearchLoader] = useState(false);
  const [languagePayload, setLanguagePayload] = useState({
    page: 1,
    sort: "description",
    filter: {
      is_spoken: true,
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      auction: id,
      auction_lot: bid?.auction_lot?.id ? bid?.auction_lot : "",
      bidding_type: bid?.bidding_type || "absentee",
      customer: bid?.customer?.id ? bid?.customer : "",
      value: bid?.value !== null ? bid?.value : "",
      contact_1: bid?.contact_1 || "",
      contact_2: bid?.contact_2 || "",
      employee_preference: bid?.employee_preference?.id ? bid?.employee_preference : null,
      bidding_origin: bid?.bidding_origin || "",
      languages_speaks: bid?.languages_speaks?.length > 0 ? bid?.languages_speaks : [],
    },
    validationSchema: Yup.object({
      auction_lot: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.lot")}`),
      customer: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.customer")}`),
      contact_1: Yup.string().when("bidding_type", {
        is: "telephone",
        then: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.contact1")}`),
      }),
      contact_2: Yup.string().when("bidding_type", {
        is: "telephone",
        then: Yup.string(),
      }),
      languages_speaks: Yup.array().when("bidding_type", {
        is: "telephone",
        then: Yup.array()
          .min(1, `${t("common.please")} ${t("common.select")} ${t("common.languagePreference")}`)
          .required(`${t("common.please")} ${t("common.select")} ${t("common.languagePreference")}`),
      }),
      value: Yup.number().when("bidding_type", {
        is: "absentee",
        then: Yup.number()
          .min(0, t("validation.numberMin", { totalNumber: 0 }))
          .required(`${t("common.please")} ${t("common.enter")} ${t("common.value")}`),
      }),
    }),
    onSubmit: (values) => {
      onSave({
        ...values,
        employee_preference: values?.employee_preference?.id || null,
        languages_speaks:
          values?.languages_speaks?.length > 0 ? values?.languages_speaks?.map((language) => language?.id) : [],
      });
    },
  });

  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_customer__icontains: value },
    }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the user search
  const handleUserSearch = (value) => {
    setUsersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for user search
  const userSearchDebounce = useMemo(() => {
    return debounce(handleUserSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the lot search
  const handleLotSearch = (value) => {
    setLotsPayload((prevState) => {
      const search_key = getColumnDetail("search_author_title_en__icontains", "search_author_title_pt__icontains");
      return {
        ...prevState,
        filter: { ...prevState?.filter, [search_key]: value },
      };
    });
  };
  // debounce for lot search
  const lotSearchDebounce = useMemo(() => {
    return debounce(handleLotSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
      userSearchDebounce.cancel();
      lotSearchDebounce.cancel();
    };
  }, [customerSearchDebounce, userSearchDebounce, lotSearchDebounce]);

  // to get the list of customers
  useEffect(() => {
    if (isModalOpen) {
      setCustomerSearchLoader(true);
      dispatch(getCustomerList(customersPayload)).then((res) => {
        setCustomerSearchLoader(false);
      });
    }
  }, [dispatch, customersPayload, isModalOpen]);
  // to get the list of the users
  useEffect(() => {
    if (isModalOpen) {
      setUserSearchLoader(true);
      dispatch(getAuctionCollaborators(usersPayload)).then((res) => {
        setUserSearchLoader(false);
      });
    }
  }, [dispatch, usersPayload, isModalOpen]);
  // to get the list of lots for the auction
  useEffect(() => {
    if (isModalOpen) {
      setLotSearchLoader(true);
      dispatch(getLotLists(lotsPayload)).then((res) => {
        setLotSearchLoader(false);
      });
    }
  }, [dispatch, lotsPayload, isModalOpen]);
  // to get the list of spoken languages in system.
  useEffect(() => {
    if (isModalOpen) {
      setLanguageSearchLoader(true);
      dispatch(fetchLanguageListAction(languagePayload)).then((res) => {
        setLanguageSearchLoader(false);
      });
    }
  }, [dispatch, languagePayload, isModalOpen]);

  // to pre-fill contact 1
  useEffect(() => {
    if (!bid?.contact_1 && validation?.values?.customer && validation?.values?.bidding_type === "telephone") {
      validation.setFieldValue("contact_1", validation?.values?.customer?.contact_1);
    }
  }, [validation?.values?.customer, validation?.values?.bidding_type, bid]);

  // get item description -> artist, title
  const getLotDescription = (row) => {
    let description = [];

    if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
    } else {
      if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
    }
    return description?.length > 0 ? description.join(", ") : "";
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {bid?.id && !copyBid
          ? t("common.editTitle", {
              module: t("common.bid"),
            })
          : t("common.addTitle", {
              module: t("common.bid"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="auction_lot" className="form-label">
                  {`${t("common.lot")}*`}
                </Label>
                <Select
                  isLoading={lotSearchLoader}
                  name="auction_lot"
                  options={lotSearchLoader ? [] : LOTS_OPTIONS}
                  getOptionLabel={(option) =>
                    seperateDetails(
                      [option?.lot_no ? option?.lot_no + option?.lot_alpha : "", getLotDescription(option)],
                      " - "
                    )
                  }
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${
                    validation.touched.auction_lot && validation.errors.auction_lot ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.lot")}`}
                  value={validation?.values?.auction_lot || ""}
                  onChange={(auction_lot) => {
                    validation.setFieldValue("auction_lot", auction_lot?.id ? auction_lot : null);
                    validation.setFieldValue("value", +auction_lot?.contract_item?.min_estimate);
                  }}
                  onInputChange={lotSearchDebounce}
                  onBlur={(e) => {
                    validation.setFieldTouched("auction_lot", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.auction_lot && validation.errors.auction_lot ? (
                  <p className="custom-invalid-feedback">{validation.errors.auction_lot}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="bidding_type" className="form-label">
                  {`${t("common.typeOfBidding")}*`}
                </Label>
                <Select
                  name="bidding_type"
                  options={TYPE_OF_BID_OPTIONS}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("common.typeOfBidding")}`}
                  value={getTypeFromValue(TYPE_OF_BID_OPTIONS, validation?.values?.bidding_type) || ""}
                  onChange={(option) => {
                    // validation.resetForm();
                    validation.setFieldValue("bidding_type", option?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("bidding_type", true);
                    validation.handleBlur(e);
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="customer" className="form-label">
              {`${t("common.customer")}*`}
            </Label>
            <Select
              isLoading={customerSearchLoader}
              name="customer"
              options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
              getOptionLabel={getCustomerOptionLabel}
              getOptionValue={(option) => option?.id}
              className={`custom-select ${
                validation.touched.customer && validation.errors.customer ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.customer")}`}
              value={validation.values.customer || null}
              onChange={(customer) => {
                validation.setFieldValue("customer", customer?.id ? customer : null);
              }}
              onInputChange={customerSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("customer", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.customer && validation.errors.customer ? (
              <p className="custom-invalid-feedback">{validation.errors.customer}</p>
            ) : null}
          </div>

          {validation?.values?.bidding_type === "absentee" ? (
            <div className="mb-3">
              <Label htmlFor="value" className="form-label">
                {`${t("common.value")}*`}
              </Label>
              <NumberInput
                name="value"
                className="form-control"
                placeholder={`${t("common.enter")} ${t("common.value")}`}
                type="number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value}
                invalid={validation.touched.value && validation.errors.value ? true : false}
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
              ) : null}
            </div>
          ) : (
            <Row>
              <Col sm={6} md={6} lg={6}>
                <div className="mb-3">
                  <Label htmlFor="contact_1" className="form-label">
                    {`${t("common.contact1")}*`}
                  </Label>
                  <PhoneNumberInput
                    isValid={!(validation.touched.contact_1 && validation.errors.contact_1)}
                    value={validation?.values?.contact_1}
                    onChange={(value) => {
                      validation.setFieldValue("contact_1", value);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("contact_1", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.contact_1 && validation.errors.contact_1 ? (
                    <p className="custom-invalid-feedback">{validation.errors.contact_1}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="contact_2" className="form-label">
                    {t("common.contact2")}
                  </Label>
                  <PhoneNumberInput
                    isValid={!(validation.touched.contact_2 && validation.errors.contact_2)}
                    value={validation?.values?.contact_2}
                    onChange={(value) => {
                      validation.setFieldValue("contact_2", value);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("contact_2", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.contact_2 && validation.errors.contact_2 ? (
                    <p className="custom-invalid-feedback">{validation.errors.contact_2}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}

          {validation?.values?.bidding_type === "telephone" ? (
            <div className="mb-3">
              <Label htmlFor="spoken" className="form-label">
                {`${t("common.languagePreference")}`}*
              </Label>
              <Select
                options={LANGUAGE_OPTIONS}
                getOptionLabel={(option) => option?.description}
                getOptionValue={(option) => option?.id}
                className={`custom-select ${
                  validation.touched.languages_speaks && validation.errors.languages_speaks ? "select-error" : ""
                }`}
                placeholder={`${t("common.select")} ${t("common.languagePreference")}`}
                value={validation.values.languages_speaks?.[0] || null}
                onChange={(languages_speaks) => {
                  validation.setFieldValue("languages_speaks", languages_speaks?.id ? [languages_speaks] : []);
                }}
                onInputChange={customerSearchDebounce}
                onBlur={(e) => {
                  validation.setFieldTouched("languages_speaks", true);
                  validation.handleBlur(e);
                }}
              />

              {validation.touched.languages_speaks && validation.errors.languages_speaks ? (
                <p className="custom-validation-style">{validation.errors.languages_speaks}</p>
              ) : null}
            </div>
          ) : null}

          <div className="mb-3">
            <Label htmlFor="employee_preference" className="form-label">
              {`${t("common.employeePreference")}`}
            </Label>
            <Select
              isClearable
              isLoading={userSearchLoader}
              name="employee_preference"
              options={userSearchLoader ? [] : USERS_OPTIONS}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              className={`custom-select ${
                validation.touched.employee_preference && validation.errors.employee_preference ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.employeePreference")}`}
              value={validation.values.employee_preference || null}
              onChange={(option) => {
                validation.setFieldValue("employee_preference", option?.id ? option : "");
              }}
              onInputChange={userSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("employee_preference", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.employee_preference && validation.errors.employee_preference ? (
              <p className="custom-invalid-feedback">{validation.errors.employee_preference}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="bidding_origin" className="form-label">
              {`${t("common.biddingOrigin")}`}
            </Label>
            <Select
              isClearable
              name="bidding_origin"
              options={BIDDING_ORIGIN_OPTIONS}
              className={`custom-select custom-mini-select ${
                validation.touched.bidding_origin && validation.errors.bidding_origin ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.biddingOrigin")}`}
              value={getTypeFromValue(BIDDING_ORIGIN_OPTIONS, validation.values.bidding_origin) || ""}
              onChange={(option) => {
                validation.setFieldValue("bidding_origin", option?.value ? option?.value : "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("bidding_origin", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.bidding_origin && validation.errors.bidding_origin ? (
              <p className="custom-invalid-feedback">{validation.errors.bidding_origin}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader || JSON.stringify(validation?.initialValues) === JSON.stringify(validation?.values)}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddBidModal);
