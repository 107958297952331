import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import {
  ADD_DOCUMENTSTATUS,
  DELETE_DOCUMENTSTATUS,
  SET_DOCUMENTSTATUS_DATA,
  UPDATE_DOCUMENTSTATUS,
} from "./actionTypes";

export const getDocumentStatus = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/document-status`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_DOCUMENTSTATUS_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add currency
export const addDocumentStatus = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/document-status`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_DOCUMENTSTATUS,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update currency
export const updateDocumentStatus = (documentStatusId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/document-status/${documentStatusId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_DOCUMENTSTATUS,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete currency
export const deleteDocumentStatus = (documentStatusId) => async (dispatch) => {
  try {
    return API.delete(`/billings/document-status/${documentStatusId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: DELETE_DOCUMENTSTATUS,
          payload: documentStatusId,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
