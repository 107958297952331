import { RESET_LOGGEDIN_USER, SET_LOGGEDIN_USER, SET_USER_DETAILS } from "./actionType";

const INIT_STATE = {
  userData: JSON.parse(localStorage.getItem("user")),
  token: localStorage.getItem("token"),
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOGGEDIN_USER:
      return {
        ...state,
        userData: action.payload.userData,
        token: action.payload.token,
      };
    case RESET_LOGGEDIN_USER:
      return {
        ...state,
        userData: null,
        token: null,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

export default User;
