import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";

import {
  ADD_PAYMENTCONDITION,
  DELETE_PAYMENTCONDITION,
  SET_PAYMENTCONDITIONS_DATA,
  UPDATE_PAYMENTCONDITION,
} from "./actionTypes";

export const getPaymentConditions = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/payment-conditions`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PAYMENTCONDITIONS_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add payment conditions
export const addPaymentCondition = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/payment-conditions`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_PAYMENTCONDITION,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update payment conditions
export const updatePaymentCondition = (paymentConditionId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/payment-conditions/${paymentConditionId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_PAYMENTCONDITION,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete payment conditions
export const deletePaymentCondition = (paymentConditionId) => async (dispatch) => {
  try {
    return API.delete(`/billings/payment-conditions/${paymentConditionId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: DELETE_PAYMENTCONDITION,
          payload: paymentConditionId,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
