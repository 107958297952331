import { ADD_CATEGORY, DELETE_CATEGORY, SET_CATEGORIES_DATA, UPDATE_CATEGORY } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CATEGORIES_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_CATEGORY:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_CATEGORY:
      const existingCategoryIndex = state.data.findIndex((category) => category.id === action.payload.id);
      const updatedCategoriesData = state.data;
      if (existingCategoryIndex !== -1) {
        updatedCategoriesData[existingCategoryIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedCategoriesData,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        data: [...state.data.filter((category) => category.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default Categories;
