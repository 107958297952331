import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { Button, Card, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddUserModal from "../../../../components/pages/settings/AddUserModal";
import { ACTIVE_SELECTION, SELECT_ALL_OPTION } from "../../../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  getLableAndValue,
  getLanguageCodes,
  getValuesFromArray,
} from "../../../../helpers/utils";
import {
  createUser,
  deleteUser,
  editUser,
  getMainLanguages,
  getPermissions,
  getSpokenLanguages,
  getUsers,
} from "../../../../store/general-user-settings/action";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const UserSettings = (props) => {
  const dispatch = useDispatch();
  const { data: userData, total: totalUser } = useSelector((state) => state.GeneralUserSettings);
  const { mainLanguageList, spokenLanguageList, permissionList } = useSelector((state) => state.GeneralUserSettings);

  // eslint-disable-next-line no-unused-vars
  const [isGetUserLoader, setIsGetUserLoader] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [userSaveLoader, setUserSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [mainLanguageLoader, setMainLanguageLoader] = useState(false);
  const [spokenLanguageLoader, setSpokenLanguageLoader] = useState(false);
  const [permissionsLoader, setPermissionsLoader] = useState(false);
  const [userDeleteLoader, setUserDeleteLoader] = useState(false);
  const [mainLanguages, setMainLanguages] = useState([]);
  const [spokenLanguages, setSpokenLanguages] = useState([]);
  const [permissionOption, setPermissionOption] = useState("");
  const [selectedMainLanguage, setSelectedMainLanguage] = useState([]);
  const [selectedSpokenLanguages, setSelectedSpokenLanguage] = useState([]);
  const [selectedActive, setSelectedActive] = useState("");
  const [selectedPermission, setSelectedPermission] = useState("");
  const [userPayload, setUserPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {
      id__icontains: "",
      name__icontains: "",
      email__icontains: "",
    },
  });

  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.users")}`;

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setUserPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  useEffect(() => {
    setMainLanguages(getLableAndValue(mainLanguageList));
    setSpokenLanguages(getLableAndValue(spokenLanguageList));
    setPermissionOption(getLableAndValue(permissionList));
  }, [mainLanguageList, spokenLanguageList, permissionList]);

  // getting list of users
  const handleGetUserList = useCallback(
    (userPayload) => {
      setIsGetUserLoader(true);
      dispatch(getUsers(userPayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setIsGetUserLoader(false);
        })
        .catch((error) => {
          setIsGetUserLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetUserList(userPayload);
  }, [handleGetUserList, userPayload]);

  // call main language and spoken language
  useEffect(() => {
    setMainLanguageLoader(true);

    // fetch main languages
    dispatch(getMainLanguages({ is_main: true }))
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setMainLanguageLoader(false);
      })
      .catch((error) => {
        setMainLanguageLoader(false);
      });

    // fetch spoken languages
    setSpokenLanguageLoader(true);
    dispatch(getSpokenLanguages({ is_spoken: true }))
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setSpokenLanguageLoader(false);
      })
      .catch((error) => {
        setSpokenLanguageLoader(false);
      });

    // fetch permissions
    setPermissionsLoader(true);
    dispatch(getPermissions())
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setPermissionsLoader(false);
      })
      .catch((error) => {
        setPermissionsLoader(false);
      });
  }, [dispatch]);

  const handleSaveUser = (values, validation) => {
    setUserSaveLoader(true);

    if (selectedRow && selectedRow.id) {
      const { email, ...editedData } = values;
      // to edit user
      dispatch(editUser({ id: selectedRow.id, data: editedData }))
        .then((res) => {
          if (res) {
            //
            handleGetUserList(userPayload);
            handleUserModalClose();
          } else {
            //
          }
          setUserSaveLoader(false);
        })
        .catch((error) => {
          setUserSaveLoader(false);
          handleUserModalClose();
        });
    } else {
      // to create/save user
      dispatch(createUser(values))
        .then((res) => {
          if (res) {
            //
            handleGetUserList(userPayload);
            handleUserModalClose();
          } else {
            //
          }
          setUserSaveLoader(false);
        })
        .catch((error) => {
          setUserSaveLoader(false);
          handleUserModalClose();
        });
    }
  };

  const handleConfirmBtn = () => {
    setUserDeleteLoader(true);

    // to delete the user
    dispatch(deleteUser(selectedRow.id))
      .then((res) => {
        if (res) {
          //
          handleGetUserList(userPayload);
        } else {
          //
        }
        setUserDeleteLoader(false);
        setIsDeleteUserModalOpen(false);
      })
      .catch((error) => {
        setUserDeleteLoader(false);
        setIsDeleteUserModalOpen(false);
      });
  };

  const handleCloseBtn = () => {
    setIsDeleteUserModalOpen(false);
  };

  // handle page change
  const handlePageChange = (value) => {
    setUserPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setUserPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleUserModalClose = () => {
    setIsAddUserModalOpen(false);
    setSelectedRow(null);
  };

  const handleUserModalOpen = () => {
    setSelectedRow(null);
    setIsAddUserModalOpen(true);
  };

  const userEditHandler = (row) => {
    setIsAddUserModalOpen(true);
    setSelectedRow(row);
  };

  const userDeleteHandler = (row) => {
    setSelectedRow(row);
    setIsDeleteUserModalOpen(true);
  };

  // handle main language filter
  const handleMainFilter = (values) => {
    if (checkSelectAllSelected(values)) {
      setSelectedMainLanguage({ label: "Select All", value: "*" });
      return setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.languages__in;
        return { ...tempPayload, page: 1 };
      });
    }
    if (values.length > 0) {
      setUserPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          languages__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.languages__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handle spoken language filter
  const handleSpokenFilter = (values) => {
    if (checkSelectAllSelected(values)) {
      setSelectedSpokenLanguage({ label: "Select All", value: "*" });
      return setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.languages_speaks__in;
        return { ...tempPayload, page: 1 };
      });
    }
    if (values.length > 0) {
      setUserPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          languages_speaks__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.languages_speaks__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handle spoken language filter
  const handleActiveFilter = (values) => {
    if (values.value === "*") {
      setSelectedActive({ label: "Select All", value: "*" });
      return setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.is_active;
        return { ...tempPayload, page: 1 };
      });
    }
    if (values) {
      setUserPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          is_active: values.value,
        },
        page: 1,
      }));
    } else {
      setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.is_active;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handle spoken language filter
  const handlePermissionFilter = (values) => {
    if (checkSelectAllSelected(values)) {
      setSelectedPermission({ label: "Select All", value: "*" });
      return setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.permission__in;
        return { ...tempPayload, page: 1 };
      });
    }
    if (values.length > 0) {
      setUserPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          permission__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setUserPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.permission__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={userPayload}
            setPayload={setUserPayload}
          />
        </div>
      ),
      selector: (row) => row.id,
      wrap: true,
      minWidth: "150px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="name__icontains"
            className="column-search-input"
            payload={userPayload}
            setPayload={setUserPayload}
          />
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.email")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="email__icontains"
            className="column-search-input"
            payload={userPayload}
            setPayload={setUserPayload}
          />
        </div>
      ),
      selector: (row) => row.email,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            {`${props.t("settings.general.users.system")} ${props.t("settings.general.language.language")}`}
          </div>
          <Select
            className="custom-select"
            isMulti
            components={{ Option }}
            options={[{ label: "Select All", value: "*" }, ...mainLanguages]}
            value={selectedMainLanguage || []}
            onChange={(sortBy) => {
              setSelectedMainLanguage(sortBy);
              handleMainFilter(sortBy);
            }}
            hideSelectedOptions={false}
          />
        </div>
      ),
      selector: (row) => getLanguageCodes(row?.languages),
      wrap: true,
      center: true,
      minWidth: "220px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            {`${props.t("settings.general.language.spoken")} ${props.t("settings.general.language.language")}`}
          </div>
          <Select
            className="custom-select"
            isMulti
            components={{ Option }}
            options={[{ label: "Select All", value: "*" }, ...spokenLanguages]}
            value={selectedSpokenLanguages}
            onChange={(sortBy) => {
              setSelectedSpokenLanguage(sortBy);
              handleSpokenFilter(sortBy);
            }}
            hideSelectedOptions={false}
          />
        </div>
      ),
      selector: (row) => getLanguageCodes(row.languages_speaks),
      wrap: true,
      center: true,
      minWidth: "220px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.active")}`}
            sortable
            sortableKey="is_active"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            className="custom-select"
            isMulti={false}
            components={{ Option }}
            options={[SELECT_ALL_OPTION, ...ACTIVE_SELECTION]}
            value={selectedActive}
            onChange={(sortBy) => {
              setSelectedActive(sortBy);
              handleActiveFilter(sortBy);
            }}
          />
        </div>
      ),
      selector: (row) => (row.is_active ? `${props.t("common.yes")}` : `${props.t("common.no")}`),
      wrap: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("sidebarMenu.permissions")}`}
            sortable
            sortableKey="permission"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            className="custom-select"
            isMulti
            components={{ Option }}
            options={[{ label: "Select All", value: "*" }, ...permissionOption]}
            value={selectedPermission}
            onChange={(sortBy) => {
              setSelectedPermission(sortBy);
              handlePermissionFilter(sortBy);
            }}
            hideSelectedOptions={false}
          />
        </div>
      ),
      selector: (row) => row.permission.code,
      wrap: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.general.users.specialist")}</div>
        </div>
      ),
      selector: (row) =>
        row?.specialist ? (
          <i className="mdi mdi-check-circle" style={{ fontSize: "20px", color: "#45CB85" }}></i>
        ) : null,
      minWidth: "120px",
      center: true,
      wrap: true,
    },
    {
      name: (
        <>
          <div className="font-weight-bold fs-14 table-column-container py-4">
            <div className="table-actions">{props.t("common.actions")}</div>
          </div>
        </>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => userEditHandler(row)}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => userDeleteHandler(row)}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
      wrap: true,
      fixed: "right",
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput className="custom-search-input" payload={userPayload} setPayload={setUserPayload} />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleUserModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">
                {`${props.t("common.add")} ${props.t("settings.general.users.user")}`}
              </span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {isGetUserLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.users")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            // selectableRows
            responsive={true}
            columns={columns}
            data={[...userData]}
            pagination
            paginationServer
            paginationTotalRows={totalUser}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* delete modal for languages */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("settings.general.users.user")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.general.users.user"),
        })}
        actionBtnTitle={`${props.t("common.delete")}`}
        loader={userDeleteLoader}
        isModalOpen={isDeleteUserModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />

      {/* to add /edit languages */}
      <AddUserModal
        isModalOpen={isAddUserModalOpen}
        user={selectedRow}
        onCancel={handleUserModalClose}
        onSave={handleSaveUser}
        loader={userSaveLoader}
        mainLanguageLoader={mainLanguageLoader}
        spokenLanguageLoader={spokenLanguageLoader}
        permissionsLoader={permissionsLoader}
      />
    </>
  );
};

export default withTranslation()(UserSettings);
