import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import { createFileUrl, extractNameFromUrl, scrollToFirstFormikError } from "../../../helpers/utils";

const AddTemplateModal = ({ template, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      document: template?.document || "",
      template_name: template?.template_name || "",
    },
    validationSchema: Yup.object({
      document: Yup.string().required(`${t("common.please")} ${t("common.upload")} ${t("common.document")}`),
      template_name: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.description")}`),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  const clearUploadedDocument = () => {
    validation.setFieldValue("document", "");
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {template?.id
          ? t("common.editTitle", {
              module: t("settings.lots.templates.template"),
            })
          : t("common.addTitle", {
              module: t("settings.lots.templates.template"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="document" className="form-label">
              {t("common.document")}
            </Label>
            {validation.values.document ? (
              <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                <CustomLinkWrapper
                  link={
                    typeof validation.values.document === "string"
                      ? validation.values.document
                      : createFileUrl(validation.values.document)
                  }
                  title={
                    typeof validation.values.document === "string"
                      ? extractNameFromUrl(validation.values.document, "/", 5)
                      : validation.values.document.name
                  }
                />
                <i className="ri-close-fill fs-18 cursor-pointer text-danger" onClick={clearUploadedDocument} />
              </div>
            ) : (
              <>
                <Input
                  name="document"
                  className="form-control"
                  placeholder={`${t("common.enter")} ${t("common.document")}`}
                  type="file"
                  accept="image/*,video/*,application/pdf,application/vnd.ms-excel,.doc,.docx,.txt"
                  onChange={async (e) => {
                    validation.setFieldValue("document", e.target.files[0]);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.document || ""}
                  invalid={validation.touched.document && validation.errors.document ? true : false}
                />
                {validation.touched.document && validation.errors.document ? (
                  <FormFeedback type="invalid">{validation.errors.document}</FormFeedback>
                ) : null}
              </>
            )}
          </div>
          <div className="mb-3">
            <Label htmlFor="template_name" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="template_name"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.description")}`}
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.template_name || ""}
              invalid={validation.touched.template_name && validation.errors.template_name ? true : false}
            />
            {validation.touched.template_name && validation.errors.template_name ? (
              <FormFeedback type="invalid">{validation.errors.template_name}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddTemplateModal);
