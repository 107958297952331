import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { CardFooter, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { checkPDFFileValidation } from "../../../../helpers/utils";
import Loader from "../../../common/Loader";
import MultiMediaTableForm from "../../../common/MultiMediaTableForm";

const EditionCatalogue = ({ languageType, pageType, ...props }) => {
  const [infoLoader, setInfoLoader] = useState(false);

  return (
    <>
      <FormikProvider value={props?.catalogueValidation}>
        <Form
          className="position-relative"
          onSubmit={(e) => {
            e.preventDefault();
            props?.catalogueValidation.handleSubmit();
            return false;
          }}
        >
          {infoLoader ? <Loader /> : null}
          <Row>
            {/* catalogue */}
            <Col sm={12} md={12} lg={10}>
              <div className="mb-3">
                <Label className="form-label fw-bold">{`${props.t("common.catalogue")}`}</Label>
                <MultiMediaTableForm
                  hideAddButton
                  hideDeleteAction
                  validation={props?.catalogueValidation}
                  arrayKey="catalogue"
                  sectionName={props.t("common.catalogue")}
                  defaultRowValues={{
                    issuu_link: "",
                    pdf_catalogue: "",
                    pdf_erratum: "",
                  }}
                  columns={[
                    {
                      key: "issuu_link",
                      type: "text",
                      label: props.t("common.issuuLink"),
                      spacing: { sm: 4, md: 4, lg: 4 },
                    },
                    {
                      key: "pdf_catalogue",
                      label: props.t("common.pdfCatalogue"),
                      spacing: { sm: 4, md: 4, lg: 4 },
                      type: "text",
                    },
                    {
                      key: "pdf_erratum",
                      label: props.t("common.pdfErratum"),
                      spacing: { sm: 4, md: 4, lg: 4 },
                      type: "text",
                    },
                  ]}
                />
              </div>
            </Col>
            {/* catalogue spine color */}
            <Col sm={12} md={4} lg={2}>
              <div className="mb-3">
                <Label className="form-label fw-bold">{`${props.t("common.catalogueSpineColor")}`}</Label>
                <Input
                  name="catalogue_spine_color"
                  className="form-control"
                  type="color"
                  onChange={props?.catalogueValidation.handleChange}
                  onBlur={props?.catalogueValidation.handleBlur}
                  value={props?.catalogueValidation.values.catalogue_spine_color || ""}
                  invalid={
                    props?.catalogueValidation.touched.catalogue_spine_color &&
                    props?.catalogueValidation.errors.catalogue_spine_color
                      ? true
                      : false
                  }
                />
                {props?.catalogueValidation.touched.catalogue_spine_color &&
                props?.catalogueValidation.errors.catalogue_spine_color ? (
                  <FormFeedback type="invalid">{props?.catalogueValidation.errors.catalogue_spine_color}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </>
  );
};

export default withTranslation()(EditionCatalogue);
