import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const UpdateBulkUserModal = ({ artist, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      is_active: true,
    },
    validationSchema: Yup.object({
      is_active: Yup.boolean()
        .oneOf(
          [true, false],
          `${t("common.selectValidationMsg", {
            field: `${t("common.status")}`,
          })}`
        )
        .required(
          `${t("common.selectValidationMsg", {
            field: `${t("common.status")}`,
          })}`
        ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.update", {
          module: `${t("sidebarMenu.customers")}`,
        })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="active user" className="form-label">
              {`${t("common.activeStatus")}`}
            </Label>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                role="switch"
                id="is_active"
                name="is_active"
                // defaultChecked={user ? user?.is_active : true}
                defaultChecked={true}
                className="form-check-input"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.is_active}
                invalid={validation.touched.is_active && validation.errors.is_active && true}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.update", { module: "" })}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(UpdateBulkUserModal);
