import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";

const CustomDropdownButton = ({
  buttonBGColor,
  options,
  color,
  disabled,
  btnTitle,
  isLoading,
  classes,
  id,
  styles,
}) => {
  return (
    <UncontrolledDropdown group style={{ ...styles }}>
      <DropdownToggle
        id={id}
        caret
        color={`${color ? color : "primary"}`}
        disabled={disabled || isLoading}
        className={`${classes ? classes : ""} ${isLoading ? "btn-load d-flex align-items-center" : ""}`}
        style={{ backgroundColor: buttonBGColor, borderColor: buttonBGColor }}
      >
        {isLoading ? (
          <span className="d-flex align-items-center ">
            <Spinner size="xs" className="flex-shrink-0"></Spinner>
          </span>
        ) : null}
        <span className={`flex-grow-1 ${isLoading ? "ms-2" : ""}`}>{btnTitle}</span>
      </DropdownToggle>
      <DropdownMenu>
        {options?.map((option, index) => {
          return (
            <div key={index}>
              <DropdownItem key={index} disabled={option.disabled} onClick={option.onClick ? option.onClick : () => {}}>
                {option.label}
              </DropdownItem>
              {index !== options.length - 1 ? <DropdownItem key={`divider-${index}`} divider /> : null}
            </div>
          );
        })}
        {options?.length === 0 || options === undefined ? <DropdownItem header>no options</DropdownItem> : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default CustomDropdownButton;
