import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomButton from "./CustomButton";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../helpers/constants";
import { getCustomerOptionLabel } from "../../helpers/utils";
import { getCustomerList } from "../../store/customers/actions";
import { NEW_PERSONA_TYPE } from "../../helpers/constants/selectOptions";
import i18next from "i18next";

const MergeCustomerModal = ({ isModalOpen, title, onCancel, onConfirm, disabled, t, loader }) => {
  const dispatch = useDispatch();

  const [selectedPersonaType, setSelectedPersonaType] = useState({
    value: "personal",
    label: i18next.t("common.personal"),
  });
  const [mainCustomers, setMainCustomers] = useState([]);
  const [personaToBe, setPersonaToBe] = useState([]);
  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customerPayload, setCustomerPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: {
      parent_id: null,
    },
  });
  const [personaToBeSearchLoader, setPersonaToBeSearchLoader] = useState(false);
  const [personaToBePayload, setPersonaToBePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: {
      parent_id: null,
    },
    is_persona_count: true,
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      main_customer_id: "",
      persona_id: "",
      customer_type: "personal",
    },
    validationSchema: Yup.object({
      main_customer_id: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.mainCustomer")}`),
      persona_id: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.person")}`),
      customer_type:
        Yup.string().oneOf[
          (("peronal", "company"),
            `${t("common.please")} ${t("common.select")} ${t("common.customerType")}`)
          ],
    }),
    onSubmit: (values) => {
      onConfirm({
        main_customer_id: values?.main_customer_id?.id || null,
        persona_id: values?.persona_id?.id || null,
        customer_type: values?.customer_type || null,
      });
    },
  });

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the main customer search
  const handleCustomerSearch = (value) => {
    if (value !== customerPayload.search_customer__icontains)
      setCustomerPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, search_customer__icontains: value },
      }));
  };

  // to memorize the debounce
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
    };
  }, [customerSearchDebounce]);

  useEffect(() => {
    if (isModalOpen)
      validation.resetForm();
  }, [isModalOpen]);

  //   to get the list of main customers
  useEffect(() => {
    if (isModalOpen) {
      setCustomerSearchLoader(true);
      dispatch(getCustomerList(customerPayload, true)).then((res) => {
        setCustomerSearchLoader(false);
        if (res) {
          setMainCustomers((prevState) => [...res?.results]);
        }
      });
    }
  }, [customerPayload, dispatch, isModalOpen]);

  // to handle the main customer search
  const handlePersonaToBeSearch = (value) => {
    if (value !== personaToBePayload.search_customer__icontains)
      setPersonaToBePayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, search_customer__icontains: value },
      }));
  };

  // to memorize the debounce
  const personaToBeSearchDebounce = useMemo(() => {
    return debounce(handlePersonaToBeSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      personaToBeSearchDebounce.cancel();
    };
  }, [personaToBeSearchDebounce]);

  //   to get the list of personaToBe customers
  useEffect(() => {
    if (validation?.values?.main_customer_id) {
      setPersonaToBeSearchLoader(true);
      dispatch(getCustomerList({
        ...personaToBePayload,
        exclude: { id: validation?.values?.main_customer_id?.id },
      }, true)).then((res) => {
        setPersonaToBeSearchLoader(false);
        if (res) {
          setPersonaToBe((prevState) => [...res?.results]);
        }
      });
    }
  }, [personaToBePayload, dispatch, validation?.values?.main_customer_id]);


  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{title}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody className="d-flex flex-column justify-content-center ">
          <div className="mb-3">
            <Label htmlFor="main_customer_id" className="form-label">
              {t("common.mainCustomer")}
            </Label>
            <Select
              isLoading={customerSearchLoader}
              name="main_customer_id"
              options={customerSearchLoader ? [] : mainCustomers}
              getOptionLabel={(customer) => getCustomerOptionLabel(customer, " - ", true)}
              getOptionValue={(option) => option?.id}
              className={`custom-select ${
                validation.touched.main_customer_id && validation.errors.main_customer_id ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.mainCustomer")}`}
              value={validation.values.main_customer_id || ""}
              onChange={(option) => {
                validation.setFieldValue("main_customer_id", option?.id ? option : "");
                validation.setFieldValue("persona_id", "");
              }}
              onInputChange={customerSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("main_customer_id", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.main_customer_id && validation.errors.main_customer_id ? (
              <p className="custom-invalid-feedback">{validation.errors.main_customer_id}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="persona_id" className="form-label">
              {t("common.person")}
            </Label>
            <Select
              isLoading={personaToBeSearchLoader}
              name="persona_id"
              options={personaToBeSearchLoader ? [] : personaToBe}
              getOptionLabel={(customer) => getCustomerOptionLabel(customer, " - ", true)}
              getOptionValue={(option) => option?.id}
              className={`custom-select ${
                validation.touched.persona_id && validation.errors.persona_id ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.person")}`}
              value={validation.values.persona_id || ""}
              onChange={(option) => {
                validation.setFieldValue("persona_id", option?.id ? option : "");
              }}
              onInputChange={personaToBeSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("persona_id", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.persona_id && validation.errors.persona_id ? (
              <p className="custom-invalid-feedback">{validation.errors.persona_id}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="title" className="form-label">
              {`${t("common.customerType")}*`}
            </Label>
            <Select
              name="customer_type"
              options={NEW_PERSONA_TYPE}
              value={selectedPersonaType}
              className={`custom-select ${
                validation.touched.customer_type && validation.errors.customer_type ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.customerType")}`}
              onChange={(customer_type) => {
                setSelectedPersonaType(customer_type);
                validation.setFieldValue("customer_type", customer_type?.value || "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("customer_type", true);
                validation.handleBlur(e);
              }}
            />
          </div>
          {+validation?.values?.persona_id?.total_personas > 0 ?
            <div className="d-flex gap-2 flex-row justify-content-center">
              <i className="ri-file-info-fill text-danger" style={{ fontSize: "medium" }}></i>
              <p
                className="text-danger">{t("common.personaInsidePersonaToBe", { totalPersona: validation?.values?.persona_id?.total_personas })}</p>
            </div> : null}
        </ModalBody>
        <ModalFooter>
          <CustomButton btnTitle={t("common.cancel")} type="button" onClick={handleModalClose} color="dark" />
          <CustomButton btnTitle={t("common.mergeCustomers")} isLoading={loader} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(MergeCustomerModal);
