import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  DEBOUNCE_TIME,
  DROPDOWN_DEFAULT_LIMIT,
  INVOICE_DOCUMENT,
  PROFORMA_INVOICE_DOCUMENT,
} from "../../../helpers/constants";
import {
  DISCOUNT_UNIT_TYPES,
  FROM_TYPE,
  TAX_EXEMPTION_OPTIONS,
  TYPE_OF_PRODUCTS,
} from "../../../helpers/constants/selectOptions";
import {
  calculateTotalForDocumentLine,
  filteredTypeOfProduct,
  getColumnDetail,
  getContractItemDescriptionForInvoice,
  getLotDescriptionForInvoice,
  getOnlyCustomerName,
  getPremiumAmountForLot,
  getPremiumByPaddleNumber,
  getTypeFromValue,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";
import { getProductsAndServices } from "../../../store/products-services/actions";
import { getAuctionList } from "../../../store/auctions/actions";
import { getPaddles } from "../../../store/auctions/paddleActions";
import { getVATs } from "../../../store/vat/actions";
import { getLotLists } from "../../../store/auctions/lotActions";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { getContractItems } from "../../../store/contracts/actions";
import NumberInput from "../../common/NumberInput";

const ProformaInvoiceDocumentLineModal = ({
                                            selectedPremium,
                                            doNotApplyVat,
                                            exemptionCode,
                                            documentType,
                                            selectedLots,
                                            selectedCustomer,
                                            isModalOpen,
                                            handleModalClose,
                                            addDocumentLine,
                                            t,
                                          }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { contractItems, totalItems } = useSelector((state) => state?.Contracts);
  const { data: lotsData, total: totalLots } = useSelector((state) => state?.Auctions?.lots);
  const DEFAULT_VAT_OPTION = useSelector((state) => state?.common?.vats?.default);
  const PRODUCTS_OPTIONS = useSelector((state) => state?.ProductsAndServices?.data);
  const AUCTIONS_OPTIONS = useSelector((state) => state?.Auctions?.data);
  const PADDLES_OPTIONS = useSelector((state) => state?.Auctions?.paddles?.data);

  const VAT_OPTIONS = useSelector((state) => state?.VAT.data);
  const [vatOptions, setVatOptions] = useState(VAT_OPTIONS);

  const memoizedDefaultVatOption = useMemo(() => DEFAULT_VAT_OPTION, [DEFAULT_VAT_OPTION]);
  const memoizedVatOptions = useMemo(() => VAT_OPTIONS, [VAT_OPTIONS]);

  const [saveLoader, setSaveLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [productsSearchLoader, setProductsSearchLoader] = useState(false);
  const [productsPayload, setProductsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "product_name",
  });
  const [vatSearchLoader, setVatSearchLoader] = useState(false);
  const [vatPayload, setVatPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "code",
    filter: doNotApplyVat ? { rate: 0 } : undefined,
    exclude: doNotApplyVat ? undefined : { rate: 0 },
  });
  const [auctionSearchLoader, setAuctionSearchLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "-auction_no",
  });
  const [paddleSearchLoader, setPaddleSearchLoader] = useState(false);
  const [paddlePayload, setPaddlePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "id",
    filter: selectedCustomer ? { customer: selectedCustomer } : {},
  });
  const [itemsPayload, setItemsPayload] = useState({
    page: 1,
    filter: {
      is_sold: false,
      is_invoice: false,
    },
  });
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    sort: "lot_no",
  });

  const [vatOption, setVatOption] = useState("");

  useEffect(() => {
    let calculatedVatOption = "";
    const typeOfProduct =
      documentType === INVOICE_DOCUMENT || documentType === PROFORMA_INVOICE_DOCUMENT ? "lot" : "product";

    if (doNotApplyVat) {
      calculatedVatOption = memoizedVatOptions.find((option) => option.rate === 0);
    } else if (typeOfProduct === "lot") {
      calculatedVatOption = memoizedDefaultVatOption;
    } else {
      calculatedVatOption = "";
    }
    setVatOption(calculatedVatOption);
  }, [documentType, doNotApplyVat, memoizedVatOptions, memoizedDefaultVatOption]);

  // handle products or services search
  const handleProductsSearch = (value) => {
    setProductsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, product_name__icontains: value },
    }));
  };
  // debounce for product or service search
  const productSearchDebounce = useMemo(() => {
    return debounce(handleProductsSearch, DEBOUNCE_TIME);
  }, []);

  // handle auction search
  const handleAuctionSearch = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("search_auction_en__icontains", "search_auction_pt__icontains")]: value,
      },
    }));
  };
  // debounce for auction search
  const auctionSearchDebounce = useMemo(() => {
    return debounce(handleAuctionSearch, DEBOUNCE_TIME);
  }, []);

  // handle paddle search
  const handlePaddleSearch = (value) => {
    setPaddlePayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_paddle__icontains: value },
    }));
  };
  // debounce for paddle search
  const paddleSearchDebounce = useMemo(() => {
    return debounce(handlePaddleSearch, DEBOUNCE_TIME);
  }, []);

  // handle vat search
  const handleVatSearch = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_code__icontains: value },
    }));
  };
  // debounce for vat search
  const vatSearchDebounce = useMemo(() => {
    return debounce(handleVatSearch, DEBOUNCE_TIME);
  }, []);

  // cancel all debounces
  useEffect(() => {
    return () => {
      productSearchDebounce.cancel();
      vatSearchDebounce.cancel();
      auctionSearchDebounce.cancel();
      paddleSearchDebounce.cancel();
    };
  }, [productSearchDebounce, vatSearchDebounce, auctionSearchDebounce, paddleSearchDebounce]);

  // to the list of products or services
  useEffect(() => {
    if (isModalOpen) {
      setProductsSearchLoader(true);
      dispatch(getProductsAndServices(productsPayload)).then(() => {
        setProductsSearchLoader(false);
      });
    }
  }, [dispatch, productsPayload, isModalOpen]);

  // to get the list of the auctions
  useEffect(() => {
    if (isModalOpen) {
      setAuctionSearchLoader(true);
      dispatch(getAuctionList(auctionPayload)).then(() => {
        setAuctionSearchLoader(false);
      });
    }
  }, [dispatch, auctionPayload, isModalOpen]);

  // to get the list of the paddles
  useEffect(() => {
    if (isModalOpen) {
      setPaddleSearchLoader(true);
      dispatch(getPaddles(paddlePayload)).then(() => {
        setPaddleSearchLoader(false);
      });
    }
  }, [dispatch, paddlePayload, isModalOpen]);

  // to get the list of the vats
  useEffect(() => {
    if (isModalOpen) {
      setVatSearchLoader(true);
      dispatch(getVATs(vatPayload)).then(() => {
        setVatSearchLoader(false);
      });
    }
  }, [dispatch, vatPayload, isModalOpen]);

  // to get the list of lots
  useEffect(() => {
    if (isModalOpen && lotsPayload?.filter?.auction && lotsPayload?.filter?.paddle) {
      setTableLoader(true);
      dispatch(getLotLists(lotsPayload)).then(() => {
        setTableLoader(false);
      });
    }
  }, [lotsPayload, dispatch, isModalOpen]);

  // handle getting contract items
  const handleGetContractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then((res) => {
        setTableLoader(false);
      });
    },
    [dispatch],
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      type_of_product:
        documentType === INVOICE_DOCUMENT || documentType === PROFORMA_INVOICE_DOCUMENT ? "lot" : "product",
      from_type: "auction",
      product: "",
      quantity: "",
      auction: "",
      paddle: "",
      discount: 0,
      discount_unit: "%",
      premium: "",
      vat: vatOption,
      tax_exemption_code: exemptionCode || "",
    },
    validationSchema: Yup.object({
      type_of_product: Yup.string().required(
        `${t("common.please")} ${t("common.select")} ${t("common.typeOfProduct")}`,
      ),
      product: Yup.object().when("type_of_product", {
        is: (type_of_product) => type_of_product === "product" || type_of_product === "service",
        then: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.product/service")}`),
      }),
      quantity: Yup.number().when("type_of_product", {
        is: (type_of_product) => type_of_product === "product" || type_of_product === "service",
        then: Yup.number()
          .min(1, t("validation.numberMin", { totalNumber: 1 }))
          .required(`${t("common.please")} ${t("common.enter")} ${t("common.quantity")}`),
      }),
      auction: Yup.object().when(["type_of_product", "from_type"], {
        is: (type_of_product, from_type) => type_of_product === "lot" && from_type === "auction",
        then: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.auction")}`),
      }),
      paddle: Yup.object().when(["type_of_product", "from_type"], {
        is: (type_of_product, from_type) => type_of_product === "lot" && from_type === "auction",
        then: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.paddle")}`),
      }),
      discount: Yup.number()
        .min(0, t("validation.numberMin", { totalNumber: 0 }))
        .when("discount_unit", {
          is: (discount_unit) => discount_unit === "%",
          then: Yup.number().max(100, t("validation.numberMax", { totalNumber: 100 })),
        }),
      premium: Yup.number().min(0, t("validation.numberMin", { totalNumber: 0 })),
      // tax_exemption_code: Yup.string().when("vat", {
      //   is: (vat) =>
      //     vat.rate === 0 ,
      //   then: Yup.string()
      //     .required(
      //       `${t("common.please")} ${t("common.select")} ${t("common.taxExemptionCode")}`
      //     ),
      // }),
    }),
    onSubmit: (values) => {
      handleAddDocumentLine(values);
    },
  });

  // to get the list of contract items.
  useEffect(() => {
    if (isModalOpen && validation?.values?.from_type === "contract") {
      handleGetContractItems(itemsPayload);
    }
  }, [handleGetContractItems, isModalOpen, itemsPayload, validation?.values?.from_type]);

  // handle add document
  const handleAddDocumentLine = (values) => {
    if (values?.from_type === "auction") {
      if (
        ((isAllSelected && totalLots === 0) || (!isAllSelected && selectedRows?.length === 0)) &&
        values?.type_of_product === "lot"
      ) {
        toast.info(t("information.atleastOneItem", { module: t("common.lot") }));
        return;
      }
    } else {
      if (
        ((isAllSelected && totalItems === 0) || (!isAllSelected && selectedRows?.length === 0)) &&
        values?.type_of_product === "lot"
      ) {
        toast.info(t("information.atleastOneItem", { module: t("common.contractItem") }));
        return;
      }
    }

    if (values?.type_of_product === "lot") {
      if (values?.premium !== selectedPremium && selectedPremium) {
        toast.info(
          t("information.premiumNotMatchedInvoice", {
            premium: selectedPremium,
          }),
        );
        return;
      }
      if (values?.from_type === "auction") {
        let lots = [];
        let items = [];

        if (isAllSelected && totalLots) {
          lots = lotsData;
        }
        if (selectedRows.length > 0 && !isAllSelected) {
          lots = selectedRows;
        }
        items = lots?.map((lot) => {
          const item = {};
          const finalAmount = calculateTotalForDocumentLine(
            {
              qty: 1,
              value: lot?.sale_value,
              discount: values?.discount,
              discount_unit: values?.discount_unit,
              premium: getPremiumAmountForLot(values),
              vat: values?.vat?.rate,
            },
            values?.type_of_product,
            doNotApplyVat,
          );
          item.type_of_product = values?.type_of_product;
          item.auction = values?.auction?.id;
          item.paddle = values?.paddle?.id;
          item.quantity = 1;
          item.discount = values?.discount || 0;
          item.discount_unit = values?.discount_unit;
          item.auction_lot = lot?.id;
          item.vat = values?.vat?.id;
          item.amount = finalAmount;
          item.tax_exemption_code = values?.tax_exemption_code || null;
          if (getPremiumAmountForLot(values, true)) {
            item.premium = getPremiumAmountForLot(values);
          }

          const itemData = {};
          itemData.type_of_product = values?.type_of_product;
          itemData.positionInContract = lot?.contract_item?.contract_item_no;
          itemData.description = getLotDescriptionForInvoice(lot);
          itemData.quantity = 1;
          itemData.amount = lot?.sale_value || 0;
          itemData.premium = getPremiumAmountForLot(values);
          itemData.discount = values?.discount || 0;
          itemData.discount_unit = values?.discount_unit;
          itemData.vat = values?.vat?.rate || 0;
          itemData.final_amount = finalAmount;
          itemData.lot_no = lot?.lot_no;
          item.itemData = itemData;

          return { ...item };
        });
        // items.sort((a, b) => {
        //   if (a?.itemData?.lot_no === null && b?.itemData?.lot_no === null) {
        //     return 0;
        //   } else if (a?.itemData?.lot_no === null) {
        //     return -1;
        //   } else if (b?.itemData?.lot_no === null) {
        //     return 1;
        //   } else {
        //     return a?.itemData.lot_no - b?.itemData?.lot_no;
        //   }
        // });

        addDocumentLine(items);
        return;
      } else if (values?.from_type === "contract") {
        let lots = [];
        let items = [];

        if (isAllSelected && totalItems) {
          lots = contractItems;
        }
        if (selectedRows.length > 0 && !isAllSelected) {
          lots = selectedRows;
        }
        items = lots?.map((lot) => {
          const item = {};
          const finalAmount = calculateTotalForDocumentLine(
            {
              qty: 1,
              value: lot?.min_estimate || 0,
              discount: values?.discount,
              discount_unit: values?.discount_unit,
              premium: getPremiumAmountForLot(values),
              vat: values?.vat?.rate,
            },
            values?.type_of_product,
            doNotApplyVat,
          );
          item.type_of_product = values?.type_of_product;
          item.contract_item = lot?.id;
          item.quantity = 1;
          item.discount = values?.discount || 0;
          item.discount_unit = values?.discount_unit;
          item.vat = values?.vat?.id;
          item.amount = finalAmount;
          item.tax_exemption_code = values?.tax_exemption_code || null;
          if (getPremiumAmountForLot(values, true)) {
            item.premium = getPremiumAmountForLot(values);
          }

          const itemData = {};
          itemData.type_of_product = values?.type_of_product;
          itemData.positionInContract = lot?.contract_item_no;
          itemData.description = getContractItemDescriptionForInvoice(lot);
          itemData.quantity = 1;
          itemData.amount = lot?.min_estimate || 0;
          itemData.premium = getPremiumAmountForLot(values);
          itemData.discount = values?.discount || 0;
          itemData.discount_unit = values?.discount_unit;
          itemData.vat = values?.vat?.rate || 0;
          itemData.final_amount = finalAmount;
          item.itemData = itemData;

          return { ...item };
        });
        addDocumentLine(items);
        return;
      }
    }
    if (values?.type_of_product === "product" || values?.type_of_product === "service") {
      const item = {};
      const finalAmount = calculateTotalForDocumentLine(
        {
          qty: values?.quantity,
          value: values?.product?.value,
          discount: values?.discount,
          discount_unit: values?.discount_unit,
          vat: values?.vat?.rate,
        },
        values?.type_of_product,
        doNotApplyVat,
      );

      item.type_of_product = values?.type_of_product;
      item.product = values?.product?.id;
      item.quantity = values?.quantity;
      item.discount = values?.discount;
      item.discount_unit = values?.discount_unit;
      item.vat = values?.vat?.id;
      item.amount = finalAmount;
      item.tax_exemption_code = values?.tax_exemption_code || null;
      if (values?.premium) {
        item.premium = values?.premium;
      }

      const itemData = {};
      itemData.type_of_product = values?.type_of_product;
      itemData.description = values?.product?.product_name;
      itemData.quantity = values?.quantity;
      itemData.amount = values?.product?.value || 0;
      itemData.discount = values?.discount || 0;
      itemData.discount_unit = values?.discount_unit;
      itemData.vat = values?.vat?.rate || 0;
      itemData.final_amount = finalAmount;
      item.itemData = itemData;

      addDocumentLine([item]);
      return;
    }
  };

  // handle reset form with all other maunual states
  const handleReset = () => {
    validation.resetForm();
    setIsAllSelected(false);
    setSelectedRows([]);
    setLotsPayload({
      page: 1,
      sort: "lot_no",
    });
    setItemsPayload({
      page: 1,
      filter: { is_sold: false, is_invoice: false },
      exclude: { id__in: selectedLots?.contract_items },
    });
  };

  // use effect to reset the modal form
  useEffect(() => {
    if (!isModalOpen) {
      handleReset();
    }
  }, [isModalOpen]);

  // use effect to set the filter by the customer in paddles
  useEffect(() => {
    if (selectedCustomer && isModalOpen) {
      setPaddlePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          customer: selectedCustomer,
        },
      }));
    }
  }, [selectedCustomer, isModalOpen, state?.type]);

  // use effect to set the filter by the type in  product/service
  useEffect(() => {
    let type_of_product = "product";
    if (validation?.values?.type_of_product === "product") {
      type_of_product = "product";
    } else if (validation?.values?.type_of_product === "service") {
      type_of_product = "service";
    }
    setProductsPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        product_type: type_of_product,
      },
    }));
  }, [validation?.values?.type_of_product]);

  // use effect to filter the paddles of the particular auction only.
  useEffect(() => {
    if (validation?.values?.auction) {
      setPaddlePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          auction: validation?.values?.auction?.id,
        },
      }));
    }
  }, [validation?.values?.auction]);

  // use effect to filter the lots by auction and paddel id
  useEffect(() => {
    if (validation?.values?.auction && validation?.values?.paddle && documentType) {
      const filter = {};
      if (documentType === PROFORMA_INVOICE_DOCUMENT) {
        filter.contract_item__is_proforma_invoice = false;
      }
      if (documentType === INVOICE_DOCUMENT) {
        filter.contract_item__is_invoice = false;
      }

      setLotsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          ...filter,
          auction: validation?.values?.auction?.id,
          paddle: validation?.values?.paddle?.id,
        },
        exclude: {
          ...prevState?.exclude,
          id__in: selectedLots?.auction_lots,
        },
      }));
    }
  }, [validation?.values?.auction, validation?.values?.paddle, selectedLots?.auction_lots, documentType]);

  // useEffect to exclude contract items
  useEffect(() => {
    if (selectedLots?.contract_items?.length > 0) {
      setItemsPayload((prevState) => ({
        ...prevState,
        exclude: {
          ...prevState?.exclude,
          id__in: selectedLots?.contract_items,
        },
      }));
    }
  }, [selectedLots?.contract_items]);

  // get item description -> artist, title, description
  const getItemDescription = (row) => {
    let description = [];
    if (row?.author?.id) description.push(row?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.title_pt) description.push(row?.title_pt);
      if (row?.description_pt) description.push(row?.description_pt);
    } else {
      if (row?.title_en) description.push(row?.title_en);
      if (row?.description_en) description.push(row?.description_en);
    }
    return description?.length > 0 ? description.join(", ") : "-";
  };

  const contractItemsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.contractNo")} / ${t("common.item")} ${t(
            "common.no_",
          )}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.contract_item_no ? row?.contract_item_no : "-"),
      width: "25%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.description")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => getItemDescription(row),
      width: "65%",
      wrap: true,
    },
  ];

  const auctionColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "8%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_lot_no__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.lot_no ? row?.lot_no + row?.lot_alpha : "-"),
      width: "17%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.artist")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail(
              "contract_item__author__description_en__icontains",
              "contract_item__author__description_pt__icontains",
            )}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.contract_item?.author?.description_en, row?.contract_item?.author?.description) || "-",
      width: "23%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail(
              "contract_item__title_en__icontains",
              "contract_item__title_pt__icontains",
            )}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.contract_item?.title_en, row?.contract_item?.title_pt) || "-",
      width: "23%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail(
              "contract_item__description_en__icontains",
              "contract_item__description_pt__icontains",
            )}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.contract_item?.description_en, row?.contract_item?.description_pt) || "-",
      width: "25%",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="lg"
      className="modal-header-width-100"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{t("common.addTitle", { module: t("common.documentLine") })}</span>

          {((isAllSelected && totalLots > 0) || selectedRows?.length) &&
          validation?.values?.auction &&
          validation?.values?.paddle ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows?.length || totalLots}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>
      <Form
        onKeyPress={onKeyPressForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <Row>
            <Col sm={6} md={6} lg={6}>
              <div className="mb-3">
                <Label htmlFor="type_of_product" className="form-label">
                  {`${t("common.typeOfProduct")}*`}
                </Label>
                <Select
                  name="type_of_product"
                  // options={TYPE_OF_PRODUCTS?.filter(
                  //   (type) => !["expense", "debit_note"]?.includes(type?.value)
                  // )}
                  options={filteredTypeOfProduct(documentType)}
                  className={`custom-select ${
                    validation.touched.type_of_product && validation.errors.type_of_product ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.typeOfProduct")}`}
                  value={getTypeFromValue(TYPE_OF_PRODUCTS, validation.values.type_of_product) || ""}
                  onChange={(type_of_product) => {
                    handleReset();
                    validation.setFieldValue("type_of_product", type_of_product?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("type_of_product", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.type_of_product && validation.errors.type_of_product ? (
                  <p className="custom-invalid-feedback">{validation.errors.type_of_product}</p>
                ) : null}
              </div>
            </Col>
            {documentType === INVOICE_DOCUMENT && validation?.values?.type_of_product === "lot" ? (
              <Col sm={6} md={6} lg={6}>
                <div className="mb-3">
                  <Label htmlFor="from_type" className="form-label">
                    {`${t("common.from")}*`}
                  </Label>
                  <Select
                    name="from_type"
                    options={FROM_TYPE}
                    className={`custom-select ${
                      validation.touched.from_type && validation.errors.from_type ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.from")}`}
                    value={getTypeFromValue(FROM_TYPE, validation.values.from_type) || ""}
                    onChange={(from_type) => {
                      handleReset();
                      if (from_type?.value === "contract") {
                        validation.setFieldValue("premium", 17);
                      }
                      validation.setFieldValue("type_of_product", "lot");
                      validation.setFieldValue("from_type", from_type?.value);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("from_type", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.from_type && validation.errors.from_type ? (
                    <p className="custom-invalid-feedback">{validation.errors.from_type}</p>
                  ) : null}
                </div>
              </Col>
            ) : null}
          </Row>

          {/* type: product or service */}
          {validation?.values?.type_of_product !== "lot" && (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="product" className="form-label">
                    {`${t("common.product/service")}*`}
                  </Label>
                  <Select
                    isLoading={productsSearchLoader}
                    name="product"
                    options={productsSearchLoader ? [] : PRODUCTS_OPTIONS}
                    className={`custom-select ${
                      validation.touched.product && validation.errors.product ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.product/service")}`}
                    getOptionLabel={(option) => option?.product_name}
                    getOptionValue={(option) => option?.id}
                    value={validation.values.product || ""}
                    onInputChange={productSearchDebounce}
                    onChange={(product) => {
                      validation.setFieldValue("product", product);
                      setVatOptions(doNotApplyVat ? VAT_OPTIONS.filter((option) => option.rate === 0) : [product?.vat]);
                      validation.setFieldValue(
                        "vat",
                        doNotApplyVat ? VAT_OPTIONS.filter((option) => option.rate === 0) : product?.vat,
                      );
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("product", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.product && validation.errors.product ? (
                    <p className="custom-invalid-feedback">{validation.errors.product}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="quantity" className="form-label">
                    {t("common.quantity")}*
                  </Label>
                  <NumberInput
                    name="quantity"
                    className="form-control"
                    placeholder={`${t("common.enter")} ${t("common.quantity")}`}
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.quantity}
                    invalid={validation.touched.quantity && validation.errors.quantity ? true : false}
                  />
                  {validation.touched.quantity && validation.errors.quantity ? (
                    <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
          {/* type: lots */}
          {validation?.values?.type_of_product === "lot" && validation?.values?.from_type === "auction" && (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="auction" className="form-label">
                    {`${t("common.auction")}*`}
                  </Label>
                  <Select
                    isLoading={auctionSearchLoader}
                    name="auction"
                    options={auctionSearchLoader ? [] : AUCTIONS_OPTIONS}
                    className={`custom-select ${
                      validation.touched.auction && validation.errors.auction ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.auction")}`}
                    value={validation?.values?.auction || ""}
                    getOptionLabel={(option) =>
                      option?.auction_no + " - " + (getColumnDetail(option?.title_en, option?.title_pt) || "")
                    }
                    getOptionValue={(option) => option?.id}
                    onInputChange={auctionSearchDebounce}
                    onChange={(auction) => {
                      setIsAllSelected(false);
                      setSelectedRows([]);
                      validation.setFieldValue("paddle", "");
                      validation.setFieldValue("premium", auction?.buyer_premium >= 0 ? auction?.buyer_premium : "");
                      validation.setFieldValue("auction", auction);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("auction", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.auction && validation.errors.auction ? (
                    <p className="custom-invalid-feedback">{validation.errors.auction}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="paddle" className="form-label">
                    {`${t("common.paddle")}*`}
                  </Label>
                  <Select
                    isLoading={paddleSearchLoader}
                    isDisabled={!validation?.values?.auction}
                    name="paddle"
                    options={paddleSearchLoader ? [] : PADDLES_OPTIONS}
                    getOptionLabel={(option) => option?.paddle_no + " - " + getOnlyCustomerName(option?.customer)}
                    getOptionValue={(option) => option?.id}
                    className={`custom-select ${
                      validation.touched.paddle && validation.errors.paddle ? "select-error" : ""
                    } ${!validation?.values?.auction ? "disabled-select" : ""}`}
                    placeholder={`${t("common.select")} ${t("common.paddle")}`}
                    value={validation.values.paddle || ""}
                    onInputChange={paddleSearchDebounce}
                    onChange={(paddle) => {
                      setIsAllSelected(false);
                      setSelectedRows([]);
                      validation.setFieldValue("paddle", paddle);
                      if ([INVOICE_DOCUMENT, PROFORMA_INVOICE_DOCUMENT].includes(documentType)) {
                        const paddlePremium = getPremiumByPaddleNumber(paddle?.paddle_no);
                        if (paddlePremium) validation.setFieldValue("premium", paddlePremium);
                      }
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("paddle", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.paddle && validation.errors.paddle ? (
                    <p className="custom-invalid-feedback">{validation.errors.paddle}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
          {/* common */}
          <Row>
            {[PROFORMA_INVOICE_DOCUMENT, INVOICE_DOCUMENT].includes(documentType) &&
            validation?.values?.type_of_product === "lot" ? (
              <Col className="col-sm-12 col-md-12 col-xl-4">
                <div className="mb-3">
                  <Label htmlFor="premium" className="form-label">
                    {t("common.premium")}
                  </Label>
                  <NumberInput
                    name="premium"
                    className="form-control"
                    placeholder={`${t("common.enter")} ${t("common.premium")}`}
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.premium}
                    invalid={validation.touched.premium && validation.errors.premium ? true : false}
                  />
                  {validation.touched.premium && validation.errors.premium ? (
                    <FormFeedback type="invalid">{validation.errors.premium}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            ) : null}
            <Col className="col-sm-12 col-md-12 col-xl-4">
              <div className="mb-3">
                <Label htmlFor="discount" className="form-label">
                  {t("common.discount")}
                </Label>
                <Row>
                  <Col sm={7} md={7} lg={7}>
                    <NumberInput
                      name="discount"
                      className="form-control"
                      placeholder={`${t("common.enter")} ${t("common.discount")}`}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.discount}
                      invalid={validation.touched.discount && validation.errors.discount ? true : false}
                    />
                    {validation.touched.discount && validation.errors.discount ? (
                      <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={5} md={5} lg={5}>
                    <Select
                      name="discount_unit"
                      options={DISCOUNT_UNIT_TYPES}
                      className={`custom-select ${
                        validation.touched.discount_unit && validation.errors.discount_unit ? "select-error" : ""
                      }`}
                      value={getTypeFromValue(DISCOUNT_UNIT_TYPES, validation.values.discount_unit) || "%"}
                      onChange={(discount_unit) => {
                        validation.setFieldValue("discount_unit", discount_unit?.value);
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("discount_unit", true);
                        validation.handleBlur(e);
                      }}
                    />
                  </Col>
                </Row>
                {validation.touched.discount && validation.errors.discount ? (
                  <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-sm-12 col-md-12 col-xl-4">
              <div className="mb-3">
                <Label htmlFor="vat" className="form-label">
                  {`${t("common.vat_another")}`}
                </Label>
                <Select
                  isClearable
                  isLoading={vatSearchLoader}
                  name="vat"
                  options={vatOptions}
                  getOptionLabel={(option) => option?.code + " - " + option?.rate + "%"}
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${validation.touched.vat && validation.errors.vat ? "select-error" : ""}`}
                  placeholder={`${t("common.select")} ${t("common.vat_another")}`}
                  isDisabled={true}
                  value={validation?.values?.vat || ""}
                  onInputChange={vatSearchDebounce}
                  onChange={(vat) => {
                    validation.setFieldValue("vat", vat);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("vat", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.vat && validation.errors.vat ? (
                  <p className="custom-invalid-feedback">{validation.errors.vat}</p>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {doNotApplyVat ? (
                <div className="mb-3">
                  <Label className="form-label">{`${t("common.taxExemptionCode")}`}</Label>
                  <Select
                    isDisabled={doNotApplyVat || validation?.values?.vat?.rate !== 0 || exemptionCode}
                    options={TAX_EXEMPTION_OPTIONS}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.value}
                    className={`custom-select ${
                      validation.touched.tax_exemption_code && validation.errors.tax_exemption_code
                        ? "select-error"
                        : ""
                    } ${doNotApplyVat || validation?.values?.vat?.rate !== 0 ? "disabled-select" : ""}`}
                    // className={`custom-select disabled-select`}
                    placeholder={`${t("common.select")} ${t("common.taxExemptionCode")}`}
                    value={
                      doNotApplyVat
                        ? getTypeFromValue(TAX_EXEMPTION_OPTIONS, exemptionCode) || ""
                        : getTypeFromValue(TAX_EXEMPTION_OPTIONS, validation.values.tax_exemption_code) || ""
                    }
                    onChange={(tax_exemption_code) => {
                      validation.setFieldValue(
                        "tax_exemption_code",
                        tax_exemption_code?.value ? tax_exemption_code?.value : "",
                      );
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("tax_exemption_code", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.tax_exemption_code && validation.errors.tax_exemption_code ? (
                    <p className="custom-invalid-feedback">{validation.errors.tax_exemption_code}</p>
                  ) : null}
                </div>
              ) : null}
            </Col>
          </Row>
          {/* lots table */}
          {validation?.values?.type_of_product === "lot" && (
            <Card className="subcard-table-container table-light">
              {tableLoader ? <Loader /> : null}
              <DataTable
                className="mini-data-table"
                fixedHeader
                persistTableHead
                columns={validation?.values?.from_type === "auction" ? auctionColumns : contractItemsColumns}
                data={
                  validation?.values?.from_type === "auction" &&
                  validation?.values?.auction &&
                  validation?.values?.paddle
                    ? [...lotsData]
                    : validation?.values?.from_type === "contract"
                      ? [...contractItems]
                      : []
                }
                noDataComponent={
                  <NoRecords
                    message={
                      !(validation?.values?.auction && validation?.values?.paddle) && t("information.proformaLots")
                    }
                  />
                }
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: t("common.rowsPerPage"),
                  rangeSeparatorText: t("common.rangeSeparator"),
                }}
              />
            </Card>
          )}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            color="dark"
            btnTitle={t("common.cancel")}
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ProformaInvoiceDocumentLineModal);
