import React, { useEffect } from "react";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

import { withTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractItems, getContracts } from "../../../store/contracts/actions";
import CustomSearchInput from "../../common/CustomSearchInput";
import { generateContrastColor, getColumnDetail, getOnlyCustomerName } from "../../../helpers/utils";
import NoRecords from "../../common/NoRecords";
import DataTable from "react-data-table-component";
import Loader from "../../common/Loader";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import CustomTagChip from "../../common/CustomTagChip";
import { useParams } from "react-router-dom";

const AddContractModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: contractsData, total: totalContracts } = useSelector((state) => state?.Contracts);
  // loader
  const [tableLoader, setTableLoader] = useState(false);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [exclude, setExclude] = useState([]);
  const [contractsPayload, setContractsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: { contract_items__is_sold: false },
    exclude: { id__in: exclude },
  });

  // handle getting contracts
  const handleGetContracts = useCallback(
    (contractsPayload) => {
      setTableLoader(true);
      dispatch(getContracts(contractsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isModalOpen) {
      handleGetContracts(contractsPayload);
    }
  }, [handleGetContracts, isModalOpen, contractsPayload]);

  const handleSaveBtn = () => {
    onSave(isAllSelected, selectedRows, contractsPayload?.filter, exclude);
    handleModalClose();
  };

  const handleModalClose = () => {
    if (!loader) {
      setSelectedRows([]);
      setIsAllSelected(false);
      setContractsPayload({
        page: 1,
        limit: DEFAULT_ROW_PER_PAGE,
        filter: { contract_items__is_sold: false },
      });
      onCancel();
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleRowSelection = (row, event = () => {}) => {
    const checked = event?.target?.checked;
    if (checked && row?.unsold_item_count) {
      setSelectedRows((prevState) => [...prevState, row?.id]);
    } else {
      setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      setExclude((prevExclude) => [...prevExclude, row?.id]);
    }
  };

  // to get the list of auctions
  const getAuctionsColumn = (contract) => {
    return contract?.linkable_items?.map((auction) => auction?.auction_no)?.join(", ");
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              if (event.target.checked) {
                setSelectedRows(contractsData?.filter((row) => row.unsold_item_count)?.map((row) => row.id));
              } else {
                setSelectedRows([]);
              }
              setExclude([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={!row?.unsold_item_count}
          checked={Boolean(selectedRows?.find((item) => item === row?.id)) && row?.unsold_item_count}
          onChange={(event) => {
            handleRowSelection(row, event);
          }}
        />
      ),
      width: "60px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.contractNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"contract_no__icontains"}
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.contract_no ? row?.contract_no : "-"),
      width: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.customer")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"search_name__icontains"}
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.id ? getOnlyCustomerName(row?.customer) : "-"),
      width: "220px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.auctions")} ${t("common.no_")}`}</div>
        </div>
      ),
      selector: (row) => (row?.linkable_items?.length > 0 ? getAuctionsColumn(row) : "-"),
      width: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.total")} ${t("common.linkAbleItems")}`}</div>
        </div>
      ),
      selector: (row) => row?.unsold_item_count,
      width: "170px",
    },
  ];

  return (
    <Modal centered isOpen={isModalOpen} size="lg" toggle={handleModalClose} fade={false}>
      <ModalHeader className="lot-header w-100">
        <div className="d-flex w-100 flex-row justify-content-between">
          <span>
            {`${t("common.addTitle", {
              module: t("common.contract"),
            })}`}
          </span>
          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalContracts > 0) || selectedRows.length ? (
              <>
                <Button
                  color="info"
                  onClick={() => {
                    setSelectedRows([]);
                    setIsAllSelected(false);
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {`${t("common.deselect")}`}
                  <Badge color="dark" className="ms-1">
                    {selectedRows.length}
                  </Badge>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody
        className="subcard-table-container table-light"
        style={{ border: "0px", borderRadius: "5px", boxShadow: "2px" }}
      >
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            fixedHeader
            pointerOnHover
            className="data-table"
            persistTableHead
            columns={columns}
            data={contractsData}
            pagination
            paginationServer
            paginationDefaultPage={contractsPayload?.page}
            paginationPerPage={contractsPayload?.limit}
            paginationTotalRows={totalContracts}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            conditionalRowStyles={[
              {
                when: (row) => !row?.unsold_item_count,
                style: { backgroundColor: "lightgray" },
              },
            ]}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="dark"
          onClick={() => {
            handleModalClose();
          }}
          disabled={loader}
        >
          {t("common.cancel")}
        </Button>
        <Button
          color="primary"
          disabled={loader || (isAllSelected === false && selectedRows.length < 1) || totalContracts < 1}
          className="btn-load d-flex"
          onClick={handleSaveBtn}
        >
          {loader ? (
            <span className="d-flex align-items-center ">
              <Spinner size="xs" className="flex-shrink-0">
                {t("common.loading")}...
              </Spinner>
            </span>
          ) : null}
          <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{`${t("common.add")}`}</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(AddContractModal);
