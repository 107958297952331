import React from "react";
import { withTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import TabsComponent from "../../../components/common/TabsComponent";
import WebAuctionsContent from "../../../components/pages/website-management/auctions/WebAuctionsContent";

const WebAuctions = (props) => {
  document.title = `Veritas | ${props.t("sidebarMenu.auctions")}`;

  return (
    <div className="page-content layout-main-container">
      <Card className="container-body card-section">
        <CardHeader className="card-section-header">
          <span className="card-title">{props.t("common.auctions")}</span>
        </CardHeader>
        <TabsComponent
          tabData={[
            {
              label: props.t("common.nextAuctions"),
              Component: WebAuctionsContent,
              props: { type: "next" },
            },
            {
              label: props.t("common.closedAuctions"),
              Component: WebAuctionsContent,
              props: { type: "closed" },
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default withTranslation()(WebAuctions);
