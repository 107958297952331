import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_CURRENCY, SET_CURRENCIES_DATA, UPDATE_CURRENCY } from "./actionType";

export const getCurrencies = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/currencies`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CURRENCIES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add currency
export const addCurrency = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/currencies`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_CURRENCY,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update currency
export const updateCurrency = (currencyId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/currencies/${currencyId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_CURRENCY,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete currency
export const deleteCurrency =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/billings/currencies/${id}`, {
        data: { ...payload },
      }).then((res) => {
        if (res && res?.status && res?.status === 200) {
          return res;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
