import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Button, Card, Input } from "reactstrap";
import { addContracts, deleteLot, getContractsInLots, getLotLists } from "../../../store/auctions/lotActions";
import CustomAddButton from "../../common/CustomAddButton";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import CustomTextLink from "../../common/CustomTextLink";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import DeleteModal from "../../common/DeleteModal";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";
import AddContractModal from "./AddContractModal";
import { INITIAL_GET_LOTS_PAYLOAD } from "./Lots";
import { checkPermissionByRoles, getOnlyCustomerName } from "../../../helpers/utils";
import { ACCOUNTANT, ADMIN, DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import IssueSalesNoteModal from "./IssueSalesNoteModal";
import SendSalesNoteModal from "./SendSalesNoteModal";
import ChooseIssueSalesNoteModal from "./ChooseIssueSalesNoteModal";
import ChooseSendSalesNoteModal from "./ChooseSendSalesNoteModal";
import {
  bulkIssueSalesNotes as bulkIssueSalesNotesAction,
  bulkSendSalesNotes as bulkSendSalesNotesAction,
} from "../../../store/auctions/actions";
import ChooseNotificationTypeModal from "./ChooseNotificationTypeModal";
import NotifySellerModal from "./NotifySellerModal";
import ChooseSellersModal from "./ChooseSellersModal";
import { notifySellers } from "../../../store/auctions/actions";

const Contracts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const contractsData = useSelector((state) => state?.Auctions?.contracts?.data);
  const totalItems = useSelector((state) => state?.Auctions?.contracts?.total);
  const [bulkIssueSalesNoteModalOpen, setBulkIssueSalesNoteModalOpen] = useState(false);
  const [bulkSendSalesNoteModalOpen, setBulkSendSalesNoteModalOpen] = useState(false);
  const [chooseIssueSalesNoteModalOpen, setChooseIssueSalesNoteModalOpen] = useState(false);
  const [chooseSendSalesNoteModalOpen, setChooseSendSalesNoteModalOpen] = useState(false);
  const [bulkIssueSalesNoteLoader, setBulkIssueSalesNoteLoader] = useState(false);
  const [bulkSendSalesNoteLoader, setBulkSendSalesNoteLoader] = useState(false);

  const [contractsPayload, setContractsPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "contract_no",
    filter: {
      contract_items__item_status: "auction_lot",
      contract_items__auction_lots__auction: id,
      contract_items__auction_lots__is_delete: false,
    },
    auction_id: id,
  });
  const [lotsPayload, setLotsPayload] = useState({
    ...INITIAL_GET_LOTS_PAYLOAD,
    filter: { ...INITIAL_GET_LOTS_PAYLOAD?.filter, auction: id },
    exclude: { ...INITIAL_GET_LOTS_PAYLOAD?.filter },
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isChooseProNotificationTypeModalOpen, setIsChooseProNotificationTypeModalOpen] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [isNotifySellerModalOpen, setIsNotifySellerModalOpen] = useState(false);
  const [sendNotifySellerLoader, setNotifySellerLoader] = useState(false);
  const [isChooseSellerModalOpen, setIsChooseSellerModalOpen] = useState(false);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [exclude, setExclude] = useState([]);
  // navigate to customer edit page
  const navigateToCustomerEditPage = (row) => {
    if (row?.customer?.id && !row?.customer?.parent_id) history.push(`/customers/edit/${row?.customer?.id}`);
    else if (row?.customer?.parent_id)
      history.push(`/customers/${row?.customer?.parent_id}/edit-person/${row?.customer?.id}`);
  };

  // navigate to customer edit page
  const navigateToContractEditPage = (row) => {
    history.push(`/contracts/contracts/edit/${row?.id}`);
  };

  // handle getting lots item
  const handleContractListing = useCallback(
    (contractsPayload) => {
      setTableLoader(true);
      dispatch(getContractsInLots(contractsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        handleGetLotLists(lotsPayload);
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      handleContractListing(contractsPayload);
    }
  }, [handleContractListing, contractsPayload, id]);

  // handle getting lots item
  const handleGetLotLists = useCallback(
    (lotsPayload) => {
      dispatch(getLotLists(lotsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (props?.id) {
      handleGetLotLists(lotsPayload);
    }
  }, [handleGetLotLists, lotsPayload, props?.id]);

  //   handle delete modal open
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  //   handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const handleBulkIssueSalesNoteModalOpen = () => {
    setBulkIssueSalesNoteModalOpen(true);
  };
  const handleBulkIssueSalesNoteModalClose = () => {
    setBulkIssueSalesNoteModalOpen(false);
  };

  const handleBulkSendSalesNoteModalOpen = () => {
    setBulkSendSalesNoteModalOpen(true);
  };
  const handleBulkSendSalesNoteModalClose = () => {
    setBulkSendSalesNoteModalOpen(false);
  };

  const handleChooseIssueSalesNoteModalOpen = () => {
    setChooseIssueSalesNoteModalOpen(true);
  };
  const handleChooseIssueSalesNoteModalClose = () => {
    setChooseIssueSalesNoteModalOpen(false);
  };
  const handleChooseSendSalesNoteModalOpen = () => {
    setChooseSendSalesNoteModalOpen(true);
  };
  const handleChooseSendSalesNoteModalClose = () => {
    setChooseSendSalesNoteModalOpen(false);
  };
  const bulkIssueSalesNotes = (data) => {
    const payload = {
      auction_id: +id,
      contract_ids: data?.contract_ids || [],
      is_issue: true,
    };
    setBulkIssueSalesNoteLoader(true);
    dispatch(bulkIssueSalesNotesAction(payload)).then((res) => {
      setBulkIssueSalesNoteLoader(false);
      handleChooseIssueSalesNoteModalClose();
      handleBulkIssueSalesNoteModalClose();
    });
  };
  const bulkSendSalesNotes = (data) => {
    const payload = {
      auction_id: +id,
      contract_ids: data?.contract_ids || [],
    };
    setBulkSendSalesNoteLoader(true);
    dispatch(bulkSendSalesNotesAction(payload)).then((res) => {
      setBulkSendSalesNoteLoader(false);
      handleChooseSendSalesNoteModalClose();
      handleBulkSendSalesNoteModalClose();
    });
  };

  //   handle delete item
  const handleDeleteItem = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteLot({
          filter: contractsPayload?.filter,
          isSelectedAll: false,
          contract_ids: [selectedItem?.id],
          apiType: "contract",
          auctionId: id,
        })
      ).then((res) => {
        if (res) {
          handleContractListing(contractsPayload);
          setDeleteLoader(false);
          handleDeleteModalClose();
          setSelectedRows((prevState) => [...prevState.filter((item) => item !== selectedItem?.id)]);
        } else {
          //
          setDeleteLoader(false);
        }
      });
    }
  };

  //   handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  //   handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsBulkDeleteModalOpen(false);
  };

  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    const { contract_items__item_status, ...restFilter } = contractsPayload?.filter;
    setBulkDeleteLoader(true);
    dispatch(
      deleteLot({
        isSelectedAll: isAllSelected,
        contract_ids: selectedRows,
        filter: restFilter,
        apiType: "contract",
        auctionId: id,
        exclude: isAllSelected ? { id__in: exclude } : [],
      })
    ).then((res) => {
      if (res) {
        handleContractListing(contractsPayload);
        setBulkDeleteLoader(false);
        setIsAllSelected(false);
        handleBulkDeleteModalClose();
        setSelectedRows([]);
      } else {
        //
        setBulkDeleteLoader(false);
      }
    });
  };

  // handle page change
  const handlePageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleNotifySellerModalOpen = () => {
    setIsChooseProNotificationTypeModalOpen(true);
  };

  const handleChooseNotificationTypeModalClose = () => {
    setIsChooseProNotificationTypeModalOpen(false);
  };

  const handleNotificationTypeChange = (type) => {
    setNotificationType(type);
    handleChooseNotificationTypeModalClose();
    setIsNotifySellerModalOpen(true);
  };

  const handleNotifySellerModalClose = () => {
    setIsNotifySellerModalOpen(false);
  };

  const handleNotifySeller = (data) => {
    let payload = {
      auction_id: id,
      notification_type: notificationType,
    };

    if (data) {
      payload = {
        ...payload,
        seller_ids: data?.seller_ids,
      };
    } else {
      payload = {
        ...payload,
        seller_ids: [],
      };
    }

    setNotifySellerLoader(true);
    dispatch(notifySellers(payload)).then((res) => {
      setNotifySellerLoader(false);
      handleChooseSellersModalClose();
      handleNotifySellerModalClose();
    });
  };

  const handleChooseSellersModalOpen = () => {
    setIsChooseSellerModalOpen(true);
  };

  const handleChooseSellers = () => {
    handleChooseSellersModalOpen();
  };

  const handleChooseSellersModalClose = () => {
    setIsChooseSellerModalOpen(false);
  };

  const handleSaveContracts = (isAllSelected, selectedItems, itemFilter, exclude) => {
    if (id) {
      setSaveLoader(true);
      dispatch(
        addContracts({
          auction_id: id,
          contract_ids: selectedItems,
          is_selected_all: isAllSelected,
          filter: itemFilter,
          exclude: { id__in: exclude },
        })
      )
        .then((res) => {
          if (res) {
            handleContractListing(contractsPayload);
            handleAddModalClose();
          } else {
            //
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          handleAddModalClose();
          setSaveLoader(false);
        });
    }
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "68px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.contractNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_no__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_no ? (
          <CustomTextLink text={`${row?.contract_no}`} handleClick={() => navigateToContractEditPage(row)} />
        ) : (
          "-"
        ),
      width: "208px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.id ? (
          <CustomTextLink
            text={getOnlyCustomerName(row?.customer)}
            handleClick={() => navigateToCustomerEditPage(row)}
          />
        ) : (
          "-"
        ),
      width: "452px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.noOfLotOnAuction")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots_qty__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) => row?.auction_lots_qty,
      width: "452px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className="ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "208px",
      center: true,
    },
  ];

  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-2 gap-2">
        {(isAllSelected && totalItems > 0) || selectedRows.length ? (
          <Button
            color="info"
            onClick={() => {
              setSelectedRows([]);
              setIsAllSelected(false);
            }}
          >
            {props.t("common.deselect")}
            <Badge color="dark" className="ms-1">
              {selectedRows.length || totalItems}
            </Badge>
          </Button>
        ) : null}
        {checkPermissionByRoles([ADMIN, ACCOUNTANT]) ? (
          <>
            <CustomDropdownButton
              btnTitle={props.t("common.salesNote")}
              isLoading={false}
              id={"sales-note"}
              options={[
                {
                  label: `${props.t("common.issue")}`,
                  onClick: handleBulkIssueSalesNoteModalOpen,
                },
                {
                  label: `${props.t("common.sendV2")}`,
                  onClick: handleBulkSendSalesNoteModalOpen,
                },
              ]}
            />
            <CustomTooltipWrapper target={"sales-note"} tooltipBody={props.t("information.bulkSalesNoteButton")} />
          </>
        ) : null}
        <CustomButton
          color={"primary"}
          btnTitle={props.t("common.notifySellers")}
          onClick={handleNotifySellerModalOpen}
        />
        <CustomAddButton
          color={"success"}
          btnTitle={props.t("common.addTitle", {
            module: props.t("common.contract"),
          })}
          onClick={handleAddModalOpen}
        />
        <CustomButton
          isLoading={bulkDeleteLoader}
          color={"danger"}
          btnTitle={props.t("common.delete")}
          disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
          onClick={() => {
            handleBulkDeleteModalOpen();
          }}
        />
      </div>
      <Card className="subcard-table-container table-light">
        {tableLoader ? <Loader /> : null}
        <DataTable
          fixedHeader
          className="data-table"
          persistTableHead
          columns={columns}
          data={contractsData}
          pagination
          paginationServer
          paginationTotalRows={totalItems}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationPerPage={DEFAULT_ROW_PER_PAGE}
          paginationComponentOptions={{
            rowsPerPageText: props.t("common.rowsPerPage"),
            rangeSeparatorText: props.t("common.rangeSeparator"),
          }}
        />
      </Card>
      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={`${props.t("common.delete")} ${selectedRows.length || totalItems} ${props.t("common.contracts")}`}
        body={props.t("confirmation.deleteMessage", {
          module: `${props.t("common.contracts")}`,
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={bulkDeleteLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteItem}
        title={`${props.t("common.delete")} ${props.t("common.contract")}`}
        body={props.t("confirmation.contractDeleteMessage")}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />

      <AddContractModal
        isModalOpen={isAddModalOpen}
        onCancel={handleAddModalClose}
        onSave={handleSaveContracts}
        loader={saveLoader}
      />

      {bulkIssueSalesNoteModalOpen ? (
        <IssueSalesNoteModal
          isModalOpen={bulkIssueSalesNoteModalOpen}
          onCloseBtnHandler={handleBulkIssueSalesNoteModalClose}
          onIssueBtnHandler={bulkIssueSalesNotes}
          onChooseBtnHandler={handleChooseIssueSalesNoteModalOpen}
          loader={bulkIssueSalesNoteLoader}
        />
      ) : null}
      {bulkSendSalesNoteModalOpen ? (
        <SendSalesNoteModal
          isModalOpen={bulkSendSalesNoteModalOpen}
          onCloseBtnHandler={handleBulkSendSalesNoteModalClose}
          onSendBtnHandler={bulkSendSalesNotes}
          onChooseBtnHandler={handleChooseSendSalesNoteModalOpen}
          loader={bulkSendSalesNoteLoader}
        />
      ) : null}

      {chooseIssueSalesNoteModalOpen ? (
        <ChooseIssueSalesNoteModal
          isModalOpen={chooseIssueSalesNoteModalOpen}
          onSend={bulkIssueSalesNotes}
          onCancel={handleChooseIssueSalesNoteModalClose}
          loader={bulkIssueSalesNoteLoader}
        />
      ) : null}
      {chooseSendSalesNoteModalOpen ? (
        <ChooseSendSalesNoteModal
          isModalOpen={chooseSendSalesNoteModalOpen}
          onSend={bulkSendSalesNotes}
          onCancel={handleChooseSendSalesNoteModalClose}
          loader={bulkSendSalesNoteLoader}
        />
      ) : null}

      <ChooseNotificationTypeModal
        isModalOpen={isChooseProNotificationTypeModalOpen}
        onCancel={handleChooseNotificationTypeModalClose}
        handleNotificationTypeChange={handleNotificationTypeChange}
      />

      <NotifySellerModal
        loader={sendNotifySellerLoader}
        isModalOpen={isNotifySellerModalOpen}
        onCloseBtnHandler={handleNotifySellerModalClose}
        onSendBtnHandler={handleNotifySeller}
        onChooseBtnHandler={handleChooseSellers}
      />

      <ChooseSellersModal
        isModalOpen={isChooseSellerModalOpen}
        loader={sendNotifySellerLoader}
        onCancel={handleChooseSellersModalClose}
        onSend={handleNotifySeller}
        contracts={contractsData}
        auction_id={id}
      />
    </>
  );
};

export default withTranslation()(Contracts);
