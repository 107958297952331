import { combineReducers } from "redux";

// Front
import FAQs from "./FAQs/reducer";
import Artists from "./artists/reducer";
import Collaborators from "./auction-collaborators/reducer";
import AuctionTags from "./auction-tags/reducer";
import AuctionTheme from "./auction-theme/reducer";
import AuctionTypes from "./auction-types/reducer";
import Auctions from "./auctions/reducer";
import Categories from "./categories/reducer";
import common from "./common/reducer";
import Contracts from "./contracts/reducer";
import Currencies from "./currencies/reducer";
import Customers from "./customers/reducer";
import DocumentStatus from "./document-status/reducer";
import DocumentTypes from "./document-types/reducer";
import Files from "./files/reducer";
import GeneralUserSettings from "./general-user-settings/reducer";
import Invoicing from "./invoicing/reducer";
import ItemList from "./item-list/reducer";
import Languages from "./languages/reducer";
import Layout from "./layouts/reducer";
import Locations from "./locations/reducer";
import LotsTags from "./lots-tags/reducer";
import MyVeritas from "./my-veritas/reducer";
import Notes from "./notes/reducer";
import Payloads from "./payloads/reducers";
import PaymentConditions from "./payment-conditions/reducer";
import PaymentTypes from "./payment-types/reducer";
import Periods from "./periods/reducer";
import Permissions from "./permissoins/reducer";
import PreContracts from "./pre-contracts/reducer";
import ProductsAndServices from "./products-services/reducer";
import Report from "./report/reducer";
import Tasks from "./tasks/reducer";
import Templates from "./templates/reducer";
import Titles from "./titles/reducer";
import User from "./user/reducer";
import VAT from "./vat/reducer";
import Warehouse from "./warehouse/reducer";
import Pages from "./website-pages/reducer";

const rootReducer = combineReducers({
  // public
  Auctions,
  PreContracts,
  Contracts,
  Invoicing,
  User,
  ProductsAndServices,
  Currencies,
  PaymentTypes,
  PaymentConditions,
  DocumentTypes,
  DocumentStatus,
  VAT,
  Categories,
  Artists,
  Periods,
  Templates,
  LotsTags,
  Layout,
  Languages,
  Permissions,
  GeneralUserSettings,
  Report,
  Warehouse,
  common,
  Locations,
  AuctionTypes,
  AuctionTheme,
  AuctionTags,
  Collaborators,
  Customers,
  Tasks,
  Notes,
  ItemList,
  Files,
  MyVeritas,
  Pages,
  FAQs,
  Payloads,
  Titles,
});

export default rootReducer;
