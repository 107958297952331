import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { ADD_PAYMENTTYPE, DELETE_PAYMENTTYPE, SET_PAYMENTTYPES_DATA, UPDATE_PAYMENTTYPE } from "./actionType";

// to get the list of the payment types
export const getPaymentTypes = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/payment-types`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        sort: params?.sort,
        search: params?.search,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: SET_PAYMENTTYPES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add the payment type
export const addPaymentType = (payload) => async (dispatch) => {
  try {
    return API.post("/billings/payment-types", payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({ type: ADD_PAYMENTTYPE, payload: res?.data?.data });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to edit the payment type
export const updatePaymentType = (paymentTypeId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/payment-types/${paymentTypeId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: UPDATE_PAYMENTTYPE, payload: res?.data?.data });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {}
};

// to delete the payment type
export const deletePaymentType = (paymentTypeId) => async (dispatch) => {
  try {
    return API.delete(`/billings/payment-types/${paymentTypeId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({ type: DELETE_PAYMENTTYPE, payload: paymentTypeId });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
