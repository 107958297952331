import React, { useCallback, useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DeleteModal from "../../common/DeleteModal";
import CustomSearchInput from "../../common/CustomSearchInput";
import NoRecords from "../../common/NoRecords";
import { withTranslation } from "react-i18next";
import Loader from "../../common/Loader";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import { toast } from "react-toastify";
import CustomAddButton from "../../common/CustomAddButton";
import { deleteCustomer, getCustomerList } from "../../../store/customers/actions";
import { NEW_PERSONA_TYPE, SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import { capitalizeFirstCharacterInString, checkSelectAllSelected, getValuesFromArray } from "../../../helpers/utils";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { SET_PERSON_DETAILS } from "../../../store/customers/actionType";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";

const PersonList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paramId } = useParams();

  const {
    selectedCustomerData: customerData,
    data: personListData,
    total: totalPersons,
  } = useSelector((state) => state?.Customers);

  const [customersPayload, setCustomersPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "",
    filter: {
      parent_id: Number(paramId),
      search_name__icontains: "",
    },
  });

  const [selectedPerson, setSelectedPerson] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedPersonType, setSelectedPersonType] = useState("");
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // delete action handlers
  const handleDeleteModalOpen = (personData) => {
    setSelectedPerson(personData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedPerson(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeletePerson = () => {
    if (selectedPerson && selectedPerson?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteCustomer({
          id: selectedPerson?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetCustomer(customersPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get person list
  const handleGetCustomer = useCallback(
    (customersPayload) => {
      setTableLoader(true);
      dispatch(getCustomerList(customersPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCustomer(customersPayload);
  }, [customersPayload, handleGetCustomer]);

  const navigateOnPersonForm = () => {
    dispatch({
      type: SET_PERSON_DETAILS,
      payload: {},
    });
    history.push(`/customers/${paramId}/new-person`);
  };

  const navigateOnEditPersonForm = (personId) => {
    history.push(`/customers/${paramId}/edit-person/${personId}`);
  };

  // handle customer type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setCustomersPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setCustomersPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  const getColumnDetail = (conditionalColumn, personColumnName, companyColumnName) => {
    return conditionalColumn === "personal"
      ? personColumnName
      : conditionalColumn === "company"
      ? companyColumnName
      : "-";
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("customers.customer")} ${props.t("common.no_")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="customer_no__icontains"
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.customer_no,
      width: "214px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.customerType")}`}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...NEW_PERSONA_TYPE]}
            selectedOptions={selectedPersonType}
            handleOnSelection={(selectedItem) => {
              setSelectedPersonType(selectedItem);
              handleDropdownFilter(selectedItem, "customer_type__in", setSelectedPersonType);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer_type ? capitalizeFirstCharacterInString(props.t(`common.${row?.customer_type}`)) : "-",
      width: "214px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.customer_type, row?.name, row?.company_name),
      width: "500px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.billing.vat.VAT")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_vat__icontains"
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => getColumnDetail(row?.customer_type, row?.client_vat, row?.company_vat),
      width: "356px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "142px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("common.persons")}`;

  return (
    <>
      <div className="layout-main-container">
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
            <span className="card-title ">{`${props.t("common.persons")}`}</span>
            <div className="d-flex flex-row gap-2">
              <CustomAddButton
                color={"success"}
                disabled={!(paramId && customerData?.id)}
                onClick={navigateOnPersonForm}
                btnTitle={`${props.t("common.new")} ${props.t("common.person")}`}
              />
            </div>
          </CardHeader>
          {paramId ? (
            <DataTable
              fixedHeader
              className="data-table"
              persistTableHead
              columns={columns}
              data={[...personListData]}
              pagination
              paginationServer
              paginationTotalRows={totalPersons}
              noDataComponent={<NoRecords />}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              paginationPerPage={DEFAULT_ROW_PER_PAGE}
              paginationComponentOptions={{
                rowsPerPageText: props.t("common.rowsPerPage"),
                rangeSeparatorText: props.t("common.rangeSeparator"),
              }}
              pointerOnHover={true}
              onRowClicked={(row) => {
                navigateOnEditPersonForm(row?.id);
              }}
            />
          ) : null}
        </Card>
      </div>

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePerson}
        title={`${props.t("common.delete")} ${props.t("common.person")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.person"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(PersonList);
