import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";

export const addPosition = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/positions`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
