import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const AttentionModal = ({
  informativeModal,
  isModalOpen,
  title,
  body,
  onCloseBtnHandler,
  loader,
  onConfirmBtnHandler,
  cancelBtnTitle,
  actionBtnTitle,
  t,
  disable,
}) => {
  const handleModalClose = () => {
    if (!loader) onCloseBtnHandler();
  };

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
        <ModalHeader>{title || `${t("confirmation.attention")}`}</ModalHeader>
        <ModalBody>
          <div className="attention-body" style={{ display: "block" }}>
            <div>
              <p style={{ fontWeight: "bold", display: "inline" }}>
                {t("confirmation.changedInformationAlreadyPublised")}
              </p>{" "}
              <p style={{ display: "inline" }}>{t("confirmation.changeInformationOnWebsite")}</p>
            </div>
            <p style={{ marginTop: "15px", fontWeight: "bold" }}>{t("confirmation.AreYouSureChanges")}</p>
          </div>
          <p>{t("confirmation.confirmActions")}</p>
        </ModalBody>

        {!informativeModal ? (
          <ModalFooter>
            <Button color="dark" onClick={handleModalClose} disabled={loader}>
              {t("common.cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loader || disable}
              className="btn-load d-flex"
              onClick={onConfirmBtnHandler}
            >
              {loader ? (
                <span className="d-flex align-items-center ">
                  <Spinner size="xs" className="flex-shrink-0"></Spinner>
                </span>
              ) : null}

              <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{actionBtnTitle || t("common.yes")}</span>
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="primary" className="btn-load d-flex" onClick={onCloseBtnHandler}>
              <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.cancel")}</span>
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(AttentionModal);
