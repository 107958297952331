import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import * as Yup from "yup";
import {
  DEBIT_NOTE,
  DEBIT_NOTE_LOT,
  DEBOUNCE_TIME,
  DROPDOWN_DEFAULT_LIMIT,
  INVOICE_DOCUMENT,
  NORMAL_INVOICE,
  SALES_NOTE,
} from "../../../helpers/constants";
import {
  getFormattedDocumentLines,
  getReferenceDocumentLabel,
  scrollToFirstFormikError,
  roundTo,
} from "../../../helpers/utils";
import { SET_MODAL_DOCUMENTS } from "../../../store/invoicing/actionTypes";
import { getInvoices } from "../../../store/invoicing/actions";
import { getVATs } from "../../../store/vat/actions";
import CustomButton from "../../common/CustomButton";

const ReceiptDocumentLineModal = ({
  selectedItems,
  selectedDebitNotes,
  selectedCustomer,
  addDocumentLine,
  isModalOpen,
  handleModalClose,
  t,
}) => {
  const dispatch = useDispatch();

  const INVOICES_OPTIONS = useSelector((state) => state?.Invoicing?.modal?.data);

  const [saveLoader, setSaveLoader] = useState(false);
  const [invoiceSearchLoader, setInvoiceSearchLoader] = useState(false);
  const [invoicePayload, setInvoicePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    is_paid: false,
    filter: {
      is_settled: false,
      customer: selectedCustomer || undefined,
      document_type__description__in: [INVOICE_DOCUMENT, DEBIT_NOTE, NORMAL_INVOICE, DEBIT_NOTE_LOT, SALES_NOTE],
      is_cancel: false,
    },
    exclude: {},
  });

  const [vatSearchLoader, setVatSearchLoader] = useState(false);
  const [vatPayload, setVatPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "code",
  });

  useEffect(() => {
    // To reset the modal documents when the modal is closed
    return () => {
      dispatch({
        type: SET_MODAL_DOCUMENTS,
        payload: {
          data: [],
          total: 0,
        },
      });
    };
  }, []);

  // to handle the invoice search
  const handleInvoiceSearch = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_document__icontains: value },
    }));
  };
  // debounce for invoice search
  const invoiceSearchDebounce = useMemo(() => {
    return debounce(handleInvoiceSearch, DEBOUNCE_TIME);
  }, []);

  // handle vat search
  const handleVatSearch = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_code__icontains: value },
    }));
  };
  // debounce for vat search
  const vatSearchDebounce = useMemo(() => {
    return debounce(handleVatSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      invoiceSearchDebounce.cancel();
      vatSearchDebounce.cancel();
    };
  }, [invoiceSearchDebounce, vatSearchDebounce]);

  // to get the list of invoices
  useEffect(() => {
    if (isModalOpen && invoicePayload?.filter?.customer === selectedCustomer) {
      setInvoiceSearchLoader(true);
      dispatch(
        getInvoices({
          ...invoicePayload,
          exclude: { ...invoicePayload?.exclude, id__in: selectedDebitNotes },
        })
      ).then((res) => {
        dispatch({
          type: SET_MODAL_DOCUMENTS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        setInvoiceSearchLoader(false);
      });
    }
  }, [dispatch, invoicePayload, isModalOpen, selectedCustomer]);

  // to get the list of the vats
  useEffect(() => {
    if (isModalOpen) {
      setVatSearchLoader(true);
      dispatch(getVATs(vatPayload)).then(() => {
        setVatSearchLoader(false);
      });
    }
  }, [dispatch, vatPayload, isModalOpen]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      invoice: "",
    },
    validationSchema: Yup.object({
      invoice: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.invoice")}`),
    }),
    onSubmit: (values) => {
      handleAddReceiptDocumentLine(values);
    },
  });

  // handle add/edit document
  const handleAddReceiptDocumentLine = (values) => {
    let items;
    if ([NORMAL_INVOICE, INVOICE_DOCUMENT, SALES_NOTE].includes(values?.invoice?.document_type?.description)) {
      items = getFormattedDocumentLines([
        ...validation?.values?.invoice?.document_lines
          ?.map((row) => {
            return {
              ...row,
              invoice_item_id: row?.id,
              vat: validation?.values?.invoice?.dont_apply_vat ? null : row?.vat,
              premium: row?.premium,
              discount: row?.discount,
              discount_unit: row?.discount_unit,
            };
          })
          .filter((lineItem) => {
            if (selectedItems?.length > 0) {
              if (selectedItems?.includes(lineItem?.id)) return false;
            }
            return true;
          }),
      ])?.map((row) => ({
        ...row,
        invoice_id: values?.invoice?.id,
        total_to_settle: roundTo(parseFloat(row?.amount || 0) - parseFloat(row?.itemData?.paid_amount || 0)),
      }));
    } else {
      let amount = values?.invoice?.debit_total;
      let finalAmount = values?.invoice?.total;
      items = [
        {
          amount: finalAmount,
          type_of_product: "debit_note",
          quantity: 1,
          vat: values?.invoice?.vat?.id,
          invoice_id: values?.invoice?.id,
          total_to_settle: roundTo(parseFloat(finalAmount || 0) - parseFloat(values?.invoice?.paid_amount || 0)),
          itemData: {
            type_of_product: "debit_note",
            description: values?.invoice?.information_text,
            quantity: 1,
            amount: amount,
            vat: values?.invoice?.vat?.rate,
            paid_amount: values?.invoice?.paid_amount,
            final_amount: finalAmount,
          },
        },
      ];
    }
    const updatedItems = items.map((item) => {
      const newItemData = {
        ...item.itemData,
        documentType: values?.invoice?.document_type?.description,
        documentNo: values?.invoice?.document_no,
      };
      return { ...item, itemData: newItemData };
    });

    addDocumentLine(updatedItems);
  };

  // useEffect to filter the invoices by selected customer
  useEffect(() => {
    if (selectedCustomer && isModalOpen) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, customer: selectedCustomer },
      }));
    }
  }, [selectedCustomer, isModalOpen]);

  // handle reset form with all other maunual states
  const handleReset = () => {
    validation.resetForm();
  };
  // use effect to reset the modal form
  useEffect(() => {
    if (!isModalOpen) {
      handleReset();
    }
  }, [isModalOpen]);

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      // size="lg"
    >
      <ModalHeader>{t("common.addTitle", { module: t("common.documentLine") })}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <Row>
            {/* <Col sm={6} md={6} lg={6}> */}
            <Col>
              <div className="mb-3">
                <Label htmlFor="invoice" className="form-label">
                  {`${t("common.document")}*`}
                </Label>
                <Select
                  isLoading={invoiceSearchLoader}
                  name="invoice"
                  options={INVOICES_OPTIONS}
                  getOptionLabel={getReferenceDocumentLabel}
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${
                    validation.touched.invoice && validation.errors.invoice ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.document")}`}
                  value={validation.values.invoice || ""}
                  onInputChange={invoiceSearchDebounce}
                  onChange={(invoice) => {
                    validation.setFieldValue("invoice", invoice);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("invoice", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.invoice && validation.errors.invoice ? (
                  <p className="custom-invalid-feedback">{validation.errors.invoice}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            color="dark"
            btnTitle={t("common.cancel")}
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ReceiptDocumentLineModal);
