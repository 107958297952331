import {
  SET_AVAILABLE_CONTRACT_ITEMS,
  SET_CONTRACT,
  SET_CONTRACTS_LIST,
  SET_CONTRACT_ITEM,
  SET_CONTRACT_ITEMS,
  SET_SELLER_DOCUMENTS,
  SET_SOLD_CONTRACT_ITEMS,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
  contract: null,
  contractItems: [],
  totalItems: 0,
  contractItem: null,
  invoice: {
    availableLots: { data: [], total: 0 },
    lots: { data: [], total: 0 },
    documents: { data: [], total: 0 },
  },
};

const Contracts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACTS_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case SET_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case SET_CONTRACT_ITEMS:
      return {
        ...state,
        contractItems: action.payload.data,
        totalItems: action.payload.total,
        suggested_contract_no: action.payload.suggested_contract_no,
      };
    case SET_CONTRACT_ITEM:
      return {
        ...state,
        contractItem: action.payload,
      };

    case SET_AVAILABLE_CONTRACT_ITEMS:
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          availableLots: {
            ...state?.invoice?.availableLots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };

    case SET_SOLD_CONTRACT_ITEMS:
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          lots: {
            ...state?.invoice?.lots,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };

    case SET_SELLER_DOCUMENTS:
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          documents: {
            ...state?.invoice?.documents,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default Contracts;
