import React, { useCallback, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import { getContractOptionLabel } from "../../../helpers/utils";
import CustomButton from "../../common/CustomButton";
import { getContracts } from "../../../store/contracts/actions";
import debounce from "lodash.debounce";
import { useParams } from "react-router-dom";

const CloneItemModal = ({ isModalOpen, onCancel, onConfirm, t, loader }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const contracts = useSelector((state) => state?.Contracts?.data);

  const [contractSearchLoader, setContractSearchLoader] = useState(false);
  const [contractsPayload, setContractsPayload] = useState({
    search: "",
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    exclude: { id: id },
  });

  // handle get contracts
  const handleGetContracts = useCallback(
    (contractsPayload) => {
      setContractSearchLoader(true);
      dispatch(getContracts(contractsPayload))
        .then((res) => {
          setContractSearchLoader(false);
        })
        .catch((error) => {
          setContractSearchLoader(false);
        });
    },
    [dispatch]
  );

  // to handle the contract search
  const handleContractSearch = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_list__icontains: value },
    }));
  };

  // to memorize the debounce
  const contractSearchDebounce = useMemo(() => {
    return debounce(handleContractSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      contractSearchDebounce.cancel();
    };
  }, [contractSearchDebounce]);

  useEffect(() => {
    handleGetContracts(contractsPayload);
  }, [contractsPayload, handleGetContracts]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      contract: "",
    },
    validationSchema: Yup.object({
      contract: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.contract")}`),
    }),
    onSubmit: (values) => {
      onConfirm({ contract: values?.contract?.id || null });
    },
  });

  const handleModalClose = () => {
    if (!loader) {
      onCancel();
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
    }
  }, [isModalOpen]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${t("common.clone")} ${t("common.item")}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody className="d-flex flex-column justify-content-center ">
          <div className="mb-3">
            <Label htmlFor="contract" className="form-label">
              {t("common.contract")}
            </Label>
            <Select
              isLoading={contractSearchLoader}
              name="contract"
              options={contractSearchLoader ? [] : contracts}
              getOptionLabel={getContractOptionLabel}
              getOptionValue={(option) => option?.id}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.contract")}`}
              value={validation.values.contract || ""}
              onChange={(option) => {
                validation.setFieldValue("contract", option?.id ? option : "");
              }}
              onInputChange={contractSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("contract", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.contract && validation.errors.contract ? (
              <p className="custom-invalid-feedback">{validation.errors.contract}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton btnTitle={t("common.cancel")} type="button" onClick={handleModalClose} color="dark" />
          <CustomButton btnTitle={t("common.clone")} isLoading={loader} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(CloneItemModal);
