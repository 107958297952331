/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import CustomButton from "../../common/CustomButton";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getSpokenLanguages } from "../../../store/general-user-settings/action";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddCollaboratorModal = ({ collaborator, isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const { spokenLanguageList } = useSelector((state) => state.GeneralUserSettings);
  const [languagesSearchLoader, setLanguagesSearchLoader] = useState(false);
  const [languagesPayload, setLanguagesPayload] = useState({ is_spoken: true });

  //   to get the list of languages
  useEffect(() => {
    if (isModalOpen) {
      setLanguagesSearchLoader(true);
      dispatch(getSpokenLanguages(languagesPayload, "code")).then(() => {
        setLanguagesSearchLoader(false);
      });
    }
  }, [languagesPayload, isModalOpen]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      name: collaborator?.name || "",
      languages_speaks: collaborator?.languages_speaks?.length > 0 ? collaborator?.languages_speaks : [],
      iban_no: collaborator?.iban_no || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.name"),
        })
      ),
      languages_speaks: Yup.array().min(
        1,
        t("validation.generalField", {
          promptType: t("common.select"),
          fieldType: t("common.spokenLanguage"),
        })
      ),
      iban_no: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.iban"),
        })
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    if (!isModalOpen) validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${collaborator?.id ? t("common.edit") : t("common.add")}  ${t(
        "common.auctionCollaborator"
      )}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              {t("common.name")}*
            </Label>
            <Input
              name="name"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.name"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={validation.touched.name && validation.errors.name ? true : false}
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="languages_speaks" className="form-label">
              {t("common.spokenLanguages") + "*"}
            </Label>
            <Select
              isLoading={languagesSearchLoader}
              name="languages_speaks"
              isMulti
              options={languagesSearchLoader ? [] : spokenLanguageList}
              getOptionLabel={(option) => option?.code}
              getOptionValue={(option) => option?.id}
              className={`custom-select custom-multi-select ${
                validation.touched.languages_speaks && validation.errors.languages_speaks ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.spokenLanguages")}`}
              value={validation.values.languages_speaks || null}
              onChange={(languages_speaks) => {
                validation.setFieldValue("languages_speaks", languages_speaks);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("languages_speaks", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.languages_speaks && validation.errors.languages_speaks ? (
              <span className="custom-invalid-feedback">{validation.errors.languages_speaks}</span>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="iban_no" className="form-label">
              {t("common.iban")}*
            </Label>
            <Input
              name="iban_no"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.iban"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.iban_no || ""}
              invalid={validation.touched.iban_no && validation.errors.iban_no ? true : false}
            />
            {validation.touched.iban_no && validation.errors.iban_no ? (
              <FormFeedback type="invalid">{validation.errors.iban_no}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            btnTitle={t("common.cancel")}
            color="dark"
            onClick={handleModalClose}
            disabled={loader}
            type="button"
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={loader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddCollaboratorModal);
