import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddPaymentTypeModal from "../../../../components/pages/settings/AddPaymentTypeModal";
import {
  addPaymentType,
  deletePaymentType,
  getPaymentTypes,
  updatePaymentType,
} from "../../../../store/payment-types/actions";

const PaymentTypes = (props) => {
  const dispatch = useDispatch();
  const { data: paymentTypesData, total: totalPaymentTypes } = useSelector((state) => state?.PaymentTypes);

  const [paymentTypesPayload, setPaymentTypesPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: {
      id__icontains: "",
      code__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isPaymentTypeModalOpen, setIsPaymentTypeModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  // add or edit action handlers
  const handlePaymentTypeModalOpen = (paymentData) => {
    setSelectedPaymentType(paymentData);
    setIsPaymentTypeModalOpen(true);
  };
  const handlePaymentTypeModalClose = () => {
    setSelectedPaymentType(null);
    setIsPaymentTypeModalOpen(false);
  };
  const handleSavePaymentType = (values, formikValidation) => {
    // check for add or edit
    if (selectedPaymentType && selectedPaymentType?.id) {
      // edit payment type
      setSaveLoader(true);
      dispatch(updatePaymentType(selectedPaymentType?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handlePaymentTypeModalClose();
            formikValidation.resetForm();
            handleGetPaymentTypes(paymentTypesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add payment type
      setSaveLoader(true);
      dispatch(addPaymentType(values))
        .then((res) => {
          if (res) {
            // success handler
            handlePaymentTypeModalClose();
            formikValidation.resetForm();
            handleGetPaymentTypes(paymentTypesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (paymentData) => {
    setSelectedPaymentType(paymentData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedPaymentType(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeletePaymentType = () => {
    if (selectedPaymentType && selectedPaymentType?.id) {
      setDeleteLoader(true);
      dispatch(deletePaymentType(selectedPaymentType?.id))
        .then((res) => {
          if (res) {
            handleGetPaymentTypes(paymentTypesPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setPaymentTypesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPaymentTypesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get payment types
  const handleGetPaymentTypes = useCallback(
    (paymentTypesPayload) => {
      setTableLoader(true);
      dispatch(getPaymentTypes(paymentTypesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaymentTypes(paymentTypesPayload);
  }, [handleGetPaymentTypes, paymentTypesPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={paymentTypesPayload}
            setPayload={setPaymentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code__icontains"
            className="column-search-input"
            payload={paymentTypesPayload}
            setPayload={setPaymentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.code,
      minWidth: "250px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={paymentTypesPayload}
            setPayload={setPaymentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={paymentTypesPayload}
            setPayload={setPaymentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.description_en,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handlePaymentTypeModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.paymentTypes")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={paymentTypesPayload}
            setPayload={setPaymentTypesPayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handlePaymentTypeModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">{props.t("settings.billing.paymentTypes.addPaymentType")}</span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.paymentTypes")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...paymentTypesData]}
            pagination
            paginationServer
            paginationTotalRows={totalPaymentTypes}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddPaymentTypeModal
        paymentType={selectedPaymentType}
        isModalOpen={isPaymentTypeModalOpen}
        onCancel={handlePaymentTypeModalClose}
        onSave={handleSavePaymentType}
        loader={saveLoader}
      />
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePaymentType}
        title={`${props.t("common.delete")} ${props.t("settings.billing.paymentTypes.paymentType")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.paymentTypes.paymentType"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(PaymentTypes);
