import React from "react";

const CenteredTextDivider = ({ padding, width }) => {
  return (
    <div
      className="d-flex flex-row align-items-center gap-2 fs-16 centered-text-divider"
      style={{ padding: padding || "8px 0px" }}
    >
      <hr style={{ width: width || "45%" }} className="bg-primary" />
      <h5 className="text-primary mt-2">OR</h5>
      <hr style={{ width: width || "45%" }} className="bg-primary" />
    </div>
  );
};

export default CenteredTextDivider;
