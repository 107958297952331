import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_TITLES_DATA } from "../actionTypes";

export const getTitles = (params) => async (dispatch) => {
  try {
    return API.get(`/customers/title`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        sort: params?.sort,
        search: params?.search,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_TITLES_DATA,
          payload: res?.data?.data,
        });
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add title
export const addTitle = (payload) => async (dispatch) => {
  try {
    return API.post(`/customers/title`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update title
export const updateTitle = (titleID, payload) => async (dispatch) => {
  try {
    return API.put(`/customers/title/${titleID}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete title
export const deleteTitle = (titleID) => async (dispatch) => {
  try {
    return API.delete(`/customers/title/${titleID}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
