import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Card, Col, Label, Row } from "reactstrap";
import {
  AVAILABLE_FOR_AUCTION_LOT,
  AVAILABLE_LOT,
  DEBOUNCE_TIME,
  DEFAULT_ROW_PER_PAGE,
  DROPDOWN_DEFAULT_LIMIT,
  RESERVED_LOT,
} from "../../helpers/constants";
import { getColumnDetail, getOnlyCustomerName, openFileURL } from "../../helpers/utils";
import { exportAuctionSituation, getAuctionById, getAuctionList } from "../../store/auctions/actions";
import { exportContractSituation, getContractById } from "../../store/contracts/actions";
import { exportCustomerSituation, getCustomerDetails } from "../../store/customers/actions";
import AvailableLots from "../pages/customers/AvailableLots";
import PaidToSellerLots from "../pages/customers/PaidToSellerLots";
import ReturnedLots from "../pages/customers/ReturnedLots";
import SoldLots from "../pages/customers/SoldLots";
import CustomBreadcrumb from "./CustomBreadcrumb";
import CustomDropdownButton from "./CustomDropdownButton";

const Situation = (props) => {
  const dispatch = useDispatch();
  const { paramId, id, auctionId } = useParams();
  const history = useHistory();
  const customerData = useSelector((state) => state?.Customers?.selectedCustomerData);
  const contractData = useSelector((state) => state?.Contracts?.contract);
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const [auctions, setAuctions] = useState([]);

  const [exportSituationLoader, setExportSituationLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // available lots payload
  const [availableLotsPayload, setAvailableLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "position_contract",
    filter: {
      lots_tag__tag_label__in: [AVAILABLE_LOT, AVAILABLE_FOR_AUCTION_LOT, RESERVED_LOT],
    },
  });

  const [selectedAuction, setSelectedAuction] = useState(null);
  const [auctionSearchLoader, setAuctionSearchLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "id",
  });

  const [availableLotsPage, setAvailableLotsPage] = useState({ page: 1 });
  const [isAvailableLotsZero, setIsAvailableLotsZero] = useState(false);

  const handleExportSituation = (is_english) => {
    if (paramId || id || auctionId) {
      const payload = {
        auction_id: props?.type === "customer" && selectedAuction?.id ? selectedAuction?.id : undefined,
        is_english,
        ids: [],
        filter: {},
      };
      setExportSituationLoader(true);
      dispatch(
        props?.type === "customer"
          ? exportCustomerSituation(paramId, payload)
          : props?.type === "contract"
          ? exportContractSituation(id, payload)
          : exportAuctionSituation(auctionId, payload)
      ).then((res) => {
        setExportSituationLoader(false);
        openFileURL(res, "download");
      });
    }
  };

  // handle auction search
  const handleAuctionSearch = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("search_auction_en__icontains", "search_auction_pt__icontains")]: value,
      },
    }));
  };
  // debounce for auction search
  const auctionSearchDebounce = useMemo(() => {
    return debounce(handleAuctionSearch, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    auctionSearchDebounce.cancel();
  }, [auctionSearchDebounce]);

  // to get the list of the auctions
  useEffect(() => {
    setAuctionSearchLoader(true);
    dispatch(getAuctionList(auctionPayload, true)).then((res) => {
      if (res) {
        setAuctions(res?.results?.length > 0 ? res?.results : []);
      }
      setAuctionSearchLoader(false);
    });
  }, [dispatch, auctionPayload]);

  // useEffect to set customer filter
  useEffect(() => {
    if (paramId) {
      setAvailableLotsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, contract__customer: paramId },
      }));
    }
  }, [paramId]);

  // for contract filter
  useEffect(() => {
    if (id) {
      setAvailableLotsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, contract: id },
      }));
    }
  }, [id]);

  // for auction filter
  useEffect(() => {
    if (auctionId || selectedAuction?.id)
      setAvailableLotsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          auction_lots__auction: auctionId || selectedAuction?.id,
          auction_lots__is_delete: false,
        },
      }));
    else {
      setAvailableLotsPayload((prevState) => {
        const payload = { ...prevState };
        delete payload?.filter?.auction_lots__auction;
        delete payload?.filter?.auction_lots__is_delete;
        return payload;
      });
    }
  }, [auctionId, selectedAuction]);

  //   to get the customer detail by id
  useEffect(() => {
    if (paramId || contractData?.customer?.id) {
      dispatch(
        getCustomerDetails({
          customerId: paramId || contractData?.customer?.id,
        })
      ).then((res) => {
        if (!res) {
          history.push(`/customers`);
        }
      });
    }
  }, [dispatch, paramId, history, contractData?.customer?.id]);

  //   to get the contract detail by id
  useEffect(() => {
    if (id) {
      dispatch(getContractById(id)).then((res) => {
        if (!res) {
          history.push(`/contracts/contracts`);
        }
      });
    }
  }, [dispatch, id, history]);

  //   to get the auction detail by id
  useEffect(() => {
    if (auctionId) {
      dispatch(getAuctionById(auctionId)).then((res) => {
        if (!res) {
          history.push(`/auctions`);
        }
      });
    }
  }, [dispatch, auctionId, history]);

  document.title = `Veritas | ${props.t(
    props?.type === "customer"
      ? "sidebarMenu.customers"
      : props?.type === "contract"
      ? "sidebarMenu.contracts"
      : "sidebarMenu.auctions"
  )}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header contract-situation-header">
          <CustomBreadcrumb
            data={
              props?.type === "customer"
                ? [
                    {
                      label: props.t("sidebarMenu.customers"),
                      link: "/customers",
                    },
                    {
                      label: `${customerData?.customer_no} (${getOnlyCustomerName(customerData)})`,
                      link: `/customers/edit/${paramId}`,
                    },
                    {
                      label: props.t("common.customerSituation"),
                    },
                  ]
                : props?.type === "contract"
                ? [
                    {
                      label: props.t("sidebarMenu.contracts"),
                      link: "/contracts/contracts",
                    },
                    {
                      label: `${contractData?.contract_no} (${getOnlyCustomerName(contractData?.customer)})`,
                      link: `/contracts/contracts/edit/${id}`,
                    },
                    {
                      label: props.t("common.contractSituation"),
                    },
                  ]
                : [
                    {
                      label: props.t("sidebarMenu.auctions"),
                      link: "/auctions",
                    },
                    {
                      label: `${auctionData?.auction_no}`,
                      link: `/auctions/edit/${auctionId}`,
                    },
                    {
                      label: props.t("common.auctionSituation"),
                    },
                  ]
            }
          />
          <div className="contain-header-right d-flex flex-row gap-2">
            <CustomDropdownButton
              isLoading={exportSituationLoader}
              btnTitle={props.t("common.export")}
              options={[
                {
                  label: props.t("common.pdf", { lang: props.t("common.pt") }),
                  onClick: () => handleExportSituation(false),
                },
                {
                  label: props.t("common.pdf", { lang: props.t("common.en") }),
                  onClick: () => handleExportSituation(true),
                },
              ]}
            />
          </div>
        </div>
        {props?.type === "customer" ? (
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label fs-16">{`${props.t("common.auction")}`}</Label>
                <Select
                  isClearable
                  isLoading={auctionSearchLoader}
                  name="auction"
                  options={auctionSearchLoader ? [] : auctions}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) =>
                    option?.auction_no + " - " + (getColumnDetail(option?.title_en, option?.title_pt) || "")
                  }
                  className={`custom-select`}
                  placeholder={`${props.t("common.select")} ${props.t("common.auction")}`}
                  value={selectedAuction}
                  onInputChange={auctionSearchDebounce}
                  onChange={(auction) => setSelectedAuction(auction)}
                />
              </div>
            </Col>
          </Row>
        ) : null}
        <Card className="container-body card-section">
          {/* available lots */}
          <AvailableLots
            itemsPayload={availableLotsPayload}
            setItemsPayload={setAvailableLotsPayload}
            setIsAvailableLotsZero={setIsAvailableLotsZero}
            initialPage={availableLotsPage}
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            customerData={customerData}
          />
        </Card>
        <Card className="container-body card-section">
          {/* sold lots */}
          <SoldLots selectedAuction={selectedAuction} type={props?.type} />
        </Card>
        <Card className="container-body card-section">
          {/* returned lots */}
          <ReturnedLots selectedAuction={selectedAuction} type={props?.type} />
        </Card>
        <Card className="container-body card-section">
          {/* returned lots */}
          <PaidToSellerLots selectedAuction={selectedAuction} type={props?.type} />
        </Card>
      </div>
    </>
  );
};

export default withTranslation()(Situation);
