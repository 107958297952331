import { FormikProvider, useFormik } from "formik";
import i18next, { t } from "i18next";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import ActionButton from "../../../components/common/ActionButton";
import AttentionModal from "../../../components/common/AttentionModal";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import CustomDropDown from "../../../components/common/CustomDropDown";
import Loader from "../../../components/common/Loader";
import MessageBlock from "../../../components/common/MessageBlock";
import NumberInput from "../../../components/common/NumberInput";
import ExpensesForm from "../../../components/pages/contracts/ExpensesForm";
import AddArtistModal from "../../../components/pages/settings/AddArtistModal";
import AddCategoryModal from "../../../components/pages/settings/AddCategoryModal";
import AddPeriodModal from "../../../components/pages/settings/AddPeriodModal";
import { Option } from "../../../components/pages/settings/AddUserModal";
import {
  ADMIN,
  COMUNICATIONS,
  DEBOUNCE_TIME,
  DEFAULT_SELLER_PERMIUM,
  DROPDOWN_DEFAULT_LIMIT,
  EXTERNAL,
  GENERAL,
  MANUAL_LOTS_TAGS,
  RETURNED_LOT,
  SOLD_LOT,
  TEXTAREA_DEFAULT_ROWS,
} from "../../../helpers/constants";
import { METAL_OPTIONS, SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  equalsIgnoreCase,
  generateExpensesData,
  getColumnDetail,
  getDateInYYYYMMDDFormat,
  getLabelValueArray,
  getOnlyCustomerName,
  getTypeFromValue,
  getVATOptioins,
  getValuesFromArray,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import { addArtist, getArtists, updateArtist } from "../../../store/artists/actions";
import { getAuctionList } from "../../../store/auctions/actions";
import { addCategory, getCategories, updateCategory } from "../../../store/categories/actions";
import { SET_CONTRACT_ITEM } from "../../../store/contracts/actionTypes";
import {
  addContractItem,
  getContractById,
  getContractItemById,
  updateContractItem,
} from "../../../store/contracts/actions";
import { getTags } from "../../../store/lots-tags/actions";
import { addPeriod, getPeriods, updatePeriod } from "../../../store/periods/actions";
import { getVATs } from "../../../store/vat/actions";
import { getWarehouses } from "../../../store/warehouse/actions";
import { getDocumentTypes } from "../../../store/document-types/actions";

const documentTypesPayload = {
  page: 1,
  sort: "description",
};
const ContractItemForm = (props) => {
  const { id, itemId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const itemIdToCopy = location?.state?.itemIdToCopy;
  const latestPosition = location?.state?.lastPosition || 0;
  const [appraisalPhotos, setAppraisalPhotos] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [conditionReportPhotos, setConditionReportPhotos] = useState([]);
  const [defaultPhoto, setDefaultPhoto] = useState([]);
  const [selectedLotsTags, setSelectedLotsTags] = useState([]);
  const [defaultVAT, setDefaultVAT] = useState(null);
  const categories = useSelector((state) => state?.Categories?.data);
  const AUCTION_OPTIONS = useSelector((state) => state?.Auctions?.data);
  const TYPES_OF_DOCUMENTS = useSelector((state) => state?.DocumentTypes?.data);

  const LOTS_TAGS = getLabelValueArray(
    useSelector((state) => state?.LotsTags?.data),
    "id",
    getColumnDetail("tag_label", "tag_label_pt")
  );
  const CATEGORIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Categories?.data),
    "id",
    getColumnDetail("category_name_en", "category_name_pt")
  );
  const ARTISTS_OPTIONS = useSelector((state) => state?.Artists?.data);
  const PERIODS_OPTIONS = useSelector((state) => state?.Periods?.data);
  const vats = useSelector((state) => state?.VAT.data);
  const VAT_OPTIONS = getVATOptioins(
    useSelector((state) => state?.VAT.data),
    "id",
    "code",
    "rate",
    " - ",
    "%"
  );
  const { data: WAREHOUSE_OPTIONS } = useSelector((state) => state.Warehouse);
  const categoryOptions = useSelector((state) => state?.Categories?.data);
  const contractData = useSelector((state) => state?.Contracts?.contract);
  const contractItem = useSelector((state) => state?.Contracts?.contractItem);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [itemInfoLoader, setItemInfoLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [modalSaveLoader, setModalSaveLoader] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isArtistModalOpen, setIsArtistModalOpen] = useState(false);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [confirmSave, setConfirmSave] = useState(false);

  const [categoriesPayload, setCategoriesPayload] = useState({
    page: 1,
    sort: getColumnDetail("category_name_en", "category_name_pt"),
    filter:
      i18next.resolvedLanguage === "pt" ? { category_name_pt__icontains: "" } : { category_name_en__icontains: "" },
  });
  const [artistSearchLoader, setArtistSearchLoader] = useState(false);
  const [artistsPayload, setArtistsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "description",
    filter: {
      description__icontains: "",
    },
  });
  const [periodSearchLoader, setPeriodSearchLoader] = useState(false);
  const [periodsPayload, setPeriodsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: getColumnDetail("title_en", "title_pt"),
    filter: i18next.resolvedLanguage === "pt" ? { title_pt__icontains: "" } : { title_en__icontains: "" },
  });
  const [vatsPayload, setVatsPayload] = useState({
    page: 1,
    sort: "code",
    filter: {
      code__icontains: "",
    },
  });
  const [auctionSearchLoader, setAuctionSearchLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: {
      auction_start_date__gt: getDateInYYYYMMDDFormat(new Date()),
    },
  });
  const [warehouseSearchLoader, setWarehouseSearchLoader] = useState(false);
  const [warehousePayload, setWarehousePayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "description",
  });

  // to get the list of document types
  useEffect(() => {
    dispatch(getDocumentTypes(documentTypesPayload)).catch(console.error);
  }, [documentTypesPayload, dispatch]);

  // handle artist search
  const handleArtistSearchDebounce = (value) => {
    setArtistsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, description__icontains: value },
    }));
  };
  // debounce for artist search
  const artitstSearchDebounce = useMemo(() => {
    return debounce(handleArtistSearchDebounce, DEBOUNCE_TIME);
  }, []);

  // handle period search
  const handlePeriodSearchDebounce = (value) => {
    setPeriodsPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("title_en__icontains", "title_pt__icontains")]: value,
      },
    }));
  };
  // debounce for period search
  const periodSearchDebounce = useMemo(() => {
    return debounce(handlePeriodSearchDebounce, DEBOUNCE_TIME);
  }, []);

  // to handle the warehouse search
  const handleWarehouseSerch = (value) => {
    setWarehousePayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, description__icontains: value },
    }));
  };

  // to memorize the debounce
  const warehouseSearchDebounce = useMemo(() => {
    return debounce(handleWarehouseSerch, DEBOUNCE_TIME);
  }, []);

  // to handle the auction search
  const handleAuctionSearch = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("search_auction_en__icontains", "search_auction_pt__icontains")]: value,
      },
    }));
  };

  const handleSubmit = () => {
    if (contractItem?.auction_id?.is_publish && id && itemId && contractItem?.auction_id?.publish_auction) {
      setConfirmSave(true);
    } else {
      validation.handleSubmit();
    }
  };

  const handleCancel = () => {
    setConfirmSave(false);
  };

  const handleConfirm = () => {
    validation.handleSubmit();
  };

  // to memorize the debounce
  const auctionSearchDebounce = useMemo(() => {
    return debounce(handleAuctionSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      artitstSearchDebounce.cancel();
      auctionSearchDebounce.cancel();
      warehouseSearchDebounce.cancel();
      periodSearchDebounce.cancel();
    };
  }, [auctionSearchDebounce, artitstSearchDebounce, warehouseSearchDebounce, periodSearchDebounce]);

  //   to get the list of auctions
  useEffect(() => {
    setAuctionSearchLoader(true);
    dispatch(getAuctionList(auctionPayload)).then((res) => {
      setAuctionSearchLoader(false);
    });
  }, [auctionPayload, dispatch]);

  //   to get the list of warehouses
  useEffect(() => {
    setWarehouseSearchLoader(true);
    dispatch(getWarehouses(warehousePayload)).then((res) => {
      setWarehouseSearchLoader(false);
    });
  }, [warehousePayload, dispatch]);

  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key, setState) => {
    if (checkSelectAllSelected(selectedItem)) {
      setState([...CATEGORIES_OPTIONS]);
      validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value)
      );
    } else {
      if (selectedItem.length > 0) {
        setState(selectedItem);
        validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        setState([]);
        validation.setFieldValue(key, []);
      }
    }
  };

  // to set default vat option
  useEffect(() => {
    if (vats.length > 0) {
      const vat = vats?.find((vat) => vat?.rate === 23);
      setDefaultVAT(vat?.id || null);
    }
  }, [vats]);

  // useEffect to set default photo
  useEffect(() => {
    if (photos?.length > 0 && defaultPhoto?.length === 0 && !selectedIndex) {
      setDefaultPhoto([photos[0]]);
      setSelectedIndex(0);
    }
  }, [photos, defaultPhoto, selectedIndex]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      contract: id,
      position_contract: itemId && contractItem?.position_contract ? contractItem?.position_contract : latestPosition,
      categories:
        ((itemId || itemIdToCopy) && contractItem?.categories && contractItem?.categories?.map((item) => item?.id)) ||
        [],
      author: (itemId || itemIdToCopy) && contractItem?.author?.id ? contractItem?.author : "",
      period: (itemId || itemIdToCopy) && contractItem?.period?.id ? contractItem?.period : "",
      net_value: (itemId || itemIdToCopy) && contractItem?.net_value !== null ? contractItem?.net_value : "",
      reserve_price:
        (itemId || itemIdToCopy) && contractItem?.reserve_price !== null ? contractItem?.reserve_price : "",
      min_estimate: (itemId || itemIdToCopy) && contractItem?.min_estimate !== null ? contractItem?.min_estimate : 0,
      max_estimate: (itemId || itemIdToCopy) && contractItem?.max_estimate !== null ? contractItem?.max_estimate : 0,
      vat: (itemId || itemIdToCopy) && contractItem?.vat?.id ? contractItem?.vat?.id : defaultVAT || "",
      internal_observations:
        (itemId || itemIdToCopy) && contractItem?.internal_observations ? contractItem?.internal_observations : "",
      item_history: (itemId || itemIdToCopy) && contractItem?.item_history ? contractItem?.item_history : "",
      title_en: (itemId || itemIdToCopy) && contractItem?.title_en ? contractItem?.title_en : "",
      description_en: (itemId || itemIdToCopy) && contractItem?.description_en ? contractItem?.description_en : "",
      dimensions_en:
        (itemId || itemIdToCopy) && contractItem?.dimensions_en !== null ? contractItem?.dimensions_en : "",
      weight_en: (itemId || itemIdToCopy) && contractItem?.weight_en !== null ? contractItem?.weight_en : "",
      literature_en: (itemId || itemIdToCopy) && contractItem?.literature_en ? contractItem?.literature_en : "",
      provenance_en: (itemId || itemIdToCopy) && contractItem?.provenance_en ? contractItem?.provenance_en : "",
      exhibitions_en: (itemId || itemIdToCopy) && contractItem?.exhibitions_en ? contractItem?.exhibitions_en : "",
      notes_en: (itemId || itemIdToCopy) && contractItem?.notes_en ? contractItem?.notes_en : "",
      text_en: (itemId || itemIdToCopy) && contractItem?.text_en ? contractItem?.text_en : "",
      condition_report_en:
        (itemId || itemIdToCopy) && contractItem?.condition_report_en ? contractItem?.condition_report_en : "",
      title_pt: (itemId || itemIdToCopy) && contractItem?.title_pt ? contractItem?.title_pt : "",
      description_pt: (itemId || itemIdToCopy) && contractItem?.description_pt ? contractItem?.description_pt : "",
      dimensions_pt: (itemId || itemIdToCopy) && contractItem?.dimensions_pt ? contractItem?.dimensions_pt : "",
      weight_pt: (itemId || itemIdToCopy) && contractItem?.weight_pt ? contractItem?.weight_pt : "",
      literature_pt: (itemId || itemIdToCopy) && contractItem?.literature_pt ? contractItem?.literature_pt : "",
      provenance_pt: (itemId || itemIdToCopy) && contractItem?.provenance_pt ? contractItem?.provenance_pt : "",
      exhibitions_pt: (itemId || itemIdToCopy) && contractItem?.exhibitions_pt ? contractItem?.exhibitions_pt : "",
      notes_pt: (itemId || itemIdToCopy) && contractItem?.notes_pt ? contractItem?.notes_pt : "",
      text_pt: (itemId || itemIdToCopy) && contractItem?.text_pt ? contractItem?.text_pt : "",
      condition_report_pt:
        (itemId || itemIdToCopy) && contractItem?.condition_report_pt ? contractItem?.condition_report_pt : "",

      dimensions_catalog:
        (itemId || itemIdToCopy) && contractItem?.dimensions_catalog !== null ? contractItem?.dimensions_catalog : "",
      weight_catalog:
        (itemId || itemIdToCopy) && contractItem?.weight_catalog !== null ? contractItem?.weight_catalog : "",
      price_on_request:
        (itemId || itemIdToCopy) && contractItem?.price_on_request ? contractItem?.price_on_request : false,
      value_to_be_agreed:
        (itemId || itemIdToCopy) && contractItem?.value_to_be_agreed ? contractItem?.value_to_be_agreed : false,
      cites: (itemId || itemIdToCopy) && contractItem?.cites ? contractItem?.cites : false,
      general_psp_map:
        (itemId || itemIdToCopy) && contractItem?.general_psp_map ? contractItem?.general_psp_map : false,
      jewelry_psp_map:
        (itemId || itemIdToCopy) && contractItem?.jewelry_psp_map ? contractItem?.jewelry_psp_map : false,
      asae_map: (itemId || itemIdToCopy) && contractItem?.asae_map ? contractItem?.asae_map : false,
      described: (itemId || itemIdToCopy) && contractItem?.described ? contractItem?.described : false,
      photographed: (itemId || itemIdToCopy) && contractItem?.photographed ? contractItem?.photographed : false,
      translated: (itemId || itemIdToCopy) && contractItem?.translated ? contractItem?.translated : false,
      highlighted: (itemId || itemIdToCopy) && contractItem?.highlighted ? contractItem?.highlighted : false,
      estimate_for_catalog:
        (itemId || itemIdToCopy) &&
        contractItem?.min_estimate >= 0 &&
        contractItem?.max_estimate &&
        contractItem?.max_estimate > contractItem?.min_estimate
          ? `${contractItem?.min_estimate} - ${contractItem?.max_estimate} €`
          : "",
      premium:
        (itemId || itemIdToCopy) && contractItem?.premium
          ? contractItem?.premium
          : contractData?.premium || DEFAULT_SELLER_PERMIUM,
      hammer_price:
        (itemId || itemIdToCopy) && contractItem?.hammer_price !== 0 && contractItem?.hammer_price !== null
          ? contractItem?.hammer_price
          : "",
      published_value:
        itemId || itemIdToCopy
          ? contractItem?.published_value !== 0 && contractItem?.published_value !== null
            ? contractItem?.published_value
            : contractItem?.hammer_price !== 0 && contractItem?.hammer_price !== null
              ? contractItem?.hammer_price
              : ""
          : "",
      expenses:
        (itemId || itemIdToCopy) && contractItem?.expenses.length ? generateExpensesData(contractItem?.expenses) : [],
      auction_id: itemId && contractItem?.auction_id?.id ? contractItem?.auction_id : null,
      warehouse: itemId && contractItem?.warehouse?.id ? contractItem?.warehouse : "",
      item_location: itemId && contractItem?.item_location ? contractItem?.item_location : "",
      metal: itemId && contractItem?.metal ? contractItem?.metal : "",
      renegotiation: itemId && contractItem?.renegotiation ? contractItem?.renegotiation : "",
    },
    validationSchema: Yup.object({
      position_contract: Yup.number()
        .integer(
          props.t("validation.formatGeneral", {
            enterOrSelect: props?.t("common.enter"),
            field: props?.t("common.position"),
          })
        )
        .min(1, props.t("validation.numberMin", { totalNumber: 1 }))
        .required(
          props.t("validation.generalField", {
            promptType: props?.t("common.enter"),
            fieldType: props?.t("common.position"),
          })
        ),
      categories: Yup.array()
        .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.category")}`)
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.category")}`),
      reserve_price: Yup.number().min(0, props.t("validation.numberMin", { totalNumber: 0 })),
      net_value: Yup.number().min(0, props.t("validation.numberMin", { totalNumber: 0 })),
      min_estimate: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .test(
          "max_estimate",
          props.t("validation.numberMaxStrict", {
            totalNumber: props.t("common.maximumEstimate"),
          }),
          (value) => {
            if (validation?.values?.max_estimate) {
              return (validation?.values?.min_estimate || 0) < (validation?.values?.max_estimate || 0);
            }
            return true;
          }
        )
        .test(
          "max_estimate",
          `${props.t("common.please")}  ${props.t("common.enter")} ${props.t("common.minimumEstimate")} `,
          (value) => {
            if (validation?.values?.min_estimate >= 0 && validation?.values?.min_estimate !== "") {
              return true;
            }
            return (validation?.values?.max_estimate || 0) <= 0;
          }
        ),
      max_estimate: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .test(
          "min_estimate",
          props.t("validation.numberMinStrict", {
            totalNumber: props.t("common.minimumEstimate"),
          }),
          (value) => {
            if (validation?.values?.min_estimate) {
              return (validation?.values?.min_estimate || 0) < (validation?.values?.max_estimate || 0);
            }
            return true;
          }
        ),
      title_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.title")}`
      ),
      // .min(
      //   0,
      //   props.t("validation.numberMin", { totalNumber: 0 })
      // ),
      premium: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.premium")}`),
      hammer_price: Yup.number().min(0, props.t("validation.numberMin", { totalNumber: 0 })),
      published_value: Yup.number().min(0, props.t("validation.numberMin", { totalNumber: 0 })),
      expenses: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(
            `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.description")}`
          ),
          value: Yup.number()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.enter"),
                field: props.t("common.value"),
              })
            )
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.value")}`),
          currency_id: Yup.number().required(
            `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.currency")}`
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        author: values?.author?.id || "",
        period: values?.period?.id || "",
        default_photo_index: selectedIndex || 0,
        appraisal_photos: appraisalPhotos,
        photos,
        condition_report_photos: conditionReportPhotos,
        lots_tag: selectedLotsTags,
        auction_id: values?.auction_id?.id || null,
        warehouse: values?.warehouse?.id || "",
      };
      if (!payload?.auction_id) {
        delete payload?.auction_id;
      }
      if (itemId) {
        setSaveLoader(true);
        dispatch(updateContractItem(itemId, payload)).then((res) => {
          if (res) {
            navigateOnContract();
          }
          setSaveLoader(false);
        });
      } else {
        setSaveLoader(true);
        dispatch(addContractItem(payload)).then((res) => {
          if (res) {
            navigateOnContract();
          } else {
            validation.setFieldValue("position_contract", latestPosition);
          }
          setSaveLoader(false);
        });
      }
      setConfirmSave(false);
    },
  });

  const setDropdown = useCallback(() => {
    if (itemId || itemIdToCopy) {
      setSelectedCategories(
        getLabelValueArray(
          categoryOptions.filter((list) => validation?.values?.categories?.some((item) => item === list?.id)),
          "id",
          i18next.resolvedLanguage === "pt" ? "category_name_pt" : "category_name_en"
        )
      );
    } else {
      setSelectedCategories([]);
    }
  }, [categoryOptions, itemId, itemIdToCopy, validation?.values?.categories]);
  // to set all state of form
  useEffect(() => {
    if (itemId || itemIdToCopy) {
      setDropdown();
    }
  }, [itemId, itemIdToCopy, setDropdown]);

  // handle category modal actions
  const handleCategoryModalOpen = (data) => {
    setSelectedItem(data);
    setIsCategoryModalOpen(true);
  };
  const handleCategoryModalClose = () => {
    setSelectedItem(null);
    setIsCategoryModalOpen(false);
  };
  const handleCategorySave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit category
      setModalSaveLoader(true);
      dispatch(updateCategory(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleCategoryModalClose();
            formikValidation.resetForm();
            setSelectedCategories((prevState) => [
              {
                label: getColumnDetail(res?.category_name_en, res?.category_name_pt),
                value: prevState[0]?.value,
              },
            ]);
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add category
      setModalSaveLoader(true);
      dispatch(addCategory(values))
        .then((res) => {
          if (res) {
            // success handler
            validation?.setFieldValue("categories", [res?.id, ...validation?.values?.categories]);
            handleCategoryModalClose();
            formikValidation.resetForm();
            setSelectedCategories((prevState) => [
              {
                label: getColumnDetail(res?.category_name_en, res?.category_name_pt),
                value: res?.id,
              },
              ...prevState,
            ]);
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };

  // handle artist modal actions
  const handleArtistModalOpen = (data) => {
    setSelectedItem(data);
    setIsArtistModalOpen(true);
  };
  const handleArtistModalClose = () => {
    setSelectedItem(null);
    setIsArtistModalOpen(false);
  };

  const handleArtistSave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit artist
      setModalSaveLoader(true);
      dispatch(updateArtist(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            validation.setFieldValue("author", res);
            handleArtistModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add artist
      setModalSaveLoader(true);
      dispatch(addArtist(values))
        .then((res) => {
          if (res) {
            // success handler
            validation?.setFieldValue("author", res);
            handleArtistModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };

  // handle period modal actions
  const handlePeriodModalOpen = (data) => {
    setSelectedItem(data);
    setIsPeriodModalOpen(true);
  };
  const handlePeriodModalClose = () => {
    setSelectedItem(null);
    setIsPeriodModalOpen(false);
  };
  const handlePeriodSave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit period
      setModalSaveLoader(true);
      dispatch(updatePeriod(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            validation?.setFieldValue("period", res);
            handlePeriodModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add period
      setModalSaveLoader(true);
      dispatch(addPeriod(values))
        .then((res) => {
          if (res) {
            // success handler
            validation?.setFieldValue("period", res);
            handlePeriodModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };
  // handle confirmation open
  const handleConfirmationModalOpen = (event, tag) => {
    setIsConfirmationModalOpen(true);
    setSelectedTag({ event, tag });
  };
  // handle confirmation close
  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    setSelectedTag(null);
  };
  // handle confirm on confimation modal
  const handleConfirmOnTagChange = () => {
    handleTagChange(selectedTag?.event, selectedTag?.tag);
    handleConfirmationModalClose();
  };
  // to handle change in the lots-->tag switches
  const handleTagChange = (event, tag) => {
    let tempArray = selectedLotsTags;
    if (!event.target.checked) {
      tempArray.push(tag?.value);
    } else {
      tempArray = tempArray?.filter((item) => item !== tag?.value);
    }
    setSelectedLotsTags([...tempArray]);
  };

  const imageUrlComparison = (highResolutionURl, lowResolutionURL) => {
    const url1 = highResolutionURl?.split(".").slice(0, -1).join(".") + "__lowres";
    const url2 = lowResolutionURL?.split(".").slice(0, -1).join(".");
    if (url1 && url2) {
      return url1 === url2;
    } else {
      return false;
    }
  };

  const setPrefillStates = useCallback((contractItem, itemId, itemIdToCopy) => {
    if (contractItem?.default_photo && itemId) {
      setDefaultPhoto([contractItem?.default_photo]);
      setSelectedIndex(contractItem?.photos.findIndex((x) => imageUrlComparison(x, contractItem?.default_photo)));
    } else {
      setDefaultPhoto([]);
      setSelectedIndex();
    }
    if (contractItem?.appraisal_photos.length > 0 && itemId) {
      setAppraisalPhotos(contractItem?.appraisal_photos);
    } else {
      setAppraisalPhotos([]);
    }
    if (contractItem?.photos.length > 0 && itemId) {
      setPhotos(contractItem?.photos);
    } else {
      setPhotos([]);
    }
    if (contractItem?.condition_report_photos.length > 0 && itemId) {
      setConditionReportPhotos(contractItem?.condition_report_photos);
    } else {
      setConditionReportPhotos([]);
    }
    if (contractItem?.lots_tag.length > 0 && itemId) {
      setSelectedLotsTags(contractItem?.lots_tag);
    } else {
      setSelectedLotsTags([]);
    }
  }, []);

  //   handle reset item info form
  const handleResetItemInfoForm = () => {
    if (
      // typeof contractItem?.defaultPhoto !== typeof defaultPhoto?.[0] ||
      (contractItem?.default_photo
        ? JSON.stringify(contractItem?.default_photo) !== JSON.stringify(defaultPhoto?.[0])
        : defaultPhoto.length === 1) ||
      (contractItem?.appraisal_photos?.length || 0) !== appraisalPhotos?.length ||
      (contractItem?.photos?.length || 0) !== photos?.length ||
      (contractItem?.condition_report_photos?.length || 0) !== conditionReportPhotos?.length ||
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        setPrefillStates(contractItem, itemId);
        setDropdown();
      }
    }
  };

  // get item history content
  const getItemHistoryContent = (itemHistory) => {
    if (itemHistory.action === "change_auction") {
      return props.t("itemHistory.changeAuction", {
        old: itemHistory?.metadata?.old_auction_no,
        new: itemHistory?.metadata?.new_auction_no,
      });
    } else if (itemHistory.action === "convert_lot") {
      return props.t("itemHistory.convertLot", {
        auction: itemHistory?.metadata?.auction_no,
      });
    } else if (itemHistory.action === "change_contract") {
      return props.t("itemHistory.changeContract", {
        old: itemHistory?.metadata?.old_contract_no,
        new: itemHistory?.metadata?.new_contract_no,
      });
    } else if (itemHistory.action === "create_document") {
      const document =
        TYPES_OF_DOCUMENTS.find((type) => type?.description === itemHistory?.metadata?.document_type) || {};
      return props.t("itemHistory.documentCreated", {
        document_no: itemHistory?.metadata?.document_no,
        document_type:
          getColumnDetail(document.description_en, document.description) || itemHistory?.metadata?.document_type,
      });
    } else if (itemHistory.action === "delete_document") {
      return props.t("itemHistory.documentCancelled", {
        document_no: itemHistory?.metadata?.document_no,
        document_type: itemHistory?.metadata?.document_type,
      });
    }
  };

  // navigate on contract item
  const navigateOnContract = () => {
    history.push(`/contracts/contracts/edit/${id}`);
  };

  // to pre-fill estimate for catalog price
  useEffect(() => {
    if (
      validation?.values?.min_estimate >= 0 &&
      validation?.values?.min_estimate !== "" &&
      validation?.values?.max_estimate &&
      validation?.values?.max_estimate > validation?.values?.min_estimate
    ) {
      validation?.setFieldValue(
        "estimate_for_catalog",
        `${validation?.values?.min_estimate} - ${validation?.values?.max_estimate} €`
      );
    } else {
      validation?.setFieldValue("estimate_for_catalog", "");
    }
  }, [validation?.values?.min_estimate, validation?.values?.max_estimate]);

  // to pre-filled the appraisal photos
  useEffect(() => {
    setPrefillStates(contractItem, itemId, itemIdToCopy);
  }, [itemId, contractItem, setPrefillStates, itemIdToCopy]);

  // to get the lots--> tags for the TAGS
  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);

  // to get the list of lots categories
  useEffect(() => {
    dispatch(getCategories(categoriesPayload));
  }, [dispatch, categoriesPayload]);

  // to get the list of lots artists
  useEffect(() => {
    setArtistSearchLoader(true);
    dispatch(getArtists(artistsPayload)).then(() => {
      setArtistSearchLoader(false);
    });
  }, [dispatch, artistsPayload]);

  // to get the list of lots periods
  useEffect(() => {
    setPeriodSearchLoader(true);
    dispatch(getPeriods(periodsPayload)).then(() => {
      setPeriodSearchLoader(false);
    });
  }, [dispatch, periodsPayload]);

  // to get the list of the vats
  useEffect(() => {
    dispatch(getVATs(vatsPayload));
  }, [dispatch, vatsPayload]);

  //   to get the contract data
  useEffect(() => {
    if (id) {
      dispatch(getContractById(id)).then((res) => {
        if (!res) {
          history.push(`/contracts/contracts`);
        }
      });
    }
  }, [dispatch, id, history]);

  const handleGetContractItemById = useCallback(
    (itemId) => {
      if (itemId) {
        setItemInfoLoader(true);
        dispatch(getContractItemById(itemId)).then((res) => {
          if (!res) {
            history.push(`/contracts/contracts`);
          }
          setItemInfoLoader(false);
        });
      }
    },
    [dispatch, history]
  );

  // to get the contract item data
  useEffect(() => {
    if (itemId) {
      handleGetContractItemById(itemId);
    }
  }, [handleGetContractItemById, itemId]);

  // to get the contract item data for pre-filling for copy action
  useEffect(() => {
    if (itemIdToCopy) {
      handleGetContractItemById(itemIdToCopy);
    }
  }, [handleGetContractItemById, itemIdToCopy]);

  // to toggle photographed switch
  useEffect(() => {
    if (photos?.length > 0) {
      validation.setFieldValue("photographed", true);
    } else {
      validation.setFieldValue("photographed", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  // to reset the contract item state
  useEffect(() => {
    return () => {
      dispatch({ type: SET_CONTRACT_ITEM, payload: null });
    };
  }, [dispatch]);

  document.title = `Veritas | ${props.t("sidebarMenu.contracts")}`;

  return (
    <>
      <Prompt
        when={
          (!validation?.isSubmitting &&
            ((contractItem?.default_photo
              ? JSON.stringify(contractItem?.default_photo) !== JSON.stringify(defaultPhoto?.[0])
              : defaultPhoto.length === 1) ||
              (contractItem?.appraisal_photos?.length || 0) !== appraisalPhotos?.length ||
              (contractItem?.photos?.length || 0) !== photos?.length ||
              (contractItem?.condition_report_photos?.length || 0) !== conditionReportPhotos?.length)) ||
          (!validation?.isSubmitting &&
            JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <FormikProvider value={validation}>
        <div className="page-content layout-main-container">
          <div className="container-header">
            <CustomBreadcrumb
              data={[
                {
                  label: props.t("sidebarMenu.contracts"),
                  link: "/contracts/contracts",
                },
                {
                  label: contractData?.customer
                    ? `${contractData?.contract_no} (${getOnlyCustomerName(contractData?.customer)})`
                    : contractData?.contract_no,
                  link: `/contracts/contracts/edit/${id}`,
                },
                {
                  label: props.t(`${itemId ? "common.editTitle" : "common.addTitle"}`, {
                    module: `${props.t("contracts.contracts.contract")} ${props.t("common.item")}`,
                  }),
                },
              ]}
            />
          </div>
          <Card className="container-body card-section">
            {/* item info form */}
            {itemInfoLoader ? <Loader /> : null}
            <Form
              onKeyPress={onKeyPressForm}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                return false;
              }}
            >
              <CardHeader className="card-section-header">
                <span className="card-title">{`${props.t("common.item")} ${props.t("common.info")}`}</span>
                <div className="d-flex flex-row justify-content-end gap-2">
                  <CustomButton
                    color="dark"
                    btnTitle={props.t("common.reset")}
                    disabled={saveLoader}
                    onClick={handleResetItemInfoForm}
                  />
                  <CustomButton
                    color="primary"
                    disabled={saveLoader}
                    btnTitle={props.t("common.save")}
                    type="submit"
                    isLoading={saveLoader}
                    onClick={() => scrollToFirstFormikError(validation.errors)}
                  />
                </div>
              </CardHeader>
              <CardBody className="card-section-body">
                <Row>
                  <Col sm={6} md={6} lg={4}>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="contract_no" className="form-label">
                          {props.t("common.positionInContract")}
                        </Label>
                        <InputGroup>
                          <InputGroupText>{contractData?.contract_no + "/"}</InputGroupText>
                          <NumberInput
                            name="position_contract"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.position"),
                            })}
                            type="number"
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.position_contract}
                            invalid={
                              validation.touched.position_contract && validation.errors.position_contract ? true : false
                            }
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.position_contract && validation.errors.position_contract ? (
                            <FormFeedback type="invalid">{validation.errors.position_contract}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="author" className="form-label">
                          {props.t("common.artist")}
                        </Label>
                        <div className="d-flex flex-row gap-1">
                          <Select
                            isClearable
                            isLoading={artistSearchLoader}
                            name="author"
                            options={artistSearchLoader ? [] : ARTISTS_OPTIONS}
                            getOptionLabel={(option) => option?.description}
                            getOptionValue={(option) => option?.id}
                            className={`custom-select w-100 ${
                              validation.touched.author && validation.errors.author ? "select-error" : ""
                            }`}
                            placeholder={props.t("placeholder.selectField", {
                              fieldType: props.t("common.artist"),
                            })}
                            value={validation.values.author || ""}
                            onInputChange={artitstSearchDebounce}
                            onChange={(author) => {
                              if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])) {
                                validation.setFieldValue("author", author?.id ? author : "");
                              }
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("author", true);
                              validation.handleBlur(e);
                            }}
                            isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                            <>
                              <ActionButton
                                iconClasses="ri-add-circle-fill fs-18"
                                onClick={() => {
                                  handleArtistModalOpen();
                                }}
                                tooltip={props.t("common.addTitle", {
                                  module: props.t("common.artist"),
                                })}
                                buttonId={"artist-add"}
                              />
                              <ActionButton
                                disabled={!validation?.values?.author}
                                iconClasses="ri-pencil-fill fs-18"
                                onClick={() => {
                                  handleArtistModalOpen(validation?.values?.author);
                                }}
                                tooltip={props.t("common.editTitle", {
                                  module: props.t("common.artist"),
                                })}
                                buttonId={"artist-edit"}
                              />
                            </>
                          )}
                        </div>
                        {validation.touched.author && validation.errors.author ? (
                          <span className="custom-invalid-feedback">{validation.errors.author}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  {/* <Col sm={4} md={4} lg={4}>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="defaultPhoto" className="form-label">
                          {`${props.t("common.mainPhoto")}`}
                        </Label>
                        <CustomDropDown
                          grid={12}
                          setFiles={setDefaultPhoto}
                          files={defaultPhoto}
                          multiple={false}
                          classes="dropzone-height-170"
                        />
                      </div>
                    </Col>
                  </Col> */}
                </Row>
                <Row></Row>

                {/* form fields */}
                <Row>
                  <Col sm={12} md={12} lg={8}>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="title_pt" className="form-label fw-bold">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.title")} ${props.t("common.pt")}`
                              : `${props.t("common.title")}`}
                            {" *"}
                          </Label>
                          <Input
                            name="title_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.title"),
                            })}
                            type="text"
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.title_pt}
                            invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.title_pt && validation.errors.title_pt ? (
                            <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="title_en" className="form-label fw-bold">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.title")} `
                              : `${props.t("common.title")} ${props.t("common.en")}`}
                          </Label>
                          <Input
                            name="title_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.title"),
                            })}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title_en}
                            invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
                          />
                          {validation.touched.title_en && validation.errors.title_en ? (
                            <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="description_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.description")} ${props.t("common.pt")}`
                              : `${props.t("common.description")}`}
                          </Label>
                          <textarea
                            name="description_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.description"),
                            })}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.description_pt}
                            invalid={
                              validation.touched.description_pt && validation.errors.description_pt ? true : false
                            }
                            rows={TEXTAREA_DEFAULT_ROWS}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.description_pt && validation.errors.description_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.description_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="description_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.description")} `
                              : `${props.t("common.description")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="description_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.description"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description_en}
                            invalid={
                              validation.touched.description_en && validation.errors.description_en ? true : false
                            }
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.description_en && validation.errors.description_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.description_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                      <Row>
                        <Col className="col-sm-12 col-md-12 col-xl-4">
                          <div className="mb-3">
                            <Label htmlFor="category" className="form-label">
                              {props.t("common.category") + "*"}
                            </Label>
                            <div className="d-flex flex-row gap-1">
                              <Select
                                name="categories"
                                options={[SELECT_ALL_OPTION, ...CATEGORIES_OPTIONS]}
                                className={`w-100`}
                                placeholder={props.t("placeholder.selectField", {
                                  fieldType: props.t("common.category"),
                                })}
                                value={selectedCategories || []}
                                onChange={(category) => {
                                  handleMultiSelectDropdown(
                                    category,
                                    CATEGORIES_OPTIONS,
                                    "categories",
                                    setSelectedCategories
                                  );
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("categories", true);
                                  validation.handleBlur(e);
                                }}
                                components={{ Option }}
                                isMulti={true}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                              />
                              <ActionButton
                                // disabled={true}
                                iconClasses="ri-add-circle-fill fs-18"
                                onClick={() => {
                                  handleCategoryModalOpen();
                                }}
                                tooltip={props.t("common.addTitle", {
                                  module: props.t("common.category"),
                                })}
                                buttonId={"category-add"}
                              />
                              <ActionButton
                                // disabled={true}
                                disabled={validation?.values?.categories?.length !== 1}
                                iconClasses="ri-pencil-fill fs-18"
                                onClick={() => {
                                  handleCategoryModalOpen(
                                    categories?.find((category) => validation?.values?.categories[0] === category?.id)
                                  );
                                }}
                                tooltip={props.t("common.editTitle", {
                                  module: props.t("common.category"),
                                })}
                                buttonId={"category-edit"}
                              />
                            </div>
                            {validation.touched.categories && validation.errors.categories ? (
                              <span className="custom-invalid-feedback">{validation.errors.categories}</span>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-sm-12 col-md-12 col-xl-4">
                          <div className="mb-3">
                            <Label htmlFor="period" className="form-label">
                              {props.t("common.period")}
                            </Label>
                            <div className="d-flex flex-row gap-1">
                              <Select
                                isClearable
                                isLoading={periodSearchLoader}
                                name="period"
                                options={periodSearchLoader ? [] : PERIODS_OPTIONS}
                                getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
                                getOptionValue={(option) => option?.id}
                                className={`custom-select w-100 ${
                                  validation.touched.period && validation.errors.period ? "select-error" : ""
                                }`}
                                placeholder={props.t("placeholder.selectField", {
                                  fieldType: props.t("common.period"),
                                })}
                                value={validation.values.period || ""}
                                onInputChange={periodSearchDebounce}
                                onChange={(period) => {
                                  validation.setFieldValue("period", period?.id ? period : "");
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("period", true);
                                  validation.handleBlur(e);
                                }}
                              />
                              <ActionButton
                                iconClasses="ri-add-circle-fill fs-18"
                                onClick={() => {
                                  handlePeriodModalOpen();
                                }}
                                tooltip={props.t("common.addTitle", {
                                  module: props.t("common.period"),
                                })}
                                buttonId={"period-add"}
                              />
                              <ActionButton
                                disabled={!validation?.values?.period}
                                iconClasses="ri-pencil-fill fs-18"
                                onClick={() => {
                                  handlePeriodModalOpen(validation?.values?.period);
                                }}
                                tooltip={props.t("common.editTitle", {
                                  module: props.t("common.period"),
                                })}
                                buttonId={"period-edit"}
                              />
                            </div>

                            {validation.touched.period && validation.errors.period ? (
                              <span className="custom-invalid-feedback">{validation.errors.period}</span>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-sm-12 col-md-12 col-xl-4">
                          <div className="mb-3">
                            <Label htmlFor="metal" className="form-label">
                              {props.t("common.metal")}
                            </Label>
                            <div className="d-flex flex-row gap-1">
                              <Select
                                isClearable
                                name="metal"
                                options={METAL_OPTIONS}
                                className={`custom-select w-100 ${
                                  validation.touched.metal && validation.errors.metal ? "select-error" : ""
                                }`}
                                placeholder={props.t("placeholder.selectField", {
                                  fieldType: props.t("common.metal"),
                                })}
                                value={getTypeFromValue(METAL_OPTIONS, validation.values.metal) || ""}
                                onChange={(metal) => {
                                  validation.setFieldValue("metal", metal?.value ? metal?.value : "");
                                  // if (metal?.value && metal?.value !== "none") {
                                  //   validation.setFieldValue("jewelry_psp_map", true);
                                  //   validation.setFieldValue("asae_map", true);
                                  // } else {
                                  //   validation.setFieldValue(
                                  //     "jewelry_psp_map",
                                  //     (itemId || itemIdToCopy) && contractItem?.jewelry_psp_map
                                  //       ? contractItem?.jewelry_psp_map
                                  //       : false
                                  //   );
                                  //   validation.setFieldValue(
                                  //     "asae_map",
                                  //     (itemId || itemIdToCopy) && contractItem?.asae_map
                                  //       ? contractItem?.asae_map
                                  //       : false
                                  //   );
                                  // }
                                }}
                                onBlur={(e) => {
                                  validation.setFieldTouched("metal", true);
                                  validation.handleBlur(e);
                                }}
                              />
                            </div>

                            {validation.touched.metal && validation.errors.metal ? (
                              <span className="custom-invalid-feedback">{validation.errors.metal}</span>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <hr className="opacity-50" />
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="dimensions_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.dimensions")} ${props.t("common.pt")}`
                              : `${props.t("common.dimensions")}`}
                          </Label>
                          <textarea
                            name="dimensions_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.dimensions"),
                            })}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.dimensions_pt}
                            invalid={validation.touched.dimensions_pt && validation.errors.dimensions_pt ? true : false}
                            rows={2}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.dimensions_pt && validation.errors.dimensions_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.dimensions_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="dimensions_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.dimensions")} `
                              : `${props.t("common.dimensions")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="dimensions_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.dimensions"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dimensions_en}
                            invalid={validation.touched.dimensions_en && validation.errors.dimensions_en ? true : false}
                            rows={2}
                          />
                          {validation.touched.dimensions_en && validation.errors.dimensions_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.dimensions_en}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="dimensions_catalog" className="form-label">
                            {`${props.t("common.dimensionsForCatalogue")}`}
                          </Label>
                          <textarea
                            name="dimensions_catalog"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.dimensionsForCatalogue"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dimensions_catalog}
                            invalid={
                              validation.touched.dimensions_catalog && validation.errors.dimensions_catalog
                                ? true
                                : false
                            }
                            rows={2}
                          />
                          {validation.touched.dimensions_catalog && validation.errors.dimensions_catalog ? (
                            <span className="custom-invalid-feedback">{validation.errors.dimensions_catalog}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="weight_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.weight")} ${props.t("common.pt")}`
                              : `${props.t("common.weight")}`}
                          </Label>
                          <textarea
                            name="weight_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.weight"),
                            })}
                            row={2}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.weight_pt}
                            invalid={validation.touched.weight_pt && validation.errors.weight_pt ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.weight_pt && validation.errors.weight_pt ? (
                            <span type="invalid">{validation.errors.weight_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="weight_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.weight")} `
                              : `${props.t("common.weight")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="weight_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.weight"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight_en}
                            invalid={validation.touched.weight_en && validation.errors.weight_en ? true : false}
                          />
                          {validation.touched.weight_en && validation.errors.weight_en ? (
                            <span type="invalid">{validation.errors.weight_en}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="weight_catalog" className="form-label">
                            {`${props.t("common.weightForCatalogue")}`}
                          </Label>
                          <textarea
                            name="weight_catalog"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.weightForCatalogue"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight_catalog}
                            invalid={
                              validation.touched.weight_catalog && validation.errors.weight_catalog ? true : false
                            }
                          />
                          {validation.touched.weight_catalog && validation.errors.weight_catalog ? (
                            <span type="invalid">{validation.errors.weight_catalog}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr className="opacity-50" />
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="literature_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.literature")} ${props.t("common.pt")}`
                              : `${props.t("common.literature")}`}
                          </Label>
                          <textarea
                            name="literature_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.literature"),
                            })}
                            row={2}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.literature_pt}
                            invalid={validation.touched.literature_pt && validation.errors.literature_pt ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.literature_pt && validation.errors.literature_pt ? (
                            <span type="invalid">{validation.errors.literature_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="literature_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.literature")} `
                              : `${props.t("common.literature")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="literature_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.literature"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.literature_en}
                            invalid={validation.touched.literature_en && validation.errors.literature_en ? true : false}
                          />
                          {validation.touched.literature_en && validation.errors.literature_en ? (
                            <span type="invalid">{validation.errors.literature_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="provenance_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.provenance")} ${props.t("common.pt")}`
                              : `${props.t("common.provenance")}`}
                          </Label>
                          <textarea
                            name="provenance_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.provenance"),
                            })}
                            row={2}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.provenance_pt}
                            invalid={validation.touched.provenance_pt && validation.errors.provenance_pt ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.provenance_pt && validation.errors.provenance_pt ? (
                            <span type="invalid">{validation.errors.provenance_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="provenance_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.provenance")} `
                              : `${props.t("common.provenance")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="provenance_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.provenance"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.provenance_en}
                            invalid={validation.touched.provenance_en && validation.errors.provenance_en ? true : false}
                          />
                          {validation.touched.provenance_en && validation.errors.provenance_en ? (
                            <span type="invalid">{validation.errors.provenance_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="exhibitions_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.exhibition")} ${props.t("common.pt")}`
                              : `${props.t("common.exhibition")}`}
                          </Label>
                          <textarea
                            name="exhibitions_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.exhibitions"),
                            })}
                            row={2}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.exhibitions_pt}
                            invalid={
                              validation.touched.exhibitions_pt && validation.errors.exhibitions_pt ? true : false
                            }
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.exhibitions_pt && validation.errors.exhibitions_pt ? (
                            <span type="invalid">{validation.errors.exhibitions_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="exhibitions_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.exhibition")} `
                              : `${props.t("common.exhibition")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="exhibitions_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.exhibitions"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.exhibitions_en}
                            invalid={
                              validation.touched.exhibitions_en && validation.errors.exhibitions_en ? true : false
                            }
                          />
                          {validation.touched.exhibitions_en && validation.errors.exhibitions_en ? (
                            <span type="invalid">{validation.errors.exhibitions_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr className="opacity-50" />
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="notes_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.notes")} ${props.t("common.pt")}`
                              : `${props.t("common.notes")}`}
                          </Label>
                          <textarea
                            name="notes_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.notes"),
                            })}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.notes_pt}
                            invalid={validation.touched.notes_pt && validation.errors.notes_pt ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.notes_pt && validation.errors.notes_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.notes_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="notes_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.notes")} `
                              : `${props.t("common.notes")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="notes_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.notes"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes_en}
                            invalid={validation.touched.notes_en && validation.errors.notes_en ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.notes_en && validation.errors.notes_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.notes_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="text_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.text")} ${props.t("common.pt")} `
                              : `${props.t("common.text")} `}
                          </Label>
                          <textarea
                            name="text_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.text"),
                            })}
                            onChange={
                              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) &&
                              validation.handleChange
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.text_pt}
                            invalid={validation.touched.text_pt && validation.errors.text_pt ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                          />
                          {validation.touched.text_pt && validation.errors.text_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.text_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="text_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.text")} `
                              : `${props.t("common.text")} ${props.t("common.en")} `}
                          </Label>
                          <textarea
                            name="text_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.text"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.text_en}
                            invalid={validation.touched.text_en && validation.errors.text_en ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.text_en && validation.errors.text_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.text_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="internal_observations" className="form-label">
                              {props.t("common.internalObservations")}
                            </Label>
                            <textarea
                              name="internal_observations"
                              className="form-control"
                              placeholder={props.t("placeholder.enterField", {
                                fieldType: props.t("common.internalObservations"),
                              })}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.internal_observations}
                              invalid={
                                validation.touched.internal_observations && validation.errors.internal_observations
                                  ? true
                                  : false
                              }
                              rows={TEXTAREA_DEFAULT_ROWS}
                            />
                            {validation.touched.internal_observations && validation.errors.internal_observations ? (
                              <span className="custom-invalid-feedback">{validation.errors.internal_observations}</span>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                      <>
                        <hr className="opacity-50" />
                        <Row>
                          <Col>
                            {" "}
                            <div className="mb-3">
                              <Label htmlFor="condition_report_pt" className="form-label">
                                {i18next.resolvedLanguage === "en"
                                  ? `${props.t("common.conditionReport")} ${props.t("common.pt")}`
                                  : `${props.t("common.conditionReport")}`}
                              </Label>
                              <textarea
                                name="condition_report_pt"
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: props.t("common.conditionReport"),
                                })}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.condition_report_pt}
                                invalid={
                                  validation.touched.condition_report_pt && validation.errors.condition_report_pt
                                    ? true
                                    : false
                                }
                                rows={TEXTAREA_DEFAULT_ROWS}
                              />
                              {validation.touched.condition_report_pt && validation.errors.condition_report_pt ? (
                                <span className="custom-invalid-feedback">{validation.errors.condition_report_pt}</span>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="condition_report_en" className="form-label">
                                {i18next.resolvedLanguage === "en"
                                  ? `${props.t("common.conditionReport")}`
                                  : `${props.t("common.conditionReport")}  ${props.t("common.en")}`}
                              </Label>
                              <textarea
                                name="condition_report_en"
                                className="form-control en-field-bg"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: props.t("common.conditionReport"),
                                })}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.condition_report_en}
                                invalid={
                                  validation.touched.condition_report_en && validation.errors.condition_report_en
                                    ? true
                                    : false
                                }
                                rows={TEXTAREA_DEFAULT_ROWS}
                              />
                              {validation.touched.condition_report_en && validation.errors.condition_report_en ? (
                                <span className="custom-invalid-feedback">{validation.errors.condition_report_en}</span>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="cr_we_transfer_link" className="form-label">
                                {props.t("common.crWeTransferLink")}
                              </Label>
                              <Input
                                name="cr_we_transfer_link"
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: props.t("common.crWeTransferLink"),
                                })}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cr_we_transfer_link}
                                invalid={
                                  validation.touched.cr_we_transfer_link && validation.errors.cr_we_transfer_link
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.cr_we_transfer_link && validation.errors.cr_we_transfer_link ? (
                                <FormFeedback type="invalid">{validation.errors.cr_we_transfer_link}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
                      <>
                        <hr className="opacity-50" />
                        <Row>
                          <Col>
                            <Row>
                              <div className="mb-3">
                                <Label htmlFor="item_history" className="form-label">
                                  {props.t("common.itemHistory")}
                                </Label>
                                <div className="details-border-box">
                                  {contractItem?.item_histories?.length > 0 ? (
                                    contractItem?.item_histories?.map((itemHistory, index) => (
                                      <div key={index}>
                                        <div className="p-1 px-2 fs-13">{getItemHistoryContent(itemHistory)}</div>
                                        {index !== contractItem?.item_histories?.length - 1 && <hr />}
                                      </div>
                                    ))
                                  ) : (
                                    <MessageBlock
                                      fontSize={"12px"}
                                      height={"124px"}
                                      message={props.t("itemHistory.noHistory")}
                                    />
                                  )}
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3">
                                <Label htmlFor="warehouse" className="form-label">
                                  {props.t("common.warehouse")}
                                </Label>
                                <div className="d-flex flex-row gap-1">
                                  <Select
                                    isClearable
                                    isLoading={warehouseSearchLoader}
                                    name="warehouse"
                                    options={warehouseSearchLoader ? [] : WAREHOUSE_OPTIONS}
                                    getOptionLabel={(option) => option?.description}
                                    getOptionValue={(option) => option?.id}
                                    className={`custom-select w-100 ${
                                      validation.touched.warehouse && validation.errors.warehouse ? "select-error" : ""
                                    }`}
                                    placeholder={props.t("placeholder.selectField", {
                                      fieldType: props.t("common.warehouse"),
                                    })}
                                    value={validation.values.warehouse || ""}
                                    onInputChange={warehouseSearchDebounce}
                                    onChange={(warehouse) => {
                                      validation.setFieldValue("warehouse", warehouse?.id ? warehouse : "");
                                    }}
                                    onBlur={(e) => {
                                      validation.setFieldTouched("warehouse", true);
                                      validation.handleBlur(e);
                                    }}
                                  />
                                </div>

                                {validation.touched.warehouse && validation.errors.warehouse ? (
                                  <span className="custom-invalid-feedback">{validation.errors.warehouse}</span>
                                ) : null}
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3">
                                <Label htmlFor="item_location" className="form-label">
                                  {`${props.t("common.locationInWarehouse")}`}
                                </Label>
                                <Input
                                  name="item_location"
                                  className="form-control"
                                  placeholder={props.t("placeholder.enterField", {
                                    fieldType: props.t("common.locationInWarehouse"),
                                  })}
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.item_location}
                                  invalid={
                                    validation.touched.item_location && validation.errors.item_location ? true : false
                                  }
                                />
                                {validation.touched.item_location && validation.errors.item_location ? (
                                  <FormFeedback type="invalid">{validation.errors.item_location}</FormFeedback>
                                ) : null}
                              </div>
                            </Row>
                          </Col>
                          <Col>
                            {/* lots tags switches */}
                            {LOTS_TAGS?.length > 0 ? (
                              <Row>
                                {LOTS_TAGS?.map((tag, index) => (
                                  <Col sm={6} md={6} lg={6} key={index}>
                                    <div className="mb-3">
                                      <Label htmlFor={tag?.label} className="form-label">
                                        {tag?.label}
                                      </Label>
                                      <div className="form-check form-switch">
                                        <input
                                          disabled={
                                            itemIdToCopy
                                              ? !MANUAL_LOTS_TAGS.includes(tag?.label)
                                              : !MANUAL_LOTS_TAGS.includes(tag?.label) ||
                                                (MANUAL_LOTS_TAGS.includes(tag?.label) &&
                                                  contractItem?.lot_tags_dict?.find(
                                                    (tag) =>
                                                      equalsIgnoreCase(tag?.tag_label, SOLD_LOT) ||
                                                      equalsIgnoreCase(tag?.tag_label, RETURNED_LOT)
                                                  ))
                                          }
                                          type="checkbox"
                                          role="switch"
                                          className="form-check-input"
                                          onChange={(event) => {
                                            handleConfirmationModalOpen(event, tag);
                                          }}
                                          checked={selectedLotsTags?.includes(tag?.value)}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ) : null}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>

                  {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                    <Col sm={12} md={12} lg={4}>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="described" className="form-label">
                              {`${props.t("common.described")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="described"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.described}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="photographed" className="form-label">
                              {`${props.t("common.photographed")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="photographed"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.photographed}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="translated" className="form-label">
                              {`${props.t("common.translated")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="translated"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.translated}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="highlighted" className="form-label">
                              {`${props.t("common.highlighted")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="highlighted"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.highlighted}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr className="opacity-50" />
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="cites" className="form-label">
                              {`${props.t("common.CITES")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="cites"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.cites}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="general_psp_map" className="form-label">
                              {`${props.t("common.generalPJMap")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="general_psp_map"
                                disabled={true}
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.general_psp_map}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="jewelry_psp_map" className="form-label">
                              {`${props.t("common.jewelryPJMap")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="jewelry_psp_map"
                                disabled={true}
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.jewelry_psp_map}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="asae_map" className="form-label">
                              {`${props.t("common.ASAEMap")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="asae_map"
                                disabled={true}
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.asae_map}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr className="opacity-50" />
                      {/* link to auction */}
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="auction_id" className="form-label">
                            {props.t("common.linkToAuction")}
                          </Label>
                          <Select
                            isDisabled={contractItem?.is_sold}
                            isClearable
                            isLoading={auctionSearchLoader}
                            name="auction_id"
                            options={auctionSearchLoader ? [] : AUCTION_OPTIONS}
                            getOptionLabel={(option) =>
                              `${option?.auction_no} - ${getColumnDetail(option?.title_en, option?.title_pt) || ""}`
                            }
                            getOptionValue={(option) => option?.id}
                            className={`custom-select ${
                              validation.touched.auction_id && validation.errors.auction_id ? "select-error" : ""
                            }`}
                            placeholder={props.t("placeholder.selectField", {
                              fieldType: props.t("common.auction"),
                            })}
                            value={validation.values.auction_id || null}
                            onChange={(option) => {
                              validation.setFieldValue("auction_id", option?.id ? option : null);
                            }}
                            onInputChange={auctionSearchDebounce}
                            onBlur={(e) => {
                              validation.setFieldTouched("auction_id", true);
                              validation.handleBlur(e);
                            }}
                          />
                          {validation.touched.auction_id && validation.errors.auction_id ? (
                            <span className="custom-invalid-feedback">{validation.errors.auction_id}</span>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="contract_no" className="form-label">
                              {props.t("common.auction")}
                            </Label>
                            <Input
                              disabled
                              className="form-control"
                              placeholder={
                                validation?.values?.auction_id ? validation?.values?.auction_id?.auction_no : "****"
                              }
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="contract_no" className="form-label">
                              {props.t("common.lot")}
                            </Label>
                            <Input
                              disabled
                              className="form-control"
                              placeholder={
                                validation?.values?.auction_id?.lot_no ||
                                validation?.values?.auction_id?.id === contractItem?.auction_id?.id
                                  ? contractItem?.auction_id?.lot_no + contractItem?.auction_id?.lot_alpha
                                  : "****"
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <hr className="opacity-50" />
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="min_estimate" className="form-label fw-bold">
                            {props.t("common.minimumEstimate")}
                          </Label>
                          <NumberInput
                            name="min_estimate"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.minimumEstimate"),
                            })}
                            type="number"
                            onChange={(e) => {
                              validation.handleChange(e);
                              validation.setFieldValue("reserve_price", e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.min_estimate}
                            invalid={validation.touched.min_estimate && validation.errors.min_estimate ? true : false}
                          />
                          {validation.touched.min_estimate && validation.errors.min_estimate ? (
                            <FormFeedback type="invalid">{validation.errors.min_estimate}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="max_estimate" className="form-label fw-bold">
                            {props.t("common.maximumEstimate")}
                          </Label>
                          <NumberInput
                            name="max_estimate"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.maximumEstimate"),
                            })}
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.max_estimate}
                            invalid={validation.touched.max_estimate && validation.errors.max_estimate ? true : false}
                          />
                          {validation.touched.max_estimate && validation.errors.max_estimate ? (
                            <FormFeedback type="invalid">{validation.errors.max_estimate}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="estimate_for_catalog" className="form-label">
                            {`${props.t("common.estimateForCatalog")}`}
                          </Label>
                          <Input
                            name="estimate_for_catalog"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.estimateForCatalog"),
                            })}
                            type="text"
                            disabled={true}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.estimate_for_catalog}
                            invalid={
                              validation.touched.estimate_for_catalog && validation.errors.estimate_for_catalog
                                ? true
                                : false
                            }
                          />
                          {validation.touched.estimate_for_catalog && validation.errors.estimate_for_catalog ? (
                            <FormFeedback type="invalid">{validation.errors.estimate_for_catalog}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="premium" className="form-label">
                            {props.t("common.premium") + "*"}
                          </Label>
                          <NumberInput
                            name="premium"
                            className="form-control"
                            placeholder={`${props.t("placeholder.enterField", {
                              fieldType: props.t("common.premium"),
                            })}`}
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.premium}
                            invalid={validation.touched.premium && validation.errors.premium ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                          />
                          {validation.touched.premium && validation.errors.premium ? (
                            <FormFeedback type="invalid">{validation.errors.premium}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <hr className="opacity-50" />
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="price_on_request" className="form-label">
                              {`${props.t("common.priceOnRequest")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="price_on_request"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.price_on_request}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="value_to_be_agreed" className="form-label">
                              {`${props.t("common.valueToBeAgreed")}`}
                            </Label>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                role="switch"
                                name="value_to_be_agreed"
                                className="form-check-input"
                                onChange={validation.handleChange}
                                checked={validation.values.value_to_be_agreed}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
                        <>
                          <Row>
                            <div className="mb-3">
                              <Label htmlFor="hammer_price" className="form-label">
                                {`${props.t("common.hammerPrice")}`}
                              </Label>
                              <NumberInput
                                name="hammer_price"
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: props.t("common.hammerPrice"),
                                })}
                                type="number"
                                disabled={true}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.hammer_price}
                                invalid={
                                  validation.touched.hammer_price && validation.errors.hammer_price ? true : false
                                }
                              />
                              {validation.touched.hammer_price && validation.errors.hammer_price ? (
                                <FormFeedback type="invalid">{validation.errors.hammer_price}</FormFeedback>
                              ) : null}
                            </div>
                          </Row>

                          <Row>
                            <div className="mb-3">
                              <Label htmlFor="published_value" className="form-label">
                                {`${props.t("common.publishedValue")}`}
                              </Label>
                              <NumberInput
                                name="published_value"
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: props.t("common.publishedValue"),
                                })}
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.published_value}
                                invalid={
                                  validation.touched.published_value && validation.errors.published_value ? true : false
                                }
                              />
                              {validation.touched.published_value && validation.errors.published_value ? (
                                <FormFeedback type="invalid">{validation.errors.published_value}</FormFeedback>
                              ) : null}
                            </div>
                          </Row>
                          <Row>
                            <div className="mb-3">
                              <Label htmlFor="buyer" className="form-label">
                                {`${props.t("common.buyer")}`}
                              </Label>
                              <Input disabled className="form-control" placeholder={"C***"} />
                            </div>
                          </Row>
                        </>
                      )}
                      <hr className="opacity-50" />
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="reserve_price" className="form-label">
                            {props.t("common.reservePrice")}
                          </Label>
                          <NumberInput
                            name="reserve_price"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.reservePrice"),
                            })}
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reserve_price}
                            invalid={validation.touched.reserve_price && validation.errors.reserve_price ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                          />
                          {validation.touched.reserve_price && validation.errors.reserve_price ? (
                            <FormFeedback type="invalid">{validation.errors.reserve_price}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="net_value" className="form-label">
                            {props.t("common.netValue")}
                          </Label>
                          <NumberInput
                            name="net_value"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.netValue"),
                            })}
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.net_value}
                            invalid={validation.touched.net_value && validation.errors.net_value ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                          />
                          {validation.touched.net_value && validation.errors.net_value ? (
                            <FormFeedback type="invalid">{validation.errors.net_value}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="renegotiation" className="form-label">
                            {props.t("common.valuesForRenegotiation")}
                          </Label>
                          <Input
                            name="renegotiation"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.valuesForRenegotiation"),
                            })}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.renegotiation}
                            invalid={validation.touched.renegotiation && validation.errors.renegotiation ? true : false}
                            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                          />
                          {validation.touched.renegotiation && validation.errors.renegotiation ? (
                            <FormFeedback type="invalid">{validation.errors.renegotiation}</FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="vat" className="form-label">
                            {props.t("common.vat_another")}
                          </Label>
                          <Select
                            isClearable
                            name="vat"
                            options={VAT_OPTIONS}
                            className={`custom-select ${
                              validation.touched.vat && validation.errors.vat ? "select-error" : ""
                            }`}
                            placeholder={props.t("placeholder.selectField", {
                              fieldType: props.t("common.vat_another"),
                            })}
                            value={getTypeFromValue(VAT_OPTIONS, validation.values.vat) || ""}
                            onChange={(vat) => {
                              validation.setFieldValue("vat", vat?.value ? vat?.value : "");
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("vat", true);
                              validation.handleBlur(e);
                            }}
                            isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                          />
                          {validation.touched.vat && validation.errors.vat ? (
                            <span className="custom-invalid-feedback">{validation.errors.vat}</span>
                          ) : null}
                        </div>
                      </Row>
                    </Col>
                  )}
                </Row>
                <hr className="opacity-50" />

                {/* photos */}
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="photos" className="form-label fw-bold">
                      {props.t("common.photos")}
                    </Label>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="appraisal_photos" className="form-label">
                        {`${props.t("common.appraisal")}`}
                      </Label>
                      <CustomDropDown
                        grid={12}
                        setFiles={setAppraisalPhotos}
                        files={appraisalPhotos}
                        isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="photos" className="form-label">
                        {`${props.t("common.catalogue")}`}
                      </Label>
                      <CustomDropDown
                        grid={12}
                        setFiles={setPhotos}
                        files={photos}
                        default={true}
                        selectedIndex={selectedIndex}
                        handleClick={(index) => {
                          setDefaultPhoto([photos[index]]);
                          setSelectedIndex(index);
                        }}
                        isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="condition_report_photos" className="form-label">
                        {`${props.t("common.conditionReport")}`}
                      </Label>
                      <CustomDropDown
                        grid={12}
                        setFiles={setConditionReportPhotos}
                        files={conditionReportPhotos}
                        isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])}
                      />
                    </div>
                  </Col>
                </Row>
                {/* expenses */}
                {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
                  <>
                    <hr className="opacity-50" />
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="expenses" className="form-label fw-bold">
                          {`${props.t("common.expenses")}`}
                        </Label>
                        <ExpensesForm validation={validation} />
                      </div>
                    </Row>
                  </>
                )}
              </CardBody>
              <CardFooter>
                <div className="d-flex flex-row justify-content-end gap-2">
                  <CustomButton
                    color="dark"
                    btnTitle={props.t("common.reset")}
                    disabled={saveLoader}
                    onClick={handleResetItemInfoForm}
                  />
                  <CustomButton
                    color="primary"
                    disabled={saveLoader}
                    btnTitle={props.t("common.save")}
                    type="submit"
                    isLoading={saveLoader}
                    onClick={() => scrollToFirstFormikError(validation.errors)}
                  />
                </div>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </FormikProvider>
      {/* category modal */}
      <AddCategoryModal
        category={selectedItem}
        isModalOpen={isCategoryModalOpen}
        onCancel={handleCategoryModalClose}
        onSave={handleCategorySave}
        loader={modalSaveLoader}
      />
      {/* artist modal */}
      <AddArtistModal
        artist={selectedItem}
        isModalOpen={isArtistModalOpen}
        onCancel={handleArtistModalClose}
        onSave={handleArtistSave}
        loader={modalSaveLoader}
      />
      {/* period modal */}
      <AddPeriodModal
        period={selectedItem}
        isModalOpen={isPeriodModalOpen}
        onCancel={handlePeriodModalClose}
        onSave={handlePeriodSave}
        loader={modalSaveLoader}
      />
      {/* confirmaton modal for tag actions */}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        body={t("confirmation.actionMessage")}
        onCloseBtnHandler={handleConfirmationModalClose}
        onConfirmBtnHandler={handleConfirmOnTagChange}
      />
      {/* confirmaton modal if published lots */}
      <AttentionModal
        isModalOpen={confirmSave}
        body={t("confirmation.actionMessage")}
        onCloseBtnHandler={handleCancel}
        onConfirmBtnHandler={handleConfirm}
      />
    </>
  );
};

export default withTranslation()(ContractItemForm);
