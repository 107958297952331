import { FormikProvider } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Label, Row } from "reactstrap";
import { CONTACT_US_CONTENT, TYPE_SAVE } from "../../../../helpers/constants";
import {
  checkImageFileValidation,
  checkVideoFileValidation,
  getColumnDetail,
  getLabelValueArray,
} from "../../../../helpers/utils";
import { getTeamPositions } from "../../../../store/common/actions";
import { deleteWebsitePagesInformation, getWebsitePagesInformation } from "../../../../store/website-pages/actions";
import InformationFieldArray from "../../../common/InformationFieldArray";
import Loader from "../../../common/Loader";
import MultiMediaTableForm from "../../../common/MultiMediaTableForm";
import ActionButton from "../../../../components/common/ActionButton";
import CustomAddButton from "../../../common/CustomAddButton";
import ImageCropperModal from "../../../common/ImageCropperModal";
import AddPositionModal from "../../settings/AddPositionModal";
import { addPosition } from "../../../../store/positions/actions";

const ContactUSContent = ({ languageType, website_page, pageType, ...props }) => {
  const dispatch = useDispatch();
  const TEAM_POSITIONS = getLabelValueArray(
    useSelector((state) => state?.common?.teamPositions?.data),
    "id",
    getColumnDetail("title_en", "title_pt")
  );
  const [infoLoader, setInfoLoader] = useState(false);
  const [infoPayload, setInfoPayload] = useState({
    filter: {
      website_page: website_page,
      language_type: languageType,
      page_type: pageType,
      save_type: TYPE_SAVE,
    },
  });
  const [teamPositionsPayload, setTeamPositionsPayload] = useState({
    page: 1,
    sort: getColumnDetail("title_en", "title_pt"),
  });

  // const [addPosition, setAddPosition] = useState(false);
  const [isImageCropperModalOpen, setIsImageCropperModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const [selectedArrayKey, setSelectedArrayKey] = useState();
  const [positionLoader, setPositionLoader] = useState(false);
  const [isPositionModalOpen, setIsPositionModalOpen] = useState(false);

  // to get the home page information
  const handleGetPageInformation = useCallback(
    (payload) => {
      setInfoLoader(true);
      dispatch(getWebsitePagesInformation(payload, CONTACT_US_CONTENT)).then(() => {
        setInfoLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPageInformation(infoPayload);
  }, [infoPayload, handleGetPageInformation]);

  // to get the list of team positions
  useEffect(() => {
    dispatch(getTeamPositions(teamPositionsPayload));
  }, [teamPositionsPayload, dispatch]);

  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.contactUsValidation?.resetForm();
    };
  }, []);

  const handleDeleteItem = (payload, setDeleteLoader, closeModal, boundArrayHelpers, itemIndex, arrayKey) => {
    setDeleteLoader(true);
    dispatch(deleteWebsitePagesInformation(payload)).then((res) => {
      if (res) {
        if (arrayKey === "departments")
          props?.setWhoWeAreValues((prevState) => ({
            ...prevState,
            departments: prevState?.departments?.filter((item) => !payload?.ids?.includes(item?.id)),
          }));
        else if (arrayKey === "teams")
          props?.setWhoWeAreValues((prevState) => ({
            ...prevState,
            teams: prevState?.teams?.filter((item) => !payload?.ids?.includes(item?.id)),
          }));
        boundArrayHelpers?.remove(itemIndex);
      }
      setDeleteLoader(false);
      closeModal();
    });
  };

  // handle image modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsImageCropperModalOpen(true);
  };
  // handle image modal close
  const handleImageCropModalClose = () => {
    setIsImageCropperModalOpen(false);
  };

  // gets the cropped image file and sets in the form
  const handleImageCrop = (file) => {
    const selectedRowData = props?.contactUsValidation?.values?.[selectedArrayKey]?.[selectedImageIndex];

    props?.contactUsValidation?.setFieldValue(`${selectedArrayKey}.${selectedImageIndex}`, {
      ...selectedRowData,
      file,
    });
    handleImageCropModalClose();
  };

  //position modal open handler
  const handlePositionModalOpen = () => {
    setIsPositionModalOpen(true);
  };

  //position modal close handler
  const handlePositionModalClose = () => {
    setIsPositionModalOpen(false);
  };

  //handle save position
  const handleSavePosition = (values, formikValidation) => {
    setPositionLoader(true);
    dispatch(addPosition(values))
      .then((res) => {
        if (res) {
          // success handler
          handlePositionModalClose();
          setTeamPositionsPayload((prevState) => ({ ...prevState }));
          formikValidation.resetForm();
        } else {
          // failure handler
        }
        setPositionLoader(false);
      })
      .catch(() => {
        setPositionLoader(false);
      });
  };

  return (
    <FormikProvider value={props?.contactUsValidation}>
      <Form
        className="position-relative"
        onSubmit={(e) => {
          e.preventDefault();
          props?.contactUsValidation.handleSubmit();
          return false;
        }}
      >
        {infoLoader ? <Loader /> : null}

        {/* banners */}
        <Row>
          <div className="mb-3">
            <Label className="form-label fw-bold">{`${props.t("common.banner")}`}</Label>
            <MultiMediaTableForm
              hideAddButton
              hideDeleteAction
              validation={props?.contactUsValidation}
              arrayKey="banner"
              sectionName={props.t("common.banner")}
              defaultRowValues={{ file: "", title: "", subtitle: "", link: "" }}
              columns={[
                {
                  label: props.t("common.no_"),
                  spacing: { sm: 1, md: 1, lg: 1 },
                  type: "counter",
                },
                {
                  key: "file",
                  label: `${props.t("common.image")}/${props.t("common.video")}`,
                  actionButtons: [
                    {
                      Component: (index, arrayKey) => (
                        <ActionButton
                          iconClasses="ri-crop-2-fill fs-18 cursor-pointer"
                          buttonId={`tooltip-${arrayKey}-${index}-cropping`}
                          tooltip={props.t("common.cropImage")}
                          onClick={() => {
                            setSelectedImageIndex(index);
                            setSelectedArrayKey(arrayKey);
                            handleLinkToAuctionModalOpen();
                          }}
                        />
                      ),
                      fileType: "image",
                    },
                  ],
                  spacing: { sm: 4, md: 4, lg: 4 },
                  type: "file",
                  accept: "image/*,video/*",
                  checkFileTypeValidation: (fileName) => {
                    return checkImageFileValidation(fileName) || checkVideoFileValidation(fileName);
                  },
                },
                {
                  key: "title",
                  label: props.t("common.title"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "subtitle",
                  label: props.t("common.subTitle"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "link",
                  label: props.t("common.link"),
                  spacing: { sm: 3, md: 3, lg: 3 },
                  type: "text",
                },
              ]}
            />
          </div>
        </Row>

        {/* information */}
        <InformationFieldArray
          validation={props?.contactUsValidation}
          arrayKey={"meta_info"}
          blocks={[
            {
              label: props.t("common.informationNumber", { number: 1 }),
              key: "information_1",
            },
          ]}
        />

        {/* departments */}
        <Row>
          <div className="mb-3">
            <Label className="form-label fw-bold">{`${props.t("common.departments")}`}</Label>
            <MultiMediaTableForm
              validation={props?.contactUsValidation}
              arrayKey="departments"
              sectionName={props.t("common.department")}
              defaultRowValues={{ name: "", email: "" }}
              handleDeleteItem={handleDeleteItem}
              columns={[
                {
                  label: props.t("common.no_"),
                  spacing: { sm: 1, md: 1, lg: 1 },
                  type: "counter",
                },
                {
                  key: "name",
                  label: props.t("common.name"),
                  spacing: { sm: 5, md: 5, lg: 5 },
                  type: "text",
                },
                {
                  key: "email",
                  label: props.t("common.email"),
                  spacing: { sm: 5, md: 5, lg: 5 },
                  type: "text",
                },
              ]}
            />
          </div>
        </Row>

        {/* teams */}

        <Row>
          <div className="mb-3">
            <Label className="form-label fw-bold">{`${props.t("common.teams")}`}</Label>

            <MultiMediaTableForm
              validation={props?.contactUsValidation}
              arrayKey="teams"
              sectionName={props.t("common.team")}
              defaultRowValues={{ position: "", name: "", email: "" }}
              handleDeleteItem={handleDeleteItem}
              hidePositionAction={true}
              columns={[
                {
                  label: props.t("common.no_"),
                  spacing: { sm: 1, md: 1, lg: 1 },
                  type: "counter",
                },
                {
                  key: "position",
                  label: props.t("common.position"),
                  spacing: { sm: 4, md: 4, lg: 4 },
                  type: "select",
                  options: TEAM_POSITIONS,
                  headerActionButton: [
                    <ActionButton
                      buttonClasses={"ms-1"}
                      tooltip={props.t("common.addTitle", {
                        module: props.t("common.position"),
                      })}
                      iconClasses={"ri-add-circle-fill fs-18"}
                      buttonId={"position-add"}
                      onClick={() => {
                        handlePositionModalOpen();
                      }}
                    />,
                  ],
                },
                {
                  key: "name",
                  label: props.t("common.name"),
                  spacing: { sm: 3, md: 3, lg: 3 },
                  type: "text",
                },
                {
                  key: "email",
                  label: props.t("common.email"),
                  spacing: { sm: 3, md: 3, lg: 3 },
                  type: "text",
                },
              ]}
            />
          </div>
        </Row>
      </Form>
      <ImageCropperModal
        isModalOpen={isImageCropperModalOpen}
        onCancel={handleImageCropModalClose}
        title={props.t("common.cropImage")}
        src={props?.contactUsValidation?.values?.[selectedArrayKey]?.[selectedImageIndex]?.file}
        handleImageCrop={handleImageCrop}
      />
      <AddPositionModal
        loader={positionLoader}
        isModalOpen={isPositionModalOpen}
        onCancel={handlePositionModalClose}
        onSave={handleSavePosition}
      />
    </FormikProvider>
  );
};

export default withTranslation()(ContactUSContent);
