import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";

import { ADD_LOTSTAG, DELETE_LOTSTAG, SET_LOTSTAGS_LIST, UPDATE_LOTSTAG } from "./actionTypes";

export const getTags = (params) => async (dispatch) => {
  try {
    return API.get(`/lots/tags`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        filter: params?.filter,
        sort: params?.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_LOTSTAGS_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add tag
export const addTag = (payload) => async (dispatch) => {
  try {
    return API.post(`/lots/tags`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_LOTSTAG,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update tag
export const updateTag = (tagId, payload) => async (dispatch) => {
  try {
    return API.put(`/lots/tags/${tagId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_LOTSTAG,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete tag
export const deleteTag = (tagId) => async (dispatch) => {
  try {
    return API.delete(`/lots/tags/${tagId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: DELETE_LOTSTAG,
          payload: tagId,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
