import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { generateCustomerPassword } from "../../../store/customers/actions";
import ActionButton from "../../common/ActionButton";
import CustomButton from "../../common/CustomButton";

const GeneratePasswordModal = ({ isModalOpen, onCancel, customer, t }) => {
  const dispatch = useDispatch();
  const [generateLoader, setGenerateLoader] = useState(false);
  const [password, setNewPassword] = useState(null);
  const [copyLoader, setCopyLoader] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setNewPassword(null);
    }
  }, [isModalOpen]);

  //   handle modal close
  const handleModalClose = () => {
    if (!generateLoader) {
      onCancel();
    }
  };

  // to generate new password
  const handleGeneratePassword = () => {
    if (customer?.id) {
      setGenerateLoader(true);
      dispatch(generateCustomerPassword(customer?.id)).then((res) => {
        if (res) {
          setNewPassword(res?.data?.data?.password);
        }
        setGenerateLoader(false);
      });
    }
  };

  //   handel password copy
  const handlePasswordCopy = () => {
    const passwordEl = document.getElementById("password-text");
    passwordEl?.select();
    navigator?.clipboard?.writeText(passwordEl?.value);
    setCopyLoader(true);
    setTimeout(() => {
      setCopyLoader(false);
    }, [1000]);
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{password ? t("common.passwordGeneratedSuccessfully") : t("common.generatePassword")}</ModalHeader>
      <ModalBody>
        {password ? (
          <div className="mb-3">
            <Label className="form-label">{t("common.password")}</Label>
            <div className="d-flex flex-row gap-2">
              <Input id="password-text" disabled className="form-control" value={password} />
              {!copyLoader ? (
                <ActionButton
                  iconClasses={"ri-file-copy-fill fs-24"}
                  tooltip={t("common.copy")}
                  buttonId={"password-copy"}
                  onClick={handlePasswordCopy}
                />
              ) : (
                <ActionButton iconColor={"green"} iconClasses={"ri-checkbox-fill fs-24"} onClick={handlePasswordCopy} />
              )}
            </div>
          </div>
        ) : (
          <div>{t("confirmation.generatePassword")}</div>
        )}
      </ModalBody>
      <ModalFooter>
        {password ? (
          <CustomButton
            btnTitle={t("common.okay")}
            type="button"
            onClick={handleModalClose}
            disabled={generateLoader}
          />
        ) : (
          <>
            <CustomButton
              btnTitle={t("common.cancel")}
              type="button"
              onClick={handleModalClose}
              color="dark"
              disabled={generateLoader}
            />
            <CustomButton
              type="button"
              btnTitle={t("common.generate")}
              isLoading={generateLoader}
              onClick={handleGeneratePassword}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(GeneratePasswordModal);
