import React from "react";

const MessageBlock = ({ message, height, width, fontSize }) => {
  return (
    <h5
      className="d-flex justify-content-center align-items-center"
      style={{
        height: height || "250px",
        fontSize: fontSize || "18px",
        width: width || "100%",
      }}
    >
      {message}
    </h5>
  );
};

export default MessageBlock;
