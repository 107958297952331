import { FieldArray } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { Label, Row } from "reactstrap";
import EditorBlock from "./EditorBlock";

const InformationFieldArray = (props) => {
  return (
    <>
      <div>
        <FieldArray
          name={props.arrayKey}
          render={(arrayHelpers) => {
            return props.validation.values?.[props?.arrayKey]?.length !== 0
              ? props.validation.values?.[props.arrayKey]?.map((row, index) => {
                  return (
                    <Row key={index}>
                      <div className="mb-3">
                        <Label className="form-label fw-bold">{props?.blocks?.[index]?.label}</Label>
                        <EditorBlock
                          editorState={
                            props.validation?.values?.[props.arrayKey]?.[index]?.[props?.blocks?.[index]?.key] || ""
                          }
                          onEditorStateChange={(value) => {
                            props.validation.setFieldValue(
                              `${props.arrayKey}.${index}.${props?.blocks?.[index]?.key}`,
                              value
                            );
                          }}
                          onBlur={(e) => {
                            props?.validation.setFieldTouched(
                              `${props.arrayKey}.${index}.${props?.blocks?.[index]?.key}`,
                              true
                            );
                          }}
                          invalid={
                            props?.errorsInInfoFields
                              ? !props?.errorsInInfoFields[index]
                              : props.validation?.errors?.[props?.arrayKey]?.[index]?.[props?.blocks?.[index]?.key] &&
                                props.validation?.touched?.[props?.arrayKey]?.[index]?.[props?.blocks?.[index]?.key]
                          }
                          errorText={
                            props?.errorsInInfoFields
                              ? !props?.errorsInInfoFields[index] &&
                                props.validation?.touched?.[props?.arrayKey]?.[index]?.[props?.blocks?.[index]?.key] &&
                                props.t("validation.generalField", {
                                  promptType: props.t("common.enter"),
                                  fieldType: props.t("common.information"),
                                })
                              : props.validation?.errors?.[props?.arrayKey]?.[index]?.[props?.blocks?.[index]?.key]
                          }
                        />
                      </div>
                    </Row>
                  );
                })
              : null;
          }}
        />
      </div>
    </>
  );
};

export default withTranslation()(InformationFieldArray);
