import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { TYPE_OF_PRODUCTS } from "../../../helpers/constants/selectOptions";
import {
  roundTo,
  formatAmount,
  getTypeFromValue,
  calculateInsuranceForLots,
  calculateTotalForDocumentLine,
} from "../../../helpers/utils";
import ActionButton from "../../common/ActionButton";
import MessageBlock from "../../common/MessageBlock";
import DeleteModal from "../../common/DeleteModal";
import {
  CREDIT_NOTE_LOT,
  DEBIT_NOTE_LOT,
  DEFAULT_ROW_PER_PAGE,
  INVOICE_DOCUMENT,
  PROFORMA_INVOICE_DOCUMENT,
  RETURN_GUIDE_DOCUMENT,
  SALES_NOTE,
  TRANSPORT_DOCUMENT,
} from "../../../helpers/constants";
import NumberInput from "../../common/NumberInput";
import { toast } from "react-toastify";

const DocumentLines = (props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDocumentLine, setSelectedDocumentLine] = useState();

  // handle delete document line modal open
  const handleDeleteModalOpen = (index) => {
    setSelectedDocumentLine(index);
    setIsDeleteModalOpen(true);
  };
  // handle delete document line modal close
  const handleDeleteModalClose = () => {
    setSelectedDocumentLine(null);
    setIsDeleteModalOpen(false);
  };

  // handle delete document line
  const handleDeleteDocumentLine = () => {
    props.deleteDocumentLine(selectedDocumentLine);
    handleDeleteModalClose();
  };

  const handlePremiumChange = (lineIndex, premium) => {
    const premiumValue = +premium || 0;
    if (premiumValue < 0 || premiumValue > 100) {
      const target = premiumValue < 0 ? 0 : 100;
      const errorText = props.t(`validation.number${!target ? "Min" : "Max"}`, { totalNumber: target });
      return toast.error(errorText);
    }

    props?.setDocumentLines((prevState) => {
      const temp = structuredClone(prevState);
      temp[lineIndex].premium = premiumValue;
      if (temp[lineIndex].itemData) temp[lineIndex].itemData.premium = premiumValue;

      let insurance = 0;
      const documentLine = temp[lineIndex];
      if (
        props?.validation?.values?.document_type?.description === SALES_NOTE &&
        documentLine?.type_of_product === "lot"
      ) {
        insurance = calculateInsuranceForLots(
          documentLine?.itemData?.amount,
          1,
          documentLine?.itemData?.vat,
          props?.validation?.values?.dont_apply_vat
        );
      }
      const total = calculateTotalForDocumentLine(
        {
          qty: documentLine?.itemData?.quantity,
          value: documentLine?.itemData?.amount,
          premium: documentLine?.itemData?.premium,
          insurance: insurance,
          discount: documentLine?.itemData?.discount,
          discount_unit: documentLine?.itemData?.discount_unit,
          vat: documentLine?.itemData?.vat,
        },
        documentLine?.itemData?.type_of_product,
        props?.validation?.values?.dont_apply_vat,
        props?.validation?.values?.document_type?.description
      );
      temp[lineIndex] = {
        ...documentLine,
        amount: +total,
        itemData: {
          ...documentLine?.itemData,
          final_amount: +total,
          insurance: insurance,
        },
      };

      return temp;
    });
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.no_")}</div>
        </div>
      ),
      selector: (row, index) => index + 1 || "-",
      width: props.onlyInfo ? "15%" : "70px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.type")}</div>
        </div>
      ),
      selector: (row) =>
        props?.isRecipt && props.viewMode
          ? row?.invoice_id?.document_type
          : props.isRecipt
            ? row?.itemData?.documentType
            : row?.itemData?.type_of_product
              ? getTypeFromValue(TYPE_OF_PRODUCTS, row?.itemData?.type_of_product)?.label
              : "-",
      width: props?.isRecipt ? "150px" : props.onlyInfo ? "15%" : props?.isPartialPayment ? "70px" : "112px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.documentNo")}</div>
        </div>
      ),
      selector: (row) =>
        props?.isRecipt && props.viewMode
          ? row?.invoice_id?.document_no
          : row?.itemData?.documentNo
            ? row?.itemData?.documentNo
            : " - ",
      width: "120px",
      wrap: true,
      omit: !props?.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.positionInContract")}</div>
        </div>
      ),
      selector: (row) => row?.itemData?.positionInContract || "-",
      width: props.onlyInfo ? "15%" : "145px",
      wrap: true,
      omit: ![
        PROFORMA_INVOICE_DOCUMENT,
        INVOICE_DOCUMENT,
        TRANSPORT_DOCUMENT,
        RETURN_GUIDE_DOCUMENT,
        CREDIT_NOTE_LOT,
        DEBIT_NOTE_LOT,
        SALES_NOTE,
      ].includes(props.formValues?.document_type?.description),
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
        </div>
      ),
      selector: (row) => row?.itemData?.description || "-",
      width: props.onlyInfo ? "20%" : props?.isPartialPayment ? "140px" : "210px",
      wrap: true,
      omit: props.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.qty")}</div>
        </div>
      ),
      selector: (row) => row?.itemData?.quantity || 1,
      width: props.onlyInfo ? "15%" : "80px",
      wrap: true,
      omit: props.isRecipt || props.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.amount")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.itemData?.amount || 0) + " €",
      width: props?.isPartialPayment ? "112px" : "120px",
      wrap: true,
      omit: props.onlyInfo || props.isRecipt,
    },
    [SALES_NOTE, PROFORMA_INVOICE_DOCUMENT, INVOICE_DOCUMENT].includes(
      props?.validation?.values?.document_type?.description
    )
      ? {
          name: (
            <div className="font-weight-bold fs-14 table-column-container py-4">
              <div className="table-column-title mb-2">{props.t("common.premium")} ( % )</div>
            </div>
          ),
          selector: (row, index) => {
            const target = +row?.itemData?.premium < 0 ? 0 : 100;
            const errorText = props.t(`validation.number${!target ? "Min" : "Max"}`, { totalNumber: target });
            return props?.viewMode ? (
              (row?.itemData?.premium || 0) + " %"
            ) : (
              <NumberInput
                name="premium"
                placeholder={`${props.t("common.enter")} ${props.t("common.premium")}`}
                onChange={(e) => handlePremiumChange(index, e?.target?.value)}
                onBlur={props.validation.handleBlur}
                value={row?.itemData?.premium || 0}
                invalid={+row?.itemData?.premium < 0 || +row?.itemData?.premium > 100}
                tooltipId={`premium-${index}`}
                errorText={errorText}
                bsSize="sm"
                style={{ width: "120px" }}
                required
              />
            );
          },
          width: "160px",
          wrap: true,
          omit: props.onlyInfo || props.isRecipt,
        }
      : null,
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.discount")} ( % / € )`}</div>
        </div>
      ),
      selector: (row) =>
        row?.itemData?.discount ? `${row?.itemData?.discount} ${row?.itemData?.discount_unit}` : 0 + " %",
      width: "168px",
      wrap: true,
      omit: props.onlyInfo || props.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            {`${props.t("sidebarMenu.vat")} `} {"( % )"}
          </div>
        </div>
      ),
      selector: (row) => (row?.itemData?.vat && !props.doNotApplyVat ? row?.itemData?.vat : 0) + " %",
      width: "112px",
      wrap: true,
      omit: props.onlyInfo || props.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.insurance")}`}</div>
        </div>
      ),
      selector: (row) => (row?.itemData?.insurance ? formatAmount(row?.itemData?.insurance) : 0) + " €",
      width: props?.isPartialPayment ? "84px" : "112px",
      wrap: true,
      omit: props.onlyInfo || props.isRecipt,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.finalAmount")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.itemData?.final_amount || 0) + " €",
      width: "138px",
      wrap: true,
      omit: props.onlyInfo,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.paidAmount")}</div>
        </div>
      ),
      selector: (row) =>
        props?.viewMode
          ? formatAmount(roundTo(row?.itemData?.total_to_settle || 0)) + " €"
          : formatAmount(roundTo(row?.itemData?.paid_amount || 0)) + " €",
      width: "112px",
      wrap: true,
      omit: props.onlyInfo || !props?.isPartialPayment,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.totalAmount")}</div>
        </div>
      ),
      selector: (row, index) => (
        <>
          <NumberInput
            className="form-control"
            placeholder={`${props.t("placeholder.enterField", {
              fieldType: props.t("common.totalAmount"),
            })}`}
            value={row?.debit_total}
            onChange={(e) => {
              if (Number(e.target.value) < 0) return;
              props?.setDocumentLines((prevState) => {
                const temp = [...prevState];
                temp[index].debit_total = Number(e.target.value);
                temp[index].itemData.hasError = Number(e.target.value) < 0;
                return temp;
              });
            }}
            tooltipId={`amount-${index}`}
            errorText={`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.totalAmount")}`}
            invalid={Number(row?.debit_total) <= 0}
            required={true}
          />
        </>
      ),
      width: "138px",
      wrap: true,
      omit: props.onlyInfo || !props?.isDebitNote || props?.viewMode,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.totalToSettle")}</div>
        </div>
      ),
      selector: (row, index) => (
        <>
          <NumberInput
            className="form-control"
            placeholder={`${props.t("placeholder.enterField", {
              fieldType: props.t("common.amount"),
            })}`}
            value={row?.total_to_settle}
            onChange={(e) => {
              if (Number(e.target.value) < 0) return;
              props?.setDocumentLines((prevState) => {
                const temp = [...prevState];
                temp[index].total_to_settle = Number(e.target.value);
                temp[index].itemData.hasError =
                  +(e.target.value || 0) >
                  roundTo(parseFloat(row?.amount || 0) - parseFloat(row?.itemData?.paid_amount || 0));
                return temp;
              });
            }}
            tooltipId={`amount-${index}`}
            errorText={props.t("validation.maxTotalToSettle")}
            invalid={
              +(row?.total_to_settle || 0) >
              roundTo(parseFloat(row?.amount || 0) - parseFloat(row?.itemData?.paid_amount || 0))
            }
          />
        </>
      ),
      width: "138px",
      wrap: true,
      omit: props.onlyInfo || !props?.isPartialPayment || props?.viewMode,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row, index) => (
        <div className="table-actions">
          <ActionButton
            iconClasses="ri-delete-bin-fill fs-18"
            buttonId={`action-${index}-delete`}
            tooltip={props.t("common.delete")}
            onClick={() => {
              handleDeleteModalOpen(index);
            }}
          />
        </div>
      ),
      width: props.onlyInfo ? "15%" : props?.isPartialPayment ? "68px" : "110px",
      center: true,
      omit: props.viewMode,
    },
  ].filter(Boolean);

  return (
    <>
      <DataTable
        fixedHeader
        className="mini-data-table document-lines-table"
        persistTableHead
        columns={columns}
        data={[...props?.data] || []}
        paginationPerPage={DEFAULT_ROW_PER_PAGE}
        pagination
        noDataComponent={
          <MessageBlock
            message={props.t("common.addTitle", {
              module: props.t("common.documentLine"),
            })}
            height="100px"
            fontSize={"14px"}
          />
        }
        paginationComponentOptions={{
          rowsPerPageText: props.t("common.rowsPerPage"),
          rangeSeparatorText: props.t("common.rangeSeparator"),
        }}
      />
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteDocumentLine}
        title={`${props.t("common.delete")} ${props.t("common.documentLine")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.documentLine"),
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(DocumentLines);
