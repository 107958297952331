import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { SET_NOTE_LIST } from "./actionType";

// list all notes
export const getNoteLists = (params) => async (dispatch) => {
  try {
    return API.get(`/homes/notes`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_NOTE_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// create tasks
export const createNote = (payload) => async (dispatch) => {
  try {
    return API.post(`/homes/notes`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// update tasks
export const updateNote =
  ({ id, values }) =>
  async (dispatch) => {
    try {
      return API.put(`/homes/notes/${id}`, values).then((res) => {
        if (res && res.status && res.status === 200) {
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// delete tasks
export const deleteNote =
  ({ ids, isSelectAll }) =>
  async (dispatch) => {
    try {
      return API.delete(`/homes/notes`, {
        data: {
          is_selected_all: isSelectAll,
          ids: [ids],
        },
      }).then((res) => {
        if (res && res.status && res.status === 200) {
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
