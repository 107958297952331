import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddNoteModal = ({ note, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      description: note?.description || "",
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .required(
          t("validation.requiredValidationMsg", {
            field: `${t("home.notes.note")}`,
          })
        ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {note
          ? t("common.editTitle", {
              module: t("home.notes.note"),
            })
          : t("common.addTitle", {
              module: t("home.notes.note"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("home.notes.note")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("home.notes.note")}`}
              type="textarea"
              style={{ height: "150px" }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddNoteModal);
