import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_MAINLANGUAGELIST,
  GET_PERMISSIONLIST,
  GET_SPOKENLANGUAGELIST,
  GET_USERLIST,
} from "./actionType";

// list all users
export const getUsers =
  (params = {}) =>
  async (dispatch) => {
    try {
      return API.get(`/users`, {
        params: {
          page_number: params?.page,
          page_size: params?.limit,
          search: params?.search,
          filter: params?.filter,
          sort: params?.sort,
        },
      }).then((res) => {
        if (res && res.status && res.status === 200) {
          dispatch({
            type: GET_USERLIST,
            payload: {
              data: res?.data?.data?.results,
              total: res?.data?.data?.count,
            },
          });
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// list all main Languages
export const getMainLanguages =
  ({ is_main }) =>
  async (dispatch) => {
    try {
      return API.get(`/users/languages`, {
        params: {
          filter: { is_main },
        },
      }).then((res) => {
        if (res && res.status && res.status === 200) {
          dispatch({
            type: GET_MAINLANGUAGELIST,
            payload: {
              data: res?.data?.data?.results,
              total: res?.data?.data?.count,
            },
          });
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// list all main Languages
export const getSpokenLanguages =
  ({ is_spoken }, sort) =>
  async (dispatch) => {
    try {
      return API.get(`/users/languages`, {
        params: {
          filter: { is_spoken },
          sort,
        },
      }).then((res) => {
        if (res && res.status && res.status === 200) {
          dispatch({
            type: GET_SPOKENLANGUAGELIST,
            payload: {
              data: res?.data?.data?.results,
              total: res?.data?.data?.count,
            },
          });
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// list all permissoins
export const getPermissions = () => async (dispatch) => {
  try {
    return API.get(`/users/permissions`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: GET_PERMISSIONLIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// create user
export const createUser = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: CREATE_USER,
          payload: {
            data: res?.data?.data,
          },
        });
        // toast message
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// create user
export const editUser = (payload) => async (dispatch) => {
  try {
    return API.put(`/users/${payload.id}`, payload.data).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: EDIT_USER,
          payload: {
            data: res?.data?.data,
          },
        });
        // toast message
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// delete user
export const deleteUser = (payload) => async (dispatch) => {
  try {
    return API.delete(`/users/${payload}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: DELETE_USER,
          payload: {
            deletedId: payload,
          },
        });
        // toast message
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
