import { FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CardBody, CardFooter, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import {
  checkSelectAllSelected,
  getColumnDetail,
  getDateInYYYYMMDDFormat,
  getDateString,
  getDateTimeString,
  getLabelValueArray,
  getValuesFromArray,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";

import { getAuctionTheme } from "../../../store/auction-theme/actions";
import { getAuctionTypes } from "../../../store/auction-types/actions";
import { getLocations } from "../../../store/locations/actions";
import CustomButton from "../../common/CustomButton";
import { Prompt, useHistory, useParams } from "react-router-dom";
import CustomDropDown from "../../common/CustomDropDown";
import SessionsForm from "./SessionsForm";
import { addAuction, updateAuction } from "../../../store/auctions/actions";
import debounce from "lodash.debounce";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT, MAX_DATE } from "../../../helpers/constants";
import ResultModal from "./ResultModal";
import { UpdateLotPublishStatus } from "../../../store/auctions/lotActions";
import PublishLotModal from "./PublishLotModal";
import moment from "moment";
import { toast } from "react-toastify";
import DateTimePicker from "../../common/DateTimePicker";
import Loader from "../../common/Loader";
import NumberInput from "../../common/NumberInput";
import { getAuctionCollaborators } from "../../../store/auction-collaborators/actions";
import { SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import { getShippingOptions } from "../../../store/customers/actions";
import API from "../../../helpers/api/api_interceptor";

const InfoForm = (props) => {
  const dispatch = useDispatch();
  const { id, editionType } = useParams();
  const history = useHistory();
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const COLLABORATORS_OPTIONS = useSelector((state) => state.Collaborators.data);
  const THEME_OPTIONS = useSelector((state) => state?.AuctionTheme?.data);

  const LOCATION_OPTIONS = useSelector((state) => state?.Locations?.data);
  const AUCTOIN_TYPES_OPTIONS = useSelector((state) => state?.AuctionTypes?.data);
  const shippingOptionList = useSelector((state) => state?.Customers?.shippingOptions?.results || []);
  const [photo, setPhoto] = useState([]);
  const [calendarPhoto, setCalendarPhoto] = useState([]);
  const [isPhotoSet, setIsPhotoSet] = useState(false);
  const [infoLoader, setInfoLoader] = useState(false);
  const [infoSaveLoader, setInfoSaveLoader] = useState(false);
  const [showingDateToggler, setShowingDateToggler] = useState("");
  const [themeSearchLoader, setThemeSearchLoader] = useState(false);
  const [isResulModalOpen, setIsResulModalOpen] = useState(false);
  const [isLotModalOpen, setIsLotModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemFilter, setItemFilter] = useState({});
  const [publishLoader, setPublishLoader] = useState(false);
  const [auctionThemePayload, setAuctionThemePayload] = useState({
    page: 1,
    sort: getColumnDetail("description_en", "description_pt"),
    limit: DROPDOWN_DEFAULT_LIMIT,
  });
  const [locationSearchLoader, setLocationSearchLoader] = useState(false);
  const [auctionLocationPayload, setAuctionLocationPayload] = useState({
    page: 1,
    sort: getColumnDetail("location_name_en", "location_name_pt"),
    limit: DROPDOWN_DEFAULT_LIMIT,
  });
  const [auctionTypeSearchLoader, setAuctionTypeSearchLoader] = useState(false);
  const [auctionTypePayload, setAuctionTypePayload] = useState({
    page: 1,
    sort: "auction_type",
    limit: DROPDOWN_DEFAULT_LIMIT,
  });
  const [auctionCollaboratorsSearchLoader, setAuctionCollaboratorsSearchLoader] = useState(false);
  const [auctionCollaboratorsPayload, setAuctionCollaboratorsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
  });

  // to handle the collaborators search
  const handleCollaboratorsSearch = (value) => {
    setAuctionCollaboratorsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for collaborators search
  const collaboratorsSearchDebounce = useMemo(() => {
    return debounce(handleCollaboratorsSearch, DEBOUNCE_TIME);
  }, []);

  // handler for calling listing collaborators
  const handleGetCollaboratorsList = useCallback(
    (collaboratorsPayload) => {
      setAuctionCollaboratorsSearchLoader(true);
      dispatch(getAuctionCollaborators(collaboratorsPayload))
        .then((res) => {
          setAuctionCollaboratorsSearchLoader(false);
        })
        .catch((error) => {
          setAuctionCollaboratorsSearchLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCollaboratorsList(auctionCollaboratorsPayload);
  }, [handleGetCollaboratorsList, auctionCollaboratorsPayload]);

  useEffect(() => {
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "shipping" },
      })
    );
  }, [dispatch]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      auction_no: props?.isEditable && auctionData?.auction_no ? auctionData?.auction_no : "",
      title_pt: props?.isEditable ? auctionData?.title_pt || "" : "",
      title_en: props?.isEditable ? auctionData?.title_en || "" : "",
      slug: props?.isEditable ? auctionData?.slug : "",
      auction_theme: props?.isEditable && auctionData?.auction_theme?.id ? auctionData?.auction_theme : "",
      buyer_premium: props?.isEditable ? auctionData?.buyer_premium : 17,
      auction_text_pt: props?.isEditable ? auctionData?.auction_text_pt : "",
      auction_location: props?.isEditable && auctionData?.auction_location?.id ? auctionData?.auction_location : "",
      // auction_type: props?.isEditMode && auctionData?.auction_type ? auctionData?.auction_type : AUCTION_TYPE_OPTION[0]?.value ,
      auction_type: props?.isEditable && auctionData?.auction_type?.id ? auctionData?.auction_type : "",
      auction_start_date: props?.isEditable && auctionData?.auction_start_date ? auctionData?.auction_start_date : "",
      auction_end_date: props?.isEditable && auctionData?.auction_end_date ? auctionData?.auction_end_date : "",
      exhibition_start_date:
        props?.isEditable && auctionData?.exhibition_start_date ? auctionData?.exhibition_start_date : "",
      exhibition_end_date:
        props?.isEditable && auctionData?.exhibition_end_date ? auctionData?.exhibition_end_date : "",
      consignment_deadline_date:
        props?.isEditable && auctionData?.consignment_deadline_date ? auctionData?.consignment_deadline_date : "",
      auction_text_en: props?.isEditable ? auctionData?.auction_text_en : "",
      publish_auction: props?.isEditable ? auctionData?.publish_auction : false,
      publish_results_automatically: props?.isEditable ? auctionData?.publish_results_automatically : false,
      pause_auction: props?.isEditable ? auctionData?.is_pause : false,
      sessions: props?.isEditable ? auctionData?.sessions : [],
      publish_lots: props?.isEditable ? auctionData?.publish_lots : false,
      auction_collaborators: props?.isEditable ? auctionData?.auction_collaborators?.map((item) => item?.id) : [],
      catalogues_shipping: props?.isEditable ? auctionData?.catalogues_shipping?.map((item) => item?.id) : [],
    },
    validationSchema: Yup.object({
      auction_no: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.auctionNo")}`),
      title_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.title")}`
      ),
      title_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.title")}`
      ),
      auction_theme: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.theme")}`
      ),
      buyer_premium: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.buyer'sPremium")}`),
      auction_location: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.location")}`
      ),
      auction_type: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.type")}`
      ),
      auction_start_date: Yup.date()
        .typeError(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.select"),
            field: props.t("common.Date&Time"),
          })
        )
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.Date&Time")}`)
        .test(
          "minimum-datetime",
          props.t("validation.dateMin", {
            label: props.t("common.exhibitionEndDate"),
          }),
          (value) => {
            if (!validation?.values?.exhibition_end_date) {
              return true;
            }
            return value > new Date(validation?.values?.exhibition_end_date);
          }
        ),
      auction_end_date: Yup.date()
        .typeError(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.select"),
            field: props.t("common.Date&Time"),
          })
        )
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.Date&Time")}`)
        .test(
          "minimum-datetime",
          props.t("validation.dateMin", {
            label: props.t("common.auctionStartDateTime"),
          }),
          (value) => {
            if (!validation?.values?.auction_start_date) {
              return true;
            }
            return value > new Date(validation?.values?.auction_start_date);
          }
        ),
      exhibition_start_date: Yup.date().when("auction_type", {
        is: (value) => value?.auction_type === "Online",
        then: Yup.date().notRequired(),
        otherwise: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.Date&Time"),
            })
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.Date&Time")}`),
      }),
      exhibition_end_date: Yup.date().when("auction_type", {
        is: (value) => value?.auction_type === "Online",
        then: Yup.date().notRequired(),
        otherwise: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.Date&Time"),
            })
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.Date&Time")}`)
          .test(
            "minimum-datetime",
            props.t("validation.dateMin", {
              label: props.t("common.exhibitionStartDate"),
            }),
            (value) => {
              if (!validation?.values?.exhibition_start_date) {
                return true;
              }
              return value > new Date(validation?.values?.exhibition_start_date);
            }
          ),
      }),
      consignment_deadline_date: Yup.date().test(
        "maximum-year",
        props.t("validation.yearMax", {
          label: `9999`,
        }),
        (value) => {
          if (value) {
            return value < moment(MAX_DATE);
          } else {
            return true;
          }
        }
      ),
      auction_collaborators: Yup.array().min(
        1,
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.auctionCollaborators")}`
      ),
      sessions: Yup.array().of(
        Yup.object().shape({
          date: Yup.date()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.select"),
                field: props.t("common.date"),
              })
            )
            .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`),
          hour: Yup.string().required(
            `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.hour")}`
          ),
          first_lot: Yup.number()
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .test(
              "last_lot",
              props.t("validation.numberMaxStrict", {
                totalNumber: props.t("common.lastLot"),
              }),
              (value, index) => {
                if (validation?.values?.sessions?.[index?.options?.index]?.last_lot) {
                  return (value || 0) < (validation?.values?.sessions?.[index?.options?.index]?.last_lot || 0);
                }
                return true;
              }
            )
            .test(
              "last_lot",
              `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.firstLot")} `,
              (value, index) => {
                if (value >= 0 && value !== "") {
                  return true;
                }
                return (validation?.values?.sessions[index?.options?.index].last_lot || 0) <= 0;
              }
            )
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.firstLot")}`),
          last_lot: Yup.number()
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .test(
              "first_lot",
              props.t("validation.numberMinStrict", {
                totalNumber: props.t("common.firstLot"),
              }),
              (value, index) => {
                if (validation?.values?.sessions?.[index?.options?.index]?.first_lot) {
                  return (validation?.values?.sessions?.[index?.options?.index]?.first_lot || 0) < (value || 0);
                }
                return true;
              }
            )
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.lastLot")}`),
        })
      ),
      catalogues_shipping: Yup.array()
        .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.tagType")}`)
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.tagType")}`),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        exhibition_start_date: values?.exhibition_start_date || null,
        exhibition_end_date: values?.exhibition_end_date || null,
        auction_theme: values?.auction_theme?.id || null,
        auction_location: values?.auction_location?.id || null,
        auction_type: values?.auction_type?.id || null,
        auction_photo: photo?.[0] || "",
        auction_calendar: calendarPhoto?.[0] || "",
        show_exhibition_date: showingDateToggler === "exhibition",
        show_consignment_date: showingDateToggler === "consignment",
        // auction_collaborators:
        //   values?.auction_collaborators?.length > 0
        //     ? values?.auction_collaborators?.map(
        //         (collaborator) => collaborator?.id
        //       )
        //     : [],
        is_pause: values?.pause_auction || false,
      };

      if (props?.isEditable) {
        // edit auction
        setInfoSaveLoader(true);
        dispatch(updateAuction(auctionData?.id, payload))
          .then((res) => {
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      } else {
        // add auction
        setInfoSaveLoader(true);
        dispatch(addAuction(payload))
          .then((res) => {
            if (res) {
              history.push(`/auctions/edit/${res?.id}`);
            }
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      }
    },
  });

  const handleGetTagTypesValue = () => {
    return validation.values.catalogues_shipping
      ?.map((id) => {
        const existingItem = shippingOptionList?.find((item) => item?.id === id);
        if (existingItem) {
          return {
            label: getColumnDetail(existingItem?.name_en, existingItem?.name_pt),
            value: existingItem?.id,
          };
        }
      })
      ?.filter((item) => Boolean(item?.value));
  };
  const handleGetCollaboratorValue = () => {
    return validation.values.auction_collaborators
      ?.map((id) => {
        const existingItem = COLLABORATORS_OPTIONS?.find((item) => item?.id === id);
        if (existingItem) {
          return {
            label: existingItem?.name,
            value: existingItem?.id,
          };
        }
      })
      ?.filter((item) => Boolean(item?.value));
  };
  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key) => {
    if (checkSelectAllSelected(selectedItem)) {
      validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value)
      );
    } else {
      if (selectedItem.length > 0) {
        validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        validation.setFieldValue(key, []);
      }
    }
  };

  const handleResetInfoForm = () => {
    if (
      (auctionData?.auction_photo
        ? JSON.stringify(auctionData?.auction_photo) !== JSON.stringify(photo?.[0])
        : photo.length === 1) ||
      (auctionData?.auction_calendar
        ? JSON.stringify(auctionData?.auction_calendar) !== JSON.stringify(calendarPhoto?.[0])
        : calendarPhoto.length === 1) ||
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        if (props?.isEditable) {
          if (auctionData?.auction_photo) {
            setPhoto([auctionData?.auction_photo]);
          }
          if (auctionData?.auction_calendar) {
            setCalendarPhoto([auctionData?.auction_calendar]);
          }
        } else {
          setPhoto([]);
          setCalendarPhoto([]);
        }
      }
    }
  };

  //to set the auction photo
  useEffect(() => {
    if (props?.isEditable) {
      if (auctionData?.auction_calendar) {
        setCalendarPhoto([auctionData?.auction_calendar]);
      }
      if (auctionData?.auction_photo) {
        setPhoto([auctionData?.auction_photo]);
      }
      setIsPhotoSet(true);
      setShowingDateToggler(() => {
        if (auctionData?.show_exhibition_date) return "exhibition";
        if (auctionData?.show_consignment_date) return "consignment";
      });
    }
  }, [props?.isEditable, auctionData]);

  // const handle auction theme search
  const handleThemeSearch = (value) => {
    setAuctionThemePayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("description_en__icontains", "description_pt__icontains")]: value,
      },
    }));
  };
  const themeSearchDebounce = useMemo(() => {
    return debounce(handleThemeSearch, DEBOUNCE_TIME);
  }, []);

  // const handle auction location search
  const handleLocationSearch = (value) => {
    setAuctionLocationPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("location_name_en__icontains", "location_name_pt__icontains")]: value,

        // getColumnDetail("location_name__icontains_en","location_name__icontains_pt") : value,
        //location_name__icontains: value,
      },
    }));
  };
  const locationSearchDebounce = useMemo(() => {
    return debounce(handleLocationSearch, DEBOUNCE_TIME);
  }, []);

  // const handle auction type search
  const handleAuctionTypeSearch = (value) => {
    setAuctionTypePayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        auction_type__icontains: value,
      },
    }));
  };
  const auctionTypeSearchDebounce = useMemo(() => {
    return debounce(handleAuctionTypeSearch, DEBOUNCE_TIME);
  }, []);

  // reset selectedLot states
  const handleResetLotModalState = () => {
    setIsAllSelected(false);
    setSelectedRows([]);
    setItemFilter({});
  };

  // result modal open
  const handleResultModalOpen = () => {
    setIsResulModalOpen(true);
  };

  // result modal close
  const handleResultModalClose = () => {
    handleResetLotModalState();
    setIsResulModalOpen(false);
  };

  // open lot modal for selecting lots
  const handleChooseBtn = () => {
    handleResetLotModalState();
    setIsLotModalOpen(true);
  };

  // choose lot modal close
  const handleLotModalClose = () => {
    setIsLotModalOpen(false);
  };

  // handle selecting lots
  const handleSelectingLots = (isAllSelected, selectedRows, itemFilter) => {
    setIsAllSelected(isAllSelected);
    setSelectedRows(selectedRows);
    setItemFilter(itemFilter);
  };

  // handle publish button
  const handlePublishBtn = () => {
    if (id && (isAllSelected || selectedRows.length > 0)) {
      // publish lots
      const payload = {
        filter: itemFilter,
        lot_ids: selectedRows,
        is_selected_all: isAllSelected,
      };
      setPublishLoader(true);
      dispatch(UpdateLotPublishStatus(id, payload))
        .then((res) => {
          if (res) {
            // success
            handleResetLotModalState();
            setIsResulModalOpen(false);
          } else {
            // failure
          }
          setPublishLoader(false);
        })
        .catch((error) => {
          setPublishLoader(false);
        });
    }
  };

  // to clear all the debounce
  useEffect(() => {
    return () => {
      themeSearchDebounce.cancel();
      locationSearchDebounce.cancel();
      auctionTypeSearchDebounce.cancel();
      collaboratorsSearchDebounce.cancel();
    };
  }, [themeSearchDebounce, locationSearchDebounce, auctionTypeSearchDebounce, collaboratorsSearchDebounce]);

  // to get the list of auction themes
  useEffect(() => {
    setThemeSearchLoader(true);
    dispatch(getAuctionTheme(auctionThemePayload)).then(() => {
      setThemeSearchLoader(false);
    });
  }, [auctionThemePayload, dispatch]);

  // to get the list of auction types
  useEffect(() => {
    setAuctionTypeSearchLoader(true);
    dispatch(getAuctionTypes(auctionTypePayload)).then(() => {
      setAuctionTypeSearchLoader(false);
    });
  }, [auctionTypePayload, dispatch]);

  // to get the list of auction locations
  useEffect(() => {
    setLocationSearchLoader(true);
    dispatch(getLocations(auctionLocationPayload)).then(() => {
      setLocationSearchLoader(false);
    });
  }, [auctionLocationPayload, dispatch]);

  // to set the dirty form for the info setction.
  useEffect(() => {
    if (props?.setIsInfoFormDirty) props.setIsInfoFormDirty(validation?.dirty);
  }, [validation?.dirty]);

  useEffect(() => {
    if (!id) {
      getAuctionSettings();
    }
  }, []);

  const getAuctionSettings = async () => {
    setInfoLoader(true);
    try {
      const res = await API.get(`/auctions/settings`);
      if (res && res.status && res.status === 200) {
        validation.setFieldValue("buyer_premium", +res?.data?.data?.buyer_premium || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInfoLoader(false);
    }
  };

  return (
    <>
      <Prompt
        when={
          !validation?.isSubmitting &&
          isPhotoSet &&
          ((auctionData?.auction_photo && id
            ? JSON.stringify(auctionData?.auction_photo) !== JSON.stringify(photo?.[0])
            : photo.length === 1) ||
            (auctionData?.auction_calendar && id
              ? JSON.stringify(auctionData?.auction_calendar) !== JSON.stringify(calendarPhoto?.[0])
              : calendarPhoto.length === 1) ||
            validation?.dirty)
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      {infoLoader ? <Loader /> : null}
      <FormikProvider value={validation}>
        <Form
          onKeyPress={onKeyPressForm}
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <CardBody className="card-section-body">
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <div className="mb-4">
                  <div className="d-flex flex-row justify-content-end gap-2">
                    <CustomButton
                      color="dark"
                      btnTitle={props.t("common.reset")}
                      disabled={infoSaveLoader}
                      onClick={handleResetInfoForm}
                    />
                    <CustomButton
                      color="primary"
                      disabled={infoSaveLoader}
                      btnTitle={props.t("common.save")}
                      type="submit"
                      isLoading={infoSaveLoader}
                      onClick={() => scrollToFirstFormikError(validation.errors)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-sm-12 col-md-12 col-xl-4">
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="auction_no" className="form-label">
                      {`${props.t("common.auctionNo")}*`}
                    </Label>
                    <NumberInput
                      name="auction_no"
                      className="form-control"
                      placeholder={`${props.t("common.enter")} ${props.t("common.auctionNo")}`}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.auction_no || ""}
                      invalid={validation.touched.auction_no && validation.errors.auction_no ? true : false}
                    />
                    {validation.touched.auction_no && validation.errors.auction_no ? (
                      <FormFeedback type="invalid">{validation.errors.auction_no}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                {!(editionType && editionType === "en") ? (
                  <Row>
                    <div className="mb-3">
                      <Label className="form-label fw-bold">
                        {`${props.t("common.title")} ${props.t("common.pt")}*`}
                      </Label>
                      <Input
                        name="title_pt"
                        className="form-control"
                        placeholder={`${props.t("common.enter")} ${props.t("common.title")}`}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title_pt || ""}
                        invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
                      />
                      {validation.touched.title_pt && validation.errors.title_pt ? (
                        <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                ) : null}
                {!(editionType && editionType === "pt") ? (
                  <Row>
                    <div className="mb-3">
                      <Label className="form-label fw-bold">
                        {`${props.t("common.title")} ${props.t("common.en")}*`}
                      </Label>
                      <Input
                        name="title_en"
                        className="form-control"
                        placeholder={`${props.t("common.enter")} ${props.t("common.title")}`}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title_en || ""}
                        invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
                      />
                      {validation.touched.title_en && validation.errors.title_en ? (
                        <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                ) : null}
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="slug" className="form-label">
                      {`${props.t("common.slug")}`}
                    </Label>
                    <Input
                      name="slug"
                      className="form-control"
                      placeholder={`${props.t("common.enter")} ${props.t("common.slug")}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.slug || ""}
                      invalid={validation.touched.slug && validation.errors.slug ? true : false}
                    />
                    {validation.touched.slug && validation.errors.slug ? (
                      <FormFeedback type="invalid">{validation.errors.slug}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="auction_theme" className="form-label">
                      {`${props.t("common.theme")}*`}
                    </Label>
                    <Select
                      isLoading={themeSearchLoader}
                      name="auction_theme"
                      options={themeSearchLoader ? [] : THEME_OPTIONS}
                      getOptionLabel={(option) =>
                        getColumnDetail(option?.description_en, option?.description_pt) || "-"
                      }
                      getOptionValue={(option) => option?.id}
                      className={`custom-select ${
                        validation.touched.auction_theme && validation.errors.auction_theme ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.theme")}`}
                      value={validation.values.auction_theme || ""}
                      onInputChange={themeSearchDebounce}
                      onChange={(data) => {
                        validation.setFieldValue("auction_theme", data?.id ? data : null);
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("auction_theme", true);
                        validation.handleBlur(e);
                      }}
                    />
                    {validation.touched.auction_theme && validation.errors.auction_theme ? (
                      <span className="custom-invalid-feedback">{validation.errors.auction_theme}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="buyer_premium" className="form-label">
                      {`${props.t("common.buyer'sPremium")}*`}
                    </Label>
                    <NumberInput
                      name="buyer_premium"
                      className="form-control"
                      placeholder={`${props.t("common.enter")} ${props.t("common.buyer'sPremium")}`}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.buyer_premium}
                      invalid={validation.touched.buyer_premium && validation.errors.buyer_premium ? true : false}
                    />
                    {validation.touched.buyer_premium && validation.errors.buyer_premium ? (
                      <FormFeedback type="invalid">{validation.errors.buyer_premium}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                {!editionType && (
                  <Row>
                    <div className="mb-3">
                      <Label htmlFor="auction_text_pt" className="form-label">
                        {`${props.t("common.auctionText")} ${props.t("common.pt")}`}
                      </Label>
                      <Input
                        name="auction_text_pt"
                        className="form-control"
                        placeholder={`${props.t("common.enter")} ${props.t("common.auctionText")}`}
                        style={{ height: "200px" }}
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auction_text_pt || ""}
                        invalid={validation.touched.auction_text_pt && validation.errors.auction_text_pt ? true : false}
                      />
                      {validation.touched.auction_text_pt && validation.errors.auction_text_pt ? (
                        <FormFeedback type="invalid">{validation.errors.auction_text_pt}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                )}
              </Col>
              <Col className="col-sm-12 col-md-12 col-xl-4">
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="auction_location" className="form-label fw-bold">
                        {`${props.t("common.location")}*`}
                      </Label>
                      <Select
                        isLoading={locationSearchLoader}
                        name="auction_location"
                        options={locationSearchLoader ? [] : LOCATION_OPTIONS}
                        // getOptionLabel={(option) =>
                        //   option?.location_name || "-"
                        // }
                        getOptionLabel={(option) =>
                          getColumnDetail(option?.location_name_en, option?.location_name_pt) || "-"
                        }
                        getOptionValue={(option) => option?.id}
                        className={`custom-select ${
                          validation.touched.auction_location && validation.errors.auction_location
                            ? "select-error"
                            : null
                        }`}
                        placeholder={`${props.t("common.select")} ${props.t("common.location")}`}
                        value={validation.values.auction_location || null}
                        onInputChange={locationSearchDebounce}
                        onChange={(data) => {
                          validation.setFieldValue("auction_location", data?.id ? data : null);
                        }}
                        onBlur={(e) => {
                          validation.setFieldTouched("auction_location", true);
                          validation.handleBlur(e);
                        }}
                      />
                      {validation.touched.auction_location && validation.errors.auction_location ? (
                        <span className="custom-invalid-feedback">{validation.errors.auction_location}</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="auction_type" className="form-label fw-bold">
                        {`${props.t("common.type")}*`}
                      </Label>
                      <Select
                        isLoading={auctionTypeSearchLoader}
                        name="auction_type"
                        options={auctionTypeSearchLoader ? [] : AUCTOIN_TYPES_OPTIONS}
                        getOptionLabel={(option) => option?.auction_type}
                        getOptionValue={(option) => option?.id}
                        className={`custom-select ${
                          validation.touched.auction_type && validation.errors.auction_type ? "select-error" : ""
                        }`}
                        placeholder={`${props.t("common.select")} ${props.t("common.type")}`}
                        value={validation.values.auction_type || null}
                        onInputChange={auctionTypeSearchDebounce}
                        onChange={(data) => {
                          if (data?.auction_type === "Online") {
                            validation.setFieldValue("publish_results_automatically", true);
                          } else {
                            validation.setFieldValue("publish_results_automatically", false);
                          }
                          validation.setFieldValue("auction_type", data?.id ? data : "");
                        }}
                        onBlur={(e) => {
                          validation.setFieldTouched("auction_type", true);
                          validation.handleBlur(e);
                        }}
                      />
                      {validation.touched.auction_type && validation.errors.auction_type ? (
                        <span className="custom-invalid-feedback">{validation.errors.auction_type}</span>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="auction_start_date" className="form-label fw-bold">
                        {`${props.t("common.auctionStartDateTime")}*`}
                      </Label>
                      <DateTimePicker
                        date={validation?.values?.auction_start_date || ""}
                        minDate={validation?.values?.exhibition_end_date}
                        onChange={(date) => validation.setFieldValue("auction_start_date", date)}
                        invalid={
                          validation.touched.auction_start_date && validation.errors.auction_start_date ? true : false
                        }
                      />
                      {validation.touched.auction_start_date && validation.errors.auction_start_date ? (
                        <span className="custom-invalid-feedback">{validation.errors.auction_start_date}</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="auction_end_date" className="form-label fw-bold">
                        {`${props.t("common.auctionEndDateTime")}*`}
                      </Label>
                      <DateTimePicker
                        date={validation.values.auction_end_date || ""}
                        minDate={validation.values?.auction_start_date}
                        onChange={(date) => validation.setFieldValue("auction_end_date", date)}
                        invalid={
                          validation.touched.auction_end_date && validation.errors.auction_end_date ? true : false
                        }
                      />
                      {validation.touched.auction_end_date && validation.errors.auction_end_date ? (
                        <span className="custom-invalid-feedback">{validation.errors.auction_end_date}</span>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="consignment_deadline_date" className="form-label">
                      {`${props.t("common.consignmentDeadlineDate")}`}
                    </Label>
                    <Input
                      name="consignment_deadline_date"
                      className="form-control"
                      placeholder={`${props.t("common.enter")} ${props.t("common.consignmentDeadlineDate")}`}
                      type="date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.consignment_deadline_date}
                      invalid={
                        validation.touched.consignment_deadline_date && validation.errors.consignment_deadline_date
                          ? true
                          : false
                      }
                    />
                    {validation.touched.consignment_deadline_date && validation.errors.consignment_deadline_date ? (
                      <FormFeedback type="invalid">{validation.errors.consignment_deadline_date}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="exhibition_start_date" className="form-label">
                        {`${props.t("common.exhibitionStartDate")}${
                          validation?.values?.auction_type?.auction_type !== "Online" ? "*" : ""
                        }`}
                      </Label>
                      <DateTimePicker
                        date={validation.values.exhibition_start_date || ""}
                        onChange={(date) => validation.setFieldValue("exhibition_start_date", date)}
                        invalid={
                          validation.touched.exhibition_start_date && validation.errors.exhibition_start_date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.exhibition_start_date && validation.errors.exhibition_start_date ? (
                        <span className="custom-invalid-feedback">{validation.errors.exhibition_start_date}</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="exhibition_end_date" className="form-label">
                        {`${props.t("common.exhibitionEndDate")}${
                          validation?.values?.auction_type?.auction_type !== "Online" ? "*" : ""
                        }`}
                      </Label>
                      <DateTimePicker
                        date={validation.values.exhibition_end_date || ""}
                        minDate={validation.values?.exhibition_start_date}
                        onChange={(date) => validation.setFieldValue("exhibition_end_date", date)}
                        invalid={
                          validation.touched.exhibition_end_date && validation.errors.exhibition_end_date ? true : false
                        }
                      />
                      {validation.touched.exhibition_end_date && validation.errors.exhibition_end_date ? (
                        <span className="custom-invalid-feedback">{validation.errors.exhibition_end_date}</span>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <div className="mb-3">
                      <Label htmlFor="show_exhibition_date" className="form-label">
                        {`${props.t("common.showExhibitionDate")}`}
                      </Label>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          role="switch"
                          className="form-check-input"
                          onChange={(event) => {
                            if (event?.target?.checked) {
                              setShowingDateToggler("exhibition");
                            } else {
                              setShowingDateToggler("");
                            }
                          }}
                          checked={showingDateToggler === "exhibition"}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-3">
                    <div>
                      <Label htmlFor="show_consignment_date" className="form-label">
                        {`${props.t("common.showConsignmentDate")}`}
                      </Label>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          role="switch"
                          className="form-check-input"
                          onChange={(event) => {
                            if (event?.target?.checked) {
                              setShowingDateToggler("consignment");
                            } else {
                              setShowingDateToggler("");
                            }
                          }}
                          checked={showingDateToggler === "consignment"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {!editionType && (
                  <Row>
                    <div className="mb-3">
                      <Label htmlFor="auction_text_en" className="form-label">
                        {props.t("common.auctionText")}
                      </Label>
                      <Input
                        name="auction_text_en"
                        className="form-control"
                        placeholder={`${props.t("common.enter")} ${props.t("common.auctionText")}`}
                        style={{ height: "200px" }}
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auction_text_en || ""}
                        invalid={validation.touched.auction_text_en && validation.errors.auction_text_en ? true : false}
                      />
                      {validation.touched.auction_text_en && validation.errors.auction_text_en ? (
                        <FormFeedback type="invalid">{validation.errors.auction_text_en}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                )}
              </Col>
              <Col className="col-sm-12 col-md-12 col-xl-4">
                <Row>
                  <Col className="mb-3">
                    <div className="mb-3">
                      <CustomButton
                        color={validation?.values?.publish_auction ? "danger" : "primary"}
                        type="button"
                        btnTitle={
                          validation?.values?.publish_auction
                            ? `${props.t("common.unPublish")} ${props.t("common.auction")}`
                            : `${props.t("common.publish")} ${props.t("common.auction")}`
                        }
                        classes="w-100"
                        onClick={() => {
                          toast.info(
                            props.t("information.actionAfterSave", {
                              module: props.t("common.info"),
                            })
                          );
                          validation?.setFieldValue("publish_auction", !validation?.values?.publish_auction);
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3">
                    <div>
                      <Label htmlFor="publish_results_automatically" className="form-label">
                        {`${props.t("common.publishResultsAutomatically")}`}
                      </Label>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          role="switch"
                          name="publish_results_automatically"
                          className="form-check-input"
                          onChange={validation.handleChange}
                          checked={validation.values.publish_results_automatically}
                        />
                      </div>
                    </div>
                  </Col>
                  {props?.isEditable && auctionData?.auction_type?.auction_type === "Online" ? (
                    <>
                      <Col className="mb-3">
                        <div>
                          <Label htmlFor="pause_auction" className="form-label">
                            {`${props.t("common.pauseAuction")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              name="pause_auction"
                              className="form-check-input"
                              onChange={validation.handleChange}
                              checked={validation.values.pause_auction}
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="mb-3">
                  <Col>
                    <CustomButton
                      color={validation?.values?.publish_lots ? "danger" : "primary"}
                      type="button"
                      btnTitle={
                        validation?.values?.publish_lots
                          ? props.t("common.unPublishLots")
                          : props.t("common.publishLots")
                      }
                      classes="w-100"
                      onClick={() => {
                        toast.info(
                          props.t("information.actionAfterSave", {
                            module: props.t("common.info"),
                          })
                        );
                        validation?.setFieldValue("publish_lots", !validation?.values?.publish_lots);
                      }}
                    />
                  </Col>
                  <Col>
                    <CustomButton
                      disabled={!validation?.values?.publish_results_automatically}
                      type="button"
                      btnTitle={props.t("common.results")}
                      classes="w-100"
                      onClick={handleResultModalOpen}
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="auction_collaborators" className="form-label">
                      {props.t("common.auctionCollaborators") + "*"}
                    </Label>
                    <Select
                      name="auction_collaborators"
                      isLoading={auctionCollaboratorsSearchLoader}
                      options={[SELECT_ALL_OPTION, ...getLabelValueArray(COLLABORATORS_OPTIONS, "id", "name")]}
                      className={`custom-select custom-multi-select ${
                        validation.touched.auction_collaborators && validation.errors.auction_collaborators
                          ? "select-error"
                          : ""
                      }`}
                      value={validation?.values?.auction_collaborators?.length > 0 ? handleGetCollaboratorValue() : []}
                      placeholder={`${props.t("common.select")} ${props.t("common.auctionCollaborators")}`}
                      onChange={(item) => {
                        handleMultiSelectDropdown(
                          item,
                          getLabelValueArray(COLLABORATORS_OPTIONS, "id", "name"),
                          "auction_collaborators"
                        );
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("auction_collaborators", true);
                        validation.handleBlur(e);
                      }}
                      isMulti={true}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      // options={
                      //   auctionCollaboratorsSearchLoader
                      //     ? []
                      //     : [SELECT_ALL_OPTION_COLLABORATORS,...COLLABORATORS_OPTIONS]
                      // }

                      // getOptionLabel={(option) => option?.name}
                      // getOptionValue={(option) => option?.id}

                      //value={validation.values.auction_collaborators || null}

                      // onInputChange={collaboratorsSearchDebounce}
                    />
                    {validation.touched.auction_collaborators && validation.errors.auction_collaborators ? (
                      <span className="custom-invalid-feedback">{validation.errors.auction_collaborators}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="catalogues_shipping" className="form-label">
                      {props.t("common.tagType") + "*"}
                    </Label>
                    <Select
                      name="catalogues_shipping"
                      options={[
                        SELECT_ALL_OPTION,
                        ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                      ]}
                      className={`custom-select custom-multi-select ${
                        validation.touched.catalogues_shipping && validation.errors.catalogues_shipping
                          ? "select-error"
                          : ""
                      }`}
                      value={validation?.values?.catalogues_shipping?.length > 0 ? handleGetTagTypesValue() : []}
                      placeholder={`${props.t("common.select")} ${props.t("common.tagType")}`}
                      onChange={(items) => {
                        handleMultiSelectDropdown(
                          items,
                          getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                          "catalogues_shipping"
                        );
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("catalogues_shipping", true);
                        validation.handleBlur(e);
                      }}
                      isMulti={true}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                    />
                    {validation.touched.catalogues_shipping && validation.errors.catalogues_shipping ? (
                      <span className="custom-invalid-feedback">{validation.errors.catalogues_shipping}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label className="form-label">{`${props.t("common.auctionPhoto")}`}</Label>
                    <CustomDropDown
                      dropzoneHight={"170px"}
                      grid={12}
                      setFiles={setPhoto}
                      files={photo}
                      multiple={false}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label className="form-label">{`${props.t("common.calendarPhoto")}`}</Label>
                    <CustomDropDown
                      dropzoneHight={"170px"}
                      grid={12}
                      setFiles={setCalendarPhoto}
                      files={calendarPhoto}
                      multiple={false}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="form-label">{props.t("common.sessions")}</Label>
                <SessionsForm validation={validation} />
              </div>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="d-flex flex-row justify-content-end gap-2">
              <CustomButton
                color="dark"
                btnTitle={props.t("common.reset")}
                disabled={infoSaveLoader}
                onClick={handleResetInfoForm}
              />
              <CustomButton
                color="primary"
                disabled={infoSaveLoader}
                btnTitle={props.t("common.save")}
                type="submit"
                isLoading={infoSaveLoader}
                onClick={() => scrollToFirstFormikError(validation.errors)}
              />
            </div>
          </CardFooter>
        </Form>
      </FormikProvider>
      {/* to add lots */}
      <PublishLotModal
        isModalOpen={isLotModalOpen}
        onCancel={handleLotModalClose}
        onSave={handleSelectingLots}
        loader={publishLoader}
      />
      <ResultModal
        isModalOpen={isResulModalOpen}
        onCloseBtnHandler={handleResultModalClose}
        title={`${props?.t("common.confirm")} ${props?.t("common.modal")}`}
        body={props?.t("confirmation.publichConfirmation")}
        onChooseBtnHandler={handleChooseBtn}
        isAllselected={isAllSelected}
        selectedRows={selectedRows}
        onConfirmBtnHandler={handlePublishBtn}
      />
    </>
  );
};

export default withTranslation()(InfoForm);
