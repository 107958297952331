import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddAuctionTypeModal = ({ auctionType, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      code: auctionType?.code || "",
      auction_type: auctionType?.auction_type || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .max(5, t("common.validataionMessageForMax5Chars"))
        .required(
          t("common.requiredValidationMsg", {
            field: `${t("common.code")}`,
          })
        ),
      auction_type: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.description")}`,
        })
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {`${
          auctionType?.id
            ? t("common.editTitle", {
                module: `${t("settings.auctions.auctionTypes.auctionType")} `,
              })
            : t("common.addTitle", {
                module: `${t("settings.auctions.auctionTypes.auctionType")} `,
              })
        } `}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="code" className="form-label">
              {t("common.code")}
            </Label>
            <Input
              name="code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.auctions.auctionTypes.auctionType")} ${t(
                "common.code"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={validation.touched.code && validation.errors.code ? true : false}
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="auction_type"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.auctions.auctionTypes.auctionType")} ${t(
                "common.description"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.auction_type || ""}
              invalid={validation.touched.auction_type && validation.errors.auction_type ? true : false}
            />
            {validation.touched.auction_type && validation.errors.auction_type ? (
              <FormFeedback type="invalid">{validation.errors.auction_type}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddAuctionTypeModal);
