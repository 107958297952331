import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { withTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { extractNameFromUrl } from "../../helpers/utils";
import ActionButton from "./ActionButton";
import ConfirmationModal from "./ConfirmationModal";
import CustomLinkWrapper from "./CustomLinkWrapper";

const CustomDropDown = ({ multiple = true, multimedia = false, previewContainerHeight, dropzoneHight, ...props }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);

  const dropzone = useDropzone({
    multiple: multiple,
    accept: multimedia ? {} : { "image/*": [] },
  });

  const handleDeleteFile = (index) => {
    setUploadedFiles((prevState) => [...prevState.filter((file, i) => i !== index)]);
    props.setFiles((prevState) => [...prevState.filter((file, i) => i !== index)]);
  };

  // handle confirmation open
  const handleConfirmationModalOpen = (index) => {
    setImageIndex(index);
    setIsConfirmationModalOpen(true);
  };
  // handle confirmation close
  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    setImageIndex(null);
  };
  // handle confirm on confimation modal
  const handleConfirmBtn = () => {
    handleDeleteFile(imageIndex);
    if (props.default) {
      const defaultImage = uploadedFiles[props.selectedIndex];

      if (imageIndex === props.selectedIndex) {
        props.handleClick(0);
      } else {
        let temp = [...uploadedFiles.filter((file, i) => i !== imageIndex)];

        props.handleClick(
          temp.findIndex(
            (x) =>
              (typeof x === "string" ? x : x?.preview) ===
              (typeof defaultImage === "string" ? defaultImage : defaultImage?.preview)
          )
        );
      }
    }
    handleConfirmationModalClose();
  };

  //   to set the pre-filled uploaded files
  useEffect(() => {
    setUploadedFiles(props.files);
  }, [props.files]);

  //   to set the uploaded files on successful drop
  useEffect(() => {
    setUploadedFiles((prevState) => [
      ...prevState,
      ...dropzone.acceptedFiles?.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
    props.setFiles((prevState) => {
      return [
        ...prevState,
        ...dropzone.acceptedFiles?.map((item, index) => {
          return item;
        }),
      ];
    });
  }, [dropzone.acceptedFiles]);
  return (
    <>
      {(multiple || uploadedFiles.length === 0) && !props?.isDisabled ? (
        <div
          className="dropzone dz-clickable"
          {...dropzone.getRootProps({ className: `dropzone ${props.classes}` })}
          style={{ height: dropzoneHight || "230px" }}
        >
          <input {...dropzone.getInputProps()} />
          <div className="dz-message needsclick">
            <div className="mb-3">
              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
            </div>
            <h4>{props.t("placeholder.dropzone")}</h4>
          </div>
        </div>
      ) : null}
      {uploadedFiles.length > 0 ? (
        <div
          className="list-unstyled mb-0 image-preview-container mt-2"
          id="file-previews"
          style={{ maxHeight: previewContainerHeight || "312px" }}
        >
          <Row>
            {uploadedFiles?.map((item, index) => {
              return (
                <Col lg={props?.grid ? props?.grid : 3} key={index + "-file"} onClick={() => props.handleClick(index)}>
                  <Card
                    className={`mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete ${
                      props.selectedIndex === index ? "bg-soft-primary" : ""
                    }`}
                  >
                    <div className="p-2">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center gap-2">
                          {multimedia ? null : (
                            <img
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={typeof item === "string" ? extractNameFromUrl(item, "/", 5) : item?.name}
                              src={typeof item === "string" ? item : item?.preview}
                            />
                          )}
                          <CustomLinkWrapper
                            link={typeof item === "string" ? item : item?.preview}
                            title={typeof item === "string" ? extractNameFromUrl(item, "/", 5) : item?.name}
                          />
                        </div>
                        {!props?.isDisabled && (
                          <ActionButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleConfirmationModalOpen(index);
                            }}
                            tooltip={props.t("common.delete")}
                            iconClasses="ri-delete-bin-fill fs-18"
                            iconColor="red"
                            buttonId={`delete-action-${index}`}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : null}
      {/* confirmaton modal on delete photo */}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        body={props.t("confirmation.actionMessage")}
        onCloseBtnHandler={handleConfirmationModalClose}
        onConfirmBtnHandler={handleConfirmBtn}
      />
    </>
  );
};

export default withTranslation()(CustomDropDown);
