import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import CustomButton from "../../../components/common/CustomButton";
import TabsComponent from "../../../components/common/TabsComponent";
import HomePageContent from "../../../components/pages/website-management/pages/HomePageContent";
import { HOME_PAGE, TYPE_PUBLISH, TYPE_SAVE } from "../../../helpers/constants";
import { checkLinksInPagesData } from "../../../helpers/utils";
import { uploadWebsitePagesInformation } from "../../../store/website-pages/actions";

const TABS = ["pt", "en"];
const HomePage = ({ selectedPage, ...props }) => {
  const dispatch = useDispatch();
  const { info: homepageInfo } = useSelector((state) => state?.Pages);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isFormWarningModalOpen, setIsFormWarningModalOpen] = useState(false);
  const [isENFormEmpty, setIsENFormEmpty] = useState(false);
  const [isPTFormEmpty, setIsPTFormEmpty] = useState(false);
  const [carousel, setCarousel] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [banners, setBanners] = useState([]);

  const handleInfoModalOpen = () => {
    setIsInfoModalOpen(true);
  };
  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleFormWarningModalOpen = (isENFormEmpty, isPTFormEmpty) => {
    setIsFormWarningModalOpen(true);
    setIsENFormEmpty(isENFormEmpty);
    setIsPTFormEmpty(isPTFormEmpty);
  };
  const handleFormWarningModalClose = () => {
    setIsFormWarningModalOpen(false);
    setIsENFormEmpty(false);
    setIsPTFormEmpty(false);
  };

  // set carousel , blocks and banners
  useEffect(() => {
    setCarousel(homepageInfo?.carousel);
    setBlocks(homepageInfo?.block);
    setBanners(homepageInfo?.banner);
  }, [homepageInfo]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      carousel: homepageInfo?.carousel || [],
      block: homepageInfo?.block || [],
      banner: homepageInfo?.banner || [],
    },
    validationSchema: Yup.object({
      carousel: Yup.array().of(
        Yup.object().shape({
          order_no: Yup.number().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.orderNo_"),
            })
          ),
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
      block: Yup.array().of(
        Yup.object().shape({
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
      banner: Yup.array().of(
        Yup.object().shape({
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  useEffect(() => {
    setCarousel((prevState) =>
      prevState?.filter((item) => validation?.values?.carousel?.map((item) => item?.id)?.includes(item?.id))
    );
    setBlocks((prevState) =>
      prevState?.filter((item) => validation?.values?.block?.map((item) => item?.id)?.includes(item?.id))
    );
    setBanners((prevState) =>
      prevState?.filter((item) => validation?.values?.banner?.map((item) => item?.id)?.includes(item?.id))
    );
  }, [validation?.values]);

  const isFormDirty = () => {
    return (
      JSON.stringify(carousel) !== JSON.stringify(validation?.values?.carousel) ||
      JSON.stringify(blocks) !== JSON.stringify(validation?.values?.block) ||
      JSON.stringify(banners) !== JSON.stringify(validation?.values?.banner)
    );
  };

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (isFormDirty()) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        setActiveTabIndex(index);
      }
    } else {
      setActiveTabIndex(index);
    }
  };

  // to format the save and publish payload
  const formatPayload = (values, save_type) => {
    const payload = [];
    Object.keys(values).forEach((key) => {
      values?.[key]?.forEach((item) => {
        payload.push({
          ...item,
          website_page: selectedPage?.id,
          language_type: TABS[activeTabIndex],
          save_type: save_type,
          file_type: key,
          field1: item?.title,
          field2: item?.subtitle,
          link: item?.link,
          file: item?.file,
          order_no: item?.order_no,
        });
      });
    });
    return payload;
  };

  // to handle save and save&publish of images
  const handleSave = (values) => {
    if (checkLinksInPagesData(values)) {
      handleInfoModalOpen();
    }
    const payload = formatPayload(values, TYPE_SAVE);
    setSaveLoader(true);
    dispatch(uploadWebsitePagesInformation(payload, HOME_PAGE, TYPE_SAVE)).then((res) => {
      setSaveLoader(false);
    });
  };

  // handle publish
  const handlePublish = () => {
    if (isFormDirty()) {
      return toast.info(props.t("information.saveBeforePublish"));
    }
    setPublishLoader(true);
    dispatch(
      uploadWebsitePagesInformation(
        {
          website_page: selectedPage?.id,
          save_type: TYPE_PUBLISH,
        },
        HOME_PAGE,
        TYPE_PUBLISH
      )
    ).then((res) => {
      if (res?.data?.is_en_remaining || res?.data?.is_pt_remaining) {
        handleFormWarningModalOpen(res?.data?.is_en_remaining, res?.data?.is_pt_remaining);
      }
      setPublishLoader(false);
    });
  };

  // to reset form
  const handleReset = () => {
    validation.resetForm();
  };

  return (
    <div className="file-manager-content w-100 p-3 homepage-main-content">
      <Prompt when={isFormDirty()} message={props.t("confirmation.resetTabData")} />
      <TabsComponent
        controlledTabChange={true}
        activeTab={activeTabIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabData={[
          {
            label: props.t("common.pt"),
            Component: HomePageContent,
            props: {
              type: "pt",
              validation,
              website_page: selectedPage?.id,
            },
            disabled: false,
          },
          {
            label: props.t("common.en"),
            Component: HomePageContent,
            props: { type: "en", validation, website_page: selectedPage?.id },
            disabled: false,
          },
        ]}
        HeaderCompoenent={
          <div className="d-flex flex-row gap-2 ms-auto">
            <CustomButton
              disabled={publishLoader || saveLoader}
              onClick={() => {
                window.open(`${process.env.REACT_APP_NEXT_WEBSITE}/${TABS[activeTabIndex]}?preview=true`, "_blank");
              }}
              btnTitle={props.t("common.preview")}
            />
            <CustomButton
              isLoading={saveLoader}
              disabled={publishLoader}
              btnTitle={props.t("common.save")}
              onClick={() => validation?.handleSubmit()}
            />
            <CustomButton
              isLoading={publishLoader}
              disabled={saveLoader}
              btnTitle={props.t("common.publish")}
              onClick={handlePublish}
            />
            <CustomButton
              disabled={publishLoader || saveLoader}
              btnTitle={props.t("common.reset")}
              color="dark"
              onClick={handleReset}
            />
          </div>
        }
      />
      {/* Empty link popup modal */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isInfoModalOpen}
        onCloseBtnHandler={handleInfoModalClose}
        body={props.t("information.linkEmpty")}
      />
      {/* EN form empty fields */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isFormWarningModalOpen}
        onCloseBtnHandler={handleFormWarningModalClose}
        body={
          isENFormEmpty && isPTFormEmpty
            ? props.t("information.ENPTFormSaveReminder")
            : isENFormEmpty
            ? props.t("information.enFormSaveReminder")
            : isPTFormEmpty
            ? props.t("information.ptFormSaveReminder")
            : ""
        }
      />
    </div>
  );
};

export default withTranslation()(HomePage);
