import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_VAT, SET_VATs_DATA, UPDATE_VAT } from "./actionTypes";

export const getVATs = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/vats`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_VATs_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add vat
export const addVAT = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/vats`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_VAT,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update vat
export const updateVAT = (vatId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/vats/${vatId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_VAT,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete vat
export const deleteVAT =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/billings/vats/${id}`, {
        data: { ...payload },
      }).then((res) => {
        if (res && res?.status && res?.status === 200) {
          return res;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
