import { SET_TASK_DETAILS, SET_TASK_LIST } from "./actionType";

const INIT_STATE = {
  total: 0,
  data: [],
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TASK_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case SET_TASK_DETAILS:
      const existingIndex = state.data.findIndex((permission) => permission.id === action.payload.data.id);
      const updatedData = state.data;
      if (existingIndex !== -1) {
        updatedData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        data: [...updatedData],
      };
    default:
      return state;
  }
};

export default User;
