export const REPORT_PAYLOAD = [
  {
    type: "report",
    name: "company_name",
    value: "",
  },
  {
    type: "report",
    name: "full_address",
    value: "",
  },
  {
    type: "report",
    name: "telephone",
    value: "",
  },
  {
    type: "report",
    name: "website",
    value: "",
  },
  {
    type: "report",
    name: "CRC",
    value: "",
  },
  {
    type: "report",
    name: "VAT",
    value: "",
  },
  {
    type: "saft",
    name: "company_name",
    value: "",
  },
  {
    type: "saft",
    name: "country_code",
    value: "",
  },
  {
    type: "saft",
    name: "address",
    value: "",
  },
  {
    type: "saft",
    name: "telephone",
    value: "",
  },
  {
    type: "saft",
    name: "fax",
    value: "",
  },
  {
    type: "saft",
    name: "email",
    value: "",
  },
  {
    type: "saft",
    name: "website",
    value: "",
  },
  {
    type: "saft",
    name: "NIF",
    value: "",
  },
  {
    type: "saft",
    name: "CRC",
    value: "",
  },
];
