import React, { useEffect, useMemo, useState } from "react";

import { FormikProvider, useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import Select from "react-select";
import { CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import {
  ADMIN,
  COMUNICATIONS,
  DEBOUNCE_TIME,
  DROPDOWN_DEFAULT_LIMIT,
  GENERAL,
  TEXTAREA_DEFAULT_ROWS,
} from "../../../helpers/constants";
import {
  checkPermissionByRoles,
  generateExpensesData,
  getCustomerOptionLabel,
  getDateString,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import { getCustomerList } from "../../../store/customers/actions";
import { getUsers } from "../../../store/general-user-settings/action";
import { addPrecontractForm, updatePrecontractForm } from "../../../store/pre-contracts/actions";
import { getVATs } from "../../../store/vat/actions";
import CustomButton from "../../common/CustomButton";
import CustomDropDown from "../../common/CustomDropDown";
import NumberInput from "../../common/NumberInput";
import ExpensesForm from "./ExpensesForm";

const PreContractInfoForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [preContractPhotos, setPreContractPhotos] = useState([]);
  const [defaultVAT, setDefaultVAT] = useState(null);
  const precontractData = useSelector((state) => state?.PreContracts?.precontract);
  const vats = useSelector((state) => state?.VAT.data);

  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);
  const SPECIALIST_OPTIONS = useSelector((state) => state?.GeneralUserSettings?.data);

  const VAT_OPTIONS = useSelector((state) => state?.VAT.data);

  const [infoSaveLoader, setInfoSaveLoader] = useState(false);
  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    list: "basic",
    filter: {},
  });
  const [specialistSearchLoader, setSpecialistSearchLoader] = useState(false);
  const [specialistsPayload, setSpecialistsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    filter: { name__icontains: "", specialist: true },
  });
  const [vatSearchLoader, setVatSearchLoader] = useState(false);
  const [vatsPayload, setVatsPayload] = useState({
    page: 1,
    sort: "code",
    filter: {
      code__icontains: "",
    },
  });

  // to set default vat option
  useEffect(() => {
    if (vats.length > 0) {
      const vat = vats?.find((vat) => vat?.rate === 23);
      setDefaultVAT(vat || null);
    }
  }, [vats]);

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_customer__icontains: value },
    }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the user search
  const handleSpecialistSearch = (value) => {
    setSpecialistsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for user search
  const specialistSearchDebounce = useMemo(() => {
    return debounce(handleSpecialistSearch, DEBOUNCE_TIME);
  }, []);

  // handle vat search
  const handleVatSearch = (value) => {
    setVatsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_code__icontains: value },
    }));
  };
  // debounce for vat search
  const vatSearchDebounce = useMemo(() => {
    return debounce(handleVatSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
      vatSearchDebounce.cancel();
      specialistSearchDebounce.cancel();
    };
  }, [customerSearchDebounce, vatSearchDebounce, specialistSearchDebounce]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      customer: props?.isEditable && precontractData?.customer?.id ? precontractData?.customer : "",
      specialists: props?.isEditable && precontractData?.specialists?.length > 0 ? precontractData?.specialists : [],
      contract_date: props?.isEditable ? precontractData?.contract_date : new Date().toISOString().split("T")[0],
      premium: props?.isEditable ? precontractData?.premium : "",
      expenses: props?.isEditable ? generateExpensesData(precontractData?.expenses) : [],
      vat: props?.isEditable && precontractData?.vat?.id ? precontractData?.vat : defaultVAT || "",
      internal_observations: props?.isEditable ? precontractData?.internal_observations : "",
      notes: props?.isEditable ? precontractData?.notes : "",
      appraisal_title_pt: props?.isEditable ? precontractData?.appraisal_title_pt : "",
      appraisal_abstract_pt: props?.isEditable ? precontractData?.appraisal_abstract_pt : "",
      appraisal_location_pt: props?.isEditable ? precontractData?.appraisal_location_pt : "",
      appraisal_report_conditions_pt: props?.isEditable
        ? precontractData?.appraisal_report_conditions_pt
        : "A avaliação foi realizada através da observação directa dos artigos durante o dia e os valores foram determinados com base nos valores de mercado prevalecentes na altura.",
      appraisal_title_en: props?.isEditable ? precontractData?.appraisal_title_en : "",
      appraisal_abstract_en: props?.isEditable ? precontractData?.appraisal_abstract_en : "",
      appraisal_location_en: props?.isEditable ? precontractData?.appraisal_location_en : "",
      appraisal_report_conditions_en: props?.isEditable
        ? precontractData?.appraisal_report_conditions_en
        : "The appraisal was carried out by direct observation of the items in daylight and the values were determined based on market values prevailing at the time.",
    },
    validationSchema: Yup.object({
      customer: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.customerName")}`
      ),
      specialists: Yup.array().min(
        1,
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.specialists")}`
      ),
      contract_date: Yup.date()
        .typeError(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.enter"),
            field: props.t("common.date"),
          })
        )
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.date")}`),
      premium: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.premium")}`),
      expenses: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(
            `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.description")}`
          ),
          value: Yup.number()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.enter"),
                field: props.t("common.value"),
              })
            )
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.value")}`),
          currency_id: Yup.number().required(
            `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.currency")}`
          ),
        })
      ),
      vat: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.vatToApply")}`
      ),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        customer: values?.customer?.id || null,
        pre_contract_photos: preContractPhotos,
        specialists: values?.specialists?.length > 0 ? values?.specialists?.map((specialist) => specialist?.id) : [],
        vat: values?.vat?.id || null,
      };
      if (props?.isEditable) {
        // edit pre-contract
        setInfoSaveLoader(true);
        dispatch(updatePrecontractForm(precontractData?.id, payload))
          .then((res) => {
            if (res) {
              //success
            } else {
              //failure
            }
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      } else {
        // add pre-contract
        setInfoSaveLoader(true);
        dispatch(addPrecontractForm(payload))
          .then((res) => {
            if (res) {
              // success
              history.push(`/contracts/pre-contracts/edit/${res?.id}`);
            } else {
              // failure
            }
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      }
    },
  });

  // to pre-filled the appraisal photos
  useEffect(() => {
    if (precontractData?.pre_contract_photos.length > 0 && props?.isEditable) {
      setPreContractPhotos(precontractData?.pre_contract_photos);
    } else {
      setPreContractPhotos([]);
    }
  }, [props?.isEditable, precontractData]);

  const handleResetInfoForm = () => {
    if (JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
      }
    }
    // prompt before resetting item
    if (
      (precontractData?.pre_contract_photos?.length || 0) !== preContractPhotos?.length ||
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        if (precontractData?.pre_contract_photos?.length > 0 && props?.isEditable) {
          setPreContractPhotos(precontractData?.pre_contract_photos);
        } else {
          setPreContractPhotos([]);
        }
        //setDropdown();
      }
    }
  };

  // to get the list of the customers
  useEffect(() => {
    setCustomerSearchLoader(true);
    dispatch(getCustomerList(customersPayload)).then(() => {
      setCustomerSearchLoader(false);
    });
  }, [dispatch, customersPayload]);

  // to get the list of the specialists users
  useEffect(() => {
    setSpecialistSearchLoader(true);
    dispatch(getUsers(specialistsPayload)).then(() => {
      setSpecialistSearchLoader(false);
    });
  }, [dispatch, specialistsPayload]);

  // to get the list of the vats
  useEffect(() => {
    setVatSearchLoader(true);
    dispatch(getVATs(vatsPayload)).then(() => {
      setVatSearchLoader(false);
    });
  }, [dispatch, vatsPayload]);

  return (
    <>
      <Prompt
        when={
          (!validation?.isSubmitting &&
            (precontractData?.pre_contract_photos?.length || 0) !== preContractPhotos?.length) ||
          (!validation?.isSubmitting &&
            JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <Prompt
        when={
          !validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <FormikProvider value={validation}>
        <Form
          onKeyPress={onKeyPressForm}
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <CardHeader className="card-section-header">
            <span className="card-title">{props.t("common.info")}</span>
            <div className="d-flex flex-row justify-content-end gap-2">
              <CustomButton
                color="dark"
                btnTitle={props.t("common.reset")}
                disabled={infoSaveLoader}
                onClick={handleResetInfoForm}
              />
              <CustomButton
                color="primary"
                disabled={infoSaveLoader}
                btnTitle={props.t("common.save")}
                type="submit"
                isLoading={infoSaveLoader}
                onClick={() => scrollToFirstFormikError(validation.errors)}
              />
            </div>
          </CardHeader>
          <CardBody className="card-section-body">
            <Row>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="pre_contract_no" className="form-label">
                      {props.t("common.precontractNo")}
                    </Label>
                    <Input
                      disabled
                      className="form-control"
                      placeholder={props.isEditable ? precontractData?.pre_contract_no : "PC****"}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="customer" className="form-label">
                      {props.t("common.customerName") + "*"}
                    </Label>
                    <Select
                      isLoading={customerSearchLoader}
                      name="customer"
                      options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
                      getOptionLabel={(option) => getCustomerOptionLabel(option)}
                      getOptionValue={(option) => option?.id}
                      className={`custom-select  ${
                        validation.touched.customer && validation.errors.customer ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.customerName")}`}
                      value={validation.values.customer || null}
                      onInputChange={customerSearchDebounce}
                      onChange={(customer) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          validation.setFieldValue("customer", customer?.id ? customer : null);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("customer", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.customer && validation.errors.customer ? (
                      <span className="custom-invalid-feedback">{validation.errors.customer}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="specialists" className="form-label">
                      {props.t("common.specialists") + "*"}
                    </Label>
                    <Select
                      isLoading={specialistSearchLoader}
                      name="specialists"
                      isMulti
                      options={specialistSearchLoader ? [] : SPECIALIST_OPTIONS}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      className={`custom-select custom-multi-select ${
                        validation.touched.specialists && validation.errors.specialists ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.specialists")}`}
                      value={validation.values.specialists || null}
                      onInputChange={specialistSearchDebounce}
                      onChange={(specialists) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          validation.setFieldValue("specialists", specialists);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("specialists", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.specialists && validation.errors.specialists ? (
                      <span className="custom-invalid-feedback">{validation.errors.specialists}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="contract_date" className="form-label">
                      {props.t("common.date") + "*"}
                    </Label>
                    <Input
                      name="contract_date"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.date"),
                      })}`}
                      type="date"
                      max={getDateString(new Date())}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contract_date || ""}
                      invalid={validation.touched.contract_date && validation.errors.contract_date ? true : false}
                    />
                    {validation.touched.contract_date && validation.errors.contract_date ? (
                      <FormFeedback type="invalid">{validation.errors.contract_date}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="premium" className="form-label">
                      {props.t("common.premium") + "*"}
                    </Label>
                    <NumberInput
                      name="premium"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.premium"),
                      })}`}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.premium}
                      invalid={validation.touched.premium && validation.errors.premium ? true : false}
                      disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.premium && validation.errors.premium ? (
                      <FormFeedback type="invalid">{validation.errors.premium}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="vat" className="form-label">
                      {props.t("common.vatToApply") + "*"}
                    </Label>
                    <Select
                      isLoading={vatSearchLoader}
                      name="vat"
                      options={vatSearchLoader ? [] : VAT_OPTIONS}
                      getOptionLabel={(option) => option?.code + " - " + option?.rate + "%"}
                      getOptionValue={(option) => option?.id}
                      className={`custom-select ${
                        validation.touched.vat && validation.errors.vat ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.vatToApply")}`}
                      value={validation.values.vat || null}
                      onInputChange={vatSearchDebounce}
                      onChange={(vat) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          validation.setFieldValue("vat", vat?.id ? vat : null);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("vat", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.vat && validation.errors.vat ? (
                      <span className="custom-invalid-feedback">{validation.errors.vat}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="internal_observations" className="form-label">
                      {props.t("common.internalObservations")}
                    </Label>
                    <textarea
                      name="internal_observations"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.internalObservations"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.internal_observations || ""}
                      invalid={
                        validation.touched.internal_observations && validation.errors.internal_observations
                          ? true
                          : false
                      }
                      rows={5}
                    />
                    {validation.touched.internal_observations && validation.errors.internal_observations ? (
                      <FormFeedback type="invalid">{validation.errors.internal_observations}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="notes" className="form-label">
                      {props.t("common.notes")}
                    </Label>
                    <textarea
                      name="notes"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.notes"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.notes || ""}
                      invalid={validation.touched.notes && validation.errors.notes ? true : false}
                      rows={5}
                    />
                    {validation.touched.notes && validation.errors.notes ? (
                      <FormFeedback type="invalid">{validation.errors.notes}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_title_pt" className="form-label">
                      {props.t("common.appraisalTitle")}
                    </Label>
                    <Input
                      name="appraisal_title_pt"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalTitle"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_title_pt}
                      invalid={
                        validation.touched.appraisal_title_pt && validation.errors.appraisal_title_pt ? true : false
                      }
                    />
                    {validation.touched.appraisal_title_pt && validation.errors.appraisal_title_pt ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_title_pt}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_abstract_pt" className="form-label">
                      {props.t("common.appraisalAbstract")}
                    </Label>
                    <Input
                      name="appraisal_abstract_pt"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalAbstract"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_abstract_pt}
                      invalid={
                        validation.touched.appraisal_abstract_pt && validation.errors.appraisal_abstract_pt
                          ? true
                          : false
                      }
                    />
                    {validation.touched.appraisal_abstract_pt && validation.errors.appraisal_abstract_pt ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_abstract_pt}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_location_pt" className="form-label">
                      {props.t("common.appraisalLocation")}
                    </Label>
                    <Input
                      name="appraisal_location_pt"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalLocation"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_location_pt}
                      invalid={
                        validation.touched.appraisal_location_pt && validation.errors.appraisal_location_pt
                          ? true
                          : false
                      }
                    />
                    {validation.touched.appraisal_location_pt && validation.errors.appraisal_location_pt ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_location_pt}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_report_conditions_pt" className="form-label">
                      {props.t("common.appraisalReportConditions")}
                    </Label>
                    <textarea
                      name="appraisal_report_conditions_pt"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalReportConditions"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_report_conditions_pt}
                      invalid={
                        validation.touched.appraisal_report_conditions_pt &&
                        validation.errors.appraisal_report_conditions_pt
                          ? true
                          : false
                      }
                      rows={TEXTAREA_DEFAULT_ROWS}
                    />
                    {validation.touched.appraisal_report_conditions_pt &&
                    validation.errors.appraisal_report_conditions_pt ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_report_conditions_pt}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_title_en" className="form-label">
                      {`${props.t("common.appraisalTitle")}  ${props.t("common.en")}`}
                    </Label>
                    <Input
                      name="appraisal_title_en"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalTitle"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_title_en}
                      invalid={
                        validation.touched.appraisal_title_en && validation.errors.appraisal_title_en ? true : false
                      }
                    />
                    {validation.touched.appraisal_title_en && validation.errors.appraisal_title_en ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_title_en}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="premium" className="form-label">
                      {`${props.t("common.appraisalAbstract")} ${props.t("common.en")}`}
                    </Label>
                    <Input
                      name="appraisal_abstract_en"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalAbstract"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_abstract_en}
                      invalid={
                        validation.touched.appraisal_abstract_en && validation.errors.appraisal_abstract_en
                          ? true
                          : false
                      }
                    />
                    {validation.touched.appraisal_abstract_en && validation.errors.appraisal_abstract_en ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_abstract_en}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_location_en" className="form-label">
                      {`${props.t("common.appraisalLocation")} ${props.t("common.en")}`}
                    </Label>
                    <Input
                      name="appraisal_location_en"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalLocation"),
                      })}`}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_location_en}
                      invalid={
                        validation.touched.appraisal_location_en && validation.errors.appraisal_location_en
                          ? true
                          : false
                      }
                    />
                    {validation.touched.appraisal_location_en && validation.errors.appraisal_location_en ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_location_en}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_report_conditions_en" className="form-label">
                      {`${props.t("common.appraisalReportConditions")} ${props.t("common.en")}`}
                    </Label>
                    <textarea
                      name="appraisal_report_conditions_en"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.appraisalReportConditions"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appraisal_report_conditions_en}
                      invalid={
                        validation.touched.appraisal_report_conditions_en &&
                        validation.errors.appraisal_report_conditions_en
                          ? true
                          : false
                      }
                      rows={TEXTAREA_DEFAULT_ROWS}
                    />
                    {validation.touched.appraisal_report_conditions_en &&
                    validation.errors.appraisal_report_conditions_en ? (
                      <FormFeedback type="invalid">{validation.errors.appraisal_report_conditions_en}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <Label htmlFor="appraisal_photos" className="form-label">
                  {`${props.t("common.preContractPhotos")}`}
                </Label>
                <CustomDropDown setFiles={setPreContractPhotos} files={preContractPhotos} />
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="form-label">{props.t("common.expenses")}</Label>
                <ExpensesForm validation={validation} />
              </div>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="d-flex flex-row justify-content-end gap-2">
              <CustomButton
                color="dark"
                btnTitle={props.t("common.reset")}
                disabled={infoSaveLoader}
                onClick={handleResetInfoForm}
              />
              <CustomButton
                color="primary"
                disabled={infoSaveLoader}
                btnTitle={props.t("common.save")}
                type="submit"
                isLoading={infoSaveLoader}
                onClick={() => scrollToFirstFormikError(validation.errors)}
              />
            </div>
          </CardFooter>
        </Form>
      </FormikProvider>
    </>
  );
};

export default withTranslation()(PreContractInfoForm);
