import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/common/Loader";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/common/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../../helpers/api/api_interceptor";

const Settings = (props) => {
  const [loader, setLoader] = useState();
  const [existingData, setExistingData] = useState({});

  // to handle save and save&publish of images
  const handleSave = async (values) => {
    await updateAuctionSettings(values);
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      buyer_premium: existingData?.buyer_premium || 0,
    },
    validationSchema: Yup.object({
      buyer_premium: Yup.number().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.value")}`
      ),
    }),
    onSubmit: handleSave,
  });

  // to reset form
  const handleReset = () => {
    // TODO: Can't set initial value to buyer premium from API so resetForm will not work.
    // validation.resetForm();
    validation.setFieldValue("buyer_premium", existingData?.buyer_premium || 0);
  };

  // to get the my-veritas images
  useEffect(() => {
    getAuctionSettings().then((data) => setExistingData(data));
  }, []);

  useEffect(() => {
    validation.setFieldValue("buyer_premium", existingData?.buyer_premium || 0);
  }, [existingData]);

  const getAuctionSettings = async () => {
    setLoader(true);
    try {
      const res = await API.get(`/auctions/settings`);
      if (res && res.status && res.status === 200) {
        return res?.data?.data;
      } else {
        return {};
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const updateAuctionSettings = async (payload) => {
    setLoader(true);
    try {
      const res = await API.post(`/auctions/settings`, payload);
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.settings")}`;

  return (
    <div className="page-content layout-main-container">
      <Prompt when={validation?.dirty} message={props.t("sidebarMenu.settings")} />
      <Card className="container-body card-section">
        <CardHeader className="card-section-header">
          <span className="card-title">{props.t("sidebarMenu.settings")}</span>
        </CardHeader>
        <CardBody className="position-relative">
          {loader ? <Loader /> : null}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <CardBody>
              <Row>
                <Col sm={6} md={4} lg={3} xl={2}>
                  <div className="mb-3">
                    <Label htmlFor="buyer_premium" className="form-label">
                      {`${props.t("common.buyer'sPremium")}*`}
                    </Label>
                    <Input
                      name="buyer_premium"
                      className="form-control"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.buyer_premium || ""}
                      invalid={!!(validation.touched.buyer_premium && validation.errors.buyer_premium)}
                    />
                    {validation.touched.buyer_premium && validation.errors.buyer_premium ? (
                      <FormFeedback type="invalid">{validation.errors.buyer_premium}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="d-flex flex-row gap-2 justify-content-end">
                <CustomButton
                  disabled={loader}
                  type="button"
                  btnTitle={props.t("common.reset")}
                  color="dark"
                  onClick={handleReset}
                />
                <CustomButton
                  disabled={loader}
                  isLoading={loader}
                  type="button"
                  onClick={() => {
                    validation.handleSubmit();
                  }}
                  btnTitle={props.t("common.save")}
                />
              </div>
            </CardFooter>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(Settings);
