import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import CustomButton from "../../../common/CustomButton";
import { getTypeFromValue, openFileURL, scrollToFirstFormikError } from "../../../../helpers/utils";
import * as Yup from "yup";
import MessageBlock from "../../../common/MessageBlock";

const EditHighlightModal = ({ item, isModalOpen, handleModalClose, handleSave, t }) => {
  const [saveLoader, setSaveLoader] = useState(false);

  const HIGHLIGHT_MEDIA_TYPES = [
    { label: t("common.photo"), value: "photo" },
    { label: t("common.video"), value: "video" },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      position: item?.position,
      auction_lot: item?.auction_lot,
      highlight_type: item?.image_link ? "photo" : item?.video_link ? "video" : "photo",
      title: item?.title || "",
      description: item?.description || "",
      image_link: item?.image_link || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.title"),
        })}`
      ),
      description: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.description"),
        })}`
      ),
    }),
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false} className="edit-highlight-modal">
      <ModalHeader>{`${t("common.editTitle", {
        module: t("common.highlight"),
      })}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label className="form-label">{t("common.highlightType")}</Label>
            <Select
              isDisabled
              name="highlight_type"
              options={HIGHLIGHT_MEDIA_TYPES}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.type")}`}
              value={getTypeFromValue(HIGHLIGHT_MEDIA_TYPES, validation.values.highlight_type) || ""}
              onChange={(highlight_type) => {
                validation.setFieldValue("highlight_type", highlight_type?.value);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("highlight_type", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.highlight_type && validation.errors.highlight_type ? (
              <p className="custom-invalid-feedback">{validation.errors.highlight_type}</p>
            ) : null}
          </div>
          {validation?.values?.highlight_type === "photo" ? (
            <div className="mb-3">
              <Label className="form-label">{t("common.photos")}</Label>
              <div className="highlight-media-container">
                {item?.auction_lot?.contract_item?.photos?.length > 0 ? (
                  item?.auction_lot?.contract_item?.photos?.map((photoURL, index) => (
                    <div
                      key={index}
                      className="highlight-media-card"
                      onClick={() => {
                        validation?.setFieldValue("image_link", photoURL);
                      }}
                    >
                      <div
                        className="image-container"
                        style={{
                          backgroundImage: `url(${photoURL})`,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          openFileURL(photoURL, "preview");
                        }}
                      ></div>
                      <Input
                        style={{ cursor: "pointer" }}
                        readOnly
                        type="checkbox"
                        checked={photoURL === validation?.values?.image_link}
                      />
                    </div>
                  ))
                ) : (
                  <MessageBlock fontSize={"14px"} height={"fit-content"} message={t("placeholder.noPhotos")} />
                )}

                {/* <div
                className="highlight-media-card"
                style={{
                  backgroundImage: `url("https://veritas-backend.s3.eu-west-2.amazonaws.com/media/contract_images/ironman_o8VQjU9.jpg")`,
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/806725508"
                  title="test"
                />
              </div> */}
              </div>
            </div>
          ) : null}

          <div className="mb-3">
            <Label className="form-label">{t("common.title")}*</Label>
            <Input
              name="title"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.title"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={validation.touched.title && validation.errors.title ? true : false}
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">{t("common.description")}*</Label>
            <Input
              name="description"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.description"),
              })}
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            btnTitle={t("common.cancel")}
            color="dark"
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(EditHighlightModal);
