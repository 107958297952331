import React from "react";
import CustomTooltipWrapper from "./CustomTooltipWrapper";

const ColorCircle = ({ id, color, label }) => {
  return (
    <>
      <div
        id={id}
        style={{
          background: color,
          width: "18px",
          height: "18px",
          borderRadius: "50%",
        }}
      >
        <CustomTooltipWrapper target={id} tooltipBody={label} />
      </div>
    </>
  );
};

export default ColorCircle;
