import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
  DEFAULT_VAT_RATE,
} from "../../helpers/constants";
import {
  SET_COUNTRIES_OPTIONS,
  SET_CUSTOMER_PREFERENCES,
  SET_DEFAULT_CURRENCIES,
  SET_DEFAULT_DOCUMENT_TYPES,
  SET_DEFAULT_LOTS_TAGS,
  SET_DEFAULT_PAYMENT_CONDITIONS,
  SET_DEFAULT_PAYMENT_METHODS,
  SET_DEFAULT_VATS,
  SET_TEAM_POSITIONS,
} from "./actionTypes";

const INIT_STATE = {
  countriesOptions: { data: [], total: 0 },
  documentTypes: { data: [] },
  lots_tags: { data: [] },
  vats: { data: [], default: null },
  currencies: { data: [], default: null },
  paymentConditions: { data: [], default: null },
  paymentMethods: { data: [], default: null },
  teamPositions: { data: [], total: 0, nextPosition: null },
  customerPreferences: { data: [] },
};

const common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COUNTRIES_OPTIONS:
      return {
        ...state,
        countriesOptions: {
          ...state.countriesOptions,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_DEFAULT_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: {
          ...state?.documentTypes,
          data: action.payload,
        },
      };
    case SET_DEFAULT_LOTS_TAGS:
      return {
        ...state,
        lots_tags: { ...state?.lots_tags, data: action.payload },
      };
    case SET_DEFAULT_VATS:
      return {
        ...state,
        vats: {
          ...state?.vats,
          data: action.payload,
          default: action.payload.find((vat) => vat?.rate === DEFAULT_VAT_RATE),
        },
      };
    case SET_DEFAULT_CURRENCIES:
      return {
        ...state,
        currencies: {
          ...state?.currencies,
          data: action.payload,
          default: action.payload.find((currency) => currency?.code === DEFAULT_CURRENCY_CODE),
        },
      };
    case SET_DEFAULT_PAYMENT_CONDITIONS:
      return {
        ...state,
        paymentConditions: {
          ...state?.paymentConditions,
          data: action.payload,
          default: action.payload.find((paymentCondition) => paymentCondition?.name === DEFAULT_PAYMENT_CONDITION),
        },
      };
    case SET_DEFAULT_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state?.paymentMethods,
          data: action.payload,
          default: action.payload.find(
            (paymentMethods) => paymentMethods.is_default || paymentMethods?.description === DEFAULT_PAYMENT_METHOD
          ),
        },
      };
    case SET_TEAM_POSITIONS:
      return {
        ...state,
        teamPositions: {
          ...state.teamPositions,
          data: action.payload.data,
          total: action.payload.total,
          nextPosition: action.payload.nextPosition,
        },
      };
    case SET_CUSTOMER_PREFERENCES:
      return {
        ...state,
        customerPreferences: {
          ...state.customerPreferences,
          data: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default common;
