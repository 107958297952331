import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { PRIORITY_OPTIONS, STATUS_OPTIONS } from "../../../helpers/constants/selectOptions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUsers } from "../../../store/general-user-settings/action";
import { useDispatch } from "react-redux";
import { getLabelValueArray, getSingleLabelAndValueFromArray, scrollToFirstFormikError } from "../../../helpers/utils";

const AddTaskModal = ({ task, isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const userData = getLabelValueArray(
    useSelector((state) => state?.GeneralUserSettings?.data),
    "id",
    "name"
  );

  const [isGetUserLoader, setIsGetUserLoader] = useState(false);
  // selected states
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    setIsGetUserLoader(true);
    dispatch(getUsers())
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setIsGetUserLoader(false);
      })
      .catch((error) => {
        setIsGetUserLoader(false);
      });
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      description: task?.description || "",
      status: task?.status || "pending",
      priority: task?.priority || "",
      user: task?.user?.id ? getSingleLabelAndValueFromArray(userData, task?.user?.id)?.value : "",
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .required(
          t("validation.requiredValidationMsg", {
            field: `${t("common.description")}`,
          })
        ),
      status: Yup.string()
        .trim()
        .required(
          t("validation.selectValidationMsg", {
            field: `${t("common.status")}`,
          })
        ),
      priority: Yup.string()
        .trim()
        .required(
          t("validation.selectValidationMsg", {
            field: `${t("common.priority")}`,
          })
        ),
      user: Yup.string()
        .trim()
        .required(
          t("validation.selectValidationMsg", {
            field: `${t("settings.general.users.user")}`,
          })
        ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    if (!task) {
      setSelectedStatus(getSingleLabelAndValueFromArray(STATUS_OPTIONS, "pending"));
      setSelectedPriority("");
      setSelectedUser("");
      validation.resetForm();
    } else {
      setSelectedStatus(getSingleLabelAndValueFromArray(STATUS_OPTIONS, task?.status));
      setSelectedPriority(getSingleLabelAndValueFromArray(PRIORITY_OPTIONS, task?.priority));
      setSelectedUser(getSingleLabelAndValueFromArray(userData, task?.user?.id));
    }
  }, [isModalOpen, task]);

  const handleModalClose = () => {
    if (!loader) {
      setSelectedStatus("");
      setSelectedPriority("");
      setSelectedUser("");
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {task
          ? t("common.editTitle", {
              module: t("home.tasks.task"),
            })
          : t("common.addTitle", {
              module: t("home.tasks.task"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.description")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="status" className="form-label">
              {t("common.status")}
            </Label>
            <Select
              name="status"
              options={STATUS_OPTIONS}
              value={selectedStatus}
              className={`custom-select ${validation.touched.status && validation.errors.status ? "select-error" : ""}`}
              placeholder={`${t("common.select")} ${t("common.status")}`}
              onChange={(stat) => {
                setSelectedStatus(stat);
                validation.setFieldValue("status", stat?.value || "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("status", true);
                validation.handleBlur(e);
              }}
            />

            {validation.touched.status && validation.errors.status ? (
              <span className="custom-invalid-feedback">{validation.errors.status}</span>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="priority" className="form-label">
              {t("common.priority")}
            </Label>
            <Select
              name="priority"
              options={PRIORITY_OPTIONS}
              value={selectedPriority}
              className={`custom-select ${
                validation.touched.priority && validation.errors.priority ? "select-error" : ""
              }`}
              placeholder={`${t("common.select")} ${t("common.priority")}`}
              onChange={(priority) => {
                setSelectedPriority(priority);
                validation.setFieldValue("priority", priority?.value || "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("priority", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.priority && validation.errors.priority ? (
              <span className="custom-invalid-feedback">{validation.errors.priority}</span>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="user" className="form-label">
              {t("settings.general.users.user")}
            </Label>
            <Select
              name="user"
              options={userData}
              value={selectedUser}
              className={`custom-select ${validation.touched.user && validation.errors.user ? "select-error" : ""}`}
              placeholder={`${t("common.select")} ${t("settings.general.users.user")}`}
              onChange={(user) => {
                setSelectedUser(user);
                validation.setFieldValue("user", user?.value || "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("user", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.user && validation.errors.user ? (
              <span className="custom-invalid-feedback">{validation.errors.user}</span>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddTaskModal);
