import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardHeader } from "reactstrap";
import { getLotLists } from "../../../store/auctions/lotActions";
import ActionButton from "../../common/ActionButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";
import { formatAmount } from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";

const LotsView = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: lotsData, total: totalLots } = useSelector((state) => state?.Auctions?.lots);

  const [tableLoader, setTableLoader] = useState(props?.isLoading || false);
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "lot_no",
    filter: { auction: id },
  });

  // handle page change
  const handlePageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get paddles
  const handleGetPaddles = useCallback(
    (lotsPayload) => {
      setTableLoader(true);
      dispatch(getLotLists(lotsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaddles(lotsPayload);
  }, [lotsPayload, handleGetPaddles]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lotNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_lot_no__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.lot_no ? row?.lot_no + row?.lot_alpha : "-"),
      width: "208px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={
              i18next.resolvedLanguage === "en"
                ? "contract_item__title_en__icontains"
                : "contract_item__title_pt__icontains"
            }
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) =>
        i18next.resolvedLanguage === "en"
          ? row?.contract_item?.title_en
            ? row?.contract_item?.title_en
            : "-"
          : row?.contract_item?.title_pt
          ? row?.contract_item?.title_pt
          : "-",
      width: "490px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.numberOfBids")}</div>
        </div>
      ),
      selector: (row) => row?.bids_count || "-",
      width: "242px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.maximumValue")}</div>
        </div>
      ),
      selector: (row) => (row?.max_sale_value ? `${formatAmount(row?.max_sale_value)} €` : "-"),
      width: "242px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <ActionButton
            iconClasses="ri-eye-fill fs-18"
            onClick={() => {
              props.setLotNoToView(row?.lot_no.toString() + (row?.lot_alpha || ""));
              props.bidsViewRef.current.scrollIntoView({ behavior: "smooth" });
            }}
            buttonId={`tooltip-${row?.id}-view`}
            tooltip={props.t("common.view")}
          />
        </div>
      ),
      width: "208px",
      center: true,
    },
  ];

  return (
    <Card className="subcard-table-container table-light">
      {tableLoader ? <Loader /> : null}
      <CardHeader className="d-flex flex-row justify-content-between align-items-center">
        <div className="table-title">{props.t("common.lotsView")}</div>
      </CardHeader>
      <DataTable
        className="data-table"
        fixedHeader
        persistTableHead
        columns={columns}
        data={[...lotsData]}
        pagination
        paginationServer
        paginationTotalRows={totalLots}
        paginationDefaultPage={lotsPayload?.page}
        noDataComponent={<NoRecords />}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        paginationPerPage={DEFAULT_ROW_PER_PAGE}
        paginationComponentOptions={{
          rowsPerPageText: props.t("common.rowsPerPage"),
          rangeSeparatorText: props.t("common.rangeSeparator"),
        }}
      />
    </Card>
  );
};

export default withTranslation()(LotsView);
