import axios from "axios";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { decryptResponse, encryptPayload } from "./encryption_decryption";
const history = createBrowserHistory();
export const baseURL = process.env.REACT_APP_BE_ENDPOINT;

const isSecuredModule = (url) => {
  const isSecureEndpoint = url.match(/(customers|auctions|billings|contracts)\//g);
  return process.env.REACT_APP_API_ENCRYPTION_DECRYPTION === "true" && isSecureEndpoint;
};

const API = axios.create({
  baseURL,
  responseType: "json",
});

export default API;
// Axios Request interceptors
API.interceptors.request.use((req) => {
  const userToken = localStorage.getItem("token");
  if (userToken)
    req.headers = {
      Authorization: "token " + userToken.replace(/^"|"$/g, ""),
    };

  const url = req?.url;
  const securedModule = isSecuredModule(url);
  if (securedModule) {
    if (req.method === "get") {
      req.params = encryptPayload(req.params);
    }
    const formDataObject = {};
    if (req?.data instanceof FormData) {
      for (const [key, value] of req.data.entries()) {
        formDataObject[key] = value;
      }
      req.data = formDataObject;
    }
    req.data = encryptPayload(req.data);
  }
  return req;
});

API.interceptors.response.use(
  (res) => {
    const url = res?.config?.url;
    const securedModule = isSecuredModule(url);
    if (securedModule) res.data = decryptResponse(res.data);
    return res;
  },
  (err) => {
    const url = err.response?.config?.url;
    const securedModule = isSecuredModule(url);
    if (securedModule) err.response.data = decryptResponse(err.response.data);
    if (err?.response?.status === 400) {
      if (typeof err?.response?.data?.detail !== "string") {
        Object.keys(err.response.data.detail).forEach((key) => {
          Object.keys(err.response.data.detail[key]).forEach((item, index) => {
            toast.error(err?.response?.data?.detail?.[key]?.[index]);
          });
        });
      } else {
        toast.error(err?.response?.data?.detail);
      }
    }
    if (err?.response?.status === 404) {
      toast.error(err?.response?.data?.detail);
    }
    if (err?.response?.status === 422) {
      if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.length > 0) {
        err.response.data.errors.map((text, i) => {
          return Object.keys(text).forEach(function (key) {
            toast.error(err?.response?.data?.detail);
          });
        });
      } else {
        toast.error(err?.response?.data?.detail);
      }
    }
    if (err?.response?.status === 500) {
      toast.error(err?.response?.data?.detail);
    }
    if (err?.response?.status === 403) {
      toast.error(err?.response?.data?.detail);
    }
    if (err?.response?.status === 405) {
      toast.error(err?.response?.data?.detail);
    }
    if (err?.response?.status === 401) {
      toast.error(err?.response?.data?.detail);
      localStorage.removeItem("token");
      history.push("/login");
      window.location.reload();
    }
    return err;
  }
);
