import { ADD_AUCTION_THEME, DELETE_AUCTION_THEME, EDIT_AUCTION_THEME, SET_AUCTION_THEME_LIST } from "./actionType";
const INIT_STATE = {
  data: [],
  total: 0,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUCTION_THEME_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload?.total,
      };
    case ADD_AUCTION_THEME:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        total: state.total + 1,
      };
    case EDIT_AUCTION_THEME:
      const existingIndex = state.data.findIndex((auctionTheme) => auctionTheme.id === action.payload.data.id);
      const updatedAuctionThemeData = state.data;
      if (existingIndex !== -1) {
        updatedAuctionThemeData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        data: [...updatedAuctionThemeData],
      };
    case DELETE_AUCTION_THEME:
      return {
        ...state,
        data: state.data.filter((auctionThemeData) => auctionThemeData.id !== action.payload.deletedId),
        totalAuctionTheme: state.totalAuctionTheme - 1,
      };
    default:
      return state;
  }
};

export default User;
