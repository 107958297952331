import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_AUCTION_TYPE, DELETE_AUCTION_TYPE, EDIT_AUCTION_TYPE, SET_AUCTION_TYPE_LIST } from "./actionType";

export const getAuctionTypes = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/types`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_AUCTION_TYPE_LIST,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const addAuctionTypes = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/types`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_AUCTION_TYPE,
          payload: {
            data: res?.data?.data,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const editAuctionTypes =
  ({ id, values }) =>
  async (dispatch) => {
    try {
      return API.put(`/auctions/types/${id}`, values).then((res) => {
        if (res && res.status && res.status === 200) {
          dispatch({
            type: EDIT_AUCTION_TYPE,
            payload: {
              data: res?.data?.data,
            },
          });
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteAuctionTypes = (id) => async (dispatch) => {
  try {
    return API.delete(`/auctions/types/${id}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: DELETE_AUCTION_TYPE,
          payload: {
            deletedId: id,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
