import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import { createFileUrl, extractNameFromUrl, scrollToFirstFormikError } from "../../../helpers/utils";

const ImportExcelModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      excel_file: "",
    },
    validationSchema: Yup.object({
      excel_file: Yup.string().required(`${t("common.please")} ${t("common.upload")} ${t("common.excel")}`),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  const clearUploadedExcel = () => {
    validation.setFieldValue("excel_file", "");
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${t("common.import")} ${t("common.excel")}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="excel" className="form-label">
              {t("common.excel")}
            </Label>
            {validation.values.excel_file ? (
              <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                <CustomLinkWrapper
                  link={
                    typeof validation.values.excel_file === "string"
                      ? validation.values.excel_file
                      : createFileUrl(validation.values.excel_file)
                  }
                  title={
                    typeof validation.values.excel_file === "string"
                      ? extractNameFromUrl(validation.values.excel_file, "/", 5)
                      : validation.values.excel_file.name
                  }
                />
                <i className="ri-close-fill fs-18 cursor-pointer text-danger" onClick={clearUploadedExcel} />
              </div>
            ) : (
              <>
                <Input
                  lang="pt"
                  name="excel_file"
                  className="form-control"
                  placeholder={`${t("common.enter")} ${t("common.excel")}`}
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={async (e) => {
                    validation.setFieldValue("excel_file", e.target.files[0]);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.excel_file || ""}
                  invalid={validation.touched.excel_file && validation.errors.excel_file ? true : false}
                />
                {validation.touched.excel_file && validation.errors.excel_file ? (
                  <FormFeedback type="invalid">{validation.errors.excel_file}</FormFeedback>
                ) : null}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ImportExcelModal);
