import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { logoutUser } from "../store/user/actions";
import { checkPermissionByRoles } from "../helpers/utils";

// import { logoutUser } from "../store/actions";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");

  if (!userToken) {
    dispatch(logoutUser());
    return <Redirect to="/login" />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, allowedRoles, ...rest }) => {
  if (checkPermissionByRoles(allowedRoles)) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Component {...props} />
            </>
          );
        }}
      />
    );
  }

  return <Redirect to="/unauthorized" />;
};

export { AuthProtected, AccessRoute };
