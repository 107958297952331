import React, { useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, FormFeedback, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import * as Yup from "yup";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import { getCustomerOptionLabel, scrollToFirstFormikError } from "../../../helpers/utils";
import { getCustomerList } from "../../../store/customers/actions";
import NumberInput from "../../common/NumberInput";

const AddPaddleModal = ({ suggestedPaddle, paddle, isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);

  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    list: "basic",
    filter: {
      search_customer__icontains: "",
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      paddle_no: paddle?.paddle_no >= 0 ? paddle?.paddle_no : suggestedPaddle,
      customer: paddle?.customer?.id ? paddle?.customer : "",
    },
    validationSchema: Yup.object({
      paddle_no: Yup.number()
        .min(0, t("validation.numberMin", { totalNumber: 0 }))
        .required(`${t("common.please")} ${t("common.enter")} ${t("common.paddleNo")}`),
      customer: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.customer")}`),
    }),
    onSubmit: (values) => {
      onSave({ ...values, customer: values?.customer?.id || null }, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_customer__icontains: value },
    }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);
  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
    };
  }, [customerSearchDebounce]);

  // to get the list of customers
  useEffect(() => {
    setCustomerSearchLoader(true);
    dispatch(getCustomerList(customersPayload)).then((res) => {
      setCustomerSearchLoader(false);
    });
  }, [dispatch, customersPayload]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {paddle?.id
          ? t("common.editTitle", {
              module: t("common.paddle"),
            })
          : t("common.addTitle", {
              module: t("common.paddle"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="paddle_no" className="form-label">
              {`${t("common.paddleNo")}*`}
            </Label>
            <NumberInput
              name="paddle_no"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.paddleNo")}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.paddle_no}
              invalid={validation.touched.paddle_no && validation.errors.paddle_no ? true : false}
            />
            {validation.touched.paddle_no && validation.errors.paddle_no ? (
              <FormFeedback type="invalid">{validation.errors.paddle_no}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="customer" className="form-label">
              {`${t("common.customer")}*`}
            </Label>
            <Select
              isLoading={customerSearchLoader}
              name="customer"
              options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
              getOptionLabel={getCustomerOptionLabel}
              getOptionValue={(option) => option?.id}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.customer")}`}
              value={validation.values.customer || null}
              onChange={(customer) => {
                validation.setFieldValue("customer", customer?.id ? customer : "");
              }}
              onInputChange={customerSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("customer", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.customer && validation.errors.customer ? (
              <p className="custom-invalid-feedback">{validation.errors.customer}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddPaddleModal);
