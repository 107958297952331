import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";

import { ADD_DOCUMENTTYPE, DELETE_DOCUMENTTYPE, SET_DOCUMENTTYPES_DATA, UPDATE_DOCUMENTTYPE } from "./actionTypes";

export const getDocumentTypes = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/document-types`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        sort: params?.sort,
        search: params?.search,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_DOCUMENTTYPES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add document types
export const addDocumentType = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/document-types`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_DOCUMENTTYPE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update document types
export const updateDocumentType = (documentTypeId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/document-types/${documentTypeId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_DOCUMENTTYPE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete document types
export const deleteDocumentType = (documentTypeId) => async (dispatch) => {
  try {
    return API.delete(`/billings/document-types/${documentTypeId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: DELETE_DOCUMENTTYPE,
          payload: documentTypeId,
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
