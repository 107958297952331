import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddCurrencyModal = ({ currency, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      code: currency?.code || "",
      description: currency?.description || "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("settings.billing.currencies.currency")} ${t(
          "settings.billing.currencies.code"
        )}`
      ),
      description: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("settings.billing.currencies.currency")} ${t(
          "settings.billing.currencies.description"
        )}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {currency?.id ? t("settings.billing.currencies.editCurrency") : t("settings.billing.currencies.addCurrency")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="code" className="form-label">
              {t("settings.billing.currencies.code")}
            </Label>
            <Input
              name="code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.currencies.currency")} ${t(
                "settings.billing.currencies.code"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={validation.touched.code && validation.errors.code ? true : false}
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("settings.billing.currencies.description")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.currencies.currency")} ${t(
                "settings.billing.currencies.description"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddCurrencyModal);
