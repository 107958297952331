import React from "react";
import { Input } from "reactstrap";
import CustomTooltipWrapper from "./CustomTooltipWrapper";

const NumberInput = (props) => {
  return (
    <>
      {props?.tooltipId && props?.invalid && (
        <CustomTooltipWrapper target={props?.tooltipId} tooltipBody={props?.errorText} />
      )}
      <Input
        id={props?.tooltipId}
        onWheel={(e) => e.target.blur()}
        onKeyDown={(e) => {
          if (e.key === "ArrowDown" || e.key === "ArrowUp") e.preventDefault();
        }}
        type="number"
        {...props}
      />
    </>
  );
};

export default NumberInput;
