import { ADD_LOTSTAG, DELETE_LOTSTAG, SET_LOTSTAGS_LIST, UPDATE_LOTSTAG } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const LotsTags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOTSTAGS_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_LOTSTAG:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_LOTSTAG:
      const updateTagsData = state.data;
      const existingTagindex = state.data.findIndex((tag) => tag?.id === action.payload.id);
      if (existingTagindex !== -1) {
        updateTagsData[existingTagindex] = action.payload;
      }
      return {
        ...state,
        data: updateTagsData,
      };
    case DELETE_LOTSTAG:
      return {
        ...state,
        data: [...state.data.filter((tag) => tag.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default LotsTags;
