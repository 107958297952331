import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import Loader from "../../../components/common/Loader";
import PreContractInfoForm from "../../../components/pages/contracts/PreContractInfoForm";
import PreContractItems from "../../../components/pages/contracts/PreContractItems";
import { getPrecontractById } from "../../../store/pre-contracts/actions";

const PreContractForm = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [infoLoader, setInfoLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setInfoLoader(true);
      dispatch(getPrecontractById(id)).then((res) => {
        if (!res) {
          history.push(`/contracts/pre-contracts`);
        }
        setInfoLoader(false);
      });
    }
  }, [dispatch, id, history]);

  document.title = `Veritas | ${props.t("sidebarMenu.pre-contracts")}`;

  return (
    <div className="page-content layout-main-container">
      <div className="container-header">
        <CustomBreadcrumb
          data={[
            {
              label: props.t("sidebarMenu.pre-contracts"),
              link: "/contracts/pre-contracts",
            },
            {
              label: props.t(`${id ? "common.editTitle" : "common.addTitle"}`, {
                module: props.t("contracts.precontracts.precontract"),
              }),
            },
          ]}
        />
      </div>
      <Card className="container-body card-section">
        {/* info form */}
        {infoLoader ? <Loader /> : null}
        <PreContractInfoForm isEditable={Boolean(id)} />
      </Card>
      <Card className="container-body card-section pre-contracts-items-wrapper">
        {/* items section */}
        <PreContractItems isEditable={Boolean(id)} />
      </Card>
    </div>
  );
};

export default withTranslation()(PreContractForm);
