import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import CustomButton from "../../../components/common/CustomButton";
import TabsComponent from "../../../components/common/TabsComponent";
import ContactsContent from "../../../components/pages/website-management/pages/ContactsContent";
import { CONTACT_US_CONTENT, TYPE_PUBLISH, TYPE_SAVE, WHO_WE_ARE_CONTENT } from "../../../helpers/constants";
import { checkLinksInPagesData, kebabCase, uploadEditorState } from "../../../helpers/utils";
import { uploadWebsitePagesInformation } from "../../../store/website-pages/actions";

const LANGUAGE_TABS = ["pt", "en"];
const SUB_TABS = ["who_we_are", "contact_us"];

const Contacts = ({ selectedPage, ...props }) => {
  const dispatch = useDispatch();
  const { info: pageInfo } = useSelector((state) => state?.Pages);
  const [activeLanguageIndex, setActiveLanguageIndex] = useState(0);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [isFormWarningModalOpen, setIsFormWarningModalOpen] = useState(false);
  const [isENFormEmpty, setIsENFormEmpty] = useState(false);
  const [isPTFormEmpty, setIsPTFormEmpty] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [whoWeAreValues, setWhoWeAreValues] = useState();

  const handleFormWarningModalOpen = (isENFormEmpty, isPTFormEmpty) => {
    setIsFormWarningModalOpen(true);
    setIsENFormEmpty(isENFormEmpty);
    setIsPTFormEmpty(isPTFormEmpty);
  };
  const handleFormWarningModalClose = () => {
    setIsFormWarningModalOpen(false);
    setIsENFormEmpty(false);
    setIsPTFormEmpty(false);
  };

  const handleInfoModalOpen = () => {
    setIsInfoModalOpen(true);
  };
  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const informationValidation = Yup.array().of(
    Yup.object().shape({
      information_1: Yup.object()
        .required(
          props.t("validation.generalField", {
            promptType: props.t("common.enter"),
            fieldType: props.t("common.information"),
          })
        )
        .test(
          "meta",
          props.t("validation.generalField", {
            promptType: props.t("common.enter"),
            fieldType: props.t("common.information"),
          }),
          (value) => {
            if (value?.getCurrentContent().hasText()) {
              return true;
            }
            return false;
          }
        ),
    })
  );

  useEffect(() => {
    setActivePageIndex(0);
  }, [activeLanguageIndex]);

  // to store intial value
  useEffect(() => {
    if (activePageIndex === 0)
      setWhoWeAreValues({
        carousel: pageInfo?.carousel || [],
        meta_info: [
          {
            id: pageInfo?.meta_info?.[0]?.id || "",
            information_1: pageInfo?.meta_info?.[0]?.information_1 || "",
          },
        ],
      });
    else
      setWhoWeAreValues({
        banner: pageInfo?.banner?.length > 0 ? pageInfo?.banner : [{ file: "", title: "", subtitle: "", link: "" }],
        meta_info: [
          {
            id: pageInfo?.meta_info?.[0]?.id || "",
            information_1: pageInfo?.meta_info?.[0]?.information_1 || "",
          },
        ],
        departments: pageInfo?.departments?.length > 0 ? pageInfo?.departments : [],
        teams: pageInfo?.teams?.length > 0 ? pageInfo?.teams : [],
      });
  }, [pageInfo, activePageIndex]);

  const whoWeAreValidation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      carousel: pageInfo?.carousel || [],
      meta_info: [
        {
          id: pageInfo?.meta_info?.[0]?.id || "",
          information_1: pageInfo?.meta_info?.[0]?.information_1 || "",
        },
      ],
    },
    validationSchema: Yup.object({
      carousel: Yup.array().of(
        Yup.object().shape({
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}/${props.t("common.video")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
      meta_info: informationValidation,
    }),
    onSubmit: (values) => {
      handleSave(values, WHO_WE_ARE_CONTENT);
    },
  });

  const contactUsValidation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      banner: pageInfo?.banner?.length > 0 ? pageInfo?.banner : [{ file: "", title: "", subtitle: "", link: "" }],
      meta_info: [
        {
          id: pageInfo?.meta_info?.[0]?.id || "",
          information_1: pageInfo?.meta_info?.[0]?.information_1 || "",
        },
      ],
      departments: pageInfo?.departments?.length > 0 ? pageInfo?.departments : [],
      teams: pageInfo?.teams?.length > 0 ? pageInfo?.teams : [],
    },
    validationSchema: Yup.object({
      banner: Yup.array().of(
        Yup.object().shape({
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}/${props.t("common.video")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
      meta_info: informationValidation,
      departments: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.name"),
            })
          ),
          email: Yup.string().email(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.enter"),
              field: props.t("common.email"),
            })
          ),
        })
      ),
      teams: Yup.array().of(
        Yup.object().shape({
          position: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.select"),
              fieldType: props.t("common.position"),
            })
          ),
          name: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.name"),
            })
          ),
          email: Yup.string().email(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.enter"),
              field: props.t("common.email"),
            })
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      handleSave(values, CONTACT_US_CONTENT);
    },
  });

  // to format the save and publish payload
  const formatPayload = (values, save_type, page_type) => {
    const payload = [];
    if (page_type === WHO_WE_ARE_CONTENT) {
      Object.keys(values).forEach((key) => {
        if (key === "meta_info") {
          payload.push({
            id: values?.meta_info?.[0]?.id,
            website_page: selectedPage?.id,
            language_type: LANGUAGE_TABS[activeLanguageIndex],
            save_type: save_type,
            file_type: key,
            page_type: SUB_TABS[0],
            meta: JSON.stringify({
              information_1: values?.meta_info?.[0]?.information_1
                ? uploadEditorState(values?.meta_info?.[0]?.information_1)
                : "",
            }),
          });
        } else {
          values?.[key]?.forEach((item) => {
            payload.push({
              ...item,
              website_page: selectedPage?.id,
              language_type: LANGUAGE_TABS[activeLanguageIndex],
              save_type: save_type,
              file_type: key,
              page_type: SUB_TABS[0],
              field1: item?.title,
              field2: item?.subtitle,
              link: item?.link,
              file: item?.file,
            });
          });
        }
      });
    }
    if (page_type === CONTACT_US_CONTENT) {
      Object.keys(values).forEach((key) => {
        const commonData = {
          website_page: selectedPage?.id,
          language_type: LANGUAGE_TABS[activeLanguageIndex],
          save_type: save_type,
          file_type: key,
          page_type: SUB_TABS[1],
        };
        if (key === "meta_info") {
          payload.push({
            ...commonData,
            id: values?.meta_info?.[0]?.id,
            meta: JSON.stringify({
              information_1: values?.meta_info?.[0]?.information_1
                ? uploadEditorState(values?.meta_info?.[0]?.information_1)
                : "",
            }),
          });
        } else if (key === "departments") {
          values?.[key]?.forEach((item) => {
            payload.push({
              ...item,
              ...commonData,
              field1: item?.name,
              field2: item?.email,
            });
          });
        } else if (key === "teams") {
          values?.[key]?.forEach((item) => {
            payload.push({
              ...item,
              ...commonData,
              field1: item?.name,
              field2: item?.email,
              position: item?.position,
            });
          });
        } else {
          values?.[key]?.forEach((item) => {
            payload.push({
              ...item,
              ...commonData,
              field1: item?.title,
              field2: item?.subtitle,
              link: item?.link,
              file: item?.file,
            });
          });
        }
      });
    }
    return payload;
  };

  // to handle save and save&publish of images
  const handleSave = (values, page_type) => {
    if (checkLinksInPagesData(values)) {
      handleInfoModalOpen();
    }
    const payload = formatPayload(values, TYPE_SAVE, page_type);
    setSaveLoader(true);
    dispatch(uploadWebsitePagesInformation(payload, page_type, TYPE_SAVE)).then((res) => {
      setSaveLoader(false);
    });
  };

  // handle publish
  const handlePublish = () => {
    if (isFormDirty()) {
      return toast.info(props.t("information.saveBeforePublish"));
    }
    setPublishLoader(true);
    dispatch(
      uploadWebsitePagesInformation(
        {
          website_page: selectedPage?.id,
          save_type: TYPE_PUBLISH,
        },
        null,
        TYPE_PUBLISH
      )
    ).then((res) => {
      if (res?.data?.is_en_remaining || res?.data?.is_pt_remaining) {
        handleFormWarningModalOpen(res?.data?.is_en_remaining, res?.data?.is_pt_remaining);
      }
      setPublishLoader(false);
    });
  };

  // to reset form
  const handleReset = () => {
    if (activePageIndex === 0) whoWeAreValidation.resetForm();
    if (activePageIndex === 1) contactUsValidation.resetForm();
  };

  // check is form dirty
  const isFormDirty = () => {
    return (
      JSON.stringify(whoWeAreValues) !==
      JSON.stringify(activePageIndex === 0 ? whoWeAreValidation?.values : contactUsValidation?.values)
    );
  };

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (isFormDirty()) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        setActiveLanguageIndex(index);
      }
    } else {
      setActiveLanguageIndex(index);
    }
  };

  return (
    <div className="file-manager-content w-100 p-3">
      <Prompt when={isFormDirty()} message={props.t("confirmation.resetTabData")} />
      <TabsComponent
        controlledTabChange={true}
        activeTab={activeLanguageIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabData={[
          {
            label: props.t("common.pt"),
            Component: ContactsContent,
            props: {
              languageType: "pt",
              website_page: selectedPage?.id,
              activePageIndex,
              setActivePageIndex,
              whoWeAreValidation,
              contactUsValidation,
              setWhoWeAreValues,
              isFormDirty,
            },
            disabled: false,
          },
          {
            label: props.t("common.en"),
            Component: ContactsContent,
            props: {
              languageType: "en",
              website_page: selectedPage?.id,
              activePageIndex,
              setActivePageIndex,
              whoWeAreValidation,
              contactUsValidation,
              setWhoWeAreValues,
              isFormDirty,
            },
            disabled: false,
          },
        ]}
        HeaderCompoenent={
          <div className="d-flex flex-row gap-2 ms-auto">
            <CustomButton
              disabled={saveLoader || publishLoader}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_NEXT_WEBSITE}/${LANGUAGE_TABS[activeLanguageIndex]}/${kebabCase(
                    SUB_TABS[activePageIndex]
                  )}?preview=true`,
                  "_blank"
                );
              }}
              btnTitle={props.t("common.preview")}
            />
            <CustomButton
              isLoading={saveLoader}
              disabled={publishLoader}
              btnTitle={props.t("common.save")}
              onClick={() => {
                let validation;
                if (activePageIndex === 0) validation = whoWeAreValidation;
                if (activePageIndex === 1) validation = contactUsValidation;
                validation?.handleSubmit();
              }}
            />
            <CustomButton
              isLoading={publishLoader}
              disabled={saveLoader}
              btnTitle={props.t("common.publish")}
              onClick={handlePublish}
            />
            <CustomButton
              disabled={saveLoader || publishLoader}
              btnTitle={props.t("common.reset")}
              color="dark"
              onClick={handleReset}
            />
          </div>
        }
      />
      {/* Empty link popup modal */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isInfoModalOpen}
        onCloseBtnHandler={handleInfoModalClose}
        body={props.t("information.linkEmpty")}
      />
      {/* EN form empty fields */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isFormWarningModalOpen}
        onCloseBtnHandler={handleFormWarningModalClose}
        body={
          isENFormEmpty && isPTFormEmpty
            ? props.t("information.ENPTFormSaveReminder")
            : isENFormEmpty
            ? props.t("information.enFormSaveReminder")
            : isPTFormEmpty
            ? props.t("information.ptFormSaveReminder")
            : ""
        }
      />
    </div>
  );
};

export default withTranslation()(Contacts);
