import React from "react";
import { Editor } from "react-draft-wysiwyg";

const DEFAULT_TOOLBAR_OPTION = {
  options: ["inline", "blockType", "fontSize", "fontFamily", "list", "colorPicker", "link", "emoji", "history"],
};

const EditorBlock = ({ editorState, onEditorStateChange, toolbarOption, invalid, errorText, ...props }) => {
  return (
    <>
      <Editor
        editorState={editorState ? editorState : ""}
        onEditorStateChange={(value) => {
          onEditorStateChange(value);
        }}
        toolbar={toolbarOption || DEFAULT_TOOLBAR_OPTION}
        toolbarClassName={`editor-toolbar ${props?.toolbarClassName || ""}`}
        wrapperClassName={`editor-block ${props?.wrapperClassName || ""}`}
        editorClassName={`editor-wrapper ${props?.editorClassName || ""}`}
        {...props}
      />
      {invalid ? <span className="custom-invalid-feedback">{errorText}</span> : null}
    </>
  );
};

export default EditorBlock;
