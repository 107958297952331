import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_MAINLANGUAGELIST,
  GET_PERMISSIONLIST,
  GET_SPOKENLANGUAGELIST,
  GET_USERLIST,
} from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
  mainLanguageList: [],
  totalMainLanguage: 0,
  spokenLanguageList: [],
  totalSpokenLanguage: 0,
  permissionList: [],
  totalPermissions: 0,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERLIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case GET_MAINLANGUAGELIST:
      return {
        ...state,
        mainLanguageList: action.payload.data,
        totalMainLanguage: action.payload.total,
      };
    case GET_SPOKENLANGUAGELIST:
      return {
        ...state,
        spokenLanguageList: action.payload.data,
        totalSpokenLanguage: action.payload.total,
      };
    case GET_PERMISSIONLIST:
      return {
        ...state,
        permissionList: action.payload.data,
        totalPermissions: action.payload.total,
      };
    case CREATE_USER:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        total: state.total + 1,
      };
    case DELETE_USER:
      return {
        ...state,
        data: state.data.filter((userData) => userData.id !== action.payload.deletedId),
        total: state.total - 1,
      };
    case EDIT_USER:
      const existingIndex = state.data.findIndex((user) => user.id === action.payload.data.id);
      const updatedUserData = state.data;
      if (existingIndex !== -1) {
        updatedUserData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        languageList: [...updatedUserData],
      };
    default:
      return state;
  }
};

export default User;
