import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Row } from "reactstrap";
import ActionButton from "../../common/ActionButton";
import CustomAddButton from "../../common/CustomAddButton";
import MessageBlock from "../../common/MessageBlock";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getLabelValueArray, getTypeFromValue } from "../../../helpers/utils";
import { getCurrencies } from "../../../store/currencies/actions";
import DeleteModal from "../../../components/common/DeleteModal";
import NumberInput from "../../common/NumberInput";

const DEFAULT_EXPENSE_ITEM = { description: "", value: "", currency_id: "" };

const ExpensesForm = (props) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExpenseIndex, setSelectedExpenseIndex] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const currencies = useSelector((state) => state?.Currencies?.data);
  const CURRENCIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Currencies?.data),
    "id",
    "code"
  );
  const [currenciesPayload, setCurrenciesPayload] = useState({
    page: 1,
    sort: "code",
    filter: { code__icontains: "" },
  });

  // to bind the array helpers of the expense items field array(formik).
  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  // delete modal open handler
  const handleDeleteModalOpen = (index) => {
    setSelectedExpenseIndex(index);
    setIsDeleteModalOpen(true);
  };
  // delete modal close handler
  const handleDeleteModalClose = () => {
    setSelectedExpenseIndex(null);
    setIsDeleteModalOpen(false);
  };
  // handle delete
  const handleDelete = () => {
    boundArrayHelpers.remove(selectedExpenseIndex);
    handleDeleteModalClose();
  };

  // to set default currency option
  useEffect(() => {
    if (currencies.length > 0) {
      const currency = currencies?.find((currency) => currency?.code === "€");
      setDefaultCurrency(currency?.id);
    }
  }, [currencies]);

  // to get the list of the currencies
  useEffect(() => {
    dispatch(getCurrencies(currenciesPayload));
  }, [dispatch, currenciesPayload]);

  return (
    <>
      <div className="card-subtable-section table-light">
        <Row className="p-3 subtable-header subtable-row">
          <Col md={1} lg={1}>
            {props.t("common.no_")}
          </Col>
          <Col md={4} lg={4}>
            {props.t("common.description")}
          </Col>
          <Col md={3} lg={3}>
            {props.t("common.value")}
          </Col>
          <Col md={3} lg={3}>
            {props.t("common.currency")}
          </Col>
        </Row>
        <hr />
        <div className="p-3 subtable-container">
          <FieldArray
            name="expenses"
            render={(arrayHelpers) => {
              bindArrayHelpers(arrayHelpers);
              return props.validation.values.expenses?.length !== 0 ? (
                props.validation.values.expenses?.map((expense, index) => {
                  return (
                    <Row key={index} className="subtable-row mb-3">
                      <Col md={1} lg={1}>
                        <Input disabled placeholder={index + 1} />
                      </Col>
                      <Col md={4} lg={4}>
                        <Input
                          name={`expenses[${index}].description`}
                          className="form-control"
                          placeholder={`${props.t("placeholder.enterField", {
                            fieldType: props.t("common.description"),
                          })}`}
                          type="text"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.expenses?.[index]?.description}
                          invalid={
                            props.validation.touched.expenses?.[index]?.description &&
                            props.validation.errors.expenses?.[index]?.description
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.expenses?.[index]?.description &&
                        props.validation.errors.expenses?.[index]?.description ? (
                          <FormFeedback type="invalid">
                            {props.validation.errors.expenses?.[index]?.description}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={3} lg={3}>
                        <NumberInput
                          name={`expenses[${index}].value`}
                          className="form-control"
                          placeholder={`${props.t("placeholder.enterField", {
                            fieldType: props.t("common.value"),
                          })}`}
                          type="number"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.expenses?.[index]?.value}
                          invalid={
                            props.validation.touched.expenses?.[index]?.value &&
                            props.validation.errors.expenses?.[index]?.value
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.expenses?.[index]?.value &&
                        props.validation.errors.expenses?.[index]?.value ? (
                          <FormFeedback type="invalid">{props.validation.errors.expenses?.[index]?.value}</FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={3} lg={3}>
                        <Select
                          name={`expenses[${index}].currency_id`}
                          options={CURRENCIES_OPTIONS}
                          className={`custom-select ${
                            props.validation.touched.expenses?.[index]?.currency_id &&
                            props.validation.errors.expenses?.[index]?.currency_id
                              ? "select-error"
                              : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("common.currency")}`}
                          value={
                            props.validation.values.expenses?.[index]?.currency_id
                              ? getTypeFromValue(
                                  CURRENCIES_OPTIONS,
                                  props.validation.values.expenses?.[index]?.currency_id
                                )
                              : ""
                          }
                          onChange={(value) => {
                            props.validation.setFieldValue(`expenses[${index}].currency_id`, value.value);
                          }}
                          onBlur={(e) => {
                            props.validation.setFieldTouched(`expenses[${index}].currency_id`, true);
                            props.validation.handleBlur(e);
                          }}
                        />
                        {props.validation.touched.expenses?.[index]?.currency_id &&
                        props.validation.errors.expenses?.[index]?.currency_id ? (
                          <span className="custom-invalid-feedback">
                            {props.validation.errors.expenses?.[index]?.currency_id}
                          </span>
                        ) : null}
                      </Col>
                      <Col md={1} lg={1}>
                        <ActionButton
                          onClick={() => {
                            handleDeleteModalOpen(index);
                          }}
                          tooltip={props.t("common.delete")}
                          iconClasses="ri-delete-bin-fill fs-18"
                          iconColor="red"
                          buttonId={`delete-action-${index}`}
                        />
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <MessageBlock fontSize="14px" height="50px" message={props.t("information.noExpenses")} />
              );
            }}
          />
        </div>
        <hr />
        <div className="p-2 subtable-footer">
          <div className="d-flex justify-content-end">
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.expense"),
              })}
              onClick={() => {
                boundArrayHelpers.push({
                  ...DEFAULT_EXPENSE_ITEM,
                  currency_id: defaultCurrency || "",
                });
              }}
            />
          </div>
        </div>
      </div>
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDelete}
        title={`${props.t("common.delete")} ${props.t("common.expense")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.expense"),
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(ExpensesForm);
