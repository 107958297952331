// actions constants

// FAQs
export const SET_FAQS_DATA = "SET_FAQS_DATA";

// warehouse action types
export const SET_WAREHOUSELIST = "SET_WAREHOUSELIST";

// auction collaborators action types
export const SET_COLLABORATORS = "SET_COLLABORATORS";

// payloads action types for different tables.
export const SET_PRECONTRACT_ITEMS_PAYLOADS = "SET_PRECONTRACT_ITEMS_PAYLOADS";
export const SET_CONTRACT_ITEMS_PAYLOADS = "SET_CONTRACT_ITEMS_PAYLOADS";
export const SET_PRECONTRACT_ITEMS_SCROLL = "SET_PRECONTRACT_ITEMS_SCROLL";
export const SET_CONTRACT_ITEMS_SCROLL = "SET_CONTRACT_ITEMS_SCROLL";

// action types for titles module
export const SET_TITLES_DATA = "SET_TITLES_DATA";
