import React, { useEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

// library css
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-phone-input-2/lib/style.css";
import "react-rangeslider/lib/index.css";
import "react-toastify/dist/ReactToastify.css";

// import routes
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Route from "./routes";
import { getAllDefaultValues } from "./store/common/actions";
import { getUserDetails } from "./store/user/actions";

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.User?.token);

  useEffect(() => {
    if (token) {
      dispatch(getUserDetails());
      dispatch(getAllDefaultValues());
    }
  }, [token, dispatch]);

  return (
    <>
      <Route />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
