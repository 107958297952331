import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { Badge, Button, CardBody, CardHeader, Input, Label } from "reactstrap";
import CustomSearchInput from "../../common/CustomSearchInput";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import MessageBlock from "../../common/MessageBlock";
import NoRecords from "../../common/NoRecords";
import CustomTagChip from "../../../components/common/CustomTagChip";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  exportToexcel,
  findByKeyFromArray,
  formatAmount,
  generateContractExcelData,
  generateContrastColor,
  getColumnDetail,
  getDateInDDMMYYYYFormat,
  getItemCol,
  getLabelValueArray,
  getListOfActiveStatus,
  getObjectsByValues,
  getValuesFromArray,
  openFileURL,
} from "../../../helpers/utils";
import DeleteModal from "../../common/DeleteModal";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomButton from "../../common/CustomButton";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../../../store/lots-tags/actions";
import {
  cloneContractItem,
  deleteContractItem,
  exportContract,
  exportContractRenegotiation,
  getContractItems,
  reintroduceContractItem,
  uploadExcel,
} from "../../../store/contracts/actions";
import Loader from "../../common/Loader";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { CONTRACT_STATUS_OPTIONS, SELECT_ALL_OPTION, YES_NO_OPTIONS } from "../../../helpers/constants/selectOptions";

import ColorCircle from "../../common/ColorCircle";
import ActionButton from "../../common/ActionButton";
import { toast } from "react-toastify";
import ImportPrecontractModal from "./ImportPrecontractModal";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomColumnPhoto from "../../common/CustomColumnPhoto";
import {
  ADMIN,
  AVAILABLE_FOR_AUCTION_LOT,
  AVAILABLE_LOT,
  COMUNICATIONS,
  DEFAULT_ROW_PER_PAGE,
  EXTERNAL,
  GENERAL,
  RESERVED_LOT,
  TRANSLATOR,
} from "../../../helpers/constants";
import ImportExcelModal from "./ImportExcelModal";
import CloneItemModal from "./CloneItemModal";
import API from "../../../helpers/api/api_interceptor";
import { addLots } from "../../../store/auctions/lotActions";
import LinkToAuctionModal from "../../common/LinkToAuctionModal";
import { SET_CONTRACT_ITEMS_PAYLOADS, SET_CONTRACT_ITEMS_SCROLL } from "../../../store/actionTypes";
import i18next from "i18next";
import ReIntroduceModal from "./ReIntroduceModal";

const ContractItems = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.User?.userData?.permission?.code);
  const { contract, contractItems, totalItems, suggested_contract_no } = useSelector((state) => state?.Contracts);
  const savedItemsPayload = useSelector((state) => state?.Payloads?.contractItems);
  const savedItemsScroll = useSelector((state) => state?.Payloads?.contractItemsScroll);

  const contractItemsRef = useRef(savedItemsPayload?.filter?.contract === id ? savedItemsScroll : 0);

  const contractItemsPayload = savedItemsPayload?.filter?.contract === id ? savedItemsPayload : null;

  const lotsTags = useSelector((state) => state?.LotsTags?.data);
  const TAGS_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.LotsTags?.data),
    "id",
    getColumnDetail("tag_label", "tag_label_pt")
  );
  const [itemsPayload, setItemsPayload] = useState(
    contractItemsPayload || {
      search: "",
      page: 1,
      limit: DEFAULT_ROW_PER_PAGE,
      sort: "position_contract",
      filter: {
        contract: id,
      },
    },
  );
  const [tableLoader, setTableLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAgreedOptions, setSelectedAgreedOptions] = useState(
    contractItemsPayload?.filter?.value_to_be_agreed__in?.length > 0
      ? getObjectsByValues(YES_NO_OPTIONS, contractItemsPayload?.filter?.value_to_be_agreed__in, "value")
      : [],
  );
  const [selectedStatusOptions, setSelectedStatusOptions] = useState(
    contractItemsPayload?.filter?.status?.length > 0
      ? getObjectsByValues(CONTRACT_STATUS_OPTIONS, contractItemsPayload?.filter?.status, "value")
      : [],
  );
  const [selectedTagOptions, setSelectedTagOptions] = useState(
    contractItemsPayload?.filter?.lots_tag__in?.length > 0
      ? getObjectsByValues(TAGS_OPTIONS, contractItemsPayload?.filter?.lots_tag__in, "value")
      : [],
  );
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [sendMailLoader, setSendMailLoader] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isImportPrecontractModalOpen, setIsPrecontractModalOpen] = useState(false);
  const [isImportExcelModalOpen, setIsExcelModalOpen] = useState(false);
  const [importExcelLoader, setImportExcelLoader] = useState(false);
  const [cloneItemLoader, setCloneItemLoader] = useState(false);
  const [isCloneItemModalOpen, setIsCloneItemModalOpen] = useState(false);

  const [linkToAuctionLoader, setLinkToAuctionLoader] = useState(false);
  const [isLinkToAuctionModalOpen, setIsLinkToAuctionModalOpen] = useState(false);
  const [isReIntroduceModalOpen, setIsReIntroduceModalOpen] = useState(false);

  // handle link to auction modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsLinkToAuctionModalOpen(true);
  };
  // handle link to auction modal close
  const handleLinkToAuctionModalClose = () => {
    setIsLinkToAuctionModalOpen(false);
  };
  // handle link to auction
  const handleLinkToAuction = (values) => {
    if (values?.auction_id) {
      setLinkToAuctionLoader(true);
      dispatch(
        addLots({
          auctionId: values.auction_id,
          contractIds: selectedRows?.map((item) => item?.id),
          isSelectedAll: isAllSelected,
          filter: {
            ...itemsPayload?.filter,
            lots_tag__tag_label__in: [AVAILABLE_LOT, AVAILABLE_FOR_AUCTION_LOT, RESERVED_LOT],
          },
          contract_item_search: itemsPayload?.search,
        }),
      ).then((res) => {
        setLinkToAuctionLoader(false);
        handleLinkToAuctionModalClose();
        handleGetContractItems(itemsPayload);
      });
    }
  };

  // handle clone modal open
  const handleCloneModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsCloneItemModalOpen(true);
  };
  // handle clone modal close
  const handleCloneModalClose = () => {
    setSelectedItem(null);
    setIsCloneItemModalOpen(false);
  };
  // handle clone confirm
  const handleCloneConfirm = (values) => {
    setCloneItemLoader(true);
    dispatch(
      cloneContractItem({
        contract_item_id: selectedItem?.id,
        contract_id: values?.contract,
      }),
    ).then((res) => {
      if (res) {
        handleCloneModalClose();
        handleGetContractItems(itemsPayload);
      }
      setCloneItemLoader(false);
    });
  };

  // handle open mail app
  const handleOpenMailApp = () => {
    if (contract?.customer?.id) {
      window.open(`mailto:${contract?.customer?.email}`);
    } else {
      toast.warning(props.t("common.please") + " " + props.t("common.select") + " " + props.t("customer"));
    }
  };

  // to handle modal open for import pre-contract
  const handleImportPrecontractModalOpen = () => {
    if (contract?.customer?.id) {
      setIsPrecontractModalOpen(true);
    } else {
      toast.warn(props.t("information.contractImport"));
    }
  };
  // to handle modal close for import pre-contract
  const handleImportPrecontractModalClose = () => {
    setIsPrecontractModalOpen(false);
    handleGetContractItems(itemsPayload);
  };

  // to handle modal open for import excel
  const handleImportExcelModalOpen = () => {
    if (contract?.customer?.id) {
      setIsExcelModalOpen(true);
    } else {
      toast.warn(props.t("information.contractImport"));
    }
  };
  // to handle modal close for import excel
  const handleImportExcelModalClose = () => {
    setIsExcelModalOpen(false);
  };

  // to handle Add excel
  const handleAddExcel = (values) => {
    setImportExcelLoader(true);
    dispatch(uploadExcel({ id, payload: values }, "contract")).then((res) => {
      if (res) {
        handleGetContractItems(itemsPayload);
        setImportExcelLoader(false);
        handleImportExcelModalClose();
      } else {
        setImportExcelLoader(false);
      }
    });
  };

  // navigate to contract item form
  const navigateOnContractItemForm = (itemId) => {
    if (itemId) {
      history.push(`/contracts/contracts/${id}/items/edit/${itemId}`);
    } else {
      history.push(`/contracts/contracts/${id}/items/new`, { lastPosition: suggested_contract_no });
    }
  };

  // naivgate on contract item form with pre-filled data of another item
  const navigateOnContractItemFormForCopy = (itemId) => {
    if (itemId) {
      history.push(`/contracts/contracts/${id}/items/new`, {
        itemIdToCopy: itemId,
        lastPosition: suggested_contract_no,
      });
    }
  };

  const handleReIntroduceModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsReIntroduceModalOpen(true);
  };
  const handleReIntroduceModalClose = () => {
    setSelectedItem(null);
    setIsReIntroduceModalOpen(false);
  };

  // handle re-introduce item
  const handleReIntroduceItem = () => {
    setTableLoader(true);
    dispatch(
      reintroduceContractItem({
        contract_item_id: selectedItem?.id,
        contract_id: selectedItem?.contract?.id,
      })
    ).then((res) => {
      handleGetContractItems(itemsPayload);
    });
    setTableLoader(false);
    handleReIntroduceModalClose();
  };

  //   handle delete modal open
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  //   handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };
  //   handle delete item
  const handleDeleteItem = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteContractItem({
          filter: {},
          isSelectedAll: false,
          ids: [selectedItem?.id],
        }),
      ).then((res) => {
        handleDeleteModalClose();
        handleGetContractItems(itemsPayload);
        setSelectedRows((prevState) => [...prevState.filter((item) => item?.id !== selectedItem?.id)]);
        setDeleteLoader(false);
      });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    scrollItemsToCords(0);
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    scrollItemsToCords(0);
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle contract item filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setItemsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setItemsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setItemsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // to handle the pdf exporting
  const handleExportPDF = (sendMail, isEnglish) => {
    const payload = {
      is_mail: sendMail,
      is_english: isEnglish,
      ids: selectedRows?.map((item) => item?.id),
      filter: itemsPayload?.filter,
    };
    if (sendMail) {
      setSendMailLoader(true);
    } else {
      setExportBtnLoader(true);
    }
    if (id) {
      dispatch(exportContract(id, payload)).then((res) => {
        if (res) {
          if (!sendMail) {
            openFileURL(res, "download");
          }
          handleGetContractItems(itemsPayload);
        }
        setSendMailLoader(false);
        setExportBtnLoader(false);
      });
    }
  };
  // to handle the excel exporting
  const handleExportContractExcel = (isEnglish) => {
    if (totalItems > 0 && selectedRows?.length === 0) {
      setExportBtnLoader(true);
      API.get(`/contracts/item`, {
        params: {
          sort: itemsPayload?.sort,
          filter: itemsPayload?.filter,
          exclude: itemsPayload?.exclude,
        },
      }).then((res) => {
        if (res && res?.status && res?.status === 200) {
          exportToexcel(
            generateContractExcelData(res?.data?.data?.results, isEnglish),
            `${contract?.contract_no}_${props.t("common.items")}_${
              isEnglish ? props.t("common.en") : props.t("common.pt")
            }-${getDateInDDMMYYYYFormat(new Date())}`,
            [18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 25],
            "d0cece",
          );
        }
        setExportBtnLoader(false);
      });
    } else if (selectedRows?.length > 0) {
      exportToexcel(
        generateContractExcelData(selectedRows, isEnglish),
        `${contract?.contract_no}_${props.t("common.items")}_${
          isEnglish ? props.t("common.en") : props.t("common.pt")
        }-${getDateInDDMMYYYYFormat(new Date())}`,
        [18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 25],
        "d0cece",
      );
    }
  };
  // to handle the renegotiation pdf exporting
  const handleExportRenegotiationPDF = (isEnglish) => {
    const payload = {
      is_english: isEnglish,
      ids: selectedRows?.map((item) => item?.id),
      filter: itemsPayload?.filter,
    };
    setExportBtnLoader(true);
    if (id) {
      dispatch(exportContractRenegotiation(id, payload)).then((res) => {
        if (res) {
          openFileURL(res, "download");
        }
        setExportBtnLoader(false);
      });
    }
  };

  //   handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  //   handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsBulkDeleteModalOpen(false);
  };

  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    setBulkDeleteLoader(true);
    dispatch(
      deleteContractItem({
        isSelectedAll: isAllSelected,
        ids: selectedRows?.map((item) => item?.id),
        filter: itemsPayload?.filter,
      }),
    ).then((res) => {
      handleBulkDeleteModalClose();
      handleGetContractItems(itemsPayload);
      setIsAllSelected(false);
      setSelectedRows([]);
      setBulkDeleteLoader(false);
    });
  };

  // handle getting contract items
  const handleGetContractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setTableLoader(false);
      });
    },
    [dispatch],
  );

  // to get the contract items
  useEffect(() => {
    handleGetContractItems(itemsPayload);
  }, [handleGetContractItems, itemsPayload]);

  // to get the list of the lots--> tags
  useEffect(() => {
    dispatch(getTags({ sort: getColumnDetail("tag_label", "tag_label_pt") }));
  }, [dispatch]);

  const scrollItemsToCords = (y) => {
    document.querySelector(".contract-items-table")?.scroll(0, y);
  };

  useEffect(() => {
    scrollItemsToCords(contractItemsRef?.current);
    document.querySelector(".contract-items-table")?.addEventListener("scroll", (e) => {
      contractItemsRef.current = e.target.scrollTop;
    });
    return () => {
      dispatch({
        type: SET_CONTRACT_ITEMS_SCROLL,
        payload: contractItemsRef?.current,
      });
    };
  }, []);

  // stored the items payload in redux
  useEffect(() => {
    dispatch({ type: SET_CONTRACT_ITEMS_PAYLOADS, payload: itemsPayload });
  }, [dispatch, itemsPayload]);

  const columns = [
    {
      id: "checkbox",
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
            onChange={(event) => {
              if (selectedRows?.find((item) => item?.id === row?.id)) {
                setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
              } else {
                setSelectedRows((prevState) => [...prevState, row]);
              }
            }}
          />
        </Label>
      ),
      width: "80px",
      center: true,
    },
    {
      id: "no",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="position_contract__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.position_contract__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.position_contract ? row?.position_contract : "-"),
      width: "150px",
    },
    {
      id: "photo",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.lotsPhoto")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.default_photo} title={<CustomColumnPhoto sourceUrl={row?.default_photo} />} />
      ),
      width: "120px",
    },
    {
      id: "item",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.item")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={getColumnDetail(
              itemsPayload?.filter?.custom_title_en__icontains,
              itemsPayload?.filter?.custom_title_pt__icontains,
            )}
          />
        </div>
      ),
      selector: (row) => (row ? getItemCol(row) : "-"),
      wrap: true,
      width: "225px",
    },
    {
      id: "agreedValue",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.agreedValue")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedAgreedOptions}
            handleOnSelection={(values) => {
              setSelectedAgreedOptions(values);
              handleDropdownFilter(values, "value_to_be_agreed__in", setSelectedAgreedOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.value_to_be_agreed ? props.t("common.yes") : props.t("common.no")),
      width: "200px",
      wrap: true,
    },
    {
      id: "auction",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.auction")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__auction__auction_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.auction_lots__auction__auction_no__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.auction?.id ? row?.auction?.auction_no : "-"),
      width: "200px",
      wrap: true,
    },
    {
      id: "lot",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lot")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_lot_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.contract_lot_no__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.auction_lots?.lot_no ? row?.auction_lots?.lot_no + row?.auction_lots?.lot_alpha : "-"),
      width: "200px",
      wrap: true,
    },
    {
      id: "saleValue",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__sale_value__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.auction_lots__sale_value__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.lot?.sale_value ? formatAmount(row?.lot?.sale_value || 0) : "-"),
      width: "200px",
      wrap: true,
    },
    {
      id: "status",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.TAGS")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...TAGS_OPTIONS]}
            selectedOptions={selectedTagOptions}
            handleOnSelection={(values) => {
              setSelectedTagOptions(values);
              handleDropdownFilter(values, "lots_tag__in", setSelectedTagOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.lots_tag?.length > 0 ? (
          <div className="d-flex flex-column gap-1 m-2">
            {row?.lots_tag?.map((tagId) => {
              const tagData = findByKeyFromArray(lotsTags, "id", tagId);
              return (
                <CustomTagChip
                  title={getColumnDetail(tagData?.tag_label, tagData?.tag_label_pt)}
                  bgColor={tagData?.tag_color}
                  textColor={generateContrastColor(tagData?.tag_color || "#000")}
                />
              );
            })}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
      center: true,
    },
    {
      id: "tags",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.status")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...CONTRACT_STATUS_OPTIONS.slice(1, CONTRACT_STATUS_OPTIONS.length)]}
            selectedOptions={selectedStatusOptions}
            handleOnSelection={(values) => {
              setSelectedStatusOptions(values);
              handleDropdownFilter(values, "status", setSelectedStatusOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getListOfActiveStatus(row)?.length ? (
          <div className="d-flex flex-row gap-1 flex-wrap">
            {getListOfActiveStatus(row)?.map((option, index) => {
              return (
                <ColorCircle
                  key={`tag-${option?.label}-${index}`}
                  color={option?.color}
                  id={`tag-${option?.value}-${index}`}
                  label={option?.label}
                />
              );
            })}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
      center: true,
    },
    {
      id: "actions",
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
            <>
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => {
                  navigateOnContractItemFormForCopy(row?.id);
                }}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-copy`} tooltipBody={props.t("common.copy")} />
                <i className=" ri-file-copy-fill fs-18" id={`tooltip-${row?.id}-copy`} />
              </button>
              {row.lots_tag?.some((item) => item === 17 || item === "17") ? (
                <ActionButton
                  iconClasses={"ri-arrow-go-back-fill fs-18"}
                  iconColor={"#45cb85"}
                  buttonId={`tooltip-${row?.id}-reIntroduce`}
                  className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                  onClick={() => handleReIntroduceModalOpen(row)}
                  tooltip={`${props.t("common.reIntroduce")}`}
                />
              ) : (
                <ActionButton
                  disabled={row?.is_sales_note || ![ADMIN, EXTERNAL, TRANSLATOR].includes(userRole)}
                  iconClasses={"ri-file-copy-fill fs-18"}
                  iconColor={"#f06548"}
                  buttonId={`tooltip-${row?.id}-clone`}
                  tooltip={`${props.t("common.clone")}`}
                  onClick={() => {
                    handleCloneModalOpen(row);
                  }}
                />
              )}
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => {
                  handleDeleteModalOpen(row);
                }}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
                <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
              </button>
            </>
          )}
        </div>
      ),
      width: "150px",
      center: true,
    },
  ];

  return (
    <>
      <CardHeader
        className="card-section-header item-header d-flex flex-row align-items-center justify-content-between">
        <span className="card-title">{props.t("common.items")}</span>

        {props?.isEditable && checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) ? (
          <div className="d-flex flex-row gap-2">
            {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
              <ActionButton
                tooltip={props.t("common.openEmailApp")}
                buttonClasses="me-2 bg-soft-primary"
                iconClasses="ri-mail-send-fill fs-24"
                buttonId={"mail"}
                onClick={handleOpenMailApp}
              />
            )}
            {(isAllSelected && totalItems > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalItems}
                </Badge>
              </Button>
            ) : null}
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.item"),
              })}
              onClick={() => navigateOnContractItemForm()}
            />
            {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
              <CustomDropdownButton
                disabled={totalItems <= 0 || exportBtnLoader}
                isLoading={sendMailLoader}
                btnTitle={props.t("common.sendMail")}
                options={[
                  {
                    label: props.t("common.pdf", {
                      lang: props.t("common.en"),
                    }),
                    onClick: () => {
                      handleExportPDF(true, true);
                    },
                  },
                  {
                    label: props.t("common.pdf", {
                      lang: props.t("common.pt"),
                    }),
                    onClick: () => {
                      handleExportPDF(true, false);
                    },
                  },
                ]}
              />
            )}
            <CustomButton
              disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
              btnTitle={props.t("common.link")}
              onClick={handleLinkToAuctionModalOpen}
            />
            <CustomDropdownButton
              disabled={totalItems <= 0 || sendMailLoader}
              isLoading={exportBtnLoader}
              btnTitle={props.t("common.export")}
              options={[
                {
                  label: props.t("common.pdf", { lang: props.t("common.en") }),
                  onClick: () => {
                    handleExportPDF(false, true);
                  },
                },
                {
                  label: props.t("common.pdf", { lang: props.t("common.pt") }),
                  onClick: () => {
                    handleExportPDF(false, false);
                  },
                },
                {
                  label: `${props.t("common.excel")}
                   ${props.t("common.en")}`,
                  onClick: () => {
                    handleExportContractExcel(true);
                  },
                },
                {
                  label: `${props.t("common.excel")}
                  ${props.t("common.pt")}`,
                  onClick: () => {
                    handleExportContractExcel(false);
                  },
                },
                {
                  label: `${props.t("common.renegotiation")}`,
                  onClick: () => {
                    handleExportRenegotiationPDF(false);
                  },
                },
              ]}
            />
            <CustomDropdownButton
              btnTitle={props.t("common.import")}
              options={[
                {
                  label: props.t("common.preContract"),
                  onClick: () => {
                    handleImportPrecontractModalOpen();
                  },
                },
                {
                  label: props.t("common.excel"),
                  onClick: () => {
                    handleImportExcelModalOpen();
                  },
                },
              ]}
            />

            <CustomButton
              isLoading={bulkDeleteLoader}
              color={"danger"}
              btnTitle={props.t("common.delete")}
              disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
              onClick={() => {
                handleBulkDeleteModalOpen();
              }}
            />
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="card-section-body">
        {tableLoader ? <Loader /> : null}

        {props.isEditable ? (
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table contract-items-table"
            persistTableHead
            onRowClicked={(row) => {
              if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                navigateOnContractItemForm(row?.id);
              } else {
                if (!row.auction_id || !row?.auction_id?.is_publish) {
                  navigateOnContractItemForm(row?.id);
                }
              }
            }}
            columns={
              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])
                ? columns
                : columns.filter((c) => c.id !== "saleValue")
            }
            data={contractItems}
            pagination
            paginationServer
            paginationTotalRows={totalItems}
            paginationDefaultPage={itemsPayload?.page}
            paginationPerPage={itemsPayload?.limit}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        ) : (
          <MessageBlock message={props.t("information.contractItemsBlock")} />
        )}
      </CardBody>
      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={
          i18next.resolvedLanguage === "en"
            ? `${props.t("common.delete")} ${selectedRows.length || totalItems} ${props.t(
              "contracts.contracts.contract",
            )} ${props.t("common.items")}`
            : `${props.t("common.delete")} ${selectedRows.length || totalItems} ${props
              .t("common.items")
              .toLowerCase()} ${props.t("common.contractItems")}`
        }
        body={props.t(
          "confirmation.deleteMessage",
          i18next.resolvedLanguage === "en"
            ? {
              module: `${props.t("contracts.contracts.contract")} ${props.t("common.items")}`,
            }
            : { module: `${props.t("common.items").toLowerCase()}` },
        )}
        actionBtnTitle={props.t("common.delete")}
        loader={bulkDeleteLoader}
      />
      {/* delete modal for the items */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteItem}
        title={`${props.t("common.delete")} ${props.t("common.contractItem")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.contractItem"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
      {/* import precontract modal  */}
      <ImportPrecontractModal
        isModalOpen={isImportPrecontractModalOpen}
        handleModalClose={handleImportPrecontractModalClose}
        contractId={id}
        customrId={contract?.customer?.id}
      />
      {/* import excel modal  */}
      <ImportExcelModal
        isModalOpen={isImportExcelModalOpen}
        onCancel={handleImportExcelModalClose}
        onSave={handleAddExcel}
        loader={importExcelLoader}
      />
      {/* Clone item modal */}
      <CloneItemModal
        loader={cloneItemLoader}
        isModalOpen={isCloneItemModalOpen}
        onCancel={handleCloneModalClose}
        onConfirm={handleCloneConfirm}
      />
      {/* link to auction modal */}
      <LinkToAuctionModal
        isModalOpen={isLinkToAuctionModalOpen}
        onCancel={handleLinkToAuctionModalClose}
        onConfirm={handleLinkToAuction}
        title={props.t("common.linkToAuction")}
        loader={linkToAuctionLoader}
      />
      {/* Reintroduce item modal */}
      <ReIntroduceModal
        loader={tableLoader}
        isModalOpen={isReIntroduceModalOpen}
        onCloseBtnHandler={handleReIntroduceModalClose}
        onConfirmBtnHandler={handleReIntroduceItem}
      />
    </>
  );
};

export default withTranslation()(ContractItems);
