import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardHeader, Input, Label } from "reactstrap";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomSearchInput from "../../../components/common/CustomSearchInput";
import CustomTagChip from "../../../components/common/CustomTagChip";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../components/common/DeleteModal";
import Loader from "../../../components/common/Loader";
import NoRecords from "../../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS, DEFAULT_ROW_PER_PAGE, EXTERNAL, GENERAL, TRANSLATOR } from "../../../helpers/constants";
import { checkPermissionByRoles, generateContrastColor, getOnlyCustomerName } from "../../../helpers/utils";
import { deleteContract, getContracts } from "../../../store/contracts/actions";
import { SET_CUSTOMER_DETAILS } from "../../../store/customers/actionType";

const defaultContractPayload = {
  search: "",
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  filter: {},
};

const Contracts = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [contractsPayload, setContractsPayload] = useState(defaultContractPayload);

  const [selectedContract, setSelectedContract] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSearch, setClearSearch] = useState();

  const user = useSelector((state) => state?.User?.userData);
  const { data: contractsData, total: totalContracts } = useSelector((state) => state?.Contracts);

  // handle get contracts
  const handleGetContracts = useCallback(
    (contractsPayload) => {
      setTableLoader(true);
      dispatch(getContracts(contractsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetContracts({
      ...contractsPayload,
      filter: {
        ...contractsPayload.filter,
        specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, TRANSLATOR]) ? [user.id] : undefined,
      },
    });
  }, [contractsPayload, handleGetContracts, user.id]);

  // handle row selection
  const handleRowSelection = (rowId) => {
    const isSelected = Boolean(selectedRows.find((item) => item === rowId));

    if (isSelected) {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setIsAllSelected(false);
    } else {
      setSelectedRows((prevState) => [...prevState, rowId]);
    }
  };

  const handleClearFilters = () => {
    setContractsPayload(defaultContractPayload);
    setSelectedContract(null);
    setTableLoader(false);
    setIsDeleteModalOpen(false);
    setDeleteLoader(false);
    setIsAllSelected(false);
    setSelectedRows([]);
    setClearSearch(true);
  };

  //   navigate on contract from
  const navigateOnContractForm = (id) => {
    if (id) {
      history.push(`/contracts/contracts/edit/${id}`);
    } else {
      history.push("/contracts/contracts/new");
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (contarctData) => {
    setSelectedContract(contarctData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedContract(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteContract = () => {
    if (selectedContract && selectedContract?.id) {
      setDeleteLoader(true);
      dispatch(deleteContract(selectedContract?.id))
        .then((res) => {
          if (res) {
            handleGetContracts(contractsPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      page: value,
      filter: {
        specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, TRANSLATOR]) ? [user.id] : undefined,
      },
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      limit: value,
      filter: {
        specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, TRANSLATOR]) ? [user.id] : undefined,
      },
    }));
  };

  const navigateOnCustomerForm = (row) => {
    if (row?.customer?.id && !row?.customer?.parent_id) {
      dispatch({
        type: SET_CUSTOMER_DETAILS,
        payload: {},
      });
      history.push(`/customers/edit/${row?.customer?.id}`);
    } else if (row?.customer?.parent_id) {
      history.push(`/customers/${row?.customer?.parent_id}/edit-person/${row?.customer?.id}`);
    }
  };

  const columns = [
    // {
    //   name: (
    //     <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
    //       <Input
    //         type="checkbox"
    //         checked={isAllSelected}
    //         onChange={(event) => {
    //           setIsAllSelected(event.target.checked);
    //           setSelectedRows(event.target.checked ? contractsData.map((row) => row.id) : []);
    //         }}
    //       />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <Input
    //       type="checkbox"
    //       checked={Boolean(selectedRows.find((item) => item === row.id))}
    //       onChange={() => handleRowSelection(row.id)}
    //     />
    //   ),
    //   width: "50px",
    //   center: true,
    // },
    {
      id: "contractNo",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.contractNo")}</div>
          {!checkPermissionByRoles([EXTERNAL, TRANSLATOR]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="contract_no__icontains"
              className="column-search-input"
              payload={contractsPayload}
              setPayload={setContractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => (row?.contract_no ? row?.contract_no : "-"),

      minWidth: "150px",
    },
    {
      id: "customerName",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.customerName")}</div>
          {!checkPermissionByRoles([EXTERNAL, TRANSLATOR]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="search_name__icontains"
              className="column-search-input"
              payload={contractsPayload}
              setPayload={setContractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => (
        <Label
          onClick={() => {
            if (!checkPermissionByRoles([EXTERNAL])) {
              navigateOnCustomerForm(row);
            }
          }}
          className="cursor-pointer"
        >
          <CustomTagChip
            title={getOnlyCustomerName(row?.customer)}
            bgColor={"#F6F6F6"}
            textColor={generateContrastColor("#F6F6F6")}
          />
        </Label>
      ),

      minWidth: "350px",
    },
    {
      id: "noOfItems",
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.numberOfItemsInContract")}</div>
          {!checkPermissionByRoles([EXTERNAL, TRANSLATOR]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="item_count__icontains"
              className="column-search-input"
              payload={contractsPayload}
              setPayload={setContractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => row?.item_count,
      minWidth: "250px",
      wrap: true,
    },
    {
      id: "action",
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
            <button
              type="button"
              className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
              }`}
              onClick={() => {
                handleDeleteModalOpen(row);
              }}
              disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
              style={{
                outline: "none",
                border: "none",
                opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
                cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
              }}
            >
              <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
              <i
                className={`ri-delete-bin-fill fs-18 ${
                  checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
                }`}
                id={`tooltip-${row?.id}-delete`}
                style={{
                  color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                  border: "none",
                }}
              />
            </button>
          )}
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.contracts")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
            <>
              {!checkPermissionByRoles([EXTERNAL, TRANSLATOR]) ? (
                <CustomSearchInput
                  className="custom-search-input"
                  payload={contractsPayload}
                  setPayload={setContractsPayload}
                  clearSearch={clearSearch}
                  setClearSearch={setClearSearch}
                  disabled={tableLoader}
                />
              ) : null}
              <div className="contain-header-right d-flex flex-row gap-2">
                {(isAllSelected && totalContracts > 0) || selectedRows.length ? (
                  <Button
                    color="info"
                    onClick={() => {
                      setSelectedRows([]);
                      setIsAllSelected(false);
                    }}
                  >
                    {props.t("common.deselect")}
                    <Badge color="dark" className="ms-1">
                      {selectedRows.length || totalContracts}
                    </Badge>
                  </Button>
                ) : null}
                {/* Highlighted: Clear Filters Button */}

                <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
                  {props.t("common.clearFilters")}
                </Button>
                <CustomAddButton
                  color={"success"}
                  btnTitle={props.t("common.addTitle", {
                    module: props.t("contracts.contracts.contract"),
                  })}
                  onClick={() => navigateOnContractForm()}
                />
              </div>
            </>
          )}
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.contracts")}</CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            onRowClicked={(row) => {
              navigateOnContractForm(row?.id);
            }}
            columns={
              checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL])
                ? columns
                : columns.filter((c) => c.id !== "customerName")
            }
            data={[...contractsData]}
            paginationDefaultPage={contractsPayload?.page}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            pagination
            paginationServer
            paginationTotalRows={totalContracts}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteContract}
        title={`${props.t("common.delete")} ${props.t("contracts.contracts.contract")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("contracts.contracts.contract"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Contracts);
