import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import LoginWrapper from "../../components/pages/authentication/LoginWrapper";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, withRouter } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions

//import images
import logoLight from "../../assets/images/veritas/logo-white.svg";
import { loginUser } from "../../store/user/actions";
import { scrollToFirstFormikError } from "../../helpers/utils";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.goBack();
    }
  }, [history]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter valid email").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setLoginLoader(true);
      dispatch(loginUser(values))
        .then((res) => {
          if (res) {
            setLoginLoader(false);
            history.push("/dashboard");
          } else {
            setLoginLoader(false);
          }
        })
        .catch((error) => {
          setLoginLoader(false);
        });
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login?.error,
  }));

  // useEffect(() => {
  //   API.post(`${baseURL}/users/login`, {
  //     email: "admin@veritas.com",
  //     password: "admin",
  //   });
  // }, []);

  document.title = "Veritas | Login";
  return (
    <React.Fragment>
      <LoginWrapper>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="30" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{/* Premium Admin & Dashboard Template */}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Veritas.</p>
                    </div>
                    {error && error ? <Alert color="danger"> {error} </Alert> : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="password-input">
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={isPasswordVisible ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            <button
                              className="btn shadow-none btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => {
                                setIsPasswordVisible((prevState) => !prevState);
                              }}
                            >
                              <i
                                className={`${isPasswordVisible ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle ${
                                  validation.errors.password ? "me-3" : ""
                                }`}
                              ></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                            onClick={() => scrollToFirstFormikError(validation.errors)}
                          >
                            {loginLoader ? (
                              <span className="d-flex align-items-center">
                                <Spinner size="sm" className="flex-shrink-0">
                                  Loading...
                                </Spinner>
                                <span className="flex-grow-1 ms-2">Loading...</span>
                              </span>
                            ) : (
                              "Sign In"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </LoginWrapper>
    </React.Fragment>
  );
};

export default withRouter(Login);
