import { FieldArray } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, FormFeedback, Input, Row } from "reactstrap";
import { checkImageFileValidation, createFileUrl, extractNameFromUrl, getTypeFromValue } from "../../helpers/utils";
import ActionButton from "./ActionButton";
import CustomAddButton from "./CustomAddButton";
import CustomLinkWrapper from "./CustomLinkWrapper";
import DeleteModal from "./DeleteModal";
import MessageBlock from "./MessageBlock";

import { useDispatch } from "react-redux";
import NumberInput from "./NumberInput";

const MultiMediaTableForm = (props) => {
  const DEFAULT_ITEM = props.defaultRowValues;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const dispatch = useDispatch();

  // to bind the array helpers of the items field array(formik).
  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  // delete modal open handler
  const handleDeleteModalOpen = (index, item) => {
    setSelectedItemIndex(index);
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };
  // delete modal close handler
  const handleDeleteModalClose = () => {
    setSelectedItemIndex(null);
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };

  // handle delete
  const handleDelete = () => {
    if (props?.handleDeleteItem && selectedItem?.id) {
      props.handleDeleteItem(
        { ids: [selectedItem?.id] },
        setDeleteLoader,
        handleDeleteModalClose,
        boundArrayHelpers,
        selectedItemIndex,
        props?.arrayKey
      );
    } else {
      handleDeleteModalClose();
      boundArrayHelpers.remove(selectedItemIndex);
    }
  };

  // clear uploaded document
  const clearUploadedDocument = (key) => {
    props?.validation.setFieldValue(key, "");
  };

  return (
    <>
      <div className="card-subtable-section table-light">
        <Row className="p-3 subtable-header subtable-row align-items-center">
          {props.columns?.map((column, index) => (
            <Col key={index} sm={column?.spacing?.sm} md={column?.spacing?.md} lg={column?.spacing?.lg}>
              {column?.label}
              {column.headerActionButton}
            </Col>
          ))}
        </Row>
        <hr />
        <div className="p-3 pb-0 subtable-container">
          <FieldArray
            name={props.arrayKey}
            render={(arrayHelpers) => {
              bindArrayHelpers(arrayHelpers);
              return props.validation.values?.[props?.arrayKey]?.length !== 0 ? (
                props.validation.values?.[props.arrayKey]?.map((row, index) => {
                  return (
                    <Row key={index} className="subtable-row mb-3">
                      {props.columns?.map((column, index2) => {
                        if (column?.type === "counter") {
                          return (
                            <Col
                              key={`${index2}-counter`}
                              sm={column?.spacing?.sm}
                              md={column?.spacing?.md}
                              lg={column?.spacing?.lg}
                            >
                              <Input style={{ textAlign: "center" }} disabled placeholder={index + 1} />
                            </Col>
                          );
                        }
                        if (column?.type === "file") {
                          return (
                            <Col
                              key={`${index2}-file`}
                              sm={column?.spacing?.sm}
                              md={column?.spacing?.md}
                              lg={column?.spacing?.lg}
                            >
                              <div className="mb-3">
                                {props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key] ? (
                                  <div className="d-flex flex-tow justify-content-between align-items-center w-100">
                                    <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break w-100 mr-2">
                                      <CustomLinkWrapper
                                        onClick={true}
                                        link={
                                          typeof props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key] ===
                                          "string"
                                            ? props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key]
                                            : createFileUrl(
                                                props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key]
                                              )
                                        }
                                        title={
                                          typeof props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key] ===
                                          "string"
                                            ? extractNameFromUrl(
                                                props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key],
                                                "/",
                                                5
                                              )
                                            : props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key].name
                                        }
                                      />
                                      <i
                                        className="ri-close-fill fs-18 cursor-pointer text-danger"
                                        onClick={() => {
                                          clearUploadedDocument(`${props.arrayKey}.${index}.${column?.key}`);
                                        }}
                                      />
                                    </div>
                                    <div className="ms-3">
                                      {column?.actionButtons?.map(({ Component, onClick, fileType, ...others }) => {
                                        if (
                                          fileType === "image" &&
                                          checkImageFileValidation(
                                            typeof props.validation?.values?.[props.arrayKey]?.[index]?.[
                                              column?.key
                                            ] === "string"
                                              ? extractNameFromUrl(
                                                  props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key],
                                                  "/",
                                                  5
                                                )
                                              : props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key].name
                                          )
                                        ) {
                                          return Component(index, props.arrayKey);
                                        }
                                        return null;
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <Input
                                      name={`${props.arrayKey}[${index}].${column?.key}`}
                                      className="form-control"
                                      type="file"
                                      accept={column?.accept}
                                      onChange={(e) => {
                                        if (column.checkFileTypeValidation) {
                                          if (column.checkFileTypeValidation(e.target.files[0]?.name)) {
                                            props.validation.setFieldValue(
                                              `${props.arrayKey}.${index}.${column?.key}`,
                                              e.target.files[0]
                                            );
                                          } else {
                                            toast.error(props.t("validation.uploadFileFormat"));
                                          }
                                        } else {
                                          props.validation.setFieldValue(
                                            `${props.arrayKey}.${index}.${column?.key}`,
                                            e.target.files[0]
                                          );
                                        }
                                      }}
                                      onBlur={props.validation.handleBlur}
                                      value={props.validation?.values?.[props.arrayKey]?.[index]?.[column?.key] || ""}
                                      invalid={
                                        props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                                        props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]
                                          ? true
                                          : false
                                      }
                                    />
                                    {props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                                    props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key] ? (
                                      <FormFeedback type="invalid">
                                        {props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </Col>
                          );
                        }
                        if (column?.type === "select") {
                          return (
                            <Col
                              key={`${index2}-select`}
                              sm={column?.spacing?.sm}
                              md={column?.spacing?.md}
                              lg={column?.spacing?.lg}
                            >
                              <Select
                                key={`${index}-${index2}-select`}
                                options={column?.options}
                                className={`custom-select ${
                                  props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                                  props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]
                                    ? "select-error"
                                    : ""
                                }`}
                                placeholder={props.t("placeholder.selectField", { fieldType: column?.label })}
                                value={getTypeFromValue(
                                  column?.options,
                                  props.validation.values?.[props.arrayKey]?.[index]?.[column?.key] || ""
                                )}
                                onChange={(data) => {
                                  props?.validation.setFieldValue(
                                    `${props.arrayKey}.${index}.${column?.key}`,
                                    data?.value
                                  );
                                }}
                                onBlur={(e) => {
                                  props.validation.setFieldTouched(`${props.arrayKey}.${index}.${column?.key}`, true);
                                  props.validation.handleBlur(e);
                                }}
                              />
                              {props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                              props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key] ? (
                                <span className="custom-invalid-feedback">
                                  {props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]}
                                </span>
                              ) : null}
                            </Col>
                          );
                        }
                        if (column?.type === "text") {
                          return (
                            <Col
                              key={`${index2}-text`}
                              sm={column?.spacing?.sm}
                              md={column?.spacing?.md}
                              lg={column?.spacing?.lg}
                            >
                              <Input
                                name={`${props.arrayKey}.${index}.${column.key}`}
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: column?.label,
                                })}
                                type="text"
                                onChange={props.validation.handleChange}
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values?.[props.arrayKey]?.[index]?.[column?.key]}
                                invalid={
                                  props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                                  props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]
                                    ? true
                                    : false
                                }
                              />
                              {props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                              props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key] ? (
                                <FormFeedback type="invalid">
                                  {props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          );
                        }
                        if (column?.type === "number") {
                          return (
                            <Col
                              key={`${index2}-number`}
                              sm={column?.spacing?.sm}
                              md={column?.spacing?.md}
                              lg={column?.spacing?.lg}
                            >
                              <NumberInput
                                name={`${props.arrayKey}.${index}.${column.key}`}
                                className="form-control"
                                placeholder={props.t("placeholder.enterField", {
                                  fieldType: column?.label,
                                })}
                                type="number"
                                onChange={props.validation.handleChange}
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values?.[props.arrayKey]?.[index]?.[column?.key]}
                                invalid={
                                  props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                                  props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]
                                    ? true
                                    : false
                                }
                              />
                              {props.validation.touched?.[props.arrayKey]?.[index]?.[column?.key] &&
                              props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key] ? (
                                <FormFeedback type="invalid">
                                  {props.validation.errors?.[props.arrayKey]?.[index]?.[column?.key]}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          );
                        }
                      })}

                      {!props?.hideDeleteAction ? (
                        <Col key={`${index}-delete`} md={1} lg={1}>
                          <ActionButton
                            onClick={() => {
                              handleDeleteModalOpen(index, row);
                            }}
                            tooltip={props.t("common.delete")}
                            iconClasses="ri-delete-bin-fill fs-18"
                            iconColor="red"
                            buttonId={`delete-action-${index}`}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  );
                })
              ) : (
                <MessageBlock
                  fontSize="14px"
                  height="50px"
                  message={props.t("common.addTitle", {
                    module: props.sectionName,
                  })}
                />
              );
            }}
          />
        </div>

        {!props?.hideAddButton && (
          <>
            <hr />
            <div className="p-2 subtable-footer">
              <div className="d-flex justify-content-end">
                <CustomAddButton
                  color={"success"}
                  btnTitle={props.t("common.addTitle", {
                    module: props.sectionName,
                  })}
                  onClick={() => {
                    boundArrayHelpers.push({
                      ...DEFAULT_ITEM,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {/* delete modal */}
      <DeleteModal
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDelete}
        title={`${props.t("common.delete")} ${props.sectionName}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.sectionName,
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(MultiMediaTableForm);
