import i18next from "i18next";
import React from "react";
import Select, { components } from "react-select";

const CustomMultiSelect = ({
  selectedOptions,
  options,
  getOptionLabel,
  handleOnSelection,
  otherClasses,
  ...others
}) => {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="d-flex flex-row align-items-center">
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <span className="ms-2">{props.label}</span>
        </components.Option>
      </div>
    );
  };
  return (
    <Select
      placeholder={i18next.t("common.select")}
      className={`custom-select ${otherClasses ? otherClasses : ""}`}
      isMulti
      components={{ Option }}
      getOptionLabel={getOptionLabel}
      options={[...options]}
      value={selectedOptions}
      onChange={handleOnSelection}
      hideSelectedOptions={false}
      {...others}
    />
  );
};

export default CustomMultiSelect;
