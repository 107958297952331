import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddLanguageModal from "../../../../components/pages/settings/AddLanguageModal";
import {
  addLanguage,
  deleteLanguageAction,
  editLanguage,
  fetchLanguageListAction,
} from "../../../../store/languages/action";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const GeneralSettings = (props) => {
  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.Languages);

  const [languageListLoader, setLanguageListLoader] = useState(false);
  const [isDeleteLanguageModalOpen, setIsDeleteLanguageModalOpen] = useState(false);
  const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState(false);
  const [languageDeleteLoader, setLanguageDeleteLoader] = useState(false);
  const [languageSaveLoader, setLanguageSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [languagePayload, setLanguagePayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {
      id__icontains: "",
      code__icontains: "",
      description__icontains: "",
    },
  });

  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.languages")}`;

  // handler for calling listing language
  const handleGetLanguageList = useCallback(
    (languagePayload) => {
      setLanguageListLoader(true);
      dispatch(fetchLanguageListAction(languagePayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setLanguageListLoader(false);
        })
        .catch((error) => {
          setLanguageListLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetLanguageList(languagePayload);
  }, [handleGetLanguageList, languagePayload]);

  // handler for calling deleting language
  const handleConfirmBtn = () => {
    setLanguageDeleteLoader(true);
    dispatch(
      deleteLanguageAction({
        id: selectedRow,
        payload: { is_admin: isDeleteActionAllowed },
      })
    )
      .then((res) => {
        if (res.data.status === 200) {
          if (!res?.data?.data?.action_allowed) {
            handleCloseBtn();
          }
          setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
          toast.warn(res?.data?.detail);
          setLanguageDeleteLoader(false);
        } else if (res.data.status === 204) {
          toast.success(res?.data?.detail);
          handleGetLanguageList(languagePayload);
          handleCloseBtn();
          setLanguageDeleteLoader(false);
        }
      })
      .catch((error) => {
        setLanguageDeleteLoader(false);
        setIsDeleteLanguageModalOpen(false);
      });
  };

  // handler for calling create/edit language
  const handleSaveLanguage = (values, validation) => {
    setLanguageSaveLoader(true);
    let updatedValues = {};

    if (selectedRow && selectedRow.id) {
      updatedValues = {
        code: validation.values.code,
        description: validation.values.description,
        is_main: validation.values.types.includes("main") ? true : false,
        is_spoken: validation.values.types.includes("spoken") ? true : false,
      };
      // dispatch for editing page
      dispatch(editLanguage({ id: selectedRow.id, values: updatedValues }))
        .then((res) => {
          if (res) {
            //
            handleGetLanguageList(languagePayload);
            setIsAddLanguageModalOpen(false);
          } else {
            //
          }
          setLanguageSaveLoader(false);
        })
        .catch((error) => {
          setIsAddLanguageModalOpen(false);
          setLanguageSaveLoader(false);
        });
    } else {
      updatedValues = {
        code: values.code,
        description: values.description,
        is_main: values.types.includes("main") ? true : false,
        is_spoken: values.types.includes("spoken") ? true : false,
      };
      // dispatch for adding new language
      dispatch(addLanguage(updatedValues))
        .then((res) => {
          if (res) {
            //
            handleGetLanguageList(languagePayload);
            setIsAddLanguageModalOpen(false);
          } else {
            //
          }
          setLanguageSaveLoader(false);
        })
        .catch((error) => {
          setIsAddLanguageModalOpen(false);
          setLanguageSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setLanguagePayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setLanguagePayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const languageDeleteHandler = (row) => {
    setIsDeleteLanguageModalOpen(true);
    setSelectedRow(row.id);
  };

  const languageEditHandler = (row) => {
    setIsAddLanguageModalOpen(true);
    setSelectedRow(row);
  };

  const handleCloseBtn = () => {
    setIsDeleteActionAllowed(false);
    setIsDeleteLanguageModalOpen(false);
  };

  const handleLanguageModalOpen = () => {
    setSelectedRow(null);
    setIsAddLanguageModalOpen(true);
  };

  const handleLanguageModalClose = () => {
    setIsAddLanguageModalOpen(false);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={languagePayload}
            setPayload={setLanguagePayload}
          />
        </div>
      ),
      selector: (row) => row.id,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code__icontains"
            className="column-search-input"
            payload={languagePayload}
            setPayload={setLanguagePayload}
          />
        </div>
      ),
      selector: (row) => row.code,
      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={languagePayload}
            setPayload={setLanguagePayload}
          />
        </div>
      ),
      selector: (row) => row.description,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.general.users.system")}</div>
        </div>
      ),
      selector: (row) => (
        <>
          <div className="font-weight-bold fs-14 table-column-container py-4">
            <div className="table-column-title mb-2">
              {row.is_main ? (
                <i className="mdi mdi-check-circle" style={{ fontSize: "20px", color: "#45CB85" }}></i>
              ) : (
                <i className="mdi mdi-check-circle-outline" style={{ fontSize: "20px", color: "#45CB85" }}></i>
              )}
            </div>
          </div>
        </>
      ),
      minWidth: "100px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.general.language.spoken")}</div>
        </div>
      ),
      selector: (row) => (
        <>
          <div className="font-weight-bold fs-14 table-column-container py-4">
            <div className="table-column-title mb-2">
              {row.is_spoken ? (
                <i className="mdi mdi-check-circle" style={{ fontSize: "20px", color: "#45CB85" }}></i>
              ) : (
                <i className="mdi mdi-check-circle-outline" style={{ fontSize: "20px", color: "#45CB85" }}></i>
              )}
            </div>
          </div>
        </>
      ),
      minWidth: "100px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            <div className="table-actions">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => languageEditHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
                <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
              </button>
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => languageDeleteHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
                <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
              </button>
            </div>
          </div>
        </div>
      ),
      width: "150px",
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={languagePayload}
            setPayload={setLanguagePayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleLanguageModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">
                {`${props.t("common.add")} ${props.t("settings.general.language.language")}`}
              </span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {languageListLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.languages")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            // selectableRows
            columns={columns}
            data={[...settingState.languageList]}
            pagination
            paginationServer
            paginationTotalRows={settingState.totalLanguages}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            persistTableHead
          />
        </Card>
      </div>

      {/* to add /edit languages */}
      <AddLanguageModal
        isModalOpen={isAddLanguageModalOpen}
        language={selectedRow}
        onCancel={handleLanguageModalClose}
        onSave={handleSaveLanguage}
        loader={languageSaveLoader}
      />

      {/* delete modal for languages */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("settings.general.language.language")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.general.language.language"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={languageDeleteLoader}
        isModalOpen={isDeleteLanguageModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />
    </>
  );
};

export default withTranslation()(GeneralSettings);
