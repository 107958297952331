import React, { useEffect, useMemo, useState } from "react";

import { FormikProvider, useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import Select from "react-select";
import { CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import {
  ADMIN,
  COMUNICATIONS,
  DEBOUNCE_TIME,
  DEFAULT_SELLER_PERMIUM,
  DROPDOWN_DEFAULT_LIMIT,
  GENERAL,
} from "../../../helpers/constants";
import {
  checkPermissionByRoles,
  generateExpensesData,
  getCustomerOptionLabel,
  getDateString,
  getTypeFromValue,
  getVATOptioins,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import { addContractForm, updateContractForm } from "../../../store/contracts/actions";
import { getCustomerList } from "../../../store/customers/actions";
import { getUsers } from "../../../store/general-user-settings/action";
import { getVATs } from "../../../store/vat/actions";
import CustomButton from "../../common/CustomButton";
import CustomDropDown from "../../common/CustomDropDown";
import NumberInput from "../../common/NumberInput";
import ExpensesForm from "./ExpensesForm";

const ContractInfoForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [contractPhotos, setContractPhotos] = useState([]);
  const contractData = useSelector((state) => state?.Contracts?.contract);
  const vats = useSelector((state) => state?.VAT.data);
  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);
  const SPECIALIST_OPTIONS = useSelector((state) => state?.GeneralUserSettings?.data);

  const VAT_OPTIONS = getVATOptioins(
    useSelector((state) => state?.VAT.data),
    "id",
    "code",
    "rate",
    " - ",
    "%"
  );
  const [defaultVAT, setDefaultVAT] = useState(null);
  const [infoSaveLoader, setInfoSaveLoader] = useState(false);
  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    list: "basic",
    filter: {},
  });
  const [specialistSearchLoader, setSpecialistSearchLoader] = useState(false);
  const [specialistsPayload, setSpecialistsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    filter: { name__icontains: "", specialist: true },
  });
  const [vatsPayload, setVatsPayload] = useState({
    page: 1,
    limit: 50,
    sort: "code",
    filter: {
      code__icontains: "",
    },
  });

  // to set default vat option
  useEffect(() => {
    if (vats.length > 0) {
      const vat = vats?.find((vat) => vat?.rate === 23);
      setDefaultVAT(vat?.id || null);
    }
  }, [vats]);

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_customer__icontains: value },
    }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the user search
  const handleSpecialistSearch = (value) => {
    setSpecialistsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for user search
  const specialistSearchDebounce = useMemo(() => {
    return debounce(handleSpecialistSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
      // vatSearchDebounce.cancel();
      specialistSearchDebounce.cancel();
    };
  }, [customerSearchDebounce, specialistSearchDebounce]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      customer: props?.isEditable && contractData?.customer?.id ? contractData?.customer : "",
      specialists: props?.isEditable && contractData?.specialists?.length > 0 ? contractData?.specialists : [],
      contract_date: props?.isEditable ? contractData?.contract_date : new Date().toISOString().split("T")[0],
      premium: props?.isEditable ? contractData?.premium : DEFAULT_SELLER_PERMIUM,
      expenses: props?.isEditable ? generateExpensesData(contractData?.expenses) : [],
      vat: props?.isEditable && contractData?.vat?.id ? contractData?.vat?.id : defaultVAT || "",
      internal_observations: props?.isEditable ? contractData?.internal_observations : "",
      notes: props?.isEditable ? contractData?.notes : "",
    },

    validationSchema: Yup.object({
      customer: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.customerName")}`
      ),
      specialists: Yup.array().min(
        1,
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.specialists")}`
      ),
      contract_date: Yup.date()
        .typeError(
          props.t("validation.formatGeneral", {
            enterOrSelect: props.t("common.enter"),
            field: props.t("common.date"),
          })
        )
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.contractDate")}`),
      premium: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.premium")}`),
      expenses: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(
            `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.description")}`
          ),
          value: Yup.number()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.enter"),
                field: props.t("common.value"),
              })
            )
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.value")}`),
          currency_id: Yup.number().required(
            `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.currency")}`
          ),
        })
      ),
      vat: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.vatToApply")}`
      ),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        customer: values?.customer?.id,
        contract_photos: contractPhotos,
        specialists: values?.specialists?.length > 0 ? values?.specialists?.map((specialist) => specialist?.id) : [],
      };
      if (props?.isEditable) {
        // edit contract
        setInfoSaveLoader(true);
        dispatch(updateContractForm(contractData?.id, payload))
          .then((res) => {
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      } else {
        // add contract
        setInfoSaveLoader(true);
        dispatch(addContractForm(payload))
          .then((res) => {
            if (res) {
              history.push(`/contracts/contracts/edit/${res?.id}`);
            }
            setInfoSaveLoader(false);
          })
          .catch((error) => {
            setInfoSaveLoader(false);
          });
      }
    },
  });
  // to pre-filled the appraisal photos
  useEffect(() => {
    if (contractData?.contract_photos.length > 0 && props?.isEditable) {
      setContractPhotos(contractData?.contract_photos);
    } else {
      setContractPhotos([]);
    }
  }, [props?.isEditable, contractData]);

  const handleResetInfoForm = () => {
    if (JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
      }
    }
    // prompt before resetting item
    if (
      (contractData?.contract_photos?.length || 0) !== contractPhotos?.length ||
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        if (contractData?.contract_photos.length > 0 && props?.isEditable) {
          setContractPhotos(contractData?.contract_photos);
        } else {
          setContractPhotos([]);
        }
        // setDropdown();
      }
    }
  };

  // to get the list of the customers
  useEffect(() => {
    setCustomerSearchLoader(true);
    dispatch(getCustomerList(customersPayload)).then(() => {
      setCustomerSearchLoader(false);
    });
  }, [dispatch, customersPayload]);

  // to get the list of the specialist users
  useEffect(() => {
    setSpecialistSearchLoader(true);
    dispatch(getUsers(specialistsPayload)).then(() => {
      setSpecialistSearchLoader(false);
    });
  }, [dispatch, specialistsPayload]);

  // to get the list of the vats
  useEffect(() => {
    dispatch(getVATs(vatsPayload));
  }, [dispatch, vatsPayload]);

  return (
    <>
      <Prompt
        when={
          (!validation?.isSubmitting && (contractData?.contract_photos?.length || 0) !== contractPhotos?.length) ||
          (!validation?.isSubmitting &&
            JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <Prompt
        when={
          !validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <FormikProvider value={validation}>
        <Form
          onKeyPress={onKeyPressForm}
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <CardHeader className="card-section-header">
            <span className="card-title">{props.t("common.info")}</span>
            <div className="d-flex flex-row justify-content-end gap-2">
              <CustomButton
                color="dark"
                btnTitle={props.t("common.reset")}
                disabled={infoSaveLoader}
                onClick={handleResetInfoForm}
              />
              <CustomButton
                color="primary"
                disabled={infoSaveLoader}
                btnTitle={props.t("common.save")}
                type="submit"
                isLoading={infoSaveLoader}
                onClick={() => scrollToFirstFormikError(validation.errors)}
              />
            </div>
          </CardHeader>
          <CardBody className="card-section-body">
            <Row>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="contract_no" className="form-label">
                      {props.t("common.contractNo")}
                    </Label>
                    <Input
                      disabled
                      className="form-control"
                      placeholder={props.isEditable ? contractData?.contract_no : "C****"}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="customer" className="form-label">
                      {props.t("common.customerName") + "*"}
                    </Label>
                    <Select
                      name="customer"
                      options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
                      getOptionLabel={(option) => getCustomerOptionLabel(option)}
                      getOptionValue={(option) => option?.id}
                      className={`custom-select ${
                        validation.touched.customer && validation.errors.customer ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.customerName")}`}
                      value={validation.values.customer || null}
                      onInputChange={customerSearchDebounce}
                      onChange={(customer) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          if (customer?.id) {
                            validation?.setFieldValue("premium", customer?.suggested_premium || DEFAULT_SELLER_PERMIUM);
                          }
                          validation.setFieldValue("customer", customer?.id ? customer : null);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("customer", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.customer && validation.errors.customer ? (
                      <span className="custom-invalid-feedback">{validation.errors.customer}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="specialists" className="form-label">
                      {props.t("common.specialists") + "*"}
                    </Label>
                    <Select
                      isLoading={specialistSearchLoader}
                      name="specialists"
                      isMulti
                      options={specialistSearchLoader ? [] : SPECIALIST_OPTIONS}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      className={`custom-select custom-multi-select ${
                        validation.touched.specialists && validation.errors.specialists ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.specialists")}`}
                      value={validation.values.specialists || null}
                      onInputChange={specialistSearchDebounce}
                      onChange={(specialists) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          validation.setFieldValue("specialists", specialists);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("specialists", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.specialists && validation.errors.specialists ? (
                      <span className="custom-invalid-feedback">{validation.errors.specialists}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="contract_date" className="form-label">
                      {props.t("common.contractDate") + "*"}
                    </Label>
                    <Input
                      name="contract_date"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.contractDate"),
                      })}`}
                      type="date"
                      max={getDateString(new Date())}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contract_date || ""}
                      invalid={validation.touched.contract_date && validation.errors.contract_date ? true : false}
                    />
                    {validation.touched.contract_date && validation.errors.contract_date ? (
                      <FormFeedback type="invalid">{validation.errors.contract_date}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="premium" className="form-label">
                      {props.t("common.premium") + "*"}
                    </Label>
                    <NumberInput
                      name="premium"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.premium"),
                      })}`}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.premium}
                      invalid={validation.touched.premium && validation.errors.premium ? true : false}
                      disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.premium && validation.errors.premium ? (
                      <FormFeedback type="invalid">{validation.errors.premium}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="vat" className="form-label">
                      {props.t("common.vatToApply") + "*"}
                    </Label>
                    <Select
                      name="vat"
                      options={VAT_OPTIONS}
                      className={`custom-select ${
                        validation.touched.vat && validation.errors.vat ? "select-error" : ""
                      }`}
                      placeholder={`${props.t("common.select")} ${props.t("common.vatToApply")}`}
                      value={getTypeFromValue(VAT_OPTIONS, validation.values.vat) || ""}
                      onChange={(vat) => {
                        if (checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])) {
                          validation.setFieldValue("vat", vat?.value);
                        }
                      }}
                      onBlur={(e) => {
                        validation.setFieldTouched("vat", true);
                        validation.handleBlur(e);
                      }}
                      isDisabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL])}
                    />
                    {validation.touched.vat && validation.errors.vat ? (
                      <span className="custom-invalid-feedback">{validation.errors.vat}</span>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="internal_observations" className="form-label">
                      {props.t("common.internalObservations")}
                    </Label>
                    <textarea
                      name="internal_observations"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.internalObservations"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.internal_observations || ""}
                      invalid={
                        validation.touched.internal_observations && validation.errors.internal_observations
                          ? true
                          : false
                      }
                      rows={5}
                    />
                    {validation.touched.internal_observations && validation.errors.internal_observations ? (
                      <FormFeedback type="invalid">{validation.errors.internal_observations}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="notes" className="form-label">
                      {props.t("common.notes")}
                    </Label>
                    <textarea
                      name="notes"
                      className="form-control"
                      placeholder={`${props.t("placeholder.enterField", {
                        fieldType: props.t("common.notes"),
                      })}`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.notes || ""}
                      invalid={validation.touched.notes && validation.errors.notes ? true : false}
                      rows={5}
                    />
                    {validation.touched.notes && validation.errors.notes ? (
                      <FormFeedback type="invalid">{validation.errors.notes}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <Label htmlFor="appraisal_photos" className="form-label">
                  {`${props.t("common.contractPhotos")}`}
                </Label>
                <CustomDropDown setFiles={setContractPhotos} files={contractPhotos} />
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="form-label">{props.t("common.expenses")}</Label>
                <ExpensesForm validation={validation} />
              </div>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="d-flex flex-row justify-content-end gap-2">
              <CustomButton
                color="dark"
                btnTitle={props.t("common.reset")}
                disabled={infoSaveLoader}
                onClick={handleResetInfoForm}
              />
              <CustomButton
                color="primary"
                disabled={infoSaveLoader}
                btnTitle={props.t("common.save")}
                type="submit"
                isLoading={infoSaveLoader}
                onClick={() => scrollToFirstFormikError(validation.errors)}
              />
            </div>
          </CardFooter>
        </Form>
      </FormikProvider>
    </>
  );
};

export default withTranslation()(ContractInfoForm);
