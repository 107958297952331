import {
  SET_AUCTION,
  SET_AUCTIOND_DATA,
  SET_BIDS_DATA,
  SET_BUYER_DOCUMENTS,
  SET_CONTRACTS_IN_LOTS_LIST,
  SET_LOTS,
  SET_LOTS_VIEW_DATA,
  SET_PADDLES_DATA,
  SET_PADDLE_LOTS,
} from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
  auction: null,
  lots: { data: [], total: 0 },
  paddles: { data: [], total: 0, suggested_paddle_no: null },
  bids: {
    lotsView: { data: [], total: 0 },
    bidsView: { data: [], total: 0 },
  },
  contracts: {
    data: [],
    total: 0,
  },
  invoice: {
    lots: {
      data: [],
      total: 0,
    },
    buyerDocuments: {
      data: [],
      total: 0,
    },
  },
};

const Auctions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUCTIOND_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case SET_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };
    case SET_LOTS:
      return {
        ...state,
        lots: {
          data: action.payload.results,
          total: action.payload.count,
          all_ids: action.payload.all_ids,
        },
      };
    case SET_PADDLES_DATA:
      return {
        ...state,
        paddles: {
          ...state?.paddles,
          data: action.payload.data,
          total: action.payload.total,
          suggested_paddle_no: action.payload.suggested_paddle_no,
        },
      };
    case SET_LOTS_VIEW_DATA:
      return {
        ...state,
        bids: {
          ...state?.bids,
          lotsView: {
            ...state?.bids?.lotsView,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_BIDS_DATA:
      return {
        ...state,
        bids: {
          ...state?.bids,
          bidsView: {
            ...state?.bids?.bidsView,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_CONTRACTS_IN_LOTS_LIST:
      return {
        ...state,
        contracts: {
          ...state?.contracts,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_PADDLE_LOTS:
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          lots: {
            ...state?.invoice?.lots,
            data: action.payload.results,
            total: action.payload.count,
          },
        },
      };
    case SET_BUYER_DOCUMENTS:
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          buyerDocuments: {
            ...state?.invoice?.buyerDocuments,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default Auctions;
