import { ADD_ARTIST, DELETE_ARTIST, SET_ARTISTS_DATA, UPDATE_ARTIST } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Artists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ARTISTS_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };

    case ADD_ARTIST:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_ARTIST:
      const existingArtistIndex = state.data.findIndex((artist) => artist.id === action.payload.id);
      const updatedArtistsData = state.data;
      if (existingArtistIndex !== -1) {
        updatedArtistsData[existingArtistIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedArtistsData,
      };
    case DELETE_ARTIST:
      return {
        ...state,
        data: [...state.data.filter((artist) => artist.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default Artists;
