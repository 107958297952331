import React, { useCallback, useEffect, useState } from "react";

import i18next from "i18next";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Badge, Button, CardBody, CardHeader, Input, Label } from "reactstrap";

import { RETURN_GUIDE_DOCUMENT } from "../../../helpers/constants";
import { SELECT_ALL_OPTION, YES_NO_OPTIONS } from "../../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  formatAmount,
  generateDocumentLinesFromContractItems,
  getColumnDetail,
  getObjectsByValues,
  getValuesFromArray,
} from "../../../helpers/utils";
import { addLots } from "../../../store/auctions/lotActions";
import { getContractItems } from "../../../store/contracts/actions";
import { SET_AVAILABLE_LOTS } from "../../../store/customers/actionType";
import CustomButton from "../../common/CustomButton";
import CustomColumnPhoto from "../../common/CustomColumnPhoto";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CustomSearchInput from "../../common/CustomSearchInput";
import CustomTextLink from "../../common/CustomTextLink";
import LinkToAuctionModal from "../../common/LinkToAuctionModal";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";

const AvailableLots = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: lots, total: totalLots } = useSelector((state) => state?.Customers?.situation?.availableLots);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [createDocumentLoader, setCreateDocumentLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isLinkToAuctionModalOpen, setIsLinkToAuctionModalOpen] = useState(false);
  const [linkLoader, setLinkLoader] = useState(false);
  const [selectedReservedFilter, setSelectedReservedFilter] = useState();

  const [selectedAgreedOptions, setSelectedAgreedOptions] = useState(
    props?.itemsPayload?.filter?.value_to_be_agreed__in?.length > 0
      ? getObjectsByValues(YES_NO_OPTIONS, props?.itemsPayload?.filter?.value_to_be_agreed__in, "value")
      : [],
  );

  // handle contract item filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      props?.setItemsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        props?.setItemsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        props?.setItemsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle link to auction modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsLinkToAuctionModalOpen(true);
  };
  // handle link to acution modal close
  const handleLinkToAuctionModalClose = () => {
    setIsLinkToAuctionModalOpen(false);
  };

  // handle confirm on link
  const handleLinkToAuction = (values) => {
    if (values?.auction_id) {
      setLinkLoader(true);
      dispatch(
        addLots({
          auctionId: values?.auction_id,
          contractIds: selectedRows?.map((item) => item?.id),
          isSelectedAll: isAllSelected,
          filter: {
            ...props?.itemsPayload?.filter,
          },
        }),
      ).then((res) => {
        setLinkLoader(false);
        handleLinkToAuctionModalClose();
        handleGetContractItems(props?.itemsPayload);
        setSelectedRows([]);
        setIsAllSelected(false);
      });
    }
  };

  // navigate to contract item form
  const navigateOnContractItemForm = (contractId, itemId) => {
    if (itemId) {
      history.push(`/contracts/contracts/${contractId}/items/edit/${itemId}`);
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    props.setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    props.setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle getting contract items
  const handleGetContractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then((res) => {
        if (res) {
          dispatch({
            type: SET_AVAILABLE_LOTS,
            payload: {
              data: res?.results,
              total: res?.count,
            },
          });
          props.setIsAvailableLotsZero(res?.count === 0);
        } else {
          //
        }
        setTableLoader(false);
      });
    },
    [dispatch],
  );

  // to get the contract items
  useEffect(() => {
    if (
      props?.itemsPayload?.filter?.contract__customer ||
      props?.itemsPayload?.filter?.contract ||
      props?.itemsPayload?.filter?.auction_lots__auction
    )
      handleGetContractItems(props.itemsPayload);
  }, [handleGetContractItems, props.itemsPayload]);

  // to refesh the items list after linking
  useEffect(() => {
    if (props.initialPage.page) {
      props.setItemsPayload((prevState) => ({
        ...prevState,
        page: props.initialPage.page,
      }));
    }
  }, [props.initialPage]);

  // handle create document
  const handleCreateDocument = (documentType) => {
    if (!isAllSelected) {
      history.push({
        pathname: "/invoicing/new",
        state: {
          customer: props?.customerData,
          document_type: documentType,
          documentLines: generateDocumentLinesFromContractItems(selectedRows),
        },
      });
      return;
    } else {
      setCreateDocumentLoader(true);
      dispatch(
        getContractItems({
          page: 1,
          filter: props.itemsPayload?.filter,
        }),
      ).then((res) => {
        history.push({
          pathname: "/invoicing/new",
          state: {
            customer: props?.customerData,
            document_type: documentType,
            documentLines: generateDocumentLinesFromContractItems(res?.results),
          },
        });
      });
    }
  };

  // get item -> artist, title, description
  const getItemCol = (row) => {
    let str = "";
    if (row?.author?.id) str = str.concat(`${row?.author?.description}, `);
    if (i18next?.resolvedLanguage === "pt") {
      str = row?.title_pt ? str.concat(`${row?.title_pt}`) : str.concat("");
      str = row?.description_pt ? str.concat(`, ${row?.description_pt}`) : str.concat("");
    } else {
      str = row?.title_en ? str.concat(`${row?.title_en}`) : str.concat("");
      str = row?.description_en ? str.concat(`, ${row?.description_en}`) : str.concat("");
    }
    return str || "-";
  };

  // handle item filter on reserved lot status
  const handleReservedFilter = (value, filterKey, setOptionTypes) => {
    if (typeof value === "boolean") {
      props?.setItemsPayload((prevState) => ({
        ...prevState,
        [filterKey]: value,
        page: 1,
      }));
    } else {
      props?.setItemsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload?.[filterKey];
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
            onChange={(event) => {
              if (selectedRows?.find((item) => item?.id === row?.id)) {
                setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
              } else {
                setSelectedRows((prevState) => [...prevState, row]);
              }
            }}
          />
        </Label>
      ),
      width: "68px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.contractNo")} / ${props.t("common.item")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item_no__icontains"
            className="column-search-input"
            payload={props.itemsPayload}
            setPayload={props.setItemsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_item_no ? (
          <CustomTextLink
            text={row?.contract_item_no}
            handleClick={() => navigateOnContractItemForm(row?.contract?.id, row?.id)}
          />
        ) : (
          "-"
        ),
      width: "166px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.default_photo} title={<CustomColumnPhoto sourceUrl={row?.default_photo} />} />
      ),
      width: "140px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={props.itemsPayload}
            setPayload={props.setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row ? getItemCol(row) : "-"),
      wrap: true,
      width: "248px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.auctionNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"auction_lots__auction__auction_no__icontains"}
            className="column-search-input"
            payload={props?.itemsPayload}
            setPayload={props?.setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.auction?.auction_no ? row?.auction?.auction_no : "-"),
      width: "154px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.lotNo")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"lot__lot_no__icontains"}
            className="column-search-input"
            payload={props?.itemsPayload}
            setPayload={props?.setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.lot?.lot_no ? row?.lot?.lot_no + row?.lot?.lot_alpha : "-"),
      width: "154px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.reserved")}</div>
          <Select
            isClearable
            className={`custom-select`}
            placeholder={props.t("common.select")}
            options={[...YES_NO_OPTIONS]}
            value={selectedReservedFilter}
            onChange={(option) => {
              setSelectedReservedFilter(option);
              handleReservedFilter(option?.value, "is_reserved_tag", setSelectedReservedFilter);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_reserved_tag ? props.t("common.yes") : props.t("common.no")),
      width: "154px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.agreedValue")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedAgreedOptions}
            handleOnSelection={(values) => {
              setSelectedAgreedOptions(values);
              handleDropdownFilter(values, "value_to_be_agreed__in", setSelectedAgreedOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.value_to_be_agreed ? props.t("common.yes") : props.t("common.no")),
      width: "154px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__sale_value__icontains"
            className="column-search-input"
            payload={props.itemsPayload}
            setPayload={props.setItemsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.is_reserved_tag ? props.t("common.reserved") : formatAmount(row?.auction_lots?.sale_value || 0) + " €",
      width: "154px",
      wrap: true,
    },
  ];

  return (
    <>
      <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
        <span className="card-title">{props.t("common.availableLots")}</span>
        <div className="contain-header-right d-flex flex-row gap-2">
          {(isAllSelected && totalLots > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${props.t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalLots}
                </Badge>
              </Button>
            </>
          ) : null}
          <CustomButton
            type="button"
            disabled={(!(isAllSelected && totalLots > 0) && selectedRows.length === 0) || createDocumentLoader}
            btnTitle={props.t("common.link")}
            onClick={() => handleLinkToAuctionModalOpen()}
          />
          <CustomButton
            type="button"
            isLoading={createDocumentLoader}
            disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
            btnTitle={props.t("common.return")}
            onClick={() => {
              handleCreateDocument(RETURN_GUIDE_DOCUMENT);
            }}
          />
        </div>
      </CardHeader>
      <CardBody className="card-section-body">
        {tableLoader ? <Loader /> : null}
        <DataTable
          pointerOnHover
          fixedHeader
          className="data-table"
          persistTableHead
          columns={columns}
          data={lots}
          pagination
          paginationServer
          paginationTotalRows={totalLots}
          paginationDefaultPage={props.itemsPayload?.page}
          paginationPerPage={props.itemsPayload?.limit}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            rowsPerPageText: props.t("common.rowsPerPage"),
            rangeSeparatorText: props.t("common.rangeSeparator"),
          }}
        />
      </CardBody>
      {/* link to auction modal */}
      <LinkToAuctionModal
        isModalOpen={isLinkToAuctionModalOpen}
        loader={linkLoader}
        onCancel={handleLinkToAuctionModalClose}
        onConfirm={handleLinkToAuction}
        title={props.t("common.linkToAuction")}
      />
    </>
  );
};

export default withTranslation()(AvailableLots);
