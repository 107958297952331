import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { TYPE_SAVE } from "../../helpers/constants";
import { getFormattedDataForWebsitePages } from "../../helpers/utils";
import { SET_AUCTION_EDITION, SET_PAGE_DATA, SET_PAGE_INFORMATION } from "./actionTypes";

const handleDocumentsFormDataAppend = (payload) => {
  const formData = new FormData();

  payload.forEach((obj, index) => {
    formData.append(`id-${index}`, obj.id || "");
    formData.append(`website_page-${index}`, obj.website_page || "");
    formData.append(`language_type-${index}`, obj.language_type || "");
    formData.append(`save_type-${index}`, obj.save_type || "");
    formData.append(`file_type-${index}`, obj.file_type || "");
    formData.append(`page_type-${index}`, obj.page_type || "");
    formData.append(`field1-${index}`, obj.field1 || "");
    formData.append(`field2-${index}`, obj.field2 || "");
    formData.append(`link-${index}`, obj.link || "");
    formData.append(`meta-${index}`, obj.meta || "");
    formData.append(`file-${index}`, obj.file || "");
    formData.append(`order_no-${index}`, obj.order_no || "");
    formData.append(`position-${index}`, obj.position || "");
  });

  return formData;
};

// to get the website page's information
export const getWebsitePagesInformation = (params, pageType) => async (dispatch) => {
  try {
    return API.get(`/website-management/data`, {
      params: { ...params },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PAGE_INFORMATION,
          payload: getFormattedDataForWebsitePages(res?.data?.data?.results, pageType),
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to upload website datas
export const uploadWebsitePagesInformation = (payload, pageType, save_type) => async (dispatch) => {
  try {
    let formData = payload;
    if (save_type === TYPE_SAVE) {
      formData = handleDocumentsFormDataAppend(payload);
    }
    return API.post(`/website-management/data`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        if (save_type === TYPE_SAVE) {
          dispatch({
            type: SET_PAGE_INFORMATION,
            payload: getFormattedDataForWebsitePages(res?.data?.data, pageType),
          });
          toast.success(res?.data?.detail);
        } else {
          if (!res?.data?.is_en_remaining && !res?.data?.is_pt_remaining) {
            toast.success(res?.data?.detail);
          }
        }
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete website pages datas
export const deleteWebsitePagesInformation = (payload) => async (dispatch) => {
  try {
    return API.delete(`/website-management/data`, { data: payload }).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the website pages
export const getWebsitePages = (params) => async (dispatch) => {
  try {
    return API.get(`/website-management/pages`, { params: { ...params } }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_PAGE_DATA,
          payload: res?.data?.data,
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// for auction edition
const getAuctionEditionData = (type, data) => {
  if (type === "catalogue") {
    return {
      issuu_link: data?.issuu_link,
      pdf_catalogue: data?.pdf_catalogue,
      pdf_erratum: data?.pdf_erratum,
      spine_color: data?.spine_color,
    };
  } else if (type === "info") {
    return {
      auction_text: data?.auction_text,
      information: data?.information,
      highlights: data?.highlights,
      information_title: data?.information_title,
    };
  }
};

// form data append handler for adding/editing auction
const handleFormDataAppend = (payload) => {
  const formData = new FormData();
  for (var key in payload) {
    formData.append(key, payload[key] || "");
  }
  return formData;
};

// to get the website auction edition content
export const getAuctionEdition = (auctionId) => async (dispatch) => {
  try {
    return API.get(`/website-management/auctions/${auctionId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_AUCTION_EDITION,
          payload: {
            type: "catalogue",
            data: getAuctionEditionData("catalogue", res?.data?.data),
          },
        });
        dispatch({
          type: SET_AUCTION_EDITION,
          payload: {
            type: "info",
            data: getAuctionEditionData("info", res?.data?.data),
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add website auction edition info
export const addAuctionEdition = (payload) => async (dispatch) => {
  try {
    let data = payload;
    if (payload?.category_type === "catalogue") {
      data = handleFormDataAppend(payload);
    }
    return API.post(`/website-management/auctions`, data).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: SET_AUCTION_EDITION,
          payload: {
            type: payload?.category_type,
            data: getAuctionEditionData(payload?.category_type, res?.data?.data),
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update website auction edition info
export const updateAuctionEdition = (auctionId, payload) => async (dispatch) => {
  try {
    // const formData = handleDocumentsFormDataAppend(payload);
    return API.post(`/website-management/auctions/${auctionId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        // dispatch({
        //   type: SET_PAGE_INFORMATION,
        //   payload: getFormattedDataForWebsitePages(res?.data?.data, pageType),
        // });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
