import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import EditPermissoinModal from "../../../../components/pages/settings/EditPermissoinModal";
import { editPermission, getPermissions } from "../../../../store/permissoins/action";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const Permissions = (props) => {
  const dispatch = useDispatch();
  const { data: permissoinsData, total: totalPermissions } = useSelector((state) => state.Permissions);

  const [isGetPermissionLoader, setIsGetPermissionLoader] = useState(false);
  const [isEditPermissionModalOpen, setIsEditPermissionModalOpen] = useState(false);
  const [permissionSaveLoader, setPermissionSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [permissionPayload, setPermissionPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {
      id__icontains: "",
      code__icontains: "",
      description__icontains: "",
    },
  });

  // for sorting
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.permissions")}`;

  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setPermissionPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  const handleGetPermissionList = useCallback(
    (permissionPayload) => {
      setIsGetPermissionLoader(true);
      dispatch(getPermissions(permissionPayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setIsGetPermissionLoader(false);
        })
        .catch((error) => {
          setIsGetPermissionLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPermissionList(permissionPayload);
  }, [handleGetPermissionList, permissionPayload]);

  const handleSavePermission = (values, validation) => {
    setPermissionSaveLoader(true);

    if (selectedRow && selectedRow.id) {
      // dispatch for editing page
      dispatch(editPermission({ id: selectedRow.id, values: values }))
        .then((res) => {
          if (res) {
            //
            handleGetPermissionList(permissionPayload);
            validation.resetForm();
            setIsEditPermissionModalOpen(false);
          } else {
            //
          }
          setPermissionSaveLoader(false);
        })
        .catch((error) => {
          setIsEditPermissionModalOpen(false);
          setPermissionSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setPermissionPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPermissionPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const permissionEditHandler = (row) => {
    setIsEditPermissionModalOpen(true);
    setSelectedRow(row);
  };

  const handlePermissionModalClose = () => {
    setIsEditPermissionModalOpen(false);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={permissionPayload}
            setPayload={setPermissionPayload}
          />
        </div>
      ),
      selector: (row) => row.id,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          {/* <div className="table-column-title mb-2">
            {props.t("common.code")}
          </div> */}
          <CustomColumnHeader
            columnTitle={props.t("common.code")}
            sortable
            sortableKey="code"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code__icontains"
            className="column-search-input"
            payload={permissionPayload}
            setPayload={setPermissionPayload}
          />
        </div>
      ),
      selector: (row) => row.code,
      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={permissionPayload}
            setPayload={setPermissionPayload}
          />
        </div>
      ),
      selector: (row) => row.description,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.edit")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => permissionEditHandler(row)}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
        </div>
      ),
      minWidth: "100px",
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={permissionPayload}
            setPayload={setPermissionPayload}
          />
        </div>
        <Card className="container-body">
          {isGetPermissionLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.permissions")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...permissoinsData]}
            pagination
            paginationServer
            paginationTotalRows={totalPermissions}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* to add /edit languages */}
      <EditPermissoinModal
        isModalOpen={isEditPermissionModalOpen}
        permission={selectedRow}
        onCancel={handlePermissionModalClose}
        onSave={handleSavePermission}
        loader={permissionSaveLoader}
      />
    </>
  );
};

export default withTranslation()(Permissions);
