import i18next from "i18next";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import TabsComponent from "../../../common/TabsComponent";
import ContactUSContent from "./ContactUSContent";
import WhoWeAreContent from "./WhoWeAreContent";

const ContactsContent = (props) => {
  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.contactUsValidation?.resetForm();
      props?.whoWeAreValidation?.resetForm();
    };
  }, []);

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (props?.isFormDirty()) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        props?.setActivePageIndex(index);
      }
    } else {
      props?.setActivePageIndex(index);
    }
  };

  return (
    <div className="contacts-main-content">
      <TabsComponent
        controlledTabChange={true}
        activeTab={props?.activePageIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabStyleClasses="sub-tabs-style"
        tabData={[
          {
            label: i18next.t("common.whoWeAre"),
            Component: WhoWeAreContent,
            props: { pageType: "who_we_are", ...props },
            disabled: false,
          },
          {
            label: i18next.t("common.contactUs"),
            Component: ContactUSContent,
            props: { pageType: "contact_us", ...props },
            disabled: false,
          },
        ]}
      />
    </div>
  );
};

export default withTranslation()(ContactsContent);
