import { FieldArray } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Row } from "reactstrap";
import ActionButton from "../../common/ActionButton";
import CustomAddButton from "../../common/CustomAddButton";
import DeleteModal from "../../common/DeleteModal";
import MessageBlock from "../../common/MessageBlock";
import NumberInput from "../../common/NumberInput";

const DEFAULT_SESSION_ITEM = {
  date: "",
  hour: "",
  first_lot: "",
  last_lot: "",
};

const SessionsForm = (props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExpenseIndex, setSelectedExpenseIndex] = useState(null);

  // to bind the array helpers of the session items field array(formik).
  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  // delete modal open handler
  const handleDeleteModalOpen = (index) => {
    setSelectedExpenseIndex(index);
    setIsDeleteModalOpen(true);
  };
  // delete modal close handler
  const handleDeleteModalClose = () => {
    setSelectedExpenseIndex(null);
    setIsDeleteModalOpen(false);
  };
  // handle delete
  const handleDelete = () => {
    boundArrayHelpers.remove(selectedExpenseIndex);
    handleDeleteModalClose();
  };

  return (
    <>
      <div className="card-subtable-section table-light session-form-wrapper">
        <Row className="p-3 subtable-header subtable-row">
          <Col md={2} lg={1}>
            {`${props.t("common.session")} ${props.t("common.no_")}`}
          </Col>
          <Col md={3} lg={3}>
            {props.t("common.date")}
          </Col>
          <Col md={2} lg={2}>
            {props.t("common.hour")}
          </Col>
          <Col md={2} lg={2}>
            {props.t("common.firstLot")}
          </Col>
          <Col md={2} lg={2}>
            {props.t("common.lastLot")}
          </Col>
          <Col md={1} lg={2}></Col>
        </Row>
        <hr />
        <div className="p-3 subtable-container">
          <FieldArray
            name="sessions"
            render={(arrayHelpers) => {
              bindArrayHelpers(arrayHelpers);
              return props.validation.values.sessions?.length !== 0 ? (
                props.validation.values.sessions?.map((session, index) => {
                  return (
                    <Row key={index} className="subtable-row mb-3">
                      <Col md={2} lg={1}>
                        <Input disabled placeholder={index + 1} />
                      </Col>
                      <Col md={3} lg={3}>
                        <Input
                          name={`sessions[${index}].date`}
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.date")}`}
                          type="date"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.sessions?.[index]?.date}
                          invalid={
                            props.validation.touched.sessions?.[index]?.date &&
                            props.validation.errors.sessions?.[index]?.date
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.sessions?.[index]?.date &&
                        props.validation.errors.sessions?.[index]?.date ? (
                          <FormFeedback type="invalid">{props.validation.errors.sessions?.[index]?.date}</FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} lg={2}>
                        <Input
                          name={`sessions[${index}].hour`}
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.hour")}`}
                          type="time"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.sessions?.[index]?.hour}
                          invalid={
                            props.validation.touched.sessions?.[index]?.hour &&
                            props.validation.errors.sessions?.[index]?.hour
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.sessions?.[index]?.hour &&
                        props.validation.errors.sessions?.[index]?.hour ? (
                          <FormFeedback type="invalid">{props.validation.errors.sessions?.[index]?.hour}</FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} lg={2}>
                        <NumberInput
                          name={`sessions[${index}].first_lot`}
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.firstLot")}`}
                          type="number"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.sessions?.[index]?.first_lot}
                          invalid={
                            props.validation.touched.sessions?.[index]?.first_lot &&
                            props.validation.errors.sessions?.[index]?.first_lot
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.sessions?.[index]?.first_lot &&
                        props.validation.errors.sessions?.[index]?.first_lot ? (
                          <FormFeedback type="invalid">
                            {props.validation.errors.sessions?.[index]?.first_lot}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} lg={2}>
                        <NumberInput
                          name={`sessions[${index}].last_lot`}
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.lastLot")}`}
                          type="number"
                          onChange={props.validation.handleChange}
                          onBlur={props.validation.handleBlur}
                          value={props.validation.values.sessions?.[index]?.last_lot}
                          invalid={
                            props.validation.touched.sessions?.[index]?.last_lot &&
                            props.validation.errors.sessions?.[index]?.last_lot
                              ? true
                              : false
                          }
                        />
                        {props.validation.touched.sessions?.[index]?.last_lot &&
                        props.validation.errors.sessions?.[index]?.last_lot ? (
                          <FormFeedback type="invalid">
                            {props.validation.errors.sessions?.[index]?.last_lot}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={1} lg={2}>
                        <ActionButton
                          onClick={() => {
                            handleDeleteModalOpen(index);
                          }}
                          tooltip={props.t("common.delete")}
                          iconClasses="ri-delete-bin-fill fs-18"
                          iconColor="red"
                          buttonId={`delete-action-${index}`}
                        />
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <MessageBlock
                  fontSize="14px"
                  height="50px"
                  message={props.t("common.addTitle", {
                    module: props.t("common.sessions"),
                  })}
                />
              );
            }}
          />
        </div>
        <hr />
        <div className="p-2 subtable-footer">
          <div className="d-flex justify-content-end">
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.session"),
              })}
              onClick={() => {
                boundArrayHelpers.push(DEFAULT_SESSION_ITEM);
              }}
            />
          </div>
        </div>
      </div>
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDelete}
        title={`${props.t("common.delete")} ${props.t("common.session")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.session"),
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(SessionsForm);
