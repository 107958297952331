import { SET_WAREHOUSELIST } from "../actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Warehouse = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_WAREHOUSELIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default Warehouse;
