import { SET_CONTRACT_ITEM_LIST } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const ItemList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_ITEM_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        all_ids: action.payload?.all_ids,
      };
    default:
      return { ...state };
  }
};

export default ItemList;
