import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";

import ActionButton from "../../../../components/common/ActionButton";
import CustomMultiSelect from "../../../../components/common/CustomMultiSelect";
import AddCollaborator from "../../../../components/pages/settings/AddCollaborator";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";
import { SELECT_ALL_OPTION } from "../../../../helpers/constants/selectOptions";
import { checkSelectAllSelected, getLabelValueArray, getValuesFromArray } from "../../../../helpers/utils";
import {
  addCollaborator,
  deleteCollaborator,
  editCollaborator,
  getAuctionCollaborators,
} from "../../../../store/auction-collaborators/actions";
import { getSpokenLanguages } from "../../../../store/general-user-settings/action";

const AuctionCollaborators = (props) => {
  const dispatch = useDispatch();
  const { data: collaborators, total: totalCollaborators } = useSelector((state) => state.Collaborators);
  const SPOKEN_LANGUAGELIST = getLabelValueArray(
    useSelector((state) => state.GeneralUserSettings.spokenLanguageList),
    "id",
    "code"
  );

  const [listLoader, setListLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCollaboratorModalOpen, setIsCollaboratorModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [collaboratorsPayload, setCollaboratorsPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {},
    sort: "id",
  });
  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState([]);
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);
  const [languagesSearchLoader, setLanguagesSearchLoader] = useState(false);
  const [languagesPayload, setLanguagesPayload] = useState({ is_spoken: true });

  //   to get the list of languages
  useEffect(() => {
    setLanguagesSearchLoader(true);
    dispatch(getSpokenLanguages(languagesPayload, "code")).then(() => {
      setLanguagesSearchLoader(false);
    });
  }, [languagesPayload, dispatch]);

  // handler for calling listing collaborators
  const handleGetCollaboratorsList = useCallback(
    (collaboratorsPayload) => {
      setListLoader(true);
      dispatch(getAuctionCollaborators(collaboratorsPayload))
        .then((res) => {
          setListLoader(false);
        })
        .catch((error) => {
          setListLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCollaboratorsList(collaboratorsPayload);
  }, [handleGetCollaboratorsList, collaboratorsPayload]);

  // handler for calling deleting collaborators
  const handleDeleteCollaborator = () => {
    if (selectedCollaborator?.id) {
      setDeleteLoader(true);
      dispatch(deleteCollaborator(selectedCollaborator?.id))
        .then((res) => {
          if (res) {
            handleGetCollaboratorsList(collaboratorsPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          handleDeleteModalClose();
          setDeleteLoader(false);
        });
      // dispatch(
      //   deleteWarehouse({
      //     id: selectedCollaborator,
      //     payload: { is_admin: isDeleteActionAllowed },
      //   })
      // )
      //   .then((res) => {
      //     if (res.data.status === 200) {
      //       if (!res?.data?.data?.action_allowed) {
      //         handleDeleteModalClose();
      //       }
      //       setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
      //       toast.warn(res?.data?.detail);
      //       setDeleteLoader(false);
      //     } else if (res.data.status === 204) {
      //       toast.success(res?.data?.detail);
      //       handleGetCollaboratorsList(collaboratorsPayload);
      //       handleDeleteModalClose();
      //       setDeleteLoader(false);
      //     }
      //   })
      //   .catch((error) => {
      //     setDeleteLoader(false);
      //     setIsDeleteModalOpen(false);
      //   });
    }
  };

  // handler for calling create/edit collaborators
  const handleSaveCollaborator = (values) => {
    const payload = {
      ...values,
      languages_speaks: values?.languages_speaks?.map((lang) => lang?.id),
    };
    setSaveLoader(true);
    if (selectedCollaborator && selectedCollaborator.id) {
      // dispatch for editing collaborator
      dispatch(editCollaborator(selectedCollaborator?.id, payload))
        .then((res) => {
          if (res) {
            handleGetCollaboratorsList(collaboratorsPayload);
            handleWarehouseModalClose();
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          handleWarehouseModalClose();
          setSaveLoader(false);
        });
    } else {
      // dispatch for adding new collaborator
      dispatch(addCollaborator(payload))
        .then((res) => {
          if (res) {
            handleGetCollaboratorsList(collaboratorsPayload);
            handleWarehouseModalClose();
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          handleWarehouseModalClose();
          setSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setCollaboratorsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCollaboratorsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleDeleteModalOpen = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedCollaborator(row);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setIsDeleteModalOpen(false);
    setSelectedCollaborator(null);
  };

  const handleWarehouseModalOpen = (row) => {
    setIsCollaboratorModalOpen(true);
    setSelectedCollaborator(row);
  };
  const handleWarehouseModalClose = () => {
    setIsCollaboratorModalOpen(false);
    setSelectedCollaborator(null);
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setCollaboratorsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setCollaboratorsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setCollaboratorsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={collaboratorsPayload}
            setPayload={setCollaboratorsPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,

      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="name__icontains"
            className="column-search-input"
            payload={collaboratorsPayload}
            setPayload={setCollaboratorsPayload}
          />
        </div>
      ),
      selector: (row) => row?.name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.spokenLanguages")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...SPOKEN_LANGUAGELIST]}
            selectedOptions={selectedLanguageOptions}
            handleOnSelection={(values) => {
              setSelectedLanguageOptions(values);
              handleDropdownFilter(values, "languages_speaks__in", setSelectedLanguageOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.languages_speaks?.length > 0 ? row?.languages_speaks?.map((lang) => lang?.code)?.join(", ") : "-",
      wrap: true,
      minWidth: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.iban")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="iban_no__icontains"
            className="column-search-input"
            payload={collaboratorsPayload}
            setPayload={setCollaboratorsPayload}
          />
        </div>
      ),
      selector: (row) => row?.iban_no,

      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            <div className="table-actions">
              <ActionButton
                iconClasses="ri-pencil-fill fs-18"
                buttonId={`tooltip-${row?.id}-edit`}
                tooltip={props.t("common.edit")}
                onClick={() => handleWarehouseModalOpen(row)}
              />
              <ActionButton
                iconClasses="ri-delete-bin-fill fs-18"
                buttonId={`tooltip-${row?.id}-delete`}
                tooltip={props.t("common.delete")}
                onClick={() => handleDeleteModalOpen(row)}
              />
            </div>
          </div>
        </div>
      ),

      minWidth: "150px",
      center: true,
      wrap: true,
    },
  ];

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.auctionCollaborators")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={collaboratorsPayload}
            setPayload={setCollaboratorsPayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleWarehouseModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">
                {`${props.t("common.add")} ${props.t("common.auctionCollaborator")}`}
              </span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {listLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.auctionCollaborators")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            columns={columns}
            data={[...collaborators]}
            pagination
            paginationServer
            paginationTotalRows={totalCollaborators}
            paginationPerPage={collaboratorsPayload?.limit}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            persistTableHead
          />
        </Card>
      </div>

      {/* to add /edit collaborator */}
      <AddCollaborator
        isModalOpen={isCollaboratorModalOpen}
        collaborator={selectedCollaborator}
        onCancel={handleWarehouseModalClose}
        onSave={handleSaveCollaborator}
        loader={saveLoader}
      />

      {/* delete modal for collaborator */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("common.auctionCollaborator")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.auctionCollaborator"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onConfirmBtnHandler={handleDeleteCollaborator}
        onCloseBtnHandler={handleDeleteModalClose}
      />
    </>
  );
};

export default withTranslation()(AuctionCollaborators);
