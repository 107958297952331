import { Form, FormikProvider } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Label, Row } from "reactstrap";
import { SELLING_CONTENT, TYPE_SAVE } from "../../../../helpers/constants";
import { checkImageFileValidation, checkVideoFileValidation } from "../../../../helpers/utils";
import { getWebsitePagesInformation } from "../../../../store/website-pages/actions";
import InformationFieldArray from "../../../common/InformationFieldArray";
import Loader from "../../../common/Loader";
import MultiMediaTableForm from "../../../common/MultiMediaTableForm";
import ImageCropperModal from "../../../common/ImageCropperModal";
import ActionButton from "../../../common/ActionButton";

const SellingContent = ({ languageType, pageType, website_page, ...props }) => {
  const dispatch = useDispatch();
  const [infoLoader, setInfoLoader] = useState(false);
  const [infoPayload, setInfoPayload] = useState({
    filter: {
      website_page: website_page,
      language_type: languageType,
      page_type: pageType,
      save_type: TYPE_SAVE,
    },
  });
  const [isImageCropperModalOpen, setIsImageCropperModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const [selectedArrayKey, setSelectedArrayKey] = useState();

  // to get the home page information
  const handleGetPageInformation = useCallback(
    (payload) => {
      setInfoLoader(true);
      dispatch(getWebsitePagesInformation(payload, SELLING_CONTENT)).then(() => {
        setInfoLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPageInformation(infoPayload);
  }, [infoPayload, handleGetPageInformation]);

  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.sellingValidation?.resetForm();
    };
  }, []);

  // handle image modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsImageCropperModalOpen(true);
  };
  // handle image modal close
  const handleImageCropModalClose = () => {
    setIsImageCropperModalOpen(false);
  };

  // gets the cropped image file and sets in the form
  const handleImageCrop = (file) => {
    const selectedRowData = props?.sellingValidation?.values?.[selectedArrayKey]?.[selectedImageIndex];

    props?.sellingValidation?.setFieldValue(`${selectedArrayKey}.${selectedImageIndex}`, {
      ...selectedRowData,
      file,
    });
    handleImageCropModalClose();
  };

  return (
    <FormikProvider value={props?.sellingValidation}>
      <Form
        className="position-relative"
        onSubmit={(e) => {
          e.preventDefault();
          props?.sellingValidation.handleSubmit();
          return false;
        }}
      >
        {infoLoader ? <Loader /> : null}

        {/* banners */}
        <Row>
          <div className="mb-3">
            <Label className="form-label fw-bold">{`${props.t("common.banner")}`}</Label>
            <MultiMediaTableForm
              hideAddButton
              hideDeleteAction
              validation={props?.sellingValidation}
              arrayKey="banner"
              sectionName={props.t("common.banner")}
              defaultRowValues={{ file: "", title: "", subtitle: "", link: "" }}
              columns={[
                {
                  label: props.t("common.no_"),
                  spacing: { sm: 1, md: 1, lg: 1 },
                  type: "counter",
                },
                {
                  key: "file",
                  label: `${props.t("common.image")}/${props.t("common.video")}`,
                  actionButtons: [
                    {
                      Component: (index, arrayKey) => (
                        <ActionButton
                          iconClasses="ri-crop-2-fill fs-18 cursor-pointer"
                          buttonId={`tooltip-${arrayKey}-${index}-cropping`}
                          tooltip={props.t("common.cropImage")}
                          onClick={() => {
                            setSelectedImageIndex(index);
                            setSelectedArrayKey(arrayKey);
                            handleLinkToAuctionModalOpen();
                          }}
                        />
                      ),
                      fileType: "image",
                    },
                  ],
                  spacing: { sm: 4, md: 4, lg: 4 },
                  type: "file",
                  accept: "image/*,video/*",
                  checkFileTypeValidation: (fileName) => {
                    return checkImageFileValidation(fileName) || checkVideoFileValidation(fileName);
                  },
                },
                {
                  key: "title",
                  label: props.t("common.title"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "subtitle",
                  label: props.t("common.subTitle"),
                  spacing: { sm: 2, md: 2, lg: 2 },
                  type: "text",
                },
                {
                  key: "link",
                  label: props.t("common.link"),
                  spacing: { sm: 3, md: 3, lg: 3 },
                  type: "text",
                },
              ]}
            />
          </div>
        </Row>

        {/* information */}
        <InformationFieldArray
          validation={props?.sellingValidation}
          arrayKey={"meta_info"}
          errorsInInfoFields={props.errorsInInfoFields}
          blocks={[
            {
              label: props.t("common.informationNumber", { number: 1 }),
              key: "information_1",
            },
            {
              label: props.t("common.informationNumber", { number: 2 }),
              key: "information_2",
            },
            {
              label: props.t("common.informationNumber", { number: 3 }),
              key: "information_3",
            },
          ]}
        />
      </Form>
      <ImageCropperModal
        isModalOpen={isImageCropperModalOpen}
        onCancel={handleImageCropModalClose}
        title={props.t("common.cropImage")}
        src={props?.sellingValidation?.values?.[selectedArrayKey]?.[selectedImageIndex]?.file}
        handleImageCrop={handleImageCrop}
      />
    </FormikProvider>
  );
};

export default SellingContent;
