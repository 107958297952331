import React, { useCallback, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import Slider from "react-rangeslider";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, CardHeader } from "reactstrap";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddVatModal from "../../../../components/pages/settings/AddVatModal";
import { getLabelValueArray, getValuesFromArray } from "../../../../helpers/utils";
import { getCountriesOptions } from "../../../../store/common/actions";
import { addVAT, deleteVAT, getVATs, updateVAT } from "../../../../store/vat/actions";

const VAT = (props) => {
  const dispatch = useDispatch();
  const { data: vatsData, total: totalVATs } = useSelector((state) => state?.VAT);
  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);

  const [vatPayload, setVatPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: {
      id__icontains: "",
      code__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedVat, setSelectedVat] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isVatModalOpen, setIsVatModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [rangeSliderValue, setRangeSliderValue] = useState(100);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // add or edit action handlers
  const handleVatModalOpen = (vatData) => {
    setSelectedVat(vatData);
    setIsVatModalOpen(true);
  };
  const handleVatModalClose = () => {
    setSelectedVat(null);
    setIsVatModalOpen(false);
  };
  const handleSaveVat = (values, formikValidation) => {
    // check for add or edit
    if (selectedVat && selectedVat?.id) {
      // edit currency
      setSaveLoader(true);
      dispatch(updateVAT(selectedVat?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleVatModalClose();
            formikValidation.resetForm();
            handleGetVats(vatPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add currency
      setSaveLoader(true);
      dispatch(addVAT(values))
        .then((res) => {
          if (res) {
            // success handler
            handleVatModalClose();
            formikValidation.resetForm();
            handleGetVats(vatPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (vatData) => {
    setSelectedVat(vatData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setSelectedVat(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteVat = () => {
    if (selectedVat && selectedVat?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteVAT({
          id: selectedVat?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetVats(vatPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setVatPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle country type filter
  const handleCoutryTypeFilter = (values) => {
    if (values.length > 0) {
      setVatPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          country__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setVatPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.country__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const handleRateRangeFilter = (value) => {
    if (value !== rangeSliderValue) {
      setRangeSliderValue(value);
      if (value >= 0) {
        setVatPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            rate__range: [0, value],
          },
          page: 1,
        }));
      } else {
        setVatPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter.rate__range;
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setVatPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get vats
  const handleGetVats = useCallback(
    (vatPayload) => {
      setTableLoader(true);
      dispatch(getVATs(vatPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  //   to get the list of the vats
  useEffect(() => {
    handleGetVats(vatPayload);
  }, [handleGetVats, vatPayload]);

  //   to get the list of the countries options
  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
  }, [dispatch]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={vatPayload}
            setPayload={setVatPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code__icontains"
            className="column-search-input"
            payload={vatPayload}
            setPayload={setVatPayload}
          />
        </div>
      ),
      selector: (row) => row?.code,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.country")}
            sortable
            sortableKey="country"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            className="custom-select"
            isMulti
            options={getLabelValueArray(contriesOptions, "id", "name")}
            onChange={handleCoutryTypeFilter}
          />
        </div>
      ),
      selector: (row) => (
        <ReactCountryFlag
          countryCode={row?.country?.code}
          svg
          style={{
            width: "2em",
            height: "2em",
          }}
          title={row?.country?.name}
        />
      ),
      minWidth: "150px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.rate")}
            sortable
            sortableKey="rate"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between mb-2">
              <span className="text-muted fs-12">0</span>
              <span className="text-muted fs-12">100%</span>
            </div>
            <Slider
              className="custom-range-slider"
              min={0}
              max={100}
              step={10}
              value={rangeSliderValue}
              onChange={handleRateRangeFilter}
            />
          </div>
        </div>
      ),
      selector: (row) => row?.rate,
      minWidth: "150px",
    },

    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={vatPayload}
            setPayload={setVatPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleVatModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.vat")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput className="custom-search-input" payload={vatPayload} setPayload={setVatPayload} />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleVatModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">{props.t("settings.billing.vat.addVAT")}</span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.vat")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...vatsData]}
            pagination
            paginationServer
            paginationTotalRows={totalVATs}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddVatModal
        vat={selectedVat}
        isModalOpen={isVatModalOpen}
        onCancel={handleVatModalClose}
        onSave={handleSaveVat}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteVat}
        title={`${props.t("common.delete")} ${props.t("sidebarMenu.vat")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("sidebarMenu.vat"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(VAT);
