import React from "react";
import CustomDateRangePicker from "./CustomDateRangePicker";

const DateRangeInput = ({ inputValue, rangeValue, onChange, onClear, classes }) => {
  return (
    <div className={classes}>
      <CustomDateRangePicker inputValue={inputValue} onChange={onChange} rangeValue={rangeValue} />
      <span
        className={`mdi mdi-close-circle search-icon clear-icon ${inputValue.length > 0 ? "" : "d-none"}`}
        id="search-close-options"
        onClick={onClear}
      ></span>
    </div>
  );
};

export default DateRangeInput;
