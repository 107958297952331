import React from "react";
import { Redirect } from "react-router-dom";

// Login page
import Login from "../pages/authentication/Login";

// Dashboard Page
import Dashboard from "../pages/dashboards";

// Home Page
import Tasks from "../pages/home/tasks";

// Auction Page
import Auctions from "../pages/auctions";
import AuctionForm from "../pages/auctions/AuctionForm";

// contracts
// contracts-pre-contracts
import PreContracts from "../pages/contracts/pre-contracts";
import PreContractForm from "../pages/contracts/pre-contracts/PreContractForm";
import PreContractItemForm from "../pages/contracts/pre-contracts/PreContractItemForm";

// contracts-contracts
import Contracts from "../pages/contracts/contracts";
import ContractForm from "../pages/contracts/contracts/ContractForm";
import ContractItemForm from "../pages/contracts/contracts/ContractItemForm";

// contracts-itemlist
import ItemList from "../pages/contracts/itemList";

// customers
import Customers from "../pages/customers";
import CustomerForm from "../pages/customers/CustomerForm";
import PersonaForm from "../pages/customers/PersonaForm";
import CustomerDocuments from "../pages/customers/CustomerDocuments";

// Settings - genral
import UserSettings from "../pages/settings/general/user";
import GeneralSettings from "../pages/settings/general/languages";
import PermissionsSettings from "../pages/settings/general/permissions";
import ReportSettings from "../pages/settings/general/report";
import Warehouse from "../pages/settings/general/warehouse";

// Settings - billings
import ProductsAndServices from "../pages/settings/billing/products&services";
import Currencies from "../pages/settings/billing/currencies";
import DocumentTypes from "../pages/settings/billing/document-types";
import DocumentStatus from "../pages/settings/billing/document-status";
import PaymentConditions from "../pages/settings/billing/payment-conditions";
import PaymentTypes from "../pages/settings/billing/payment-types";
import VAT from "../pages/settings/billing/vat";

// Settings - auctions
import AuctionTypes from "../pages/settings/auctions/auction-types";
import AuctionLocations from "../pages/settings/auctions/locations";
import AuctionTheme from "../pages/settings/auctions/auction-theme";
import Auctiontags from "../pages/settings/auctions/auction-tags";
import AuctionCollaborators from "../pages/settings/auctions/auction-collaborators";

// settings - lots
import Categories from "../pages/settings/lots/categories";
import Artists from "../pages/settings/lots/artists";
import Periods from "../pages/settings/lots/periods";
import Templates from "../pages/settings/lots/templates";
import Tags from "../pages/settings/lots/tags";

// invoicing
import Invoicing from "../pages/invoicing";
import InvoicingForm from "../pages/invoicing/InvoicingForm";

// reports
import Reports from "../pages/reports";

// website management
import Pages from "../pages/website-management/pages";
import Files from "../pages/website-management/files";
import WebAuctions from "../pages/website-management/website-auctions";
import AuctionEdition from "../pages/website-management/website-auctions/AuctionEdition";
import MyVeritas from "../pages/website-management/my-veritas";
import FAQs from "../pages/website-management/FAQs";

// 404 page
import NotFoundPage from "../pages/error/404Page";
import CustomerSituation from "../pages/customers/CustomerSituation";
import ContractSituation from "../pages/contracts/contracts/ContractSituation";
import AuctionSituations from "../pages/auctions/AuctionSituations";
import Titles from "../pages/settings/general/titles";
import { ADMIN, COMUNICATIONS, EXTERNAL, GENERAL, TRANSLATOR } from "../helpers/constants";
import Unauthorized from "../pages/error/Unauthorized";
import Settings from "../pages/settings/auctions/settings";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    exact: true,
    component: () => <Dashboard />,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  // home routes
  // {
  //   path: "/home",
  //   exact: true,
  //   component: () => <Redirect to="/home/tasks" />,
  // },
  {
    path: "/tasks",
    exact: true,
    component: Tasks,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  // customer routes
  {
    path: "/customers",
    exact: true,
    component: Customers,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/new",
    exact: true,
    component: CustomerForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/edit/:paramId",
    exact: true,
    component: CustomerForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/:paramId/checking-account",
    exact: true,
    component: CustomerDocuments,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/:paramId/customer-situation",
    exact: true,
    component: CustomerSituation,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/:paramId/new-person",
    exact: true,
    component: PersonaForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/customers/:paramId/edit-person/:personId",
    exact: true,
    component: PersonaForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  // Auction routes
  {
    path: "/auctions",
    exact: true,
    component: Auctions,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/auctions/new",
    exact: true,
    component: AuctionForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/auctions/edit/:id",
    exact: true,
    component: AuctionForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/auctions/:auctionId/auction-situation",
    exact: true,
    component: AuctionSituations,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/invoicing",
    exact: true,
    component: Invoicing,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/invoicing/new",
    exact: true,
    component: InvoicingForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/invoicing/edit/:id",
    exact: true,
    component: InvoicingForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/contracts",
    component: () => <Redirect to="/contracts/pre-contracts" />,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL],
  },
  {
    path: "/contracts/pre-contracts",
    exact: true,
    component: PreContracts,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL],
  },
  {
    path: "/contracts/pre-contracts/new",
    exact: true,
    component: PreContractForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/contracts/pre-contracts/edit/:id",
    exact: true,
    component: PreContractForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL],
  },
  {
    path: "/contracts/pre-contracts/:id/items/new",
    exact: true,
    component: PreContractItemForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL],
  },
  {
    path: "/contracts/pre-contracts/:id/items/edit/:itemId",
    exact: true,
    component: PreContractItemForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL],
  },
  {
    path: "/contracts/contracts",
    exact: true,
    component: Contracts,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/contracts/contracts/new",
    exact: true,
    component: ContractForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  {
    path: "/contracts/contracts/edit/:id",
    exact: true,
    component: ContractForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/contracts/contracts/:id/contract-situation",
    exact: true,
    component: ContractSituation,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/contracts/contracts/:id/items/new",
    exact: true,
    component: ContractItemForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/contracts/contracts/:id/items/edit/:itemId",
    exact: true,
    component: ContractItemForm,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/contracts/item-list",
    exact: true,
    component: ItemList,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  // reports
  {
    path: "/reports",
    exact: true,
    component: Reports,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL],
  },
  // website-management routes
  {
    path: "/website-management",
    component: () => <Redirect to="/website-management/pages/1" />,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/pages",
    component: () => <Redirect to="/website-management/pages/1" />,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/pages/:pageId",
    exact: true,
    component: Pages,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/files",
    exact: true,
    component: Files,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/website-auctions",
    exact: true,
    component: WebAuctions,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/website-auctions/:id/edition/:editionType",
    exact: true,
    component: AuctionEdition,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/my-veritas",
    exact: true,
    component: MyVeritas,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/website-management/FAQs",
    exact: true,
    component: FAQs,
    allowedRoles: [ADMIN, COMUNICATIONS],
  },
  {
    path: "/settings",
    component: () => <Redirect to="/settings/general/report" />,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general",
    component: () => <Redirect to="/settings/general/report" />,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/users",
    exact: true,
    component: UserSettings,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/languages",
    exact: true,
    component: GeneralSettings,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/permissions",
    exact: true,
    component: PermissionsSettings,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/report",
    exact: true,
    component: ReportSettings,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/warehouse",
    exact: true,
    component: Warehouse,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/general/titles",
    exact: true,
    component: Titles,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing",
    component: () => <Redirect to="/settings/billing/products-and-services" />,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/products-and-services",
    exact: true,
    component: ProductsAndServices,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/currencies",
    exact: true,
    component: Currencies,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/document-types",
    exact: true,
    component: DocumentTypes,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/document-status",
    exact: true,
    component: DocumentStatus,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/payment-conditions",
    exact: true,
    component: PaymentConditions,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/payment-types",
    exact: true,
    component: PaymentTypes,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/billing/VAT",
    exact: true,
    component: VAT,
    allowedRoles: [ADMIN],
  },

  // settings -> auction routes
  {
    path: "/settings/auction",
    component: () => <Redirect to="/settings/auction/locations" />,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/locations",
    exact: true,
    component: AuctionLocations,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/auction-types",
    exact: true,
    component: AuctionTypes,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/auction-theme",
    exact: true,
    component: AuctionTheme,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/auction-tags",
    exact: true,
    component: Auctiontags,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/auction-collaborators",
    exact: true,
    component: AuctionCollaborators,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/auction/settings",
    exact: true,
    component: Settings,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots",
    component: () => <Redirect to="/settings/lots/categories" />,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots/categories",
    exact: true,
    component: Categories,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots/artists",
    exact: true,
    component: Artists,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots/periods",
    exact: true,
    component: Periods,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots/templates",
    exact: true,
    component: Templates,
    allowedRoles: [ADMIN],
  },
  {
    path: "/settings/lots/tags",
    exact: true,
    component: Tags,
    allowedRoles: [ADMIN],
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "/unauthorized",
    exact: true,
    component: Unauthorized,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
  {
    path: "*",
    component: NotFoundPage,
    allowedRoles: [ADMIN, COMUNICATIONS, GENERAL, EXTERNAL, TRANSLATOR],
  },
];

const publicRoutes = [{ path: "/login", component: Login }];

export { authProtectedRoutes, publicRoutes };
