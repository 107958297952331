import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Select from "react-select";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { getLabelValueArray, getTypeFromValue, scrollToFirstFormikError } from "./../../../helpers/utils";
import { useSelector } from "react-redux";
import NumberInput from "../../common/NumberInput";

const AddVatModal = ({ vat, isModalOpen, onCancel, onSave, loader, t }) => {
  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data),
    "id",
    "name"
  );
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      code: vat?.code || "",
      country: vat?.country?.id || "",
      rate: vat?.rate,
      description: vat?.description || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .max(5, `${t("validation.maxChars", { totalChars: 5 })}`)
        .required(`${t("common.please")} ${t("common.enter")} ${t("common.code")}`),
      country: Yup.number().required(`${t("common.please")} ${t("common.select")} ${t("common.country")}`),
      rate: Yup.number()
        .min(0, t("validation.numberMin", { totalNumber: 0 }))
        .max(100, t("validation.numberMax", { totalNumber: 100 }))
        .required(`${t("common.please")} ${t("common.enter")} ${t("common.rate")}`),
      description: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.description")}`),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{vat?.id ? t("settings.billing.vat.editVAT") : t("settings.billing.vat.addVAT")}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="code" className="form-label">
              {t("common.code")}
            </Label>
            <Input
              name="code"
              className="form-control"
              placeholder={`${t("common.enter")}  ${t("common.code")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={validation.touched.code && validation.errors.code ? true : false}
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="country" className="form-label">
              {t("common.country")}
            </Label>
            <Select
              name="country"
              options={countriesOptions}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.country")}`}
              value={getTypeFromValue(countriesOptions, validation.values.country) || ""}
              onChange={(country) => {
                validation.setFieldValue("country", country?.value);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("country", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.country && validation.errors.country ? (
              <p className="custom-invalid-feedback">{validation.errors.country}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="rate" className="form-label">
              {t("common.rate")}
            </Label>
            <NumberInput
              name="rate"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.rate")}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.rate}
              invalid={validation.touched.rate && validation.errors.rate ? true : false}
            />
            {validation.touched.rate && validation.errors.rate ? (
              <FormFeedback type="invalid">{validation.errors.rate}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.description")}`}
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddVatModal);
