import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Card, CardHeader } from "reactstrap";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddDocumentTypeModal from "../../../../components/pages/settings/AddDocumentTypeModal";
import { MOVEMENT_TYPES, PRINTED_TYPES } from "../../../../helpers/constants/selectOptions";
import { getMovementTypeFromValue, getPrintedTypeFromValue, getValuesFromArray } from "../../../../helpers/utils";
import {
  addDocumentType,
  deleteDocumentType,
  getDocumentTypes,
  updateDocumentType,
} from "../../../../store/document-types/actions";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const DocumentTypes = (props) => {
  const dispatch = useDispatch();
  const { data: documentTypesData, total: totalDocumentTypes } = useSelector((state) => state?.DocumentTypes);

  const [documentTypesPayload, setDocumentTypesPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "",
    filter: {
      id__icontains: "",
      fiscal_code__icontains: "",
      prefix__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDocumentTypeModalOpen, setIsDocumentTypeModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // add or edit action handlers
  const handleDocumentTypeModalOpen = (paymentData) => {
    setSelectedDocumentType(paymentData);
    setIsDocumentTypeModalOpen(true);
  };
  const handleDocumentTypeModalClose = () => {
    setSelectedDocumentType(null);
    setIsDocumentTypeModalOpen(false);
  };
  const handleSaveDocumentType = (values, formikValidation) => {
    // check for add or edit
    if (selectedDocumentType && selectedDocumentType?.id) {
      // edit document type
      setSaveLoader(true);
      dispatch(updateDocumentType(selectedDocumentType?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleDocumentTypeModalClose();
            formikValidation.resetForm();
            handleGetDocumentTypes(documentTypesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add document type
      setSaveLoader(true);
      dispatch(addDocumentType(values))
        .then((res) => {
          if (res) {
            // success handler
            handleDocumentTypeModalClose();
            formikValidation.resetForm();
            handleGetDocumentTypes(documentTypesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (paymentData) => {
    setSelectedDocumentType(paymentData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedDocumentType(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteDocumentType = () => {
    if (selectedDocumentType && selectedDocumentType?.id) {
      setDeleteLoader(true);
      dispatch(deleteDocumentType(selectedDocumentType?.id))
        .then((res) => {
          if (res) {
            handleGetDocumentTypes(documentTypesPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setDocumentTypesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setDocumentTypesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle movement type filter
  const handleMovementTypeFilter = (values) => {
    if (values.length > 0) {
      setDocumentTypesPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          movement__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setDocumentTypesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.movement__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handle printed type filter
  const handlePrintedTypeFilter = (values) => {
    if (values.length > 0) {
      setDocumentTypesPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          printed__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setDocumentTypesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.printed__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setDocumentTypesPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get document types
  const handleGetDocumentTypes = useCallback(
    (documentTypesPayload) => {
      setTableLoader(true);
      dispatch(getDocumentTypes(documentTypesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetDocumentTypes(documentTypesPayload);
  }, [handleGetDocumentTypes, documentTypesPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,

      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("settings.billing.documentTypes.fiscalCode")}
            sortable
            sortableKey="fiscal_code"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="fiscal_code__icontains"
            className="column-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.fiscal_code,

      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("settings.billing.documentTypes.prefix")}
            sortable
            sortableKey="prefix"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="prefix__icontains"
            className="column-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.prefix,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.descriptionPt")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.descriptionEn")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
        </div>
      ),
      selector: (row) => row?.description_en,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("settings.billing.documentTypes.movement")}
            sortable
            sortableKey="movement"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select className="custom-select" isMulti options={MOVEMENT_TYPES} onChange={handleMovementTypeFilter} />
        </div>
      ),
      selector: (row) => getMovementTypeFromValue(row?.movement)?.label,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("settings.billing.documentTypes.printed")}
            sortable
            sortableKey="printed"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select className="custom-select" isMulti options={PRINTED_TYPES} onChange={handlePrintedTypeFilter} />
        </div>
      ),
      selector: (row) => getPrintedTypeFromValue(row?.printed)?.label,
      minWidth: "150px",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDocumentTypeModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.documentTypes")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={documentTypesPayload}
            setPayload={setDocumentTypesPayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleDocumentTypeModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">{props.t("settings.billing.documentTypes.addDocumentType")}</span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.documentTypes")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...documentTypesData]}
            pagination
            paginationServer
            paginationTotalRows={totalDocumentTypes}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddDocumentTypeModal
        documentType={selectedDocumentType}
        isModalOpen={isDocumentTypeModalOpen}
        onCancel={handleDocumentTypeModalClose}
        onSave={handleSaveDocumentType}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteDocumentType}
        title={`${props.t("common.delete")} ${props.t("settings.billing.documentTypes.documentType")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.documentTypes.documentType_small"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(DocumentTypes);
