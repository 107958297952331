import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddPeriodModal from "../../../../components/pages/settings/AddPeriodModal";
import { addPeriod, deletePeriod, getPeriods, updatePeriod } from "../../../../store/periods/actions";

const Periods = (props) => {
  const dispatch = useDispatch();
  const { data: periodsData, total: totalPeriods } = useSelector((state) => state?.Periods);

  const [periodsPayload, setPeriodsPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    sort: "",
    filter: {
      id__icontains: "",
      title_en__icontains: "",
      title_pt__icontains: "",
    },
  });

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // add or edit action handlers
  const handlePeriodModalOpen = (periodData) => {
    setSelectedPeriod(periodData);
    setIsPeriodModalOpen(true);
  };
  const handlePeriodModalClose = () => {
    setSelectedPeriod(null);
    setIsPeriodModalOpen(false);
  };
  const handleSavePeriod = (values, formikValidation) => {
    // check for add or edit
    if (selectedPeriod && selectedPeriod?.id) {
      // edit period
      setSaveLoader(true);
      dispatch(updatePeriod(selectedPeriod?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handlePeriodModalClose();
            formikValidation.resetForm();
            handleGetPeriods(periodsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add period
      setSaveLoader(true);
      dispatch(addPeriod(values))
        .then((res) => {
          if (res) {
            // success handler
            handlePeriodModalClose();
            formikValidation.resetForm();
            handleGetPeriods(periodsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (periodData) => {
    setSelectedPeriod(periodData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedPeriod(null);
    setIsDeleteActionAllowed(false);
    setIsDeleteModalOpen(false);
  };
  const handleDeletePeriod = () => {
    if (selectedPeriod && selectedPeriod?.id) {
      setDeleteLoader(true);
      dispatch(
        deletePeriod({
          id: selectedPeriod?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetPeriods(periodsPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setPeriodsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPeriodsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setPeriodsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get periods
  const handleGetPeriods = useCallback(
    (periodsPayload) => {
      setTableLoader(true);
      dispatch(getPeriods(periodsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPeriods(periodsPayload);
  }, [handleGetPeriods, periodsPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={periodsPayload}
            setPayload={setPeriodsPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "130px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.descriptionEn")}
            sortable
            sortableKey="title_en"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="title_en__icontains"
            className="column-search-input"
            payload={periodsPayload}
            setPayload={setPeriodsPayload}
          />
        </div>
      ),
      selector: (row) => row?.title_en,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.descriptionPt")}
            sortable
            sortableKey="title_pt"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="title_pt__icontains"
            className="column-search-input"
            payload={periodsPayload}
            setPayload={setPeriodsPayload}
          />
        </div>
      ),
      selector: (row) => row?.title_pt,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handlePeriodModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.periods")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput className="custom-search-input" payload={periodsPayload} setPayload={setPeriodsPayload} />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handlePeriodModalOpen}
              btnTitle={props.t("common.addTitle", {
                module: props.t("settings.lots.periods.period"),
              })}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.periods")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...periodsData]}
            pagination
            paginationServer
            paginationTotalRows={totalPeriods}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddPeriodModal
        period={selectedPeriod}
        isModalOpen={isPeriodModalOpen}
        onCancel={handlePeriodModalClose}
        onSave={handleSavePeriod}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePeriod}
        title={`${props.t("common.delete")} ${props.t("settings.lots.periods.period")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.lots.periods.period"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Periods);
