import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_FAQS_DATA } from "../actionTypes";

export const getFAQsData = (params) => async (dispatch) => {
  try {
    return API.get(`/website-management/faqs`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        sort: params?.sort,
        search: params?.search,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_FAQS_DATA,
          payload: res?.data?.data,
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add FAQ
export const addFAQ = (payload) => async (dispatch) => {
  try {
    return API.post(`/website-management/faqs`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update FAQ
export const updateFAQ = (FAQId, payload) => async (dispatch) => {
  try {
    return API.put(`/website-management/faqs/${FAQId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete FAQ
export const deleteFAQ = (FAQId) => async (dispatch) => {
  try {
    return API.delete(`/website-management/faqs/${FAQId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
