import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";

import DeleteModal from "../../../../components/common/DeleteModal";

import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";

import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";

import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import AddAuctionThemeModal from "../../../../components/pages/settings/AddAuctionThemeModal";
import {
  addAuctionTheme,
  deleteAuctionTheme,
  editAuctionTheme,
  getAuctionTheme,
} from "../../../../store/auction-theme/actions";

const AuctionTheme = ({ t }) => {
  const dispatch = useDispatch();
  const { data: auctionThemeData, total: totalAuctionTheme } = useSelector((state) => state.AuctionTheme);

  const [auctionThemeListLoader, setAuctionThemeListLoader] = useState(false);
  const [isDeleteAuctionThemeModalOpen, setIsDeleteAuctionThemeModalOpen] = useState(false);
  const [isAddActionThemeModalOpen, setIsAddAuctionThemeModalOpen] = useState(false);
  const [auctionThemeDeleteLoader, setAuctionThemeDeleteLoader] = useState(false);
  const [auctionThemeSaveLoader, setAuctionThemeSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [auctionThemePayload, setAuctionThemePayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: {
      id__icontains: "",
      code_en__icontains: "",
      code_pt__icontains: "",
      description_en__icontains: "",
      description_pt__icontains: "",
    },
  });

  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // setting title of the page
  document.title = `Veritas | ${t("sidebarMenu.auctionThemes")}`;

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setAuctionThemePayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handling getting list of auction theme

  const handleGetAuctionTheme = useCallback(
    (auctionThemePayload) => {
      setAuctionThemeListLoader(true);
      dispatch(getAuctionTheme(auctionThemePayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setAuctionThemeListLoader(false);
        })
        .catch((error) => {
          setAuctionThemeListLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetAuctionTheme(auctionThemePayload);
  }, [auctionThemePayload, handleGetAuctionTheme]);

  const handleConfirmBtn = () => {
    setAuctionThemeDeleteLoader(true);
    dispatch(deleteAuctionTheme(selectedRow))
      .then((res) => {
        if (res) {
          //
          handleGetAuctionTheme(auctionThemePayload);
          setIsDeleteAuctionThemeModalOpen(false);
        } else {
          //
        }
        setAuctionThemeDeleteLoader(false);
      })
      .catch((error) => {
        setAuctionThemeDeleteLoader(false);
        setIsDeleteAuctionThemeModalOpen(false);
      });
  };

  const handleSaveAuctoinType = (values, validation) => {
    setAuctionThemeSaveLoader(true);
    if (selectedRow && selectedRow.id) {
      // dispatch for editing page
      dispatch(editAuctionTheme({ id: selectedRow.id, values }))
        .then((res) => {
          if (res) {
            handleGetAuctionTheme(auctionThemePayload);
            validation.resetForm();
            setIsAddAuctionThemeModalOpen(false);
            //
          } else {
            //
          }
          setAuctionThemeSaveLoader(false);
        })
        .catch((error) => {
          setIsAddAuctionThemeModalOpen(false);
          setAuctionThemeSaveLoader(false);
        });
    } else {
      // dispatch for adding new language
      dispatch(addAuctionTheme(values))
        .then((res) => {
          if (res) {
            handleGetAuctionTheme(auctionThemePayload);
            validation.resetForm();
            setIsAddAuctionThemeModalOpen(false);
            //
          } else {
            //
          }
          setAuctionThemeSaveLoader(false);
        })
        .catch((error) => {
          setIsAddAuctionThemeModalOpen(false);
          setAuctionThemeSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setAuctionThemePayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setAuctionThemePayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const locationDeleteHandler = (row) => {
    setIsDeleteAuctionThemeModalOpen(true);
    setSelectedRow(row.id);
  };

  const locationEditHandler = (row) => {
    setIsAddAuctionThemeModalOpen(true);
    setSelectedRow(row);
  };

  const handleCloseBtn = () => {
    setIsDeleteAuctionThemeModalOpen(false);
  };

  const handleLocationModalOpen = () => {
    setSelectedRow(null);
    setIsAddAuctionThemeModalOpen(true);
  };

  const handleAuctionTypeModalClose = () => {
    setIsAddAuctionThemeModalOpen(false);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
        </div>
      ),
      selector: (row) => row.id,

      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code_pt__icontains"
            className="column-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
        </div>
      ),
      selector: (row) => row.code_pt,

      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          {/* <div className="table-column-title mb-2">
            {t("common.description")}
          </div> */}
          <CustomColumnHeader
            columnTitle={t("common.description")}
            sortable
            sortableKey="description_pt"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description_pt__icontains"
            className="column-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
        </div>
      ),
      selector: (row) => row.description_pt,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.codeEn")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code_en__icontains"
            className="column-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
        </div>
      ),
      selector: (row) => row.code_en,

      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          {/* <div className="table-column-title mb-2">
            {t("common.descriptionEn")}
          </div> */}
          <CustomColumnHeader
            columnTitle={t("common.descriptionEn")}
            sortable
            sortableKey="description_en"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description_en__icontains"
            className="column-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
        </div>
      ),
      selector: (row) => row.description_en,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            <div className="table-actions">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => locationEditHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={t("common.edit")} />
                <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
              </button>
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
                onClick={() => locationDeleteHandler(row)}
              >
                <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={t("common.delete")} />
                <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
              </button>
            </div>
          </div>
        </div>
      ),
      minWidth: "150px",
      wrap: true,
      center: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={auctionThemePayload}
            setPayload={setAuctionThemePayload}
          />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleLocationModalOpen}
              btnTitle={`${t("common.addTitle", {
                module: `${t("settings.auctions.auctionThemes.auctionTheme")}`,
              })}`}
            />
          </div>
        </div>
        <Card className="container-body">
          {auctionThemeListLoader ? <Loader /> : null}
          <CardHeader className="table-title">{t("sidebarMenu.auctionThemes")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            // selectableRows
            columns={columns}
            data={[...auctionThemeData]}
            pagination
            paginationServer
            paginationTotalRows={totalAuctionTheme}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            persistTableHead
          />
        </Card>
      </div>

      {/* to add /edit languages */}
      <AddAuctionThemeModal
        isModalOpen={isAddActionThemeModalOpen}
        auctionTheme={selectedRow}
        onCancel={handleAuctionTypeModalClose}
        onSave={handleSaveAuctoinType}
        loader={auctionThemeSaveLoader}
      />

      {/* delete modal for languages */}
      <DeleteModal
        title={`${t("common.delete")} ${t("settings.auctions.auctionThemes.auctionTheme")}`}
        body={t("confirmation.deleteMessage", {
          module: t("settings.auctions.auctionThemes.auctionTheme"),
        })}
        actionBtnTitle={`${t("common.delete")}`}
        loader={auctionThemeDeleteLoader}
        isModalOpen={isDeleteAuctionThemeModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />
    </>
  );
};

export default withTranslation()(AuctionTheme);
