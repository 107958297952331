import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkPermissionByRoles } from "../helpers/utils";
import { ADMIN, COMUNICATIONS, EXTERNAL, GENERAL } from "../helpers/constants";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isHome, setIsHome] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isContracts, setIsContracts] = useState(false);
  const [isGeneral, setIsGeneral] = useState(false);
  const [isSettingAuction, setIsSettingAuction] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isReports, setIsReports] = useState(false);

  const [isWebsiteManagement, setIsWebsiteManagement] = useState(false);

  // Pages
  // eslint-disable-next-line no-unused-vars
  const [isBilling, setIsBilling] = useState(false);
  const [isLots, setIsLots] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Home") {
      setIsHome(false);
    }
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSettings(false);
    }
    if (iscurrentState !== "Auction") {
      setIsAuction(false);
    }
    if (iscurrentState !== "Customer") {
      setIsCustomer(false);
    }
    if (iscurrentState !== "Contracts") {
      setIsContracts(false);
    }
    if (iscurrentState !== "Invoicing") {
      setIsInvoice(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "Website-management") {
      setIsWebsiteManagement(false);
    }
  }, [history, isHome, iscurrentState, isDashboard, isSettings]);

  const menuItems = [
    {
      label: "sidebarMenu.menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "sidebarMenu.dashboards",
      icon: "mdi mdi-speedometer",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "task",
      label: "sidebarMenu.tasks",
      icon: "ri-todo-line",
      link: "/tasks",
      stateVariables: isHome,
      click: function (e) {
        e.preventDefault();
        setIsHome(!isHome);
        setIscurrentState("Home");
        updateIconSidebar(e);
      },
    },
    {
      id: "customer",
      label: "sidebarMenu.customers",
      icon: "ri-user-3-line",
      link: "/customers",
      stateVariables: isCustomer,
      isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]),
      click: function (e) {
        e.preventDefault();
        setIsCustomer(!isCustomer);
        setIscurrentState("Customer");
        updateIconSidebar(e);
      },
    },
    {
      id: "auctions",
      label: "sidebarMenu.auctions",
      icon: "ri-auction-line",
      link: "/auctions",
      stateVariables: isAuction,
      isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]),
      click: function (e) {
        e.preventDefault();
        setIsAuction(!isAuction);
        setIscurrentState("Auction");
        updateIconSidebar(e);
      },
    },
    {
      id: "contracts",
      label: "sidebarMenu.contracts",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isContracts,
      click: function (e) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pre-contracts",
          label: "sidebarMenu.pre-contracts",
          link: "/contracts/pre-contracts",
          parentId: "contracts",
          isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]),
        },
        {
          id: "contract",
          label: "sidebarMenu.contracts",
          link: "/contracts/contracts",
          parentId: "contracts",
        },
        {
          id: "item-list",
          label: "sidebarMenu.itemsList",
          link: "/contracts/item-list",
          parentId: "contracts",
          isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]),
        },
      ],
    },
    {
      id: "invoicing",
      label: "sidebarMenu.invoicing",
      icon: "ri-file-list-3-fill",
      link: "/invoicing",
      stateVariables: isInvoice,
      isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]),
      click: function (e) {
        e.preventDefault();
        setIsInvoice(!isInvoice);
        setIscurrentState("Invoicing");
        updateIconSidebar(e);
      },
    },
    {
      id: "reports",
      label: "sidebarMenu.reports",
      icon: "ri-bar-chart-fill",
      link: "/reports",
      stateVariables: isReports,
      isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]),
      click: function (e) {
        e.preventDefault();
        setIsInvoice(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
    },
    {
      id: "website-management",
      label: "sidebarMenu.websiteManagement",
      icon: "ri-pages-fill",
      link: "/#",
      stateVariables: isWebsiteManagement,
      isDisabled: !checkPermissionByRoles([ADMIN, COMUNICATIONS]),
      click: function (e) {
        e.preventDefault();
        setIsWebsiteManagement(!isWebsiteManagement);
        setIscurrentState("Website-management");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pages",
          label: "sidebarMenu.pages",
          link: "/website-management/pages",
          parentId: "website-management",
        },
        {
          id: "files",
          label: "sidebarMenu.files",
          link: "/website-management/files",
          parentId: "website-management",
        },
        {
          id: "website-auctions",
          label: "sidebarMenu.auctions",
          link: "/website-management/website-auctions",
          parentId: "website-management",
        },
        {
          id: "my-veritas",
          label: "sidebarMenu.myVeritas",
          link: "/website-management/my-veritas",
          parentId: "website-management",
        },
        {
          id: "faqs",
          label: "sidebarMenu.FAQs",
          link: "/website-management/FAQs",
          parentId: "website-management",
        },
      ],
    },
    {
      id: "settings",
      label: "sidebarMenu.settings",
      icon: "ri-settings-4-line",
      link: "/#",
      stateVariables: isSettings,
      isDisabled: !checkPermissionByRoles([ADMIN]),
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("Settings");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "general",
          label: "sidebarMenu.general",
          link: "/settings/general",
          parentId: "settings",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsGeneral(!isGeneral);
          },
          stateVariables: isGeneral,
          childItems: [
            {
              id: 1,
              label: "sidebarMenu.users",
              link: "/settings/general/users",
              parentId: "general",
            },
            {
              id: 2,
              label: "sidebarMenu.languages",
              link: "/settings/general/languages",
              parentId: "general",
            },
            {
              id: 3,
              label: "sidebarMenu.permissions",
              link: "/settings/general/permissions",
              parentId: "general",
            },
            {
              id: 4,
              label: "sidebarMenu.report",
              link: "/settings/general/report",
              parentId: "general",
            },
            {
              id: 5,
              label: "sidebarMenu.warehouse",
              link: "/settings/general/warehouse",
              parentId: "general",
            },
            {
              id: 6,
              label: "sidebarMenu.titles",
              link: "/settings/general/titles",
              parentId: "general",
            },
          ],
        },

        {
          id: "billing",
          label: "sidebarMenu.billing",
          link: "/#",
          parentId: "settings",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsBilling(!isBilling);
          },
          stateVariables: isBilling,
          childItems: [
            {
              id: 1,
              label: "sidebarMenu.products&services",
              link: "/settings/billing/products-and-services",
              parentId: "billing",
            },
            {
              id: 2,
              label: "sidebarMenu.currencies",
              link: "/settings/billing/currencies",
              parentId: "billing",
            },
            {
              id: 3,
              label: "sidebarMenu.documentTypes",
              link: "/settings/billing/document-types",
              parentId: "billing",
            },
            {
              id: 4,
              label: "sidebarMenu.documentStatus",
              link: "/settings/billing/document-status",
              parentId: "billing",
            },
            {
              id: 5,
              label: "sidebarMenu.paymentConditions",
              link: "/settings/billing/payment-conditions",
              parentId: "billing",
            },
            {
              id: 6,
              label: "sidebarMenu.paymentTypes",
              link: "/settings/billing/payment-types",
              parentId: "billing",
            },
            {
              id: 7,
              label: "sidebarMenu.vat",
              link: "/settings/billing/VAT",
              parentId: "billing",
            },
          ],
        },

        {
          id: "settingAuctions",
          label: "sidebarMenu.auctions",
          link: "/#",
          parentId: "settings",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsSettingAuction(!isSettingAuction);
          },
          stateVariables: isSettingAuction,
          childItems: [
            {
              id: 1,
              label: "sidebarMenu.locations",
              link: "/settings/auction/locations",
              parentId: "settingAuctions",
            },
            {
              id: 2,
              label: "sidebarMenu.auctionTypes",
              link: "/settings/auction/auction-types",
              parentId: "settingAuctions",
            },
            {
              id: 3,
              label: "sidebarMenu.auctionThemes",
              link: "/settings/auction/auction-theme",
              parentId: "settingAuctions",
            },
            {
              id: 4,
              label: "sidebarMenu.auctionTags",
              link: "/settings/auction/auction-tags",
              parentId: "settingAuctions",
            },
            {
              id: 5,
              label: "sidebarMenu.auctionCollaborators",
              link: "/settings/auction/auction-collaborators",
              parentId: "settingAuctions",
            },
            {
              id: 6,
              label: "sidebarMenu.settings",
              link: "/settings/auction/settings",
              parentId: "settingAuctions",
            },
          ],
        },
        {
          id: "lots",
          label: "sidebarMenu.lots",
          link: "/#",
          parentId: "settings",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsLots(!isLots);
          },
          stateVariables: isLots,
          childItems: [
            {
              id: 1,
              label: "sidebarMenu.categories",
              link: "/settings/lots/categories",
              parentId: "lots",
            },
            {
              id: 2,
              label: "sidebarMenu.artists",
              link: "/settings/lots/artists",
              parentId: "lots",
            },
            {
              id: 3,
              label: "sidebarMenu.periods",
              link: "/settings/lots/periods",
              parentId: "lots",
            },
            {
              id: 4,
              label: "sidebarMenu.templates",
              link: "/settings/lots/templates",
              parentId: "lots",
            },
            {
              id: 5,
              label: "sidebarMenu.tags",
              link: "/settings/lots/tags",
              parentId: "lots",
            },
          ],
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
