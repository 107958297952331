import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap";
import API from "../../../helpers/api/api_interceptor";
import {
  DEFAULT_ROW_PER_PAGE,
  RETURN_GUIDE_DOCUMENT,
  SALES_NOTE,
  TRANSPORT_DOCUMENT,
} from "../../../helpers/constants";
import {
  formatAmount,
  generateDocumentLinesFromContractItems,
  getColumnDetail,
  getOnlyCustomerName,
  openFileURL,
} from "../../../helpers/utils";
import {
  getAvailableContractItems,
  getContractById,
  getContractItems,
  getSellerDocuments,
  getSoldContractItems,
} from "../../../store/contracts/actions";
import { exportInvoice } from "../../../store/invoicing/actions";
// import { getLotLists } from "../../../store/auctions/lotActions";
import ActionButton from "../../common/ActionButton";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import CustomTextLink from "../../common/CustomTextLink";
import Loader from "../../common/Loader";
import MessageBlock from "../../common/MessageBlock";
import NoRecords from "../../common/NoRecords";
// import { INITIAL_GET_LOTS_PAYLOAD } from "./Lots";

const Invoice = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const contractData = useSelector((state) => state?.Contracts?.contract);
  const { data: soldLotsData, total: totalSoldLots } = useSelector((state) => state?.Contracts?.invoice?.lots);
  const { data: availableLotsData, total: totalAvailableLots } = useSelector(
    (state) => state?.Contracts?.invoice?.availableLots
  );
  const { data: documentsData, total: totalDocuments } = useSelector((state) => state?.Contracts?.invoice?.documents);

  const [viewDocumentLoader, setViewDocumentLoader] = useState(false);
  const [createAvailableDocumentLoader, setCreateAvailableDocumentLoader] = useState(false);
  const [createDocumentLoader, setCreateDocumentLoader] = useState(false);
  const [selectedAvailableRows, setSelectedAvailableRows] = useState([]);
  const [isAllAvailableLotsSelected, setIsAllAvailableLotsSelected] = useState(false);
  const [availableLotsLoader, setAvailableLotsLoader] = useState(false);
  const [availableLotsPayload, setAvailableLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "position_contract",
    filter: {
      is_sold: false,
      contract: id,
      is_return_guide: false,
    },
  });
  const [selectedSoldRows, setSelectedSoldRows] = useState([]);
  const [isAllSoldLotsSelected, setIsAllSoldLotsSelected] = useState(false);
  const [soldLotsLoader, setSoldLotsLoader] = useState(false);
  const [soldLotsPayload, setSoldLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "auction_lots__lot_no",
    is_paid_expense: false,
    filter: {
      is_sold: true,
      contract: id,
      is_sales_note: false,
    },
  });

  const [documentsLoader, setDocumentsLoader] = useState(false);
  const [docuemntsPayload, setDocumentsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: { document_lines__contract: id },
  });

  // handle sending document lines in invoice for document type
  const navigateOnInvoiceWithDocumentLines = (documentType, documentLines, expenses) => {
    history.push({
      pathname: "/invoicing/new",
      state: {
        customer: contractData?.customer,
        document_type: documentType,
        documentLines: generateDocumentLinesFromContractItems(
          documentLines,
          expenses,
          documentType === SALES_NOTE ? contractData.premium : null,
          true
        ),
      },
    });
  };

  // handle create document for return and transport guide
  const handleCreateDocumentOnAvailableLots = (documentType) => {
    if (!isAllAvailableLotsSelected) {
      navigateOnInvoiceWithDocumentLines(documentType, selectedAvailableRows);
      return;
    } else {
      setCreateAvailableDocumentLoader(true);
      API.get(`/contracts/item`, {
        params: {
          page_number: 1,
          sort: availableLotsPayload?.sort,
          filter: availableLotsPayload?.filter,
        },
      }).then((res) => {
        setCreateAvailableDocumentLoader(false);
        navigateOnInvoiceWithDocumentLines(documentType, res?.data?.data?.results);
      });
    }
  };

  // handle create document
  const handleCreateDocument = (documentType) => {
    if (!isAllSoldLotsSelected) {
      const contractItems = selectedSoldRows;
      const expenseItems = [];
      // to include expense or not
      if (documentType === SALES_NOTE) {
        setCreateDocumentLoader(true);
        dispatch(
          getContractById(soldLotsPayload?.filter?.contract, {
            is_paid_expense: false,
          })
        ).then((contract) => {
          // contract expenses
          if (contract?.expenses?.length > 0) {
            contract?.expenses?.forEach((expense) =>
              expenseItems.push({
                ...expense,
                no: contract?.contract_no,
                premium: contract?.premium,
              })
            );
          }
          // contract items expenses
          dispatch(
            getContractItems({
              page: 1,
              is_paid_expense: false,
              filter: { contract: soldLotsPayload?.filter?.contract },
            })
          ).then((items) => {
            items?.results?.forEach((item) =>
              item?.expenses?.forEach((expense) =>
                expenseItems.push({
                  ...expense,
                  no: item?.contract_item_no,
                  premium: item?.premium,
                })
              )
            );
            navigateOnInvoiceWithDocumentLines(documentType, contractItems, expenseItems);
            setCreateDocumentLoader(false);
          });
        });
      } else {
        navigateOnInvoiceWithDocumentLines(documentType, contractItems);
      }
    } else {
      setCreateDocumentLoader(true);
      API.get(`/contracts/item`, {
        params: {
          page_number: 1,
          sort: soldLotsPayload?.sort,
          filter: soldLotsPayload?.filter,
        },
      }).then((res) => {
        const contractItems = res?.data?.data?.results;
        const expenseItems = [];
        if (documentType === SALES_NOTE) {
          dispatch(
            getContractById(soldLotsPayload?.filter?.contract, {
              is_paid_expense: false,
            })
          ).then((contract) => {
            // contract expenses
            if (contract?.expenses?.length > 0) {
              contract?.expenses?.forEach((expense) =>
                expenseItems.push({
                  ...expense,
                  no: contract?.contract_no,
                  premium: contract?.premium,
                })
              );
            }
            // contract items expenses
            dispatch(
              getContractItems({
                page: 1,
                is_paid_expense: false,
                filter: { contract: soldLotsPayload?.filter?.contract },
              })
            ).then((items) => {
              items?.results?.forEach((item) =>
                item?.expenses?.forEach((expense) =>
                  expenseItems.push({
                    ...expense,
                    no: item?.contract_item_no,
                    premium: item?.premium,
                  })
                )
              );
              navigateOnInvoiceWithDocumentLines(documentType, contractItems, expenseItems);
              setCreateDocumentLoader(false);
            });
          });
        } else {
          navigateOnInvoiceWithDocumentLines(documentType, contractItems);
        }
      });
    }
  };

  //   handle navigation on invoice
  const navigateToInvoice = (id) => {
    window.open(`/invoicing/edit/${id}`);
  };

  // handle page change
  const handleLotsPageChange = (value, setPayload) => {
    setPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleLotsRowsPerPageChange = (value, setPayload) => {
    setPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle page change
  const handleDocumentsPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleDocumentsRowsPerPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  //   handle getting available lots item
  const handleGetAvailableLotLists = useCallback(
    (availableLotsPayload) => {
      setAvailableLotsLoader(true);
      dispatch(getAvailableContractItems(availableLotsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setAvailableLotsLoader(false);
      });
    },
    [dispatch]
  );

  //   handle getting sold lots item
  const handleGetSoldLotLists = useCallback(
    (soldLotsPayload) => {
      setSoldLotsLoader(true);
      dispatch(getSoldContractItems(soldLotsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setSoldLotsLoader(false);
      });
    },
    [dispatch]
  );

  //   handle getting documents by the contract id
  const handleGetSellerDocuments = useCallback(
    (docuemntsPayload) => {
      setDocumentsLoader(true);
      dispatch(getSellerDocuments(docuemntsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setDocumentsLoader(false);
      });
    },
    [dispatch]
  );

  // get list of available lots for the contract
  useEffect(() => {
    if (id) {
      handleGetAvailableLotLists(availableLotsPayload);
    }
  }, [handleGetAvailableLotLists, availableLotsPayload, id]);

  // get list of sold lots for the contract
  useEffect(() => {
    if (id) {
      handleGetSoldLotLists(soldLotsPayload);
    }
  }, [handleGetSoldLotLists, soldLotsPayload, id]);

  // get list of documents for the contract
  useEffect(() => {
    if (id) {
      handleGetSellerDocuments(docuemntsPayload);
    }
  }, [handleGetSellerDocuments, docuemntsPayload, id]);

  const availableLotsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllAvailableLotsSelected}
            onChange={(event) => {
              setIsAllAvailableLotsSelected(event.target.checked);
              setSelectedAvailableRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllAvailableLotsSelected}
          checked={isAllAvailableLotsSelected || Boolean(selectedAvailableRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedAvailableRows?.find((item) => item?.id === row?.id)) {
              setSelectedAvailableRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedAvailableRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.no_")}</div>
        </div>
      ),
      selector: (row) => row?.position_contract || "-",
      width: "20%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
        </div>
      ),
      selector: (row) => getColumnDetail(row?.title_en, row?.title_pt) || "-",
      width: "45%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.lot?.sale_value || 0) + " €",
      width: "25%",
      wrap: true,
    },
  ];
  const sodlLotsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSoldLotsSelected}
            onChange={(event) => {
              setIsAllSoldLotsSelected(event.target.checked);
              setSelectedSoldRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSoldLotsSelected}
          checked={isAllSoldLotsSelected || Boolean(selectedSoldRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedSoldRows?.find((item) => item?.id === row?.id)) {
              setSelectedSoldRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedSoldRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lotNo")}</div>
        </div>
      ),
      selector: (row) => (row?.lot?.lot_no ? row?.lot?.lot_no + row?.lot?.lot_alpha : "-"),
      width: "20%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
        </div>
      ),
      selector: (row) => getColumnDetail(row?.title_en, row?.title_pt) || "-",
      width: "45%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.lot?.sale_value || 0) + " €",
      width: "25%",
      wrap: true,
    },
  ];

  const sellerDocumentsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.documentNo")}</div>
        </div>
      ),
      selector: (row) =>
        row?.document_no ? (
          <CustomTextLink
            text={row?.document_no}
            handleClick={() => {
              navigateToInvoice(row?.id);
            }}
          />
        ) : (
          "-"
        ),
      width: "20%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.total")}</div>
        </div>
      ),
      selector: (row) => (row?.total || 0) + "€",
      width: "30%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.noOfLines")}</div>
        </div>
      ),
      selector: (row) => row?.document_lines?.length || 0,
      width: "25%",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <ActionButton
            iconClasses="ri-printer-fill fs-18"
            onClick={() => {
              if (row?.phc_document_link) openFileURL(row?.phc_document_link, "download");
            }}
            buttonId={`tooltip-${row?.id}-print`}
            tooltip={props.t("common.print")}
          />
          <ActionButton
            iconClasses="ri-eye-fill fs-18"
            onClick={() => {
              navigateToInvoice(row?.id);
            }}
            buttonId={`tooltip-${row?.id}-view`}
            tooltip={props.t("common.view")}
          />
        </div>
      ),
      width: "15%",
      center: true,
    },
  ];

  return (
    <>
      <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
        <span className="card-title">{props.t("common.invoice")}</span>
      </CardHeader>
      <CardBody className="card-section-body">
        {id ? (
          <>
            <div className="d-flex flex-row mb-3 gap-2">
              <div>
                <Label className="form-label">{props?.t("common.contractToBeInvoiced")}</Label>
                <Input
                  disabled
                  className="form-control"
                  placeholder={`${contractData?.contract_no} - ${getOnlyCustomerName(contractData?.customer)}`}
                />
              </div>
            </div>
            <Row>
              {/*available lots table */}
              <Col sm={12} md={12} lg={6}>
                <Card className="subcard-table-container table-light">
                  {availableLotsLoader ? <Loader /> : null}
                  <CardHeader className="d-flex flex-row justify-content-between align-items-center">
                    <div className="table-title">{`${props.t("common.available")} ${props.t("common.items")}`}</div>
                    <div className="d-flex flex-row gap-2">
                      {(isAllAvailableLotsSelected && totalAvailableLots > 0) || selectedAvailableRows.length ? (
                        <Button
                          color="info"
                          onClick={() => {
                            setSelectedAvailableRows([]);
                            setIsAllAvailableLotsSelected(false);
                          }}
                        >
                          {props.t("common.deselect")}
                          <Badge color="dark" className="ms-1">
                            {selectedAvailableRows.length || totalAvailableLots}
                          </Badge>
                        </Button>
                      ) : null}
                      <CustomDropdownButton
                        isLoading={createAvailableDocumentLoader}
                        color={"success"}
                        disabled={
                          !(isAllAvailableLotsSelected && totalAvailableLots > 0) && selectedAvailableRows.length === 0
                        }
                        btnTitle={props.t("common.issue")}
                        options={[
                          {
                            label: props.t("common.returnGuide"),
                            onClick: () => {
                              handleCreateDocumentOnAvailableLots(RETURN_GUIDE_DOCUMENT);
                            },
                          },
                          {
                            label: props.t("common.transportDocument"),
                            onClick: () => {
                              handleCreateDocumentOnAvailableLots(TRANSPORT_DOCUMENT);
                            },
                          },
                        ]}
                      />
                    </div>
                  </CardHeader>
                  <DataTable
                    className="data-table"
                    persistTableHead
                    columns={availableLotsColumns}
                    data={[...availableLotsData]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalAvailableLots}
                    paginationDefaultPage={availableLotsPayload?.page}
                    paginationPerPage={availableLotsPayload?.limit}
                    noDataComponent={<NoRecords />}
                    onChangeRowsPerPage={(value) => {
                      handleLotsRowsPerPageChange(value, setAvailableLotsPayload);
                    }}
                    onChangePage={(value) => {
                      handleLotsPageChange(value, setAvailableLotsPayload);
                    }}
                    paginationComponentOptions={{
                      rowsPerPageText: props.t("common.rowsPerPage"),
                      rangeSeparatorText: props.t("common.rangeSeparator"),
                    }}
                  />
                </Card>
              </Col>
              {/* sold lots table */}
              <Col sm={12} md={12} lg={6}>
                <Card className="subcard-table-container table-light">
                  {soldLotsLoader ? <Loader /> : null}
                  <CardHeader className="d-flex flex-row justify-content-between align-items-center">
                    <div className="table-title">{props.t("common.itemsToBeBilled")}</div>
                    <div className="d-flex flex-row gap-2">
                      {(isAllSoldLotsSelected && totalSoldLots > 0) || selectedSoldRows.length ? (
                        <Button
                          color="info"
                          onClick={() => {
                            setSelectedSoldRows([]);
                            setIsAllSoldLotsSelected(false);
                          }}
                        >
                          {props.t("common.deselect")}
                          <Badge color="dark" className="ms-1">
                            {selectedSoldRows.length || totalSoldLots}
                          </Badge>
                        </Button>
                      ) : null}
                      <CustomDropdownButton
                        isLoading={createDocumentLoader}
                        color={"success"}
                        disabled={!(isAllSoldLotsSelected && totalSoldLots > 0) && selectedSoldRows.length === 0}
                        btnTitle={props.t("common.issue")}
                        options={[
                          {
                            label: props.t("common.salesNote"),
                            onClick: () => {
                              handleCreateDocument(SALES_NOTE);
                            },
                          },
                          {
                            label: props.t("common.transportDocument"),
                            onClick: () => {
                              handleCreateDocument(TRANSPORT_DOCUMENT);
                            },
                          },
                        ]}
                      />
                    </div>
                  </CardHeader>
                  <DataTable
                    className="data-table"
                    persistTableHead
                    columns={sodlLotsColumns}
                    data={[...soldLotsData]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalSoldLots}
                    paginationDefaultPage={soldLotsPayload?.page}
                    paginationPerPage={soldLotsPayload?.limit}
                    noDataComponent={<NoRecords />}
                    onChangeRowsPerPage={(value) => {
                      handleLotsRowsPerPageChange(value, setSoldLotsPayload);
                    }}
                    onChangePage={(value) => {
                      handleLotsPageChange(value, setSoldLotsPayload);
                    }}
                    paginationComponentOptions={{
                      rowsPerPageText: props.t("common.rowsPerPage"),
                      rangeSeparatorText: props.t("common.rangeSeparator"),
                    }}
                  />
                </Card>
              </Col>
            </Row>
            {/* buyer documents table */}
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Card className="subcard-table-container table-light">
                  {documentsLoader ? <Loader /> : null}
                  <CardHeader className="d-flex flex-row justify-content-between align-items-center">
                    <div className="table-title">{props.t("common.sellerDocuments")}</div>
                  </CardHeader>
                  <DataTable
                    className="data-table"
                    persistTableHead
                    columns={sellerDocumentsColumns}
                    data={[...documentsData]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalDocuments}
                    paginationDefaultPage={docuemntsPayload?.page}
                    paginationPerPage={docuemntsPayload?.limit}
                    noDataComponent={<NoRecords />}
                    onChangeRowsPerPage={handleDocumentsRowsPerPageChange}
                    onChangePage={handleDocumentsPageChange}
                    paginationComponentOptions={{
                      rowsPerPageText: props.t("common.rowsPerPage"),
                      rangeSeparatorText: props.t("common.rangeSeparator"),
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <MessageBlock message={props.t("information.contractItemsBlock")} />
        )}
      </CardBody>
    </>
  );
};

export default withTranslation()(Invoice);
