import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, CardHeader } from "reactstrap";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddCurrencyModal from "../../../../components/pages/settings/AddCurrencyModal";
import { addCurrency, deleteCurrency, getCurrencies, updateCurrency } from "../../../../store/currencies/actions";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const Currencies = (props) => {
  const dispatch = useDispatch();
  const { data: currenciesData, total: totalCurrencies } = useSelector((state) => state?.Currencies);

  const [currenciesPayload, setCurrenciesPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {
      id__icontains: "",
      code__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // add or edit action handlers
  const handleCurrencyModalOpen = (currencyData) => {
    setSelectedCurrency(currencyData);
    setIsCurrencyModalOpen(true);
  };
  const handleCurrencyModalClose = () => {
    setSelectedCurrency(null);
    setIsCurrencyModalOpen(false);
  };
  const handleSaveCurrency = (values, formikValidation) => {
    // check for add or edit
    if (selectedCurrency && selectedCurrency?.id) {
      // edit currency
      setSaveLoader(true);
      dispatch(updateCurrency(selectedCurrency?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleCurrencyModalClose();
            formikValidation.resetForm();
            handleGetCurrencies(currenciesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add currency
      setSaveLoader(true);
      dispatch(addCurrency(values))
        .then((res) => {
          if (res) {
            // success handler
            handleCurrencyModalClose();
            formikValidation.resetForm();
            handleGetCurrencies(currenciesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (currencyData) => {
    setSelectedCurrency(currencyData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setSelectedCurrency(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCurrency = () => {
    if (selectedCurrency && selectedCurrency?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteCurrency({
          id: selectedCurrency?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetCurrencies(currenciesPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setCurrenciesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCurrenciesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get currencies
  const handleGetCurrencies = useCallback(
    (currenciesPayload) => {
      setTableLoader(true);
      dispatch(getCurrencies(currenciesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCurrencies(currenciesPayload);
  }, [currenciesPayload, handleGetCurrencies]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={currenciesPayload}
            setPayload={setCurrenciesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.billing.currencies.code")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="code__icontains"
            className="column-search-input"
            payload={currenciesPayload}
            setPayload={setCurrenciesPayload}
          />
        </div>
      ),
      selector: (row) => row?.code,
      minWidth: "250px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("settings.billing.currencies.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={currenciesPayload}
            setPayload={setCurrenciesPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleCurrencyModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.currencies")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={currenciesPayload}
            setPayload={setCurrenciesPayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleCurrencyModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">{props.t("settings.billing.currencies.addCurrency")}</span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.currencies")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...currenciesData]}
            pagination
            paginationServer
            paginationTotalRows={totalCurrencies}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddCurrencyModal
        currency={selectedCurrency}
        isModalOpen={isCurrencyModalOpen}
        onCancel={handleCurrencyModalClose}
        onSave={handleSaveCurrency}
        loader={saveLoader}
      />
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteCurrency}
        title={`${props.t("common.delete")} ${props.t("settings.billing.currencies.currency")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.currencies.currency_small"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Currencies);
