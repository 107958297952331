import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_ARTIST, SET_ARTISTS_DATA, UPDATE_ARTIST } from "./actionTypes";

export const getArtists = (params) => async (dispatch) => {
  try {
    return API.get(`/lots/artists`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_ARTISTS_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add artist
export const addArtist = (payload) => async (dispatch) => {
  try {
    return API.post(`/lots/artists`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_ARTIST,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update artist
export const updateArtist = (artistId, payload) => async (dispatch) => {
  try {
    return API.put(`/lots/artists/${artistId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_ARTIST,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete artist
export const deleteArtist =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/lots/artists/${id}`, { data: { ...payload } }).then((res) => {
        return res;
      });
    } catch (error) {
      console.log(error);
    }
  };
