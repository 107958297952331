import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Loader from "../../../../components/common/Loader";
import { generateReportPayload, getAddress, getFieldValue, scrollToFirstFormikError } from "../../../../helpers/utils";
import { getReport, updateReport } from "../../../../store/report/actions";

const ReportSettings = (props) => {
  const dispatch = useDispatch();

  const { data: reportData } = useSelector((state) => state.Report);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [getReportLoader, setGetReportLoader] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(
  //   reportData.length > 0 ? true : false
  // );

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.report")}`;

  // get setting report
  const handleGetReport = useCallback(() => {
    setGetReportLoader(true);
    dispatch(getReport())
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setGetReportLoader(false);
      })
      .catch((error) => {
        setGetReportLoader(false);
      });
  }, [dispatch]);

  useEffect(() => {
    handleGetReport();
  }, [handleGetReport]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      report_company_name: reportData.length > 0 ? getFieldValue(reportData || [], "report", "company_name") : "",
      report_full_address: reportData.length > 0 ? getFieldValue(reportData || [], "report", "full_address") : "",
      report_telephone: reportData.length > 0 ? getFieldValue(reportData || [], "report", "telephone") : "",
      report_website: reportData.length > 0 ? getFieldValue(reportData || [], "report", "website") : "",
      report_crc: reportData.length > 0 ? getFieldValue(reportData || [], "report", "CRC") : "",
      report_vat: reportData.length > 0 ? getFieldValue(reportData || [], "report", "VAT") : "",
      saft_company_name: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "company_name") : "",
      saft_country_code: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "country_code") : "",
      saft_street_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 0) : "",
      saft_telephone: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "telephone") : "",
      saft_door_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 1) : "",
      saft_fax: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "fax") : "",
      saft_locality_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 2) : "",
      saft_email: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "email") : "",
      saft_post_code_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 3) : "",
      saft_website: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "website") : "",
      saft_district_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 4) : "",
      saft_nif: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "NIF") : "",
      saft_country_address: reportData.length > 0 ? getAddress(reportData || [], "saft", "address", 5) : "",
      saft_crc: reportData.length > 0 ? getFieldValue(reportData || [], "saft", "CRC") : "",
    },
    validationSchema: Yup.object({
      report_company_name: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.report")} ${props.t(
              "settings.general.report.company"
            )} ${props.t("common.name")}`,
          })
        ),
      report_full_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("common.full")} ${props.t("common.address")}`,
          })
        ),
      report_telephone: Yup.string()
        .trim()
        .matches(/^[0-9]+$/, props.t("validation.telephoneValidationMsg"))
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.report")} ${props.t("settings.general.report.telephone")}`,
          })
        ),
      report_website: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.report")} ${props.t("settings.general.report.website")}`,
          })
        ),
      report_crc: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.report")} ${props.t("settings.general.report.crc")}`,
          })
        ),
      report_vat: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.report")} ${props.t("sidebarMenu.vat")}`,
          })
        ),
      saft_company_name: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.saft")} ${props.t("settings.general.report.company")} ${props.t(
              "common.name"
            )}`,
          })
        ),
      saft_country_code: Yup.string()
        .trim()
        .matches(/^[A-Z]+$/, `${props.t("common.validataionMessageForCapitalLatter")}`)
        .min(2, `${props.t("common.validataionMessageFor2Chars")}`)
        .max(2, `${props.t("common.validataionMessageFor2Chars")}`)
        .required("Please Enter Country Code"),
      saft_street_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.street")} ${props.t("common.address")}`,
          })
        ),
      saft_telephone: Yup.string()
        .trim()
        .matches(/^[0-9]+$/, props.t("validation.telephoneValidationMsg"))
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.saft")} ${props.t("settings.general.report.telephone")} `,
          })
        ),
      saft_door_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.door")} ${props.t("common.address")}`,
          })
        ),
      saft_fax: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.fax")}`,
          })
        ),
      saft_locality_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.city")} ${props.t("common.address")}`,
          })
        ),
      saft_email: Yup.string()
        .trim()
        .email(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("common.valid")} ${props.t("common.email")} ${props.t("common.address")}`,
          })
        )
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("common.email")} ${props.t("common.addresss")}`,
          })
        ),
      saft_post_code_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.postCode")}`,
          })
        ),
      saft_website: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.saft")} ${props.t("settings.general.report.website")}`,
          })
        ),
      saft_district_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.district")} ${props.t("common.address")}`,
          })
        ),
      saft_nif: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.nif")}`,
          })
        ),
      saft_country_address: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.country")} ${props.t("common.address")}`,
          })
        ),
      saft_crc: Yup.string()
        .trim()
        .required(
          props.t("common.requiredValidationMsg", {
            field: `${props.t("settings.general.report.saft")} ${props.t("settings.general.report.crc")}`,
          })
        ),
    }),
    onSubmit: (values, formValidation) => {
      handleSaveBtn(generateReportPayload(values), formValidation);
    },
  });

  const customHandleChange = (e) => {
    if (isFormChanged === false) {
      setIsFormChanged(true);
    }
    validation.handleChange(e);
  };

  // const handleEditBtn = () => {
  //   setIsEditMode(true);
  // };

  const handleCancelBtn = () => {
    validation.resetForm();
    // setIsEditMode(false);
    setIsFormChanged(false);
  };

  const handleSaveBtn = (payload, formikValidation) => {
    setGetReportLoader(true);
    dispatch(updateReport(payload))
      .then((res) => {
        if (res) {
          //
        } else {
          //
        }
        handleCancelBtn();
        setGetReportLoader(false);
      })
      .catch((error) => {
        handleCancelBtn();
        setGetReportLoader(false);
      });
  };

  return (
    <>
      <div className="page-content layout-main-container">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          {getReportLoader ? <Loader /> : null}
          {/* <Card className="container-body">
            <CardHeader className="align-items-center d-flex">
              <h4 className="table-title card-title mb-0 flex-grow-1">
                {props.t("sidebarMenu.report")}
              </h4>

              <div className="d-flex justify-content-around gap-3">
                <Button
                  color="primary"
                  className="btn-load d-flex px-4"
                  type="submit"
                  disabled={!isEditMode && !isFormChanged}
                >
                  Save
                </Button>
                {reportData.length > 0 && !isEditMode ? (
                  <Button
                    color="primary"
                    onClick={handleEditBtn}
                    className="btn-load d-flex px-4"
                  >
                    {props.t("common.edit")}
                  </Button>
                ) : null}
                <Button
                  color="danger"
                  className="btn-load d-flex px-4"
                  disabled={!isEditMode && !isFormChanged}
                  onClick={handleCancelBtn}
                >
                  Cancel
                </Button>
              </div>
            </CardHeader>
          </Card> */}
          <Row>
            <Col className="col-sm-12 col-md-12 col-xl-6">
              <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {`${props.t("settings.general.report.report")} ${props.t("sidebarMenu.settings")}`}
                  </h4>
                </CardHeader>
                <CardBody>
                  {/* form */}

                  {/* report_company_name */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("settings.general.report.company")} ${props.t("common.name")}`}
                      className="form-label"
                    >
                      {`${props.t("settings.general.report.company")} ${props.t("common.name")}`}
                    </Label>
                    <Input
                      name="report_company_name"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_company_name || ""}
                      invalid={
                        validation.touched.report_company_name && validation.errors.report_company_name ? true : false
                      }
                    />
                    {validation.touched.report_company_name && validation.errors.report_company_name ? (
                      <FormFeedback type="invalid">{validation.errors.report_company_name}</FormFeedback>
                    ) : null}
                  </div>

                  {/* full address */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("sidebarMenu.report")} ${props.t("common.full")} ${props.t(
                        "common.address"
                      )}`}
                      className="form-label"
                    >
                      {`${props.t("common.full")} ${props.t("common.address")}`}
                    </Label>
                    <Input
                      name="report_full_address"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_full_address || ""}
                      invalid={
                        validation.touched.report_full_address && validation.errors.report_full_address ? true : false
                      }
                    />
                    {validation.touched.report_full_address && validation.errors.report_full_address ? (
                      <FormFeedback type="invalid">{validation.errors.report_full_address}</FormFeedback>
                    ) : null}
                  </div>

                  {/* telephone */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("sidebarMenu.report")} ${props.t("settings.general.report.telephone")}`}
                      className="form-label"
                    >
                      {`${props.t("settings.general.report.telephone")}`}
                    </Label>
                    <Input
                      name="report_telephone"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_telephone || ""}
                      invalid={validation.touched.report_telephone && validation.errors.report_telephone ? true : false}
                    />
                    {validation.touched.report_telephone && validation.errors.report_telephone ? (
                      <FormFeedback type="invalid">{validation.errors.report_telephone}</FormFeedback>
                    ) : null}
                  </div>

                  {/* website */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("sidebarMenu.report")} ${props.t("settings.general.report.website")}`}
                      className="form-label"
                    >
                      {`${props.t("settings.general.report.website")}`}
                    </Label>
                    <Input
                      name="report_website"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_website || ""}
                      invalid={validation.touched.report_website && validation.errors.report_website ? true : false}
                    />
                    {validation.touched.report_website && validation.errors.report_website ? (
                      <FormFeedback type="invalid">{validation.errors.report_website}</FormFeedback>
                    ) : null}
                  </div>

                  {/* CRC */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("sidebarMenu.report")} ${props.t("settings.general.report.crc")}`}
                      className="form-label"
                    >
                      {`${props.t("settings.general.report.crc")}`}
                    </Label>
                    <Input
                      name="report_crc"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_crc || ""}
                      invalid={validation.touched.report_crc && validation.errors.report_crc ? true : false}
                    />
                    {validation.touched.report_crc && validation.errors.report_crc ? (
                      <FormFeedback type="invalid">{validation.errors.report_crc}</FormFeedback>
                    ) : null}
                  </div>

                  {/* VAT */}
                  <div className="mb-2">
                    <Label
                      htmlFor={`${props.t("sidebarMenu.report")} ${props.t("settings.general.report.vat")}`}
                      className="form-label"
                    >
                      {`${props.t("sidebarMenu.vat")}`}
                    </Label>
                    <Input
                      name="report_vat"
                      className="form-control"
                      type="text"
                      // disabled={reportData.length > 0 && !isEditMode}
                      onChange={customHandleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.report_vat || ""}
                      invalid={validation.touched.report_vat && validation.errors.report_vat ? true : false}
                    />
                    {validation.touched.report_vat && validation.errors.report_vat ? (
                      <FormFeedback type="invalid">{validation.errors.report_vat}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-sm-12 col-md-12 col-xl-6">
              <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {`${props.t("settings.general.report.saft")} ${props.t("sidebarMenu.settings")}`}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    {/* saft_company_name */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.company")} ${props.t("common.name")}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.company")} ${props.t("common.name")}`}
                        </Label>
                        <Input
                          name="saft_company_name"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_company_name || ""}
                          invalid={
                            validation.touched.saft_company_name && validation.errors.saft_company_name ? true : false
                          }
                        />
                        {validation.touched.saft_company_name && validation.errors.saft_company_name ? (
                          <FormFeedback type="invalid">{validation.errors.saft_company_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft_country_code */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.country"
                          )} ${props.t("common.code")}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.country")} ${props.t("common.code")}`}
                        </Label>
                        <Input
                          name="saft_country_code"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_country_code || ""}
                          invalid={
                            validation.touched.saft_country_code && validation.errors.saft_country_code ? true : false
                          }
                        />
                        {validation.touched.saft_country_code && validation.errors.saft_country_code ? (
                          <FormFeedback type="invalid">{validation.errors.saft_country_code}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft street address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.street"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {`${props.t("common.address")} (${props.t("settings.general.report.street")})`}
                        </Label>
                        <Input
                          name="saft_street_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_street_address || ""}
                          invalid={
                            validation.touched.saft_street_address && validation.errors.saft_street_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.saft_street_address && validation.errors.saft_street_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_street_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft telephone */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.telephone"
                          )}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.telephone")}`}
                        </Label>
                        <Input
                          name="saft_telephone"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_telephone || ""}
                          invalid={validation.touched.saft_telephone && validation.errors.saft_telephone ? true : false}
                        />
                        {validation.touched.saft_telephone && validation.errors.saft_telephone ? (
                          <FormFeedback type="invalid">{validation.errors.saft_telephone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft door address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.door"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {`${props.t("common.address")} (${props.t("settings.general.report.door")})`}
                        </Label>
                        <Input
                          name="saft_door_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_door_address || ""}
                          invalid={
                            validation.touched.saft_door_address && validation.errors.saft_door_address ? true : false
                          }
                        />
                        {validation.touched.saft_door_address && validation.errors.saft_door_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_door_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft fax */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.fax"
                          )}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.fax")}`}
                        </Label>
                        <Input
                          name="saft_fax"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_fax || ""}
                          invalid={validation.touched.saft_fax && validation.errors.saft_fax ? true : false}
                        />
                        {validation.touched.saft_fax && validation.errors.saft_fax ? (
                          <FormFeedback type="invalid">{validation.errors.saft_fax}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft city address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.city"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {`${props.t("common.address")} (${props.t("settings.general.report.city")})`}
                        </Label>
                        <Input
                          name="saft_locality_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_locality_address || ""}
                          invalid={
                            validation.touched.saft_locality_address && validation.errors.saft_locality_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.saft_locality_address && validation.errors.saft_locality_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_locality_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft email */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t("common.email")}`}
                          className="form-label"
                        >
                          {`${props.t("common.email")}`}
                        </Label>
                        <Input
                          name="saft_email"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_email || ""}
                          invalid={validation.touched.saft_email && validation.errors.saft_email ? true : false}
                        />
                        {validation.touched.saft_email && validation.errors.saft_email ? (
                          <FormFeedback type="invalid">{validation.errors.saft_email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft post code address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.postCode"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {` ${props.t("common.address")} (${props.t("settings.general.report.postCode")})`}
                        </Label>
                        <Input
                          name="saft_post_code_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_post_code_address || ""}
                          invalid={
                            validation.touched.saft_post_code_address && validation.errors.saft_post_code_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.saft_post_code_address && validation.errors.saft_post_code_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_post_code_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft website */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.website"
                          )}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.website")}`}
                        </Label>
                        <Input
                          name="saft_website"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_website || ""}
                          invalid={validation.touched.saft_website && validation.errors.saft_website ? true : false}
                        />
                        {validation.touched.saft_website && validation.errors.saft_website ? (
                          <FormFeedback type="invalid">{validation.errors.saft_website}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft district address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.district"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {` ${props.t("common.address")} (${props.t("settings.general.report.district")})`}
                        </Label>
                        <Input
                          name="saft_district_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_district_address || ""}
                          invalid={
                            validation.touched.saft_district_address && validation.errors.saft_district_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.saft_district_address && validation.errors.saft_district_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_district_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft nif */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.nif"
                          )}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.nif")}`}
                        </Label>
                        <Input
                          name="saft_nif"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_nif || ""}
                          invalid={validation.touched.saft_nif && validation.errors.saft_nif ? true : false}
                        />
                        {validation.touched.saft_nif && validation.errors.saft_nif ? (
                          <FormFeedback type="invalid">{validation.errors.saft_nif}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* saft country address*/}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.country"
                          )} ${props.t("common.address")}`}
                          className="form-label"
                        >
                          {`${props.t("common.address")} (${props.t("settings.general.report.country")})`}
                        </Label>
                        <Input
                          name="saft_country_address"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_country_address || ""}
                          invalid={
                            validation.touched.saft_country_address && validation.errors.saft_country_address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.saft_country_address && validation.errors.saft_country_address ? (
                          <FormFeedback type="invalid">{validation.errors.saft_country_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* saft crc */}
                    <Col>
                      <div className="mb-2">
                        <Label
                          htmlFor={`${props.t("settings.general.report.saft")} ${props.t(
                            "settings.general.report.crc"
                          )}}`}
                          className="form-label"
                        >
                          {`${props.t("settings.general.report.crc")}`}
                        </Label>
                        <Input
                          name="saft_crc"
                          className="form-control"
                          type="text"
                          // disabled={reportData.length > 0 && !isEditMode}
                          onChange={customHandleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.saft_crc || ""}
                          invalid={validation.touched.saft_crc && validation.errors.saft_crc ? true : false}
                        />
                        {validation.touched.saft_crc && validation.errors.saft_crc ? (
                          <FormFeedback type="invalid">{validation.errors.saft_crc}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Card>
            <CardHeader className="d-flex justify-content-end"> */}
          <div className="d-flex justify-content-end gap-3 mb-4">
            <Button
              color="primary"
              className="btn-load d-flex px-4"
              type="submit"
              disabled={!isFormChanged}
              onClick={() => scrollToFirstFormikError(validation.errors)}
            >
              {props.t("common.save")}
            </Button>
            {/* {reportData.length > 0 && !isEditMode ? (
              <Button
                color="primary"
                onClick={handleEditBtn}
                className="btn-load d-flex px-4"
              >
                {props.t("common.edit")}
              </Button>
            ) : null} */}
            <Button color="danger" className="btn-load d-flex px-4" disabled={!isFormChanged} onClick={handleCancelBtn}>
              {props.t("common.reset")}
            </Button>
          </div>
          {/* </CardHeader>
          </Card> */}
        </Form>
      </div>
    </>
  );
};

export default withTranslation()(ReportSettings);
