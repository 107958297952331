import React, { useEffect } from "react";
import { Badge, Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";
import NoRecords from "../../common/NoRecords";
import DataTable from "react-data-table-component";
import Loader from "../../common/Loader";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import ActionButton from "../../common/ActionButton";
import { getBidRequests, updateBidRequestStatus } from "../../../store/auctions/actions";
import { useParams } from "react-router-dom";
import CustomTagChip from "../../common/CustomTagChip";
import { getOnlyCustomerName } from "../../../helpers/utils";

const BidRequestsModal = ({ isModalOpen, onCancel, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tableData, setTableData] = useState({ data: [], total: 0 });
  // loader
  const [tableLoader, setTableLoader] = useState(false);

  const [payload, setPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: { auction: id },
  });

  // handle getting bid requests
  const handleGetBidRequests = useCallback(
    (payload) => {
      setTableLoader(true);
      dispatch(getBidRequests(payload)).then((res) => {
        if (res) {
          setTableData({
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          });
        }
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isModalOpen) {
      handleGetBidRequests(payload);
    }
  }, [handleGetBidRequests, isModalOpen, payload]);

  const handleModalClose = () => {
    onCancel();
  };

  // handle page change
  const handlePageChange = (value) => {
    setPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  //   approve bid
  const handleBidRequestStatusUpdate = (bidRequestId, requestType) => {
    dispatch(
      updateBidRequestStatus(bidRequestId, {
        request_status: requestType,
      })
    ).then((res) => {
      if (res) {
        setTableData((prevState) => {
          const updatedData = [...prevState?.data];
          const bidIndex = updatedData?.findIndex((item) => item?.id === bidRequestId);
          if (bidIndex >= 0) {
            updatedData[bidIndex].request_status = requestType;
          }
          return { ...prevState, data: updatedData };
        });
      }
    });
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("customers.customer")} ${t("common.no_")}`}</div>
          <CustomSearchInput
            inputType="number"
            columnWise={true}
            columnSearchKey={"customer__customer_no"}
            className="column-search-input"
            payload={payload}
            setPayload={setPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.customer_no ? row?.customer?.customer_no : "-"),
      width: "25%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.customer")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={"search_name__icontains"}
            className="column-search-input"
            payload={payload}
            setPayload={setPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.id ? getOnlyCustomerName(row?.customer) : "-"),
      width: "50%",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {row?.request_status ? (
            <CustomTagChip
              bgColor={row?.request_status === "approved" ? "green" : "red"}
              textColor={"white"}
              title={row?.request_status === "approved" ? t("common.approved") : t("common.rejected")}
            />
          ) : (
            <>
              <ActionButton
                iconColor={"green"}
                iconClasses="ri-checkbox-circle-line fs-24"
                buttonId={`tooltip-${row?.id}-copy`}
                tooltip={t("common.approve")}
                onClick={() => handleBidRequestStatusUpdate(row?.id, "approved")}
              />
              <ActionButton
                iconColor={"red"}
                iconClasses="ri-close-circle-line fs-24"
                buttonId={`tooltip-${row?.id}-edit`}
                tooltip={t("common.reject")}
                onClick={() => handleBidRequestStatusUpdate(row?.id, "rejected")}
              />
            </>
          )}
        </div>
      ),
      width: "25%",
      center: true,
    },
  ];

  return (
    <Modal centered isOpen={isModalOpen} size="lg" toggle={handleModalClose} fade={false}>
      <ModalHeader className="lot-header w-100">
        <div className="d-flex w-100 flex-row justify-content-between">
          <span>{t("common.bidRequests")}</span>
        </div>
      </ModalHeader>
      <ModalBody
        className="subcard-table-container table-light"
        style={{ border: "0px", borderRadius: "5px", boxShadow: "2px" }}
      >
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            fixedHeader
            pointerOnHover
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...tableData?.data]}
            pagination
            paginationServer
            paginationDefaultPage={payload?.page}
            paginationPerPage={payload?.limit}
            paginationTotalRows={tableData?.total}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            conditionalRowStyles={[
              {
                when: (row) => row?.unsold_item_count === 0,
                style: { backgroundColor: "lightgray" },
              },
            ]}
          />
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(BidRequestsModal);
