import API from "./../../helpers/api/api_interceptor";
import { SET_REPORT_DETAILS } from "./actionType";
import { toast } from "react-toastify";

export const getReport = (payload) => async (dispatch) => {
  try {
    return API.get(`/users/settings`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_REPORT_DETAILS,
          payload: {
            data: res?.data?.data,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateReport = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/settings`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_REPORT_DETAILS,
          payload: {
            data: res?.data?.data,
          },
        });
        toast.success(res?.data?.detail);

        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
