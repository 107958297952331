/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddLanguageModal = ({ language, isModalOpen, onCancel, onSave, loader, t }) => {
  const [languageTypes, setLanguageTypes] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      code: language?.code || "",
      types: [],
      // types: language?.is_main
      //   ? language?.is_spoken
      //     ? ["main", "spoken"]
      //     : ["main"]
      //   : [],
      description: language?.description || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^[A-Z]+$/, t("settings.general.language.codeValidationMsg1"))
        .min(2, t("settings.general.language.codeValidationMsg2"))
        .max(2, t("settings.general.language.codeValidationMsg2"))
        .required(t("settings.general.language.codeValidationMsg3")),
      types: Yup.array().min(1, t("settings.general.language.typeValidationMsg")),
      description: Yup.string().required(t("settings.general.language.descriptionValidationMsg")),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    validation.setFieldValue("types", languageTypes);
  }, [languageTypes]);

  useEffect(() => {
    validation.resetForm();
    setLanguageTypes([]);
  }, [isModalOpen]);

  useEffect(() => {
    if (language) {
      let lang = [];
      if (language.is_main) {
        lang.push("main");
      }
      if (language.is_spoken) {
        lang.push("spoken");
      }
      setLanguageTypes(lang);
    }
  }, [language]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      setLanguageTypes([]);
      onCancel();
    }
  };

  const typeHandleChange = (event) => {
    if (event.target.checked) {
      setLanguageTypes((prevState) => [...prevState, event.target.value]);
    } else {
      setLanguageTypes((prevState) => prevState.filter((langType) => langType !== event.target.value));
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${language?.id ? t("common.edit") : t("common.add")}  ${t(
        "settings.general.language.language"
      )}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="code" className="form-label">
              {t("common.code")}
            </Label>
            <Input
              name="code"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.general.language.language")} ${t("common.code")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={validation.touched.code && validation.errors.code ? true : false}
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="type" className="form-label">
              {t("settings.general.language.type")}
            </Label>
            <span className="d-flex flex-column">
              <span>
                <Input
                  id="grp1"
                  type="checkbox"
                  name="types"
                  onChange={typeHandleChange}
                  onBlur={validation.handleBlur}
                  value="main"
                  style={{ marginRight: "10px" }}
                  defaultChecked={language?.is_main ? true : false}
                  invalid={validation.touched.types && validation.errors.types ? true : false}
                />
                <Label for="grp1" className="cursor-pointer">{`${t("settings.general.users.system")} ${t(
                  "settings.general.language.language"
                )}`}</Label>
              </span>
              <span>
                <Input
                  id="grp2"
                  type="checkbox"
                  name="types"
                  onChange={typeHandleChange}
                  onBlur={validation.handleBlur}
                  value="spoken"
                  style={{ marginRight: "10px" }}
                  defaultChecked={language?.is_spoken ? true : false}
                  invalid={validation.touched.types && validation.errors.types ? true : false}
                />
                <Label for="grp2" className="cursor-pointer">{`${t("settings.general.language.spoken")} ${t(
                  "settings.general.language.language"
                )}`}</Label>
              </span>
            </span>
            {validation.touched.types && validation.errors.types ? (
              <p className="custom-validation-style">{validation.errors.types}</p>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.general.language.language")} ${t("common.description")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddLanguageModal);
