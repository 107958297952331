import API from "../../helpers/api/api_interceptor";
import {
  SET_COUNTRIES_OPTIONS,
  SET_CUSTOMER_PREFERENCES,
  SET_DEFAULT_CURRENCIES,
  SET_DEFAULT_DOCUMENT_TYPES,
  SET_DEFAULT_LOTS_TAGS,
  SET_DEFAULT_PAYMENT_CONDITIONS,
  SET_DEFAULT_PAYMENT_METHODS,
  SET_DEFAULT_VATS,
  SET_TEAM_POSITIONS,
} from "./actionTypes";

// to get the list of the countries options
export const getCountriesOptions = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/countries`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        sort: params.sort,
        filter: params.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: SET_COUNTRIES_OPTIONS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllDefaultValues = () => async (dispatch) => {
  try {
    return API.get(`users/seeders`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: SET_DEFAULT_DOCUMENT_TYPES,
          payload: res?.data?.data?.document_types,
        });
        dispatch({
          type: SET_DEFAULT_LOTS_TAGS,
          payload: res?.data?.data?.lots_tags,
        });
        dispatch({
          type: SET_DEFAULT_VATS,
          payload: res?.data?.data?.vats,
        });
        dispatch({
          type: SET_DEFAULT_CURRENCIES,
          payload: res?.data?.data?.currencies,
        });
        dispatch({
          type: SET_DEFAULT_PAYMENT_CONDITIONS,
          payload: res?.data?.data?.payment_conditions,
        });
        dispatch({
          type: SET_DEFAULT_PAYMENT_METHODS,
          payload: res?.data?.data?.payment_types,
        });
        dispatch({
          type: SET_CUSTOMER_PREFERENCES,
          payload: res?.data?.data?.topics_of_interest,
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get the list of the team positions options
export const getTeamPositions = (params) => async (dispatch) => {
  try {
    return API.get(`/users/positions`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        sort: params.sort,
        filter: params.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        dispatch({
          type: SET_TEAM_POSITIONS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            nextPosition: res?.data?.next_position_no,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
