import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { CardBody, CardHeader } from "reactstrap";
import CustomSearchInput from "../../common/CustomSearchInput";

import NoRecords from "../../common/NoRecords";

import {
  checkSelectAllSelected,
  formatAmount,
  getColumnDetail,
  getObjectsByValues,
  getValuesFromArray,
} from "../../../helpers/utils";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getContractItems } from "../../../store/contracts/actions";
import Loader from "../../common/Loader";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomColumnPhoto from "../../common/CustomColumnPhoto";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import i18next from "i18next";
import CustomTextLink from "../../common/CustomTextLink";
import { SET_RETURNED_LOTS } from "../../../store/customers/actionType";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { SELECT_ALL_OPTION, YES_NO_OPTIONS } from "../../../helpers/constants/selectOptions";

const ReturnedLots = (props) => {
  const history = useHistory();
  const { paramId, id, auctionId } = useParams();
  const dispatch = useDispatch();
  const { data: lots, total: totalLots } = useSelector((state) => state?.Customers?.situation?.returnedLots);

  const [itemsPayload, setItemsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "position_contract",
    filter: {
      contract__customer: paramId,
      contract: id,
      auction_lots__auction: auctionId,
      auction_lots__is_delete: auctionId ? false : undefined,
      is_return_guide: true,
    },
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [selectedAgreedOptions, setSelectedAgreedOptions] = useState(
    itemsPayload?.filter?.value_to_be_agreed__in?.length > 0
      ? getObjectsByValues(YES_NO_OPTIONS, itemsPayload?.filter?.value_to_be_agreed__in, "value")
      : []
  );

  // handle contract item filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setItemsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setItemsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setItemsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // for the auction situatio from customer situation section
  useEffect(() => {
    if (props?.type === "customer") {
      setItemsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          auction_lots__auction: props?.selectedAuction?.id || undefined,
          auction_lots__is_delete: props?.selectedAuction?.id ? false : undefined,
        },
      }));
    }
  }, [props?.selectedAuction, props?.type]);

  // navigate to contract item form
  const navigateOnContractItemForm = (contractId, itemId) => {
    if (itemId) {
      history.push(`/contracts/contracts/${contractId}/items/edit/${itemId}`);
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle getting contract items
  const handleGetContractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then((res) => {
        if (res) {
          dispatch({
            type: SET_RETURNED_LOTS,
            payload: {
              data: res?.results,
              total: res?.count,
            },
          });
        } else {
          //
        }
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  // to get the contract items
  useEffect(() => {
    handleGetContractItems(itemsPayload);
  }, [handleGetContractItems, itemsPayload]);

  // get item -> artist, title, description
  const getItemCol = (row) => {
    let str = "";
    if (row?.author?.id) str = str.concat(`${row?.author?.description}, `);
    if (i18next?.resolvedLanguage === "pt") {
      str = row?.title_pt ? str.concat(`${row?.title_pt}`) : str.concat("");
      str = row?.description_pt ? str.concat(`, ${row?.description_pt}`) : str.concat("");
    } else {
      str = row?.title_en ? str.concat(`${row?.title_en}`) : str.concat("");
      str = row?.description_en ? str.concat(`, ${row?.description_en}`) : str.concat("");
    }
    return str || "-";
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.contractNo")} / ${props.t("common.item")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_item_no ? (
          <CustomTextLink
            text={row?.contract_item_no}
            handleClick={() => navigateOnContractItemForm(row?.contract?.id, row?.id)}
          />
        ) : (
          "-"
        ),
      width: "278px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.default_photo} title={<CustomColumnPhoto sourceUrl={row?.default_photo} />} />
      ),
      width: "138px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row ? getItemCol(row) : "-"),
      wrap: true,
      width: "418px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.agreedValue")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedAgreedOptions}
            handleOnSelection={(values) => {
              setSelectedAgreedOptions(values);
              handleDropdownFilter(values, "value_to_be_agreed__in", setSelectedAgreedOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.value_to_be_agreed ? props.t("common.yes") : props.t("common.no")),
      width: "278px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__sale_value__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => formatAmount(row?.auction_lots?.sale_value || 0) + " €",
      width: "278px",
      wrap: true,
    },
  ];

  return (
    <>
      <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
        <span className="card-title">{props.t("common.returnedLots")}</span>
      </CardHeader>
      <CardBody className="card-section-body">
        {tableLoader ? <Loader /> : null}
        <DataTable
          pointerOnHover
          fixedHeader
          className="data-table"
          persistTableHead
          columns={columns}
          data={lots}
          pagination
          paginationServer
          paginationTotalRows={totalLots}
          paginationDefaultPage={itemsPayload?.page}
          paginationPerPage={itemsPayload?.limit}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            rowsPerPageText: props.t("common.rowsPerPage"),
            rangeSeparatorText: props.t("common.rangeSeparator"),
          }}
        />
      </CardBody>
    </>
  );
};

export default withTranslation()(ReturnedLots);
