import React from "react";

const CustomTagChip = ({ title, bgColor, textColor }) => {
  return (
    <div className="custom-tag-chip" style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}>
      {title}
    </div>
  );
};

export default CustomTagChip;
