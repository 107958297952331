import React from "react";
import { Button, Spinner } from "reactstrap";

const CustomButton = ({ onClick, btnTitle, color, classes, disabled, isLoading, ...others }) => {
  return (
    <Button
      color={color ? color : "primary"}
      onClick={onClick}
      className={`${classes ? classes : ""} ${isLoading ? "btn-load d-flex align-items-center" : ""}`}
      disabled={disabled || isLoading}
      {...others}
    >
      {isLoading ? (
        <span className="d-flex align-items-center ">
          <Spinner className="flex-shrink-0"></Spinner>
        </span>
      ) : null}
      <span className={`flex-grow-1 ${isLoading ? "ms-2" : ""}`}>{btnTitle}</span>
    </Button>
  );
};

export default CustomButton;
