import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Input } from "reactstrap";

const CustomSearchInput = ({
  inputType = "text",
  initialValue,
  payload,
  setPayload,
  t,
  className,
  columnWise,
  columnSearchKey,
  clearSearch,
  setClearSearch,
  disabled,
}) => {
  const [search, setSearch] = useState(initialValue || "");

  useEffect(() => {
    if (clearSearch && search?.length) setSearch("");
  }, [clearSearch]);

  const handleSearch = (value) => {
    if (columnWise) {
      const existingSearchValue = payload?.filter?.[columnSearchKey] || "";
      if (existingSearchValue !== value.trim().toLowerCase()) {
        const search = value.replace(/\s+/g, " ").toLowerCase();
        if (search.length > 0) {
          setPayload((prevState) => {
            return {
              ...prevState,
              filter: {
                ...prevState.filter,
                [`${columnSearchKey}`]: search,
              },
              page: 1,
            };
          });
        } else {
          setPayload((prevState) => {
            const tempPayload = prevState;
            delete tempPayload.filter[columnSearchKey];
            return {
              ...tempPayload,
              page: 1,
            };
          });
        }
      } else {
        setPayload((prevState) => {
          const tempPayload = prevState;

          return {
            ...tempPayload,
            page: 1,
          };
        });
      }
    } else {
      const existingSearchValue = payload.search;
      if (existingSearchValue !== value.trim().toLowerCase()) {
        const search = value.replace(/\s+/g, " ").toLowerCase();
        setPayload((prevState) => ({
          ...prevState,
          search: search,
          page: 1,
        }));
      }
    }
  };
  return (
    <div className={className}>
      <Input
        type={inputType}
        className="form-control"
        placeholder={`${t("common.search")}...`}
        value={search}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(search);
        }}
        onChange={(e) => {
          setSearch(e.target.value);
          if (clearSearch) setClearSearch(false);
          if (e.target.value.length === 0) handleSearch("");
        }}
        disabled={disabled}
      />
      <span className="mdi mdi-magnify search-icon" onClick={() => handleSearch(search)} />
      <span
        className={`mdi mdi-close-circle search-icon clear-icon ${search?.length > 0 ? "" : "d-none"}`}
        id="search-close-options"
        onClick={() => {
          setSearch("");
          handleSearch("");
        }}
      />
    </div>
  );
};

export default withTranslation()(CustomSearchInput);
