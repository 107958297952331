import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardHeader, Input, Label } from "reactstrap";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomSearchInput from "../../../components/common/CustomSearchInput";
import CustomTagChip from "../../../components/common/CustomTagChip";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../components/common/DeleteModal";
import Loader from "../../../components/common/Loader";
import NoRecords from "../../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS, DEFAULT_ROW_PER_PAGE, EXTERNAL, GENERAL } from "../../../helpers/constants";
import { checkPermissionByRoles, generateContrastColor, getOnlyCustomerName } from "../../../helpers/utils";
import { SET_CUSTOMER_DETAILS } from "../../../store/customers/actionType";
import { deletePrecontract, getPrecontracts } from "../../../store/pre-contracts/actions";

const defaultPreContractsPayload = {
  search: "",
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  filter: {},
};

const PreContracts = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [precontractsPayload, setPrecontractsPayload] = useState(defaultPreContractsPayload);
  const [selectedPrecontract, setSelectedPrecontract] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSearch, setClearSearch] = useState();

  const user = useSelector((state) => state?.User?.userData);
  const { data: precontractsData, total: totalPrecontracts } = useSelector((state) => state?.PreContracts);

  // handle get pre contracts
  const handleGetPrecontracts = useCallback(
    (precontractsPayload) => {
      setTableLoader(true);
      dispatch(getPrecontracts(precontractsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      handleGetPrecontracts({
        ...precontractsPayload,
        filter: {
          ...precontractsPayload.filter,
          specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) ? [user.id] : undefined,
        },
      });
    }
  }, [precontractsPayload, handleGetPrecontracts, user.id]);

  // handle row selection
  const handleRowSelection = (rowId) => {
    const isSelected = Boolean(selectedRows.find((item) => item === rowId));

    if (isSelected) {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setIsAllSelected(false);
    } else {
      setSelectedRows((prevState) => [...prevState, rowId]);
    }
  };

  const handleClearFilters = () => {
    setPrecontractsPayload(defaultPreContractsPayload);
    setSelectedPrecontract(null);
    setTableLoader(false);
    setIsDeleteModalOpen(false);
    setDeleteLoader(false);
    setIsAllSelected(false);
    setSelectedRows([]);
    setClearSearch(true);
  };

  //   navigate on precontract from
  const navigateOnPrecontractForm = (id) => {
    if (id) {
      history.push(`/contracts/pre-contracts/edit/${id}`);
    } else {
      history.push("/contracts/pre-contracts/new");
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (precontractData) => {
    setSelectedPrecontract(precontractData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedPrecontract(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeletePrecontract = () => {
    if (selectedPrecontract && selectedPrecontract?.id) {
      setDeleteLoader(true);
      dispatch(deletePrecontract(selectedPrecontract?.id))
        .then((res) => {
          if (res) {
            handleGetPrecontracts(precontractsPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setPrecontractsPayload((prevState) => ({
      ...prevState,
      page: value,
      filter: {
        specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) ? [user.id] : undefined,
      },
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPrecontractsPayload((prevState) => ({
      ...prevState,
      limit: value,
      filter: {
        specialists__in: !checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) ? [user.id] : undefined,
      },
    }));
  };

  const navigateOnCustomerForm = (row) => {
    if (row?.customer?.id && !row?.customer?.parent_id) {
      dispatch({
        type: SET_CUSTOMER_DETAILS,
        payload: {},
      });
      history.push(`/customers/edit/${row?.customer?.id}`);
    } else if (row?.customer?.parent_id) {
      history.push(`/customers/${row?.customer?.parent_id}/edit-person/${row?.customer?.id}`);
    }
  };

  const columns = [
    // {
    //   name: (
    //     <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
    //       <Input
    //         type="checkbox"
    //         checked={isAllSelected}
    //         onChange={(event) => {
    //           setIsAllSelected(event.target.checked);
    //           setSelectedRows(event.target.checked ? precontractsData.map((row) => row.id) : []);
    //         }}
    //       />
    //     </div>
    //   ),
    //   selector: (row) => (
    //     <Input
    //       type="checkbox"
    //       checked={Boolean(selectedRows.find((item) => item === row.id))}
    //       onChange={() => handleRowSelection(row.id)}
    //     />
    //   ),
    //   width: "50px",
    //   center: true,
    // },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.precontractNo")}</div>
          {!checkPermissionByRoles([EXTERNAL]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="pre_contract_no__icontains"
              className="column-search-input"
              payload={precontractsPayload}
              setPayload={setPrecontractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => (row?.pre_contract_no ? row?.pre_contract_no : "-"),
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.customerName")}</div>
          {!checkPermissionByRoles([EXTERNAL]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="search_name__icontains"
              className="column-search-input"
              payload={precontractsPayload}
              setPayload={setPrecontractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => (
        <Label
          onClick={() => {
            if (!checkPermissionByRoles([EXTERNAL])) {
              navigateOnCustomerForm(row);
            }
          }}
          className="cursor-pointer"
        >
          <CustomTagChip
            title={getOnlyCustomerName(row?.customer)}
            bgColor={"#F6F6F6"}
            textColor={generateContrastColor("#F6F6F6")}
          />
        </Label>
      ),
      minWidth: "300px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.numberOfItemsInPrecontract")}</div>
          {!checkPermissionByRoles([EXTERNAL]) ? (
            <CustomSearchInput
              columnWise={true}
              columnSearchKey="item_count__icontains"
              className="column-search-input"
              payload={precontractsPayload}
              setPayload={setPrecontractsPayload}
              clearSearch={clearSearch}
              setClearSearch={setClearSearch}
            />
          ) : null}
        </div>
      ),
      selector: (row) => row?.item_count,
      wrap: true,
      minWidth: "230px",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
              checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
            }`}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
            style={{
              outline: "none",
              border: "none",
              opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
              cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i
              className={`ri-delete-bin-fill fs-18 ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
              }`}
              id={`tooltip-${row?.id}-delete`}
              style={{
                color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                border: "none",
              }}
            />
          </button>
        </div>
      ),
      minWidth: "80px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.pre-contracts")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
          <div className="container-header ">
            {!checkPermissionByRoles([EXTERNAL]) ? (
              <CustomSearchInput
                className="custom-search-input"
                payload={precontractsPayload}
                setPayload={setPrecontractsPayload}
                clearSearch={clearSearch}
                setClearSearch={setClearSearch}
                disabled={tableLoader}
              />
            ) : null}
            <div className="contain-header-right d-flex flex-row gap-2">
              {(isAllSelected && totalPrecontracts > 0) || selectedRows.length ? (
                <Button
                  color="info"
                  onClick={() => {
                    setSelectedRows([]);
                    setIsAllSelected(false);
                  }}
                >
                  {props.t("common.deselect")}
                  <Badge color="dark" className="ms-1">
                    {selectedRows.length || totalPrecontracts}
                  </Badge>
                </Button>
              ) : null}

              {/* Highlighted: Clear Filters Button */}

              <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
                {props.t("common.clearFilters")}
              </Button>

              <CustomAddButton
                color={"success"}
                btnTitle={props.t("common.addTitle", {
                  module: props.t("contracts.precontracts.precontract"),
                })}
                onClick={() => navigateOnPrecontractForm()}
              />
            </div>
          </div>
        )}
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.pre-contracts")}</CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table pre-contract-table"
            persistTableHead
            onRowClicked={(row) => {
              navigateOnPrecontractForm(row?.id);
            }}
            columns={columns}
            data={[...precontractsData]}
            pagination
            paginationServer
            paginationTotalRows={totalPrecontracts}
            paginationDefaultPage={precontractsPayload?.page}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeletePrecontract}
        title={`${props.t("common.delete")} ${props.t("contracts.precontracts.precontract")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("contracts.precontracts.precontract"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(PreContracts);
