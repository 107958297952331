import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import CustomButton from "../../../components/common/CustomButton";
import TabsComponent from "../../../components/common/TabsComponent";
import PrivateSalesContent from "../../../components/pages/website-management/pages/PrivateSalesContent";
import { PRIVATE_SALES, TYPE_PUBLISH, TYPE_SAVE } from "../../../helpers/constants";
import { checkLinksInPagesData, uploadEditorState } from "../../../helpers/utils";
import { uploadWebsitePagesInformation } from "../../../store/website-pages/actions";

const LANGUAGE_TABS = ["pt", "en"];

const PrivateSales = ({ selectedPage, ...props }) => {
  const dispatch = useDispatch();
  const { info: privateSalesInfo } = useSelector((state) => state?.Pages);
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isFormWarningModalOpen, setIsFormWarningModalOpen] = useState(false);
  const [isENFormEmpty, setIsENFormEmpty] = useState(false);
  const [isPTFormEmpty, setIsPTFormEmpty] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleFormWarningModalOpen = (isENFormEmpty, isPTFormEmpty) => {
    setIsFormWarningModalOpen(true);
    setIsENFormEmpty(isENFormEmpty);
    setIsPTFormEmpty(isPTFormEmpty);
  };
  const handleFormWarningModalClose = () => {
    setIsFormWarningModalOpen(false);
    setIsENFormEmpty(false);
    setIsPTFormEmpty(false);
  };

  const handleInfoModalOpen = () => {
    setIsInfoModalOpen(true);
  };
  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const informationValidation = Yup.object()
    .required(
      props.t("validation.generalField", {
        promptType: props.t("common.enter"),
        fieldType: props.t("common.information"),
      })
    )
    .test(
      "meta",
      props.t("validation.generalField", {
        promptType: props.t("common.enter"),
        fieldType: props.t("common.information"),
      }),
      (value) => {
        if (value?.getCurrentContent().hasText()) {
          return true;
        }
        return false;
      }
    );

  const privateSalesValidation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      banner:
        privateSalesInfo?.banner?.length > 0
          ? privateSalesInfo?.banner
          : [{ file: "", title: "", subtitle: "", link: "" }],
      meta_info: [
        {
          id: privateSalesInfo?.meta_info?.[0]?.id || "",
          information_1: privateSalesInfo?.meta_info?.[0]?.information_1 || "",
        },
      ],
    },
    validationSchema: Yup.object({
      banner: Yup.array().of(
        Yup.object().shape({
          file: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: `${props.t("common.image")}/${props.t("common.video")}`,
            })
          ),
          title: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.title"),
            })
          ),
        })
      ),
      meta_info: Yup.array().of(
        Yup.object().shape({
          information_1: informationValidation,
        })
      ),
    }),
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  // to format the save and publish payload
  const formatPayload = (values, save_type) => {
    const payload = [];
    Object.keys(values).forEach((key) => {
      if (key === "meta_info") {
        payload.push({
          id: values?.meta_info?.[0]?.id,
          website_page: selectedPage?.id,
          language_type: LANGUAGE_TABS[activeTabIndex],
          save_type: save_type,
          file_type: key,
          meta: JSON.stringify({
            information_1: values?.meta_info?.[0]?.information_1
              ? uploadEditorState(values?.meta_info?.[0]?.information_1)
              : "",
          }),
        });
      } else {
        values?.[key]?.forEach((item) => {
          payload.push({
            ...item,
            website_page: selectedPage?.id,
            language_type: LANGUAGE_TABS[activeTabIndex],
            save_type: save_type,
            file_type: key,
            field1: item?.title,
            field2: item?.subtitle,
            link: item?.link,
            file: item?.file,
            meta: item?.meta ? uploadEditorState(item?.meta) : "",
          });
        });
      }
    });
    return payload;
  };

  // to handle save and save&publish of images
  const handleSave = (values) => {
    if (checkLinksInPagesData(values)) {
      handleInfoModalOpen();
    }
    const payload = formatPayload(values, TYPE_SAVE);
    setSaveLoader(true);
    dispatch(uploadWebsitePagesInformation(payload, PRIVATE_SALES, TYPE_SAVE)).then((res) => {
      setSaveLoader(false);
    });
  };

  // handle publish
  const handlePublish = () => {
    if (privateSalesValidation?.dirty) {
      return toast.info(props.t("information.saveBeforePublish"));
    }
    setPublishLoader(true);
    dispatch(
      uploadWebsitePagesInformation(
        {
          website_page: selectedPage?.id,
          save_type: TYPE_PUBLISH,
        },
        PRIVATE_SALES,
        TYPE_PUBLISH
      )
    ).then((res) => {
      if (res?.data?.is_en_remaining || res?.data?.is_pt_remaining) {
        handleFormWarningModalOpen(res?.data?.is_en_remaining, res?.data?.is_pt_remaining);
      }
      setPublishLoader(false);
    });
  };

  // to reset form
  const handleReset = () => {
    privateSalesValidation.resetForm();
  };

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (privateSalesValidation?.dirty) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        setActiveTabIndex(index);
      }
    } else {
      setActiveTabIndex(index);
    }
  };

  return (
    <div className="file-manager-content w-100 p-3 homepage-main-content">
      <Prompt when={privateSalesValidation?.dirty} message={props.t("confirmation.resetTabData")} />
      <TabsComponent
        controlledTabChange={true}
        activeTab={activeTabIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabData={[
          {
            label: props.t("common.pt"),
            Component: PrivateSalesContent,
            props: {
              validation: privateSalesValidation,
              website_page: selectedPage?.id,
              languageType: "pt",
            },
            disabled: false,
          },
          {
            label: props.t("common.en"),
            Component: PrivateSalesContent,
            props: {
              validation: privateSalesValidation,
              website_page: selectedPage?.id,
              languageType: "en",
            },
            disabled: false,
          },
        ]}
        HeaderCompoenent={
          <div className="d-flex flex-row gap-2 ms-auto">
            <CustomButton
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_NEXT_WEBSITE}/${LANGUAGE_TABS[activeTabIndex]}/private-sales?preview=true`,
                  "_blank"
                );
              }}
              btnTitle={props.t("common.preview")}
              disabled={saveLoader || publishLoader}
            />
            <CustomButton
              isLoading={saveLoader}
              btnTitle={props.t("common.save")}
              disabled={publishLoader}
              onClick={() => privateSalesValidation?.handleSubmit()}
            />
            <CustomButton
              isLoading={publishLoader}
              btnTitle={props.t("common.publish")}
              disabled={saveLoader}
              onClick={handlePublish}
            />
            <CustomButton
              btnTitle={props.t("common.reset")}
              color="dark"
              disabled={saveLoader || publishLoader}
              onClick={handleReset}
            />
          </div>
        }
      />
      {/* Empty link popup modal */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isInfoModalOpen}
        onCloseBtnHandler={handleInfoModalClose}
        body={props.t("information.linkEmpty")}
      />
      {/* EN form empty fields */}
      <ConfirmationModal
        informativeModal
        title={props.t("common.warning")}
        isModalOpen={isFormWarningModalOpen}
        onCloseBtnHandler={handleFormWarningModalClose}
        body={
          isENFormEmpty && isPTFormEmpty
            ? props.t("information.ENPTFormSaveReminder")
            : isENFormEmpty
            ? props.t("information.enFormSaveReminder")
            : isPTFormEmpty
            ? props.t("information.ptFormSaveReminder")
            : ""
        }
      />
    </div>
  );
};

export default withTranslation()(PrivateSales);
