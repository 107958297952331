import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";

const SendSalesNoteModal = ({ isModalOpen, loader, onSendBtnHandler, onChooseBtnHandler, onCloseBtnHandler, t }) => {
  const handleModalClose = () => {
    if (!loader) onCloseBtnHandler();
  };

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
        <ModalHeader>{t("common.sendSalesNotes")}</ModalHeader>
        <ModalBody>{t("confirmation.sendSalesNotes")}</ModalBody>
        <ModalFooter>
          <CustomButton
            isLoading={loader}
            color={"success"}
            btnTitle={t("common.sendAll")}
            onClick={() => {
              onSendBtnHandler();
            }}
          />
          <CustomButton disabled={loader} btnTitle={t("common.choose")} onClick={onChooseBtnHandler} />
          <CustomButton disabled={loader} color={"dark"} btnTitle={t("common.cancel")} onClick={handleModalClose} />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(SendSalesNoteModal);
