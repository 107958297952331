import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddArtistModal from "../../../../components/pages/settings/AddArtistModal";
import { addArtist, deleteArtist, getArtists, updateArtist } from "../../../../store/artists/actions";

const Artists = (props) => {
  const dispatch = useDispatch();
  const { data: artistsData, total: totalArtists } = useSelector((state) => state?.Artists);

  const [artistsPayload, setArtistsPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    sort: "",
    filter: {
      id__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isArtistModalOpen, setIsArtistModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // add or edit action handlers
  const handleArtistModalOpen = (artistData) => {
    setSelectedArtist(artistData);
    setIsArtistModalOpen(true);
  };
  const handleArtistModalClose = () => {
    setSelectedArtist(null);
    setIsArtistModalOpen(false);
  };
  const handleSaveArtist = (values, formikValidation) => {
    // check for add or edit
    if (selectedArtist && selectedArtist?.id) {
      // edit category
      setSaveLoader(true);
      dispatch(updateArtist(selectedArtist?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleArtistModalClose();
            formikValidation.resetForm();
            handleGetArtists(artistsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add category
      setSaveLoader(true);
      dispatch(addArtist(values))
        .then((res) => {
          if (res) {
            // success handler
            handleArtistModalClose();
            formikValidation.resetForm();
            handleGetArtists(artistsPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (artistData) => {
    setSelectedArtist(artistData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedArtist(null);
    setIsDeleteActionAllowed(false);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteArtist = () => {
    if (selectedArtist && selectedArtist?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteArtist({
          id: selectedArtist?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetArtists(artistsPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setArtistsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setArtistsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setArtistsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get artists
  const handleGetArtists = useCallback(
    (artistsPayload) => {
      setTableLoader(true);
      dispatch(getArtists(artistsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetArtists(artistsPayload);
  }, [handleGetArtists, artistsPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={artistsPayload}
            setPayload={setArtistsPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.description")}
            sortable
            sortableKey="description"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={artistsPayload}
            setPayload={setArtistsPayload}
          />
        </div>
      ),
      selector: (row) => row?.description || "-",
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.description")}
            sortable
            sortableKey="description_en"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description_en__icontains"
            className="column-search-input"
            payload={artistsPayload}
            setPayload={setArtistsPayload}
          />
        </div>
      ),
      selector: (row) => row?.description_en || "-",
      minWidth: "300px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleArtistModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.artists")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput className="custom-search-input" payload={artistsPayload} setPayload={setArtistsPayload} />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleArtistModalOpen}
              btnTitle={props.t("common.addTitle", {
                module: props.t("settings.lots.artists.artist"),
              })}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.artists")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...artistsData]}
            pagination
            paginationServer
            paginationTotalRows={totalArtists}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddArtistModal
        artist={selectedArtist}
        isModalOpen={isArtistModalOpen}
        onCancel={handleArtistModalClose}
        onSave={handleSaveArtist}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteArtist}
        title={`${props.t("common.delete")} ${props.t("settings.lots.artists.artist")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.lots.artists.artist"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Artists);
