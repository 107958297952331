import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Col, Form, Input, Popover, PopoverBody, Row } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import * as Yup from "yup";
import ActionButton from "../../common/ActionButton";
import NumberInput from "../../common/NumberInput";

const LotPopover = ({ target, saveLoader, clearLoader, isOpen, onCancel, onSave, onClear, lotNo, lotSuffix, t }) => {
  const handlePopoverClose = () => {
    if (!saveLoader && !clearLoader) onCancel();
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      lot_no: lotNo || "",
      lot_suffix: lotSuffix || "",
    },
    validationSchema: Yup.object({
      lot_no: Yup.number().min(1, t("validation.numberMin", { totalNumber: 1 })),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });

  return (
    <Popover placement="right" isOpen={isOpen} target={target} toggle={handlePopoverClose}>
      <PopoverBody>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="mb-2">
            <NumberInput
              autoFocus
              name="lot_no"
              className="form-control"
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.lotNo"),
              })}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lot_no}
              invalid={validation.touched.lot_no && validation.errors.lot_no ? true : false}
            />
            {validation.touched.lot_no && validation.errors.lot_no ? (
              <p className="custom-invalid-feedback">{validation.errors.lot_no}</p>
            ) : null}
          </div>

          <div className="mb-2">
            <Input
              name="lot_suffix"
              className="form-control"
              placeholder={`${t("placeholder.enterField", {
                fieldType: t("common.suffix"),
              })}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lot_suffix}
              invalid={validation.touched.lot_suffix && validation.errors.lot_suffix ? true : false}
            />
            {validation.touched.lot_suffix && validation.errors.lot_suffix ? (
              <p className="custom-invalid-feedback">{validation.errors.lot_suffix}</p>
            ) : null}
          </div>

          <div className="contain-header-right d-flex flex-row gap-2 justify-content-between">
            <div className="gap-2 d-flex">
              <CustomButton
                color={"dark"}
                type="button"
                disabled={saveLoader}
                btnTitle={t("common.cancel")}
                onClick={handlePopoverClose}
              />
              <CustomButton
                disabled={validation?.values?.lot_no ? false : true}
                isLoading={saveLoader}
                btnTitle={t("common.save")}
              />
            </div>
            {lotNo && (
              <ActionButton
                disabled={saveLoader || clearLoader}
                iconClasses={"ri-delete-bin-fill fs-18"}
                onClick={onClear}
              />
            )}
          </div>
        </Form>
      </PopoverBody>
    </Popover>
  );
};

export default withTranslation()(LotPopover);
