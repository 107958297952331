import { SET_NOTE_LIST } from "./actionType";

const INIT_STATE = {
  total: 0,
  data: [],
};

const Notes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NOTE_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default Notes;
