import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import TabsComponent from "../../../components/common/TabsComponent";
import AuctionsEditionContent from "../../../components/pages/website-management/auctions/AuctionsEditionContent";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TYPE_PUBLISH, TYPE_SAVE, VERITAS_INFO_MAIL, VERITAS_PHONE_NUMBER } from "../../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { getAuctionById } from "../../../store/auctions/actions";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { addAuctionEdition } from "../../../store/website-pages/actions";
import Loader from "../../../components/common/Loader";
import { getEditorState, uploadEditorState } from "../../../helpers/utils";

const AuctionEdition = (props) => {
  const dispatch = useDispatch();
  const { id, editionType } = useParams();
  const history = useHistory();
  const auctionData = useSelector((state) => state?.Auctions?.auction);

  const [auctionEditionData, setAuctionEditionData] = useState();
  const [activeLanguageTabIndex, setActiveLanguageTabIndex] = useState(editionType === "pt" ? 0 : 1);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [infoLoader, setInfoLoader] = useState(false);

  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [defaultAuctionText, setDefaultAuctionText] = useState();
  const [defaultAuctionInfo, setDefautlAuctionInfo] = useState();
  const [isInfoFormDirty, setIsInfoFormDirty] = useState(false);
  const [isTabChanging, setIsTabChanging] = useState(false);

  const catalogueValidation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      catalogue_spine_color: auctionEditionData?.spine_color || "#000000",
      catalogue: [
        {
          issuu_link: auctionEditionData?.issuu_link || "",
          pdf_catalogue: auctionEditionData?.pdf_catalogue || "",
          pdf_erratum: auctionEditionData?.pdf_erratum || "",
        },
      ],
    },
    validationSchema: Yup.object({
      catalogue: Yup.array().of(
        Yup.object().shape({
          issuu_link: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.enter"),
              fieldType: props.t("common.issuuLink"),
            })
          ),
          pdf_catalogue: Yup.string().required(
            props.t("validation.generalField", {
              promptType: props.t("common.upload"),
              fieldType: props.t("common.pdfCatalogue"),
            })
          ),
          pdf_erratum: Yup.string(),
          // pdf_erratum: Yup.string().required(
          //   props.t("validation.generalField", {
          //     promptType: props.t("common.upload"),
          //     fieldType: props.t("common.pdfErratum"),
          //   })
          // ),
        })
      ),
    }),
    onSubmit: (values) => {
      handleSaveAndPublishCatalogue(values, TYPE_PUBLISH);
    },
  });

  const infoValidation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      auction_text: defaultAuctionText || "",
      information: defaultAuctionInfo || "",
      highlights: auctionEditionData?.highlights || [],
      information_title: auctionEditionData?.information_title || "",
      virtual_link: auctionEditionData?.virtual_link || "",
    },
    validationSchema: Yup.object({
      auction_text: Yup.object().required(
        props.t("validation.generalField", {
          promptType: props.t("common.enter"),
          fieldType: props.t("common.auctionText"),
        })
      ),
      information_title: Yup.string(),
      information: Yup.object().required(
        props.t("validation.generalField", {
          promptType: props.t("common.enter"),
          fieldType: props.t("common.information"),
        })
      ),
      virtual_link: Yup.string(),
    }),
    onSubmit: (values) => {
      handleSaveAndPublishInfo(values, TYPE_PUBLISH);
    },
  });

  // to prefill the auction text with auctioninfo text or auction edition data
  useEffect(() => {
    if (auctionData && !auctionEditionData?.auction_text) {
      setDefaultAuctionText(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(editionType === "pt" ? auctionData?.auction_text_pt : auctionData?.auction_text_en)
          )
        )
      );
    } else {
      setDefaultAuctionText(auctionEditionData?.auction_text ? getEditorState(auctionEditionData?.auction_text) : "");
    }
  }, [auctionData, editionType, auctionEditionData]);

  // useEffect to prefill all the auction info's information text
  useEffect(() => {
    setDefautlAuctionInfo(
      auctionEditionData?.information
        ? getEditorState(auctionEditionData?.information)
        : EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(
                editionType === "pt"
                  ? `<a href="mailto:${VERITAS_INFO_MAIL}">${VERITAS_INFO_MAIL}</a><br/><p>${VERITAS_PHONE_NUMBER}</p>`
                  : `<a href="mailto:${VERITAS_INFO_MAIL}">${VERITAS_INFO_MAIL}</a><br/><p>+351${VERITAS_PHONE_NUMBER}</p>`
              )
            )
          )
    );
  }, [editionType, auctionEditionData]);

  // to get the auction edition information
  useEffect(() => {
    if (auctionData?.auction_management?.length > 0 && ["en", "pt"].includes(editionType)) {
      setAuctionEditionData(() => {
        const data = auctionData?.auction_management?.filter(
          (edition) => edition?.save_type === TYPE_SAVE && edition?.language_type === editionType
        );
        return { ...data?.[0] };
      });
    } else {
      setAuctionEditionData();
    }
  }, [editionType, auctionData]);

  // to handle save and save&publish of catalogue
  const handleSaveAndPublishCatalogue = (values, save_type) => {
    const payload = {
      auction: id,
      language_type: editionType,
      save_type,
      category_type: "catalogue",
      issuu_link: values?.catalogue?.[0]?.issuu_link,
      pdf_erratum: values?.catalogue?.[0]?.pdf_erratum,
      pdf_catalogue: values?.catalogue?.[0]?.pdf_catalogue,
      spine_color: values?.catalogue_spine_color,
    };
    if (save_type === TYPE_SAVE) setSaveLoader(true);
    if (save_type === TYPE_PUBLISH) setPublishLoader(true);
    dispatch(addAuctionEdition(payload)).then((res) => {
      setSaveLoader(false);
      setPublishLoader(false);
      handleGetAuctionById(id);
    });
  };

  // to handle save and save&publish of info
  const handleSaveAndPublishInfo = (values, save_type) => {
    const payload = {
      auction: id,
      language_type: editionType,
      save_type,
      category_type: "info",
      auction_text: values?.auction_text ? uploadEditorState(values?.auction_text) : "",
      information: values?.information ? uploadEditorState(values?.information) : "",
      highlights: values?.highlights?.map((item) => ({
        ...item,
        auction_lot: item?.auction_lot?.id,
      })),
      information_title: values?.information_title || "",
      virtual_link: values?.virtual_link || "",
    };
    if (save_type === TYPE_SAVE) setSaveLoader(true);
    if (save_type === TYPE_PUBLISH) setPublishLoader(true);
    dispatch(addAuctionEdition(payload)).then((res) => {
      setSaveLoader(false);
      setPublishLoader(false);
      handleGetAuctionById(id);
    });
  };

  // to handle save only
  const handleSave = () => {
    if (activePageIndex === 1) {
      catalogueValidation?.setErrors({});
      handleSaveAndPublishCatalogue(catalogueValidation?.values, TYPE_SAVE);
    }
    if (activePageIndex === 2) {
      infoValidation?.setErrors({});
      handleSaveAndPublishInfo(infoValidation?.values, TYPE_SAVE);
    }
  };

  // to reset form
  const handleReset = () => {
    if (activePageIndex === 1) catalogueValidation.resetForm();
    if (activePageIndex === 2) infoValidation.resetForm();
  };

  // handle navigation
  const handleAuctionsNavigation = (activeLanguageTabIndex) => {
    if (activeLanguageTabIndex === 0) {
      history.push(`/website-management/website-auctions/${id}/edition/pt`);
    } else {
      history.push(`/website-management/website-auctions/${id}/edition/en`);
    }
    setIsTabChanging(false);
  };

  useEffect(() => {
    setActivePageIndex(0);
  }, [activeLanguageTabIndex]);

  useEffect(() => {
    if (editionType === "pt") {
      setActiveLanguageTabIndex(0);
    } else if (editionType === "en") {
      setActiveLanguageTabIndex(1);
    }
  }, [editionType]);

  useEffect(() => {
    handleAuctionsNavigation(activeLanguageTabIndex);
  }, [activeLanguageTabIndex]);

  const handleGetAuctionById = useCallback(
    (id) => {
      setInfoLoader(true);
      dispatch(getAuctionById(id, { auction_management: true })).then((res) => {
        if (!res) {
          history.push(`/website-management/website-auctions`);
        }
        setInfoLoader(false);
      });
    },
    [dispatch, history]
  );

  // to get the auction information
  useEffect(() => {
    if (id && ["en", "pt"].includes(editionType)) {
      handleGetAuctionById(id);
    }
  }, [dispatch, id, editionType, history, activePageIndex, handleGetAuctionById]);

  useEffect(() => {
    if (!["en", "pt"].includes(editionType)) {
      history.push("/website-management/website-auctions");
    }
  }, [editionType, history]);

  // useEffect to reset form on change of the language tabs
  useEffect(() => {
    catalogueValidation?.resetForm();
    infoValidation?.resetForm();
  }, [activeLanguageTabIndex, activePageIndex]);
  // handle change in active language tab index
  const handleChangeActiveLanguageTabIndex = (index) => {
    if (catalogueValidation?.dirty || infoValidation?.dirty || isInfoFormDirty) {
      setIsTabChanging(true);
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        setActiveLanguageTabIndex(index);
        setIsInfoFormDirty(false);
      } else {
        setIsTabChanging(false);
      }
    } else {
      setActiveLanguageTabIndex(index);
    }
  };

  document.title = `Veritas | ${props.t("common.auctionEdition")}`;

  return (
    <div className="page-content layout-main-container">
      <Prompt
        when={(catalogueValidation?.dirty || infoValidation?.dirty || isInfoFormDirty) && !isTabChanging}
        message={props.t("confirmation.resetTabData")}
      />
      {infoLoader ? <Loader /> : null}
      <div className="container-header">
        <CustomBreadcrumb
          data={[
            {
              label: props.t("sidebarMenu.auctions"),
              link: "/website-management/website-auctions",
            },
            {
              label: `${props.t("common.auction")} (${auctionData?.auction_no})`,
            },
            {
              label: `${props.t("common.edition")}`,
            },
          ]}
        />
      </div>
      <Card className="edition-container">
        <TabsComponent
          controlledTabChange={true}
          activeTab={activeLanguageTabIndex}
          setActiveTab={handleChangeActiveLanguageTabIndex}
          tabData={[
            {
              label: props.t("common.pt"),
              Component: AuctionsEditionContent,
              props: {
                languageType: "pt",
                activePageIndex,
                setActivePageIndex,
                catalogueValidation,
                infoValidation,
                isInfoFormDirty,
                setIsInfoFormDirty,
              },
            },
            {
              label: props.t("common.en"),
              Component: AuctionsEditionContent,
              props: {
                languageType: "en",
                activePageIndex,
                setActivePageIndex,
                catalogueValidation,
                infoValidation,
                handleGetAuctionById,
                isInfoFormDirty,
                setIsInfoFormDirty,
              },
            },
          ]}
          HeaderCompoenent={
            activePageIndex !== 0 ? (
              <div className="d-flex flex-row gap-2 ms-auto">
                <CustomButton disabled={saveLoader || publishLoader} btnTitle={props.t("common.preview")} />
                <CustomButton
                  isLoading={saveLoader}
                  disabled={publishLoader}
                  btnTitle={props.t("common.save")}
                  onClick={handleSave}
                />
                <CustomButton
                  isLoading={publishLoader}
                  disabled={saveLoader}
                  btnTitle={props.t("common.save&publish")}
                  onClick={() => {
                    let validation;
                    if (activePageIndex === 1) validation = catalogueValidation;
                    if (activePageIndex === 2) validation = infoValidation;
                    validation?.handleSubmit();
                  }}
                />
                <CustomButton
                  disabled={saveLoader || publishLoader}
                  btnTitle={props.t("common.reset")}
                  color="dark"
                  onClick={handleReset}
                />
              </div>
            ) : null
          }
        />
      </Card>
    </div>
  );
};

export default withTranslation()(AuctionEdition);
