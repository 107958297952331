import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";

import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { generateContrastColor, getColumnDetail } from "../../../helpers/utils";
import CustomTagChip from "../../common/CustomTagChip";
import { getTags } from "../../../store/lots-tags/actions";
import Loader from "../../common/Loader";

const BulkUpdateLot = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const auctionLotTagOptions = useSelector((state) => state?.LotsTags?.data)?.filter(
    (tag) => tag?.tag_label === "Available for Auction" || tag?.tag_label === "Available"
  );

  const totalItems = useSelector((state) => state?.LotsTags?.total);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tagsLoader, setTagsLoader] = useState(false);
  const [lotsTagsPayload, setLotsTagsPayload] = useState({ sort: getColumnDetail("tag_label", "tag_label_pt") });

  const handleSaveBtn = () => {
    onSave(selectedRows);
    handleModalClose();
  };

  const handleModalClose = () => {
    setSelectedRows([]);
    setIsAllSelected(false);
    onCancel();
  };

  const handleRowSelection = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row?.id]);
      }
    }
  };

  // use effect to get the list of lots tags
  useEffect(() => {
    if (isModalOpen) {
      setTagsLoader(true);
      dispatch(getTags(lotsTagsPayload)).then(() => {
        setTagsLoader(false);
      });
    }
  }, [isModalOpen, lotsTagsPayload, dispatch]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              if (isAllSelected) {
                setIsAllSelected(event.target.checked);
                setSelectedRows([]);
              } else {
                setIsAllSelected(event.target.checked);
                setSelectedRows(auctionLotTagOptions?.map((item) => item?.id));
              }
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            handleRowSelection(row);
          }}
        />
      ),
      width: "20%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${t("common.description")}`}</div>
        </div>
      ),
      selector: (row) => (
        <span onClick={() => handleRowSelection(row)}>
          <CustomTagChip
            title={getColumnDetail(row?.tag_label, row?.tag_label_pt)}
            bgColor={row?.tag_color}
            textColor={generateContrastColor(row?.tag_color || "#000")}
          />
        </span>
      ),
      width: "80%",
    },
  ];

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader className="lot-header w-100">
        <div className="d-flex w-100 flex-row justify-content-between">
          <span>
            {`${t("common.update", {
              module: t("common.tags_capital"),
            })}`}
          </span>
          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalItems > 0) || selectedRows.length ? (
              <>
                <Button
                  color="info"
                  onClick={() => {
                    setSelectedRows([]);
                    setIsAllSelected(false);
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {`${t("common.deselect")}`}
                  <Badge color="dark" className="ms-1">
                    {selectedRows.length || totalItems}
                  </Badge>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Card className="subcard-table-container table-light mb-0">
          {tagsLoader ? <Loader /> : null}
          <DataTable
            fixedHeader
            pointerOnHover
            className="mini-data-table"
            persistTableHead
            columns={columns}
            data={[...auctionLotTagOptions]}
            //   pagination
            //   paginationServer
            paginationTotalRows={totalItems}
            noDataComponent={<NoRecords />}
            onRowClicked={(row) => handleRowSelection(row)}
            //   onChangeRowsPerPage={handleRowsPerPageChange}
            //   onChangePage={handlePageChange}
            //   paginationComponentOptions={{
            //     rowsPerPageText: t("common.rowsPerPage"),
            //     rangeSeparatorText: t("common.rangeSeparator"),
            //   }}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="dark"
          onClick={() => {
            handleModalClose();
          }}
          disabled={loader}
        >
          {t("common.cancel")}
        </Button>
        <Button
          color="primary"
          disabled={loader || (isAllSelected === false && selectedRows.length < 1) || totalItems < 1}
          className="btn-load d-flex"
          onClick={handleSaveBtn}
        >
          {loader ? (
            <span className="d-flex align-items-center ">
              <Spinner size="xs" className="flex-shrink-0">
                {t("common.loading")}...
              </Spinner>
            </span>
          ) : null}
          <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{`${t("common.save")}`}</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(BulkUpdateLot);
