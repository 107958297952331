import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Badge, Button, Card, CardHeader, Col, Input, Label, Row } from "reactstrap";
import {
  DEBOUNCE_TIME,
  DEFAULT_ROW_PER_PAGE,
  DROPDOWN_DEFAULT_LIMIT,
  INVOICE_DOCUMENT,
} from "../../../helpers/constants";
import {
  formatAmount,
  generateDocumentLinesFromLots,
  getColumnDetail,
  getOnlyCustomerName,
  openFileURL,
} from "../../../helpers/utils";
import { getBuyerDocuments } from "../../../store/auctions/actions";
import { SET_PADDLE_LOTS } from "../../../store/auctions/actionType";
import { getLotLists } from "../../../store/auctions/lotActions";
import { getPaddles } from "../../../store/auctions/paddleActions";
import { exportInvoice } from "../../../store/invoicing/actions";
import ActionButton from "../../common/ActionButton";
import CustomButton from "../../common/CustomButton";
import CustomTextLink from "../../common/CustomTextLink";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";

const Invoice = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const PADDLES_OPTIONS = useSelector((state) => state?.Auctions?.paddles?.data);
  const lotsData = useSelector((state) => state?.Auctions?.invoice?.lots?.data);
  const totalLots = useSelector((state) => state?.Auctions?.invoice?.lots?.total);
  const { data: buyerDocuments, total: totalDocuments } = useSelector(
    (state) => state?.Auctions?.invoice?.buyerDocuments
  );

  const [viewDocumentLoader, setViewDocumentLoader] = useState(false);
  const [printDocumentLoader, setPrintDocumentLoader] = useState(false);
  const [createDocumentLoader, setCreateDocumentLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [lotsLoader, setLotsLoader] = useState(false);
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "lot_no",
    filter: {
      contract_item__is_invoice: false,
    },
  });
  const [documentsLoader, setDocumentsLoader] = useState(false);
  const [docuemntsPayload, setDocumentsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {
      document_lines__auction: id,
    },
  });
  const [selectedPaddle, setSelectedPaddle] = useState(null);
  const [paddleSearchLoader, setPaddleSearchLoader] = useState(false);
  const [paddlesPayload, setPaddlesPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "paddle_no",
    filter: { auction: id },
  });

  // handle create document
  const handleCreateDocument = (documentType) => {
    const paddleNumber = selectedPaddle?.paddle_no
    if (!isAllSelected) {
      history.push({
        pathname: "/invoicing/new",
        state: {
          customer: selectedPaddle?.customer,
          document_type: documentType,
          documentLines: generateDocumentLinesFromLots(selectedRows, auctionData, paddleNumber),
        },
      });
      return;
    } else {
      setCreateDocumentLoader(true);
      dispatch(
        getLotLists({
          page: 1,
          filter: lotsPayload?.filter,
        })
      ).then((res) => {
        history.push({
          pathname: "/invoicing/new",
          state: {
            customer: selectedPaddle?.customer,
            document_type: documentType,
            documentLines: generateDocumentLinesFromLots(res?.data?.data?.results, auctionData, paddleNumber),
          },
        });
      });
    }
  };

  //   handle navigation on invoice
  const navigateToInvoice = (id) => {
    window.open(`/invoicing/edit/${id}`);
  };

  // handle page change
  const handleLotsPageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleLotsRowsPerPageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle page change
  const handleDocumentsPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleDocumentsRowsPerPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle getting lots item
  const handleGetLotLists = useCallback(
    (lotsPayload) => {
      setLotsLoader(true);
      dispatch(getLotLists(lotsPayload)).then((res) => {
        if (res) {
          dispatch({ type: SET_PADDLE_LOTS, payload: res?.data?.data });
        } else {
          //
        }
        setLotsLoader(false);
      });
    },
    [dispatch]
  );

  //   handle getting documents by the contract id
  const handleGetBuyerDocuments = useCallback(
    (docuemntsPayload) => {
      setDocumentsLoader(true);
      dispatch(getBuyerDocuments(docuemntsPayload)).then((res) => {
        if (res) {
          //
        } else {
          //
        }
        setDocumentsLoader(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedPaddle) {
      handleGetLotLists({
        ...lotsPayload,
        filter: { ...lotsPayload?.filter, paddle: selectedPaddle?.id },
      });
    }
  }, [handleGetLotLists, lotsPayload, selectedPaddle]);

  // get list of buyer documents
  useEffect(() => {
    if (selectedPaddle) {
      handleGetBuyerDocuments({
        ...docuemntsPayload,
        filter: {
          ...docuemntsPayload?.filter,
          customer: selectedPaddle?.customer?.id,
        },
      });
    }
  }, [handleGetBuyerDocuments, docuemntsPayload, selectedPaddle]);

  // handle paddle search
  const handlePaddleSearch = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_paddle__icontains: value },
    }));
  };

  // paddle search debounce
  const paddleSearchDebounce = useMemo(() => {
    return debounce(handlePaddleSearch, DEBOUNCE_TIME);
  }, []);

  // to cancel debounces
  useEffect(() => {
    paddleSearchDebounce.cancel();
  }, [paddleSearchDebounce]);

  // handle get paddles
  const handleGetPaddles = useCallback(
    (paddlesPayload) => {
      setPaddleSearchLoader(true);
      dispatch(getPaddles(paddlesPayload))
        .then(() => {
          setPaddleSearchLoader(false);
        })
        .catch(() => {
          setPaddleSearchLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetPaddles(paddlesPayload);
  }, [paddlesPayload, handleGetPaddles]);

  // useEffect to set the paddle from paddles section
  useEffect(() => {
    if (props?.paddle) {
      setSelectedPaddle(props?.paddle);
    }
  }, [props?.paddle]);

  // use effect to filter out lots and buyer documents
  useEffect(() => {
    if (selectedPaddle) {
      setLotsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, paddle: selectedPaddle?.id },
      }));
      setDocumentsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          customer: selectedPaddle?.customer?.id,
        },
      }));
    }
  }, [selectedPaddle]);

  // get item description -> artist, title, description
  const getLotDescription = (row) => {
    let description = [];
    if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
      if (row?.contract_item?.description_pt) description.push(row?.contract_item?.description_pt);
    } else {
      if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
      if (row?.contract_item?.description_en) description.push(row?.contract_item?.description_en);
    }
    return description?.length > 0 ? description.join(", ") : "-";
  };

  const lotsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lotNo")}</div>
        </div>
      ),
      selector: (row) => (row?.lot_no ? row?.lot_no + row?.lot_alpha : "-"),
      width: "20%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
        </div>
      ),
      selector: (row) => getLotDescription(row),
      width: "45%",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.purchasePrice")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.sale_value || 0) + " €",
      width: "25%",
      wrap: true,
    },
  ];

  const buyerDocumentsColumns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.documentNo")}</div>
        </div>
      ),
      selector: (row) =>
        row?.document_no ? (
          <CustomTextLink
            text={row?.document_no}
            handleClick={() => {
              navigateToInvoice(row?.id);
            }}
          />
        ) : (
          "-"
        ),
      width: "20%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.total")}</div>
        </div>
      ),
      selector: (row) => formatAmount(row?.total || 0) + "€",
      width: "30%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.noOfLines")}</div>
        </div>
      ),
      selector: (row) => row?.document_lines?.length || 0,
      width: "25%",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => {
        const pdfUrl =
          getColumnDetail(row?.phc_file_en, row?.phc_file_pt) || getColumnDetail(row?.phc_file_pt, row?.phc_file_en);
        return (
          <div className="table-actions">
            {pdfUrl ? (
              <ActionButton
                disabled={row?.is_cancel}
                iconClasses="ri-printer-fill fs-18"
                onClick={() => openFileURL(pdfUrl, "download")}
                buttonId={`tooltip-${row?.id}-print`}
                tooltip={props.t("common.print")}
              />
            ) : null}
            <ActionButton
              disabled={row?.is_cancel}
              iconClasses="ri-eye-fill fs-18"
              onClick={() => {
                navigateToInvoice(row?.id);
              }}
              buttonId={`tooltip-${row?.id}-view`}
              tooltip={props.t("common.view")}
            />
          </div>
        );
      },
      width: "15%",
      center: true,
    },
  ];

  return (
    <>
      <div className="d-flex flex-row mb-3 gap-2">
        <Row className="w-100">
          <Col className="col-sm-12 col-md-6 col-xl-3">
            <div>
              <Label className="form-label">{props?.t("common.paddleToBeBilled")}</Label>

              <Select
                isLoading={paddleSearchLoader}
                name="auction_lot"
                options={paddleSearchLoader ? [] : PADDLES_OPTIONS}
                getOptionLabel={(option) => `${option?.paddle_no} - ${getOnlyCustomerName(option?.customer)}`}
                getOptionValue={(option) => option?.id}
                className={`custom-select w-100`}
                placeholder={`${props.t("common.select")} ${props.t("common.paddle")}`}
                value={selectedPaddle || null}
                onChange={(paddle) => {
                  setSelectedPaddle(paddle?.id ? paddle : null);
                }}
                onInputChange={paddleSearchDebounce}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {/* lots table */}
        <Col className="col-sm-12 col-md-12 col-xl-6">
          <Card className="subcard-table-container table-light">
            {lotsLoader ? <Loader /> : null}
            <CardHeader className="d-flex flex-row justify-content-between align-items-center">
              <div className="table-title">{props.t("common.lotsToBeBilled")}</div>
              <div className="d-flex flex-row gap-2">
                {(isAllSelected && totalLots > 0) || selectedRows.length ? (
                  <Button
                    color="info"
                    onClick={() => {
                      setSelectedRows([]);
                      setIsAllSelected(false);
                    }}
                  >
                    {props.t("common.deselect")}
                    <Badge color="dark" className="ms-1">
                      {selectedRows.length || totalLots}
                    </Badge>
                  </Button>
                ) : null}
                <CustomButton
                  isLoading={createDocumentLoader}
                  color={"success"}
                  btnTitle={props.t("common.invoice")}
                  disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
                  onClick={() => {
                    handleCreateDocument(INVOICE_DOCUMENT);
                  }}
                />
              </div>
            </CardHeader>
            <DataTable
              className="data-table"
              persistTableHead
              columns={lotsColumns}
              data={selectedPaddle ? [...lotsData] : []}
              pagination
              paginationServer
              paginationTotalRows={totalLots}
              paginationDefaultPage={lotsPayload?.page}
              paginationPerPage={lotsPayload?.limit}
              noDataComponent={<NoRecords />}
              onChangeRowsPerPage={handleLotsRowsPerPageChange}
              onChangePage={handleLotsPageChange}
              paginationComponentOptions={{
                rowsPerPageText: props.t("common.rowsPerPage"),
                rangeSeparatorText: props.t("common.rangeSeparator"),
              }}
            />
          </Card>
        </Col>
        {/* buyer documents table */}
        <Col className="col-sm-12 col-md-12 col-xl-6">
          <Card className="subcard-table-container table-light">
            {documentsLoader ? <Loader /> : null}
            <CardHeader className="d-flex flex-row justify-content-between align-items-center">
              <div className="table-title">{props.t("common.buyerDocuments")}</div>
            </CardHeader>
            <DataTable
              className="data-table"
              persistTableHead
              columns={buyerDocumentsColumns}
              data={selectedPaddle ? [...buyerDocuments] : []}
              pagination
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={docuemntsPayload?.page}
              paginationPerPage={docuemntsPayload?.limit}
              noDataComponent={<NoRecords />}
              onChangeRowsPerPage={handleDocumentsRowsPerPageChange}
              onChangePage={handleDocumentsPageChange}
              paginationComponentOptions={{
                rowsPerPageText: props.t("common.rowsPerPage"),
                rangeSeparatorText: props.t("common.rangeSeparator"),
              }}
              conditionalRowStyles={[
                {
                  when: (row) => row?.is_cancel,
                  classNames: ["canceled-table-cell"],
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(Invoice);
