import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_MY_VERITAS_DATA } from "./actionTypes";

const handleFormDataAppend = (payload) => {
  const formData = new FormData();

  payload.forEach((obj, index) => {
    formData.append(`file-${index}`, obj.file);
    formData.append(`file_type-${index}`, obj.file_type);
    formData.append(`language_type-${index}`, obj.language_type);
    formData.append(`save_type-${index}`, obj.save_type);
  });

  return formData;
};

// to set the redux state for my veritas
const handleGetWebsiteDataLanguageWise = (websiteData) => {
  const data = { en: {}, pt: {} };
  websiteData?.forEach((item) => {
    data[item?.language_type][item?.file_type] = item?.file;
  });

  return data;
};

// to get the my-veritas page data
export const getMyVeritasImages = (params) => async (dispatch) => {
  try {
    return API.get(`/website-management/data`, { params: { ...params } }).then((res) => {
      if (res && res.status && res.status === 200) {
        const data = handleGetWebsiteDataLanguageWise(res?.data?.data?.results);

        dispatch({
          type: SET_MY_VERITAS_DATA,
          payload: {
            login_image_en: data?.en?.login_image,
            login_image_pt: data?.pt?.login_image,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to upload myveritas images
export const uploadMyVertiasImages = (payload) => async (dispatch) => {
  try {
    const formData = handleFormDataAppend(payload);
    return API.post(`/website-management/data`, formData).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        const data = handleGetWebsiteDataLanguageWise(res?.data?.data);

        dispatch({
          type: SET_MY_VERITAS_DATA,
          payload: {
            login_image_en: data?.en?.login_image,
            login_image_pt: data?.pt?.login_image,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
