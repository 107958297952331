import {
  SET_CONTRACT_ITEMS_PAYLOADS,
  SET_CONTRACT_ITEMS_SCROLL,
  SET_PRECONTRACT_ITEMS_PAYLOADS,
  SET_PRECONTRACT_ITEMS_SCROLL,
} from "../actionTypes";

const INIT_STATE = {
  precontractItems: null,
  precontractItemsScroll: 0,
  contractItems: null,
  contractItemsScroll: 0,
};

const Payloads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PRECONTRACT_ITEMS_PAYLOADS:
      return {
        ...state,
        precontractItems: action.payload,
      };
    case SET_CONTRACT_ITEMS_PAYLOADS:
      return {
        ...state,
        contractItems: action.payload,
      };
    case SET_PRECONTRACT_ITEMS_SCROLL:
      return {
        ...state,
        precontractItemsScroll: action.payload,
      };
    case SET_CONTRACT_ITEMS_SCROLL:
      return {
        ...state,
        contractItemsScroll: action.payload,
      };
    default:
      return state;
  }
};

export default Payloads;
