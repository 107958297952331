import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import { ADD_CATEGORY, SET_CATEGORIES_DATA, UPDATE_CATEGORY } from "./actionTypes";

export const getCategories = (params) => async (dispatch) => {
  try {
    return API.get(`/lots/categories`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_CATEGORIES_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add category
export const addCategory = (payload) => async (dispatch) => {
  try {
    return API.post(`/lots/categories`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_CATEGORY,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update category
export const updateCategory = (categoryId, payload) => async (dispatch) => {
  try {
    return API.put(`/lots/categories/${categoryId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: UPDATE_CATEGORY,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.detail);
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete category
export const deleteCategory =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/lots/categories/${id}`, {
        data: { ...payload },
      }).then((res) => {
        return res;
      });
    } catch (error) {
      console.log(error);
    }
  };
