import i18next from "i18next";
import React from "react";
import { Card } from "reactstrap";

const NoRecords = ({ message = "" }) => {
  return (
    <Card className="table-no-records-message">
      <h4>{message || i18next.t("common.noRecords")}</h4>
    </Card>
  );
};

export default NoRecords;
