import React from "react";
import PhoneInput from "react-phone-input-2";

const PhoneNumberInput = ({ isValid, ...others }) => {
  return (
    <PhoneInput
      enableSearch
      country={"pt"}
      searchPlaceholder="search country"
      inputClass={`phone-number-input ${isValid ? "" : "phone-number-error"}`}
      buttonClass={`test ${isValid ? "" : "phone-number-error"}`}
      {...others}
    />
  );
};

export default PhoneNumberInput;
