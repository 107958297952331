import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import { deleteBid, getBids, updateBid } from "../../../store/auctions/bidsActions";
import CustomSearchInput from "../../common/CustomSearchInput";
import NoRecords from "../../common/NoRecords";
import { BIDS_STATUSES, SELECT_ALL_OPTION, TYPE_OF_BID_OPTIONS } from "../../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  getOnlyCustomerName,
  getTypeFromValue,
  getValuesFromArray,
} from "../../../helpers/utils";
import Loader from "../../common/Loader";
import { useParams } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import ActionButton from "../../common/ActionButton";
import { getLotLists } from "../../../store/auctions/lotActions";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";

const BidsView = ({ bidsViewRef, setBidsViewPayload, isLoading, editBid, lotNo, setLotNoToView, ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const { data: bidsData, total: totalBids } = useSelector((state) => state?.Auctions?.bids?.bidsView);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);
  const [selectedBidForStatus, setSelectedBidForStatus] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [selectedBidTypeOption, setSelectedBidTypeOption] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [bidsPayload, setBidsPayload] = useState({
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "bid_no",
    filter: { auction: id },
  });
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: 10,
    sort: "lot_no",
    filter: { auction: id },
  });

  // to view the particulat lots bids
  useEffect(() => {
    if (lotNo) {
      setBidsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, search_lot_no__icontains: lotNo },
        page: 1,
      }));
    }
    setLotNoToView("");
  }, [lotNo]);

  // to handle delete modal open
  const handleDeleteModalOpen = (paddleData) => {
    setSelectedBid(paddleData);
    setIsDeleteModalOpen(true);
  };
  // to handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedBid(null);
    setIsDeleteModalOpen(false);
  };
  // handle delete bid
  const handleDeleteBid = () => {
    if (selectedBid && selectedBid?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteBid({
          filter: {},
          is_selected_all: false,
          ids: [selectedBid?.id],
        })
      ).then((res) => {
        if (res) {
          handleDeleteModalClose();
          handleGetBids(bidsPayload);
          handleGetLots();
        } else {
          //
        }
        setDeleteLoader(false);
      });
    }
  };

  // handle get lots
  const handleGetLots = () => {
    setTableLoader(true);
    dispatch(getLotLists(lotsPayload))
      .then((res) => {
        setTableLoader(false);
      })
      .catch((error) => {
        setTableLoader(false);
        console.log(error);
      });
  };
  // handle status update
  const handleBidStatusUpdate = (data, status) => {
    setStatusLoader(true);
    dispatch(
      updateBid(data?.id, {
        auction: id,
        auction_lot: data?.auction_lot?.id,
        customer: data?.customer?.id,
        bidding_type: data?.bidding_type,
        status: status?.value,
      })
    ).then((res) => {
      handleGetBids(bidsPayload);
      handleGetLots();
      setStatusLoader(false);
      setSelectedBidForStatus(null);
    });
  };

  // handle page change
  const handlePageChange = (value) => {
    setBidsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setBidsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get bids
  const handleGetBids = useCallback(
    (bidsPayload) => {
      setTableLoader(true);
      dispatch(getBids(bidsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetBids(bidsPayload);
  }, [bidsPayload, handleGetBids]);

  // to set the bids payload in parent
  useEffect(() => {
    setBidsViewPayload(bidsPayload);
  }, [bidsPayload, setBidsViewPayload]);

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setBidsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setBidsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setBidsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.bidNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_bid_no__icontains"
            className="column-search-input"
            payload={bidsPayload}
            setPayload={setBidsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.bid_no ? row?.bid_no : "-"),
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={bidsPayload}
            setPayload={setBidsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.id ? getOnlyCustomerName(row?.customer) : "-"),
      width: "280px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lotNo")}</div>
          <CustomSearchInput
            initialValue={lotNo}
            columnWise={true}
            columnSearchKey="search_lot_no__icontains"
            className="column-search-input"
            payload={bidsPayload}
            setPayload={setBidsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.auction_lot?.lot_no ? row?.auction_lot?.lot_no + row?.auction_lot?.lot_alpha : "-"),
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.paddle")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="customer__paddles__paddle_no__icontains"
            className="column-search-input"
            payload={bidsPayload}
            setPayload={setBidsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.paddle_no ? row?.customer?.paddle_no : "-"),
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.typeOfBidding")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...TYPE_OF_BID_OPTIONS]}
            selectedOptions={selectedBidTypeOption}
            handleOnSelection={(values) => {
              setSelectedBidTypeOption(values);
              handleDropdownFilter(values, "bidding_type__in", setSelectedBidTypeOption);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.bidding_type ? getTypeFromValue(TYPE_OF_BID_OPTIONS, row?.bidding_type)?.label : "-"),
      width: "194px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.languagePreference")}</div>
        </div>
      ),
      selector: (row) => (row?.languages_speaks?.length > 0 ? row?.languages_speaks?.[0]?.description : "-"),
      width: "194px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.status")}</div>
        </div>
      ),
      selector: (row) => {
        const status = BIDS_STATUSES.find((status) => status?.value === row?.status);
        const otherStatuses = BIDS_STATUSES.filter((status) => status?.value !== row?.status);
        return row?.status ? (
          row.status === "draft" ? (
            props.t("common.draft")
          ) : (
            <CustomDropdownButton
              btnTitle={status?.label}
              buttonBGColor={status?.color}
              isLoading={statusLoader && selectedBidForStatus?.id === row?.id}
              options={otherStatuses?.map((status) => ({
                label: status?.label,
                onClick: () => {
                  setSelectedBidForStatus(row);
                  handleBidStatusUpdate(row, status);
                },
              }))}
            />
          )
        ) : (
          "-"
        );
        // return row?.status ? (
        //   <CustomDropdownButton
        //     btnTitle={status?.label}
        //     buttonBGColor={status?.color}
        //     isLoading={statusLoader && selectedBidForStatus?.id === row?.id}
        //     options={otherStatuses?.map((status) => ({
        //       label: status?.label,
        //       onClick: () => {
        //         setSelectedBidForStatus(row);
        //         handleBidStatusUpdate(row, status);
        //       },
        //     }))}
        //   />
        // ) : (
        //   "-"
        // );
      },
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {auctionData?.auction_type?.auction_type !== "Online" ? (
            <>
              <ActionButton
                iconClasses="ri-file-copy-fill fs-18"
                buttonId={`tooltip-${row?.id}-copy`}
                tooltip={props.t("common.copy")}
                onClick={() => {
                  editBid(row, true);
                }}
              />
              <ActionButton
                iconClasses="ri-pencil-fill fs-18"
                buttonId={`tooltip-${row?.id}-edit`}
                tooltip={props.t("common.edit")}
                onClick={() => {
                  editBid(row);
                }}
              />
            </>
          ) : null}
          <ActionButton
            iconClasses="ri-delete-bin-fill fs-18"
            buttonId={`tooltip-${row?.id}-delete`}
            tooltip={props.t("common.delete")}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          />
        </div>
      ),
      width: "164px",
      center: true,
    },
  ];

  return (
    <div ref={bidsViewRef}>
      <Card className="subcard-table-container table-light">
        {tableLoader || isLoading ? <Loader /> : null}
        <CardHeader className="d-flex flex-row justify-content-between align-items-center">
          <div className="table-title">{props.t("common.bidsView")}</div>
        </CardHeader>
        <DataTable
          className="data-table bids-table"
          fixedHeader
          persistTableHead
          columns={columns}
          data={[...bidsData]}
          pagination
          paginationServer
          paginationDefaultPage={bidsPayload?.page}
          paginationTotalRows={totalBids}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationPerPage={DEFAULT_ROW_PER_PAGE}
          paginationComponentOptions={{
            rowsPerPageText: props.t("common.rowsPerPage"),
            rangeSeparatorText: props.t("common.rangeSeparator"),
          }}
        />
      </Card>
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteBid}
        title={`${props.t("common.delete")} ${props.t("common.bid")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.bid"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </div>
  );
};

export default withTranslation()(BidsView);
