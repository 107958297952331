import { ADD_LANGUAGE, DELETE_LANGUAGE, EDIT_LANGUAGE, SET_LANGUAGELIST } from "./actionType";

const INIT_STATE = {
  totalLanguages: 0,
  languageList: [],
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGELIST:
      return {
        ...state,
        languageList: action.payload.languageList,
        totalLanguages: action.payload.totalLanguages,
      };
    case DELETE_LANGUAGE:
      return {
        ...state,
        languageList: state.languageList.filter((languageData) => languageData.id !== action.payload.deletedId),
        totalLanguages: state.totalLanguages - 1,
      };
    case ADD_LANGUAGE:
      return {
        ...state,
        languageList: [action.payload.data, ...state.languageList],
        totalLanguages: state.totalLanguages + 1,
      };
    case EDIT_LANGUAGE:
      const existingIndex = state.languageList.findIndex((language) => language.id === action.payload.data.id);
      const updatedLanguageData = state.languageList;
      if (existingIndex !== -1) {
        updatedLanguageData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        languageList: [...updatedLanguageData],
      };
    default:
      return state;
  }
};

export default User;
