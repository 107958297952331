import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { CardBody, CardFooter, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import CustomButton from "../../../common/CustomButton";
import CustomLinkWrapper from "../../../common/CustomLinkWrapper";
import { checkPDFFileValidation, createFileUrl, extractNameFromUrl } from "../../../../helpers/utils";
import { TYPE_PUBLISH, TYPE_SAVE } from "../../../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteDocuments } from "../../../../store/files/actions";
import Loader from "../../../common/Loader";
import { toast } from "react-toastify";

const Documents = (props) => {
  const dispatch = useDispatch();
  const validation = props?.validation;

  const [loader, setLoader] = useState(false);
  const [payload, setPayload] = useState({
    filter: {
      page_type: "document",
      save_type: "save",
    },
  });

  // to get the my-veritas images
  const handleGetWebsiteDocumentsData = useCallback(
    (payload) => {
      setLoader(true);
      dispatch(getWebsiteDocuments(payload)).then(() => {
        setLoader(false);
      });
    },
    [dispatch]
  );

  // useEffect to get the images
  useEffect(() => {
    handleGetWebsiteDocumentsData(payload);
  }, [payload, handleGetWebsiteDocumentsData]);

  // to handle save only
  const handleSave = () => {
    validation?.setErrors({});
    props?.handleSaveAndPublish(validation?.values, TYPE_SAVE);
  };

  // to reset form
  const handleReset = () => {
    validation.resetForm();
  };

  // handle upload document
  const handleUpload = (e, key) => {
    if (checkPDFFileValidation(e.target.files[0]?.name)) {
      validation.setFieldValue(key, e.target.files[0]);
    } else {
      toast.error(props?.t("validation.uploadFileFormat"));
    }
  };

  // clear uploaded document
  const clearUploadedDocument = (key) => {
    validation.setFieldValue(key, "");
  };

  return (
    <Form
      className="position-relative"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {loader ? <Loader /> : null}
      <CardBody>
        <Row>
          <Col>
            <div className="mb-3">
              <Label htmlFor="terms_conditions_pt" className="form-label">
                {`${props.t("common.termsAndConditions")} ${props.t("common.pt")}`}
              </Label>
              {validation?.values?.terms_conditions_pt ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.terms_conditions_pt === "string"
                        ? validation.values.terms_conditions_pt
                        : createFileUrl(validation.values.terms_conditions_pt)
                    }
                    title={
                      typeof validation.values.terms_conditions_pt === "string"
                        ? extractNameFromUrl(validation.values.terms_conditions_pt, "/", 5)
                        : validation.values.terms_conditions_pt.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("terms_conditions_pt");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="terms_conditions_pt"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "terms_conditions_pt");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.terms_conditions_pt || ""}
                    invalid={
                      validation.touched.terms_conditions_pt && validation.errors.terms_conditions_pt ? true : false
                    }
                  />
                  {validation.touched.terms_conditions_pt && validation.errors.terms_conditions_pt ? (
                    <FormFeedback type="invalid">{validation.errors.terms_conditions_pt}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label htmlFor="terms_conditions_en" className="form-label">
                {`${props.t("common.termsAndConditions")} ${props.t("common.en")}`}
              </Label>
              {validation?.values?.terms_conditions_en ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.terms_conditions_en === "string"
                        ? validation.values.terms_conditions_en
                        : createFileUrl(validation.values.terms_conditions_en)
                    }
                    title={
                      typeof validation.values.terms_conditions_en === "string"
                        ? extractNameFromUrl(validation.values.terms_conditions_en, "/", 5)
                        : validation.values.terms_conditions_en.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("terms_conditions_en");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="terms_conditions_en"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "terms_conditions_en");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.terms_conditions_en || ""}
                    invalid={
                      validation.touched.terms_conditions_en && validation.errors.terms_conditions_en ? true : false
                    }
                  />
                  {validation.touched.terms_conditions_en && validation.errors.terms_conditions_en ? (
                    <FormFeedback type="invalid">{validation.errors.terms_conditions_en}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label htmlFor="privacy_policy_pt" className="form-label">
                {`${props.t("common.privacyPolicy")} ${props.t("common.pt")}`}
              </Label>
              {validation.values.privacy_policy_pt ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.privacy_policy_pt === "string"
                        ? validation.values.privacy_policy_pt
                        : createFileUrl(validation.values.privacy_policy_pt)
                    }
                    title={
                      typeof validation.values.privacy_policy_pt === "string"
                        ? extractNameFromUrl(validation.values.privacy_policy_pt, "/", 5)
                        : validation.values.privacy_policy_pt.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("privacy_policy_pt");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="privacy_policy_pt"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "privacy_policy_pt");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.privacy_policy_pt || ""}
                    invalid={validation.errors.privacy_policy_pt && validation.touched.privacy_policy_pt ? true : false}
                  />
                  {validation.errors.privacy_policy_pt && validation.touched.privacy_policy_pt ? (
                    <FormFeedback type="invalid">{validation.errors.privacy_policy_pt}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label htmlFor="privacy_policy_en" className="form-label">
                {`${props.t("common.privacyPolicy")} ${props.t("common.en")}`}
              </Label>
              {validation.values.privacy_policy_en ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.privacy_policy_en === "string"
                        ? validation.values.privacy_policy_en
                        : createFileUrl(validation.values.privacy_policy_en)
                    }
                    title={
                      typeof validation.values.privacy_policy_en === "string"
                        ? extractNameFromUrl(validation.values.privacy_policy_en, "/", 5)
                        : validation.values.privacy_policy_en.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("privacy_policy_en");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="privacy_policy_en"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "privacy_policy_en");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.privacy_policy_en || ""}
                    invalid={validation.touched.privacy_policy_en && validation.errors.privacy_policy_en ? true : false}
                  />
                  {validation.touched.privacy_policy_en && validation.errors.privacy_policy_en ? (
                    <FormFeedback type="invalid">{validation.errors.privacy_policy_en}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label htmlFor="form_to_leave_bid_pt" className="form-label">
                {`${props.t("common.formToLeaveBid")} ${props.t("common.pt")}`}
              </Label>
              {validation.values.form_to_leave_bid_pt ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.form_to_leave_bid_pt === "string"
                        ? validation.values.form_to_leave_bid_pt
                        : createFileUrl(validation.values.form_to_leave_bid_pt)
                    }
                    title={
                      typeof validation.values.form_to_leave_bid_pt === "string"
                        ? extractNameFromUrl(validation.values.form_to_leave_bid_pt, "/", 5)
                        : validation.values.form_to_leave_bid_pt.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("form_to_leave_bid_pt");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="form_to_leave_bid_pt"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "form_to_leave_bid_pt");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.form_to_leave_bid_pt || ""}
                    invalid={
                      validation.touched.form_to_leave_bid_pt && validation.errors.form_to_leave_bid_pt ? true : false
                    }
                  />
                  {validation.touched.form_to_leave_bid_pt && validation.errors.form_to_leave_bid_pt ? (
                    <FormFeedback type="invalid">{validation.errors.form_to_leave_bid_pt}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label htmlFor="form_to_leave_bid_en" className="form-label">
                {`${props.t("common.formToLeaveBid")} ${props.t("common.en")}`}
              </Label>
              {validation.values.form_to_leave_bid_en ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.form_to_leave_bid_en === "string"
                        ? validation.values.form_to_leave_bid_en
                        : createFileUrl(validation.values.form_to_leave_bid_en)
                    }
                    title={
                      typeof validation.values.form_to_leave_bid_en === "string"
                        ? extractNameFromUrl(validation.values.form_to_leave_bid_en, "/", 5)
                        : validation.values.form_to_leave_bid_en.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("form_to_leave_bid_en");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="form_to_leave_bid_en"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "form_to_leave_bid_en");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.form_to_leave_bid_en || ""}
                    invalid={
                      validation.touched.form_to_leave_bid_en && validation.errors.form_to_leave_bid_en ? true : false
                    }
                  />
                  {validation.touched.form_to_leave_bid_en && validation.errors.form_to_leave_bid_en ? (
                    <FormFeedback type="invalid">{validation.errors.form_to_leave_bid_en}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label htmlFor="catalogues_subscription_pt" className="form-label">
                {`${props.t("common.cataloguesSubscription")} ${props.t("common.pt")}`}
              </Label>
              {validation.values.catalogues_subscription_pt ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.catalogues_subscription_pt === "string"
                        ? validation.values.catalogues_subscription_pt
                        : createFileUrl(validation.values.catalogues_subscription_pt)
                    }
                    title={
                      typeof validation.values.catalogues_subscription_pt === "string"
                        ? extractNameFromUrl(validation.values.catalogues_subscription_pt, "/", 5)
                        : validation.values.catalogues_subscription_pt.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("catalogues_subscription_pt");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="catalogues_subscription_pt"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "catalogues_subscription_pt");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.catalogues_subscription_pt || ""}
                    invalid={
                      validation.touched.catalogues_subscription_pt && validation.errors.catalogues_subscription_pt
                        ? true
                        : false
                    }
                  />
                  {validation.touched.catalogues_subscription_pt && validation.errors.catalogues_subscription_pt ? (
                    <FormFeedback type="invalid">{validation.errors.catalogues_subscription_pt}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label htmlFor="catalogues_subscription_en" className="form-label">
                {`${props.t("common.cataloguesSubscription")} ${props.t("common.en")}`}
              </Label>
              {validation.values.catalogues_subscription_en ? (
                <div className="uploaded-doc-preview d-flex flex-row justify-content-between align-items-center fs-14 text-break">
                  <CustomLinkWrapper
                    link={
                      typeof validation.values.catalogues_subscription_en === "string"
                        ? validation.values.catalogues_subscription_en
                        : createFileUrl(validation.values.catalogues_subscription_en)
                    }
                    title={
                      typeof validation.values.catalogues_subscription_en === "string"
                        ? extractNameFromUrl(validation.values.catalogues_subscription_en, "/", 5)
                        : validation.values.catalogues_subscription_en.name
                    }
                  />
                  <i
                    className="ri-close-fill fs-18 cursor-pointer text-danger"
                    onClick={() => {
                      clearUploadedDocument("catalogues_subscription_en");
                    }}
                  />
                </div>
              ) : (
                <>
                  <Input
                    name="catalogues_subscription_en"
                    className="form-control"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleUpload(e, "catalogues_subscription_en");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.catalogues_subscription_en || ""}
                    invalid={
                      validation.touched.catalogues_subscription_en && validation.errors.catalogues_subscription_en
                        ? true
                        : false
                    }
                  />
                  {validation.touched.catalogues_subscription_en && validation.errors.catalogues_subscription_en ? (
                    <FormFeedback type="invalid">{validation.errors.catalogues_subscription_en}</FormFeedback>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-row gap-2 justify-content-end">
          <CustomButton
            disabled={props?.publishLoader}
            isLoading={props?.saveLoader}
            type="button"
            // onClick={handleSave}
            onClick={() => {
              props.setSubmitType(TYPE_SAVE);
              validation.handleSubmit();
            }}
            btnTitle={props.t("common.save")}
          />
          <CustomButton
            disabled={props?.saveLoader}
            isLoading={props?.publishLoader}
            btnTitle={props.t("common.save&publish")}
            onClick={() => {
              props.setSubmitType(TYPE_PUBLISH);
              validation.handleSubmit();
            }}
          />
          <CustomButton
            disabled={props?.saveLoader || props?.publishLoader}
            type="button"
            btnTitle={props.t("common.reset")}
            color="dark"
            onClick={handleReset}
          />
        </div>
      </CardFooter>
    </Form>
  );
};

export default withTranslation()(Documents);
