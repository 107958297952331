import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { currencyFormatter } from "../../helpers/utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({
  xAxisLabel,
  yAxisLabel,
  labels,
  data,
  currencyFormatY,
  barBgColor = "rgba(75,56,179,.18)",
  barThickness = 20,
  borderWidth = 2,
  borderRadius = 4,
  borderColor = "rgba(75,56,179,.6)",
  aspectRatio = "auto",
}) => {
  return (
    <Bar
      options={{
        responsive: true,
        aspectRatio,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.parsed.y;
                if (context.parsed.y !== null && currencyFormatY) {
                  label = currencyFormatter(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: xAxisLabel,
              font: {
                size: 20,
                weight: "600",
              },
              color: "rgba(75,56,179)",
            },
          },
          y: {
            ticks: {
              display: true,
              callback: function (value, index, ticks) {
                return currencyFormatY ? currencyFormatter(value) : value;
              },
            },
            title: {
              display: true,
              text: yAxisLabel,
              font: {
                size: 20,
                weight: "600",
              },
              color: "rgba(75,56,179)",
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: barBgColor,
            borderRadius: borderRadius,
            borderWidth: borderWidth,
            borderColor: borderColor,
            barThickness: barThickness,
          },
        ],
      }}
    />
  );
};

export default BarGraph;
