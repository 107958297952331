import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import NumberInput from "../../common/NumberInput";
import { TITLE_TYPE_OPTIONS } from "../../../helpers/constants/selectOptions";
import { getTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";

const AddTitleModal = ({ title, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      title_pt: title ? title?.title_pt : "",
      title_en: title ? title?.title_en : "",
      title_type: title ? title?.title_type : "",
    },
    validationSchema: Yup.object({
      title_pt: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.title"),
        })}`
      ),
      title_en: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.title"),
        })}`
      ),
      title_type: Yup.string().required(
        `${t("validation.generalField", {
          promptType: t("common.select"),
          fieldType: t("common.type"),
        })}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });

  // reset form
  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {title?.id
          ? t("common.editTitle", { module: t("common.title") })
          : t("common.addTitle", { module: t("common.title") })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="title_pt" className="form-label">
              {`${t("common.title")} ${t("common.pt")}`}
            </Label>
            <Input
              name="title_pt"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.title"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_pt || ""}
              invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
            />
            {validation.touched.title_pt && validation.errors.title_pt ? (
              <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="title_en" className="form-label">
              {`${t("common.title")} ${t("common.en")}`}
            </Label>
            <Input
              name="title_en"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.title"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title_en || ""}
              invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
            />
            {validation.touched.title_en && validation.errors.title_en ? (
              <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">{t("common.type")}</Label>
            <Select
              name="title_type"
              options={TITLE_TYPE_OPTIONS}
              className={`custom-select ${
                validation.touched.title_type && validation.errors.title_type ? "select-error" : ""
              }`}
              placeholder={`${t("placeholder.selectField", {
                fieldType: t("common.type"),
              })}`}
              value={getTypeFromValue(TITLE_TYPE_OPTIONS, validation.values.title_type) || ""}
              onChange={(title_type) => {
                validation.setFieldValue("title_type", title_type?.value);
              }}
              onBlur={(e) => {
                validation.setFieldTouched("title_type", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.title_type && validation.errors.title_type ? (
              <p className="custom-invalid-feedback">{validation.errors.title_type}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddTitleModal);
