import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { Badge, Button, CardBody, CardHeader, Input, Label } from "reactstrap";
import CustomSearchInput from "../../common/CustomSearchInput";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import MessageBlock from "../../common/MessageBlock";
import NoRecords from "../../common/NoRecords";
import CustomTagChip from "../../../components/common/CustomTagChip";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  formatAmount,
  generateContrastColor,
  getColumnDetail,
  getItemCol,
  getObjectsByValues,
  getValuesFromArray,
  openFileURL,
} from "../../../helpers/utils";
import DeleteModal from "../../common/DeleteModal";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomButton from "../../common/CustomButton";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePrecontractItem,
  exportPrecontract,
  getPrecontractItems,
  sendItemsToContract,
} from "../../../store/pre-contracts/actions";
import Loader from "../../common/Loader";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import {
  PRECONTRACT_ITEMS_SENT_OPTIONS,
  PRECONTRACT_ITEMS_TAG_OPTIONS,
  SELECT_ALL_OPTION,
  YES_NO_OPTIONS,
} from "../../../helpers/constants/selectOptions";
import SendToContractModal from "./SendToContractModal";
import ActionButton from "../../../components/common/ActionButton";
import { toast } from "react-toastify";
import { ADMIN, COMUNICATIONS, DEFAULT_ROW_PER_PAGE, GENERAL } from "../../../helpers/constants";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomColumnPhoto from "../../common/CustomColumnPhoto";
import ImportExcelModal from "./ImportExcelModal";
import { uploadExcel } from "../../../store/contracts/actions";
import { SET_PRECONTRACT_ITEMS_PAYLOADS, SET_PRECONTRACT_ITEMS_SCROLL } from "../../../store/actionTypes";

const PreContractItems = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { precontract, precontractItems, totalItems, suggested_pre_contract_no } = useSelector(
    (state) => state?.PreContracts
  );
  const savedItemsPayload = useSelector((state) => state?.Payloads?.precontractItems);
  const savedItemsScroll = useSelector((state) => state?.Payloads?.precontractItemsScroll);

  const preContractItemsRef = useRef(savedItemsPayload?.filter?.pre_contract === id ? savedItemsScroll : 0);
  const precontractItemsPayload = savedItemsPayload?.filter?.pre_contract === id ? savedItemsPayload : null;

  const [itemsPayload, setItemsPayload] = useState(
    precontractItemsPayload || {
      search: "",
      page: 1,
      limit: DEFAULT_ROW_PER_PAGE,
      sort: "position_pre_contract",
      filter: {
        pre_contract: id,
      },
    }
  );
  const [tableLoader, setTableLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSentOptions, setSelectedSentOptions] = useState(
    precontractItemsPayload?.filter?.is_sent__in?.length > 0
      ? getObjectsByValues(PRECONTRACT_ITEMS_SENT_OPTIONS, precontractItemsPayload?.filter?.is_sent__in, "value")
      : []
  );
  const [selectedValueAgreedOptions, setSelectedValueAgreedOptions] = useState(
    precontractItemsPayload?.filter?.value_to_be_agreed__in?.length > 0
      ? getObjectsByValues(YES_NO_OPTIONS, precontractItemsPayload?.filter?.value_to_be_agreed__in, "value")
      : []
  );
  const [selectedTagOptions, setSelectedTagOptions] = useState(
    precontractItemsPayload?.filter?.described__in?.length > 0
      ? getObjectsByValues(PRECONTRACT_ITEMS_TAG_OPTIONS, precontractItemsPayload?.filter?.described__in, "value")
      : []
  );
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [sendMailLoader, setSendMailLoader] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSendToContractModalOpen, setIsSendToContractModalOpen] = useState(false);
  const [sendToNewContractLoader, setSendToNewContractLoader] = useState(false);
  const [sendToExistingContractLoader, setSendToExistingContractLoader] = useState(false);
  const [isImportExcelModalOpen, setIsExcelModalOpen] = useState(false);
  const [importExcelLoader, setImportExcelLoader] = useState(false);

  // handle open mail app
  const handleOpenMailApp = () => {
    if (precontract?.customer?.id) {
      window.open(`mailto:${precontract?.customer?.email}`);
    } else {
      toast.warning(props.t("common.please") + " " + props.t("common.select") + " " + props.t("customer"));
    }
  };

  // navigate to pre-contract item form
  const navigateOnPrecontractItemForm = (itemId) => {
    if (itemId) {
      history.push(`/contracts/pre-contracts/${id}/items/edit/${itemId}`);
    } else {
      history.push(`/contracts/pre-contracts/${id}/items/new`, {
        lastPosition: suggested_pre_contract_no,
      });
    }
  };

  // naivgate on pre-contract item form with pre-filled data of another item
  const navigateOnPrecontractItemFormForCopy = (itemId) => {
    if (itemId) {
      history.push(`/contracts/pre-contracts/${id}/items/new`, {
        itemIdToCopy: itemId,
        lastPosition: suggested_pre_contract_no,
      });
    }
  };

  // navigate on contract after successfull sending the items.
  const navigateOnContract = (id) => {
    history.push(`/contracts/contracts/edit/${id}`);
  };

  // handle send to contract modal open
  const handleSendToContractModalOpen = () => {
    setIsSendToContractModalOpen(true);
  };

  // handle send to contract modal close
  const handleSendToContractModalClose = () => {
    setIsSendToContractModalOpen(false);
  };
  // handle send items to contract
  const handleSendItemsToContract = (value) => {
    let payload = {
      is_selected_all: isAllSelected,
      ...value,
      ids: selectedRows,
      filter: itemsPayload?.filter,
      is_pre_contract_export: true,
    };
    if (id) {
      if (!value) {
        setSendToNewContractLoader(true);
      } else {
        setSendToExistingContractLoader(true);
      }
      dispatch(sendItemsToContract(id, payload)).then((res) => {
        if (res) {
          navigateOnContract(res?.contract_id);
        } else {
          handleSendToContractModalClose();
        }
        setSendToNewContractLoader(false);
        setSendToExistingContractLoader(false);
      });
    }
  };

  // to handle modal open for import excel
  const handleImportExcelModalOpen = () => {
    if (precontract?.customer?.id) {
      setIsExcelModalOpen(true);
    } else {
      toast.warn(props.t("information.contractImport"));
    }
  };
  // to handle modal close for import excel
  const handleImportExcelModalClose = () => {
    setIsExcelModalOpen(false);
  };

  // to handle Add excel
  const handleAddExcel = (values) => {
    setImportExcelLoader(true);
    dispatch(uploadExcel({ id, payload: values }, "pre-contract")).then((res) => {
      if (res) {
        handleGetPrecontractItems(itemsPayload);
        setImportExcelLoader(false);
        handleImportExcelModalClose();
      } else {
        setImportExcelLoader(false);
      }
    });
  };

  //   handle delete modal open
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  //   handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };
  //   handle delete item
  const handleDeleteItem = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deletePrecontractItem({
          filter: {},
          isSelectedAll: false,
          ids: [selectedItem?.id],
        })
      ).then((res) => {
        if (res) {
          handleDeleteModalClose();
          handleGetPrecontractItems(itemsPayload);
          setSelectedRows((prevState) => [...prevState.filter((item) => item !== selectedItem?.id)]);
        }
        setDeleteLoader(false);
      });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    scrollItemsToCords(0);
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    scrollItemsToCords(0);
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle pre-contract item filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setItemsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setItemsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setItemsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // to handle the pdf exporting
  const handleExportPDF = (sendMail, isEnglish) => {
    const payload = {
      is_mail: sendMail,
      is_english: isEnglish,
      ids: selectedRows,
      filter: itemsPayload?.filter,
    };
    if (sendMail) {
      setSendMailLoader(true);
    } else {
      setExportBtnLoader(true);
    }
    if (id) {
      dispatch(exportPrecontract(id, payload)).then((res) => {
        if (res) {
          if (!sendMail) {
            openFileURL(res, "download");
          }
          handleGetPrecontractItems(itemsPayload);
        }
        setSendMailLoader(false);
        setExportBtnLoader(false);
      });
    }
  };

  //   handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  //   handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsBulkDeleteModalOpen(false);
  };

  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    setBulkDeleteLoader(true);
    dispatch(
      deletePrecontractItem({
        isSelectedAll: isAllSelected,
        ids: selectedRows,
        filter: itemsPayload?.filter,
      })
    ).then((res) => {
      if (res) {
        handleBulkDeleteModalClose();
        handleGetPrecontractItems(itemsPayload);
        setIsAllSelected(false);
        setSelectedRows([]);
      }
      setBulkDeleteLoader(false);
    });
  };

  // handle getting pre-contract items
  const handleGetPrecontractItems = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getPrecontractItems(itemsPayload)).then((res) => {
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  // to get the pre-contract items
  useEffect(() => {
    handleGetPrecontractItems(itemsPayload);
  }, [handleGetPrecontractItems, itemsPayload]);

  const scrollItemsToCords = (y) => {
    document.querySelector(".pre-contract-items-table")?.scroll(0, y);
  };

  // stored the items payload in redux
  useEffect(() => {
    dispatch({ type: SET_PRECONTRACT_ITEMS_PAYLOADS, payload: itemsPayload });
  }, [dispatch, itemsPayload]);

  useEffect(() => {
    scrollItemsToCords(preContractItemsRef?.current);
    document.querySelector(".pre-contract-items-table")?.addEventListener("scroll", (e) => {
      preContractItemsRef.current = e.target.scrollTop;
    });
    return () => {
      dispatch({
        type: SET_PRECONTRACT_ITEMS_SCROLL,
        payload: preContractItemsRef?.current,
      });
    };
  }, []);

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              if (selectedRows?.find((item) => item === row?.id)) {
                setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
              } else {
                setSelectedRows((prevState) => [...prevState, row?.id]);
              }
            }}
          />
        </Label>
      ),
      width: "68px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="position_pre_contract__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.position_pre_contract__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.position_pre_contract ? row?.position_pre_contract : "-"),
      width: "112px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper
          link={row?.appraisal_photos?.[0]}
          title={<CustomColumnPhoto sourceUrl={row?.appraisal_photos?.[0]} />}
        />
      ),
      width: "80px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.item")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            initialValue={getColumnDetail(
              itemsPayload?.filter?.custom_title_en__icontains,
              itemsPayload?.filter?.custom_title_pt__icontains
            )}
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row ? getItemCol(row) : "-"),
      wrap: true,
      width: "166px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.minimumEstimate")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="min_estimate__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.min_estimate__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.min_estimate !== "" ? formatAmount(row?.min_estimate) : "-"),
      width: "166px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.maximumEstimate")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="max_estimate__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            initialValue={itemsPayload?.filter?.max_estimate__icontains}
          />
        </div>
      ),
      selector: (row) => (row?.max_estimate !== "" ? formatAmount(row?.max_estimate) : "-"),
      width: "166px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.valueToBeAgreed")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedValueAgreedOptions}
            handleOnSelection={(values) => {
              setSelectedValueAgreedOptions(values);
              handleDropdownFilter(values, "value_to_be_agreed__in", setSelectedValueAgreedOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.value_to_be_agreed ? props.t("common.yes") : props.t("common.no")),
      width: "166px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.sent")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...PRECONTRACT_ITEMS_SENT_OPTIONS]}
            selectedOptions={selectedSentOptions}
            handleOnSelection={(values) => {
              setSelectedSentOptions(values);
              handleDropdownFilter(values, "is_sent__in", setSelectedSentOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_sent ? props.t("common.yes") : props.t("common.no")),
      width: "166px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.status")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...PRECONTRACT_ITEMS_TAG_OPTIONS]}
            selectedOptions={selectedTagOptions}
            handleOnSelection={(values) => {
              setSelectedTagOptions(values);
              handleDropdownFilter(values, "described__in", setSelectedTagOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.described ? (
          <CustomTagChip
            title={props.t("common.described")}
            bgColor="#8ed1fc"
            textColor={generateContrastColor("#8ed1fc")}
          />
        ) : (
          <CustomTagChip
            title={props.t("common.notDescribed")}
            bgColor="#eb144c"
            textColor={generateContrastColor("#eb144c")}
          />
        ),
      width: "182px",
      wrap: true,
      center: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              navigateOnPrecontractItemFormForCopy(row?.id);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-copy`} tooltipBody={props.t("common.copy")} />
            <i className=" ri-file-copy-fill fs-18" id={`tooltip-${row?.id}-copy`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "112px",
      center: true,
    },
  ];

  return (
    <>
      <CardHeader className="card-section-header d-flex flex-row align-items-center justify-content-between">
        <span className="card-title">{props.t("common.items")}</span>

        {props?.isEditable ? (
          <div className="d-flex flex-row gap-2">
            {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
              <ActionButton
                tooltip={props.t("common.openEmailApp")}
                buttonClasses="me-2 bg-soft-primary"
                iconClasses="ri-mail-send-fill fs-24"
                buttonId={"mail"}
                onClick={handleOpenMailApp}
              />
            )}
            {(isAllSelected && totalItems > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalItems}
                </Badge>
              </Button>
            ) : null}
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.item"),
              })}
              onClick={() => navigateOnPrecontractItemForm()}
            />
            {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
              <CustomDropdownButton
                disabled={totalItems <= 0 || exportBtnLoader}
                isLoading={sendMailLoader}
                btnTitle={props.t("common.sendMail")}
                options={[
                  {
                    label: props.t("common.pdf", {
                      lang: props.t("common.en"),
                    }),
                    onClick: () => {
                      handleExportPDF(true, true);
                    },
                  },
                  {
                    label: props.t("common.pdf", {
                      lang: props.t("common.pt"),
                    }),
                    onClick: () => {
                      handleExportPDF(true, false);
                    },
                  },
                ]}
              />
            )}
            <CustomDropdownButton
              disabled={totalItems <= 0 || sendMailLoader}
              isLoading={exportBtnLoader}
              btnTitle={props.t("common.export")}
              options={[
                {
                  label: props.t("common.pdf", { lang: props.t("common.en") }),
                  onClick: () => {
                    handleExportPDF(false, true);
                  },
                },
                {
                  label: props.t("common.pdf", { lang: props.t("common.pt") }),
                  onClick: () => {
                    handleExportPDF(false, false);
                  },
                },
              ]}
            />
            <CustomButton
              isLoading={importExcelLoader}
              btnTitle={props.t("common.import")}
              onClick={handleImportExcelModalOpen}
            />
            {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
              <CustomButton
                disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
                btnTitle={props.t("common.contract")}
                onClick={handleSendToContractModalOpen}
              />
            )}

            <CustomButton
              isLoading={bulkDeleteLoader}
              color={"danger"}
              btnTitle={props.t("common.delete")}
              disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
              onClick={() => {
                handleBulkDeleteModalOpen();
              }}
            />
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="card-section-body">
        {tableLoader ? <Loader /> : null}

        {props.isEditable ? (
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table pre-contract-items-table"
            persistTableHead
            onRowClicked={(row) => {
              navigateOnPrecontractItemForm(row?.id);
            }}
            columns={columns}
            data={precontractItems}
            paginationDefaultPage={itemsPayload?.page}
            paginationPerPage={itemsPayload?.limit}
            pagination
            paginationServer
            paginationTotalRows={totalItems}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        ) : (
          <MessageBlock message={props.t("information.precontractItemsBlock")} />
        )}
      </CardBody>
      {/* sent items to contract modal */}
      <SendToContractModal
        title={props.t("information.sendItemsToContract", {
          numberOfItems: selectedRows.length || totalItems,
          itemText: (selectedRows.length || totalItems) > 1 ? props.t("common.items") : props.t("common.item"),
        })}
        isModalOpen={isSendToContractModalOpen}
        newContractLoader={sendToNewContractLoader}
        existingContractLoader={sendToExistingContractLoader}
        onConfirm={handleSendItemsToContract}
        onCancel={handleSendToContractModalClose}
      />
      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={`${props.t("common.delete")} ${selectedRows.length || totalItems} ${props.t(
          "contracts.precontracts.precontract"
        )} ${props.t("common.items")}`}
        body={props.t("confirmation.deleteMessage", {
          module: `${props.t("contracts.precontracts.precontract")} ${props.t("common.items")}`,
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={bulkDeleteLoader}
      />
      {/* delete modal for the items */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteItem}
        title={`${props.t("common.delete")} ${props.t("common.precontractItem")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.precontractItem"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
      {/* import excel modal  */}
      <ImportExcelModal
        isModalOpen={isImportExcelModalOpen}
        onCancel={handleImportExcelModalClose}
        onSave={handleAddExcel}
        loader={importExcelLoader}
      />
    </>
  );
};

export default withTranslation()(PreContractItems);
