import React from "react";

const CustomLinkWrapper = ({ link, title }) => {
  return (
    <a
      className="custom-link-wrapper font-weight-bold text-primary text-break"
      href={link}
      target="_blank"
      rel="noreferrer"
      title={title}
    >
      {title}
    </a>
  );
};

export default CustomLinkWrapper;
