import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { getContractOptions, getTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getContracts } from "../../../store/contracts/actions";
import CenteredTextDivider from "../../common/CenteredTextDivider";
import debounce from "lodash.debounce";
import { useMemo } from "react";
import { DEBOUNCE_TIME } from "../../../helpers/constants";
import i18next from "i18next";

const SendToContractModal = ({
  isModalOpen,
  title,
  onCancel,
  onConfirm,
  disabled,
  t,
  existingContractLoader,
  newContractLoader,
}) => {
  const dispatch = useDispatch();
  const precontractData = useSelector((state) => state?.PreContracts?.precontract);
  const CONTRACT_OPTIONS = getContractOptions(useSelector((state) => state?.Contracts?.data));

  const [selectionLoader, setSelectionLoader] = useState(false);
  const [contractsPayload, setContractsPayload] = useState({
    page: 1,
    limit: 50,
    filter: {
      search_list__icontains: "",
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      existing_contract_id: "",
    },
    validationSchema: Yup.object({
      existing_contract_id: Yup.string().required(
        `${t("common.please")} ${t("common.select")} ${t("common.contract")}`
      ),
    }),
    onSubmit: (values) => {
      onConfirm(values);
    },
  });

  const handleModalClose = () => {
    if (!(newContractLoader || existingContractLoader)) {
      validation.resetForm();
      onCancel();
    }
  };

  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  // to handle the contract search
  const handleContractSearch = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_list__icontains: value },
    }));
  };

  // to memorize the debounce
  const contractSearchDebounce = useMemo(() => {
    return debounce(handleContractSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      contractSearchDebounce.cancel();
    };
  }, [contractSearchDebounce]);

  // to set the customer id
  useEffect(() => {
    if (precontractData?.customer?.id && isModalOpen) {
      setContractsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          customer: precontractData?.customer?.id,
        },
      }));
    }
  }, [precontractData, isModalOpen]);

  // to get the list of contracts
  useEffect(() => {
    if (isModalOpen && contractsPayload?.filter?.customer) {
      setSelectionLoader(true);
      dispatch(getContracts(contractsPayload)).then((res) => {
        setSelectionLoader(false);
      });
    }
  }, [contractsPayload, dispatch, isModalOpen]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{title}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody className="d-flex flex-column justify-content-center ">
          <div className="mb-3">
            <Label htmlFor="existing_contract_id" className="form-label">
              {t("common.contract")}
            </Label>
            <Select
              isLoading={selectionLoader}
              name="existing_contract_id"
              options={selectionLoader ? [] : CONTRACT_OPTIONS}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.contract")}`}
              value={getTypeFromValue(CONTRACT_OPTIONS, validation.values.existing_contract_id)}
              onChange={(option) => {
                validation.setFieldValue("existing_contract_id", option?.value);
              }}
              onInputChange={contractSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("existing_contract_id", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.existing_contract_id && validation.errors.existing_contract_id ? (
              <p className="custom-invalid-feedback">{validation.errors.existing_contract_id}</p>
            ) : null}
          </div>
          <div className="d-flex flex-row gap-2 justify-content-end w-100">
            <Button color="dark" onClick={handleModalClose} disabled={existingContractLoader || newContractLoader}>
              {t("common.cancel")}
            </Button>
            <Button
              color="primary"
              disabled={
                CONTRACT_OPTIONS?.length === 0 ||
                !contractsPayload?.filter?.customer ||
                newContractLoader ||
                existingContractLoader ||
                disabled
              }
              className="btn-load d-flex"
              type="submit"
              onClick={() => scrollToFirstFormikError(validation.errors)}
            >
              {existingContractLoader ? (
                <span className="d-flex align-items-center ">
                  <Spinner size="xs" className="flex-shrink-0"></Spinner>
                </span>
              ) : null}

              <span className={`flex-grow-1 ${existingContractLoader ? "ms-2" : ""}`}>
                {`${t(i18next.resolvedLanguage === "en" ? "common.send" : "common.linkContract")}`}
              </span>
            </Button>
          </div>
          <CenteredTextDivider />

          <CustomButton
            btnTitle={t("common.createNewBtn", {
              module: t("common.contract"),
            })}
            type="button"
            disabled={newContractLoader || existingContractLoader || disabled}
            isLoading={newContractLoader}
            onClick={() => {
              onConfirm();
            }}
          />
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default withTranslation()(SendToContractModal);
