import { SET_FILES_DATA, SET_MULTIMEDIA_DATA } from "./actionTypes";

const INIT_STATE = {
  documents: null,
  multimedia: { data: [] },
};

const Files = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILES_DATA:
      return {
        ...state,
        documents: { ...state?.documents, ...action.payload },
      };
    case SET_MULTIMEDIA_DATA:
      return {
        ...state,
        multimedia: {
          ...state?.multimedia,
          data: action.payload.data,
        },
      };
    default:
      return { ...state };
  }
};

export default Files;
