import {
  ADD_PAYMENTCONDITION,
  DELETE_PAYMENTCONDITION,
  SET_PAYMENTCONDITIONS_DATA,
  UPDATE_PAYMENTCONDITION,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const PaymentConditions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAYMENTCONDITIONS_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_PAYMENTCONDITION:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_PAYMENTCONDITION:
      const updatePaymentConditionsData = state.data;
      const existingPaymentConditionIndex = state.data.findIndex(
        (paymentType) => paymentType?.id === action.payload.id
      );
      if (existingPaymentConditionIndex !== -1) {
        updatePaymentConditionsData[existingPaymentConditionIndex] = action.payload;
      }
      return {
        ...state,
        data: updatePaymentConditionsData,
      };
    case DELETE_PAYMENTCONDITION:
      return {
        ...state,
        data: [...state.data.filter((paymentCondition) => paymentCondition.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default PaymentConditions;
