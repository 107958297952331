import React from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";

const SendProformaInvoicesModal = ({
  isModalOpen,
  loader,
  onSendBtnHandler,
  onChooseBtnHandler,
  onCloseBtnHandler,
  t,
}) => {
  const { id } = useParams();

  const handleModalClose = () => {
    if (!loader) onCloseBtnHandler();
  };

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
        <ModalHeader>{t("common.sendProformaInvoices")}</ModalHeader>
        <ModalBody>{t("confirmation.sendProformaInvoices")}</ModalBody>
        <ModalFooter>
          <CustomButton
            isLoading={loader}
            color={"success"}
            btnTitle={t("common.sendAll")}
            onClick={() => {
              onSendBtnHandler();
            }}
          />
          <CustomButton disabled={loader} btnTitle={t("common.choose")} onClick={onChooseBtnHandler} />
          <CustomButton disabled={loader} color={"dark"} btnTitle={t("common.cancel")} onClick={handleModalClose} />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(SendProformaInvoicesModal);
