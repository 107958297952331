import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import CustomColorPicker from "../../common/CustomColorPicker";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddAuctionTagModal = ({ auctionTag, isModalOpen, onCancel, onSave, loader, t }) => {
  // const [color, setColor] = useState("#8ed1fc");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      tag_label: auctionTag?.tag_label || "",
      tag_label_pt: auctionTag?.tag_label_pt || "",
      tag_color: auctionTag?.tag_color || "#8ed1fc",
      tag_name: auctionTag?.tag_name || "",
    },
    validationSchema: Yup.object({
      tag_label: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.tag")}`,
        })
      ),
      tag_label_pt: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.tag")} (${t("common.pt")})`,
        })
      ),
      // tag_color: Yup.string().required(
      //   t("common.requiredValidationMsg", {
      //     field: `${t("common.tag")}`,
      //   })
      // ),
      tag_name: Yup.string().required(
        t("common.requiredValidationMsg", {
          field: `${t("common.description")}`,
        })
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {`${
          auctionTag?.id
            ? t("common.editTitle", {
                module: `${t("sidebarMenu.tags")} `,
              })
            : t("common.addTitle", {
                module: `${t("sidebarMenu.tags")} `,
              })
        } `}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="tag_label" className="form-label">
              {t("common.tag")}
            </Label>
            <div className="d-flex flex-row gap-3 align-items-start">
              <div className="d-flex flex-column">
                <Input
                  name="tag_label"
                  className="form-control"
                  placeholder={`${t("common.enter")} ${t("settings.auctions.auctionTags.auction")} ${t("common.tag")}`}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tag_label || ""}
                  invalid={validation.touched.tag_label && validation.errors.tag_label ? true : false}
                />
                {validation.touched.tag_label && validation.errors.tag_label ? (
                  <FormFeedback style={{ display: "block" }} type="invalid">
                    {validation.errors.tag_label}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="d-flex flex-column">
                <Input
                  name="tag_label_pt"
                  className="form-control"
                  placeholder={`${t("common.enter")} ${t("settings.auctions.auctionTags.auction")} ${t("common.tag")}`}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tag_label_pt || ""}
                  invalid={validation.touched.tag_label_pt && validation.errors.tag_label_pt ? true : false}
                />
                {validation.touched.tag_label_pt && validation.errors.tag_label_pt ? (
                  <FormFeedback style={{ display: "block" }} type="invalid">
                    {validation.errors.tag_label_pt}
                  </FormFeedback>
                ) : null}
              </div>
              <CustomColorPicker
                initialColor={validation.values.tag_color}
                onChange={(hexColor) => {
                  validation.setFieldValue("tag_color", hexColor);
                  // setColor(hexColor);
                }}
              />
            </div>
          </div>
          <div className="mb-3">
            <Label htmlFor="tag name" className="form-label">
              {t("common.description")}
            </Label>
            <Input
              name="tag_name"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.auctions.auctionTags.auction")} ${t(
                "common.description"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.tag_name || ""}
              invalid={validation.touched.tag_name && validation.errors.tag_name ? true : false}
            />
            {validation.touched.tag_name && validation.errors.tag_name ? (
              <FormFeedback type="invalid">{validation.errors.tag_name}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddAuctionTagModal);
