import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomButton from "./CustomButton";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../helpers/constants";
import { getColumnDetail, getDateInYYYYMMDDFormat } from "../../helpers/utils";
import { getAuctionList } from "../../store/auctions/actions";

const LinkToAuctionModal = ({ isModalOpen, title, onCancel, onConfirm, disabled, t, loader }) => {
  const dispatch = useDispatch();

  const AUCTIONS_OPTIONS = useSelector((state) => state?.Auctions?.data);

  const [auctionType, setAuctionType] = useState("upcoming");
  const [auctionSearchLoader, setAuctionSearchLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    filter: {
      auction_start_date__gt: getDateInYYYYMMDDFormat(new Date()),
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      auction_id: "",
    },
    validationSchema: Yup.object({
      auction_id: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.auction")}`),
    }),
    onSubmit: (values) => {
      onConfirm({ auction_id: values?.auction_id?.id || null });
    },
  });

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
      setAuctionType("upcoming");
    }
  }, [isModalOpen]);

  // to handle the auction search
  const handleAuctionSearch = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("search_auction_en__icontains", "search_auction_pt__icontains")]: value,
      },
    }));
  };

  // to memorize the debounce
  const auctionSearchDebounce = useMemo(() => {
    return debounce(handleAuctionSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      auctionSearchDebounce.cancel();
    };
  }, [auctionSearchDebounce]);

  //   to get the list of auctions
  useEffect(() => {
    if (isModalOpen) {
      setAuctionSearchLoader(true);
      dispatch(getAuctionList(auctionPayload)).then((res) => {
        setAuctionSearchLoader(false);
      });
    }
  }, [auctionPayload, dispatch, isModalOpen]);

  // useeffect to filter old and upcoming
  useEffect(() => {
    if (auctionType === "upcoming") {
      setAuctionPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__lte;
        return {
          ...prevState,
          filter: {
            ...prevState?.filter,
            auction_start_date__gt: getDateInYYYYMMDDFormat(new Date()),
          },
        };
      });
    } else {
      setAuctionPayload((prevState) => {
        delete prevState?.filter?.auction_start_date__gt;
        return {
          ...prevState,
          filter: {
            ...prevState?.filter,
            auction_start_date__lte: getDateInYYYYMMDDFormat(new Date()),
          },
        };
      });
    }
  }, [auctionType, isModalOpen]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{title}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody className="d-flex flex-column justify-content-center ">
          <div className="d-flex flex-row mb-3 gap-4">
            <div>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={auctionType === "old"}
                onClick={() => {
                  setAuctionType("old");
                  validation?.resetForm();
                }}
              />
              <Label className="form-label ms-2" for="flexRadioDefault1">
                {t("common.oldAuctions")}
              </Label>
            </div>
            <div>
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={auctionType === "upcoming"}
                onClick={() => {
                  setAuctionType("upcoming");
                  validation?.resetForm();
                }}
              />
              <Label className="form-label ms-2" for="flexRadioDefault2">
                {t("common.upcomingAuctions")}
              </Label>
            </div>
          </div>
          <div className="mb-3">
            <Label htmlFor="auction_id" className="form-label">
              {t("common.auction")}
            </Label>
            <Select
              isLoading={auctionSearchLoader}
              name="auction_id"
              options={auctionSearchLoader ? [] : AUCTIONS_OPTIONS}
              getOptionLabel={(option) =>
                `${option?.auction_no} -  ${getColumnDetail(option?.title_en, option?.title_pt) || ""}`
              }
              getOptionValue={(option) => option?.id}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.auction")}`}
              value={validation.values.auction_id || ""}
              onChange={(option) => {
                validation.setFieldValue("auction_id", option?.id ? option : "");
              }}
              onInputChange={auctionSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("auction_id", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.auction_id && validation.errors.auction_id ? (
              <p className="custom-invalid-feedback">{validation.errors.auction_id}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton btnTitle={t("common.cancel")} type="button" onClick={handleModalClose} color="dark" />
          <CustomButton btnTitle={t("common.link")} isLoading={loader} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(LinkToAuctionModal);
