import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import {
  calculateLineFinalAmount,
  getColumnDetail,
  getContractOptions,
  getLotDescriptionForInvoice,
  getTypeFromValue,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import CustomButton from "../../common/CustomButton";
import CustomSearchInput from "../../common/CustomSearchInput";
import Loader from "../../common/Loader";
import NoRecords from "../../common/NoRecords";
import { getContractItems, getContracts } from "../../../store/contracts/actions";
import { toast } from "react-toastify";

const ReturnGuideDocumentLineModal = ({
  selectedItems,
  selectedCustomer,
  addDocumentLine,
  isModalOpen,
  handleModalClose,
  t,
}) => {
  const dispatch = useDispatch();
  const CONTRACT_OPTIONS = getContractOptions(useSelector((state) => state?.Contracts?.data));
  const { contractItems, totalItems } = useSelector((state) => state?.Contracts);

  const [saveLoader, setSaveLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [contractSearchLoader, setContractSearchLoader] = useState(false);
  const [contractsPayload, setContractsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "customer__name",
    filter: selectedCustomer ? { customer: selectedCustomer } : {},
  });
  const [itemsPayload, setItemsPayload] = useState({
    page: 1,
    sort: "position_contract",
    filter: {
      is_sold: false,
      is_return_guide: false,
    },
  });

  // to filter the contract by the selected customer
  useEffect(() => {
    if (selectedCustomer) {
      setContractsPayload((prevState) => ({
        ...prevState,
        filter: { ...prevState?.filter, customer: selectedCustomer },
      }));
    }
  }, [selectedCustomer]);

  // handle contract search
  const handleContractSearch = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_list__icontains: value },
    }));
  };
  // debounce for contract search
  const contractSearchDebounce = useMemo(() => {
    return debounce(handleContractSearch, DEBOUNCE_TIME);
  }, []);

  // cancel all debounces
  useEffect(() => {
    return () => {
      contractSearchDebounce.cancel();
    };
  }, [contractSearchDebounce]);

  // to the list of  contracts
  useEffect(() => {
    if (isModalOpen && contractsPayload?.filter?.customer) {
      setContractSearchLoader(true);
      dispatch(getContracts(contractsPayload)).then(() => {
        setContractSearchLoader(false);
      });
    }
  }, [dispatch, contractsPayload, isModalOpen]);

  // to get the list of contract items
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      dispatch(getContractItems(itemsPayload)).then(() => {
        setTableLoader(false);
      });
    }
  }, [itemsPayload, dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      type_of_product: "lot",
      contract: "",
    },
    validationSchema: Yup.object({
      contract: Yup.string().required(`${t("common.please")} ${t("common.select")} ${t("common.contract")}`),
    }),
    onSubmit: (values) => {
      handleAddDocumentLine(values);
    },
  });

  // handle add document
  const handleAddDocumentLine = (values) => {
    if ((isAllSelected && totalItems === 0) || (!isAllSelected && selectedRows?.length === 0)) {
      toast.info(t("information.atleastOneItem", { module: t("common.contractItem") }));
      return;
    }

    let lots = [];
    let items = [];

    if (isAllSelected && totalItems) {
      lots = contractItems;
    }
    if (selectedRows.length > 0 && !isAllSelected) {
      lots = selectedRows;
    }
    items = lots?.map((lot) => {
      const item = {};
      const finalAmount = calculateLineFinalAmount(lot?.auction_lots?.sale_value, {
        quantity: 1,
        discount: values?.discount,
        discount_unit: values?.discount_unit,
        vat: values?.vat,
      });

      item.type_of_product = values?.type_of_product;
      item.contract = values?.contract;
      item.contract_item = lot?.id;
      item.quantity = 1;
      item.discount = values?.discount || 0;
      item.discount_unit = values?.discount_unit;
      item.vat = values?.vat?.id;
      item.amount = finalAmount;

      const itemData = {};
      itemData.type_of_product = values?.type_of_product;
      itemData.positionInContract = lot?.contract_item_no;
      itemData.description = getLotDescriptionForInvoice({
        contract_item: { ...lot },
      });
      itemData.quantity = 1;
      itemData.amount = (lot?.auction_lots?.sale_value || 0) + "€";
      itemData.discount = (values?.discount || 0) + values?.discount_unit;
      itemData.vat = (values?.vat?.rate || 0) + "%";
      itemData.final_amount = finalAmount + "€";
      item.itemData = itemData;

      return { ...item };
    });
    addDocumentLine(items);
  };

  // to get the list of the contract items by contract id
  useEffect(() => {
    if (validation?.values?.contract) {
      setItemsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          contract: validation?.values?.contract,
        },
        exclude: {
          ...prevState?.exclude,
          id__in: selectedItems,
        },
      }));
    }
  }, [validation?.values?.contract, selectedItems]);

  // handle reset form with all other maunual states
  const handleReset = () => {
    validation.resetForm();
    setIsAllSelected(false);
    setSelectedRows([]);
  };

  // use effect to reset the modal form
  useEffect(() => {
    if (!isModalOpen) {
      handleReset();
    }
  }, [isModalOpen]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item?.id === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item?.id === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item?.id !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="position_contract__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.position_contract ? row?.position_contract : "-"),
      width: "20%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={t.resolvedLanguage === "pt" ? "title_pt__icontains" : "title_en__icontains"}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (t.resolvedLanguage === "pt" ? row?.title_pt || "-" : row?.title_en || "-"),
      width: "32.5%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={t.resolvedLanguage === "pt" ? "description_pt__icontains" : "description_en__icontains"}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (t.resolvedLanguage === "pt" ? row?.description_pt || "-" : row?.description_en || "-"),
      width: "32.5%",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="lg"
      className="modal-header-width-100"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{t("common.addTitle", { module: t("common.documentLine") })}</span>

          {((isAllSelected && totalItems > 0) || selectedRows?.length) && validation?.values?.contract ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows?.length || totalItems}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>
      <Form
        onKeyPress={onKeyPressForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <Row>
            <Col sm={6} md={6} lg={6}>
              <div className="mb-3">
                <Label htmlFor="contract" className="form-label">
                  {`${t("common.contract")}*`}
                </Label>
                <Select
                  isLoading={contractSearchLoader}
                  name="contract"
                  options={contractSearchLoader ? [] : CONTRACT_OPTIONS}
                  className={`custom-select ${
                    validation.touched.contract && validation.errors.contract ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.contract")}`}
                  value={getTypeFromValue(CONTRACT_OPTIONS, validation.values.contract) || ""}
                  onInputChange={contractSearchDebounce}
                  onChange={(contract) => {
                    setIsAllSelected(false);
                    setSelectedRows([]);
                    validation.setFieldValue("contract", contract?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("contract", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.contract && validation.errors.contract ? (
                  <p className="custom-invalid-feedback">{validation.errors.contract}</p>
                ) : null}
              </div>
            </Col>
          </Row>

          {/*contract items table */}
          <Card className="subcard-table-container table-light">
            {tableLoader ? <Loader /> : null}
            <DataTable
              className="mini-data-table"
              fixedHeader
              persistTableHead
              columns={columns}
              data={validation?.values?.contract ? [...contractItems] : []}
              noDataComponent={
                <NoRecords message={!validation?.values?.contract && t("information.returnGuideContracts")} />
              }
              pagination
              paginationComponentOptions={{
                rowsPerPageText: t("common.rowsPerPage"),
                rangeSeparatorText: t("common.rangeSeparator"),
              }}
            />
          </Card>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type="button"
            color="dark"
            btnTitle={t("common.cancel")}
            onClick={handleModalClose}
            disabled={saveLoader}
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={saveLoader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ReturnGuideDocumentLineModal);
