import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { openFileURL } from "../../helpers/utils";
import { SET_INVOICE, SET_INVOICES } from "./actionTypes";

// to get list of invoices
export const getInvoices = (params) => async (dispatch) => {
  try {
    return API.get(`/billings/documents`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        search_list: params?.search_list,
        sort: params?.sort,
        filter: params?.filter,
        exclude: params?.exclude,
        is_receipt: params?.is_receipt,
        is_paid: params?.is_paid,
        is_credit_note: params?.is_credit_note,
        is_invoice: params?.is_invoice,
        customer_id: params?.customer_id,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_INVOICES,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
            all_ids: res?.data?.data?.all_ids,
            closing_balance: res?.data?.data?.closing_balance,
          },
        });
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to get invoiec by id
export const getInvoiceById = (invoiceId) => async (dispatch) => {
  try {
    return API.get(`/billings/documents/${invoiceId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({ type: SET_INVOICE, payload: res?.data?.data });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// to add invoice
export const addInvoice = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_INVOICE, payload: res?.data?.data });
        openFileURL(res?.data?.phc_document_link || res?.data?.file, "preview");
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update invoice
export const updateInvoice = (invoiceId, payload) => async (dispatch) => {
  try {
    return API.put(`/billings/documents/${invoiceId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        dispatch({ type: SET_INVOICE, payload: res?.data?.data });
        openFileURL(res?.data?.phc_document_link || res?.data?.file, "preview");
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete invoice
export const deleteInvoice = (invoiceId, payload) => async (dispatch) => {
  try {
    return API.delete(`billings/documents/${invoiceId}`, {
      data: { ...payload },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export invoices
export const exportInvoices = (payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export individual invoice
export const exportInvoice = (invoiceId, payload) => async (dispatch) => {
  try {
    return API.post(`/billings/documents/${invoiceId}/export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
