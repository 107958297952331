import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { Card, CardHeader } from "reactstrap";
import * as Yup from "yup";
import TabsComponent from "../../../components/common/TabsComponent";
import Documents from "../../../components/pages/website-management/files/Documents";
import Multimedia from "../../../components/pages/website-management/files/Multimedia";
import { TYPE_PUBLISH, TYPE_SAVE } from "../../../helpers/constants";
import { uploadWebsiteDocuments } from "../../../store/files/actions";

const Files = (props) => {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state?.Files?.documents);
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [submitType, setSubmitType] = useState();

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      terms_conditions_pt: documents?.pt?.terms_conditions || "",
      terms_conditions_en: documents?.en?.terms_conditions || "",
      privacy_policy_pt: documents?.pt?.privacy_policy || "",
      privacy_policy_en: documents?.en?.privacy_policy || "",
      form_to_leave_bid_pt: documents?.pt?.form_to_leave_bid || "",
      form_to_leave_bid_en: documents?.en?.form_to_leave_bid || "",
      catalogues_subscription_pt: documents?.pt?.catalogues_subscription || "",
      catalogues_subscription_en: documents?.en?.catalogues_subscription || "",
    },
    validationSchema: Yup.object({
      terms_conditions_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      terms_conditions_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      privacy_policy_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      privacy_policy_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      form_to_leave_bid_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      form_to_leave_bid_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      catalogues_subscription_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
      catalogues_subscription_en: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.upload")} ${props.t("common.document")}`
      ),
    }),
    onSubmit: (values) => {
      handleSaveAndPublish(values, submitType);
    },
  });

  // to format the save and publish payload
  const formatPayload = (values, save_type) => {
    const payload = [];
    Object.keys(values).forEach((key) => {
      const splittedKey = key.split("_");
      splittedKey.pop();
      payload.push({
        file_type: splittedKey.join("_"),
        language_type: key.split("_").slice(-1)[0],
        save_type: save_type,
        page_type: "document",
        file: values?.[key],
      });
    });
    return payload;
  };

  // to handle save and save&publish of images
  const handleSaveAndPublish = (values, save_type) => {
    const payload = formatPayload(values, save_type);
    if (save_type === TYPE_SAVE) setSaveLoader(true);
    if (save_type === TYPE_PUBLISH) setPublishLoader(true);
    dispatch(uploadWebsiteDocuments(payload)).then((res) => {
      setSaveLoader(false);
      setPublishLoader(false);
    });
  };

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (validation?.dirty) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        setActivePageIndex(index);
        validation?.resetForm();
      }
    } else {
      setActivePageIndex(index);
      validation?.resetForm();
    }
  };

  document.title = `Veritas | ${props.t("sidebarMenu.files")}`;

  return (
    <div className="page-content layout-main-container">
      <Prompt when={validation?.dirty} message={props.t("confirmation.resetTabData")} />
      <Card className="container-body card-section">
        <CardHeader className="card-section-header">
          <span className="card-title">{props.t("sidebarMenu.files")}</span>
        </CardHeader>
        <TabsComponent
          controlledTabChange={true}
          activeTab={activePageIndex}
          setActiveTab={handleChangeActiveTabIndex}
          tabData={[
            {
              label: props.t("common.documents"),
              Component: Documents,
              props: {
                type: "next",
                validation,
                saveLoader,
                publishLoader,
                handleSaveAndPublish,
                setSubmitType,
              },
            },
            {
              label: props.t("common.multimedia"),
              Component: Multimedia,
              props: { type: "closed" },
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default withTranslation()(Files);
