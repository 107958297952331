// common action types
export const SET_COUNTRIES_OPTIONS = "SET_COUNTRIES_OPTIONS";
export const SET_DEFAULT_DOCUMENT_TYPES = "SET_DEFAULT_DOCUMENT_TYPES";
export const SET_DEFAULT_LOTS_TAGS = "SET_DEFAULT_LOTS_TAGS";
export const SET_DEFAULT_VATS = "SET_DEFAULT_VATS";
export const SET_DEFAULT_CURRENCIES = "SET_DEFAULT_CURRENCIES";
export const SET_DEFAULT_PAYMENT_CONDITIONS = "SET_DEFAULT_PAYMENT_CONDITIONS";
export const SET_DEFAULT_PAYMENT_METHODS = "SET_DEFAULT_PAYMENT_METHODS";
export const SET_TEAM_POSITIONS = "SET_TEAM_POSITIONS";
export const SET_CUSTOMER_PREFERENCES = "SET_CUSTOMER_PREFERENCES";
