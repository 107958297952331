import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomMultiSelect from "../../../../components/common/CustomMultiSelect";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddProductOrServiceModal from "../../../../components/pages/settings/AddProductOrServiceModal";
import {
  PRODUCT_SERVICE_TYPES,
  PRODUCT_UNIT_TYPES,
  SELECT_ALL_OPTION,
} from "../../../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  getLabelValueArray,
  getTypeFromValue,
  getValuesFromArray,
} from "../../../../helpers/utils";
import { getCurrencies } from "../../../../store/currencies/actions";
import {
  addProductOrService,
  deleteProductOrService,
  getProductsAndServices,
  updateProductOrService,
} from "../../../../store/products-services/actions";
import { getVATs } from "../../../../store/vat/actions";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";

const ProductsAndServices = (props) => {
  const dispatch = useDispatch();
  const { data: productsAndServicesData, total: totalProductsAndServices } = useSelector(
    (state) => state?.ProductsAndServices
  );
  const VAT_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.VAT.data),
    "id",
    "rate"
  );
  const CURRENCIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Currencies?.data),
    "id",
    "code"
  );

  const [productsAndServicesPayload, setProductsAndServicesPayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "",
    filter: {
      id__icontains: "",
      product_name__icontains: "",
      value__icontains: "",
    },
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isProductsAndServicesModalOpen, setIsProductAndServicesModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState([]);
  const [selectedVats, setSelectedVats] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);

  // add or edit action handlers
  const handleProductsAndServicesModelOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsProductAndServicesModalOpen(true);
  };
  const handleProductsAndServicesModelClose = () => {
    setSelectedItem(null);
    setIsProductAndServicesModalOpen(false);
  };
  const handleSaveProductandService = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit currency
      setSaveLoader(true);
      dispatch(updateProductOrService(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleProductsAndServicesModelClose();
            formikValidation.resetForm();
            handleGetProductsAndServices(productsAndServicesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add currency
      setSaveLoader(true);
      dispatch(addProductOrService(values))
        .then((res) => {
          if (res) {
            // success handler
            handleProductsAndServicesModelClose();
            formikValidation.resetForm();
            handleGetProductsAndServices(productsAndServicesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteProductAndService = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(deleteProductOrService(selectedItem?.id))
        .then((res) => {
          if (res) {
            handleGetProductsAndServices(productsAndServicesPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setProductsAndServicesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setProductsAndServicesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setProductsAndServicesPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setProductsAndServicesPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setProductsAndServicesPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // to the list of the VATs and also to get the list of currencies
  useEffect(() => {
    dispatch(getVATs());
    dispatch(getCurrencies());
  }, [dispatch]);

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setProductsAndServicesPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get products and services
  const handleGetProductsAndServices = useCallback(
    (productsAndServicesPayload) => {
      setTableLoader(true);
      dispatch(getProductsAndServices(productsAndServicesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetProductsAndServices(productsAndServicesPayload);
  }, [productsAndServicesPayload, handleGetProductsAndServices]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={productsAndServicesPayload}
            setPayload={setProductsAndServicesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="product_name__icontains"
            className="column-search-input"
            payload={productsAndServicesPayload}
            setPayload={setProductsAndServicesPayload}
          />
        </div>
      ),
      selector: (row) => row?.product_name,
      minWidth: "280px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.type")}
            sortable
            sortableKey="product_type"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...PRODUCT_SERVICE_TYPES]}
            selectedOptions={selectedProductTypes}
            handleOnSelection={(values) => {
              setSelectedProductTypes(values);
              handleDropdownFilter(values, "product_type__in", setSelectedProductTypes);
            }}
          />
        </div>
      ),
      selector: (row) => props.t(getTypeFromValue(PRODUCT_SERVICE_TYPES, row?.product_type)?.label),
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.value")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="value__icontains"
            className="column-search-input"
            payload={productsAndServicesPayload}
            setPayload={setProductsAndServicesPayload}
          />
        </div>
      ),
      selector: (row) => row?.value,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.unit")}
            sortable
            sortableKey="units"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...PRODUCT_UNIT_TYPES]}
            selectedOptions={selectedUnitTypes}
            handleOnSelection={(values) => {
              setSelectedUnitTypes(values);
              handleDropdownFilter(values, "units__in", setSelectedUnitTypes);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.units !== undefined ? row?.units : "-"),
      minWidth: "140px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.vat")}
            sortable
            sortableKey="vat"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...VAT_OPTIONS]}
            selectedOptions={selectedVats}
            handleOnSelection={(values) => {
              setSelectedVats(values);
              handleDropdownFilter(values, "vat__in", setSelectedVats);
            }}
            getOptionLabel={(e) => e.label + (e.value === "*" ? "" : "%")}
          />
        </div>
      ),
      selector: (row) => (row?.vat?.rate !== undefined ? row?.vat?.rate + "%" : "-"),
      minWidth: "140px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.currency")}
            sortable
            sortableKey="currency"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...CURRENCIES_OPTIONS]}
            selectedOptions={selectedCurrencies}
            handleOnSelection={(values) => {
              setSelectedCurrencies(values);
              handleDropdownFilter(values, "currency__in", setSelectedCurrencies);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.currency?.code ? row?.currency?.code : "-"),
      minWidth: "140px",
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleProductsAndServicesModelOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.products&services")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={productsAndServicesPayload}
            setPayload={setProductsAndServicesPayload}
          />

          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleProductsAndServicesModelOpen}
              btnTitle={props.t("settings.billing.products&services.addProductOrService")}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.products&services")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...productsAndServicesData]}
            pagination
            paginationServer
            paginationTotalRows={totalProductsAndServices}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddProductOrServiceModal
        item={selectedItem}
        isModalOpen={isProductsAndServicesModalOpen}
        onCancel={handleProductsAndServicesModelClose}
        onSave={handleSaveProductandService}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteProductAndService}
        title={`${props.t("common.delete")} ${props.t("settings.billing.products&services.productOrServiceItem")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.products&services.productOrServiceItem"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(ProductsAndServices);
