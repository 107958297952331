import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import ContractInfoForm from "../../../components/pages/contracts/ContractInfoForm";
import ContractInvoices from "../../../components/pages/contracts/ContractInvoices";
import ContractItems from "../../../components/pages/contracts/ContractItems";
import { ADMIN, COMUNICATIONS, EXTERNAL, GENERAL } from "../../../helpers/constants";
import { checkPermissionByRoles } from "../../../helpers/utils";
import { getContractById } from "../../../store/contracts/actions";

const ContractForm = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [infoLoader, setInfoLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setInfoLoader(true);
      dispatch(getContractById(id)).then((res) => {
        if (!res) {
          history.push(`/contracts/contracts`);
        }
        setInfoLoader(false);
      });
    }
  }, [dispatch, id, history]);

  // navigate to the customer's situation page.
  const navigateToContractSituation = () => {
    history.push(`/contracts/contracts/${id}/contract-situation`);
  };

  document.title = `Veritas | ${props.t("sidebarMenu.contracts")}`;

  return (
    <div className="page-content layout-main-container">
      <div className="container-header justify-between">
        <CustomBreadcrumb
          data={[
            {
              label: props.t("sidebarMenu.contracts"),
              link: "/contracts/contracts",
            },
            {
              label: props.t(`${id ? "common.editTitle" : "common.addTitle"}`, {
                module: props.t("contracts.contracts.contract"),
              }),
            },
          ]}
        />
        <div className="contain-header-right d-flex flex-row gap-2">
          {id && checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) ? (
            <>
              <CustomButton btnTitle={props.t("common.contractSituation")} onClick={navigateToContractSituation} />
            </>
          ) : null}
        </div>
      </div>
      {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL, EXTERNAL]) && (
        <Card className="container-body card-section">
          {/* info form */}
          {infoLoader ? <Loader /> : null}
          <ContractInfoForm isEditable={Boolean(id)} />
        </Card>
      )}
      <Card className="container-body card-section">
        {/* items section */}
        <ContractItems isEditable={Boolean(id)} />
      </Card>
      {checkPermissionByRoles([ADMIN, COMUNICATIONS, GENERAL]) && (
        <Card className="container-body card-section">
          {/* items section */}
          <ContractInvoices isEditable={Boolean(id)} />
        </Card>
      )}
    </div>
  );
};

export default withTranslation()(ContractForm);
