import React from "react";

const CustomColumnHeader = ({
  columnTitle,
  sortable,
  sortableKey,
  orderBy,
  sortingOrder,
  setOrderBy,
  setSortingOrder,
}) => {
  return (
    <div
      className={`table-column-title mb-2 d-flex flex-row align-items-center ${
        sortable ? "sortable-column-header" : ""
      }`}
      onClick={
        sortable
          ? () => {
              setOrderBy(sortableKey);
              setSortingOrder((prevState) => {
                if (orderBy === sortableKey) {
                  return prevState === "asc" ? "desc" : prevState === "desc" ? "asc" : "asc";
                } else {
                  return "asc";
                }
              });
            }
          : null
      }
    >
      <span className="me-2">{columnTitle}</span>
      {sortable ? (
        orderBy !== sortableKey ? (
          <i className="ri-arrow-up-down-fill fs-18 sorting-icon" />
        ) : sortingOrder === "asc" ? (
          <i className="ri-arrow-up-fill fs-18 sorting-subicon" />
        ) : sortingOrder === "desc" ? (
          <i className="ri-arrow-down-fill fs-18 sorting-subicon" />
        ) : null
      ) : null}
    </div>
  );
};

export default CustomColumnHeader;
