/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import CustomButton from "../../common/CustomButton";
import Select from "react-select";
import { getCountriesOptions } from "../../../store/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddWarehouseModal = ({ warehouse, isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      description: warehouse?.description || "",
      address: warehouse?.address || "",
      post_code: warehouse?.post_code || "",
      city: warehouse?.city || "",
      country: warehouse?.country?.id ? warehouse?.country : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.description"),
        })
      ),
      address: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.address"),
        })
      ),
      post_code: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.postCode"),
        })
      ),
      city: Yup.string().required(
        t("validation.generalField", {
          promptType: t("common.enter"),
          fieldType: t("common.city"),
        })
      ),
      country: Yup.object().required(
        t("validation.generalField", {
          promptType: t("common.select"),
          fieldType: t("common.country"),
        })
      ),
    }),

    onSubmit: (values) => {
      onSave({ ...values, country: values?.country?.id || null }, validation);
    },
  });

  useEffect(() => {
    if (!isModalOpen) validation.resetForm();
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) dispatch(getCountriesOptions({ sort: "name" }));
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      onCancel();
    }
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>{`${warehouse?.id ? t("common.edit") : t("common.add")}  ${t(
        "sidebarMenu.warehouse"
      )}`}</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="description" className="form-label">
              {t("common.description")}*
            </Label>
            <Input
              name="description"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.description"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={validation.touched.description && validation.errors.description ? true : false}
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="address" className="form-label">
              {t("common.address")}*
            </Label>
            <Input
              name="address"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.address"),
              })}
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.address || ""}
              invalid={validation.touched.address && validation.errors.address ? true : false}
            />
            {validation.touched.address && validation.errors.address ? (
              <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="city" className="form-label">
              {t("common.city")}*
            </Label>
            <Input
              name="city"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.city"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.city || ""}
              invalid={validation.touched.city && validation.errors.city ? true : false}
            />
            {validation.touched.city && validation.errors.city ? (
              <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="post_code" className="form-label">
              {t("common.postCode")}*
            </Label>
            <Input
              name="post_code"
              className="form-control"
              placeholder={t("placeholder.enterField", {
                fieldType: t("common.postCode"),
              })}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.post_code || ""}
              invalid={validation.touched.post_code && validation.errors.post_code ? true : false}
            />
            {validation.touched.post_code && validation.errors.post_code ? (
              <FormFeedback type="invalid">{validation.errors.post_code}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">{t("common.country")}</Label>
            <Select
              name="country"
              options={contriesOptions}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              className={`custom-select ${
                validation.touched.country && validation.errors.country ? "select-error" : ""
              }`}
              placeholder={`${t("placeholder.selectField", {
                fieldType: t("common.country"),
              })}`}
              value={validation.values.country || ""}
              onChange={(country) => {
                validation.setFieldValue("country", country?.id ? country : "");
              }}
              onBlur={(e) => {
                validation.setFieldTouched("country", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.country && validation.errors.country ? (
              <p className="custom-invalid-feedback">{validation.errors.country}</p>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            btnTitle={t("common.cancel")}
            color="dark"
            onClick={handleModalClose}
            disabled={loader}
            type="button"
          />
          <CustomButton
            type="submit"
            btnTitle={t("common.save")}
            isLoading={loader}
            onClick={() => scrollToFirstFormikError(validation.errors)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddWarehouseModal);
