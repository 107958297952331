import React from "react";
import imagePlaceholder from "../../assets/images/veritas/image-fallback-placeholder.jpg";
import { isImage } from "../../helpers/utils";

const CustomColumnPhoto = ({ sourceUrl }) => {
  return sourceUrl === null || sourceUrl === undefined || !isImage(sourceUrl) ? (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 my-2">
        <img src={imagePlaceholder} alt="auction pictures" className="avatar-sm square rounded-start rounded-end" />
      </div>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 my-2">
        <img src={sourceUrl} alt="pictures" className="avatar-sm square rounded-start rounded-end" />
      </div>
    </div>
  );
};

export default CustomColumnPhoto;
