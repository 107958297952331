import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { SET_COLLABORATORS } from "../actionTypes";

// list all collaborators
export const getAuctionCollaborators = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/collaborators`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        filter: params?.filter,
        sort: params?.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_COLLABORATORS,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Add  collaborator
export const addCollaborator = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/collaborators`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Edit collaborator
export const editCollaborator = (collaboratorId, payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/collaborators/${collaboratorId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// delete collaborator
export const deleteCollaborator = (collaboratorId) => async (dispatch) => {
  try {
    return API.delete(`/auctions/collaborators/${collaboratorId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
