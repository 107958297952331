import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CustomAddButton from "../../../components/common/CustomAddButton";
import Loader from "../../../components/common/Loader";
import { getWebsitePages } from "../../../store/website-pages/actions";
import BuyingAndSelling from "./BuyingAndSelling";
import Contacts from "./Contacts";
import HomePage from "./HomePage";
import NewPage from "./NewPage";
import PrivateSales from "./PrivateSales";
import Services from "./Services";

const Pages = (props) => {
  const dispatch = useDispatch();
  const { pageId } = useParams();
  const history = useHistory();
  const { data: pagesData } = useSelector((state) => state?.Pages?.pages);
  const MENU_ITEMS = [
    { id: 1, label: props.t("common.homePage"), Component: HomePage },
    { id: 2, label: props.t("common.services"), Component: Services },
    { id: 3, label: props.t("common.privateSales"), Component: PrivateSales },
    {
      id: 4,
      label: props.t("common.buying&selling"),
      Component: BuyingAndSelling,
    },
    { id: 5, label: props.t("common.contacts"), Component: Contacts },
  ];
  const [menuItems, setMenuItems] = useState(MENU_ITEMS);
  const [pagesLoader, setPagesLoader] = useState(false);
  const [pagesPayload, setPagesPayload] = useState({
    sort: "id",
  });
  const [selectedPage, setSelectedPage] = useState(menuItems[0]);
  const PageComponent = selectedPage?.Component;

  // handle page selection
  const handlePageSelection = (page) => {
    history.push(`/website-management/pages/${page?.id}`);
  };

  // to set pages
  useEffect(() => {
    let additionalPages = [];
    pagesData?.slice(5).forEach((page) => {
      additionalPages.push({
        id: page?.id,
        label: page?.name,
        Component: NewPage,
      });
    });
    if (additionalPages?.length > 0) {
      setMenuItems((prevState) => [...MENU_ITEMS, ...additionalPages]);
    }
  }, [pagesData]);

  // to get the list of pages for website
  useEffect(() => {
    setPagesLoader(true);
    dispatch(getWebsitePages(pagesPayload)).then(() => {
      setPagesLoader(false);
    });
  }, [dispatch, pagesPayload]);

  useEffect(() => {
    const currentPage = menuItems?.find((item) => item?.id === Number(pageId));
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      history.push("/website-management/pages/1");
    }
  }, [pageId, menuItems, history]);

  document.title = `Veritas | ${props.t("sidebarMenu.pages")}`;

  return (
    <div className="page-content layout-main-container d-flex gap-2 website-pages-container">
      {/* pages sidebar */}
      {pagesLoader ? <Loader /> : null}
      <div className="file-manager-sidebar pages-sidebar">
        <div className="p-3 d-flex flex-column h-100">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 fw-semibold fs-4">{props.t("sidebarMenu.pages")}</h5>
              <CustomAddButton color={"success"} btnTitle={props.t("common.newPage")} />
            </div>
            <hr className="header-divider" />
          </div>

          <div className="mx-n4 px-4 pages-menu-sidebar-scroll">
            <ul className="list-unstyled pages-menu">
              {menuItems?.length > 0
                ? menuItems?.map((item, index) => {
                    return (
                      <div key={index}>
                        <li
                          key={index}
                          className={`cursor-pointer ${selectedPage?.id === item?.id ? "bg-soft-primary" : ""}`}
                          onClick={() => handlePageSelection(item)}
                        >
                          <div className={`menu-item ${selectedPage?.id === item?.id ? "active" : ""}`}>
                            <span className="file-list-link fs-5">{item?.label}</span>
                          </div>
                        </li>
                        {menuItems?.length - 1 !== index ? <hr /> : null}
                      </div>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
      {/* pages content */}
      <PageComponent selectedPage={selectedPage} />
    </div>
  );
};

export default withTranslation()(Pages);
