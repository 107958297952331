import { ADD_TEMPLATE, DELETE_TEMPLATE, SET_TEMPLATES_LIST, UPDATE_TEMPLATE } from "./actionTypes";

const INIT_STATE = {
  data: [],
  total: 0,
};

const Templates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TEMPLATES_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_TEMPLATE:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_TEMPLATE:
      const updatedTemplatesData = state.data;
      const existingTemplateIndex = state.data.findIndex((template) => template.id === action.payload.id);
      if (existingTemplateIndex !== -1) {
        updatedTemplatesData[existingTemplateIndex] = action.payload;
      }
      return {
        ...state,
        data: updatedTemplatesData,
      };
    case DELETE_TEMPLATE:
      return {
        ...state,
        data: [...state.data?.filter((template) => template.id !== action.payload)],
        total: state.total - 1,
      };
    default:
      return { ...state };
  }
};

export default Templates;
