import { FormikProvider, useFormik } from "formik";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

// import PhoneInput, {
//   formatPhoneNumber,
//   formatPhoneNumberIntl,
//   isValidPhoneNumber,
// } from "react-phone-number-input";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import CustomButton from "../../components/common/CustomButton";
import Loader from "../../components/common/Loader";
import PhoneNumberInput from "../../components/common/PhoneNumberInput";
import PersonaList from "../../components/pages/customers/PersonaList";
import { Option } from "../../components/pages/settings/AddUserModal";
import { ADMIN, COMUNICATIONS, MAX_DATE, TEXTAREA_DEFAULT_ROWS } from "../../helpers/constants";
import { LANGUAGE_OPTIONS, SELECT_ALL_OPTION } from "../../helpers/constants/selectOptions";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  getColumnDetail,
  getLabelValueArray,
  getValuesFromArray,
  onKeyPressForm,
  removeFieldsFromObj,
  scrollToFirstFormikError,
  sortByObjectsByName,
} from "../../helpers/utils";
import { getCountriesOptions } from "../../store/common/actions";
import { addCustomer, getCustomerDetails, getShippingOptions, updateCustomer } from "../../store/customers/actions";

import moment from "moment";
import { toast } from "react-toastify";
import MessageBlock from "../../components/common/MessageBlock";
import CatalogueSubscriptionTable from "../../components/pages/customers/CatalogueSubscriptionTable";
import GeneratePasswordModal from "../../components/pages/customers/GeneratePasswordModal";
import { getTitles } from "../../store/titles/actions";

const CustomerForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paramId } = useParams();

  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);
  const { selectedCustomerData: customerData } = useSelector((state) => state?.Customers);
  const shippingOptionList = useSelector((state) => state?.Customers?.shippingOptions?.results || []);
  const preferencesList = sortByObjectsByName(
    useSelector((state) => state?.common?.customerPreferences?.data),
    getColumnDetail("name_en", "name_pt")
  );
  const tagOptionList = useSelector((state) => state?.Customers?.tagOptions?.results || []);

  const pathname = window.location.pathname;

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (pathname.includes("edit")) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [pathname]);

  const handleSelectClick = () => {
    alert("Your user level is not allowed to edit this.");
  };

  // customer genral titles and communication titles
  const [generalTitles, setGeneralTitles] = useState([]);
  const [communicationTitles, setCommunicationTitles] = useState([]);

  // loaders
  const [customerSaveLoader, setCustomerSaveLoader] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);

  // form states
  const [selectedTags, setSelectedTags] = useState("");
  const [vatCheckbox, setVatCheckbox] = useState(false);
  const [selectedPostCards, setSelectedPostCards] = useState("");
  const [selectedPreference, setSelectedPreference] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedClientCountry, setSelectedClientCountry] = useState("");
  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] = useState(false);

  // handle open password generate modal
  const handleGeneratPasswordModalOpen = () => {
    setIsGeneratePasswordModalOpen(true);
  };
  // handle close password generate modal
  const handleGeneratPasswordModalClose = () => {
    setIsGeneratePasswordModalOpen(false);
  };

  const setAllDropDowns = useCallback(() => {
    setSelectedTags(
      getLabelValueArray(
        tagOptionList.filter(
          (list) => customerData && customerData?.tags && customerData?.tags?.some((item) => item.id === list.id)
        ),
        "id",
        getColumnDetail("name_en", "name_pt")
      )
    );

    if (paramId) {
      setSelectedPostCards(
        getLabelValueArray(
          shippingOptionList.filter(
            (list) =>
              customerData && customerData?.postcards && customerData?.postcards?.some((item) => item.id === list.id)
          ),
          "id",
          getColumnDetail("name_en", "name_pt")
        )
      );
      setSelectedClientCountry(
        getLabelValueArray(contriesOptions, "id", "name").find(
          (item) => item.value === (customerData && customerData?.addresses && customerData?.addresses[0]?.country?.id)
        ) || ""
      );
    } else {
      setSelectedPostCards(getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")));
      setSelectedClientCountry(
        getLabelValueArray(contriesOptions, "id", "name").find((item) => item.label === "Portugal") || ""
      );
    }
    setSelectedPreference(
      getLabelValueArray(
        preferencesList.filter(
          (list) =>
            customerData && customerData?.preferences && customerData?.preferences?.some((item) => item.id === list.id)
        ),
        "id",
        getColumnDetail("name_en", "name_pt")
      )
    );
    setSelectedLanguage(LANGUAGE_OPTIONS.find((item) => item.value === customerData?.language) || "");
  }, [contriesOptions, customerData, shippingOptionList, tagOptionList, preferencesList]);

  // // for reseting form
  const handleResetForm = useCallback(() => {
    setAllDropDowns();
    validation.resetForm();
  }, [setAllDropDowns]);

  const navigateToCustomerListPage = useCallback(() => {
    history.push("/customers");
  }, [history]);

  // navigate to the customer's docuemnt page.
  const navigateToCustomerDocuments = () => {
    history.push(`/customers/${paramId}/checking-account`);
  };

  // navigate to the customer's situation page.
  const navigateToCustomerSituation = () => {
    history.push(`/customers/${paramId}/customer-situation`);
  };

  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "shipping" },
      })
    );
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "tag" },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  useEffect(() => {
    if (paramId) {
      // get details of cusomer from customer id in url
      setFetchLoader(true);
      dispatch(getCustomerDetails({ customerId: paramId }))
        .then((res) => {
          if (res) {
            setFetchLoader(false);
          } else {
            setFetchLoader(false);
            navigateToCustomerListPage();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch, paramId, navigateToCustomerListPage]);

  const handleSaveCustomer = (values) => {
    if (!values?.is_seller && !values?.is_buyer) {
      return toast.error(props.t("validation.customerType"));
    }
    // to only assign value not reference
    const formValues = Object.assign({}, values);

    // for edit user
    if (paramId) {
      let addresses = [
        {
          id: customerData && customerData?.addresses && customerData?.addresses[0]?.id,
          address_type: "client",
          address: formValues.client_address,
          post_code: formValues.client_post_code,
          city: formValues.client_city,
          country_id: formValues.client_country,
        },
      ];
      let cusPayload = removeFieldsFromObj(formValues, [
        "client_address",
        "client_post_code",
        "client_city",
        "client_country",
      ]);
      cusPayload.addresses = addresses;
      setCustomerSaveLoader(true);
      dispatch(
        updateCustomer({
          id: customerData && customerData?.id,
          payload: {
            ...cusPayload,
            title_comm: cusPayload?.title_comm?.id || null,
            title_gen: cusPayload?.title_gen?.id || null,
          },
        })
      )
        .then((res) => {
          if (res) {
            setCustomerSaveLoader(false);
            handleResetForm();
            navigateToCustomerListPage();
          }
          setCustomerSaveLoader(false);
        })
        .catch(() => {
          setCustomerSaveLoader(false);
        });
    }
    // add user
    else {
      let addresses = [
        {
          address_type: "client",
          address: formValues.client_address,
          post_code: formValues.client_post_code,
          city: formValues.client_city,
          country_id: formValues.client_country,
        },
      ];
      let cusPayload = removeFieldsFromObj(formValues, [
        "client_address",
        "client_post_code",
        "client_city",
        "client_country",
      ]);
      cusPayload.addresses = addresses;

      setCustomerSaveLoader(true);
      dispatch(
        addCustomer({
          ...cusPayload,
          title_comm: cusPayload?.title_comm?.id || null,
          title_gen: cusPayload?.title_gen?.id || null,
        })
      )
        .then((res) => {
          if (res) {
            setCustomerSaveLoader(false);
            handleResetForm();
            navigateToCustomerListPage();
          }
          setCustomerSaveLoader(false);
        })
        .catch(() => {
          setCustomerSaveLoader(false);
        });
    }
  };

  // to set all state of form
  useEffect(() => {
    if (!paramId) {
      return;
    }
    setAllDropDowns();
  }, [paramId, setAllDropDowns]);

  // to get the list of the general titles
  useEffect(() => {
    dispatch(
      getTitles({
        filter: { title_type: "general" },
        sort: getColumnDetail("title_en", "title_pt"),
      })
    ).then((res) => {
      if (res?.results) setGeneralTitles(res?.results);
    });
  }, [dispatch]);

  // to get the list of the communication titles
  useEffect(() => {
    dispatch(
      getTitles({
        filter: { title_type: "communication" },
        sort: getColumnDetail("title_en", "title_pt"),
      })
    ).then((res) => {
      if (res?.results) setCommunicationTitles(res?.results);
    });
  }, [dispatch]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      title_comm: customerData?.title_comm?.id ? customerData?.title_comm : "",
      title_gen: customerData?.title_gen?.id ? customerData?.title_gen : "",
      name: customerData?.name || "",
      surname: customerData?.surname || "",
      birth_date: customerData?.birth_date || null,
      client_vat: customerData?.client_vat || "",
      passport_no: customerData?.passport_no || "",
      iban_no: customerData?.iban_no || "",
      contact_1: customerData?.contact_1 || "",
      contact_2: customerData?.contact_2 || "",
      email: customerData?.email || "",
      client_address: (customerData && customerData?.addresses && customerData?.addresses[0]?.address) || "",
      client_post_code: (customerData && customerData?.addresses && customerData?.addresses[0]?.post_code) || "",
      client_city: (customerData && customerData?.addresses && customerData?.addresses[0]?.city) || "",
      client_country:
        (customerData && customerData?.addresses && customerData?.addresses[0]?.country?.id) ||
        contriesOptions?.find((country) => country?.code === "PT")?.id,
      tags: (customerData && customerData?.tags && customerData?.tags?.map((item) => item?.id)) || [],
      postcards: (customerData && customerData?.postcards && customerData?.postcards.map((item) => item?.id)) || [
        ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")).map(
          (item) => item?.value
        ),
      ],
      catalogue_shippings:
        paramId && customerData?.catalogue_shippings?.length > 0
          ? customerData?.catalogue_shippings?.map((item) => ({
              ...item,
              catalogues: item?.catalogues?.map((item) => item?.id),
            }))
          : [],
      preferences:
        (customerData && customerData?.preferences && customerData?.preferences.map((item) => item?.id)) || [],
      language: customerData?.language || "",
      internal_observations: customerData?.internal_observations || "",
      is_active: paramId ? (customerData?.is_active ? true : false) : true,
      is_buyer: paramId ? (customerData?.is_buyer ? true : false) : false,
      is_seller: paramId ? (customerData?.is_seller ? true : false) : false,
      is_subscribed_to_newsletter: paramId ? customerData?.is_subscribed_to_newsletter : true,
      is_subscribed_to_catalog: paramId ? customerData?.is_subscribed_to_catalog : false,
      is_received_text: paramId ? customerData?.is_received_text : true,
      is_email_verified: paramId ? customerData?.is_email_verified : false,
      catalogue_subscriptions:
        paramId && customerData?.catalogue_subscriptions?.length > 0
          ? customerData?.catalogue_subscriptions?.map((item) => ({
              ...item,
              catalogues: item?.catalogues?.map((item) => item?.id),
            }))
          : [],
    },
    validationSchema: Yup.object({
      title_comm: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.title")}`
      ),
      title_gen: Yup.object().required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.title")}`
      ),
      name: Yup.string()
        .trim()
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.name")}`),
      surname: Yup.string()
        .trim()
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.surname")}`),
      client_vat: Yup.string()
        .max(20, props.t("validation.maxChars", { totalChars: 20 }))
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.vat")}`),
      passport_no: Yup.string()
        .trim()
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.idOrpassport")}`),
      iban_no: Yup.string()
        .trim()
        .max(35, `${props.t("validation.numberMax", { totalNumber: 35 })}`),
      contact_1: Yup.number().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.contact1")}`
      ),

      email: Yup.string()
        .trim()
        .email(`${props.t("settings.general.users.emailValidationMag1")}`)
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.email")}`),
      tags: Yup.array(),
      postcards: Yup.array(),
      preferences: Yup.array(),
      language: Yup.string()
        .trim()
        .required(
          props.t("validation.selectValidationMsg", {
            field: `${props.t("settings.general.language.language")}`,
          })
        ),
      internal_observations: Yup.string().trim(),
      client_city: Yup.string()
        .trim()
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("settings.general.report.city")}`),
      client_country: Yup.string()
        .trim()
        .required(
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("settings.general.report.country")}`
        ),
      client_post_code: Yup.string()
        .trim()
        .matches(/^\S*$/, { message: `${props.t("common.enterNoSpaces")}` })
        .max(15, `${props.t("validation.numberMax", { totalNumber: 15 })}`)
        .required(
          `${props.t("common.please")} ${props.t("common.enter")} ${props.t("settings.general.report.postCode")}`
        ),
      client_address: Yup.string()
        .trim()
        .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.address")}`),
      is_active: Yup.boolean().oneOf(
        [true, false],
        `${props.t("common.selectValidationMsg", {
          field: `${props.t("common.status")}`,
        })}`
      ),
      catalogue_subscriptions: Yup.array().of(
        Yup.object().shape({
          catalogues: Yup.array()
            .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
            .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
          start_date: Yup.date()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.select"),
                field: props.t("common.date"),
              })
            )
            .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
            .test(
              "maximum-year",
              props.t("validation.yearMax", {
                label: `9999`,
              }),
              (value) => {
                return value < moment(MAX_DATE);
              }
            ),
          end_date: Yup.date()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.select"),
                field: props.t("common.date"),
              })
            )
            .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
            .test(
              "minimum-datetime",
              props.t("validation.dateMin", {
                label: props.t("common.startDate"),
              }),
              (value, context) => {
                if (!context?.from?.[0]?.value?.start_date) {
                  return true;
                }
                return value > new Date(context?.from?.[0]?.value?.start_date);
              }
            )
            .test(
              "maximum-year",
              props.t("validation.yearMax", {
                label: `9999`,
              }),
              (value) => {
                return value < moment(MAX_DATE);
              }
            ),
        })
      ),
      catalogue_shippings: Yup.array().of(
        Yup.object().shape({
          catalogues: Yup.array()
            .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
            .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
        })
      ),
    }),
    onSubmit: (values, validation) => {
      handleSaveCustomer(values, validation);
    },
  });

  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key, setState) => {
    if (checkSelectAllSelected(selectedItem)) {
      setState([...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt"))]);
      validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value)
      );
    } else {
      if (selectedItem.length > 0) {
        setState(selectedItem);
        validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        setState("");
        validation.setFieldValue(key, []);
      }
    }
  };

  // get the customer history data
  const getCustomerHistoryData = (customerHistory) => {
    if (customerHistory?.action === "change_customer") {
      const data = [];
      const keys = [
        { value: "address", label: "customerHistory.changeInAddress" },
        { value: "city", label: "customerHistory.changeInCity" },
        {
          value: "contact_1",
          label: "customerHistory.changeInContact1",
          remove: "customerHistory.removedContact1",
          new: "customerHistory.newContact1",
        },
        {
          value: "contact_2",
          label: "customerHistory.changeInContact2",
          remove: "customerHistory.removedContact2",
          new: "customerHistory.newContact2",
        },
        {
          value: "country",
          label: "customerHistory.changeInCountry",
        },
        { value: "email", label: "customerHistory.changeInEmail" },
        {
          value: "iban_no",
          label: "customerHistory.changeInIban",
          remove: "customerHistory.removedIBAN",
          new: "customerHistory.newIBAN",
        },
        {
          value: "passport_no",
          label: "customerHistory.changeInId/Passport",
        },
        { value: "post_code", label: "customerHistory.changeInPostCode" },
      ];
      keys.forEach((key, index) => {
        if (customerHistory?.metadata?.[`old_${key?.value}`] && !customerHistory?.metadata?.[`new_${key?.value}`]) {
          data.push(
            props.t(key?.remove, {
              old: customerHistory?.metadata?.[`old_${key?.value}`],
            })
          );
        } else if (
          !customerHistory?.metadata?.[`old_${key?.value}`] &&
          customerHistory?.metadata?.[`new_${key?.value}`]
        ) {
          data.push(
            props.t(key?.new, {
              new: customerHistory?.metadata?.[`new_${key?.value}`],
            })
          );
        } else if (customerHistory?.metadata?.[`old_${key?.value}`] || customerHistory?.metadata?.[`new_${key?.value}`])
          data.push(
            props.t(key?.label, {
              old: customerHistory?.metadata?.[`old_${key?.value}`],
              new: customerHistory?.metadata?.[`new_${key?.value}`],
            })
          );
      });
      return (
        <>
          {data?.map((item, index) => (
            <Fragment key={index}>
              {item}
              <br />
            </Fragment>
          ))}
        </>
      );
    }
  };

  const CardHeaderFooter = () => (
    <div className="d-flex flex-row justify-content-end">
      <Button
        color="dark"
        disabled={customerSaveLoader}
        onClick={() => {
          if (JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)) {
            if (window.confirm(props.t("confirmation.resetMessage"))) {
              handleResetForm();
            }
          }
        }}
      >
        {props.t("common.reset")}
      </Button>
      <Button
        color="primary"
        disabled={customerSaveLoader}
        className="btn-load d-flex ms-2"
        onClick={() => scrollToFirstFormikError(validation.errors)}
        type="submit"
      >
        {customerSaveLoader ? (
          <span className="d-flex align-items-center ">
            <Spinner size="xs" className="flex-shrink-0"></Spinner>
          </span>
        ) : null}
        <span className={`flex-grow-1 ${customerSaveLoader ? "ms-2" : ""}`}>{props.t("common.save")}</span>
      </Button>
    </div>
  );

  return (
    <>
      <Prompt
        when={
          !validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomBreadcrumb
            data={[
              {
                label: props.t("sidebarMenu.customers"),
                link: "/customers",
              },
              {
                label: props.t(`${paramId ? "common.editTitle" : "common.addTitle"}`, {
                  module: props.t("customers.customer"),
                }),
              },
            ]}
          />
          <div className="contain-header-right d-flex flex-row gap-2">
            {paramId ? (
              <>
                <CustomButton
                  color={"success"}
                  btnTitle={props.t("common.generatePassword")}
                  onClick={handleGeneratPasswordModalOpen}
                />
                <CustomButton btnTitle={props.t("common.checkingAccount")} onClick={navigateToCustomerDocuments} />
                <CustomButton btnTitle={props.t("common.customerSituation")} onClick={navigateToCustomerSituation} />
              </>
            ) : null}
          </div>
        </div>

        <Card className="container-body">
          {fetchLoader ? <Loader /> : null}
          {/* customer form */}
          <FormikProvider value={validation}>
            <Form
              onKeyPress={onKeyPressForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <CardHeader>
                <CardHeaderFooter />
              </CardHeader>
              <CardBody>
                {/* {id && ( */}
                <Row>
                  <Col className="col-sm-12 col-md-6 col-xl-4">
                    <div className="mb-3">
                      <Label htmlFor="customer_no" className="form-label">
                        {`${props.t("customers.customer")} ${props.t("common.no_")}`}
                      </Label>
                      <Input
                        disabled
                        className="form-control"
                        placeholder={paramId && customerData?.id ? customerData?.customer_no : "****"}
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-12 col-md-1 col-xl-4"></Col>
                  <Col className="col-sm-12 col-md-5 col-xl-4">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="active user" className="form-label">
                            {`${props.t("common.activeCustomer")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_active"
                              name="is_active"
                              // defaultChecked={user ? user?.is_active : true}
                              checked={validation?.values?.is_active}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_active}
                              invalid={validation.touched.is_active && validation.errors.is_active && true}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">{`${props.t("common.emailVerified")}`}</Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_email_verified"
                              name="is_email_verified"
                              checked={validation?.values?.is_email_verified}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_email_verified}
                              invalid={
                                validation.touched.is_email_verified && validation.errors.is_email_verified && true
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* )} */}
                {/* for customer */}
                <Row>
                  {/* 1st column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* title communication field */}
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label">{`${props.t("common.titleForCommunication")}*`}</Label>
                        <Select
                          name="title_comm"
                          options={communicationTitles}
                          getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
                          getOptionValue={(option) => option?.id}
                          value={validation?.values?.title_comm}
                          className={`custom-select ${
                            validation.touched.title_comm && validation.errors.title_comm ? "select-error" : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("common.title")}`}
                          onChange={(title) => {
                            validation.setFieldValue("title_comm", title || "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("title_comm", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.title_comm && validation.errors.title_comm ? (
                          <span className="custom-invalid-feedback">{validation.errors.title_comm}</span>
                        ) : null}
                      </div>
                    </Row>
                    {/* title general field */}
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label">{`${props.t("common.title")}*`}</Label>
                        <Select
                          name="title_gen"
                          options={generalTitles}
                          getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
                          getOptionValue={(option) => option?.id}
                          value={validation?.values?.title_gen}
                          className={`custom-select ${
                            validation.touched.title_gen && validation.errors.title_gen ? "select-error" : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("common.title")}`}
                          onChange={(title) => {
                            validation.setFieldValue("title_gen", title || "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("title_gen", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.title_gen && validation.errors.title_gen ? (
                          <span className="custom-invalid-feedback">{validation.errors.title_gen}</span>
                        ) : null}
                      </div>
                    </Row>
                    {/* name field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          {`${props.t("common.name")}*`}
                        </Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.name")}`}
                          type="text"
                          value={validation.values.name || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.name && validation.errors.name ? true : false}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* surname field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="surname" className="form-label">
                          {`${props.t("common.surname")}*`}
                        </Label>
                        <Input
                          name="surname"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.surname")}`}
                          type="text"
                          value={validation.values.surname || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.surname && validation.errors.surname ? true : false}
                        />
                        {validation.touched.surname && validation.errors.surname ? (
                          <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* birthday field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="birthday" className="form-label">
                          {`${props.t("common.birthDate")}`}
                        </Label>
                        <Input
                          name="birth_date"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.birthDate")}`}
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.birth_date || ""}
                          invalid={validation.touched.birth_date && validation.errors.birth_date ? true : false}
                        />
                        {validation.touched.birth_date && validation.errors.birth_date ? (
                          <FormFeedback type="invalid">{validation.errors.birth_date}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* client_vat field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="birthday" className="form-label">
                          {`${props.t("common.vat")}*`}
                        </Label>
                        <Input
                          name="client_vat"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.vat")}`}
                          type="text"
                          value={validation.values.client_vat}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.client_vat && validation.errors.client_vat ? true : false}
                        />
                        {validation.touched.client_vat && validation.errors.client_vat ? (
                          <FormFeedback type="invalid">{validation.errors.client_vat}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label">No VAT number</Label>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            id="setVatCheckbox"
                            name="setVatCheckbox"
                            checked={vatCheckbox}
                            onChange={(e) => {
                              setVatCheckbox(e.target.checked);
                              if (e.target.checked) {
                                validation.setFieldValue("client_vat", "999999990");
                              } else {
                                validation.setFieldValue("client_vat", ""); // Reset to empty if unchecked
                              }
                            }}
                            className="form-check-input"
                          />
                        </div>
                      </div>
                    </Row>
                    {/* id/passport passport_no field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="passport_no" className="form-label">
                          {`${props.t("common.idOrpassport")}*`}
                        </Label>
                        <Input
                          name="passport_no"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.idOrpassport")}`}
                          type="text"
                          value={validation.values.passport_no || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.passport_no && validation.errors.passport_no ? true : false}
                        />
                        {validation.touched.passport_no && validation.errors.passport_no ? (
                          <FormFeedback type="invalid">{validation.errors.passport_no}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* iban_no field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="iban_no" className="form-label">
                          {`${props.t("common.iban")}`}
                        </Label>
                        <Input
                          name="iban_no"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.iban")}`}
                          type="text"
                          value={validation.values.iban_no || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.iban_no && validation.errors.iban_no ? true : false}
                        />
                        {validation.touched.iban_no && validation.errors.iban_no ? (
                          <FormFeedback type="invalid">{validation.errors.iban_no}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {paramId ? (
                      <Row>
                        <div className="mb-3">
                          <Label className="form-label">{props.t("common.customerHistory")}</Label>
                          <div className="details-border-box">
                            <div className="p-1 px-2 fs-13">
                              {props.t("customerHistory.accountCreatedAt", {
                                date: moment(customerData?.created_at).format("DD-MM-YYYY, HH:mm"),
                              })}
                            </div>
                            {customerData?.customer_histories?.length > 0 ? (
                              <>
                                <hr />
                                {customerData?.customer_histories?.map((customerHistory, index) => (
                                  <div key={index}>
                                    <div className="p-1 px-2 fs-13">{getCustomerHistoryData(customerHistory)}</div>
                                    {index !== customerData?.customer_histories?.length - 1 && <hr />}
                                  </div>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Row>
                    ) : null}
                  </Col>
                  {/* 2nd column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* contact 1 */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="contact_1" className="form-label">
                          {`${props.t("common.contact1")}*`}
                        </Label>
                        <PhoneNumberInput
                          isValid={!(validation.touched.contact_1 && validation.errors.contact_1)}
                          value={validation?.values?.contact_1}
                          onChange={(value) => {
                            validation.setFieldValue("contact_1", value);
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("contact_1", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.contact_1 && validation.errors.contact_1 ? (
                          <p className="custom-invalid-feedback">{validation.errors.contact_1}</p>
                        ) : null}
                      </div>
                    </Row>
                    {/* contact-2 */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="contact_2" className="form-label">
                          {`${props.t("common.contact2")}`}
                        </Label>
                        <PhoneNumberInput
                          isValid={!(validation.touched.contact_2 && validation.errors.contact_2)}
                          value={validation?.values?.contact_2}
                          onChange={(value) => {
                            validation.setFieldValue("contact_2", value);
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("contact_2", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.contact_2 && validation.errors.contact_2 ? (
                          <p className="custom-invalid-feedback">{validation.errors.contact_2}</p>
                        ) : null}
                      </div>
                    </Row>
                    {/* email */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {`${props.t("common.email")}*`}
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.email")}`}
                          type="email"
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* client address field*/}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="client_address" className="form-label">
                          {`${props.t("common.address")}*`}
                        </Label>
                        <Input
                          name="client_address"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.address")}`}
                          type="text"
                          value={validation.values.client_address || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.client_address && validation.errors.client_address ? true : false}
                        />
                        {validation.touched.client_address && validation.errors.client_address ? (
                          <FormFeedback type="invalid">{validation.errors.client_address}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* client_post_code field*/}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="client_post_code" className="form-label">
                          {`${props.t("settings.general.report.postCode")}*`}
                        </Label>
                        <Input
                          name="client_post_code"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.postCode")}`}
                          type="text"
                          value={validation.values.client_post_code || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.client_post_code && validation.errors.client_post_code ? true : false
                          }
                        />
                        {validation.touched.client_post_code && validation.errors.client_post_code ? (
                          <FormFeedback type="invalid">{validation.errors.client_post_code}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* client_city field*/}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="client_city" className="form-label">
                          {`${props.t("settings.general.report.city")}*`}
                        </Label>
                        <Input
                          name="client_city"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.city")}`}
                          type="text"
                          value={validation.values.client_city || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.client_city && validation.errors.client_city ? true : false}
                        />
                        {validation.touched.client_city && validation.errors.client_city ? (
                          <FormFeedback type="invalid">{validation.errors.client_city}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* client_country field*/}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="client_country" className="form-label">
                          {`${props.t("settings.general.report.country")}*`}
                        </Label>
                        <Select
                          name="client_country"
                          options={getLabelValueArray(contriesOptions, "id", "name")}
                          value={selectedClientCountry}
                          className={`custom-select ${
                            validation.touched.client_country && validation.errors.client_country ? "select-error" : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("settings.general.report.country")}`}
                          onChange={(country) => {
                            setSelectedClientCountry(country);
                            validation.setFieldValue("client_country", country?.value || "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("client_country", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.client_country && validation.errors.client_country ? (
                          <span className="custom-invalid-feedback">{validation.errors.client_country}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  {/* 3rd column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* tags field */}
                    {isEdit ? (
                      <>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="active user" className="form-label">
                                {`${props.t("common.buyer")}`}
                              </Label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  role="switch"
                                  id="is_buyer"
                                  name="is_buyer"
                                  checked={validation?.values?.is_buyer}
                                  className="form-check-input"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.is_buyer}
                                  invalid={validation.touched.is_buyer && validation.errors.is_buyer && true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="active user" className="form-label">
                                {`${props.t("common.seller")}`}
                              </Label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  role="switch"
                                  id="is_seller"
                                  name="is_seller"
                                  checked={validation?.values?.is_seller}
                                  className="form-check-input"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.is_seller}
                                  invalid={validation.touched.is_seller && validation.errors.is_seller && true}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="tags" className="form-label">
                              {props.t("common.tags_capital")}
                            </Label>
                            <Select
                              name="tags"
                              options={getLabelValueArray(tagOptionList, "id", getColumnDetail("name_en", "name_pt"))}
                              value={selectedTags}
                              placeholder={`${props.t("common.select")} ${props.t("common.tags_capital")}`}
                              onChange={(tag) => {
                                if (checkPermissionByRoles([ADMIN, COMUNICATIONS])) {
                                  setSelectedTags(tag);
                                  validation.setFieldValue("tags", getValuesFromArray(tag));
                                }
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("tags", true);
                                validation.handleBlur(e);
                              }}
                              components={{ Option }}
                              isMulti={true}
                              hideSelectedOptions={false}
                              closeMenuOnSelect={false}
                              // isDisabled={
                              //   !checkPermissionByRoles([ADMIN, COMUNICATIONS])
                              // }
                              onMenuOpen={!checkPermissionByRoles([ADMIN]) ? handleSelectClick : undefined}
                            />
                          </div>
                        </Row>{" "}
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="active user" className="form-label">
                                {`${props.t("common.buyer")}`}
                              </Label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  role="switch"
                                  id="is_buyer"
                                  name="is_buyer"
                                  checked={validation?.values?.is_buyer}
                                  className="form-check-input"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.is_buyer}
                                  invalid={validation.touched.is_buyer && validation.errors.is_buyer && true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="active user" className="form-label">
                                {`${props.t("common.seller")}`}
                              </Label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  role="switch"
                                  id="is_seller"
                                  name="is_seller"
                                  checked={validation?.values?.is_seller}
                                  className="form-check-input"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.is_seller}
                                  invalid={validation.touched.is_seller && validation.errors.is_seller && true}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="tags" className="form-label">
                              {props.t("common.tags_capital")}
                            </Label>
                            <Select
                              name="tags"
                              options={getLabelValueArray(tagOptionList, "id", getColumnDetail("name_en", "name_pt"))}
                              value={selectedTags}
                              placeholder={`${props.t("common.select")} ${props.t("common.tags_capital")}`}
                              onChange={(tag) => {
                                {
                                  setSelectedTags(tag);
                                  validation.setFieldValue("tags", getValuesFromArray(tag));
                                }
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("tags", true);
                                validation.handleBlur(e);
                              }}
                              components={{ Option }}
                              isMulti={true}
                              hideSelectedOptions={false}
                              closeMenuOnSelect={false}
                              isDisabled={false}
                            />
                          </div>
                        </Row>
                      </>
                    )}
                    {/* postcards field */}
                    {isEdit ? (
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="postcards" className="form-label">
                            {`${props.t("common.postcardsShipping")}`}
                          </Label>
                          <Select
                            name="postcards"
                            options={[
                              SELECT_ALL_OPTION,
                              ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                            ]}
                            value={selectedPostCards || []}
                            placeholder={`${props.t("common.select")} ${props.t("customers.postCards")}`}
                            onChange={(postCard) => {
                              if (checkPermissionByRoles([ADMIN, COMUNICATIONS])) {
                                handleMultiSelectDropdown(
                                  postCard,
                                  getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                                  "postcards",
                                  setSelectedPostCards
                                );
                              }
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("postcards", true);
                              validation.handleBlur(e);
                            }}
                            components={{ Option }}
                            isMulti={true}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            // isDisabled={
                            //   !checkPermissionByRoles([ADMIN, COMUNICATIONS])
                            // }
                            onMenuOpen={!checkPermissionByRoles([ADMIN]) ? handleSelectClick : undefined}
                          />
                        </div>
                      </Row>
                    ) : (
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="postcards" className="form-label">
                            {`${props.t("common.postcardsShipping")}`}
                          </Label>
                          <Select
                            name="postcards"
                            options={[
                              SELECT_ALL_OPTION,
                              ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                            ]}
                            value={selectedPostCards || []}
                            placeholder={`${props.t("common.select")} ${props.t("customers.postCards")}`}
                            onChange={(postCard) => {
                              {
                                handleMultiSelectDropdown(
                                  postCard,
                                  getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                                  "postcards",
                                  setSelectedPostCards
                                );
                              }
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("postcards", true);
                              validation.handleBlur(e);
                            }}
                            components={{ Option }}
                            isMulti={true}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            isDisabled={false}
                          />
                        </div>
                      </Row>
                    )}
                    {/* news letter and subscription switches */}
                    <Row className="mb-3">
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">{`${props.t("common.newsletterSubscription")}`}</Label>
                          <div className="form-check form-switch ">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_subscribed_to_newsletter"
                              name="is_subscribed_to_newsletter"
                              checked={validation?.values?.is_subscribed_to_newsletter}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_subscribed_to_newsletter}
                              invalid={
                                validation.touched.is_subscribed_to_newsletter &&
                                validation.errors.is_subscribed_to_newsletter &&
                                true
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      {/* <Col>
                        <div className="mb-3">
                          <Label className="form-label">
                            {`${props.t("common.subscriptionToCatalogues")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_subscribed_to_catalog"
                              name="is_subscribed_to_catalog"
                              checked={
                                validation?.values?.is_subscribed_to_catalog
                              }
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_subscribed_to_catalog}
                              invalid={
                                validation.touched.is_subscribed_to_catalog &&
                                validation.errors.is_subscribed_to_catalog &&
                                true
                              }
                            />
                          </div>
                        </div>
                      </Col> */}
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">{`${props.t("common.receivesTextMessage")}`}</Label>
                          <div className="form-check form-switch ">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_received_text"
                              name="is_received_text"
                              checked={validation?.values?.is_received_text}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_received_text}
                              invalid={
                                validation.touched.is_received_text && validation.errors.is_received_text && true
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* Receives Text Message switch */}
                    {/* <Row className="mb-3">
                    </Row> */}
                    {/* preference field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="preferences" className="form-label">
                          {`${props.t("common.preferences")}`}
                        </Label>
                        <Select
                          name="preferences"
                          options={[
                            SELECT_ALL_OPTION,
                            ...getLabelValueArray(preferencesList, "id", getColumnDetail("name_en", "name_pt")),
                          ]}
                          value={selectedPreference || []}
                          placeholder={`${props.t("common.select")} ${props.t("common.preferences")}`}
                          onChange={(preference) => {
                            handleMultiSelectDropdown(
                              preference,
                              getLabelValueArray(preferencesList, "id", getColumnDetail("name_en", "name_pt")),
                              "preferences",
                              setSelectedPreference
                            );
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("preferences", true);
                            validation.handleBlur(e);
                          }}
                          components={{ Option }}
                          isMulti={true}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </Row>
                    {/* language field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="language" className="form-label">
                          {`${props.t("settings.general.language.language")}*`}
                        </Label>
                        <Select
                          name="language"
                          options={LANGUAGE_OPTIONS}
                          value={selectedLanguage}
                          className={`custom-select ${
                            validation.touched.language && validation.errors.language ? "select-error" : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("settings.general.language.language")}`}
                          onChange={(lang) => {
                            setSelectedLanguage(lang);
                            validation.setFieldValue("language", lang?.value || "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("language", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.language && validation.errors.language ? (
                          <span className="custom-invalid-feedback">{validation.errors.language}</span>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      {/* international_onservations field */}
                      <div className="mb-3">
                        <Label htmlFor="internal_observations" className="form-label">
                          {props.t("common.internalObservations")}
                        </Label>
                        <Input
                          name="internal_observations"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.internalObservations")}`}
                          type="textarea"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.internal_observations}
                          invalid={
                            validation.touched.internal_observations && validation.errors.internal_observations
                              ? true
                              : false
                          }
                          rows={TEXTAREA_DEFAULT_ROWS}
                        />
                        {validation.touched.internal_observations && validation.errors.internal_observations ? (
                          <FormFeedback type="invalid">{validation.errors.internal_observations}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-12 col-md-12 col-xl-6">
                    <div className="mb-3">
                      <Label className="form-label">{props.t("common.cataloguesShipping")}</Label>
                      <CatalogueSubscriptionTable
                        validation={validation}
                        arrayKey="catalogue_shippings"
                        catalogueShipping
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-12 col-md-12 col-xl-6">
                    <div className="mb-3">
                      <Label className="form-label">{props.t("common.subscriptionToCatalogues")}</Label>
                      <CatalogueSubscriptionTable validation={validation} arrayKey="catalogue_subscriptions" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <CardHeaderFooter />
              </CardFooter>
            </Form>
          </FormikProvider>
        </Card>

        {/* person CRUD */}
        <PersonaList />
      </div>
      {/* password generate modal */}
      <GeneratePasswordModal
        customer={customerData}
        isModalOpen={isGeneratePasswordModalOpen}
        onCancel={handleGeneratPasswordModalClose}
      />
    </>
  );
};

export default withTranslation()(CustomerForm);
