import CryptoJS from "crypto-js";

/**
 * Retrieves the key and IV used for encryption/decryption.
 * @return {Object} An object containing the key and IV.
 */
const getKeyAndIV = () => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_BE_DECRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_BE_DECRYPTION_IV);
  return { key, iv };
};

/**
 * Encrypts the provided payload using AES encryption with a generated key and initialization vector (IV).
 * @param {Object} payload - The payload to be encrypted
 * @return {string} The encrypted payload as a string
 */
export const encryptPayload = (payload) => {
  const { key, iv } = getKeyAndIV();
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), key, {
    mode: CryptoJS.mode.CFB,
    iv: iv,
    padding: CryptoJS.pad.NoPadding,
  });

  return encrypted.toString();
};

/**
 * Decrypts the given response using AES encryption with the provided key and IV.
 * @param {Object} response - The encrypted response to be decrypted
 * @return {Object} The decrypted response
 */
export const decryptResponse = (response) => {
  const { key, iv } = getKeyAndIV();
  const decrypted = CryptoJS.AES.decrypt(response, key, {
    mode: CryptoJS.mode.CFB,
    iv: iv,
    padding: CryptoJS.pad.NoPadding,
  });

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};
