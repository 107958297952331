import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { openFileURL } from "../../helpers/utils";
import { SET_BIDS_DATA, SET_LOTS_VIEW_DATA } from "./actionType";

// to get lots lists
export const getLotsViewData = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/lots`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params.sort,
        exclude: params?.exclude,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_LOTS_VIEW_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};

// to get list of bids
export const getBids = (params) => async (dispatch) => {
  try {
    return API.get(`/auctions/bids`, {
      params: {
        page_number: params?.page,
        page_size: params?.limit,
        search: params?.search,
        sort: params?.sort,
        filter: params?.filter,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_BIDS_DATA,
          payload: {
            data: res?.data?.data?.results,
            total: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add bid
export const addBid = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/bids`, payload).then((res) => {
      return res;
    });
  } catch (error) {
    console.log(error);
  }
};

// to update bid
export const updateBid = (bidId, payload) => async (dispatch) => {
  try {
    return API.put(`/auctions/bids/${bidId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to delete bid
export const deleteBid = (payload) => async (dispatch) => {
  try {
    return API.delete(`/auctions/bids`, { data: { ...payload } }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to distribute all bids
export const distributeBids = (auctionId) => async (dispatch) => {
  try {
    return API.post(`/auctions/bids/distribution/${auctionId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export distributed bids pdfs
export const exportDistributedBids = (auctionId) => async (dispatch) => {
  try {
    return API.post(`/auctions/bids/distribution-export/${auctionId}`).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        console.log(res?.data?.data?.files_list);
        if (res?.data?.data?.files_list?.length > 0) {
          res?.data?.data?.files_list?.forEach((file) => {
            openFileURL(file, "download");
          });
        }
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to send emails to the all bidders of auction by emails
export const sendEmailNotifications = (auctionId, payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/bids/email/${auctionId}`, payload).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to check bid validation for bulk bid adding
export const checkBidValidation = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/bids/validation`, payload).then((res) => {
      if (res && res?.status && res?.status === 200) return true;
      return res?.data?.detail;
    });
  } catch (error) {
    console.log(error);
  }
};

// to add several bids
export const addBids = (payload) => async (dispatch) => {
  try {
    return API.post(`/auctions/several-bids`, payload).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.detail);
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
  }
};
