import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import CustomSearchInput from "../../common/CustomSearchInput";
import { getOnlyCustomerName } from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import CustomTextLink from "../../common/CustomTextLink";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import API from "../../../helpers/api/api_interceptor";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";

const ChooseIssueSalesNoteModal = ({ isModalOpen, onSend, onCancel, loader, t }) => {
  const { id } = useParams();
  const history = useHistory();

  const INITIAL_PAYLOAD = {
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    sort: "contract_no",
    filter: {
      contract_items__item_status: "auction_lot",
      contract_items__auction_lots__auction: id,
      contract_items__auction_lots__is_delete: false,
      contract_items__lots_tag__tag_label: "Awaiting payment to seller",
    },
    auction_id: +id,
  };

  const [contractsPayload, setContractsPayload] = useState({
    ...INITIAL_PAYLOAD,
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [contracts, setContracts] = useState([]);
  const [totalContracts, setTotalContracts] = useState(0);

  // handle page change
  const handlePageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setContractsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get contracts
  const handleGetContracts = useCallback((contractsPayload) => {
    try {
      setTableLoader(true);
      API.get(`/contracts/`, {
        params: {
          ...contractsPayload,
          page_number: contractsPayload?.page,
          page_size: contractsPayload?.limit,
          search: contractsPayload?.search,
          search_list: contractsPayload?.search_list,
          sort: contractsPayload?.sort,
          filter: contractsPayload?.filter,
          is_paid_expense: contractsPayload?.is_paid_expense,
          expense_exclude: JSON.stringify(contractsPayload?.expense_exclude),
          exclude: contractsPayload?.exclude,
        },
      })
        .then((res) => {
          if (res && res.status && res.status === 200) {
            setContracts(res?.data?.data?.results);
            setTotalContracts(res?.data?.data?.count);
            return true;
          } else {
            return false;
          }
        })
        .finally(() => setTableLoader(false));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    handleGetContracts(contractsPayload);
  }, [contractsPayload]);

  const handleModalClose = () => {
    if (!loader) onCancel();
  };

  // navigate to customer edit page
  const navigateToContractEditPage = (row) => {
    history.push(`/contracts/contracts/edit/${row?.id}`);
  };

  const navigateToCustomerEditPage = (row) => {
    if (row?.customer?.id && !row?.customer?.parent_id) history.push(`/customers/edit/${row?.customer?.id}`);
    else if (row?.customer?.parent_id)
      history.push(`/customers/${row?.customer?.parent_id}/edit-person/${row?.customer?.id}`);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              if (event.target.checked) {
                setSelectedRows(contracts?.map((item) => item?.id));
              } else {
                setSelectedRows([]);
              }
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "68px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.contractNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_no__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_no ? (
          <CustomTextLink text={`${row?.contract_no}`} handleClick={() => navigateToContractEditPage(row)} />
        ) : (
          "-"
        ),
      width: "210px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.id ? (
          <CustomTextLink
            text={getOnlyCustomerName(row?.customer)}
            handleClick={() => navigateToCustomerEditPage(row)}
          />
        ) : (
          "-"
        ),
      width: "500px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.noOfLotOnAuction")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots_qty__icontains"
            className="column-search-input"
            payload={contractsPayload}
            setPayload={setContractsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <div className="d-flex justify-content-between align-items-center" style={{ width: "240px" }}>
          <span>{row?.auction_lots_qty}</span>
          {row?.is_sales_issue_error ? (
            <div>
              <CustomTooltipWrapper
                target={`tooltip-${row?.id}-error`}
                tooltipBody={t("information.errorOnBulkSalesNote", { action: t("common.issue").toLowerCase() })}
              />
              <i className="ri-alert-line fs-18" style={{ color: "red" }} id={`tooltip-${row?.id}-error`} />
            </div>
          ) : null}
        </div>
      ),
      width: "275px",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="xl"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select")} ${t("common.and")} ${t("common.issueSalesNotes")}`}</span>
          {(isAllSelected && totalContracts > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalContracts}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            fixedHeader
            persistTableHead
            columns={columns}
            data={[...contracts]}
            pagination
            paginationServer
            paginationTotalRows={totalContracts}
            paginationDefaultPage={contractsPayload?.page}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={() => {
            onSend({
              contract_ids: selectedRows,
            });
          }}
          isLoading={loader}
          btnTitle={t("common.issue")}
          disabled={(isAllSelected && totalContracts === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ChooseIssueSalesNoteModal);
