import React from "react";
import { Button } from "reactstrap";

const CustomAddButton = ({ onClick, btnTitle, disabled, color, ...others }) => {
  return (
    <Button
      color={color || "primary"}
      className="btn-load d-flex align-items-center"
      onClick={onClick}
      disabled={disabled}
      {...others}
    >
      <i className="ri-add-fill" />
      {btnTitle && <span className="flex-grow-1 ms-2">{btnTitle}</span>}
    </Button>
  );
};

export default CustomAddButton;
