import React, { useEffect, useMemo, useState } from "react";

import { FormikProvider, useFormik } from "formik";
import debounce from "lodash.debounce";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import * as Yup from "yup";

import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import { BIDDING_ORIGIN_OPTIONS } from "../../../helpers/constants/selectOptions";
import { getCustomerOptionLabel, getTypeFromValue, scrollToFirstFormikError } from "../../../helpers/utils";
import { getAuctionCollaborators } from "../../../store/auction-collaborators/actions";
import { getCustomerList } from "../../../store/customers/actions";
import { fetchLanguageListAction } from "../../../store/languages/action";
import BulkBidsTable from "./BulkBidsTable";

const AddBulkBidsModal = ({ isModalOpen, onCancel, onSave, loader, t }) => {
  const dispatch = useDispatch();
  const CUSTOMER_OPTIONS = useSelector((state) => state?.Customers?.data);
  const LANGUAGE_OPTIONS = useSelector((state) => state?.Languages?.languageList);

  const USERS_OPTIONS = useSelector((state) => state?.Collaborators?.data);

  const [bidsData, setBidsData] = useState([]);
  const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
  const [customersPayload, setCustomersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    list: "basic",
    filter: {
      search_customer__icontains: "",
    },
  });
  const [userSearchLoader, setUserSearchLoader] = useState(false);
  const [usersPayload, setUsersPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "name",
    filter: {
      name__icontains: "",
    },
  });

  const [languageSearchLoader, setLanguageSearchLoader] = useState(false);
  const [languagePayload, setLanguagePayload] = useState({
    page: 1,
    sort: "description",
    filter: {
      is_spoken: true,
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      customer: "",
      employee_preference: "",
      bidding_origin: "",
      languages_speaks: [],
    },
    validationSchema: Yup.object({
      customer: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.customer")}`),
      languages_speaks: Yup.array()
        .min(1, `${t("common.please")} ${t("common.select")} ${t("common.languagePreference")}`)
        .required(`${t("common.please")} ${t("common.select")} ${t("common.languagePreference")}`),
    }),
    onSubmit: (values) => {
      if (bidsData?.length === 0) return toast.info(t("information.atleastOneBid"));
      onSave({
        ...values,
        employee_preference: values?.employee_preference?.id || null,
        languages_speaks:
          values?.languages_speaks?.length > 0 ? values?.languages_speaks?.map((language) => language?.id) : [],
        bidsData,
      });
    },
  });

  //   to reset states and form on close of the modal
  useEffect(() => {
    if (!isModalOpen) {
      setBidsData([]);
      validation.resetForm();
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the customer search
  const handleCustomerSearch = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_customer__icontains: value },
    }));
  };
  // debounce for customer search
  const customerSearchDebounce = useMemo(() => {
    return debounce(handleCustomerSearch, DEBOUNCE_TIME);
  }, []);

  // to handle the user search
  const handleUserSearch = (value) => {
    setUsersPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, name__icontains: value },
    }));
  };
  // debounce for user search
  const userSearchDebounce = useMemo(() => {
    return debounce(handleUserSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      customerSearchDebounce.cancel();
      userSearchDebounce.cancel();
    };
  }, [customerSearchDebounce, userSearchDebounce]);

  // to get the list of customers
  useEffect(() => {
    if (isModalOpen) {
      setCustomerSearchLoader(true);
      dispatch(getCustomerList(customersPayload)).then((res) => {
        setCustomerSearchLoader(false);
      });
    }
  }, [dispatch, customersPayload, isModalOpen]);
  // to get the list of the users
  useEffect(() => {
    if (isModalOpen) {
      setUserSearchLoader(true);
      dispatch(getAuctionCollaborators(usersPayload)).then((res) => {
        setUserSearchLoader(false);
      });
    }
  }, [dispatch, usersPayload, isModalOpen]);

  // to get the list of spoken languages in system.
  useEffect(() => {
    if (isModalOpen) {
      setLanguageSearchLoader(true);
      dispatch(fetchLanguageListAction(languagePayload)).then((res) => {
        setLanguageSearchLoader(false);
      });
    }
  }, [dispatch, languagePayload, isModalOpen]);

  return (
    <Modal size="lg" centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.addTitle", {
          module: t("common.bids"),
        })}
      </ModalHeader>
      <FormikProvider value={validation}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="customer" className="form-label">
                    {`${t("common.customer")}*`}
                  </Label>
                  <Select
                    isLoading={customerSearchLoader}
                    name="customer"
                    options={customerSearchLoader ? [] : CUSTOMER_OPTIONS}
                    getOptionLabel={getCustomerOptionLabel}
                    getOptionValue={(option) => option?.id}
                    className={`custom-select ${
                      validation.touched.customer && validation.errors.customer ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.customer")}`}
                    value={validation.values.customer || null}
                    onChange={(customer) => {
                      if (bidsData?.length > 0) {
                        if (window.confirm("change customer")) {
                          validation.setFieldValue("customer", customer?.id ? customer : null);
                          setBidsData([]);
                        }
                      } else {
                        validation.setFieldValue("customer", customer?.id ? customer : "");
                      }
                    }}
                    onInputChange={customerSearchDebounce}
                    onBlur={(e) => {
                      validation.setFieldTouched("customer", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.customer && validation.errors.customer ? (
                    <p className="custom-invalid-feedback">{validation.errors.customer}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="employee_preference" className="form-label">
                    {`${t("common.employeePreference")}`}
                  </Label>
                  <Select
                    isClearable
                    isLoading={userSearchLoader}
                    name="employee_preference"
                    options={userSearchLoader ? [] : USERS_OPTIONS}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    className={`custom-select ${
                      validation.touched.employee_preference && validation.errors.employee_preference
                        ? "select-error"
                        : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.employee")}`}
                    value={validation.values.employee_preference || null}
                    onChange={(option) => {
                      validation.setFieldValue("employee_preference", option?.id ? option : "");
                    }}
                    onInputChange={userSearchDebounce}
                    onBlur={(e) => {
                      validation.setFieldTouched("employee_preference", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.employee_preference && validation.errors.employee_preference ? (
                    <p className="custom-invalid-feedback">{validation.errors.employee_preference}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="spoken" className="form-label">
                    {`${t("common.languagePreference")}`}*
                  </Label>
                  <Select
                    isLoading={languageSearchLoader}
                    options={languageSearchLoader ? [] : LANGUAGE_OPTIONS}
                    getOptionLabel={(option) => option?.description}
                    getOptionValue={(option) => option?.id}
                    className={`custom-select ${
                      validation.touched.languages_speaks && validation.errors.languages_speaks ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.language")}`}
                    value={validation.values.languages_speaks?.[0] || null}
                    onChange={(languages_speaks) => {
                      validation.setFieldValue("languages_speaks", languages_speaks?.id ? [languages_speaks] : []);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("languages_speaks", true);
                      validation.handleBlur(e);
                    }}
                  />

                  {validation.touched.languages_speaks && validation.errors.languages_speaks ? (
                    <p className="custom-validation-style">{validation.errors.languages_speaks}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="bidding_origin" className="form-label">
                    {`${t("common.biddingOrigin")}`}
                  </Label>
                  <Select
                    isClearable
                    name="bidding_origin"
                    options={BIDDING_ORIGIN_OPTIONS}
                    className={`custom-select custom-mini-select ${
                      validation.touched.bidding_origin && validation.errors.bidding_origin ? "select-error" : ""
                    }`}
                    placeholder={`${t("common.select")} ${t("common.biddingOrigin")}`}
                    value={getTypeFromValue(BIDDING_ORIGIN_OPTIONS, validation.values.bidding_origin) || ""}
                    onChange={(option) => {
                      validation.setFieldValue("bidding_origin", option?.value ? option?.value : "");
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("bidding_origin", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.bidding_origin && validation.errors.bidding_origin ? (
                    <p className="custom-invalid-feedback">{validation.errors.bidding_origin}</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            {/* lots selection table */}
            <BulkBidsTable
              bidsData={bidsData}
              validation={validation}
              isModalOpen={isModalOpen}
              setBidsData={setBidsData}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={handleModalClose} disabled={loader}>
              {t("common.cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loader || JSON.stringify(validation?.initialValues) === JSON.stringify(validation?.values)}
              className="btn-load d-flex"
              type="submit"
              onClick={() => scrollToFirstFormikError(validation.errors)}
            >
              {loader ? (
                <span className="d-flex align-items-center ">
                  <Spinner size="xs" className="flex-shrink-0"></Spinner>
                </span>
              ) : null}
              <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
            </Button>
          </ModalFooter>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default withTranslation()(AddBulkBidsModal);
