import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => (
  <div className="custom-loader-backdrop">
    <Spinner className="backdrop-spinner" />
  </div>
);

export default Loader;
