import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";

import { formatAmount, getOnlyCustomerName } from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import { getPaddles } from "../../../store/auctions/paddleActions";

const INITIAL_PAYLOAD = {
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
};

const ChoosePaddlesModal = ({ isModalOpen, onSend, onCancel, loader, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data: paddlesData, total: totalPaddles } = useSelector((state) => state?.Auctions?.paddles);

  const [paddlesPayload, setPaddlesPayload] = useState({
    ...INITIAL_PAYLOAD,
    filter: {
      auction: id,
      total_purchase: 0,
      customer__bids__bidding_type: "absentee",
      is_notification_sent: false,
    },
  });

  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // handle page change
  const handlePageChange = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get documents
  const handleGetDocuments = useCallback(
    (paddlesPayload) => {
      setTableLoader(true);
      dispatch(getPaddles(paddlesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // to get the list of the documents
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      handleGetDocuments(paddlesPayload);
    }
  }, [isModalOpen, paddlesPayload, handleGetDocuments]);

  // to reset the form
  useEffect(() => {
    if (!isModalOpen && id) {
      setPaddlesPayload({
        ...INITIAL_PAYLOAD,
        filter: {
          auction: id,
          total_purchase: null,
          customer__bids__bidding_type: "absentee",
          is_notification_sent: false,
        },
      });
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }, [isModalOpen, id]);

  // to fiter documents by auction id
  useEffect(() => {
    if (id) {
      setPaddlesPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          //   auction: id,
        },
      }));
    }
  }, [id]);

  const handleModalClose = () => {
    if (!loader) onCancel();
  };

  const handleSelectRow = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row?.id]);
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "50px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.paddleNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="paddle_no__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) => (row?.paddle_no ? <span style={{ color: row?.color_code }}>{row?.paddle_no}</span> : "-"),
      width: "135px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.id ? (
          <span className="cursor-pointer" style={{ color: row?.color_code }}>
            {getOnlyCustomerName(row?.customer)}
          </span>
        ) : (
          "-"
        ),
      width: "425px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.totalPurchase")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total_purchase__icontains"
            className="column-search-input"
            payload={paddlesPayload}
            setPayload={setPaddlesPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span style={{ color: row?.color_code }}>{formatAmount(row?.total_purchase || 0) + " €"}</span>
      ),
      width: "150px",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="xl"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select")} ${t("common.paddles")} ${t("common.and")} ${t(
            "common.sendNotification"
          )}`}</span>

          {(isAllSelected && totalPaddles > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalPaddles}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            persistTableHead
            fixedHeader
            columns={columns}
            data={[...paddlesData]}
            pagination
            paginationServer
            paginationDefaultPage={paddlesPayload?.page}
            paginationPerPage={paddlesPayload?.limit}
            paginationTotalRows={totalPaddles}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            pointerOnHover
            onRowClicked={(row) => {
              handleSelectRow(row);
            }}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={() => {
            onSend({
              paddle_ids: selectedRows,
              filter: paddlesPayload?.filter,
            });
          }}
          isLoading={loader}
          btnTitle={t("common.send")}
          disabled={(isAllSelected && totalPaddles === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ChoosePaddlesModal);
