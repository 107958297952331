import debounce from "lodash.debounce";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import BarGraph from "../../components/common/BarGraph";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import CustomDropdownButton from "../../components/common/CustomDropdownButton";
import Loader from "../../components/common/Loader";
import { ADMIN, DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../helpers/constants";
import {
  CATEGORY_WISE_OPTIONS,
  CUSTOMER_CATEGORIES_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
} from "../../helpers/constants/selectOptions";
import { checkPermissionByRoles, exportExcelWithTitleRow, formatAmount, getColumnDetail } from "../../helpers/utils";
import { getAuctionList } from "../../store/auctions/actions";
import { getAuctionReports, getSalesReports } from "../../store/reports/actions";

const Reports = (props) => {
  const dispatch = useDispatch();
  const AUCTIONS_OPTIONS = useSelector((state) => state?.Auctions?.data);

  const [monthLoader, setMonthLoader] = useState(false);
  const [monthData, setMonthData] = useState();
  const [monthRange, setMonthRange] = useState([]);
  const [yearLoader, setYearLoader] = useState(false);
  const [yearData, setYearData] = useState();
  const [yearRange, setYearRange] = useState([]);

  const [auctionGeneralLoader, setAuctionGeneralLoader] = useState(false);
  const [auctionGeneralData, setAuctionGeneralData] = useState();
  const [topCustomersLoader, setTopCustomersLoader] = useState(false);
  const [topCustomersData, setTopCustomersData] = useState();
  const [piecesRaiserSummary, setPiecesRaiserSummary] = useState();
  const [piecesRaiserLoader, setPiecesRaiserLoader] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectedCategoryWise, setSelectedCategoryWise] = useState(CATEGORY_WISE_OPTIONS[0]);
  const [selectedCustomerType, setSelectedCustomerType] = useState();
  const [selectedCustomerCategory, setSelectedCustomerCategory] = useState();

  const [auctionSearchLoader, setAuctionSearchLoader] = useState(false);
  const [auctionPayload, setAuctionPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "id",
  });

  // handle auction search
  const handleAuctionSearch = (value) => {
    setAuctionPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("search_auction_en__icontains", "search_auction_pt__icontains")]: value,
      },
    }));
  };
  // debounce for auction search
  const auctionSearchDebounce = useMemo(() => {
    return debounce(handleAuctionSearch, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    auctionSearchDebounce.cancel();
  }, [auctionSearchDebounce]);

  // to get the list of the auctions
  useEffect(() => {
    setAuctionSearchLoader(true);
    dispatch(getAuctionList(auctionPayload)).then(() => {
      setAuctionSearchLoader(false);
    });
  }, [dispatch, auctionPayload]);

  // sales per month
  useEffect(() => {
    if (monthRange[0] && monthRange[1]) {
      setMonthLoader(true);
      dispatch(
        getSalesReports({
          sales_report_type: "sales_per_month",
          start_date: moment(monthRange[0]).format("YYYY-MM-DD"),
          end_date: moment(monthRange[1]).format("YYYY-MM-DD"),
        })
      ).then((res) => {
        setMonthData(res?.data?.data);
        setMonthLoader(false);
      });
    }
  }, [monthRange, dispatch]);

  // sales per year
  useEffect(() => {
    if (yearRange[0] && yearRange[1]) {
      setYearLoader(true);
      dispatch(
        getSalesReports({
          sales_report_type: "sales_per_year",
          start_date: moment(yearRange[0]).format("YYYY-MM-DD"),
          end_date: moment(yearRange[1]).format("YYYY-MM-DD"),
        })
      ).then((res) => {
        setYearData(res?.data?.data);
        setYearLoader(false);
      });
    }
  }, [yearRange, dispatch]);

  // auctions report default
  useEffect(() => {
    if (selectedAuction?.id) {
      setAuctionGeneralLoader(true);
      dispatch(getAuctionReports(selectedAuction?.id)).then((res) => {
        setAuctionGeneralData(res?.data?.data);
        setAuctionGeneralLoader(false);
      });
    } else {
      setAuctionGeneralData(null);
    }
  }, [selectedAuction?.id, dispatch]);

  // auction's pieces raiser summary
  useEffect(() => {
    if (selectedAuction?.id) {
      setPiecesRaiserLoader(true);
      dispatch(
        getAuctionReports(selectedAuction?.id, {
          report_type: "pieces_raiser_summary",
        })
      ).then((res) => {
        setPiecesRaiserSummary(res?.data?.data?.pieces_raiser_summary);
        setPiecesRaiserLoader(false);
      });
    } else {
      setPiecesRaiserSummary(null);
    }
  }, [selectedAuction?.id, dispatch]);

  // top 50 buyer/seller by value/voluem report
  useEffect(() => {
    if (selectedAuction?.id && selectedCustomerType?.value && selectedCustomerCategory?.value) {
      setTopCustomersLoader(true);
      dispatch(
        getAuctionReports(selectedAuction?.id, {
          report_type: `50_top_${selectedCustomerType?.value}_${selectedCustomerCategory?.value}`,
        })
      ).then((res) => {
        setTopCustomersData(
          res?.data?.data?.[`50_top_${selectedCustomerType?.value}_${selectedCustomerCategory?.value}`]?.map(
            (item) => ({
              label: item?.customer,
              data: selectedCustomerCategory?.value === "value" ? item?.sale_value_count : item?.item_count,
            })
          )
        );
        setTopCustomersLoader(false);
      });
    } else {
      setTopCustomersData(null);
    }
  }, [selectedAuction?.id, selectedCustomerType, selectedCustomerCategory, dispatch]);

  // handle export of auction general information
  const handleExportAuctionInfo = (isEnglish) => {
    const adminData = [
      {
        label_en: "Total Sales",
        label_pt: "Vendas totais",
        value: formatAmount(auctionGeneralData?.total_sales || 0),
      },
      {
        label_en: "Contracts At Auction",
        label_pt: "Contratos em Leilão",
        value: auctionGeneralData?.contracts_at_auction || 0,
      },
      {
        label_en: "Catalogue Shipping Count",
        label_pt: "Contagem de Envio de Catálogo",
        value: auctionGeneralData?.catalogue_shipping_count || 0,
      },
      {
        label_en: "Postcard Shipping Count",
        label_pt: "Contagem de envio de cartão postal",
        value: auctionGeneralData?.postcards_count || 0,
      },
    ];
    const generalData = [
      {
        label_en: "Items At Auction",
        label_pt: "Itens em leilão",
        value: auctionGeneralData?.items_at_auction || 0,
      },
      {
        label_en: "PJ",
        label_pt: "PJ",
        value: auctionGeneralData?.general_psp_map_count || 0,
      },
      {
        label_en: "PJ Jewellery",
        label_pt: "Joias PJ",
        value: auctionGeneralData?.jewelry_psp_map_count || 0,
      },
      {
        label_en: "ASAE",
        label_pt: "ASAE",
        value: auctionGeneralData?.asae_map_count || 0,
      },
      {
        label_en: "CITES",
        label_pt: "CITES",
        value: auctionGeneralData?.cites_count || 0,
      },
    ];
    let finalData = [...generalData];
    if (checkPermissionByRoles([ADMIN])) {
      finalData = [...adminData, ...finalData];
    }
    let excelData = {};
    finalData?.forEach((data) => {
      excelData[`${isEnglish ? data?.label_en : data?.label_pt}`] = data?.value;
    });

    exportExcelWithTitleRow(
      [excelData],
      isEnglish
        ? `Auction_${selectedAuction?.auction_no}_General_Report`
        : `Leilão_${selectedAuction?.auction_no}_Relatório_Geral`,
      finalData?.map(() => 20),
      isEnglish
        ? `Auction ${selectedAuction?.auction_no} - General Report`
        : `Leilão ${selectedAuction?.auction_no} - Relatório Geral`
    );
  };

  document.title = `Veritas | ${props.t("sidebarMenu.reports")}`;

  return (
    <div className="page-content layout-main-container">
      {/* sales reports */}
      {checkPermissionByRoles([ADMIN]) ? (
        <Card>
          <CardHeader>
            <h4>{props.t("common.salesReports")}</h4>
          </CardHeader>
          <CardBody className="position-relative">
            {/* <Loader /> */}
            <Row>
              <Col>
                <div className="mb-4 ms-2 d-flex flex-row justify-content-between">
                  <CustomDatePicker
                    dateFormat="MM/yyyy"
                    selected={monthRange[0]}
                    onChange={(dates) => {
                      setMonthRange(dates);
                    }}
                    selectsRange
                    startDate={monthRange[0]}
                    endDate={monthRange[1]}
                    showMonthYearPicker
                    placeholderText={props.t("placeholder.selectMonths")}
                    isClearable={true}
                  />
                  <CustomDropdownButton
                    disabled={!(monthRange[0] && monthRange[1])}
                    btnTitle={props.t("common.export")}
                    options={[
                      {
                        label: `${props.t("common.excel")} ${props.t("common.en")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            monthData?.map((data) => ({
                              Month: data?.month || "-",
                              Year: data?.year || "-",
                              "Sale value": data?.total_sales || "0",
                            })),
                            `Monthly_Sales_${moment(monthRange?.[0]).format("MM/YYYY")}-${moment(
                              monthRange?.[1]
                            ).format("MM/YYYY")}`,
                            [20, 20, 20],
                            `Monthly Sales Report (${moment(monthRange?.[0]).format("MM/YYYY")} - ${moment(
                              monthRange?.[1]
                            ).format("MM/YYYY")})`
                          );
                        },
                      },
                      {
                        label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            monthData?.map((data) => ({
                              Mês: data?.month || "-",
                              Ano: data?.year || "-",
                              "Valor de venda": data?.total_sales || "0",
                            })),
                            `Vendas_Mensais_${moment(monthRange?.[0]).format("MM/YYYY")}-${moment(
                              monthRange?.[1]
                            ).format("MM/YYYY")}`,
                            [20, 20, 20],
                            `Relatório Mensal de Vendas (${moment(monthRange?.[0]).format("MM/YYYY")} - ${moment(
                              monthRange?.[1]
                            ).format("MM/YYYY")})`
                          );
                        },
                      },
                    ]}
                  />
                </div>

                <BarGraph
                  xAxisLabel={props.t("common.months")}
                  yAxisLabel={props.t("common.saleValue")}
                  labels={monthData?.map((item) => `${String(item?.month).padStart(2, "0")}/${item?.year}`)}
                  data={monthData?.map((item) => formatAmount(item?.total_sales || 0))}
                  barThickness={10}
                  currencyFormatY
                />
              </Col>
              <Col>
                <div className="mb-4 ms-2 d-flex flex-row justify-content-between">
                  <CustomDatePicker
                    dateFormat="yyyy"
                    selected={yearRange[0]}
                    onChange={(dates) => {
                      setYearRange(dates);
                    }}
                    selectsRange
                    startDate={yearRange[0]}
                    endDate={yearRange[1]}
                    showYearPicker
                    placeholderText={props.t("placeholder.selectYears")}
                    isClearable={true}
                  />
                  <CustomDropdownButton
                    disabled={!(yearRange[0] && yearRange[1])}
                    btnTitle={props.t("common.export")}
                    options={[
                      {
                        label: `${props.t("common.excel")} ${props.t("common.en")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            yearData?.map((data) => ({
                              Year: data?.year || "-",
                              "Sale value": data?.total_sales || "0",
                            })),
                            `Yearly_Sales_${moment(yearRange?.[0]).format("YYYY")}-${moment(yearRange?.[1]).format(
                              "YYYY"
                            )}`,
                            [20, 20],
                            `Yearly Sales Report (${moment(yearRange?.[0]).format("YYYY")} - ${moment(
                              yearRange?.[1]
                            ).format("YYYY")})`
                          );
                        },
                      },
                      {
                        label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            yearData?.map((data) => ({
                              Ano: data?.year || "-",
                              "Valor de venda": data?.total_sales || "0",
                            })),
                            `Vendas_Anuais_${moment(yearRange?.[0]).format("YYYY")}-${moment(yearRange?.[1]).format(
                              "YYYY"
                            )}`,
                            [20, 20],
                            `Relatório Anual de Vendas (${moment(yearRange?.[0]).format("YYYY")} - ${moment(
                              yearRange?.[1]
                            ).format("YYYY")})`
                          );
                        },
                      },
                    ]}
                  />
                </div>
                <BarGraph
                  xAxisLabel={props.t("common.years")}
                  yAxisLabel={props.t("common.saleValue")}
                  labels={yearData?.map((item) => item?.year)}
                  data={yearData?.map((item) => formatAmount(item?.total_sales || 0))}
                  barThickness={10}
                  currencyFormatY
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : null}
      {/* auction reports */}
      <Card>
        <CardHeader>
          <h4>{props.t("common.auctionReports")}</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="col-xs-12 col-sm-12 col-md-6 col-xl-6">
              <div className="mb-3">
                <Label className="form-label fs-16">{`${props.t("common.auction")}`}</Label>
                <Select
                  isClearable
                  isLoading={auctionSearchLoader}
                  name="auction"
                  options={auctionSearchLoader ? [] : AUCTIONS_OPTIONS}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) =>
                    option?.auction_no + " - " + (getColumnDetail(option?.title_en, option?.title_pt) || "")
                  }
                  className={`custom-select`}
                  placeholder={`${props.t("common.select")} ${props.t("common.auction")}`}
                  value={selectedAuction}
                  onInputChange={auctionSearchDebounce}
                  onChange={(auction) => setSelectedAuction(auction)}
                />
              </div>
            </Col>
            <Col className="col-xs-12 col-sm-12 col-md-6 col-xl-6 d-flex justify-content-end">
              <CustomDropdownButton
                disabled={!selectedAuction?.id}
                btnTitle={props.t("common.export")}
                styles={{ height: "fit-content" }}
                options={[
                  {
                    label: `${props.t("common.excel")} ${props.t("common.en")}`,
                    onClick: () => {
                      handleExportAuctionInfo(true);
                    },
                  },
                  {
                    label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                    onClick: () => {
                      handleExportAuctionInfo(false);
                    },
                  },
                ]}
              />
            </Col>
            <Col lg={12} className="auaction-report-count-wrap">
              {checkPermissionByRoles([ADMIN]) ? (
                <Row>
                  <Col md={2} lg={2}>
                    <Label className="fs-16">{props.t("common.totalSales")}:</Label>
                    <h3>{formatAmount(auctionGeneralData?.total_sales || 0)}</h3>
                  </Col>
                  <Col md={2} lg={2}>
                    <Label className="fs-16">{props.t("common.contractsAtAuction")}:</Label>
                    <h3>{auctionGeneralData?.contracts_at_auction || 0}</h3>
                  </Col>
                  <Col md={3} lg={2}>
                    <Label className="fs-16">{props.t("common.catalogueShippingCount")}:</Label>
                    <h3>{auctionGeneralData?.catalogue_shipping_count || 0}</h3>
                  </Col>
                  <Col md={3} lg={2}>
                    <Label className="fs-16">{props.t("common.postcardShppingCount")}:</Label>
                    <h3>{auctionGeneralData?.postcards_count || 0}</h3>
                  </Col>
                  <Col md={2} lg={2}></Col>
                </Row>
              ) : null}
            </Col>
            <Col lg={12} className="auaction-report-count-wrap">
              <Row>
                <Col md={2} lg={2}>
                  <Label className="fs-16">{props.t("common.itemsAtAuction")}:</Label>
                  <h3>{auctionGeneralData?.items_at_auction || 0}</h3>
                </Col>
                <Col md={2} lg={2}>
                  <Label className="fs-16">{props.t("common.pj")}:</Label>
                  <h3>{auctionGeneralData?.general_psp_map_count || 0}</h3>
                </Col>
                <Col md={3} lg={2}>
                  <Label className="fs-16">{props.t("common.pjJewellery")}:</Label>
                  <h3>{auctionGeneralData?.jewelry_psp_map_count || 0}</h3>
                </Col>
                <Col md={3} lg={2}>
                  <Label className="fs-16">{props.t("common.asae")}:</Label>
                  <h3>{auctionGeneralData?.asae_map_count || 0}</h3>
                </Col>
                <Col md={2} lg={2}>
                  <Label className="fs-16">{props.t("common.cites")}:</Label>
                  <h3>{auctionGeneralData?.cites_count || 0}</h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="position-relative d-flex">
            {auctionGeneralLoader ? <Loader /> : null}
            <div className="mb-4 ms-2 d-flex flex-row justify-content-between">
              {checkPermissionByRoles([ADMIN]) ? (
                <div className="mb-4 ms-2">
                  <div className="d-flex flex-row align-items-center gap-3">
                    <Label className="fs-16">{props.t("common.categoryWise")}:</Label>
                    <div style={{ width: "200px" }}>
                      <Select
                        className="custom-select"
                        options={CATEGORY_WISE_OPTIONS}
                        value={selectedCategoryWise}
                        onChange={(categoryWise) => {
                          setSelectedCategoryWise(categoryWise);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Label className="fs-16">{props.t("common.itemsByCategories")}:</Label>
              )}
              <CustomDropdownButton
                classes={"me-2"}
                disabled={
                  auctionGeneralData?.categories_at_auction?.length === 0 || !auctionGeneralData?.categories_at_auction
                }
                btnTitle={props.t("common.export")}
                styles={{ height: "fit-content" }}
                options={[
                  {
                    label: `${props.t("common.excel")} ${props.t("common.en")}`,
                    onClick: () => {
                      exportExcelWithTitleRow(
                        auctionGeneralData?.categories_at_auction?.map((category) => ({
                          Category: category?.category_name_en || "-",
                          [selectedCategoryWise?.value === "items"
                            ? "Items Quantity"
                            : selectedCategoryWise?.value === "sales"
                            ? "Sale Value"
                            : "Buying Quantity"]:
                            (selectedCategoryWise?.value === "items"
                              ? category?.item_count
                              : selectedCategoryWise?.value === "sales"
                              ? category?.sale_count
                              : category?.buying_count) || 0,
                        })),
                        `Auction_${selectedAuction?.auction_no}-Category Wise ${
                          selectedCategoryWise?.value === "items"
                            ? "Items Quantity"
                            : selectedCategoryWise?.value === "sales"
                            ? "Sale Value"
                            : "Buying Quantity"
                        }`,
                        [20, 20],
                        `Auction ${selectedAuction?.auction_no} - Category Wise ${
                          selectedCategoryWise?.value === "items"
                            ? "Items Quantity"
                            : selectedCategoryWise?.value === "sales"
                            ? "Sale Value"
                            : "Buying Quantity"
                        }`
                      );
                    },
                  },
                  {
                    label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                    onClick: () => {
                      exportExcelWithTitleRow(
                        auctionGeneralData?.categories_at_auction?.map((category) => ({
                          Category: category?.category_name_pt || "-",
                          [selectedCategoryWise?.value === "items"
                            ? "Quantidade de Itens"
                            : selectedCategoryWise?.value === "sales"
                            ? "Valor de venda"
                            : "Quantidade de compra"]:
                            (selectedCategoryWise?.value === "items"
                              ? category?.item_count
                              : selectedCategoryWise?.value === "sales"
                              ? category?.sale_count
                              : category?.buying_count) || 0,
                        })),
                        `Leilão_${selectedAuction?.auction_no}-Categoria Sábia ${
                          selectedCategoryWise?.value === "items"
                            ? "Quantidade de Itens"
                            : selectedCategoryWise?.value === "sales"
                            ? "Valor de venda"
                            : "Quantidade de compra"
                        }`,
                        [20, 20],
                        `Leilão ${selectedAuction?.auction_no} - Categoria Sábia ${
                          selectedCategoryWise?.value === "items"
                            ? "Quantidade de Itens"
                            : selectedCategoryWise?.value === "sales"
                            ? "Valor de venda"
                            : "Quantidade de compra"
                        }`
                      );
                    },
                  },
                ]}
              />
            </div>
            <BarGraph
              xAxisLabel={props.t("common.categories")}
              yAxisLabel={checkPermissionByRoles([ADMIN]) ? selectedCategoryWise?.label : props.t("common.quantity")}
              labels={auctionGeneralData?.categories_at_auction?.map((category) =>
                getColumnDetail(category?.category_name_en, category?.category_name_pt)
              )}
              data={auctionGeneralData?.categories_at_auction?.map((category) =>
                checkPermissionByRoles([ADMIN])
                  ? selectedCategoryWise?.value === "items"
                    ? category?.item_count
                    : selectedCategoryWise?.value === "sales"
                    ? category?.sale_count
                    : category?.buying_count
                  : category?.item_count
              )}
              barThickness={10}
              aspectRatio={3}
              currencyFormatY={selectedCategoryWise?.value === "sales"}
            />
          </Row>
          {checkPermissionByRoles([ADMIN]) ? (
            <>
              <hr />
              <Row className="position-relative">
                {topCustomersLoader ? <Loader /> : null}
                <div className="mb-4 ms-2 d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row align-items-center gap-3">
                    <Label className="fs-16">{props.t("common.top50")}:</Label>
                    <div style={{ width: "200px" }}>
                      <Select
                        isClearable
                        className="custom-select"
                        options={CUSTOMER_TYPE_OPTIONS}
                        value={selectedCustomerType}
                        onChange={(customerType) => {
                          setSelectedCustomerType(customerType);
                        }}
                      />
                    </div>
                    <Label className="fs-16">{props.t("common.by")}</Label>
                    <div style={{ width: "200px" }}>
                      <Select
                        isClearable
                        className="custom-select"
                        options={CUSTOMER_CATEGORIES_OPTIONS}
                        value={selectedCustomerCategory}
                        onChange={(category) => {
                          setSelectedCustomerCategory(category);
                        }}
                      />
                    </div>
                  </div>
                  <CustomDropdownButton
                    classes={"me-2"}
                    disabled={topCustomersData?.length === 0 || !topCustomersData}
                    btnTitle={props.t("common.export")}
                    styles={{ height: "fit-content" }}
                    options={[
                      {
                        label: `${props.t("common.excel")} ${props.t("common.en")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            topCustomersData?.map((data) => ({
                              [selectedCustomerType?.value === "buyers" ? "Buyers" : "Sellers"]: data?.label || "-",
                              [selectedCustomerCategory?.value === "volume" ? "Volume" : "Value"]: data?.data || 0,
                            })),
                            `Auction_${selectedAuction?.auction_no}-Top_${
                              selectedCustomerType?.value === "buyers" ? "Buyers" : "Sellers"
                            }_By_${selectedCustomerCategory?.value === "volume" ? "Volume" : "Value"}`,
                            [40, 20],
                            `Auction ${selectedAuction?.auction_no} - Top ${
                              selectedCustomerType?.value === "buyers" ? "Buyers" : "Sellers"
                            } By ${selectedCustomerCategory?.value === "volume" ? "Volume" : "Value"}`
                          );
                        },
                      },
                      {
                        label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            topCustomersData?.map((data) => ({
                              [selectedCustomerType?.value === "buyers" ? "Compradoras" : "Vendedoras"]:
                                data?.label || "-",
                              [selectedCustomerCategory?.value === "volume" ? "Volume" : "Valor"]: data?.data || 0,
                            })),
                            `Leilão_${selectedAuction?.auction_no}-Top_${
                              selectedCustomerType?.value === "buyers" ? "Compradoras" : "Vendedoras"
                            }_Por_${selectedCustomerCategory?.value === "volume" ? "Volume" : "Valor"}`,
                            [40, 20],
                            `Leilão ${selectedAuction?.auction_no} - Top ${
                              selectedCustomerType?.value === "buyers" ? "Compradoras" : "Vendedoras"
                            } Por ${selectedCustomerCategory?.value === "volume" ? "Volume" : "Valor"}`
                          );
                        },
                      },
                    ]}
                  />
                </div>
                <BarGraph
                  xAxisLabel={selectedCustomerType?.label || ""}
                  yAxisLabel={selectedCustomerCategory?.label || ""}
                  labels={topCustomersData?.map((item) => item?.label)}
                  data={topCustomersData?.map((item) => item?.data)}
                  barThickness={10}
                  aspectRatio={3}
                  currencyFormatY={selectedCustomerCategory?.value === "value"}
                />
              </Row>
              <hr />
              <Row className="position-relative">
                {piecesRaiserLoader ? <Loader /> : null}
                <div className="mb-4 ms-2 d-flex flex-row justify-content-between">
                  <Label className="fs-16">{props.t("common.piecesRaiserSummary")}:</Label>
                  <CustomDropdownButton
                    classes={"me-2"}
                    disabled={piecesRaiserSummary?.length === 0 || !piecesRaiserSummary}
                    btnTitle={props.t("common.export")}
                    styles={{ height: "fit-content" }}
                    options={[
                      {
                        label: `${props.t("common.excel")} ${props.t("common.en")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            piecesRaiserSummary?.map((data) => ({
                              Specialist: data?.specialist || "-",
                              "Items Quantity": data?.item_count || 0,
                            })),
                            `Auction_${selectedAuction?.auction_no}-Pieces_Raiser_Summary`,
                            [40, 20],
                            `Auction ${selectedAuction?.auction_no} - Pieces Raiser Summary`
                          );
                        },
                      },
                      {
                        label: `${props.t("common.excel")} ${props.t("common.pt")}`,
                        onClick: () => {
                          exportExcelWithTitleRow(
                            piecesRaiserSummary?.map((data) => ({
                              Specialist: data?.specialist || "-",
                              "Items Quantity": data?.item_count || 0,
                            })),
                            `Leilão_${selectedAuction?.auction_no}-Resumo_do_Levantador_de_Peças`,
                            [40, 20],
                            `Leilão ${selectedAuction?.auction_no} - Resumo do Levantador de Peças`
                          );
                        },
                      },
                    ]}
                  />
                </div>
                <BarGraph
                  xAxisLabel={props.t("common.specialists")}
                  yAxisLabel={props.t("common.quantity")}
                  labels={piecesRaiserSummary?.map((item) => item?.specialist)}
                  data={piecesRaiserSummary?.map((item) => item?.item_count)}
                  barThickness={10}
                  aspectRatio={3}
                />
              </Row>
            </>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(Reports);
