import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Select from "react-select";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { getPaddles } from "../../../store/auctions/paddleActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaddleOptionLabel,
  getPaddleOptions,
  getTypeFromValue,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import debounce from "lodash.debounce";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import CustomButton from "../../common/CustomButton";
import NumberInput from "../../common/NumberInput";

const UpdateLot = ({ lot, isModalOpen, onCancel, onSave, loader, t, onClear }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const PADDLE_OPTIONS = useSelector((state) => state?.Auctions?.paddles?.data);

  const [paddleLoader, setPaddleLoader] = useState(false);
  const [paddlesPayload, setPaddlesPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "paddle_no",
    filter: { auction: id },
  });

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      paddle: lot?.paddle?.id ? lot?.paddle : "",
      sale_value:
        lot?.sale_value >= 0 && lot?.sale_value
          ? lot?.sale_value
          : lot?.contract_item?.min_estimate >= 0 && lot?.contract_item?.min_estimate
          ? lot?.contract_item?.min_estimate
          : "",
    },
    validationSchema: Yup.object({
      paddle: Yup.object().required(
        t("validation.selectValidationMsg", {
          field: `${t("common.paddle")}`,
        })
      ),
      sale_value: Yup.number()
        .min(0, t("validation.numberMin", { totalNumber: 0 }))
        .test("min_est", t("validation.saleValueGreateThanMinEst"), (value) => {
          if (lot?.contract_item?.min_estimate >= 0 && lot?.contract_item?.min_estimate)
            return value >= lot?.contract_item?.min_estimate;
          return true;
        })
        .required(
          t("validation.requiredValidationMsg", {
            field: `${t("common.saleValue")}`,
          })
        ),
    }),
    onSubmit: (values) => {
      onSave({ ...values, paddle: values?.paddle?.id || "" }, validation);
    },
  });

  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the customer search
  const handlePaddleSearch = (value) => {
    setPaddlesPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, search_paddle__icontains: value },
    }));
  };
  // debounce for customer search
  const paddleSearchDebounce = useMemo(() => {
    return debounce(handlePaddleSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      paddleSearchDebounce.cancel();
    };
  }, [paddleSearchDebounce]);

  // handle get paddles
  const handleGetPaddles = useCallback(
    (paddlesPayload) => {
      setPaddleLoader(true);
      dispatch(getPaddles(paddlesPayload))
        .then((res) => {
          setPaddleLoader(false);
        })
        .catch((error) => {
          setPaddleLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isModalOpen) {
      handleGetPaddles(paddlesPayload);
    }
  }, [paddlesPayload, handleGetPaddles, isModalOpen]);

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.editTitle", {
          module: t("common.lot"),
        })}{" "}
        {lot?.lot_no}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="paddle" className="form-label">
              {`${t("common.paddle")}*`}
            </Label>
            <Select
              isLoading={paddleLoader}
              name="paddle"
              options={paddleLoader ? [] : PADDLE_OPTIONS}
              getOptionLabel={getPaddleOptionLabel}
              getOptionValue={(option) => option?.id}
              className="custom-select"
              placeholder={`${t("common.select")} ${t("common.paddle")}`}
              value={validation.values.paddle || null}
              onChange={(paddle) => {
                validation.setFieldValue("paddle", paddle?.id ? paddle : "");
              }}
              onInputChange={paddleSearchDebounce}
              onBlur={(e) => {
                validation.setFieldTouched("paddle", true);
                validation.handleBlur(e);
              }}
            />
            {validation.touched.paddle && validation.errors.paddle ? (
              <p className="custom-invalid-feedback">{validation.errors.paddle}</p>
            ) : null}
          </div>

          <div className="mb-3">
            <Label htmlFor="sale_value" className="form-label">
              {`${t("common.saleValue")}*`}
            </Label>
            <NumberInput
              name="sale_value"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.saleValue")}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.sale_value}
              invalid={validation.touched.sale_value && validation.errors.sale_value ? true : false}
            />
            {validation.touched.sale_value && validation.errors.sale_value ? (
              <FormFeedback type="invalid">{validation.errors.sale_value}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          {lot?.paddle?.id === validation?.values?.paddle?.id ? (
            <CustomButton disabled={loader} btnTitle={t("common.clear")} color={"danger"} onClick={onClear} />
          ) : null}
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0">
                  {t("common.loading")}...
                </Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(UpdateLot);
