import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomLinkWrapper from "../../../../components/common/CustomLinkWrapper";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddTemplateModal from "../../../../components/pages/settings/AddTemplateModal";
import { extractNameFromUrl } from "../../../../helpers/utils";
import { addTemplate, deleteTemplate, getTemplates, updateTemplate } from "../../../../store/templates/actions";

const Templates = (props) => {
  const dispatch = useDispatch();
  const { data: templatesData, total: totalTemplates } = useSelector((state) => state?.Templates);

  const [templatesPayload, setTemplatesPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: {
      id__icontains: "",
      document__icontains: "",
      template_name__icontains: "",
    },
  });

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // add or edit action handlers
  const handleTemplateModalOpen = (templateData) => {
    setSelectedTemplate(templateData);
    setIsTemplateModalOpen(true);
  };
  const handleTemplateModalClose = () => {
    setSelectedTemplate(null);
    setIsTemplateModalOpen(false);
  };
  const handleSaveTemplate = (values, formikValidation) => {
    // check for add or edit
    if (selectedTemplate && selectedTemplate?.id) {
      // edit period
      setSaveLoader(true);
      dispatch(updateTemplate(selectedTemplate?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleTemplateModalClose();
            formikValidation.resetForm();
            handleGetTemplates(templatesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add period
      setSaveLoader(true);
      dispatch(addTemplate(values))
        .then((res) => {
          if (res) {
            // success handler
            handleTemplateModalClose();
            formikValidation.resetForm();
            handleGetTemplates(templatesPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (templateData) => {
    setSelectedTemplate(templateData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedTemplate(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteTemplate = () => {
    if (selectedTemplate && selectedTemplate?.id) {
      setDeleteLoader(true);
      dispatch(deleteTemplate(selectedTemplate?.id))
        .then((res) => {
          if (res) {
            handleGetTemplates(templatesPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setTemplatesPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setTemplatesPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setTemplatesPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get templates
  const handleGetTemplates = useCallback(
    (templatesPayload) => {
      setTableLoader(true);
      dispatch(getTemplates(templatesPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetTemplates(templatesPayload);
  }, [handleGetTemplates, templatesPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={templatesPayload}
            setPayload={setTemplatesPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,

      minWidth: "130px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.document")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="document__icontains"
            className="column-search-input"
            payload={templatesPayload}
            setPayload={setTemplatesPayload}
          />
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper
          link={row?.document}
          title={extractNameFromUrl(extractNameFromUrl(row?.document, "/", 5), ".", 0)}
        />
      ),
      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.description")}
            sortable
            sortableKey="template_name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="template_name__icontains"
            className="column-search-input"
            payload={templatesPayload}
            setPayload={setTemplatesPayload}
          />
        </div>
      ),
      selector: (row) => row?.template_name,

      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleTemplateModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.templates")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={templatesPayload}
            setPayload={setTemplatesPayload}
          />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleTemplateModalOpen}
              btnTitle={props.t("common.addTitle", {
                module: props.t("settings.lots.templates.template"),
              })}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.templates")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...templatesData]}
            pagination
            paginationServer
            paginationTotalRows={totalTemplates}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddTemplateModal
        template={selectedTemplate}
        isModalOpen={isTemplateModalOpen}
        onCancel={handleTemplateModalClose}
        onSave={handleSaveTemplate}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteTemplate}
        title={`${props.t("common.delete")} ${props.t("settings.lots.templates.template")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.lots.templates.template"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Templates);
