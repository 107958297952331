import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "reactstrap";
import CustomAddButton from "../../../../components/common/CustomAddButton";
import CustomColumnHeader from "../../../../components/common/CustomColumnHeader";
import CustomMultiSelect from "../../../../components/common/CustomMultiSelect";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddDocumentStatusModal from "../../../../components/pages/settings/AddDocumentStatusModal";
import { TAG_TYPES } from "../../../../helpers/constants/selectOptions";
import { checkSelectAllSelected, getTypeFromValue, getValuesFromArray } from "../../../../helpers/utils";
import {
  addDocumentStatus,
  deleteDocumentStatus,
  getDocumentStatus,
  updateDocumentStatus,
} from "../../../../store/document-status/actions";

const DocumentStatus = (props) => {
  const dispatch = useDispatch();
  const { data: documentStatusData, total: totalDocumentStatus } = useSelector((state) => state?.DocumentStatus);

  const [documentStatusPayload, setDocumentStatusPayload] = useState({
    search: "",
    page: 1,
    limit: 10,
    sort: "",
    filter: {
      id__icontains: "",
      fiscal_code__icontains: "",
      description__icontains: "",
    },
  });

  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDocumentStatusModalOpen, setIsDocumentStatusModalOpen] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  // add or edit action handlers
  const handleDocumentStatusModalOpen = (currencyData) => {
    setSelectedDocumentStatus(currencyData);
    setIsDocumentStatusModalOpen(true);
  };
  const handleDocumentStatusModalClose = () => {
    setSelectedDocumentStatus(null);
    setIsDocumentStatusModalOpen(false);
  };
  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes({
        value: "*",
        text: props.t("common.selectAll"),
      });
      setDocumentStatusPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setDocumentStatusPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setDocumentStatusPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };
  const handleSaveDocumentStatus = (values, formikValidation) => {
    // check for add or edit
    if (selectedDocumentStatus && selectedDocumentStatus?.id) {
      // edit currency
      setSaveLoader(true);
      dispatch(updateDocumentStatus(selectedDocumentStatus?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleDocumentStatusModalClose();
            formikValidation.resetForm();
            handleGetDocumentStatus(documentStatusPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    } else {
      // add currency
      setSaveLoader(true);
      dispatch(addDocumentStatus(values))
        .then((res) => {
          if (res) {
            // success handler
            handleDocumentStatusModalClose();
            formikValidation.resetForm();
            handleGetDocumentStatus(documentStatusPayload);
          } else {
            // failure handler
          }
          setSaveLoader(false);
        })
        .catch(() => {
          setSaveLoader(false);
        });
    }
  };

  // delete action handlers
  const handleDeleteModalOpen = (currencyData) => {
    setSelectedDocumentStatus(currencyData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedDocumentStatus(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteDocumentStatus = () => {
    if (selectedDocumentStatus && selectedDocumentStatus?.id) {
      setDeleteLoader(true);
      dispatch(deleteDocumentStatus(selectedDocumentStatus?.id))
        .then((res) => {
          if (res) {
            handleGetDocumentStatus(documentStatusPayload);
          }
          setDeleteLoader(false);
          handleDeleteModalClose();
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setDocumentStatusPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setDocumentStatusPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setDocumentStatusPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get docuement status
  const handleGetDocumentStatus = useCallback(
    (documentStatusPayload) => {
      setTableLoader(true);
      dispatch(getDocumentStatus(documentStatusPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );
  useEffect(() => {
    handleGetDocumentStatus(documentStatusPayload);
  }, [handleGetDocumentStatus, documentStatusPayload]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={documentStatusPayload}
            setPayload={setDocumentStatusPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,

      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.fiscalCode")}
            sortable
            sortableKey="fiscal_code"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="fiscal_code__icontains"
            className="column-search-input"
            payload={documentStatusPayload}
            setPayload={setDocumentStatusPayload}
          />
        </div>
      ),
      selector: (row) => row?.fiscal_code,
      minWidth: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.tag")}
            sortable
            sortableKey="tag"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            options={[
              {
                value: "*",
                text: props.t("common.selectAll"),
              },
              ...TAG_TYPES,
            ]}
            selectedOptions={selectedTags}
            getOptionLabel={(e) => (
              <div className="d-flex align-items-center">
                <i class={`fs-18 ${e.iconClasses}`} />
                <span className="ms-3">{e.text}</span>
              </div>
            )}
            handleOnSelection={(values) => {
              setSelectedTags(values);
              handleDropdownFilter(values, "tag__in", setSelectedTags);
            }}
          />
        </div>
      ),
      selector: (row) => <i class={`fs-24 ${getTypeFromValue(TAG_TYPES, row?.tag)?.iconClasses}`} />,
      minWidth: "150px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={documentStatusPayload}
            setPayload={setDocumentStatusPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      minWidth: "400px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDocumentStatusModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      minWidth: "150px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.documentStatus")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header ">
          <CustomSearchInput
            className="custom-search-input"
            payload={documentStatusPayload}
            setPayload={setDocumentStatusPayload}
          />
          <div className="contain-header-right">
            <CustomAddButton
              color="success"
              onClick={handleDocumentStatusModalOpen}
              btnTitle={props.t("settings.billing.documentStatus.addDocumentStatus")}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.documentStatus")}</CardHeader>
          <DataTable
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...documentStatusData]}
            pagination
            paginationServer
            paginationTotalRows={totalDocumentStatus}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* add / edit modal */}
      <AddDocumentStatusModal
        documentStatus={selectedDocumentStatus}
        isModalOpen={isDocumentStatusModalOpen}
        onCancel={handleDocumentStatusModalClose}
        onSave={handleSaveDocumentStatus}
        loader={saveLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteDocumentStatus}
        title={`${props.t("common.delete")} ${props.t("sidebarMenu.documentStatus")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("settings.billing.documentStatus.documentStatus_small"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(DocumentStatus);
