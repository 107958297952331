import { FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, CardFooter, Col, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import * as Yup from "yup";

// import PhoneInput, {
//   formatPhoneNumber,
//   formatPhoneNumberIntl,
//   isValidPhoneNumber,
// } from "react-phone-number-input";
import moment from "moment";
import { toast } from "react-toastify";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import Loader from "../../components/common/Loader";
import MessageBlock from "../../components/common/MessageBlock";
import PhoneNumberInput from "../../components/common/PhoneNumberInput";
import CatalogueSubscriptionTable from "../../components/pages/customers/CatalogueSubscriptionTable";
import { Option } from "../../components/pages/settings/AddUserModal";
import { MAX_DATE } from "../../helpers/constants";
import {
  ACTIVITY_AREA_OPTION,
  LANGUAGE_OPTIONS,
  NEW_PERSONA_TYPE,
  SELECT_ALL_OPTION,
} from "../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  getColumnDetail,
  getLabelValueArray,
  getValuesFromArray,
  onKeyPressForm,
  removeFieldsFromObj,
  scrollToFirstFormikError,
  sortByObjectsByName,
} from "../../helpers/utils";
import { getCountriesOptions } from "../../store/common/actions";
import {
  addCustomer,
  getCustomerDetails,
  getPersonDetails,
  getShippingOptions,
  updateCustomer,
} from "../../store/customers/actions";
import { getTitles } from "../../store/titles/actions";
import CustomButton from "../../components/common/CustomButton";

const PersonaForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paramId, personId } = useParams();

  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);
  const { selectedCustomerData: customerData, selectedPersonData: personData } = useSelector(
    (state) => state?.Customers,
  );
  const shippingOptionList = useSelector((state) => state?.Customers?.shippingOptions?.results || []);
  const preferencesList = sortByObjectsByName(
    useSelector((state) => state?.common?.customerPreferences?.data),
    getColumnDetail("name_en", "name_pt"),
  );
  const tagOptionList = useSelector((state) => state?.Customers?.tagOptions?.results || []);

  // customer genral titles and communication titles
  const [generalTitles, setGeneralTitles] = useState([]);
  const [communicationTitles, setCommunicationTitles] = useState([]);

  // loaders
  const [customerSaveLoader, setCustomerSaveLoader] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);

  // form states
  const [selectedPersonaType, setSelectedPersonaType] = useState(
    NEW_PERSONA_TYPE.find((item) => item.value === personData?.customer_type),
  );
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedActivityArea, setSelectedActivityArea] = useState("");
  const [selectedPostCards, setSelectedPostCards] = useState("");
  const [selectedPreference, setSelectedPreference] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedClientCountry, setSelectedClientCountry] = useState("");
  const [selectedCompanyCountry, setSelectedCompanyCountry] = useState("");
  const [vatCheckbox, setVatCheckbox] = useState(false);
  const [companyVATCheckbox, setCompanyVATCheckbox] = useState(false);

  const setAllDropDowns = useCallback(() => {
    setSelectedPersonaType(
      () => NEW_PERSONA_TYPE.find((item) => item.value === personData?.customer_type) || NEW_PERSONA_TYPE[0],
    );
    setSelectedTags(
      getLabelValueArray(
        tagOptionList.filter(
          (list) => personData && personData?.tags && personData?.tags?.some((item) => item.id === list.id),
        ),
        "id",
        getColumnDetail("name_en", "name_pt"),
      ),
    );
    if (personId) {
      setSelectedPostCards(
        getLabelValueArray(
          shippingOptionList.filter(
            (list) => personData && personData?.postcards && personData?.postcards.some((item) => item.id === list.id),
          ),
          "id",
          getColumnDetail("name_en", "name_pt"),
        ),
      );
    } else {
      setSelectedPostCards([]);
    }

    setSelectedPreference(
      getLabelValueArray(
        preferencesList.filter(
          (list) => personData && personData?.preferences && personData?.preferences.some((item) => item.id === list.id),
        ),
        "id",
        getColumnDetail("name_en", "name_pt"),
      ),
    );
    setSelectedLanguage(LANGUAGE_OPTIONS.find((item) => item.value === personData?.language) || "");
    setSelectedClientCountry(
      getLabelValueArray(contriesOptions, "id", "name").find(
        (item) => item.value === (personData && personData?.addresses && personData?.addresses[0]?.country?.id),
      ) ||
      getLabelValueArray(contriesOptions, "id", "name").find((item) => item.label === "Portugal") ||
      "",
    );
    setSelectedActivityArea(ACTIVITY_AREA_OPTION.find((item) => item.value === personData?.activity_area) || "");
    setSelectedCompanyCountry(
      getLabelValueArray(contriesOptions, "id", "name").find(
        (item) => item.value === (personData && personData?.addresses && personData?.addresses[1]?.country?.id),
      ) ||
      getLabelValueArray(contriesOptions, "id", "name").find((item) => item.label === "Portugal") ||
      "",
    );
  }, [contriesOptions, personData, shippingOptionList, tagOptionList]);

  // // for reseting form
  const handleResetForm = useCallback(() => {
    setAllDropDowns();
    validation.resetForm();
  }, [setAllDropDowns]);

  const navigateToCustomerListPage = useCallback(() => {
    history.push("/customers");
  }, [history]);

  const navigateToCustomerEditPage = () => {
    history.push(`/customers/edit/${paramId}`);
  };

  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "shipping" },
      }),
    );
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "tag" },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  // to set all state of form
  useEffect(() => {
    if (!personId) {
      return;
    }
    setAllDropDowns();
  }, [personId, setAllDropDowns]);

  // to get the list of the general titles
  useEffect(() => {
    dispatch(getTitles({ filter: { title_type: "general" } })).then((res) => {
      if (res?.results) setGeneralTitles(res?.results);
    });
  }, [dispatch]);

  // to get the list of the communication titles
  useEffect(() => {
    dispatch(getTitles({ filter: { title_type: "communication" } })).then((res) => {
      if (res?.results) setCommunicationTitles(res?.results);
    });
  }, [dispatch]);

  useEffect(() => {
    if (paramId) {
      // get details of cusomer from customer id in url
      setFetchLoader(true);
      dispatch(getCustomerDetails({ customerId: paramId }))
        .then((res) => {
          if (res) {
            setFetchLoader(false);
          } else {
            setFetchLoader(false);
            navigateToCustomerListPage();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (personId) {
      dispatch(getPersonDetails({ personId: personId }))
        .then((res) => {
          if (res) {
            setFetchLoader(false);
          } else {
            setFetchLoader(false);
            navigateToCustomerListPage();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch, paramId, navigateToCustomerListPage, personId]);

  const handleSaveCustomer = (values, formikValidation) => {
    if (!values?.is_seller && !values?.is_buyer) {
      return toast.error(props.t("validation.customerType"));
    }
    // to only assign value not reference
    const formValues = Object.assign({}, values);

    // for editing person
    if (personId && personData) {
      if (values?.customer_type === "personal") {
        let addresses = [
          {
            id: personData && personData?.addresses && personData?.addresses[0]?.id,
            address_type: "client",
            address: formValues.client_address,
            post_code: formValues.client_post_code,
            city: formValues.client_city,
            country_id: formValues.client_country,
          },
        ];
        let cusPayload = removeFieldsFromObj(formValues, [
          "client_address",
          "client_post_code",
          "client_city",
          "client_country",
        ]);
        cusPayload.addresses = addresses;

        setCustomerSaveLoader(true);
        dispatch(
          updateCustomer({
            id: personData && personData?.id,
            payload: {
              ...cusPayload,
              title_comm: cusPayload?.title_comm?.id || null,
              title_gen: cusPayload?.title_gen?.id || null,
            },
          }),
        )
          .then((res) => {
            if (res) {
              setCustomerSaveLoader(false);
              handleResetForm();
              navigateToCustomerEditPage();
            }
            setCustomerSaveLoader(false);
          })
          .catch(() => {
            setCustomerSaveLoader(false);
          });
      }
      if (values?.customer_type === "company") {
        let addresses = [
          {
            id: personData && personData?.addresses && personData?.addresses[0]?.id,
            address_type: "client",
            address: formValues.client_address,
            post_code: formValues.client_post_code,
            city: formValues.client_city,
            country_id: formValues.client_country,
          },
          {
            id: personData && personData?.addresses && personData?.addresses[1]?.id,
            address_type: "company",
            address: formValues.company_address,
            post_code: formValues.company_post_code,
            city: formValues.company_city,
            country_id: formValues.company_country,
          },
        ];
        let cusPayload = removeFieldsFromObj(formValues, [
          "company_address",
          "company_city",
          "company_country",
          "company_post_code",
        ]);
        cusPayload.addresses = addresses;

        setCustomerSaveLoader(true);
        dispatch(
          updateCustomer({
            id: personData && personData?.id,
            payload: cusPayload,
          }),
        )
          .then((res) => {
            if (res) {
              setCustomerSaveLoader(false);
              handleResetForm();
              navigateToCustomerEditPage();
            }
            setCustomerSaveLoader(false);
          })
          .catch(() => {
            setCustomerSaveLoader(false);
          });
      }
    }
    // for creating person
    else {
      if (paramId && selectedPersonaType?.value === "personal") {
        let addresses = [
          {
            address_type: "client",
            address: formValues.client_address,
            post_code: formValues.client_post_code,
            city: formValues.client_city,
            country_id: formValues.client_country,
          },
        ];
        let cusPayload = removeFieldsFromObj(formValues, [
          "client_address",
          "client_post_code",
          "client_city",
          "client_country",
        ]);
        cusPayload.addresses = addresses;
        setCustomerSaveLoader(true);
        dispatch(
          addCustomer({
            ...cusPayload,
            title_comm: cusPayload?.title_comm?.id || null,
            title_gen: cusPayload?.title_gen?.id || null,
          }),
        )
          .then((res) => {
            if (res) {
              setCustomerSaveLoader(false);
              handleResetForm();
              navigateToCustomerEditPage();
            }
            setCustomerSaveLoader(false);
          })
          .catch(() => {
            setCustomerSaveLoader(false);
          });
      } else {
        let addresses = [
          {
            address_type: "client",
            address: formValues.client_address,
            post_code: formValues.client_post_code,
            city: formValues.client_city,
            country_id: formValues.client_country,
          },
          {
            address_type: "company",
            address: formValues.company_address,
            post_code: formValues.company_post_code,
            city: formValues.company_city,
            country_id: formValues.company_country,
          },
        ];
        let cusPayload = removeFieldsFromObj(formValues, [
          "client_address",
          "client_post_code",
          "client_city",
          "client_country",
          "company_address",
          "company_city",
          "company_country",
          "company_post_code",
        ]);
        cusPayload.addresses = addresses;
        setCustomerSaveLoader(true);
        dispatch(addCustomer(cusPayload))
          .then((res) => {
            if (res) {
              // success handler
              setCustomerSaveLoader(false);
              handleResetForm();
              navigateToCustomerEditPage();
            } else {
              // failure handler
            }
            setCustomerSaveLoader(false);
          })
          .catch(() => {
            setCustomerSaveLoader(false);
          });
      }
    }
  };
  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key, setState) => {
    if (checkSelectAllSelected(selectedItem)) {
      setState([
        SELECT_ALL_OPTION,
        ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
      ]);
      validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value),
      );
    } else {
      if (selectedItem.length > 0) {
        setState(selectedItem);
        validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        setState("");
        validation.setFieldValue(key, []);
      }
    }
  };

  const clientValidationSchema = {
    customer_type:
      Yup.string().oneOf[
        (("peronal", "company"),
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.customerType")}`)
        ],
    title_gen: Yup.object().required(
      `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.title")}`,
    ),
    title_comm: Yup.object().required(
      `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.title")}`,
    ),
    name: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.name")}`),
    surname: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.surname")}`),
    client_vat: Yup.string()
      .max(20, props.t("validation.maxChars", { totalChars: 20 }))
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.vat")}`),
    passport_no: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.idOrpassport")}`),
    iban_no: Yup.string()
      .trim()
      .max(35, `${props.t("validation.numberMax", { totalNumber: 35 })}`),
    contact_1: Yup.number().required(
      `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.contact1")}`,
    ),

    email: Yup.string()
      .trim()
      .email(`${props.t("settings.general.users.emailValidationMag1")}`)
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.email")}`),
    tags: Yup.array(),
    postcards: Yup.array(),
    preferences: Yup.array(),
    language: Yup.string()
      .trim()
      .required(
        props.t("validation.selectValidationMsg", {
          field: `${props.t("settings.general.language.language")}`,
        }),
      ),
    internal_observations: Yup.string().trim(),
    client_city: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")}  ${props.t("settings.general.report.city")}`),
    client_country: Yup.string()
      .trim()
      .required(
        `${props.t("common.please")} ${props.t("common.select")}  ${props.t("settings.general.report.country")}`,
      ),
    client_post_code: Yup.string()
      .trim()
      .max(15, `${props.t("validation.numberMax", { totalNumber: 15 })}`)
      .required(
        `${props.t("common.please")} ${props.t("common.enter")}  ${props.t("settings.general.report.postCode")}`,
      ),
    client_address: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")}  ${props.t("common.address")}`),
    is_active: Yup.boolean().oneOf(
      [true, false],
      `${props.t("common.selectValidationMsg", {
        field: `${props.t("common.status")}`,
      })}`,
    ),
    catalogue_subscriptions: Yup.array().of(
      Yup.object().shape({
        catalogues: Yup.array()
          .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
        start_date: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            }),
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
          .test(
            "maximum-year",
            props.t("validation.yearMax", {
              label: `9999`,
            }),
            (value) => {
              return value < moment(MAX_DATE);
            },
          ),
        end_date: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            }),
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
          .test(
            "minimum-datetime",
            props.t("validation.dateMin", {
              label: props.t("common.startDate"),
            }),
            (value, context) => {
              if (!context?.from?.[0]?.value?.start_date) {
                return true;
              }
              return value > new Date(context?.from?.[0]?.value?.start_date);
            },
          )
          .test(
            "maximum-year",
            props.t("validation.yearMax", {
              label: `9999`,
            }),
            (value) => {
              return value < moment(MAX_DATE);
            },
          ),
      }),
    ),
    catalogue_shippings: Yup.array().of(
      Yup.object().shape({
        catalogues: Yup.array()
          .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
      }),
    ),
  };

  const companyValidationScehma = {
    customer_type:
      Yup.string().oneOf[
        (("peronal", "company"),
          `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.customerType")}`)
        ],
    contact_1: Yup.number().required(
      `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.contact1")}`,
    ),

    email: Yup.string()
      .trim()
      .email(`${props.t("settings.general.users.emailValidationMag1")}`)
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.email")}`),
    company_name: Yup.string()
      .trim()
      .required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.company")} ${props.t("common.name")}`,
      ),
    activity_area: Yup.string()
      .trim()
      .required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.activity")} ${props.t(
          "common.area",
        )}`,
      ),
    company_vat: Yup.string()
      .max(20, props.t("validation.maxChars", { totalChars: 20 }))
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.vat")}`),
    tags: Yup.array(),
    postcards: Yup.array(),
    preferences: Yup.array(),
    language: Yup.string()
      .trim()
      .required(
        props.t("validation.selectValidationMsg", {
          field: `${props.t("settings.general.language.language")}`,
        }),
      ),
    internal_observations: Yup.string().trim(),
    company_country: Yup.string()
      .trim()
      .required(
        `${props.t("common.please")} ${props.t("common.select")} ${props.t("settings.general.report.country")}`,
      ),
    company_city: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("settings.general.report.city")}`),
    company_post_code: Yup.string()
      .trim()
      .max(15, `${props.t("validation.numberMax", { totalNumber: 15 })}`)
      .required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("settings.general.report.postCode")}`,
      ),
    company_address: Yup.string()
      .trim()
      .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.address")}`),
    is_active: Yup.boolean().oneOf(
      [true, false],
      `${props.t("common.selectValidationMsg", {
        field: `${props.t("common.status")}`,
      })}`,
    ),
    catalogue_subscriptions: Yup.array().of(
      Yup.object().shape({
        catalogues: Yup.array()
          .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
        start_date: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            }),
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
          .test(
            "maximum-year",
            props.t("validation.yearMax", {
              label: `9999`,
            }),
            (value) => {
              return value < moment(MAX_DATE);
            },
          ),
        end_date: Yup.date()
          .typeError(
            props.t("validation.formatGeneral", {
              enterOrSelect: props.t("common.select"),
              field: props.t("common.date"),
            }),
          )
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.date")}`)
          .test(
            "minimum-datetime",
            props.t("validation.dateMin", {
              label: props.t("common.startDate"),
            }),
            (value, context) => {
              if (!context?.from?.[0]?.value?.start_date) {
                return true;
              }
              return value > new Date(context?.from?.[0]?.value?.start_date);
            },
          )
          .test(
            "maximum-year",
            props.t("validation.yearMax", {
              label: `9999`,
            }),
            (value) => {
              return value < moment(MAX_DATE);
            },
          ),
      }),
    ),
    catalogue_shippings: Yup.array().of(
      Yup.object().shape({
        catalogues: Yup.array()
          .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`)
          .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("customers.catalogues")}`),
      }),
    ),
  };

  const intialValuesForClient = {
    parent_id: paramId ? Number(paramId) : null,
    customer_type: selectedPersonaType?.value || "",
    title_comm: personData?.title_comm?.id ? personData?.title_comm : "",
    title_gen: personData?.title_gen?.id ? personData?.title_gen : "",
    name: personData?.name || "",
    surname: personData?.surname || "",
    birth_date: personData?.birth_date || null,
    client_vat: personData?.client_vat || "",
    passport_no: personData?.passport_no || "",
    iban_no: personData?.iban_no || "",
    contact_1: !personId && customerData ? customerData?.contact_1 : personData?.contact_1 || "",
    contact_2: !personId && customerData ? customerData?.contact_2 : personData?.contact_2 || "",
    email: !personId && customerData ? customerData?.email : personData?.email || "",
    client_address: (personData && personData?.addresses && personData?.addresses[0]?.address) || "",
    client_post_code: (personData && personData?.addresses && personData?.addresses[0]?.post_code) || "",
    client_city: (personData && personData?.addresses && personData?.addresses[0]?.city) || "",
    client_country:
      (personData && personData?.addresses && personData?.addresses[0]?.country?.id) ||
      contriesOptions?.find((country) => country?.code === "PT")?.id ||
      "",
    tags: (personData && personData?.tags && personData?.tags?.map((item) => item?.id)) || [],
    postcards: (personData && personData?.postcards && personData?.postcards?.map((item) => item?.id)) || [
      ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")).map((item) => item?.value),
    ],
    preferences: (personData && personData?.preferences && personData?.preferences?.map((item) => item?.id)) || [],
    language: personData?.language || "",
    internal_observations: personData?.internal_observations || "",
    is_active: personId ? (personData?.is_active ? true : false) : true,
    is_buyer: personId ? (personData?.is_buyer ? true : false) : false,
    is_seller: personId ? (personData?.is_seller ? true : false) : false,
    is_subscribed_to_newsletter: personId ? personData?.is_subscribed_to_newsletter : true,
    catalogue_subscriptions:
      personId && personData?.catalogue_subscriptions?.length > 0
        ? personData?.catalogue_subscriptions?.map((item) => ({
          ...item,
          catalogues: item?.catalogues?.map((item) => item?.id),
        }))
        : [],
    catalogue_shippings:
      personId && personData?.catalogue_shippings?.length > 0
        ? personData?.catalogue_shippings?.map((item) => ({
          ...item,
          catalogues: item?.catalogues?.map((item) => item?.id),
        }))
        : [],
  };
  const intialValuesForCompany = {
    parent_id: paramId ? Number(paramId) : null,
    customer_type: selectedPersonaType?.value || "",
    contact_1: !personId && customerData ? customerData?.contact_1 : personData?.contact_1 || "",
    contact_2: !personId && customerData ? customerData?.contact_2 : personData?.contact_2 || "",
    email: !personId && customerData ? customerData?.email : personData?.email || "",
    company_name: personData?.company_name || "",
    activity_area: personData?.activity_area || "",
    company_vat: personData?.company_vat || "",
    iban_no: personData?.iban_no || "",
    company_address: (personData && personData?.addresses && personData?.addresses[1]?.address) || "",
    company_post_code: (personData && personData?.addresses && personData?.addresses[1]?.post_code) || "",
    company_city: (personData && personData?.addresses && personData?.addresses[1]?.city) || "",
    company_country:
      (personData && personData?.addresses && personData?.addresses[1]?.country?.id) ||
      contriesOptions?.find((country) => country?.code === "PT")?.id ||
      "",
    tags: (personData && personData?.tags && personData?.tags?.map((item) => item?.id)) || [],
    postcards: (personData && personData?.postcards && personData?.postcards?.map((item) => item?.id)) || [
      ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")).map((item) => item?.value),
    ],
    preferences: (personData && personData?.preferences && personData?.preferences?.map((item) => item?.id)) || [],
    language: personData?.language || "",
    internal_observations: personData?.internal_observations || "",
    is_active: personId ? (personData?.is_active ? true : false) : true,
    is_buyer: personId ? (personData?.is_buyer ? true : false) : false,
    is_seller: personId ? (personData?.is_seller ? true : false) : false,
    is_subscribed_to_newsletter: personId ? personData?.is_subscribed_to_newsletter : true,
    catalogue_subscriptions:
      personId && personData?.catalogue_subscriptions?.length > 0
        ? personData?.catalogue_subscriptions?.map((item) => ({
          ...item,
          catalogues: item?.catalogues?.map((item) => item?.id),
        }))
        : [],
    catalogue_shippings:
      personId && personData?.catalogue_shippings?.length > 0
        ? personData?.catalogue_shippings?.map((item) => ({
          ...item,
          catalogues: item?.catalogues?.map((item) => item?.id),
        }))
        : [],
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: selectedPersonaType?.value === "personal" ? intialValuesForClient : intialValuesForCompany,
    validationSchema: Yup.object(
      selectedPersonaType?.value === "personal" ? clientValidationSchema : companyValidationScehma,
    ),
    onSubmit: (values, validation) => {
      handleSaveCustomer(values, validation);
    },
  });

  // get the customer history data
  const getCustomerHistoryData = (customerHistory) => {
    if (customerHistory?.action === "change_customer") {
      const data = [];
      const keys = [
        { value: "address", label: "customerHistory.changeInAddress" },
        { value: "city", label: "customerHistory.changeInCity" },
        {
          value: "contact_1",
          label: "customerHistory.changeInContact1",
          remove: "customerHistory.removedContact1",
          new: "customerHistory.newContact1",
        },
        {
          value: "contact_2",
          label: "customerHistory.changeInContact2",
          remove: "customerHistory.removedContact2",
          new: "customerHistory.newContact2",
        },
        {
          value: "country",
          label: "customerHistory.changeInCountry",
        },
        { value: "email", label: "customerHistory.changeInEmail" },
        {
          value: "iban_no",
          label: "customerHistory.changeInIban",
          remove: "customerHistory.removedIBAN",
          new: "customerHistory.newIBAN",
        },
        {
          value: "passport_no",
          label: "customerHistory.changeInId/Passport",
        },
        { value: "post_code", label: "customerHistory.changeInPostCode" },
      ];
      keys.forEach((key, index) => {
        if (customerHistory?.metadata?.[`old_${key?.value}`] && !customerHistory?.metadata?.[`new_${key?.value}`]) {
          data.push(
            props.t(key?.remove, {
              old: customerHistory?.metadata?.[`old_${key?.value}`],
            }),
          );
        } else if (
          !customerHistory?.metadata?.[`old_${key?.value}`] &&
          customerHistory?.metadata?.[`new_${key?.value}`]
        ) {
          data.push(
            props.t(key?.new, {
              new: customerHistory?.metadata?.[`new_${key?.value}`],
            }),
          );
        } else if (customerHistory?.metadata?.[`old_${key?.value}`] || customerHistory?.metadata?.[`new_${key?.value}`])
          data.push(
            props.t(key?.label, {
              old: customerHistory?.metadata?.[`old_${key?.value}`],
              new: customerHistory?.metadata?.[`new_${key?.value}`],
            }),
          );
      });
      return (
        <>
          {data?.map((item) => (
            <>
              {item}
              <br />
            </>
          ))}
        </>
      );
    }
  };

  const personalDetailsColumn = (
    <Col className="col-sm-12 col-md-12 col-xl-4">
      {/* title communication field */}
      <Row>
        <div className="mb-3">
          <Label className="form-label">{`${props.t("common.titleForCommunication")}*`}</Label>
          <Select
            name="title_comm"
            options={communicationTitles}
            getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
            getOptionValue={(option) => option?.id}
            value={validation?.values?.title_comm}
            className={`custom-select ${
              validation.touched.title_comm && validation.errors.title_comm ? "select-error" : ""
            }`}
            placeholder={`${props.t("common.select")} ${props.t("common.title")}`}
            onChange={(title) => {
              validation.setFieldValue("title_comm", title || "");
            }}
            onBlur={(e) => {
              validation.setFieldTouched("title_comm", true);
              validation.handleBlur(e);
            }}
          />
          {validation.touched.title_comm && validation.errors.title_comm ? (
            <span className="custom-invalid-feedback">{validation.errors.title_comm}</span>
          ) : null}
        </div>
      </Row>
      {/* title field */}
      <Row>
        <div className="mb-3">
          <Label className="form-label">{`${props.t("common.title")}*`}</Label>
          <Select
            name="title_gen"
            options={generalTitles}
            getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
            getOptionValue={(option) => option?.id}
            value={validation?.values?.title_gen}
            className={`custom-select ${
              validation.touched.title_gen && validation.errors.title_gen ? "select-error" : ""
            }`}
            placeholder={`${props.t("common.select")} ${props.t("common.title")}`}
            onChange={(title) => {
              validation.setFieldValue("title_gen", title || "");
            }}
            onBlur={(e) => {
              validation.setFieldTouched("title_gen", true);
              validation.handleBlur(e);
            }}
          />
          {validation.touched.title_gen && validation.errors.title_gen ? (
            <span className="custom-invalid-feedback">{validation.errors.title_gen}</span>
          ) : null}
        </div>
      </Row>
      {/* name field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="name" className="form-label">
            {`${props.t("common.name")}*`}
          </Label>
          <Input
            name="name"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.name")}`}
            type="text"
            value={validation.values.name || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.name && validation.errors.name ? true : false}
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* surname field */}
      <Row>
        <div className="mb-3 789456">
          <Label htmlFor="surname" className="form-label">
            {`${props.t("common.surname")}*`}
          </Label>
          <Input
            name="surname"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.surname")}`}
            type="text"
            value={validation.values.surname || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.surname && validation.errors.surname ? true : false}
          />
          {validation.touched.surname && validation.errors.surname ? (
            <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* birthday field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="birthday" className="form-label">
            {`${props.t("common.birthDate")}`}
          </Label>
          <Input
            name="birth_date"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.birthDate")}`}
            type="date"
            max={new Date().toISOString().split("T")[0]}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.birth_date || ""}
            invalid={validation.touched.birth_date && validation.errors.birth_date ? true : false}
          />
          {validation.touched.birth_date && validation.errors.birth_date ? (
            <FormFeedback type="invalid">{validation.errors.birth_date}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* client_vat field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="birthday" className="form-label">
            {`${props.t("common.vat")}*`}
          </Label>
          <Input
            name="client_vat"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.vat")}`}
            type="text"
            value={validation.values.client_vat || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.client_vat && validation.errors.client_vat ? true : false}
          />
          {validation.touched.client_vat && validation.errors.client_vat ? (
            <FormFeedback type="invalid">{validation.errors.client_vat}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* No VAT */}
      <Row>
        <div className="mb-3">
          <Label className="form-label">No VAT number</Label>
          <div className="form-check form-switch">
            <input
              type="checkbox"
              id="setVatCheckbox"
              name="setVatCheckbox"
              checked={vatCheckbox}
              onChange={(e) => {
                setVatCheckbox(e.target.checked);
                if (e.target.checked) {
                  validation.setFieldValue("client_vat", "999999990");
                } else {
                  validation.setFieldValue("client_vat", ""); // Reset to empty if unchecked
                }
              }}
              className="form-check-input"
            />
          </div>
        </div>
      </Row>
      {/* id/passport passport_no field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="passport_no" className="form-label">
            {`${props.t("common.idOrpassport")}*`}
          </Label>
          <Input
            name="passport_no"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.idOrpassport")}`}
            type="text"
            value={validation.values.passport_no || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.passport_no && validation.errors.passport_no ? true : false}
          />
          {validation.touched.passport_no && validation.errors.passport_no ? (
            <FormFeedback type="invalid">{validation.errors.passport_no}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* iban_no field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="iban_no" className="form-label">
            {`${props.t("common.iban")}`}
          </Label>
          <Input
            name="iban_no"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.iban")}`}
            type="text"
            value={validation.values.iban_no || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.iban_no && validation.errors.iban_no ? true : false}
          />
          {validation.touched.iban_no && validation.errors.iban_no ? (
            <FormFeedback type="invalid">{validation.errors.iban_no}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* customer history */}
      {personId ? (
        <Row>
          <div className="mb-3">
            <Label className="form-label">{props.t("common.customerHistory")}</Label>
            <div className="details-border-box">
              <div className="p-1 px-2 fs-13">
                {props.t("customerHistory.accountCreatedAt", {
                  date: moment(personData?.created_at).format("DD-MM-YYYY, HH:mm"),
                })}
              </div>
              {personData?.customer_histories?.length > 0 ? (
                <>
                  <hr />
                  {personData?.customer_histories?.map((customerHistory, index) => (
                    <div key={0}>
                      <div className="p-1 px-2 fs-13">{getCustomerHistoryData(customerHistory)}</div>
                      {index !== personData?.customer_histories?.length - 1 && <hr />}
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Row>
      ) : null}
    </Col>
  );
  const companyDetailsColumn = (
    <Col className="col-sm-12 col-md-12 col-xl-4">
      {/* company name */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_name" className="form-label">
            {`${props.t("common.company")} ${props.t("common.name")}*`}
          </Label>
          <Input
            name="company_name"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.company")} ${props.t("common.name")}`}
            type="text"
            value={validation.values.company_name || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.company_name && validation.errors.company_name ? true : false}
          />
          {validation.touched.company_name && validation.errors.company_name ? (
            <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* activity area */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="activity_area" className="form-label">
            {`${props.t("common.activity")} ${props.t("common.area")}*`}
          </Label>
          <Select
            name="activity_area"
            options={ACTIVITY_AREA_OPTION}
            value={selectedActivityArea}
            className={`custom-select ${
              validation.touched.activity_area && validation.errors.activity_area ? "select-error" : ""
            }`}
            placeholder={`${props.t("common.select")} ${props.t("common.activity")} ${props.t("common.area")}`}
            onChange={(activity) => {
              setSelectedActivityArea(activity);
              validation.setFieldValue("activity_area", activity?.value || "");
            }}
            onBlur={(e) => {
              validation.setFieldTouched("activity_area", true);
              validation.handleBlur(e);
            }}
          />
          {validation.touched.activity_area && validation.errors.activity_area ? (
            <span className="custom-invalid-feedback">{validation.errors.activity_area}</span>
          ) : null}
        </div>
      </Row>
      {/* vat */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_vat" className="form-label">
            {`${props.t("common.vat")}*`}
          </Label>
          <Input
            name="company_vat"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.vat")}`}
            type="text"
            value={validation.values.company_vat || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.company_vat && validation.errors.company_vat ? true : false}
          />
          {validation.touched.company_vat && validation.errors.company_vat ? (
            <FormFeedback type="invalid">{validation.errors.company_vat}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* No VAT */}
      <Row>
        <div className="mb-3">
          <Label className="form-label">No VAT number</Label>
          <div className="form-check form-switch">
            <input
              type="checkbox"
              id="setCompanyVatCheckbox"
              name="setCompanyVatCheckbox"
              checked={companyVATCheckbox}
              onChange={(e) => {
                setCompanyVATCheckbox(e.target.checked);
                if (e.target.checked) {
                  validation.setFieldValue("company_vat", "999999990");
                } else {
                  validation.setFieldValue("company_vat", ""); // Reset to empty if unchecked
                }
              }}
              className="form-check-input"
            />
          </div>
        </div>
      </Row>
      {/* company address field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_address" className="form-label">
            {`${props.t("common.address")}*`}
          </Label>
          <Input
            name="company_address"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("common.address")}`}
            type="text"
            value={validation.values.company_address || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.company_address && validation.errors.company_address ? true : false}
          />
          {validation.touched.company_address && validation.errors.company_address ? (
            <FormFeedback type="invalid">{validation.errors.company_address}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* company_post_code field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_post_code" className="form-label">
            {`${props.t("settings.general.report.postCode")}*`}
          </Label>
          <Input
            name="company_post_code"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.postCode")}`}
            type="text"
            value={validation.values.company_post_code || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.company_post_code && validation.errors.company_post_code ? true : false}
          />
          {validation.touched.company_post_code && validation.errors.company_post_code ? (
            <FormFeedback type="invalid">{validation.errors.company_post_code}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* company_city field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_city" className="form-label">
            {`${props.t("settings.general.report.city")}*`}
          </Label>
          <Input
            name="company_city"
            className="form-control"
            placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.city")}`}
            type="text"
            value={validation.values.company_city || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.company_city && validation.errors.company_city ? true : false}
          />
          {validation.touched.company_city && validation.errors.company_city ? (
            <FormFeedback type="invalid">{validation.errors.company_city}</FormFeedback>
          ) : null}
        </div>
      </Row>
      {/* company_country field */}
      <Row>
        <div className="mb-3">
          <Label htmlFor="company_country" className="form-label">
            {`${props.t("settings.general.report.country")}*`}
          </Label>
          <Select
            name="company_country"
            options={getLabelValueArray(contriesOptions, "id", "name")}
            value={selectedCompanyCountry}
            className={`custom-select ${
              validation.touched.company_country && validation.errors.company_country ? "select-error" : ""
            }`}
            placeholder={`${props.t("common.select")} ${props.t("settings.general.report.country")}`}
            onChange={(country) => {
              setSelectedCompanyCountry(country);
              validation.setFieldValue("company_country", country?.value || "");
            }}
            onBlur={(e) => {
              validation.setFieldTouched("company_country", true);
              validation.handleBlur(e);
            }}
          />
          {validation.touched.company_country && validation.errors.company_country ? (
            <span className="custom-invalid-feedback">{validation.errors.company_country}</span>
          ) : null}
        </div>
      </Row>
      {/* customer history */}
      {personId ? (
        <Row>
          <div className="mb-3">
            <Label className="form-label">{props.t("common.customerHistory")}</Label>
            <div className="details-border-box">
              <div className="p-1 px-2 fs-13">
                {props.t("customerHistory.accountCreatedAt", {
                  date: moment(personData?.created_at).format("DD-MM-YYYY, HH:mm"),
                })}
              </div>
              {personData?.customer_histories?.length > 0 ? (
                <>
                  <hr />
                  {personData?.customer_histories?.map((customerHistory, index) => (
                    <div key={index}>
                      <div className="p-1 px-2 fs-13">{getCustomerHistoryData(customerHistory)}</div>
                      {index !== personData?.customer_histories?.length - 1 && <hr />}
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Row>
      ) : null}
    </Col>
  );

  const navigateToCustomerDocuments = () => {
    history.push(`/customers/${personId}/checking-account`);
  };

  // navigate to the customer's situation page.
  const navigateToCustomerSituation = () => {
    history.push(`/customers/${personId}/customer-situation`);
  };

  return (
    <>
      <Prompt
        when={
          !validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomBreadcrumb
            data={[
              {
                label: props.t("sidebarMenu.customers"),
                link: "/customers",
              },
              {
                label: `${customerData?.customer_no}`,
                link: `/customers/edit/${paramId}`,
              },
              {
                label: props.t(`${personId ? "common.editTitle" : "common.addTitle"}`, {
                  module: props.t("common.person"),
                }),
              },
            ]}
          />

          <div className="contain-header-right d-flex flex-row gap-2">
            {personId ? (
              <>
                <CustomButton btnTitle={props.t("common.checkingAccount")} onClick={navigateToCustomerDocuments} />
                <CustomButton btnTitle={props.t("common.customerSituation")} onClick={navigateToCustomerSituation} />
              </>
            ) : null}
          </div>
        </div>

        <Card className="container-body">
          {fetchLoader ? <Loader /> : null}
          {/* customer form */}
          <FormikProvider value={validation}>
            <Form
              onKeyPress={onKeyPressForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <CardBody>
                {/* for customer */}
                <Row>
                  <Col className="col-sm-12 col-md-5 col-xl-4">
                    <div className="mb-3">
                      <Label htmlFor="title" className="form-label">
                        {`${props.t("common.customerType")}*`}
                      </Label>
                      <Select
                        name="customer_type"
                        options={NEW_PERSONA_TYPE}
                        value={selectedPersonaType}
                        className={`custom-select ${
                          validation.touched.customer_type && validation.errors.customer_type ? "select-error" : ""
                        }`}
                        placeholder={`${props.t("common.select")} ${props.t("common.customerType")}`}
                        onChange={(customer_type) => {
                          handleResetForm();
                          validation.resetForm();
                          setSelectedPersonaType(customer_type);
                          setVatCheckbox(false);
                          setCompanyVATCheckbox(false);
                          validation.setFieldValue("customer_type", customer_type?.value || "");
                        }}
                        onBlur={(e) => {
                          validation.setFieldTouched("customer_type", true);
                          validation.handleBlur(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-1 col-md-1 col-xl-4"></Col>
                  <Col className="col-sm-12 col-md-6 col-xl-4">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="active user" className="form-label">
                            {`${props.t("common.activePerson")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_active"
                              name="is_active"
                              // defaultChecked={user ? user?.is_active : true}
                              checked={validation?.values?.is_active}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_active}
                              invalid={validation.touched.is_active && validation.errors.is_active && true}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="active user" className="form-label">
                            {`${props.t("common.buyer")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_buyer"
                              name="is_buyer"
                              checked={validation?.values?.is_buyer}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_buyer}
                              invalid={validation.touched.is_buyer && validation.errors.is_buyer && true}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="active user" className="form-label">
                            {`${props.t("common.seller")}`}
                          </Label>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_seller"
                              name="is_seller"
                              checked={validation?.values?.is_seller}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_seller}
                              invalid={validation.touched.is_seller && validation.errors.is_seller && true}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {selectedPersonaType?.value === "personal" ? personalDetailsColumn : companyDetailsColumn}
                  {/* 1st column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* contact 1 */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="contact_1" className="form-label">
                          {`${props.t("common.contact1")}*`}
                        </Label>
                        <PhoneNumberInput
                          isValid={!(validation.touched.contact_1 && validation.errors.contact_1)}
                          value={validation?.values?.contact_1}
                          onChange={(value) => {
                            validation.setFieldValue("contact_1", value);
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("contact_1", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.contact_1 && validation.errors.contact_1 ? (
                          <p className="custom-invalid-feedback">{validation.errors.contact_1}</p>
                        ) : null}
                      </div>
                    </Row>
                    {/* contact-2 */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="contact_2" className="form-label">
                          {`${props.t("common.contact2")}`}
                        </Label>
                        <PhoneNumberInput
                          isValid={!(validation.touched.contact_2 && validation.errors.contact_2)}
                          value={validation?.values?.contact_2}
                          onChange={(value) => {
                            validation.setFieldValue("contact_2", value);
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("contact_2", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.contact_2 && validation.errors.contact_2 ? (
                          <p className="custom-invalid-feedback">{validation.errors.contact_2}</p>
                        ) : null}
                      </div>
                    </Row>
                    {/* email */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {`${props.t("common.email")}*`}
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.email")}`}
                          type="email"
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {selectedPersonaType?.value === "personal" ? (
                      <>
                        {/* client address field*/}
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="client_address" className="form-label">
                              {`${props.t("common.address")}*`}
                            </Label>
                            <Input
                              name="client_address"
                              className="form-control"
                              placeholder={`${props.t("common.enter")} ${props.t("common.address")}`}
                              type="text"
                              value={validation.values.client_address || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.client_address && validation.errors.client_address ? true : false
                              }
                            />
                            {validation.touched.client_address && validation.errors.client_address ? (
                              <FormFeedback type="invalid">{validation.errors.client_address}</FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        {/* client_post_code field*/}
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="client_post_code" className="form-label">
                              {`${props.t("settings.general.report.postCode")}*`}
                            </Label>
                            <Input
                              name="client_post_code"
                              className="form-control"
                              placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.postCode")}`}
                              type="text"
                              value={validation.values.client_post_code || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.client_post_code && validation.errors.client_post_code ? true : false
                              }
                            />
                            {validation.touched.client_post_code && validation.errors.client_post_code ? (
                              <FormFeedback type="invalid">{validation.errors.client_post_code}</FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        {/* client_city field*/}
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="client_city" className="form-label">
                              {`${props.t("settings.general.report.city")}*`}
                            </Label>
                            <Input
                              name="client_city"
                              className="form-control"
                              placeholder={`${props.t("common.enter")} ${props.t("settings.general.report.city")}`}
                              type="text"
                              value={validation.values.client_city || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.client_city && validation.errors.client_city ? true : false}
                            />
                            {validation.touched.client_city && validation.errors.client_city ? (
                              <FormFeedback type="invalid">{validation.errors.client_city}</FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        {/* client_country field*/}
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="client_country" className="form-label">
                              {`${props.t("settings.general.report.country")}*`}
                            </Label>
                            <Select
                              name="client_country"
                              options={getLabelValueArray(contriesOptions, "id", "name")}
                              value={selectedClientCountry}
                              className={`custom-select ${
                                validation.touched.client_country && validation.errors.client_country
                                  ? "select-error"
                                  : ""
                              }`}
                              placeholder={`${props.t("common.select")} ${props.t("settings.general.report.country")}`}
                              onChange={(country) => {
                                setSelectedClientCountry(country);
                                validation.setFieldValue("client_country", country?.value || "");
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("client_country", true);
                                validation.handleBlur(e);
                              }}
                            />
                            {validation.touched.client_country && validation.errors.client_country ? (
                              <span className="custom-invalid-feedback">{validation.errors.client_country}</span>
                            ) : null}
                          </div>
                        </Row>
                      </>
                    ) : (
                      <>
                        {/* iban_no field */}
                        <Row>
                          <div className="mb-3">
                            <Label htmlFor="iban_no" className="form-label">
                              {`${props.t("common.iban")}`}
                            </Label>
                            <Input
                              name="iban_no"
                              className="form-control"
                              placeholder={`${props.t("common.enter")} ${props.t("common.iban")}`}
                              type="text"
                              value={validation.values.iban_no || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.iban_no && validation.errors.iban_no ? true : false}
                            />
                            {validation.touched.iban_no && validation.errors.iban_no ? (
                              <FormFeedback type="invalid">{validation.errors.iban_no}</FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                      </>
                    )}
                  </Col>

                  {/* 4th column */}
                  <Col className="col-sm-12 col-md-12 col-xl-4">
                    {/* tags field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="tags" className="form-label">
                          {props.t("settings.lots.tags.tag")}
                        </Label>
                        <Select
                          name="tags"
                          options={getLabelValueArray(tagOptionList, "id", getColumnDetail("name_en", "name_pt"))}
                          value={selectedTags}
                          placeholder={`${props.t("common.select")} ${props.t("settings.lots.tags.tag")}`}
                          onChange={(tag) => {
                            setSelectedTags(tag);
                            validation.setFieldValue("tags", getValuesFromArray(tag));
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("tags", true);
                            validation.handleBlur(e);
                          }}
                          components={{ Option }}
                          isMulti={true}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </Row>
                    {/* postcards field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="postcards" className="form-label">
                          {`${props.t("customers.postCardsShipping")}`}
                        </Label>
                        <Select
                          name="postcards"
                          options={[
                            SELECT_ALL_OPTION,
                            ...getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                          ]}
                          value={selectedPostCards || []}
                          placeholder={`${props.t("common.select")} ${props.t("customers.postCards")}`}
                          onChange={(postCard) => {
                            handleMultiSelectDropdown(
                              postCard,
                              getLabelValueArray(shippingOptionList, "id", getColumnDetail("name_en", "name_pt")),
                              "postcards",
                              setSelectedPostCards,
                            );
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("postcards", true);
                            validation.handleBlur(e);
                          }}
                          components={{ Option }}
                          isMulti={true}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </Row>
                    {/* newsletter subscription */}
                    <Row className="mb-3">
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">{`${props.t("common.newsletterSubscription")}`}</Label>
                          <div className="form-check form-switch ">
                            <input
                              type="checkbox"
                              role="switch"
                              id="is_subscribed_to_newsletter"
                              name="is_subscribed_to_newsletter"
                              checked={validation?.values?.is_subscribed_to_newsletter}
                              className="form-check-input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.is_subscribed_to_newsletter}
                              invalid={
                                validation.touched.is_subscribed_to_newsletter &&
                                validation.errors.is_subscribed_to_newsletter &&
                                true
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* preference field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="preferences" className="form-label">
                          {`${props.t("common.preferences")}`}
                        </Label>
                        <Select
                          name="preferences"
                          options={[
                            SELECT_ALL_OPTION,
                            ...getLabelValueArray(preferencesList, "id", getColumnDetail("name_en", "name_pt")),
                          ]}
                          value={selectedPreference || []}
                          placeholder={`${props.t("common.select")} ${props.t("common.preferences")}`}
                          onChange={(preference) => {
                            handleMultiSelectDropdown(
                              preference,
                              getLabelValueArray(preferencesList, "id", getColumnDetail("name_en", "name_pt")),
                              "preferences",
                              setSelectedPreference,
                            );
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("preferences", true);
                            validation.handleBlur(e);
                          }}
                          components={{ Option }}
                          isMulti={true}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </Row>
                    {/* language field */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="language" className="form-label">
                          {`${props.t("settings.general.language.language")}*`}
                        </Label>
                        <Select
                          name="language"
                          options={LANGUAGE_OPTIONS}
                          value={selectedLanguage}
                          className={`custom-select ${
                            validation.touched.language && validation.errors.language ? "select-error" : ""
                          }`}
                          placeholder={`${props.t("common.select")} ${props.t("settings.general.language.language")}`}
                          onChange={(lang) => {
                            setSelectedLanguage(lang);
                            validation.setFieldValue("language", lang?.value || "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("language", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.language && validation.errors.language ? (
                          <span className="custom-invalid-feedback">{validation.errors.language}</span>
                        ) : null}
                      </div>
                    </Row>
                    {/* international observation */}
                    <Row>
                      {/* international_onservations field */}
                      <div className="mb-3">
                        <Label htmlFor="internal_observations" className="form-label">
                          {props.t("common.internalObservations")}
                        </Label>
                        <Input
                          name="internal_observations"
                          className="form-control"
                          placeholder={`${props.t("common.enter")} ${props.t("common.internalObservations")}`}
                          style={{ height: "120px" }}
                          type="textarea"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.internal_observations || ""}
                          invalid={
                            validation.touched.internal_observations && validation.errors.internal_observations
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-12 col-md-12 col-xl-6 ">
                    <div className="mb-3">
                      <Label className="form-label">{props.t("common.cataloguesShipping")}</Label>
                      <CatalogueSubscriptionTable
                        validation={validation}
                        arrayKey="catalogue_shippings"
                        catalogueShipping
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-12 col-md-12 col-xl-6 ">
                    <div className="mb-3">
                      <Label className="form-label">{props.t("common.subscriptionToCatalogues")}</Label>
                      <CatalogueSubscriptionTable validation={validation} arrayKey="catalogue_subscriptions" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="d-flex flex-row justify-content-end">
                  <Button
                    color="dark"
                    disabled={customerSaveLoader}
                    onClick={() => {
                      if (JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values)) {
                        if (window.confirm(props.t("confirmation.resetMessage"))) {
                          handleResetForm();
                        }
                      }
                    }}
                  >
                    {props.t("common.reset")}
                  </Button>
                  <Button
                    color="primary"
                    disabled={customerSaveLoader}
                    className="btn-load d-flex ms-2"
                    type="submit"
                    onClick={() => scrollToFirstFormikError(validation.errors)}
                  >
                    {customerSaveLoader ? (
                      <span className="d-flex align-items-center ">
                        <Spinner size="xs" className="flex-shrink-0"></Spinner>
                      </span>
                    ) : null}
                    <span className={`flex-grow-1 ${customerSaveLoader ? "ms-2" : ""}`}>{props.t("common.save")}</span>
                  </Button>
                </div>
              </CardFooter>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </>
  );
};

export default withTranslation()(PersonaForm);
