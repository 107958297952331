import { SET_FAQS_DATA } from "../actionTypes";

const INITIAL_STATE = {
  data: [],
  total: 0,
  suggestions: null,
};

const FAQs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FAQS_DATA:
      return {
        ...state,
        data: action?.payload?.results,
        total: action?.payload?.count,
        suggestions: {
          next_general_pt: action?.payload?.next_general_pt,
          next_general_en: action?.payload?.next_general_en,
          next_buying_en: action?.payload?.next_buying_en,
          next_buying_pt: action?.payload?.next_buying_pt,
          next_selling_en: action?.payload?.next_selling_en,
          next_selling_pt: action?.payload?.next_selling_pt,
        },
      };
    default:
      return { ...state };
  }
};

export default FAQs;
