import React from "react";
import CustomTooltipWrapper from "./CustomTooltipWrapper";

const ActionButton = ({ onClick, tooltip, buttonId, iconClasses, iconColor, buttonClasses, disabled }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`${
        buttonClasses ? buttonClasses : ""
      }  btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
        disabled ? "disabled-action-button" : ""
      }`}
      onClick={onClick}
    >
      {tooltip ? <CustomTooltipWrapper target={buttonId} tooltipBody={tooltip} /> : null}
      <i className={iconClasses} id={buttonId} style={{ color: iconColor }} />
    </button>
  );
};

export default ActionButton;
