import { SET_MY_VERITAS_DATA } from "./actionTypes";

const INIT_STATE = {
  login_image_pt: null,
  login_image_en: null,
};

const MyVeritas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MY_VERITAS_DATA: {
      return {
        ...state,
        login_image_pt: action.payload.login_image_pt,
        login_image_en: action.payload.login_image_en,
      };
    }
    default:
      return { ...state };
  }
};

export default MyVeritas;
