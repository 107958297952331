import i18next from "i18next";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import TabsComponent from "../../../common/TabsComponent";
import BuyingContent from "./BuyingContent";
import SellingContent from "./SellingContent";

const BuyingAndSellingContent = (props) => {
  // to reset form on unmount
  useEffect(() => {
    return () => {
      props?.buyingValidation?.resetForm();
      props?.sellingValidation?.resetForm();
    };
  }, []);

  // handle change in active tab index
  const handleChangeActiveTabIndex = (index) => {
    if (props?.buyingValidation?.dirty || props?.sellingValidation?.dirty) {
      if (window.confirm(props.t("confirmation.resetTabData"))) {
        props?.setActivePageIndex(index);
      }
    } else {
      props?.setActivePageIndex(index);
    }
  };

  return (
    <div className="buying-selling-main-content">
      <TabsComponent
        controlledTabChange={true}
        activeTab={props?.activePageIndex}
        setActiveTab={handleChangeActiveTabIndex}
        tabStyleClasses="sub-tabs-style"
        tabData={[
          {
            label: i18next.t("common.buying"),
            Component: BuyingContent,
            props: { pageType: "buying", ...props },
            disabled: false,
          },
          {
            label: i18next.t("common.selling"),
            Component: SellingContent,
            props: { pageType: "selling", ...props },
            disabled: false,
          },
        ]}
      />
    </div>
  );
};

export default withTranslation()(BuyingAndSellingContent);
