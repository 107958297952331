import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardFooter } from "reactstrap";
import ActionButton from "../../common/ActionButton";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  formatAmount,
  getColumnDetail,
  getLabelFromArray,
  seperateDetails,
} from "../../../helpers/utils";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import CustomAddButton from "../../common/CustomAddButton";
import AddBulkBidModal from "./AddBulkBidModal";
import { checkBidValidation } from "../../../store/auctions/bidsActions";
import { toast } from "react-toastify";
import { TYPE_OF_BID_OPTIONS } from "../../../helpers/constants/selectOptions";
import ConfirmationModal from "../../common/ConfirmationModal";

const BulkBidsTable = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [addBidLoader, setAddBidLoader] = useState(false);
  const [isBidModalOpen, setIsBidModalOpen] = useState(false);
  const [isEditBidModelOpen, setIsEditBidModelOpen] = useState(false);
  const [editBidData, setEditBidData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState(null);
  const [tempBidData, setTempBidData] = useState();

  // confirmation popup handlers
  const handleConfirmationModalOpen = (bidData, text) => {
    setTempBidData(bidData);
    setConfirmationText(text);
    setIsConfirmationModalOpen(true);
  };
  const handleConfirmationModalClose = () => {
    setConfirmationText(null);
    setIsConfirmationModalOpen(false);
    setTempBidData(null);
  };
  const handleConfirmationOnWarning = () => {
    props?.setBidsData((prevState) => [...prevState, tempBidData]);
    handleConfirmationModalClose();
    handleAddBidModalClose();
  };

  //   to handle the add bid modal open
  const handleAddBidModalOpen = () => {
    if (!props?.validation?.values?.customer?.id) {
      return toast.info(props.t("information.selectCustomerBulkBid"));
    }
    setIsBidModalOpen(true);
  };
  // to handle the add bid modal close
  const handleAddBidModalClose = () => {
    setIsBidModalOpen(false);
  };
  // to handle the add bid
  const handleAddBid = (values) => {
    const payload = {
      auction: id || null,
      customer: props?.validation?.values?.customer?.id || null,
      auction_lot: values?.auction_lot?.id || null,
      bidding_type: values?.bidding_type || null,
      value: values?.value || null,
      contact_1: values?.contact_1 || null,
      contact_2: values?.contact_2 || null,
    };
    const bidData = {
      auction_lot: values?.auction_lot,
      bidding_type: values?.bidding_type,
      value: values?.value,
      contact_1: values?.contact_1,
      contact_2: values?.contact_2,
    };
    if (props?.bidsData?.map((item) => item?.auction_lot?.id)?.includes?.(values?.auction_lot?.id)) {
      return toast.info(props?.t("information.alreadyHavingBid"));
    }
    setAddBidLoader(true);
    dispatch(checkBidValidation(payload)).then((res) => {
      if (res === true) {
        props?.setBidsData((prevState) => [...prevState, bidData]);
        handleAddBidModalClose();
      } else {
        handleConfirmationModalOpen(bidData, res);
      }
      setAddBidLoader(false);
    });
  };

  //   handle delete bid
  const handleDeleteBid = (index) => {
    props?.setBidsData((prevState) => prevState?.filter((_, ind) => ind !== index));
  };

  const handleEditCancel = () => {
    setIsEditBidModelOpen(false);
    setEditBidData();
  };

  const handleEditBid = (index) => {
    setEditBidData(props?.bidsData[index]);
    setIsEditBidModelOpen(true);
  };

  const handleEditBitSave = (values) => {
    const index = props?.bidsData?.findIndex((bid) => bid?.auction_lot?.id === values?.auction_lot?.id);
    if (index !== -1) {
      props?.setBidsData((prevBidsData) => {
        const updatedBidsData = [...prevBidsData];
        updatedBidsData[index] = {
          auction_lot: values?.auction_lot,
          bidding_type: values?.bidding_type,
          value: values?.value,
          contact_1: values?.contact_1,
          contact_2: values?.contact_2,
        };
        return updatedBidsData;
      });
    }
    handleEditCancel();
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title">{props.t("common.no_")}</div>
        </div>
      ),
      selector: (_, index) => index + 1,
      width: "60px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title">{props.t("common.lot")}</div>
        </div>
      ),
      selector: (row) =>
        seperateDetails(
          [
            row?.auction_lot?.lot_no + row?.auction_lot?.lot_alpha,
            getColumnDetail(row?.auction_lot?.contract_item?.title_en, row?.auction_lot?.contract_item?.title_pt),
          ],
          " - "
        ),
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title">{props.t("common.typeOfBidding")}</div>
        </div>
      ),
      selector: (row) => getLabelFromArray(TYPE_OF_BID_OPTIONS, row?.bidding_type) || "-",
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title">{props.t("common.bidDetails")}</div>
        </div>
      ),
      selector: (row) => (row?.bidding_type === "telephone" ? row?.contact_1 || "-" : formatAmount(row?.value || 0)),
      width: "140px",
      wrap: true,
    },
    {
      selector: (row) => row?.contact_2 || "-",
      width: "140px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row, index) => (
        <div className="table-actions">
          <ActionButton
            iconClasses="ri-pencil-fill fs-18"
            buttonId={`tooltip-${row?.id}-edit`}
            tooltip={props.t("common.edit")}
            onClick={() => handleEditBid(index)}
          />
          <ActionButton
            iconClasses="ri-delete-bin-fill fs-18"
            buttonId={`tooltip-${row?.id}-delete`}
            tooltip={props.t("common.delete")}
            onClick={() => handleDeleteBid(index)}
          />
        </div>
      ),
      width: "85px",
      center: true,
    },
  ];

  return (
    <>
      <Card className="subcard-table-container table-light">
        <DataTable
          className="mini-data-table"
          fixedHeader
          persistTableHead
          columns={columns}
          data={[...props?.bidsData]}
          noDataComponent={<NoRecords message={props?.t("information.addBulkBids")} />}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: props?.t("common.rowsPerPage"),
            rangeSeparatorText: props?.t("common.rangeSeparator"),
          }}
        />
        <CardFooter className="d-flex flex-row justify-content-end">
          <CustomAddButton
            btnTitle={props.t("common.addTitle", {
              module: props.t("common.bid"),
            })}
            onClick={handleAddBidModalOpen}
          />
        </CardFooter>
      </Card>
      {/* add bid modal */}
      <AddBulkBidModal
        isModalOpen={isBidModalOpen}
        onCancel={handleAddBidModalClose}
        onSave={handleAddBid}
        loader={addBidLoader}
        selectedLotsIDs={props?.bidsData?.map((item) => item?.auction_lot?.id)}
        customer={props?.validation?.values?.customer}
      />
      {/* bid confirmation modal */}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        onCloseBtnHandler={handleConfirmationModalClose}
        onConfirmBtnHandler={handleConfirmationOnWarning}
        body={confirmationText}
        actionBtnTitle={props?.t("common.yes")}
        cancelBtnTitle={props?.t("common.no")}
      />
      {/* edit bid modal */}
      <AddBulkBidModal
        isModalOpen={isEditBidModelOpen}
        onCancel={handleEditCancel}
        onSave={handleEditBitSave}
        loader={addBidLoader}
        editBidData={editBidData}
        selectedLotsIDs={[]}
      />
    </>
  );
};

export default withTranslation()(BulkBidsTable);
