import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getColumnDetail, getTypeFromValue, scrollToFirstFormikError, seperateDetails } from "../../../helpers/utils";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT } from "../../../helpers/constants";
import { TYPE_OF_BID_OPTIONS } from "../../../helpers/constants/selectOptions";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import { useParams } from "react-router-dom";
import { getLotLists } from "../../../store/auctions/lotActions";
import NumberInput from "../../common/NumberInput";
import i18next from "i18next";

const AddBulkBidModal = ({ isModalOpen, onCancel, onSave, loader, t, selectedLotsIDs, customer, editBidData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const LOTS_OPTIONS = useSelector((state) => state?.Auctions?.lots?.data);

  const [lotSearchLoader, setLotSearchLoader] = useState(false);
  const [lotsPayload, setLotsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "lot_no",
    filter: {
      auction: id,
    },
    exclude: {
      id__in: [...selectedLotsIDs],
    },
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      auction_lot: editBidData?.auction_lot || "",
      bidding_type: editBidData?.bidding_type || "absentee",
      value: editBidData?.value || "",
      contact_1: editBidData?.contact_1 || customer?.contact_1 || "",
      contact_2: editBidData?.contact_2 || customer?.contact_2 || "",
    },
    validationSchema: Yup.object({
      auction_lot: Yup.object().required(`${t("common.please")} ${t("common.select")} ${t("common.lot")}`),

      contact_1: Yup.string().when("bidding_type", {
        is: "telephone",
        then: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.contact1")}`),
      }),
      contact_2: Yup.string().when("bidding_type", {
        is: "telephone",
        then: Yup.string(),
      }),

      value: Yup.number().when("bidding_type", {
        is: "absentee",
        then: Yup.number()
          .min(0, t("validation.numberMin", { totalNumber: 0 }))
          .required(`${t("common.please")} ${t("common.enter")} ${t("common.value")}`),
      }),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });

  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
    }
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };

  // to handle the lot search
  const handleLotSearch = (value) => {
    setLotsPayload((prevState) => {
      const search_key = getColumnDetail("search_author_title_en__icontains", "search_author_title_pt__icontains");
      return {
        ...prevState,
        filter: { ...prevState?.filter, [search_key]: value },
      };
    });
  };
  // debounce for lot search
  const lotSearchDebounce = useMemo(() => {
    return debounce(handleLotSearch, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      lotSearchDebounce.cancel();
    };
  }, [lotSearchDebounce]);

  // to get the list of lots for the auction
  useEffect(() => {
    if (isModalOpen) {
      setLotSearchLoader(true);
      dispatch(getLotLists(lotsPayload)).then((res) => {
        setLotSearchLoader(false);
      });
    }
  }, [dispatch, lotsPayload, isModalOpen]);

  // get item description -> artist, title
  const getLotDescription = (row) => {
    let description = [];

    if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
    } else {
      if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
    }
    return description?.length > 0 ? description.join(", ") : "";
  };

  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {t("common.addTitle", {
          module: t("common.bid"),
        })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="auction_lot" className="form-label">
                  {`${t("common.lot")}*`}
                </Label>
                <Select
                  isLoading={lotSearchLoader}
                  name="auction_lot"
                  options={lotSearchLoader ? [] : LOTS_OPTIONS}
                  getOptionLabel={(option) =>
                    seperateDetails(
                      [option?.lot_no ? option?.lot_no + option?.lot_alpha : "", getLotDescription(option)],
                      " - "
                    )
                  }
                  getOptionValue={(option) => option?.id}
                  className={`custom-select ${
                    validation.touched.auction_lot && validation.errors.auction_lot ? "select-error" : ""
                  }`}
                  placeholder={`${t("common.select")} ${t("common.lot")}`}
                  value={validation?.values?.auction_lot || ""}
                  onChange={(auction_lot) => {
                    validation.setFieldValue("auction_lot", auction_lot?.id ? auction_lot : null);
                    validation.setFieldValue("value", +auction_lot?.contract_item?.min_estimate);
                  }}
                  onInputChange={lotSearchDebounce}
                  onBlur={(e) => {
                    validation.setFieldTouched("auction_lot", true);
                    validation.handleBlur(e);
                  }}
                />
                {validation.touched.auction_lot && validation.errors.auction_lot ? (
                  <p className="custom-invalid-feedback">{validation.errors.auction_lot}</p>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="bidding_type" className="form-label">
                  {`${t("common.typeOfBidding")}*`}
                </Label>
                <Select
                  name="bidding_type"
                  options={TYPE_OF_BID_OPTIONS}
                  className="custom-select"
                  placeholder={`${t("common.select")} ${t("common.typeOfBidding")}`}
                  value={getTypeFromValue(TYPE_OF_BID_OPTIONS, validation?.values?.bidding_type) || ""}
                  onChange={(option) => {
                    validation.setFieldValue("bidding_type", option?.value);
                  }}
                  onBlur={(e) => {
                    validation.setFieldTouched("bidding_type", true);
                    validation.handleBlur(e);
                  }}
                />
              </div>
            </Col>
          </Row>

          {validation?.values?.bidding_type === "absentee" ? (
            <div className="mb-3">
              <Label htmlFor="value" className="form-label">
                {`${t("common.value")}*`}
              </Label>
              <NumberInput
                name="value"
                className="form-control"
                placeholder={`${t("common.enter")} ${t("common.value")}`}
                type="number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value}
                invalid={validation.touched.value && validation.errors.value ? true : false}
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
              ) : null}
            </div>
          ) : (
            <Row>
              <Col sm={6} md={6} lg={6}>
                <div className="mb-3">
                  <Label htmlFor="contact_1" className="form-label">
                    {`${t("common.contact1")}*`}
                  </Label>
                  <PhoneNumberInput
                    isValid={!(validation.touched.contact_1 && validation.errors.contact_1)}
                    value={validation?.values?.contact_1}
                    onChange={(value) => {
                      validation.setFieldValue("contact_1", value);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("contact_1", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.contact_1 && validation.errors.contact_1 ? (
                    <p className="custom-invalid-feedback">{validation.errors.contact_1}</p>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="contact_2" className="form-label">
                    {t("common.contact2")}
                  </Label>
                  <PhoneNumberInput
                    isValid={!(validation.touched.contact_2 && validation.errors.contact_2)}
                    value={validation?.values?.contact_2}
                    onChange={(value) => {
                      validation.setFieldValue("contact_2", value);
                    }}
                    onBlur={(e) => {
                      validation.setFieldTouched("contact_2", true);
                      validation.handleBlur(e);
                    }}
                  />
                  {validation.touched.contact_2 && validation.errors.contact_2 ? (
                    <p className="custom-invalid-feedback">{validation.errors.contact_2}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader || JSON.stringify(validation?.initialValues) === JSON.stringify(validation?.values)}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.add")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddBulkBidModal);
