import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { Badge, Button, Card, CardHeader, Input } from "reactstrap";
import CustomSearchInput from "../../../components/common/CustomSearchInput";
import CustomTagChip from "../../../components/common/CustomTagChip";
import NoRecords from "../../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS } from "../../../helpers/constants";

import {
  checkNoSelected,
  checkPermissionByRoles,
  checkSelectAllSelected,
  exportToexcel,
  findByKeyFromArray,
  formatAmount,
  generateContractItemsExcelData,
  generateContrastColor,
  getColumnDetail,
  getConcatedStringFromArray,
  getCustomerAddress,
  getDateInDDMMYYYYFormat,
  getIdFromArray,
  getItemCol,
  getLabelValueArray,
  getListOfActiveStatus,
  getOnlyCustomerName,
  getValuesFromArray,
  openFileURL,
} from "../../../helpers/utils";

import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ColorCircle from "../../../components/common/ColorCircle";
import CustomButton from "../../../components/common/CustomButton";
import CustomColumnPhoto from "../../../components/common/CustomColumnPhoto";
import CustomDropdownButton from "../../../components/common/CustomDropdownButton";
import CustomLinkWrapper from "../../../components/common/CustomLinkWrapper";
import CustomMultiSelect from "../../../components/common/CustomMultiSelect";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../../components/common/DeleteModal";
import LinkToAuctionModal from "../../../components/common/LinkToAuctionModal";
import Loader from "../../../components/common/Loader";
import API from "../../../helpers/api/api_interceptor";
import {
  AVAILABLE_FOR_AUCTION_LOT,
  AVAILABLE_LOT,
  DEFAULT_ROW_PER_PAGE,
  RESERVED_LOT,
} from "../../../helpers/constants";
import { CONTRACT_STATUS_OPTIONS, SELECT_ALL_OPTION, SELECT_NO_OPTION } from "../../../helpers/constants/selectOptions";
import { addLots } from "../../../store/auctions/lotActions";
import { getCategories } from "../../../store/categories/actions";
import { getCountriesOptions } from "../../../store/common/actions";
import { deleteContractItem, exportItemList } from "../../../store/contracts/actions";
import { getContractItemList } from "../../../store/item-list/actions";
import { getTags } from "../../../store/lots-tags/actions";
import CustomTextLink from "../../../components/common/CustomTextLink";
import { getWarehouses } from "../../../store/warehouse/actions";

const defaultItemPayload = {
  search: "",
  page: 1,
  sort: "-id",
  limit: DEFAULT_ROW_PER_PAGE,
  filter: {},
};

const categoriesPayload = {
  page: 1,
  limit: 50,
  sort: getColumnDetail("category_name_en", "category_name_pt"),
  filter: i18next.resolvedLanguage === "en" ? { category_name_en__icontains: "" } : { category_name_pt__icontains: "" },
};
const ContractItems = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data) || [],
    "id",
    "code"
  );

  const { data: itemListData, total: totalItems, all_ids: itemListIds } = useSelector((state) => state?.ItemList);

  const lotsTags = useSelector((state) => state?.LotsTags?.data);
  const TAGS_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.LotsTags?.data),
    "id",
    getColumnDetail("tag_label", "tag_label_pt")
  );
  const WAREHOUSE_OPTIONS = getLabelValueArray(
    useSelector((state) => state.Warehouse?.data),
    "id",
    "description"
  );
  const CATEGORIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Categories?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "category_name_en" : "category_name_pt"
  );

  const [itemsPayload, setItemsPayload] = useState(defaultItemPayload);
  const [tableLoader, setTableLoader] = useState(false);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [selectedWarehouseOptions, setSelectedWarehouseOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [exportBtnLoader, setExportBtnLoader] = useState(false);

  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [clearSearch, setClearSearch] = useState();
  const [exclude, setExclude] = useState([]);

  const handleClearFilters = () => {
    setItemsPayload(defaultItemPayload);
    setClearSearch(true);
    setSelectedCategory("");
    setSelectedStatusOptions([]);
    setSelectedRows([]);
    setIsAllSelected(false);
  };

  const [linkToAuctionLoader, setLinkToAuctionLoader] = useState(false);
  const [isLinkToAuctionModalOpen, setIsLinkToAuctionModalOpen] = useState(false);

  // handle link to auction modal open
  const handleLinkToAuctionModalOpen = () => {
    setIsLinkToAuctionModalOpen(true);
  };
  // handle link to auction modal close
  const handleLinkToAuctionModalClose = () => {
    setIsLinkToAuctionModalOpen(false);
  };
  // handle link to auction
  const handleLinkToAuction = (values) => {
    if (values?.auction_id) {
      setLinkToAuctionLoader(true);
      dispatch(
        addLots({
          auctionId: values.auction_id,
          contractIds: selectedRows,
          isSelectedAll: false,
          filter: {
            lots_tag__tag_label__in: [AVAILABLE_LOT, AVAILABLE_FOR_AUCTION_LOT, RESERVED_LOT],
          },
        })
      ).then((res) => {
        setLinkToAuctionLoader(false);
        handleLinkToAuctionModalClose();
        handleGetItemList(itemsPayload);
      });
    }
  };

  // handle row selection
  const handleRowSelection = (checked, rowId) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, rowId]);
    } else {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setExclude((prevExclude) => [...prevExclude, rowId]);
    }
  };

  // get countries list, vats
  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
  }, [dispatch]);

  // navigate to edit contract item
  const navigateOnContractItemForm = (row) => {
    // contracts/contracts/8/items/edit/64
    history.push(`/contracts/contracts/${row?.contract?.id}/items/edit/${row?.id}`);
  };

  // handle page change
  const handlePageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle contract item filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    const isStatusKey = filterKey === "status";
    setItemsPayload((prevState) => {
      const filter = { ...prevState.filter };
      if (checkSelectAllSelected(values)) {
        setOptionTypes(SELECT_ALL_OPTION);
        delete filter[filterKey];
        filter[isStatusKey ? `${filterKey}` : `${filterKey}__isnull`] = isStatusKey
          ? [...CONTRACT_STATUS_OPTIONS.slice(1, CONTRACT_STATUS_OPTIONS.length)].map((item) => item.value)
          : false;
      } else {
        delete filter[`${filterKey}__isnull`];
        if (checkNoSelected(values) && !isStatusKey) {
          filter[`${filterKey}__isnull`] = true;
        }
        const valuesWithoutNull = values.filter(({ value }) => value !== "-1");
        if (valuesWithoutNull.length) {
          filter[isStatusKey ? `${filterKey}` : `${filterKey}__in`] = getValuesFromArray(valuesWithoutNull);
        } else {
          delete filter[filterKey];
          delete filter[`${filterKey}__in`];
        }
      }

      return { ...prevState, filter, page: 1 };
    });
  };

  //   handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  //   handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsBulkDeleteModalOpen(false);
  };

  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    setBulkDeleteLoader(true);
    dispatch(
      deleteContractItem({
        isSelectedAll: false,
        ids: selectedRows,
      })
    ).then((res) => {
      if (res) {
        setBulkDeleteLoader(false);
        handleGetItemList(itemsPayload);
        handleBulkDeleteModalClose();
        setIsAllSelected(false);
        setSelectedRows([]);
      } else {
        //
        setBulkDeleteLoader(false);
      }
    });
  };
  //   handle delete modal open
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  //   handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };

  //   handle delete item
  const handleDeleteItem = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteContractItem({
          filter: {},
          isSelectedAll: false,
          ids: [selectedItem?.id],
        })
      ).then((res) => {
        if (res) {
          handleGetItemList(itemsPayload);
          setDeleteLoader(false);
          handleDeleteModalClose();
          setSelectedRows((prevState) => [...prevState.filter((item) => item?.id !== selectedItem?.id)]);
        } else {
          //
          setDeleteLoader(false);
        }
      });
    }
  };

  // handle getting contract items
  const handleGetItemList = useCallback(
    (itemsPayload) => {
      setTableLoader(true);
      dispatch(getContractItemList(itemsPayload)).then((res) => {
        setTableLoader(false);
      });
    },
    [dispatch]
  );

  // to get the contract items
  useEffect(() => {
    handleGetItemList(itemsPayload);
  }, [handleGetItemList, itemsPayload]);

  // to get the list of the lots--> tags
  // to get the list of categories
  useEffect(() => {
    dispatch(getTags({ sort: getColumnDetail("tag_label", "tag_label_pt") }));
    dispatch(getCategories(categoriesPayload));
  }, [categoriesPayload, dispatch]);

  // to get list of warehouse
  useEffect(() => {
    const payload = {
      sort: "description",
    };
    dispatch(getWarehouses(payload));
  }, [dispatch]);

  const handleSelectAll = (event) => {
    const hasFilterOrSearchApplied = !!Object.keys(itemsPayload?.filter)?.length || !!itemsPayload?.search?.length;
    const isAllSelected = !!event.target.checked;

    if (!isAllSelected) {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => !itemListIds?.includes(item))]);
      } else {
        setSelectedRows([]);
      }
    } else {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...new Set([...prevState, ...itemListIds])]);
      } else {
        setSelectedRows(itemListIds);
      }
    }
    setIsAllSelected(isAllSelected);
    setExclude([]);
  };

  // redirect to customer edit page
  const navigateToCustomerEditPage = (row) => {
    if (row?.auction_lots?.paddle?.customer?.id && !row?.auction_lots?.paddle?.customer?.parent_id)
      history.push(`/customers/edit/${row?.auction_lots?.paddle?.customer?.id}`);
    else if (row?.auction_lots?.paddle?.customer?.parent_id)
      history.push(
        `/customers/${row?.auction_lots?.paddle?.customer?.parent_id}/edit-person/${row?.auction_lots?.paddle?.customer?.id}`
      );
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            disabled={!itemListData.length}
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              handleSelectAll(event);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          disabled={!itemListData.length}
          type="checkbox"
          checked={Boolean(selectedRows.find((item) => item === row.id))}
          onChange={(e) => handleRowSelection(e.target.checked, row.id)}
        />
      ),
      width: "50px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.contract_item_no ? row?.contract_item_no : "-"),
      width: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper link={row?.default_photo} title={<CustomColumnPhoto sourceUrl={row?.default_photo} />} />
      ),
      width: "120px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row ? getItemCol(row) : "-"),
      width: "225px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.auction")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__auction__auction_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.auction?.auction_no ? row?.auction?.auction_no : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.lot")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_lot_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.auction_lots?.lot_no ? row?.auction_lots?.lot_no + row?.auction_lots?.lot_alpha : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.category")}`}</div>

          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              {
                ...SELECT_NO_OPTION,
                label: `${props.t("common.noOption")}  ${props.t("common.category").toLowerCase()}`,
              },
              ...CATEGORIES_OPTIONS,
            ]}
            selectedOptions={selectedCategory}
            handleOnSelection={(values) => {
              setSelectedCategory(values);
              handleDropdownFilter(values, "categories", setSelectedCategory);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getConcatedStringFromArray(row?.categories, getColumnDetail("category_name_en", "category_name_pt")),
      //   width: "10%",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("auctions.lots.seller")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="seller__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.contract?.customer?.id ? getOnlyCustomerName(row?.contract?.customer) : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.saleValue")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__sale_value__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.auction_lots?.sale_value ? formatAmount(row?.auction_lots?.sale_value) : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.paddle")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="auction_lots__paddle__paddle_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.auction_lots?.paddle?.id ? row?.auction_lots?.paddle?.paddle_no : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.buyer")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="buyer_name__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) =>
        row?.auction_lots?.paddle?.customer?.id ? (
          <CustomTextLink
            text={getOnlyCustomerName(row?.auction_lots?.paddle?.customer)}
            handleClick={() => navigateToCustomerEditPage(row)}
          />
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.warehouse")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...WAREHOUSE_OPTIONS]}
            selectedOptions={selectedWarehouseOptions}
            handleOnSelection={(values) => {
              setSelectedWarehouseOptions(values);
              handleDropdownFilter(values, "warehouse", setSelectedWarehouseOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.warehouse?.id ? row?.warehouse?.description : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.TAGS")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              { ...SELECT_NO_OPTION, label: `${props.t("common.noOption")} ${props.t("common.TAGS")}` },
              ...TAGS_OPTIONS,
            ]}
            selectedOptions={selectedTagOptions}
            handleOnSelection={(values) => {
              setSelectedTagOptions(values);
              handleDropdownFilter(values, "lots_tag", setSelectedTagOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.lots_tag?.length > 0 ? (
          <div className="d-flex flex-column gap-1 m-2">
            {row?.lots_tag?.map((tagId) => {
              const tagData = findByKeyFromArray(lotsTags, "id", tagId);
              return (
                <CustomTagChip
                  title={getColumnDetail(tagData?.tag_label, tagData?.tag_label_pt)}
                  bgColor={tagData?.tag_color}
                  textColor={generateContrastColor(tagData?.tag_color || "#000")}
                />
              );
            })}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.status")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...CONTRACT_STATUS_OPTIONS.slice(1, CONTRACT_STATUS_OPTIONS.length)]}
            selectedOptions={selectedStatusOptions}
            handleOnSelection={(values) => {
              setSelectedStatusOptions(values);
              handleDropdownFilter(values, "status", setSelectedStatusOptions);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getListOfActiveStatus(row)?.length ? (
          <div className="d-flex flex-row gap-1 flex-wrap">
            {getListOfActiveStatus(row)?.map((option, index) => {
              return (
                <ColorCircle
                  key={`tag-${option?.label}-${index}`}
                  color={option?.color}
                  id={`tag-${option?.value}-${index}`}
                  label={option?.label}
                />
              );
            })}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
      center: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
              checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
            }`}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
            style={{
              outline: "none",
              border: "none",
              opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
              cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i
              className={`ri-delete-bin-fill fs-18 ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
              }`}
              id={`tooltip-${row?.id}-delete`}
              style={{
                color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                border: "none",
              }}
            />
          </button>
        </div>
      ),
      width: "150px",
      center: true,
    },
  ];

  // // to handle the pdf exporting
  const handleExportPDF = (isEnglish) => {
    const payload = {
      is_english: isEnglish,
      filter: selectedRows?.length ? {} : itemsPayload?.filter,
      contract_item_search: selectedRows?.length ? "" : itemsPayload?.search,
    };
    if (exclude?.length) {
      payload.excludeIds = exclude;
    } else if (!isAllSelected && selectedRows?.length) {
      payload.ids = selectedRows;
    }
    setExportBtnLoader(true);
    dispatch(exportItemList(payload)).then((res) => {
      if (res) {
        openFileURL(res, "download");
        handleGetItemList(itemsPayload);
      }
      setExportBtnLoader(false);
    });
  };

  // to handle the excel exporting
  const handleExportContractItemsExcel = (isEnglish) => {
    let exportExcelPayload = {
      search: itemsPayload?.search,
      sort: itemsPayload?.sort,
      filter: itemsPayload?.filter || {},
      exclude: itemsPayload?.exclude || {},
    };

    if (exclude?.length) {
      exportExcelPayload.exclude = { id__in: exclude };
    } else if (!isAllSelected && selectedRows?.length) {
      exportExcelPayload.filter = { id__in: selectedRows };
    }

    setExportBtnLoader(true);
    API.get(`/contracts/item`, {
      params: exportExcelPayload,
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        exportToexcel(
          generateContractItemsExcelData(res?.data?.data?.results, isEnglish),
          `${props.t("common.contract")} ${props.t("common.items")}_${
            isEnglish ? props.t("common.en") : props.t("common.pt")
          }-${getDateInDDMMYYYYFormat(new Date())}`,
          [18, 18, 18, 18, 18, 18, 18, 18, 18, 25, 18, 18, 18, 18, 18, 18, 18, 18, 25, 18],
          "d0cece"
        );
      }
      setExportBtnLoader(false);
    });
  };

  document.title = `Veritas | ${props.t("sidebarMenu.itemsList")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            disabled={tableLoader}
          />

          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalItems > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalItems}
                </Badge>
              </Button>
            ) : null}
            {/* Highlighted: Clear Filters Button */}
            <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
              {props.t("common.clearFilters")}
            </Button>

            <CustomButton
              disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
              btnTitle={props.t("common.link")}
              onClick={handleLinkToAuctionModalOpen}
            />
            <CustomDropdownButton
              disabled={totalItems <= 0}
              isLoading={exportBtnLoader}
              btnTitle={props.t("common.export")}
              options={[
                {
                  label: props.t("common.pdf", { lang: props.t("common.en") }),
                  onClick: () => {
                    handleExportPDF(true);
                  },
                },
                {
                  label: props.t("common.pdf", { lang: props.t("common.pt") }),
                  onClick: () => {
                    handleExportPDF(false);
                  },
                },
                {
                  label: `${props.t("common.excel")}
                   ${props.t("common.en")}`,
                  onClick: () => {
                    handleExportContractItemsExcel(true);
                  },
                },
                {
                  label: `${props.t("common.excel")}
                  ${props.t("common.pt")}`,
                  onClick: () => {
                    handleExportContractItemsExcel(false);
                  },
                },
              ]}
            />
            <CustomButton
              isLoading={bulkDeleteLoader}
              color={"danger"}
              btnTitle={props.t("common.delete")}
              disabled={!(isAllSelected && totalItems > 0) && selectedRows.length === 0}
              onClick={() => {
                handleBulkDeleteModalOpen();
              }}
            />
          </div>
        </div>

        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("common.items")}</CardHeader>

          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            onRowClicked={(row) => {
              navigateOnContractItemForm(row);
            }}
            columns={columns}
            data={itemListData}
            paginationDefaultPage={itemsPayload?.page}
            paginationPerPage={itemsPayload?.limit}
            pagination
            paginationServer
            paginationTotalRows={totalItems}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>
      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={`${props.t("common.delete")} ${selectedRows.length || totalItems} ${props.t("common.items")}`}
        body={props.t("confirmation.deleteMessage", {
          module: `${props.t("common.items")}`,
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={bulkDeleteLoader}
      />

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteItem}
        title={`${props.t("common.delete")} ${props.t("common.item")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.item"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
      {/* link to auction modal */}
      <LinkToAuctionModal
        isModalOpen={isLinkToAuctionModalOpen}
        onCancel={handleLinkToAuctionModalClose}
        onConfirm={handleLinkToAuction}
        title={props.t("common.linkToAuction")}
        loader={linkToAuctionLoader}
      />
    </>
  );
};

export default withTranslation()(ContractItems);
