import { toast } from "react-toastify";
import API from "../../helpers/api/api_interceptor";
import { ADD_LANGUAGE, EDIT_LANGUAGE, SET_LANGUAGELIST } from "./actionType";

// list all languages
export const fetchLanguageListAction = (params) => async (dispatch) => {
  try {
    return API.get(`/users/languages`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        filter: params.filter,
        sort: params?.sort,
      },
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: SET_LANGUAGELIST,
          payload: {
            languageList: res?.data?.data?.results,
            totalLanguages: res?.data?.data?.count,
          },
        });
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// delete particular language
export const deleteLanguageAction =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/users/languages/${id}`, {
        data: { ...payload },
      }).then((res) => {
        return res;
      });
    } catch (error) {
      console.log(error);
    }
  };

// Add particular language
export const addLanguage = (payload) => async (dispatch) => {
  try {
    return API.post(`/users/languages`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        dispatch({
          type: ADD_LANGUAGE,
          payload: {
            data: res?.data?.data,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Edit particular language
export const editLanguage = (payload) => async (dispatch) => {
  try {
    return API.put(`/users/languages/${payload.id}`, payload.values).then((res) => {
      if (res && res.status && res.status === 200) {
        dispatch({
          type: EDIT_LANGUAGE,
          payload: {
            data: res?.data?.data,
          },
        });
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
