import { ADD_LOCATION, DELETE_LOCATION, EDIT_LOCATION, SET_LOCATION_LIST } from "./actionType";

const INIT_STATE = {
  data: [],
  total: 0,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOCATION_LIST:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload?.total,
      };
    case ADD_LOCATION:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        total: state.total + 1,
      };
    case EDIT_LOCATION:
      const existingIndex = state.data.findIndex((location) => location.id === action.payload.data.id);
      const updatedLocationData = state.data;
      if (existingIndex !== -1) {
        updatedLocationData[existingIndex] = action.payload.data;
      }
      return {
        ...state,
        data: [...updatedLocationData],
      };
    case DELETE_LOCATION:
      return {
        ...state,
        data: state.data.filter((locationData) => locationData.id !== action.payload.deletedId),
        totalLanguages: state.totalLanguages - 1,
      };
    default:
      return state;
  }
};

export default User;
