import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";
import { getPrecontractItems, getPrecontracts, sendItemsToContract } from "../../../store/pre-contracts/actions";
import {
  getColumnDetail,
  getLabelValueArray,
  getTypeFromValue,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import debounce from "lodash.debounce";
import { DEBOUNCE_TIME } from "../../../helpers/constants";

const ImportPrecontractModal = ({ isModalOpen, handleModalClose, t, contractId, customrId }) => {
  const dispatch = useDispatch();
  const PRECONTRACTS_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.PreContracts?.data),
    "id",
    "pre_contract_no"
  );
  const { precontractItems, totalItems } = useSelector((state) => state?.PreContracts);
  const [precontractPayload, setPrecontractPayload] = useState({
    page: 1,
    limit: 10,
  });
  const [itemsPayload, setItemsPayload] = useState({
    page: 1,
    limit: 10,
    sort: "position_pre_contract",
    filter: { contract__isnull: true },
  });
  const [precontractSearchLoader, setPrecontractSearchLoader] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      precontract_id: "",
    },
    validationSchema: Yup.object({
      precontract_id: Yup.string().required(`${t("common.please")} ${t("common.enter")} ${t("common.preContract")}`),
    }),
    onSubmit: (values) => {
      handleImport(values);
    },
  });

  // handle import pre-contract items
  const handleImport = (values) => {
    setImportLoader(true);
    dispatch(
      sendItemsToContract(values?.precontract_id, {
        filter: itemsPayload?.filter || {},
        is_selected_all: isAllSelected,
        ids: selectedRows,
        existing_contract_id: contractId,
      })
    ).then((res) => {
      setImportLoader(false);
      if (res) {
        handleModalClose();
      }
    });
  };

  // handle page change
  const handlePageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setItemsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  //   handle pre-contract search
  const handlePrecontractSearch = (value) => {
    setPrecontractPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, pre_contract_no__icontains: value },
    }));
  };

  // debounce for pre-contract search
  const precontractSearchDebounce = useMemo(() => {
    return debounce(handlePrecontractSearch, DEBOUNCE_TIME);
  }, []);

  // cancel debounce
  useEffect(() => {
    return () => {
      precontractSearchDebounce.cancel();
    };
  }, [precontractSearchDebounce]);

  // to get the list of pre-contracts
  useEffect(() => {
    setPrecontractSearchLoader(true);
    dispatch(getPrecontracts(precontractPayload)).then(() => {
      setPrecontractSearchLoader(false);
    });
  }, [precontractPayload, dispatch]);

  // to get the list of pre-contract items
  useEffect(() => {
    setTableLoader(true);
    dispatch(getPrecontractItems(itemsPayload)).then((res) => {
      setTableLoader(false);
    });
  }, [itemsPayload, dispatch]);

  // to reset the form
  useEffect(() => {
    if (!isModalOpen) {
      validation.resetForm();
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }, [isModalOpen]);

  // to set the selected pre-contract id in itemspayload
  useEffect(() => {
    if (validation?.values?.precontract_id) {
      setItemsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          pre_contract: validation?.values?.precontract_id,
        },
      }));
    }
  }, [validation?.values?.precontract_id]);

  // to set the customer id in precontract payload
  useEffect(() => {
    if (customrId && isModalOpen) {
      setPrecontractPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          customer: customrId,
        },
      }));
    }
  }, [customrId, isModalOpen]);

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          type="checkbox"
          disabled={isAllSelected || row?.item_status === "contract"}
          checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
          onChange={(event) => {
            if (selectedRows?.find((item) => item === row?.id)) {
              setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
            } else {
              setSelectedRows((prevState) => [...prevState, row?.id]);
            }
          }}
        />
      ),
      width: "10%",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.no_")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="pre_contract_no__icontains"
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span
          style={{
            color: `${row?.item_status === "contract" ? "#9c9c9c" : ""}`,
          }}
        >
          {row?.position_pre_contract ? row?.position_pre_contract : "-"}
        </span>
      ),
      width: "20%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_en__icontains", "title_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span
          style={{
            color: `${row?.item_status === "contract" ? "#9c9c9c" : ""}`,
          }}
        >
          {getColumnDetail(row?.title_en, row?.title_pt)}
        </span>
      ),
      width: "32.5%",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("description_en__icontains", "description_pt__icontains")}
            className="column-search-input"
            payload={itemsPayload}
            setPayload={setItemsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <span
          style={{
            color: `${row?.item_status === "contract" ? "#9c9c9c" : ""}`,
          }}
        >
          {getColumnDetail(row?.description_en, row?.description_pt)}
        </span>
      ),
      width: "32.5%",
      wrap: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="lg"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{t("common.importPrecontractItems")}</span>

          {((isAllSelected && totalItems > 0) || selectedRows.length) && validation?.values?.precontract_id ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalItems}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>
      <Form
        onKeyPress={onKeyPressForm}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          <Col sm={6} md={6} lg={6}>
            <div className="mb-2">
              <Label htmlFor="precontract_id" className="form-label">
                {t("common.preContract") + "*"}
              </Label>
              <Select
                isLoading={precontractSearchLoader}
                name="precontract_id"
                options={PRECONTRACTS_OPTIONS}
                className={`custom-select ${
                  validation.touched.precontract_id && validation.errors.precontract_id ? "select-error" : ""
                }`}
                placeholder={`${t("common.select")} ${t("common.preContract")}`}
                value={getTypeFromValue(PRECONTRACTS_OPTIONS, validation.values.precontract_id) || ""}
                onInputChange={precontractSearchDebounce}
                onChange={(precontract_id) => {
                  validation.setFieldValue("precontract_id", precontract_id?.value);
                }}
                onBlur={(e) => {
                  validation.setFieldTouched("precontract_id", true);
                  validation.handleBlur(e);
                }}
              />
              {validation.touched.precontract_id && validation.errors.precontract_id ? (
                <span className="custom-invalid-feedback">{validation.errors.precontract_id}</span>
              ) : null}
            </div>
          </Col>

          <Card className="subcard-table-container table-light">
            {tableLoader ? <Loader /> : null}
            <DataTable
              fixedHeader
              className="data-table"
              persistTableHead
              columns={columns}
              data={validation?.values?.precontract_id ? [...precontractItems] : []}
              pagination
              paginationServer
              paginationDefaultPage={itemsPayload?.page}
              paginationTotalRows={totalItems}
              noDataComponent={
                <NoRecords message={!validation?.values?.precontract_id && t("information.importPrecotractItems")} />
              }
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                rowsPerPageText: t("common.rowsPerPage"),
                rangeSeparatorText: t("common.rangeSeparator"),
              }}
            />
          </Card>
        </ModalBody>
        <ModalFooter>
          <CustomButton btnTitle={t("common.cancel")} disabled={importLoader} color="dark" onClick={handleModalClose} />
          <CustomButton
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
            isLoading={importLoader}
            btnTitle={t("common.import")}
            disabled={
              (isAllSelected && totalItems === 0) ||
              (selectedRows?.length === 0 && !isAllSelected) ||
              !validation?.values?.precontract_id
            }
            color="primary"
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ImportPrecontractModal);
