import React from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const ResultModal = ({
  isModalOpen,
  title,
  body,
  onCloseBtnHandler,
  loader,
  lotLoader,
  onConfirmBtnHandler,
  onChooseBtnHandler,
  actionBtnTitle,
  isAllselected,
  selectedRows,
  t,
}) => {
  const { id } = useParams();
  const handleModalClose = () => {
    if (!loader) onCloseBtnHandler();
  };

  return (
    <>
      <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
        <ModalHeader>{title || "Delete Modal"}</ModalHeader>
        <ModalBody>{body || "Are You Sure ?"}</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button color="primary" disabled={lotLoader || !id} className="btn-load d-flex" onClick={onChooseBtnHandler}>
            {lotLoader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}

            <span className={`flex-grow-1 ${lotLoader ? "ms-2" : ""}`}>{t("common.choose")}</span>
          </Button>
          <Button
            color="primary"
            disabled={loader || (!isAllselected && selectedRows.length === 0)}
            className="btn-load d-flex"
            onClick={onConfirmBtnHandler}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}

            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{actionBtnTitle || t("common.publish")}</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation()(ResultModal);
