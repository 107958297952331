// customer action types
export const SET_CUSTOMERS_DATA = "SET_CUSTOMERS_DATA";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_SHIPPING_OPTIONS = "SET_SHIPPING_OPTIONS";
export const SET_PERSON_DETAILS = "SET_PERSON_DETAILS";
export const SET_TAG_OPTIONS = "SET_TAG_OPTIONS";
export const SET_AUCTION_LOT_TAG_OPTIONS = "SET_AUCTION_LOT_TAG_OPTIONS";
export const SET_RETURNED_LOTS = "SET_RETURNED_LOTS";
export const SET_SOLD_LOTS = "SET_SOLD_LOTS";
export const SET_AVAILABLE_LOTS = "SET_AVAILABLE_LOTS";
export const SET_PAID_TO_SELLER_LOTS = "SET_PAID_TO_SELLER_LOTS";
