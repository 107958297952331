import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import NumberInput from "../../common/NumberInput";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddPaymentConditionModal = ({ paymentCondition, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      name: paymentCondition?.name || "",
      name_en: paymentCondition?.name_en || "",
      deadline: paymentCondition?.deadline,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${`${t("settings.billing.paymentConditions.paymentCondition")} ${t(
          "common.name"
        )}`}`
      ),
      deadline: Yup.number()
        .min(0, t("settings.billing.paymentConditions.deadlineTypeValidation"))
        .required(
          `${t("common.please")} ${t("common.enter")} ${`${t(
            "settings.billing.paymentConditions.paymentCondition"
          )} ${t("common.deadline")}`}`
        ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {paymentCondition?.id
          ? t("settings.billing.paymentConditions.editPaymentCondition")
          : t("settings.billing.paymentConditions.addPaymentCondition")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              {t("common.name")}
            </Label>
            <Input
              name="name"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.paymentConditions.paymentCondition")} ${t(
                "common.name"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={validation.touched.name && validation.errors.name ? true : false}
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              {t("common.name")} (En)
            </Label>
            <Input
              name="name_en"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.paymentConditions.paymentCondition")} ${t(
                "common.name"
              )}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name_en || ""}
              invalid={validation.touched.name_en && validation.errors.name_en ? true : false}
            />
            {validation.touched.name_en && validation.errors.name_en ? (
              <FormFeedback type="invalid">{validation.errors.name_en}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="deadline" className="form-label">
              {t("common.deadline")}
            </Label>
            <NumberInput
              name="deadline"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("settings.billing.paymentConditions.paymentCondition")} ${t(
                "common.deadline"
              )}`}
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.deadline}
              invalid={validation.touched.deadline && validation.errors.deadline ? true : false}
            />
            {validation.touched.deadline && validation.errors.deadline ? (
              <FormFeedback type="invalid">{validation.errors.deadline}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddPaymentConditionModal);
