import i18next from "i18next";

export const DEBOUNCE_TIME = 300;
export const TEXTAREA_DEFAULT_ROWS = 4;
export const DROPDOWN_DEFAULT_LIMIT = 50;
export const MAX_DATE = new Date(9999, 12, 31);
export const DEFAULT_ROW_PER_PAGE = 30;
export const monthNames = [
  i18next.t("months.jan"),
  i18next.t("months.feb"),
  i18next.t("months.mar"),
  i18next.t("months.apr"),
  i18next.t("months.may"),
  i18next.t("months.jun"),
  i18next.t("months.jul"),
  i18next.t("months.aug"),
  i18next.t("months.sep"),
  i18next.t("months.oct"),
  i18next.t("months.nov"),
  i18next.t("months.dec"),
];

// default currency code
export const DEFAULT_CURRENCY_CODE = "€";
// default vat rate
export const DEFAULT_VAT_RATE = 23;
// default payment condition
export const DEFAULT_PAYMENT_CONDITION = "Upfront Payment";
// default Buyer's premium
export const DEFAULT_BUYER_PERMIUM = 17;
// default Seller's premium
export const DEFAULT_SELLER_PERMIUM = 16;
// default payment method
export const DEFAULT_PAYMENT_METHOD = "Transferência bancária";

// lot's tags
export const SOLD_LOT = "Sold";
export const AVAILABLE_LOT = "Available";
export const AVAILABLE_FOR_AUCTION_LOT = "Available for auction";
export const RESERVED_LOT = "Reserved";
export const RETURNED_LOT = "Returned";

export const MANUAL_LOTS_TAGS = [AVAILABLE_LOT, AVAILABLE_FOR_AUCTION_LOT];

// default document types
export const PROFORMA_INVOICE_DOCUMENT = "Proforma Comprador"; //  proforma invoice - buyer proforma invoice
export const INVOICE_DOCUMENT = "Fatura Comprador"; // Buyer - invoice
export const RECEIPT_DOCUMENT = "Recibo"; //Receipt
export const TRANSPORT_DOCUMENT = "Guia de Transporte"; //Transport document
export const RETURN_GUIDE_DOCUMENT = "Guia de Devolução"; //Return guide
export const SALES_NOTE = "Nota de Venda"; // Sales note
export const CREDIT_NOTE = "Nota de Credito"; // credit note
export const DEBIT_NOTE = "Nota de Debito"; //Debit note
export const NORMAL_INVOICE = "Fatura Normal";
export const NORMAL_PROFORMA = "Pró-forma Normal";
export const CREDIT_NOTE_LOT = "Nota de Credito Lots"; //credit note lots
export const DEBIT_NOTE_LOT = "Nota de Debito Lots"; //debit note lots
export const SALES_NOTE_PROFORMA = "Nota de vendas pró-forma"; //Sales Note Proforma

//new changes
export const FATURA_INVOICE = "Fatura Normal";
export const PROFORMA_NORMAL = "Proforma Normal";

export const IMAGE_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".tiff",
  ".svg",
  ".webp",
  ".heif",
  ".bat",
  ".ico",
];

export const VIDEO_EXTENSIONS = [
  ".mp4",
  ".mkv",
  ".mov",
  ".avi",
  ".wmv",
  ".flv",
  ".m4v",
  ".webm",
  ".ts",
  ".3gp",
  ".mts",
  ".m2ts",
  ".gifv",
  ".asf",
  ".rm",
  ".ogv",
  ".vob",
  ".vp9",
  ".hevc",
  ".drc",
  ".mxf",
];

export const EXCEL_EXTENSIONS = [".xlsx", ".xls", ".xlsm", ".csv", ".ods", ".xml", ".xltx", ".xltm", ".xlw"];

export const PDF_EXTENSIONS = [".pdf", ".pdfa", ".fdf", ".xfdf", ".pdx", ".pdfx"];

// save and save&publish type
export const TYPE_SAVE = "save";
export const TYPE_PUBLISH = "publish";
// user roles constants
export const ADMIN = "AD";
export const ACCOUNTANT = "AC";
export const COMUNICATIONS = "CM";
export const GENERAL = "GN";
export const EXTERNAL = "EE";
export const TRANSLATOR = "TR";

// website pages types constants
export const HOME_PAGE = "HOME_PAGE";
export const SERVICES_APPRAISALS = "SERVICES_APPRAISALS";
export const SERVICES_EXPERTIES = "SERVICES_EXPERTIES";
export const SERVICES_CONSULTANCY = "SERVICES_CONSULTANCY";

export const PRIVATE_SALES = "PRIVATE_SALES";
export const BUYING_CONTENT = "BUYING_CONTENT";
export const SELLING_CONTENT = "SELLING_CONTENT";
export const WHO_WE_ARE_CONTENT = "WHO_WE_ARE_CONTENT";
export const CONTACT_US_CONTENT = "CONTACT_US_CONTENT";
export const OTHER_PAGE = "OTHER_PAGE";

// mail and phone number
export const VERITAS_INFO_MAIL = "info@veritas.art";
export const VERITAS_PHONE_NUMBER = "217948000";
