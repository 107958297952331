import { FormikProvider } from "formik";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { getColumnDetail, getDateForInput } from "../../../../helpers/utils";
import CustomAddButton from "../../../common/CustomAddButton";
import DeleteModal from "../../../common/DeleteModal";
import EditorBlock from "../../../common/EditorBlock";
import Loader from "../../../common/Loader";
import MessageBlock from "../../../common/MessageBlock";
import NoRecords from "../../../common/NoRecords";
import AddHighlightModal from "./AddHighlightModal";
import EditHighlightModal from "./EditHighlightModal";
import HighlightComponent from "./HighlightComponent";

const EditionInfo = ({ ...props }) => {
  const { id, editionType } = useParams();
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const [infoLoader, setInfoLoader] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isHighlightModalOpen, setIsHighlightModalOpen] = useState(false);
  const [isEditHighlightModalOpen, setIsEditHighlightModalOpen] = useState(false);

  // handle highlight modal open
  const handleHighlightModalOpen = () => {
    setIsHighlightModalOpen(true);
  };
  // handle highlight modal close
  const handleHighlightModalClose = () => {
    setIsHighlightModalOpen(false);
  };
  // handle add highlights
  const handleAddHighlights = (data) => {
    props?.infoValidation?.setFieldValue("highlights", [
      ...props?.infoValidation?.values?.highlights,
      ...data?.map((item, index) => ({
        auction_lot: item,
        image_link: item?.contract_item?.default_photo,
        title: editionType === "pt" ? item?.contract_item?.title_pt : item?.contract_item?.title_en,
        description: editionType === "pt" ? item?.contract_item?.description_pt : item?.contract_item?.description_en,
        position: props?.infoValidation?.values?.highlights?.length + index + 1,
      })),
    ]);
    handleHighlightModalClose();
  };

  // handle highlight modal open
  const handleEditHighlightModalOpen = (item) => {
    setSelectedItem(item);
    setIsEditHighlightModalOpen(true);
  };
  // handle highlight modal close
  const handleEditHighlightModalClose = () => {
    setSelectedItem(null);
    setIsEditHighlightModalOpen(false);
  };
  // handle highlight modal close
  const handleEditHighlightModalSave = (values) => {
    const highlights = [...props?.infoValidation?.values?.highlights];
    const highlightIndex = highlights.findIndex((item) => item?.auction_lot?.id === values?.auction_lot?.id);
    highlights[highlightIndex] = {
      ...highlights[highlightIndex],
      title: values?.title,
      description: values?.description,
      image_link: values?.image_link,
      video_link: values?.video_link,
    };
    props?.infoValidation?.setFieldValue("highlights", highlights);
    handleEditHighlightModalClose();
  };

  // handle delete modal open
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedItem(null);
  };
  // handle delete highlight modal open
  const handleDeleteHighlight = (item) => {
    setIsDeleteModalOpen(true);
    setSelectedItem(item);
  };
  // handle delete
  const handleDelete = () => {
    props?.infoValidation?.setFieldValue(
      "highlights",
      props?.infoValidation?.values?.highlights?.filter(
        (item) => item?.auction_lot?.id !== selectedItem?.auction_lot?.id
      )
    );
    handleDeleteModalClose();
  };

  const reorderHighlights = (startIndex, endIndex) => {
    const result = Array.from(props?.infoValidation?.values?.highlights);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // on drag-end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const highlightItems = reorderHighlights(result.source.index, result.destination.index);
    props?.infoValidation?.setFieldValue(
      "highlights",
      highlightItems?.map((item, index) => ({ ...item, position: index + 1 }))
    );
  };

  return (
    <>
      <FormikProvider value={props?.infoValidation}>
        <Form
          className="website-auction-info position-relative"
          onSubmit={(e) => {
            e.preventDefault();
            props?.infoValidation.handleSubmit();
            return false;
          }}
        >
          {infoLoader ? <Loader /> : null}
          <Row>
            {/* auction text */}
            <Col sm={6} md={12} lg={6}>
              <div className="mb-3">
                <Label className="form-label fw-bold">{`${props.t("common.auctionText")}`}</Label>
                <EditorBlock
                  editorClassName="auction-text-wrapper editor-wrapper"
                  editorState={props?.infoValidation?.values?.auction_text || ""}
                  onEditorStateChange={(value) => {
                    props.infoValidation.setFieldValue(`auction_text`, value);
                  }}
                  onBlur={(e) => {
                    props?.infoValidation.setFieldTouched(`auction_text`, true);
                  }}
                  invalid={props.infoValidation?.errors?.auction_text && props.infoValidation?.touched?.auction_text}
                  errorText={props.infoValidation?.errors?.auction_text}
                />
              </div>
            </Col>
            {/* auction info */}
            <Col sm={6} md={12} lg={6}>
              <Row>
                <Col sm={6} md={6} lg={6}>
                  <div className="mb-3 d-flex flex-column">
                    <Label className="form-label fw-bold">{`${props.t("common.auctionYear")}`}</Label>
                    <div>{new Date(auctionData?.auction_start_date).getFullYear()}</div>
                  </div>
                </Col>
                <Col sm={6} md={6} lg={6}>
                  <div className="mb-3 d-flex flex-column">
                    <Label className="form-label fw-bold">{`${props.t("common.location")}`}</Label>
                    <div>
                      {getColumnDetail(
                        auctionData?.auction_location?.location_name_en,
                        auctionData?.auction_location?.location_name_pt
                      )}
                      {/* {auctionData?.auction_location?.location_name} */}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* sessions */}
              <Row>
                <div className="mb-3">
                  <Label className="form-label fw-bold">{`${props.t("common.sessions")}`}</Label>
                  {auctionData?.sessions?.length > 0 ? (
                    <Card className="subcard-table-container table-light">
                      <DataTable
                        fixedHeader
                        persistTableHead
                        columns={[
                          {
                            name: (
                              <div className="font-weight-bold fs-12 table-column-container py-4">
                                <div className="table-column-title">{`${props.t("common.no_")}`}</div>
                              </div>
                            ),
                            selector: (row, index) => index + 1,
                            width: "16%",
                          },
                          {
                            name: (
                              <div className="font-weight-bold fs-12 table-column-container py-4">
                                <div className="table-column-title">{`${props.t("common.date")}`}</div>
                              </div>
                            ),
                            selector: (row) => row?.date,
                            width: "20%",
                          },
                          {
                            name: (
                              <div className="font-weight-bold fs-12 table-column-container py-4">
                                <div className="table-column-title">{`${props.t("common.hour")}`}</div>
                              </div>
                            ),
                            selector: (row) => row?.hour,
                            width: "20%",
                          },
                          {
                            name: (
                              <div className="font-weight-bold fs-12 table-column-container py-4">
                                <div className="table-column-title">{`${props.t("common.firstLot")}`}</div>
                              </div>
                            ),
                            selector: (row) => row?.first_lot,
                            width: "20%",
                          },
                          {
                            name: (
                              <div className="font-weight-bold fs-12 table-column-container py-4">
                                <div className="table-column-title">{`${props.t("common.lastLot")}`}</div>
                              </div>
                            ),
                            selector: (row) => row?.last_lot,
                            width: "20%",
                          },
                        ]}
                        data={[...auctionData?.sessions]}
                        noDataComponent={<NoRecords />}
                      />
                    </Card>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </Row>
              <Row>
                <Col sm={6} md={6} lg={6}>
                  <div className="mb-3 d-flex flex-column">
                    <Label className="form-label fw-bold">{`${props.t("common.consignmentDeadlineDate")}`}</Label>
                    <div>{auctionData?.consignment_deadline_date || "-"}</div>
                  </div>
                </Col>
                <Col sm={3} md={3} lg={3}>
                  <div className="mb-3 d-flex flex-column">
                    <Label className="form-label fw-bold">{`${props.t("common.exhibitionStartDate")}`}</Label>
                    <div>{getDateForInput(auctionData?.exhibition_start_date) || "-"}</div>
                  </div>
                </Col>
                <Col sm={3} md={3} lg={3}>
                  <div className="mb-3 d-flex flex-column">
                    <Label className="form-label fw-bold">{`${props.t("common.exhibitionEndDate")}`}</Label>
                    <div>{getDateForInput(auctionData?.exhibition_end_date) || "-"}</div>
                  </div>
                </Col>
              </Row>
              {/* title of information */}
              <Row>
                <div className="mb-3">
                  <Label className="form-label fw-bold">{`${props.t("common.titleOfInformation")}`}</Label>
                  <Input
                    name="information_title"
                    className="form-control"
                    placeholder={`${props.t("common.enter")} ${props.t("common.titleOfInformation")}`}
                    type="text"
                    onChange={props?.infoValidation.handleChange}
                    onBlur={props?.infoValidation.handleBlur}
                    value={props?.infoValidation.values.information_title || ""}
                    invalid={
                      props?.infoValidation.touched.information_title && props?.infoValidation.errors.information_title
                        ? true
                        : false
                    }
                  />
                  {props?.infoValidation.touched.information_title && props?.infoValidation.errors.information_title ? (
                    <FormFeedback type="invalid">{props?.infoValidation.errors.information_title}</FormFeedback>
                  ) : null}
                </div>
              </Row>
              {/* text box for auction information */}
              <Row>
                <div className="mb-3">
                  <Label className="form-label fw-bold">{`${props.t("common.information")}`}</Label>
                  <EditorBlock
                    editorState={props?.infoValidation?.values?.information || ""}
                    onEditorStateChange={(value) => {
                      props.infoValidation.setFieldValue(`information`, value);
                    }}
                    onBlur={(e) => {
                      props?.infoValidation.setFieldTouched(`information`, true);
                    }}
                    invalid={props.infoValidation?.errors?.information && props.infoValidation?.touched?.information}
                    errorText={props.infoValidation?.errors?.information}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          {/* highlights */}
          <Row>
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Label className="form-label fw-bold ">{`${props.t("common.highlights")}`}</Label>
                <CustomAddButton
                  color={"success"}
                  type="button"
                  btnTitle={props.t("common.addTitle", {
                    module: props.t("common.highlight"),
                  })}
                  onClick={handleHighlightModalOpen}
                />
              </div>
              <div className="highlights-container">
                {props?.infoValidation?.values?.highlights?.length > 0 ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          className="d-flex overflow-auto gap-16"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {props?.infoValidation?.values?.highlights?.map((item, index) => (
                            <Draggable
                              key={item?.auction_lot?.id}
                              draggableId={item?.auction_lot?.id?.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="p-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <HighlightComponent
                                    item={item}
                                    image_url={item?.image_link}
                                    lot_no={
                                      (item?.auction_lot?.lot_no || " ") + " " + (item?.auction_lot?.lot_alpha || " ")
                                    }
                                    title={item?.title}
                                    description={item?.description}
                                    handleEditHighlight={handleEditHighlightModalOpen}
                                    handleDelete={handleDeleteHighlight}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <MessageBlock
                    message={props?.t("common.addTitle", {
                      module: props.t("common.highlight"),
                    })}
                    fontSize={"14px"}
                  />
                )}
              </div>
            </div>
          </Row>
          {/* virtualLink */}
          <Row>
            <div className="mb-3">
              <Label className="form-label fw-bold ">{`${props.t("common.virtualLink")}`}</Label>
              <Input
                name="virtual_link"
                className="form-control"
                placeholder={`${props.t("common.enter")} ${props.t("common.enterVirtualViewingLink")}`}
                type="text"
                onChange={props?.infoValidation.handleChange}
                onBlur={props?.infoValidation.handleBlur}
                value={props?.infoValidation.values.virtual_link || ""}
                invalid={
                  props?.infoValidation.touched.virtual_link && props?.infoValidation.errors.virtual_link ? true : false
                }
              />
              {props?.infoValidation.touched.virtual_link && props?.infoValidation.errors.virtual_link ? (
                <FormFeedback type="invalid">{props?.infoValidation.errors.virtual_link}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3"></div>
          </Row>
        </Form>
      </FormikProvider>
      {/* add highligh modal */}
      <AddHighlightModal
        selectedLots={props?.infoValidation?.values?.highlights?.map((item) => item?.auction_lot?.id)}
        auctionId={id}
        isModalOpen={isHighlightModalOpen}
        onCancel={handleHighlightModalClose}
        onSave={handleAddHighlights}
      />
      {/* edit highlight modal */}
      <EditHighlightModal
        item={selectedItem}
        isModalOpen={isEditHighlightModalOpen}
        handleModalClose={handleEditHighlightModalClose}
        handleSave={handleEditHighlightModalSave}
      />
      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDelete}
        title={`${props.t("common.delete")} ${props.t("common.highlight")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.highlight"),
        })}
        actionBtnTitle={props.t("common.delete")}
      />
    </>
  );
};

export default withTranslation()(EditionInfo);
