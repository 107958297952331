import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";

import ReactCountryFlag from "react-country-flag";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import ActionButton from "../../../../components/common/ActionButton";
import CustomSearchInput from "../../../../components/common/CustomSearchInput";
import DeleteModal from "../../../../components/common/DeleteModal";
import Loader from "../../../../components/common/Loader";
import NoRecords from "../../../../components/common/NoRecords";
import AddWarehouseModal from "../../../../components/pages/settings/AddWarehouseModal";
import { DEFAULT_ROW_PER_PAGE } from "../../../../helpers/constants";
import { getLabelValueArray, getValuesFromArray } from "../../../../helpers/utils";
import { getCountriesOptions } from "../../../../store/common/actions";
import { addWarehouse, deleteWarehouse, editWarehouse, getWarehouses } from "../../../../store/warehouse/actions";

const Warehouse = (props) => {
  const dispatch = useDispatch();
  const contriesOptions = useSelector((state) => state?.common?.countriesOptions?.data);
  const { data: warehouses, total: totalWarehouses } = useSelector((state) => state.Warehouse);

  const [listLoader, setListLoader] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isWarehouseModalOpen, setIsWarehouseModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehousePayload, setWarehousePayload] = useState({
    search: "",
    page: 1,
    limit: DEFAULT_ROW_PER_PAGE,
    filter: {},
    sort: "id",
  });

  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  // handler for calling listing warehouse
  const handleGetWarehouseList = useCallback(
    (warehousePayload) => {
      setListLoader(true);
      dispatch(getWarehouses(warehousePayload))
        .then((res) => {
          setListLoader(false);
        })
        .catch((error) => {
          setListLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetWarehouseList(warehousePayload);
  }, [handleGetWarehouseList, warehousePayload]);

  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
  }, [dispatch]);

  // handle country type filter
  const handleCoutryTypeFilter = (values) => {
    if (values.length > 0) {
      setWarehousePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          country__in: getValuesFromArray(values),
        },
        page: 1,
      }));
    } else {
      setWarehousePayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter.country__in;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  // handler for calling deleting warehouse
  const handleDeleteWarehouse = () => {
    if (selectedWarehouse?.id) {
      setDeleteLoader(true);
      dispatch(deleteWarehouse(selectedWarehouse?.id))
        .then((res) => {
          if (res) {
            handleGetWarehouseList(warehousePayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          handleDeleteModalClose();
          setDeleteLoader(false);
        });
      // dispatch(
      //   deleteWarehouse({
      //     id: selectedWarehouse,
      //     payload: { is_admin: isDeleteActionAllowed },
      //   })
      // )
      //   .then((res) => {
      //     if (res.data.status === 200) {
      //       if (!res?.data?.data?.action_allowed) {
      //         handleDeleteModalClose();
      //       }
      //       setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
      //       toast.warn(res?.data?.detail);
      //       setDeleteLoader(false);
      //     } else if (res.data.status === 204) {
      //       toast.success(res?.data?.detail);
      //       handleGetWarehouseList(warehousePayload);
      //       handleDeleteModalClose();
      //       setDeleteLoader(false);
      //     }
      //   })
      //   .catch((error) => {
      //     setDeleteLoader(false);
      //     setIsDeleteModalOpen(false);
      //   });
    }
  };

  // handler for calling create/edit warehouse
  const handleSaveWarehouse = (values, validation) => {
    setSaveLoader(true);
    if (selectedWarehouse && selectedWarehouse.id) {
      // dispatch for editing warehouse
      dispatch(editWarehouse(selectedWarehouse?.id, values))
        .then((res) => {
          if (res) {
            handleGetWarehouseList(warehousePayload);
            handleWarehouseModalClose();
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          handleWarehouseModalClose();
          setSaveLoader(false);
        });
    } else {
      // dispatch for adding new warehouse
      dispatch(addWarehouse(values))
        .then((res) => {
          if (res) {
            handleGetWarehouseList(warehousePayload);
            handleWarehouseModalClose();
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          handleWarehouseModalClose();
          setSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setWarehousePayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setWarehousePayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleDeleteModalOpen = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedWarehouse(row);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setIsDeleteModalOpen(false);
    setSelectedWarehouse(null);
  };

  const handleWarehouseModalOpen = (row) => {
    setIsWarehouseModalOpen(true);
    setSelectedWarehouse(row);
  };
  const handleWarehouseModalClose = () => {
    setIsWarehouseModalOpen(false);
    setSelectedWarehouse(null);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      minWidth: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.address")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="address__icontains"
            className="column-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
        </div>
      ),
      selector: (row) => row?.address || "-",
      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.city")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="city__icontains"
            className="column-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
        </div>
      ),
      selector: (row) => row?.city || "-",
      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.postCode")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="city__icontains"
            className="column-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
        </div>
      ),
      selector: (row) => row?.post_code || "-",
      minWidth: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.country")}</div>
          <Select
            className="custom-select"
            isMulti
            options={getLabelValueArray(contriesOptions, "id", "name")}
            onChange={handleCoutryTypeFilter}
          />
        </div>
      ),
      selector: (row) => (
        <ReactCountryFlag
          countryCode={row?.country?.code}
          svg
          style={{
            width: "2em",
            height: "2em",
          }}
          title={row?.country?.name}
        />
      ),
      minWidth: "200px",
      center: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            <div className="table-actions">
              <ActionButton
                iconClasses="ri-pencil-fill fs-18"
                buttonId={`tooltip-${row?.id}-edit`}
                tooltip={props.t("common.edit")}
                onClick={() => handleWarehouseModalOpen(row)}
              />
              <ActionButton
                iconClasses="ri-delete-bin-fill fs-18"
                buttonId={`tooltip-${row?.id}-delete`}
                tooltip={props.t("common.delete")}
                onClick={() => handleDeleteModalOpen(row)}
              />
            </div>
          </div>
        </div>
      ),
      minWidth: "150px",
      center: true,
      wrap: true,
    },
  ];

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.warehouses")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={warehousePayload}
            setPayload={setWarehousePayload}
          />
          <div className="contain-header-right">
            <Button
              color="success"
              className="btn-load d-flex align-items-center"
              type="submit"
              onClick={handleWarehouseModalOpen}
            >
              <i className="ri-add-fill fs-18" />
              <span className="flex-grow-1 ms-2">{`${props.t("common.add")} ${props.t("sidebarMenu.warehouse")}`}</span>
            </Button>
          </div>
        </div>
        <Card className="container-body">
          {listLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.warehouses")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            columns={columns}
            data={[...warehouses]}
            pagination
            paginationServer
            paginationTotalRows={totalWarehouses}
            paginationPerPage={warehousePayload?.limit}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            persistTableHead
          />
        </Card>
      </div>

      {/* to add /edit warehouse */}
      <AddWarehouseModal
        isModalOpen={isWarehouseModalOpen}
        warehouse={selectedWarehouse}
        onCancel={handleWarehouseModalClose}
        onSave={handleSaveWarehouse}
        loader={saveLoader}
      />

      {/* delete modal for warehouse */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("sidebarMenu.warehouse")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("sidebarMenu.warehouse"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onConfirmBtnHandler={handleDeleteWarehouse}
        onCloseBtnHandler={handleDeleteModalClose}
      />
    </>
  );
};

export default withTranslation()(Warehouse);
