import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import { scrollToFirstFormikError } from "../../../helpers/utils";

const AddCategoryModal = ({ category, isModalOpen, onCancel, onSave, loader, t }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      category_name_en: category?.category_name_en || "",
      category_name_pt: category?.category_name_pt || "",
    },
    validationSchema: Yup.object({
      category_name_en: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("common.descriptionEn")}`
      ),
      category_name_pt: Yup.string().required(
        `${t("common.please")} ${t("common.enter")} ${t("common.descriptionPt")}`
      ),
    }),
    onSubmit: (values) => {
      onSave(values, validation);
    },
  });
  useEffect(() => {
    validation.resetForm();
  }, [isModalOpen]);

  const handleModalClose = () => {
    if (!loader) {
      validation.resetForm();
      onCancel();
    }
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={handleModalClose} fade={false}>
      <ModalHeader>
        {category?.id
          ? t("common.editTitle", {
              module: t("settings.lots.categories.category"),
            })
          : t("common.addTitle", {
              module: t("settings.lots.categories.category"),
            })}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <ModalBody>
          {/* form */}
          <div className="mb-3">
            <Label htmlFor="category_name_en" className="form-label">
              {t("common.descriptionEn")}
            </Label>
            <Input
              name="category_name_en"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionEn")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.category_name_en || ""}
              invalid={validation.touched.category_name_en && validation.errors.category_name_en ? true : false}
            />
            {validation.touched.category_name_en && validation.errors.category_name_en ? (
              <FormFeedback type="invalid">{validation.errors.category_name_en}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label htmlFor="category_name_pt" className="form-label">
              {t("common.descriptionPt")}
            </Label>
            <Input
              name="category_name_pt"
              className="form-control"
              placeholder={`${t("common.enter")} ${t("common.descriptionPt")}`}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.category_name_pt || ""}
              invalid={validation.touched.category_name_pt && validation.errors.category_name_pt ? true : false}
            />
            {validation.touched.category_name_pt && validation.errors.category_name_pt ? (
              <FormFeedback type="invalid">{validation.errors.category_name_pt}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleModalClose} disabled={loader}>
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={loader}
            className="btn-load d-flex"
            type="submit"
            onClick={() => scrollToFirstFormikError(validation.errors)}
          >
            {loader ? (
              <span className="d-flex align-items-center ">
                <Spinner size="xs" className="flex-shrink-0"></Spinner>
              </span>
            ) : null}
            <span className={`flex-grow-1 ${loader ? "ms-2" : ""}`}>{t("common.save")}</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddCategoryModal);
